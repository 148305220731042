<template>
  <v-form class="input-editor" :disabled="disabled" @update:model-value="onFormInput">
    <div v-for="(_, index) in localValues" :key="index" class="edit-input">
      <gs-input
        :model-value="localValues[index].value"
        :height="56"
        :rules="getInputRules(index)"
        :label="inputLabel"
        @update:model-value="(inputValue) => onInput(index, inputValue)"
      />
      <v-btn
        :elevation="0"
        :height="56"
        :width="40"
        :min-width="0"
        variant="outlined"
        :disabled="isActionButtonDisabled(index)"
        class="input-action-btn"
        @click="() => onActionButtonClick(index)"
      >
        <v-icon class="material-icons-outlined input-action-icon" size="large">
          {{ isAddAction(index) ? 'mdi-plus-thick' : 'mdi-minus-thick' }}
        </v-icon>
      </v-btn>
    </div>
  </v-form>
</template>

<script lang="ts">
import { RulesMixin } from '@/helpers/mixins/RulesMixin.vue'
import { Utils } from '@/helpers/mixins/utilsMixin'
import type { TranslateResult } from 'vue-i18n'
import type { Clearable } from '@/helpers/interfaces'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DynamicInputGroup',
  emits: ['update:model-value', 'validate'],
  mixins: [RulesMixin, Utils],
  data() {
    return {
      localValues: [],
    } as {
      localValues: { value: Clearable<string> }[]
    }
  },
  computed: {
    inputValues(): Clearable<string>[] {
      return this.localValues.map(({ value }) => value)
    },
    remainingInputCount(): number {
      return this.inputNumberLimit ? this.inputNumberLimit - this.localValues.length : 1
    },
  },
  created() {
    this.localValues = [{ value: '' }]
  },
  methods: {
    getInputRules(index: number) {
      if (this.localValues.length === 1) {
        return [this.rules.required, ...(this.inputRules ?? [])]
      }

      const inputValue = this.inputValues[index]
      return inputValue === '' || inputValue === null
        ? [true]
        : [this.rules.unique(this.inputValues), ...(this.inputRules ?? [])]
    },
    isAddAction(index: number): boolean {
      return index === this.localValues.length - 1
    },
    isActionButtonDisabled(index: number): boolean {
      if (this.disabled) {
        return true
      }

      if (!this.isAddAction(index)) {
        return false
      }

      if (!this.localValues[index].value) {
        return true
      }

      if (this.isAddAction(index)) {
        return !this.remainingInputCount
      }

      return false
    },
    onActionButtonClick(index: number): void {
      if (this.isAddAction(index)) {
        this.localValues.push({ value: null })
      } else {
        this.localValues.splice(index, 1)
        this.$emit(
          'update:model-value',
          this.inputValues.filter((value) => value),
        )
      }
    },
    onInput(index: number, value: string) {
      this.localValues[index].value = value
      this.$emit(
        'update:model-value',
        this.inputValues.filter((value) => value),
      )
    },
    onFormInput(isFormValid: boolean | null) {
      this.$emit('validate', isFormValid)
    },
    onValueChange() {
      if (!this.modelValue?.length) {
        this.localValues = [{ value: '' }]
        return
      }

      const hasPlaceHolder = this.localValues.some(({ value }) => value === '')
      this.localValues = this.modelValue.map((inputValue) => ({ value: inputValue }))
      if (hasPlaceHolder) {
        this.localValues.push({ value: null })
      }
    },
  },
  props: {
    disabled: {
      type: Boolean,
    },
    modelValue: {
      type: Array as PropType<string[]>,
    },
    inputRules: {
      type: Array as PropType<Array<<T extends string>(data: T) => boolean | string>>,
    },
    inputLabel: {
      type: String as PropType<TranslateResult>,
    },
    inputNumberLimit: {
      type: Number,
    },
  },
  watch: {
    modelValue: [
      {
        handler: 'onValueChange',
        deep: true,
      },
    ],
  },
})
</script>

<style scoped lang="scss">
@import '~vuetify/settings';

.input-editor {
  display: flex;
  flex-flow: column;
  gap: 10px;
  margin-bottom: 12px;
}

.edit-input {
  display: flex;
  flex-flow: row;
  gap: 8px;
}

.input-action-btn {
  padding: 0 !important;
  border-color: var(--ui-green-light);
}

.input-action-icon {
  color: var(--ui-green) !important;
}
</style>
