import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "how-it-works-wrapper" }
const _hoisted_2 = { class: "how-it-works-header" }
const _hoisted_3 = { class: "how-it-works-text" }
const _hoisted_4 = { class: "how-it-works-text icon-wrapper" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.t('header')), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t('text')), 1),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_v_icon, { class: "icon" }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode(" mdi-web")
        ])),
        _: 1
      }),
      _createElementVNode("div", {
        innerHTML: _ctx.t('multilanguage')
      }, null, 8, _hoisted_5)
    ]),
    _createElementVNode("div", {
      class: "how-it-works-text",
      innerHTML: _ctx.t('learn_more')
    }, null, 8, _hoisted_6)
  ]))
}