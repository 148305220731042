import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "user-component" }
const _hoisted_2 = { class: "user-component-wrapper" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "user-wrapper" }
const _hoisted_5 = { class: "back" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "form-wrapper" }
const _hoisted_8 = { class: "header" }
const _hoisted_9 = {
  key: 0,
  class: "name-wrapper"
}
const _hoisted_10 = { class: "tooltip-wrapper" }
const _hoisted_11 = { class: "input-label" }
const _hoisted_12 = {
  key: 0,
  class: "price"
}
const _hoisted_13 = {
  key: 1,
  class: "price"
}
const _hoisted_14 = {
  key: 2,
  class: "price"
}
const _hoisted_15 = { class: "input-description" }
const _hoisted_16 = { class: "text-field-wrapper" }
const _hoisted_17 = { class: "plus-minus" }
const _hoisted_18 = {
  key: 1,
  class: "error-message"
}
const _hoisted_19 = {
  class: "name-wrapper",
  style: {"margin-bottom":"0"}
}
const _hoisted_20 = { class: "tooltip-wrapper" }
const _hoisted_21 = { class: "input-label" }
const _hoisted_22 = {
  key: 0,
  class: "price"
}
const _hoisted_23 = {
  key: 1,
  class: "price"
}
const _hoisted_24 = {
  key: 2,
  class: "price"
}
const _hoisted_25 = { class: "input-description" }
const _hoisted_26 = { class: "text-field-wrapper" }
const _hoisted_27 = { class: "plus-minus" }
const _hoisted_28 = { class: "button-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_gs_button = _resolveComponent("gs-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "user-image",
        src: require('@/assets/add-ons-shape.svg'),
        alt: "signup"
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("img", {
            class: "chevron",
            alt: "chevron",
            src: require('@/assets/icons/chevron_left.svg'),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('backClick')))
          }, null, 8, _hoisted_6),
          _createElementVNode("div", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('backClick')))
          }, _toDisplayString(_ctx.t('back')), 1)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.t('header')), 1),
          (_ctx.$route.name !== 'SignupFamily')
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createTextVNode(_toDisplayString(_ctx.t('plastic_addon')) + " ", 1),
                    (_ctx.account.currency === 'euro')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_12, "(€5,75)"))
                      : _createCommentVNode("", true),
                    (_ctx.account.currency === 'pound')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_13, "(£5.10)"))
                      : _createCommentVNode("", true),
                    (_ctx.account.currency === 'dollar')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_14, "($6.25)"))
                      : _createCommentVNode("", true)
                  ]),
                  _createVNode(_component_Tooltip, {
                    class: "tooltip",
                    body: _ctx.t('plastic_addon_tooltip'),
                    "link-text": 'Source',
                    link: 'https://ourworldindata.org/plastic-pollution'
                  }, null, 8, ["body"])
                ]),
                _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.t('plastic_addon_description')), 1),
                _createElementVNode("div", _hoisted_16, [
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.plasticAddon,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.plasticAddon) = $event)),
                    modelModifiers: { number: true },
                    label: _ctx.t('add_plastic'),
                    variant: "outlined",
                    type: "number",
                    flat: "",
                    min: "0",
                    color: "#3B755F",
                    class: "employee-input"
                  }, null, 8, ["modelValue", "label"]),
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("div", {
                      class: "add",
                      style: {"margin-bottom":"1px"},
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.plasticAddon !== null ? _ctx.plasticAddon++ : (_ctx.plasticAddon = 1)))
                    }, " + "),
                    _createElementVNode("div", {
                      class: "add",
                      style: {"margin-top":"1px"},
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.plasticAddon !== null && _ctx.plasticAddon !== 0 ? _ctx.plasticAddon-- : ''))
                    }, " - ")
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.error)
            ? (_openBlock(), _createElementBlock("div", _hoisted_18, _toDisplayString(_ctx.t('error')), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("div", _hoisted_21, [
                _createTextVNode(_toDisplayString(_ctx.t('tree_addon')) + " ", 1),
                (_ctx.account.currency === 'euro')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_22, "(€0,35 / Tree)"))
                  : _createCommentVNode("", true),
                (_ctx.account.currency === 'pound')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_23, "(£0.32 / Tree)"))
                  : _createCommentVNode("", true),
                (_ctx.account.currency === 'dollar')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_24, "($0,35 / Tree)"))
                  : _createCommentVNode("", true)
              ]),
              _createVNode(_component_Tooltip, {
                class: "tooltip",
                body: _ctx.t('tree_addon_tooltip'),
                "link-text": 'Source',
                link: 'https://science.sciencemag.org/content/365/6448/76'
              }, null, 8, ["body"])
            ]),
            _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.t('tree_addon_description')), 1),
            _createElementVNode("div", _hoisted_26, [
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.treeAddon,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.treeAddon) = $event)),
                modelModifiers: { number: true },
                label: _ctx.t('add_trees'),
                variant: "outlined",
                type: "number",
                flat: "",
                min: "0",
                color: "#3B755F",
                class: "employee-input"
              }, null, 8, ["modelValue", "label"]),
              _createElementVNode("div", _hoisted_27, [
                _createElementVNode("div", {
                  class: "add",
                  style: {"margin-bottom":"1px"},
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.treeAddon !== null ? _ctx.treeAddon++ : (_ctx.treeAddon = 1)))
                }, " + "),
                _createElementVNode("div", {
                  class: "add",
                  style: {"margin-top":"1px"},
                  onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.treeAddon !== null && _ctx.treeAddon !== 0 ? _ctx.treeAddon-- : ''))
                }, " - ")
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_28, [
            _createVNode(_component_gs_button, {
              disabled: !_ctx.buttonActive,
              "full-width": true,
              size: "large",
              onClick: _withModifiers(_ctx.onNextClicked, ["prevent"])
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('continue')), 1)
              ]),
              _: 1
            }, 8, ["disabled", "onClick"])
          ])
        ])
      ])
    ])
  ]))
}