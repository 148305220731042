<template>
  <component :is="componentName" :icon-style="iconStyle" />
</template>

<script lang="ts">
import type { Component, PropType } from 'vue'
import { defineComponent } from 'vue'

import type { IconStyleType, OffsetType } from '@/helpers/interfaces'

import CarbonImpactIcon from '@/components/icons/ImpactIcons/CarbonImpactIcon.vue'
import KelpImpactIcon from '@/components/icons/ImpactIcons/KelpImpactIcon.vue'
import PlasticImpactIcon from '@/components/icons/ImpactIcons/PlasticImpactIcon.vue'
import TreesImpactIcon from '@/components/icons/ImpactIcons/TreesImpactIcon.vue'
import WaterImpactIcon from '@/components/icons/ImpactIcons/WaterImpactIcon.vue'
import BeesImpactIcon from '@/components/icons/ImpactIcons/BeesImpactIcon.vue'

const impactIcons: Record<OffsetType, Component> = {
  carbon: CarbonImpactIcon,
  trees: TreesImpactIcon,
  plastic: PlasticImpactIcon,
  kelp: KelpImpactIcon,
  water: WaterImpactIcon,
  bees: BeesImpactIcon,
}

export default defineComponent({
  name: 'ImpactIcon',
  components: {
    CarbonImpactIcon,
    KelpImpactIcon,
    PlasticImpactIcon,
    TreesImpactIcon,
    WaterImpactIcon,
    BeesImpactIcon,
  },
  computed: {
    componentName(): Component {
      return impactIcons[this.iconType]
    },
  },
  props: {
    iconType: {
      type: String as PropType<OffsetType>,
      required: true,
    },
    iconStyle: {
      type: String as PropType<IconStyleType>,
      required: false,
      default: 'flat',
    },
  },
})
</script>
