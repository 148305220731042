import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "dialog-wrapper"
}
const _hoisted_2 = { class: "close-wrapper" }
const _hoisted_3 = { class: "dialog-title" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Partners = _resolveComponent("Partners")!

  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t('edit')), 1),
            _createElementVNode("img", {
              style: {"width":"25px"},
              class: "close-icon",
              src: require('@/assets/icons/close_icon.svg'),
              alt: "close",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('dialogClick')))
            }, null, 8, _hoisted_4)
          ]),
          _createVNode(_component_Partners, {
            "is-edit": _ctx.isEdit,
            "is-contact-edit": _ctx.isContactEdit,
            "edit-items": _ctx.editItems,
            "relations-id": _ctx.relationsId,
            onGetFreshDataClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('getFreshDataClick'))),
            onDialogClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('dialogClick'))),
            onShowErrorAlert: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('showErrorAlert'))),
            onShowSuccessAlert: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('showSuccessAlert')))
          }, null, 8, ["is-edit", "is-contact-edit", "edit-items", "relations-id"])
        ])
      ]))
    : _createCommentVNode("", true)
}