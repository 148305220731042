<template>
  <div class="dialog-container">
    <div class="dialog-content-wrapper">
      <div class="brand-title">
        <div class="image-wrapper">
          <img
            class="brand-logo"
            height="95"
            width="95"
            :src="brand.imageURL || require('@/assets/icons/greenspark-logo-vertical-green.svg')"
            :alt="brand.companyName"
          />
        </div>
        <h2 class="brand-name">
          {{ brand.companyName }}
        </h2>
      </div>

      <ImpactCards :impacts="brand.impacts" />
      <LoaderBlock v-if="loading" class="mb-4" background-color="transparent" />
      <div v-else class="orders-by-brand">
        <div class="orders-by-brand-header">
          <h3 class="order-by-brand-title">
            <v-icon class="shopping-icon"> mdi-shopping-outline </v-icon>
            {{ t('orders_by_brand_title') }}
          </h3>
          <div class="order-counter">
            <svg>
              <use href="#greenspark-logo" />
            </svg>
            <span>{{ count }}</span>
          </div>
        </div>

        <ul class="order-list">
          <li v-for="(order, index) in orders" :key="index">
            <button class="order-list-item" @click="openOrderDetails(index)">
              <div class="order-info">
                <img
                  class="order-brand-logo"
                  height="70"
                  width="70"
                  :src="
                    order.imageURL || require('@/assets/icons/greenspark-logo-vertical-green.svg')
                  "
                  :alt="order.sellerUsername"
                />
                <div class="order-text-wrapper">
                  <p class="order-text">
                    <v-icon class="v-icon">
                      {{ getIconName(order.type) }}
                    </v-icon>
                    {{ getLabel(order) }}
                  </p>
                  <p class="order-text">
                    <v-icon class="v-icon"> mdi-calendar </v-icon>
                    {{ new Date(order.action.createdAt).toLocaleDateString(userLocale || 'en') }}
                  </p>
                </div>
              </div>

              <div class="impact-info">
                <impact-pill
                  v-for="(impact, key) in order.action.impacts"
                  :key="key"
                  :impact="{ type: impact.type || 'multiple', amount: impact.amount }"
                />
              </div>
            </button>
          </li>
        </ul>
        <v-pagination
          v-if="paginationLength > 1"
          v-model="page"
          class="mt-8"
          :length="paginationLength"
          color="#3B755F"
          @update:model-value="getOrders"
        />
      </div>

      <div v-if="brand?.profileDescription && !loading" class="brand-intro">
        <div class="intro-text-wrapper">
          <h3 class="order-by-brand-title">
            <v-icon class="shopping-icon intro"> mdi-lightbulb-outline </v-icon>
            {{ t('brand_intro_title', { companyName: brand.companyName }) }}
          </h3>
          <p class="brand-intro-text">
            {{ brand.profileDescription }}
          </p>
        </div>
        <a :href="brand.url" target="_blank" class="brand-intro-link">
          {{ t('intro_link_text') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { ImpactAction, ImpactByBrand } from '@/helpers/interfaces'
import ImpactCards from '@/components/common/ImpactCards.vue'
import { getImpactActions } from '@api/index'
import ImpactPill from '@/components/ImpactWallet/ImpactPill.vue'
import LoaderBlock from '@/components/tools/LoaderBlock.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BrandDetailDialog',
  emits: ['open-order-details'],
  components: { LoaderBlock, ImpactPill, ImpactCards },
  data() {
    return {
      orders: [],
      page: 1,
      count: 0,
      paginationLength: 0,
      limit: 4,
      loading: false,
    } as {
      orders: ImpactAction[]
      page: number
      count: number
      paginationLength: number
      limit: number
      loading: boolean
    }
  },
  created() {
    this.getOrders()
  },
  methods: {
    t(key: string, params?: { [key: string]: string }) {
      return this.$t(`BrandDetailDialog.${key}`, params ?? {})
    },
    tc(key: string, amount?: number) {
      return this.$t(`BrandDetailDialog.${key}`, amount ?? 0)
    },
    openOrderDetails(index: number) {
      const popup = document.getElementById('fullWidthPopup') as HTMLElement
      popup.scrollTo(0, 0)
      this.$emit('open-order-details', this.orders[index])
    },
    async getOrders() {
      this.loading = true
      const skip = (this.page - 1) * this.limit
      const impactAction = await getImpactActions(skip, this.limit, this.brand?.userId)
      this.orders = impactAction.data
      this.count = impactAction.count
      this.paginationLength = Math.ceil(impactAction.count / this.limit)
      this.loading = false
    },
    getIconName(orderType): string {
      switch (orderType) {
        case 'ORDER_CREATED':
          return 'mdi-basket-outline'
        case 'PER_SUBSCRIBER':
          return 'mdi-email-check-outline'
        case 'PER_PRODUCT_REVIEW':
          return 'mdi-message-draw'
        case 'PER_COMPANY_REVIEW':
          return 'mdi-message-draw'
        case 'INVOICE':
          return 'mdi-cart-outline'
        case 'GIFT':
          return 'mdi-gift-outline'
        case 'REDEEM_LOYALTY_POINTS':
          return 'mdi-star'
        default:
          return 'mdi-basket-outline'
      }
    },
    getLabel(order: ImpactAction) {
      switch (order.type) {
        case 'ORDER_CREATED':
          return this.tc('product_amount_in_order', order.action.lineItems?.length ?? 0)
        case 'PER_SUBSCRIBER':
          return this.t('per_subscriber')
        case 'PER_PRODUCT_REVIEW':
          return this.t('per_product_review')
        case 'PER_COMPANY_REVIEW':
          return this.t('per_company_review')
        case 'INVOICE':
          return this.t('purchase')
        case 'GIFT':
          return this.t('gift')
        case 'REDEEM_LOYALTY_POINTS':
          return this.t('loyalty_points')
        default:
          return this.t('per_company_review')
      }
    },
  },
  props: {
    brand: { type: Object as () => ImpactByBrand, required: true },
  },
  computed: {
    userLocale(): string {
      return this.$store.getters['getUserLocale']
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.dialog-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.brand-logo {
  border-radius: 50%;
  margin-bottom: 10px;
  object-fit: contain;
}

.brand-name {
  margin-top: -10px;
}

.dialog-content-wrapper {
  width: 100%;
}

.brand-title {
  align-items: center;
  background: url('../../assets/backgrounds/brand-detail-background.svg') var(--ui-beige) no-repeat
    center/cover;
  border-radius: var(--border-radius-big);
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 40px;
  width: 100%;
}

.image-wrapper {
  align-items: center;
  background: url('../../assets/backgrounds/brand-detail-logo-background.svg') no-repeat
    center/contain;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 180px;
  width: 100%;
}

.orders-by-brand {
  margin-bottom: 40px;
}

.orders-by-brand-header {
  align-items: baseline;
  display: flex;
  font-size: 24px;
  justify-content: space-between;
  margin-bottom: 20px;
}

.order-by-brand-title {
  align-items: center;
  display: flex;
  font-size: 20px;
}

.shopping-icon {
  color: var(--font-color-primary);
  margin-right: 5px;
}

.shopping-icon.intro {
  display: none;
}

.order-counter {
  align-items: center;
  font-size: inherit;
  display: flex;
}

.order-counter svg {
  height: 24px;
  width: 24px;
  margin-right: 5px;
}

.order-list {
  padding: 0;
  list-style-type: none;
  width: 100%;
}

.order-list-item {
  display: flex;
  background-color: var(--ui-beige);
  border-radius: var(--border-radius-big);
  box-shadow: var(--box-shadow-light);
  flex-direction: column;
  padding: 16px 20px;
  position: relative;
  margin-bottom: 20px;
  gap: 20px;
  width: 100%;
}

.order-brand-logo {
  border-radius: 50%;
  margin-right: 20px;
  object-fit: contain;
}

.order-info {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.order-text-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.order-text {
  align-items: center;
  display: flex;
  margin: 0;
}

.order-text svg {
  height: 24px;
  width: 24px;
  margin-right: 5px;
}

.order-text .v-icon {
  color: var(--font-color-primary);
  margin-right: 5px;
}

.impact-info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

button.order-list-item {
  box-shadow: none;
}

button.order-list-item:hover {
  box-shadow: var(--box-shadow-light);
}

button.order-list-item::after {
  border-right: 2px solid var(--ui-green);
  border-top: 2px solid var(--ui-green);
  bottom: 50%;
  content: '';
  display: block;
  flex-shrink: 0;
  height: 12px;
  margin: 0 10px 0 auto;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(-50%, -50%) rotate(40deg);
  width: 12px;
}

button.order-list-item:hover::after {
  opacity: 0;
  transform: translate(calc(-50% + 10px), -50%) rotate(40deg);
  transition: all linear 0.2s;
}

button.order-list-item::before {
  border-right: 2px solid var(--ui-green);
  border-top: 2px solid var(--ui-green);
  bottom: 50%;
  content: '';
  display: block;
  height: 12px;
  opacity: 0;
  position: absolute;
  right: 34px;
  top: 50%;
  transform: translate(-50%, -50%) rotate(40deg);
  width: 12px;
}

button.order-list-item:hover::before {
  opacity: 1;
  transform: translate(calc(-50% + 14px), -50%) rotate(40deg);
  transition: all linear 0.2s;
}

.brand-intro {
  align-items: flex-start;
  background: url('../../assets/backgrounds/brand-intro-background.svg') var(--ui-beige) no-repeat
    center/cover;
  border-radius: var(--border-radius-big);
  box-shadow: var(--box-shadow-light);
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  margin-bottom: 40px;
}

.brand-intro .intro-text-wrapper {
  text-align: center;
}

.intro-text-wrapper .order-by-brand-title {
  align-items: flex-start;
  margin-bottom: 10px;
  line-height: 30px;
}

.brand-intro-link {
  background-color: var(--ui-green);
  border-radius: 4px;
  color: var(--ui-white);
  display: flex;
  justify-content: center;
  text-decoration: none;
  flex-shrink: 0;
  font-weight: 500;
  text-transform: uppercase;
  padding: 10px 0;
  width: 100%;
}

.brand-intro-link:hover {
  opacity: 0.8;
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .order-list-item {
    flex-direction: row;
    justify-content: space-between;
    padding: 16px 54px 16px 20px;
  }

  .brand-intro {
    flex-direction: row;
    justify-content: space-between;
  }

  .brand-intro .intro-text-wrapper {
    text-align: left;
    width: 60%;
  }

  .shopping-icon.intro {
    display: block;
  }

  .brand-intro-link {
    width: 30%;
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .dialog-content-wrapper {
    max-width: 770px;
  }

  .order-by-brand-title {
    font-size: 24px;
  }

  .order-info {
    border: none;
    justify-content: flex-start;
    flex-wrap: nowrap;
  }

  button.order-list-item::after {
    transform: translate(-50%, -50%) rotate(40deg);
  }
}
</style>
