<template>
  <div>
    <v-navigation-drawer
      v-model="sidebarMenu"
      floating
      color="#F9F9F9"
      :permanent="sidebarMenu"
      :rail="toggleMini"
    >
      <template v-slot:prepend>
        <div class="logo-wrapper">
          <img
            v-if="!toggleMini"
            class="logo"
            :src="require('@/assets/greenspark-logo.svg')"
            alt="greenspark-app"
          />

          <img
            v-if="toggleMini"
            class="logo-small"
            :src="require('@/assets/greenspark-logo.svg')"
            alt="greenspark-app"
          />
        </div>
      </template>

      <v-divider />

      <div class="business-name">Admin</div>

      <v-divider />

      <div class="central-menu-items">
        <div
          class="menu-item"
          :style="activeMenuItem === 0 ? 'background-color: #dddcdc' : ''"
          @click="$router.push('/admin/home')"
        >
          <div class="menu-item-text">Home</div>
        </div>

        <div
          class="menu-item"
          :style="activeMenuItem === 1 ? 'background-color: #dddcdc' : ''"
          @click="$router.push('/admin/ledger')"
        >
          <div class="menu-item-text">Public Ledger</div>
        </div>

        <div
          class="menu-item"
          :style="activeMenuItem === 2 ? 'background-color: #dddcdc' : ''"
          @click="$router.push('/admin/accounts')"
        >
          <div class="menu-item-text">Add Transferred Impacts</div>
        </div>
        <div
          class="menu-item"
          :style="activeMenuItem === 3 ? 'background-color: #dddcdc' : ''"
          @click="$router.push('/admin/change-email')"
        >
          <div class="menu-item-text">Change Email</div>
        </div>
        <div
          class="menu-item"
          :style="activeMenuItem === 4 ? 'background-color: #dddcdc' : ''"
          @click="$router.push('/admin/reward')"
        >
          <div class="menu-item-text">Give Reward</div>
        </div>
        <div
          :style="activeMenuItem === 5 ? 'background-color: #dddcdc' : ''"
          class="menu-item"
          @click="$router.push('/admin/upload-shopify-payout')"
        >
          <div class="menu-item-text">Upload Shopify Payout</div>
        </div>
        <div
          class="menu-item"
          :style="activeMenuItem === 6 ? 'background-color: #dddcdc' : ''"
          @click="$router.push('/admin/delete-subscription')"
        >
          <div class="menu-item-text">Delete Subscription</div>
        </div>
        <div
          :style="activeMenuItem === 7 ? 'background-color: #dddcdc' : ''"
          class="menu-item"
          @click="$router.push('/admin/create-script-tag')"
        >
          <div class="menu-item-text">Create Script Tag</div>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script lang="ts">
import type { Account } from '@/store'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Sidebar',
  data() {
    return {
      sidebarMenu: true,
      toggleMini: false,
      activeMenuItem: 0,
    }
  },
  created() {
    this.routeCheck()
  },
  methods: {
    t(key: string) {
      return this.$t(`Sidebar.${key}`)
    },
    routeCheck() {
      this.setCoulour()
    },
    setCoulour() {
      switch (this.$route.name) {
        case 'AdminHome':
          this.activeMenuItem = 0
          break
        case 'UpdateLedger':
          this.activeMenuItem = 1
          break
        case 'AccountsView':
          this.activeMenuItem = 2
          break
        case 'ChangeEmailView':
          this.activeMenuItem = 3
          break
        case 'GiveRewardView':
          this.activeMenuItem = 4
          break
        case 'UploadShopifyPayoutView':
          this.activeMenuItem = 5
          break
        case 'DeleteSubscriptionView':
          this.activeMenuItem = 6
          break
        case 'CreateScriptTagView':
          this.activeMenuItem = 7
          break
      }
    },
    onBreakpointChange() {
      if (this.$vuetify.display.smAndDown) {
        this.toggleMini = true
      }

      if (this.$vuetify.display.mdAndUp) {
        this.toggleMini = false
      }
    },
    onRouteChange() {
      this.routeCheck()
    },
  },
  props: {
    breakpoint: {
      type: String,
    },
  },
  watch: {
    breakpoint: [
      {
        handler: 'onBreakpointChange',
      },
    ],
    '$route.name': [
      {
        handler: 'onRouteChange',
      },
    ],
  },
  computed: {
    account(): Account {
      return this.$store.getters['getAccount']
    },
  },
})
</script>

<style lang="scss" scoped>
.logo-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
}

.logo {
  max-width: 150px;
  margin-top: 10px;
}

.logo-small {
  width: 45px;
  margin-top: 20px;
  margin-bottom: 12px;
}

.business-logo {
  max-width: 200px;
  width: 150px;
  height: 150px;
  background: #ebebeb;
  margin-top: 15px;
  margin-left: 52px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  cursor: pointer;
}

.upload {
  position: relative;
  top: -60px;
  left: -20px;
}

.business-name {
  text-align: center;
  margin-top: 12px;
  font-size: 32px;
  line-height: 32px;
}

.member-since {
  text-align: center;
  font-size: 11px;
  margin-bottom: 18px;
  margin-top: 7px;
}

.icon {
  margin-right: 15px;
}

.menu-item {
  display: flex;
  align-items: center;
  padding-left: 24px;
  padding-top: 14px;
  padding-bottom: 14px;
  font-weight: 500;
  cursor: pointer;
}

.menu-item:hover {
  background-color: #3b755f17;
}
</style>
