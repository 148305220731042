import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "label" }
const _hoisted_2 = { class: "summary-pills" }

import OnboardingPill from '@/components/onboarding/OnboardingPill.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'StepSummary',
  props: {
    label: {},
    previousAnswers: {},
    hasBottomSeparator: { type: Boolean, default: true }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(['step-summary', { 'bottom-separator': _ctx.hasBottomSeparator }])
  }, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.label), 1),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.previousAnswers?.filter((a) => !!a), (answer, index) => {
        return (_openBlock(), _createBlock(OnboardingPill, { key: index }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(answer), 1)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ])
  ], 2))
}
}

})