import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "dialog-container" }
const _hoisted_2 = { class: "dialog-content-wrapper" }
const _hoisted_3 = { class: "brand-title" }
const _hoisted_4 = { class: "image-wrapper" }
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = { class: "brand-name" }
const _hoisted_7 = {
  key: 1,
  class: "orders-by-brand"
}
const _hoisted_8 = { class: "orders-by-brand-header" }
const _hoisted_9 = { class: "order-by-brand-title" }
const _hoisted_10 = { class: "order-counter" }
const _hoisted_11 = { class: "order-list" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "order-info" }
const _hoisted_14 = ["src", "alt"]
const _hoisted_15 = { class: "order-text-wrapper" }
const _hoisted_16 = { class: "order-text" }
const _hoisted_17 = { class: "order-text" }
const _hoisted_18 = { class: "impact-info" }
const _hoisted_19 = {
  key: 2,
  class: "brand-intro"
}
const _hoisted_20 = { class: "intro-text-wrapper" }
const _hoisted_21 = { class: "order-by-brand-title" }
const _hoisted_22 = { class: "brand-intro-text" }
const _hoisted_23 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImpactCards = _resolveComponent("ImpactCards")!
  const _component_LoaderBlock = _resolveComponent("LoaderBlock")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_impact_pill = _resolveComponent("impact-pill")!
  const _component_v_pagination = _resolveComponent("v-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("img", {
            class: "brand-logo",
            height: "95",
            width: "95",
            src: _ctx.brand.imageURL || require('@/assets/icons/greenspark-logo-vertical-green.svg'),
            alt: _ctx.brand.companyName
          }, null, 8, _hoisted_5)
        ]),
        _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.brand.companyName), 1)
      ]),
      _createVNode(_component_ImpactCards, {
        impacts: _ctx.brand.impacts
      }, null, 8, ["impacts"]),
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_LoaderBlock, {
            key: 0,
            class: "mb-4",
            "background-color": "transparent"
          }))
        : (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("h3", _hoisted_9, [
                _createVNode(_component_v_icon, { class: "shopping-icon" }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode(" mdi-shopping-outline ")
                  ])),
                  _: 1
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.t('orders_by_brand_title')), 1)
              ]),
              _createElementVNode("div", _hoisted_10, [
                _cache[2] || (_cache[2] = _createElementVNode("svg", null, [
                  _createElementVNode("use", { href: "#greenspark-logo" })
                ], -1)),
                _createElementVNode("span", null, _toDisplayString(_ctx.count), 1)
              ])
            ]),
            _createElementVNode("ul", _hoisted_11, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orders, (order, index) => {
                return (_openBlock(), _createElementBlock("li", { key: index }, [
                  _createElementVNode("button", {
                    class: "order-list-item",
                    onClick: ($event: any) => (_ctx.openOrderDetails(index))
                  }, [
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("img", {
                        class: "order-brand-logo",
                        height: "70",
                        width: "70",
                        src: 
                    order.imageURL || require('@/assets/icons/greenspark-logo-vertical-green.svg')
                  ,
                        alt: order.sellerUsername
                      }, null, 8, _hoisted_14),
                      _createElementVNode("div", _hoisted_15, [
                        _createElementVNode("p", _hoisted_16, [
                          _createVNode(_component_v_icon, { class: "v-icon" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.getIconName(order.type)), 1)
                            ]),
                            _: 2
                          }, 1024),
                          _createTextVNode(" " + _toDisplayString(_ctx.getLabel(order)), 1)
                        ]),
                        _createElementVNode("p", _hoisted_17, [
                          _createVNode(_component_v_icon, { class: "v-icon" }, {
                            default: _withCtx(() => _cache[3] || (_cache[3] = [
                              _createTextVNode(" mdi-calendar ")
                            ])),
                            _: 1
                          }),
                          _createTextVNode(" " + _toDisplayString(new Date(order.action.createdAt).toLocaleDateString(_ctx.userLocale || 'en')), 1)
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_18, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(order.action.impacts, (impact, key) => {
                        return (_openBlock(), _createBlock(_component_impact_pill, {
                          key: key,
                          impact: { type: impact.type || 'multiple', amount: impact.amount }
                        }, null, 8, ["impact"]))
                      }), 128))
                    ])
                  ], 8, _hoisted_12)
                ]))
              }), 128))
            ]),
            (_ctx.paginationLength > 1)
              ? (_openBlock(), _createBlock(_component_v_pagination, {
                  key: 0,
                  modelValue: _ctx.page,
                  "onUpdate:modelValue": [
                    _cache[0] || (_cache[0] = ($event: any) => ((_ctx.page) = $event)),
                    _ctx.getOrders
                  ],
                  class: "mt-8",
                  length: _ctx.paginationLength,
                  color: "#3B755F"
                }, null, 8, ["modelValue", "length", "onUpdate:modelValue"]))
              : _createCommentVNode("", true)
          ])),
      (_ctx.brand?.profileDescription && !_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("h3", _hoisted_21, [
                _createVNode(_component_v_icon, { class: "shopping-icon intro" }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode(" mdi-lightbulb-outline ")
                  ])),
                  _: 1
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.t('brand_intro_title', { companyName: _ctx.brand.companyName })), 1)
              ]),
              _createElementVNode("p", _hoisted_22, _toDisplayString(_ctx.brand.profileDescription), 1)
            ]),
            _createElementVNode("a", {
              href: _ctx.brand.url,
              target: "_blank",
              class: "brand-intro-link"
            }, _toDisplayString(_ctx.t('intro_link_text')), 9, _hoisted_23)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}