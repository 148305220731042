import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "overlay-text overlay-text--full-width" }
const _hoisted_2 = { class: "mt-3 mb-0" }
const _hoisted_3 = { class: "d-flex flex-column my-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_gs_button = _resolveComponent("gs-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("strong", null, _toDisplayString(_ctx.t('code_snippet_tutorial.title')), 1),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.t('code_snippet_tutorial.description')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_v_textarea, {
        "model-value": _ctx.codeSnippet,
        rows: 5,
        "hide-details": "",
        "no-resize": "",
        readonly: "",
        variant: "outlined"
      }, null, 8, ["model-value"])
    ]),
    _createVNode(_component_gs_button, {
      capitalized: "",
      "full-width": "",
      size: "large",
      type: "primary",
      onClick: _ctx.handleCopy
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t('code_snippet_tutorial.copy')), 1)
      ]),
      _: 1
    }, 8, ["onClick"])
  ]))
}