import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "add-month-wrapper" }
const _hoisted_2 = { class: "button-text-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_gs_button = _resolveComponent("gs-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[4] || (_cache[4] = _createTextVNode(" First add the month & receipt type to then attach the appropriate receipts. Please follow our standard naming convention of: ")),
    _cache[5] || (_cache[5] = _createElementVNode("strong", null, "Month, Year (eg: May, 2021)", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_v_text_field, {
        modelValue: _ctx.month,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.month) = $event)),
        class: "input-field",
        label: "Add Month",
        variant: "outlined",
        flat: "",
        color: "#3B755F",
        style: {"margin-right":"5px"}
      }, null, 8, ["modelValue"]),
      _createVNode(_component_v_select, {
        modelValue: _ctx.type,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.type) = $event)),
        items: _ctx.items,
        label: "Receipt Type",
        variant: "outlined"
      }, null, 8, ["modelValue", "items"]),
      _createVNode(_component_gs_button, {
        class: "button",
        depressed: "",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.saveMonth())),
        disabled: _ctx.loading
      }, {
        default: _withCtx(() => [
          _cache[3] || (_cache[3] = _createTextVNode(" Save ")),
          (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_v_progress_circular, {
                key: 0,
                indeterminate: "",
                color: "#F9F9F9"
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["disabled"])
    ])
  ]))
}