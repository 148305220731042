<script lang="ts">
import type { CarbonComparison, OffsetType } from '@/helpers/interfaces'
import { Utils } from '@/helpers/mixins/utilsMixin'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CarbonComparison',
  mixins: [Utils],
  props: {
    comparisons: {
      required: true,
      type: Object as PropType<CarbonComparison>,
    },
    colorSettings: {
      required: true,
      type: Object as PropType<Record<OffsetType, string>>,
    },
  },
})
</script>

<template>
  <div class="carbon-comparison-container">
    <div class="plant-based comparison-card" :style="{ 'background-color': colorSettings.carbon }">
      <div class="comparison-data">
        <div class="comparison-amount">
          {{ formatTotalAmounts(comparisons.plantBased.amount, comparisons.plantBased.suffix) }}
        </div>
        <div class="info-text">
          {{ comparisons.plantBased.amountDescription }}
        </div>
        <a :href="comparisons.plantBased.link" target="_blank" class="info-sub-text">
          {{ comparisons.plantBased.description }}
        </a>
      </div>
      <div class="comparison-icon-wrapper">
        <img
          id="plantBasedComparisonIcon"
          class="comparison-icon"
          alt="plant-based"
          :src="require('@/assets/icons/comparisons/plant_based_v2.svg')"
        />
      </div>
    </div>
    <div class="flights comparison-card" :style="{ 'background-color': colorSettings.carbon }">
      <div class="comparison-icon-wrapper">
        <img
          id="flightsComparisonIcon"
          class="comparison-icon"
          alt="flights"
          :src="require('@/assets/icons/comparisons/flights_v2.svg')"
        />
      </div>
      <div class="comparison-data">
        <div class="comparison-amount">
          {{ formatTotalAmounts(comparisons.flights.amount, comparisons.flights.suffix) }}
        </div>
        <div class="info-text">
          {{ comparisons.flights.amountDescription }}
        </div>
        <a :href="comparisons.flights.link" target="_blank" class="info-sub-text">
          {{ comparisons.flights.description }}
        </a>
      </div>
    </div>
    <div class="distance comparison-card" :style="{ 'background-color': colorSettings.carbon }">
      <div class="comparison-data">
        <div class="comparison-amount">
          {{ formatTotalAmounts(comparisons.distance.amount, comparisons.distance.suffix) }}
        </div>
        <div class="info-text">
          {{ comparisons.distance.amountDescription }}
        </div>
        <a :href="comparisons.distance.link" target="_blank" class="info-sub-text">
          {{ comparisons.distance.description }}
        </a>
      </div>
      <div class="comparison-icon-wrapper">
        <img
          id="distanceComparisonIcon"
          class="comparison-icon"
          alt="distance"
          :src="require('@/assets/icons/comparisons/distance_v2.svg')"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '~vuetify/settings';

.carbon-comparison-container {
  display: grid;
  gap: 16px;
}

.comparison-card {
  display: grid;
  height: 170px;
  border-radius: 10px;
}

.comparison-card.plant-based {
  grid-template-columns: auto 100px;
  padding: 6px 11px 6px 22px;
}

.comparison-card.distance {
  grid-template-columns: auto 100px;
  padding: 6px 11px 6px 22px;
}

.comparison-card.flights {
  grid-template-columns: 90px auto;
  padding: 6px 22px 6px 11px;
}

.comparison-data {
  font-family: Cabin;
  display: grid;
  align-content: center;
}

.comparison-card:nth-child(even) .comparison-data {
  text-align: right;
}

.comparison-amount {
  color: var(--font-color-primary);
  font-size: 54px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
}

.info-text {
  line-height: normal;
  font-size: 16px;
  margin-bottom: 6px;
}

.info-sub-text {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  color: #212121;
}

.comparison-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.comparison-icon {
  position: relative;
}

.plant-based .comparison-icon {
  height: 152px;
  top: 14px;
}

.flights .comparison-icon {
  height: 74px;
  margin: auto;
  right: 6px;
}

.distance .comparison-icon {
  height: 74px;
  margin: auto;
  left: 8px;
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .comparison-card.plant-based {
    grid-template-columns: auto 140px;
  }

  .comparison-card.distance {
    grid-template-columns: auto 140px;
  }

  .comparison-card.flights {
    grid-template-columns: 120px auto;
  }

  .plant-based .comparison-icon {
    height: 160px;
    top: unset;
  }

  .flights .comparison-icon {
    height: 90px;
    margin: auto;
    right: unset;
  }

  .distance .comparison-icon {
    height: 88px;
    margin: auto;
    left: unset;
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .carbon-comparison-container {
    gap: 26px;
  }

  .plant-based.comparison-card {
    grid-template-columns: auto 140px;
  }

  .distance.comparison-card {
    grid-template-columns: auto 200px;
  }

  .flights.comparison-card {
    grid-template-columns: 200px auto;
  }

  .plant-based .comparison-icon {
    height: 170px;
  }

  .flights .comparison-icon {
    height: 136px;
  }

  .distance .comparison-icon {
    height: 116px;
  }
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
  .plant-based.comparison-card,
  .flights.comparison-card {
    height: 180px;
  }

  .distance.comparison-card {
    height: 360px;
    margin-top: auto;
  }

  .carbon-comparison-container {
    gap: 32px;
  }

  .plant-based.comparison-card,
  .flights.comparison-card {
    grid-column: 1 / span 2;
    height: auto;
    min-height: 180px;
  }

  .plant-based.comparison-card {
    grid-row: 1;
  }

  .distance.comparison-card {
    grid-column: 3;
    grid-row: 1 / span 2;
    min-height: 360px;
    grid-template-columns: auto;
    grid-template-rows: 100px auto;
    gap: 16px;
    padding: 6px 40px 22px 40px;
    width: 314px;
  }

  .flights.comparison-card {
    grid-row: 2;
  }

  .plant-based.comparison-card .comparison-data {
    grid-template-columns: 1fr auto;
    column-gap: 16px;
  }

  .flights.comparison-card .comparison-data {
    grid-template-columns: auto 1fr;
    column-gap: 16px;
  }

  .plant-based.comparison-card .comparison-data .info-sub-text {
    grid-column: 2;
  }

  .flights.comparison-card .comparison-data .info-sub-text {
    grid-column: 1 / span 2;
  }

  .plant-based.comparison-card .comparison-amount,
  .flights.comparison-card .comparison-amount {
    margin: auto;
  }

  .plant-based.comparison-card {
    grid-template-columns: auto 140px;
  }

  .flights.comparison-card {
    grid-template-columns: 200px auto;
  }

  .comparison-data .info-text {
    font-size: 24px;
  }

  .distance.comparison-card .comparison-data {
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: start;
  }

  .distance.comparison-card .comparison-data .info-text {
    flex: 1;
  }

  .distance .comparison-data {
    grid-row: 2;
    display: grid;
    justify-content: center;
    align-content: space-around;
    text-align: center;
    gap: 16px;
  }

  .distance .comparison-icon {
    top: -50px;
    height: 150px;
    grid-row: 1;
  }

  .flights .comparison-icon {
    height: 154px;
    right: 16px;
  }

  .plant-based .comparison-icon {
    height: 180px;
    top: 24px;
  }
}
</style>
