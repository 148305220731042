import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, renderList as _renderList, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "edit-automation" }
const _hoisted_2 = { class: "panel-header" }
const _hoisted_3 = { class: "pill-wrapper" }
const _hoisted_4 = { class: "panel-header" }
const _hoisted_5 = { class: "pill-wrapper" }
const _hoisted_6 = {
  key: 0,
  class: "toggle-edit-button"
}
const _hoisted_7 = { class: "automation-name-wrapper section" }
const _hoisted_8 = { class: "label-wrapper" }
const _hoisted_9 = { class: "label" }
const _hoisted_10 = { class: "description" }
const _hoisted_11 = { class: "panel-header" }
const _hoisted_12 = { class: "pill-wrapper" }
const _hoisted_13 = { class: "toggle-edit-button" }
const _hoisted_14 = { class: "automation-name-wrapper section" }
const _hoisted_15 = { class: "label-wrapper" }
const _hoisted_16 = { class: "label" }
const _hoisted_17 = { class: "description" }
const _hoisted_18 = { class: "label-wrapper" }
const _hoisted_19 = { class: "label" }
const _hoisted_20 = { class: "description" }
const _hoisted_21 = { class: "panel-header" }
const _hoisted_22 = { class: "pill-wrapper" }
const _hoisted_23 = { class: "toggle-edit-button" }
const _hoisted_24 = { class: "automation-name-wrapper section" }
const _hoisted_25 = { class: "label-wrapper" }
const _hoisted_26 = { class: "label" }
const _hoisted_27 = { class: "description" }
const _hoisted_28 = { class: "panel-header" }
const _hoisted_29 = {
  key: 0,
  class: "pill-wrapper"
}
const _hoisted_30 = { key: 0 }
const _hoisted_31 = { class: "toggle-edit-button" }
const _hoisted_32 = {
  key: 0,
  class: "impact-settings-per-question-wrapper"
}
const _hoisted_33 = { class: "select-project-wrapper section" }
const _hoisted_34 = { class: "label-wrapper" }
const _hoisted_35 = { class: "label choose" }
const _hoisted_36 = { class: "description" }
const _hoisted_37 = {
  key: 0,
  class: "additional-impact-setting section"
}
const _hoisted_38 = { class: "label-wrapper" }
const _hoisted_39 = { class: "label choose" }
const _hoisted_40 = { class: "description" }
const _hoisted_41 = {
  key: 0,
  class: "input-wrapper"
}
const _hoisted_42 = {
  key: 1,
  class: "input-wrapper"
}
const _hoisted_43 = {
  key: 1,
  class: "totals-wrapper"
}
const _hoisted_44 = {
  key: 0,
  class: "cart-items-text"
}
const _hoisted_45 = {
  key: 1,
  class: "total-text"
}
const _hoisted_46 = { class: "panel-header" }
const _hoisted_47 = { class: "pill-wrapper" }
const _hoisted_48 = { class: "schedule-label-wrapper" }
const _hoisted_49 = { class: "toggle-edit-button" }
const _hoisted_50 = { class: "schedule-picker section" }
const _hoisted_51 = { class: "label-wrapper" }
const _hoisted_52 = { class: "label choose" }
const _hoisted_53 = { class: "description" }
const _hoisted_54 = { class: "panel-header" }
const _hoisted_55 = { class: "pill-wrapper" }
const _hoisted_56 = { class: "toggle-edit-button" }
const _hoisted_57 = { class: "automation-name-wrapper section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_onboarding_pill = _resolveComponent("onboarding-pill")!
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_gs_input = _resolveComponent("gs-input")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_select_form_impact_source = _resolveComponent("select-form-impact-source")!
  const _component_ImpactSettingsPerQuestion = _resolveComponent("ImpactSettingsPerQuestion")!
  const _component_PerSelectedAnswerPostPurchaseEmailNotification = _resolveComponent("PerSelectedAnswerPostPurchaseEmailNotification")!
  const _component_impact_settings = _resolveComponent("impact-settings")!
  const _component_ProductTable = _resolveComponent("ProductTable")!
  const _component_RuleTable = _resolveComponent("RuleTable")!
  const _component_DateTimePicker = _resolveComponent("DateTimePicker")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_select_shopify_order_origin = _resolveComponent("select-shopify-order-origin")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_v_expansion_panels, {
      modelValue: _ctx.openedIndex,
      "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.openedIndex) = $event)),
      variant: "accordion",
      flat: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_expansion_panel, { readonly: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_expansion_panel_title, { "hide-actions": "" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _cache[24] || (_cache[24] = _createElementVNode("p", { class: "mb-0 panel-header-text" }, "Integration:", -1)),
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_onboarding_pill, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.getIntegrationPlatformName(_ctx.automation.platform)), 1)
                      ]),
                      _: 1
                    }),
                    (!_ctx.isCustomIntegration)
                      ? (_openBlock(), _createBlock(_component_onboarding_pill, { key: 0 }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.automation.integrationName), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_ctx.isCustomIntegration)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_v_expansion_panel, { readonly: "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_expansion_panel_title, { "hide-actions": "" }, {
                    default: _withCtx(({ expanded }) => [
                      _createElementVNode("div", _hoisted_4, [
                        _cache[29] || (_cache[29] = _createElementVNode("p", { class: "mb-0 panel-header-text" }, "Source details", -1)),
                        _createElementVNode("div", _hoisted_5, [
                          _createVNode(_component_onboarding_pill, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.automation?.sourceDetails?.sourceName), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_onboarding_pill, { "is-copy-enabled": true }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.automation?.sourceDetails?.sourceId), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        (!_ctx.isIntegratorIntegration)
                          ? (_openBlock(), _createElementBlock("button", _hoisted_6, [
                              (!expanded)
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                    _cache[26] || (_cache[26] = _createTextVNode(" Edit ")),
                                    _createVNode(_component_v_icon, { class: "toggle-edit-button__icon" }, {
                                      default: _withCtx(() => _cache[25] || (_cache[25] = [
                                        _createTextVNode(" mdi-pencil-outline ")
                                      ])),
                                      _: 1
                                    })
                                  ], 64))
                                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                    _cache[28] || (_cache[28] = _createTextVNode(" Cancel ")),
                                    _createVNode(_component_v_icon, { class: "toggle-edit-button__icon" }, {
                                      default: _withCtx(() => _cache[27] || (_cache[27] = [
                                        _createTextVNode(" mdi-close-circle-outline ")
                                      ])),
                                      _: 1
                                    })
                                  ], 64))
                            ]))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 1
                  }),
                  (!_ctx.isIntegratorIntegration)
                    ? (_openBlock(), _createBlock(_component_v_expansion_panel_text, { key: 0 }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_7, [
                            _createElementVNode("div", _hoisted_8, [
                              _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.t('source_label')), 1),
                              _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.t('source_tooltip')), 1)
                            ]),
                            _createVNode(_component_v_form, {
                              modelValue: _ctx.isAutomationNameValid,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isAutomationNameValid) = $event))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_gs_input, {
                                  modelValue: _ctx.localSourceDetails.sourceName,
                                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localSourceDetails.sourceName) = $event)),
                                  class: "automation-name-input",
                                  label: _ctx.t('source_name'),
                                  "hide-details": false,
                                  rules: [_ctx.rules.required]
                                }, null, 8, ["modelValue", "label", "rules"]),
                                _createVNode(_component_gs_button, {
                                  size: "large",
                                  "full-width": "",
                                  disabled: _ctx.loading || !_ctx.isAutomationNameValid,
                                  onClick: _withModifiers(_ctx.editSource, ["prevent"])
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.t('save')), 1)
                                  ]),
                                  _: 1
                                }, 8, ["disabled", "onClick"])
                              ]),
                              _: 1
                            }, 8, ["modelValue"])
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_v_expansion_panel, { readonly: "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_expansion_panel_title, { "hide-actions": "" }, {
                    default: _withCtx(({ expanded }) => [
                      _createElementVNode("div", _hoisted_11, [
                        _cache[34] || (_cache[34] = _createElementVNode("p", { class: "mb-0 panel-header-text" }, "Trigger details", -1)),
                        _createElementVNode("div", _hoisted_12, [
                          _createVNode(_component_onboarding_pill, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.automation.name), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_onboarding_pill, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.automation.trigger), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_onboarding_pill, { "is-copy-enabled": true }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.automation.id), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _createElementVNode("button", _hoisted_13, [
                          (!expanded)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _cache[31] || (_cache[31] = _createTextVNode(" Edit ")),
                                _createVNode(_component_v_icon, { class: "toggle-edit-button__icon" }, {
                                  default: _withCtx(() => _cache[30] || (_cache[30] = [
                                    _createTextVNode(" mdi-pencil-outline ")
                                  ])),
                                  _: 1
                                })
                              ], 64))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _cache[33] || (_cache[33] = _createTextVNode(" Cancel ")),
                                _createVNode(_component_v_icon, { class: "toggle-edit-button__icon" }, {
                                  default: _withCtx(() => _cache[32] || (_cache[32] = [
                                    _createTextVNode(" mdi-close-circle-outline ")
                                  ])),
                                  _: 1
                                })
                              ], 64))
                        ])
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_expansion_panel_text, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("div", _hoisted_15, [
                          _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.t('trigger_label')), 1),
                          _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.t('custom_trigger_name_tooltip')), 1)
                        ]),
                        _createVNode(_component_v_form, {
                          modelValue: _ctx.isAutomationNameValid,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isAutomationNameValid) = $event))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_gs_input, {
                              modelValue: _ctx.localAutomation.name,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localAutomation.name) = $event)),
                              class: "automation-name-input",
                              label: _ctx.t('trigger_name'),
                              "hide-details": false,
                              rules: [_ctx.rules.required]
                            }, null, 8, ["modelValue", "label", "rules"]),
                            _createElementVNode("div", _hoisted_18, [
                              _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.t('trigger_type_label')), 1),
                              _createElementVNode("p", _hoisted_20, _toDisplayString(_ctx.t('trigger_type_help')), 1)
                            ]),
                            _createVNode(_component_v_select, {
                              modelValue: _ctx.localAutomation.trigger,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localAutomation.trigger) = $event)),
                              variant: "outlined",
                              flat: "",
                              color: "green",
                              items: _ctx.triggerTypes,
                              class: "automation-name-input",
                              label: _ctx.t('trigger_type'),
                              rules: [_ctx.rules.required]
                            }, null, 8, ["modelValue", "items", "label", "rules"]),
                            _createVNode(_component_gs_button, {
                              size: "large",
                              "full-width": "",
                              disabled: _ctx.loading || !_ctx.isAutomationNameValid,
                              onClick: _withModifiers(_ctx.editTrigger, ["prevent"])
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t('save')), 1)
                              ]),
                              _: 1
                            }, 8, ["disabled", "onClick"])
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ], 64))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createVNode(_component_v_expansion_panel, { readonly: "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_expansion_panel_title, { "hide-actions": "" }, {
                    default: _withCtx(({ expanded }) => [
                      _createElementVNode("div", _hoisted_21, [
                        _cache[39] || (_cache[39] = _createElementVNode("p", { class: "mb-0 panel-header-text" }, "Automation", -1)),
                        _createElementVNode("div", _hoisted_22, [
                          _createVNode(_component_onboarding_pill, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.automation.name), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_onboarding_pill, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t(
                        `IntegrationMixin.${
                          _ctx.hasAllProductsModifier
                            ? 'offset_all_products'
                            : _ctx.snakeCase(_ctx.automation.trigger)
                        }`,
                      )), 1)
                            ]),
                            _: 1
                          }),
                          (_ctx.isFormIntegration)
                            ? (_openBlock(), _createBlock(_component_onboarding_pill, { key: 0 }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.formSourceId), 1)
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("button", _hoisted_23, [
                          (!expanded)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _cache[36] || (_cache[36] = _createTextVNode(" Edit ")),
                                _createVNode(_component_v_icon, { class: "toggle-edit-button__icon" }, {
                                  default: _withCtx(() => _cache[35] || (_cache[35] = [
                                    _createTextVNode(" mdi-pencil-outline ")
                                  ])),
                                  _: 1
                                })
                              ], 64))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _cache[38] || (_cache[38] = _createTextVNode(" Cancel ")),
                                _createVNode(_component_v_icon, { class: "toggle-edit-button__icon" }, {
                                  default: _withCtx(() => _cache[37] || (_cache[37] = [
                                    _createTextVNode(" mdi-close-circle-outline ")
                                  ])),
                                  _: 1
                                })
                              ], 64))
                        ])
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_expansion_panel_text, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_24, [
                        _createElementVNode("div", _hoisted_25, [
                          _createElementVNode("p", _hoisted_26, _toDisplayString(_ctx.t('name_automation')), 1),
                          _createElementVNode("p", _hoisted_27, _toDisplayString(_ctx.t('automation_tooltip')), 1)
                        ]),
                        _createVNode(_component_v_form, {
                          modelValue: _ctx.isAutomationNameValid,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.isAutomationNameValid) = $event))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_gs_input, {
                              modelValue: _ctx.localAutomation.name,
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.localAutomation.name) = $event)),
                              class: "automation-name-input",
                              label: _ctx.t('automation_name'),
                              "hide-details": false,
                              rules: [_ctx.rules.required]
                            }, null, 8, ["modelValue", "label", "rules"]),
                            (_ctx.isFormIntegration)
                              ? (_openBlock(), _createBlock(_component_select_form_impact_source, {
                                  key: 0,
                                  modelValue: _ctx.localFormImpactSource,
                                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.localFormImpactSource) = $event)),
                                  platform: _ctx.automation.platform,
                                  error: _ctx.formImpactSourceError,
                                  class: "select-form-impact-source"
                                }, null, 8, ["modelValue", "platform", "error"]))
                              : _createCommentVNode("", true),
                            _createVNode(_component_gs_button, {
                              size: "large",
                              "full-width": "",
                              disabled: _ctx.loading || !_ctx.isAutomationNameValid || _ctx.formImpactSourceError,
                              onClick: _cache[7] || (_cache[7] = _withModifiers(($event: any) => (_ctx.saveChanges({ name: _ctx.localAutomation.name })), ["prevent"]))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t('save')), 1)
                              ]),
                              _: 1
                            }, 8, ["disabled"])
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_expansion_panel, { readonly: "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_expansion_panel_title, { "hide-actions": "" }, {
                    default: _withCtx(({ expanded }) => [
                      _createElementVNode("div", _hoisted_28, [
                        _cache[44] || (_cache[44] = _createElementVNode("p", { class: "mb-0 panel-header-text" }, "Impact", -1)),
                        (_ctx.automation.offsets.length)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                              (_ctx.automation.trigger !== 'offsetPerProduct')
                                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.accumulatedOffsetsByType, (offset, index) => {
                                    return (_openBlock(), _createBlock(_component_onboarding_pill, {
                                      key: index,
                                      mode: "impact",
                                      "offset-type": offset.type
                                    }, {
                                      default: _withCtx(() => [
                                        (!_ctx.includes(_ctx.PERCENTAGE_TRIGGERS, _ctx.automation.trigger))
                                          ? (_openBlock(), _createElementBlock("span", _hoisted_30, _toDisplayString(_ctx.formatTotalAmounts((offset?.amount || 0) * (offset?.unit || 1))), 1))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 2
                                    }, 1032, ["offset-type"]))
                                  }), 128))
                                : _createCommentVNode("", true),
                              (_ctx.automation.trigger === 'offsetPerProduct')
                                ? (_openBlock(), _createBlock(_component_onboarding_pill, { key: 1 }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.t('offset_per_product.impact_per_product')), 1)
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true),
                              (_ctx.showModifierPill)
                                ? (_openBlock(), _createBlock(_component_onboarding_pill, { key: 2 }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.labelForModifier), 1)
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true)
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("button", _hoisted_31, [
                          (!expanded)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _cache[41] || (_cache[41] = _createTextVNode(" Edit ")),
                                _createVNode(_component_v_icon, { class: "toggle-edit-button__icon" }, {
                                  default: _withCtx(() => _cache[40] || (_cache[40] = [
                                    _createTextVNode(" mdi-pencil-outline ")
                                  ])),
                                  _: 1
                                })
                              ], 64))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _cache[43] || (_cache[43] = _createTextVNode(" Cancel ")),
                                _createVNode(_component_v_icon, { class: "toggle-edit-button__icon" }, {
                                  default: _withCtx(() => _cache[42] || (_cache[42] = [
                                    _createTextVNode(" mdi-close-circle-outline ")
                                  ])),
                                  _: 1
                                })
                              ], 64))
                        ])
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_expansion_panel_text, null, {
                    default: _withCtx(() => [
                      (_ctx.automation.trigger === 'perSelectedAnswer')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                            _createVNode(_component_ImpactSettingsPerQuestion, {
                              "question-data": _ctx.formQuestion,
                              onSave: _ctx.onImpactSettingsPerQuestionSave,
                              onEditingChange: _ctx.onImpactSettingsPerQuestionEditingChange
                            }, null, 8, ["question-data", "onSave", "onEditingChange"]),
                            (!_ctx.isImpactSettingPerQuestionEditing)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  _createVNode(_component_PerSelectedAnswerPostPurchaseEmailNotification),
                                  _createVNode(_component_gs_button, {
                                    size: "large",
                                    "full-width": "",
                                    disabled: _ctx.loading,
                                    onClick: _withModifiers(_ctx.saveChangesWithFormQuestions, ["prevent"])
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.t('save')), 1)
                                    ]),
                                    _: 1
                                  }, 8, ["disabled", "onClick"])
                                ], 64))
                              : _createCommentVNode("", true)
                          ]))
                        : (_openBlock(), _createBlock(_component_v_form, {
                            key: 1,
                            modelValue: _ctx.isFormValid,
                            "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.isFormValid) = $event))
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_33, [
                                _createElementVNode("div", _hoisted_34, [
                                  _createElementVNode("p", _hoisted_35, _toDisplayString(_ctx.t(`${_ctx.snakeCase(_ctx.automation.trigger)}.choose_impact`)), 1),
                                  _createElementVNode("p", _hoisted_36, _toDisplayString(_ctx.t(`${_ctx.snakeCase(_ctx.automation.trigger)}.choose_impact_tooltip`)), 1)
                                ]),
                                (
                    (_ctx.automation.trigger !== 'offsetPerProduct' &&
                      _ctx.automation.trigger !== 'byLoyaltyPoints') ||
                    _ctx.hasAllProductsModifier
                  )
                                  ? (_openBlock(), _createBlock(_component_impact_settings, {
                                      key: 0,
                                      modelValue: _ctx.localAutomation.offsets,
                                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.localAutomation.offsets) = $event)),
                                      "single-selection": _ctx.includes(_ctx.PERCENTAGE_TRIGGERS, _ctx.automation.trigger),
                                      class: "mb-8",
                                      onOnValidity: _ctx.setImpactSettingValidity
                                    }, null, 8, ["modelValue", "single-selection", "onOnValidity"]))
                                  : _createCommentVNode("", true),
                                (_ctx.localAutomation.trigger === 'offsetPerProduct' && !_ctx.hasAllProductsModifier)
                                  ? _withDirectives((_openBlock(), _createBlock(_component_ProductTable, {
                                      key: 1,
                                      loading: _ctx.loading,
                                      ref: "productTable",
                                      "automation-id": _ctx.automation.id,
                                      "integration-id": _ctx.integrationId,
                                      "selected-products": _ctx.selectedProducts,
                                      onSelectItems: _cache[10] || (_cache[10] = (items) => (_ctx.selectedProducts = items)),
                                      onIsAllSelected: _ctx.setIsAllSelected,
                                      onSave: _ctx.onImpactSettingsProductSave
                                    }, null, 8, ["loading", "automation-id", "integration-id", "selected-products", "onIsAllSelected", "onSave"])), [
                                      [_vShow, _ctx.showProductsTable]
                                    ])
                                  : _createCommentVNode("", true),
                                (_ctx.localAutomation.trigger === 'byLoyaltyPoints')
                                  ? (_openBlock(), _createBlock(_component_RuleTable, {
                                      key: 2,
                                      "automation-id": _ctx.automation.id,
                                      "integration-id": _ctx.integrationId,
                                      platform: _ctx.automation.platform,
                                      onSave: _ctx.onImpactSettingsRuleSave
                                    }, null, 8, ["automation-id", "integration-id", "platform", "onSave"]))
                                  : _createCommentVNode("", true)
                              ]),
                              (_ctx.isAdditionalSettingNeeded)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                                    _createElementVNode("div", _hoisted_38, [
                                      _createElementVNode("p", _hoisted_39, _toDisplayString(_ctx.t(`${_ctx.snakeCase(_ctx.automation.trigger)}.label`)), 1),
                                      _createElementVNode("p", _hoisted_40, _toDisplayString(_ctx.t(`${_ctx.snakeCase(_ctx.automation.trigger)}.tooltip`)), 1)
                                    ]),
                                    (_ctx.includes(_ctx.CURRENCY_MODIFIER_TRIGGERS, _ctx.automation.trigger))
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
                                          _createVNode(_component_v_select, {
                                            modelValue: _ctx.localModifier.currency,
                                            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.localModifier.currency) = $event)),
                                            items: _ctx.availableCurrencies,
                                            variant: "outlined",
                                            color: "green",
                                            "item-color": "green",
                                            class: "dropdown-select",
                                            "hide-details": ""
                                          }, null, 8, ["modelValue", "items"]),
                                          _createVNode(_component_gs_input, {
                                            modelValue: _ctx.localModifier.amount,
                                            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.localModifier.amount) = $event)),
                                            modelModifiers: { number: true },
                                            label: "Amount",
                                            type: "number",
                                            min: "0",
                                            class: "additional-impact-setting-input",
                                            rules: [_ctx.rules.positiveInteger, _ctx.rules.required]
                                          }, null, 8, ["modelValue", "rules"])
                                        ]))
                                      : _createCommentVNode("", true),
                                    (_ctx.includes(_ctx.PERCENTAGE_TRIGGERS, _ctx.automation.trigger))
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
                                          _createVNode(_component_gs_input, {
                                            modelValue: _ctx.localModifier.amount,
                                            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.localModifier.amount) = $event)),
                                            modelModifiers: { number: true },
                                            label: "%",
                                            type: "number",
                                            min: "0",
                                            class: "additional-impact-setting-input",
                                            rules: [_ctx.rules.positiveInteger, _ctx.rules.required]
                                          }, null, 8, ["modelValue", "rules"])
                                        ]))
                                      : _createCommentVNode("", true)
                                  ]))
                                : _createCommentVNode("", true),
                              (
                  _ctx.automation.trigger !== 'offsetPerProduct' &&
                  _ctx.automation.trigger !== 'byLoyaltyPoints'
                )
                                ? (_openBlock(), _createElementBlock("div", _hoisted_43, [
                                    (!!_ctx.cartText)
                                      ? (_openBlock(), _createElementBlock("p", _hoisted_44, _toDisplayString(_ctx.cartText), 1))
                                      : _createCommentVNode("", true),
                                    (_ctx.isTotalTextNeeded)
                                      ? (_openBlock(), _createElementBlock("p", _hoisted_45, _toDisplayString(_ctx.totalText), 1))
                                      : _createCommentVNode("", true)
                                  ]))
                                : _createCommentVNode("", true),
                              (_ctx.localAutomation.trigger !== 'offsetPerProduct' || _ctx.hasAllProductsModifier)
                                ? (_openBlock(), _createBlock(_component_gs_button, {
                                    key: 2,
                                    size: "large",
                                    "full-width": "",
                                    disabled: 
                  !_ctx.isFormValid || _ctx.loading || !_ctx.isImpactSettingValid || !_ctx.isOffsetSettingTotalValid
                ,
                                    onClick: _cache[14] || (_cache[14] = _withModifiers(($event: any) => (_ctx.saveChanges({ offsets: _ctx.localAutomation.offsets })), ["prevent"]))
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.saveOffsetSettingButtonText), 1)
                                    ]),
                                    _: 1
                                  }, 8, ["disabled"]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }, 8, ["modelValue"]))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_expansion_panel, { readonly: "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_expansion_panel_title, { "hide-actions": "" }, {
                    default: _withCtx(({ expanded }) => [
                      _createElementVNode("div", _hoisted_46, [
                        _cache[50] || (_cache[50] = _createElementVNode("p", { class: "mb-0 panel-header-text" }, "Schedule", -1)),
                        _createElementVNode("div", _hoisted_47, [
                          _createVNode(_component_onboarding_pill, null, {
                            default: _withCtx(() => [
                              _createElementVNode("span", _hoisted_48, [
                                _createTextVNode(_toDisplayString(_ctx.automation.scheduleLabel) + " ", 1),
                                (_ctx.automation.status === 'active' && !_ctx.automation.schedule.endDate)
                                  ? (_openBlock(), _createBlock(_component_v_icon, {
                                      key: 0,
                                      class: "ongoing-icon"
                                    }, {
                                      default: _withCtx(() => _cache[45] || (_cache[45] = [
                                        _createTextVNode(" mdi-all-inclusive ")
                                      ])),
                                      _: 1
                                    }))
                                  : _createCommentVNode("", true)
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _createElementVNode("button", _hoisted_49, [
                          (!expanded)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _cache[47] || (_cache[47] = _createTextVNode(" Edit ")),
                                _createVNode(_component_v_icon, { class: "toggle-edit-button__icon" }, {
                                  default: _withCtx(() => _cache[46] || (_cache[46] = [
                                    _createTextVNode(" mdi-pencil-outline ")
                                  ])),
                                  _: 1
                                })
                              ], 64))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _cache[49] || (_cache[49] = _createTextVNode(" Cancel ")),
                                _createVNode(_component_v_icon, { class: "toggle-edit-button__icon" }, {
                                  default: _withCtx(() => _cache[48] || (_cache[48] = [
                                    _createTextVNode(" mdi-close-circle-outline ")
                                  ])),
                                  _: 1
                                })
                              ], 64))
                        ])
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_expansion_panel_text, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_50, [
                        _createElementVNode("div", _hoisted_51, [
                          _createElementVNode("p", _hoisted_52, _toDisplayString(_ctx.t('schedule')), 1),
                          _createElementVNode("p", _hoisted_53, _toDisplayString(_ctx.t('schedule_tooltip')), 1)
                        ]),
                        _createVNode(_component_DateTimePicker, {
                          modelValue: _ctx.localAutomation.schedule.startDate,
                          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.localAutomation.schedule.startDate) = $event)),
                          "min-value": _ctx.initialDate,
                          class: "mb-5",
                          clearable: false,
                          "max-width": 450
                        }, null, 8, ["modelValue", "min-value"]),
                        _cache[51] || (_cache[51] = _createElementVNode("p", { class: "mb-1" }, "End date", -1)),
                        _createVNode(_component_v_checkbox, {
                          id: "checkbox-datetime",
                          modelValue: _ctx.isEndDateVisible,
                          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.isEndDateVisible) = $event)),
                          name: "checkbox-datetime",
                          inline: "",
                          label: "Set an end date",
                          style: {"margin":"0 0 10px"},
                          "hide-details": ""
                        }, null, 8, ["modelValue"]),
                        (_ctx.isEndDateVisible)
                          ? (_openBlock(), _createBlock(_component_DateTimePicker, {
                              key: 0,
                              modelValue: _ctx.localAutomation.schedule.endDate,
                              "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.localAutomation.schedule.endDate) = $event)),
                              "min-value": _ctx.localAutomation.schedule.startDate,
                              class: "mb-5",
                              error: !_ctx.isScheduleValid,
                              "max-width": 450,
                              onFocus: _cache[19] || (_cache[19] = ($event: any) => (_ctx.isScheduleValid = true)),
                              clearable: false
                            }, null, 8, ["modelValue", "min-value", "error"]))
                          : _createCommentVNode("", true),
                        _createVNode(_component_gs_button, {
                          size: "large",
                          "full-width": "",
                          disabled: !_ctx.isScheduleValid || _ctx.loading,
                          onClick: _cache[20] || (_cache[20] = _withModifiers(($event: any) => (_ctx.saveChanges({ schedule: _ctx.localAutomation.schedule })), ["prevent"]))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t('save')), 1)
                          ]),
                          _: 1
                        }, 8, ["disabled"])
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_ctx.showOrderOriginModifier)
                ? (_openBlock(), _createBlock(_component_v_expansion_panel, {
                    key: 0,
                    readonly: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_expansion_panel_title, { "hide-actions": "" }, {
                        default: _withCtx(({ expanded }) => [
                          _createElementVNode("div", _hoisted_54, [
                            _cache[56] || (_cache[56] = _createElementVNode("p", { class: "mb-0 panel-header-text" }, "Order Origin", -1)),
                            _createElementVNode("div", _hoisted_55, [
                              _createVNode(_component_onboarding_pill, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.getOrderOriginLabel(_ctx.orderOriginModifier)), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _createElementVNode("button", _hoisted_56, [
                              (!expanded)
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                    _cache[53] || (_cache[53] = _createTextVNode(" Edit ")),
                                    _createVNode(_component_v_icon, { class: "toggle-edit-button__icon" }, {
                                      default: _withCtx(() => _cache[52] || (_cache[52] = [
                                        _createTextVNode(" mdi-pencil-outline ")
                                      ])),
                                      _: 1
                                    })
                                  ], 64))
                                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                    _cache[55] || (_cache[55] = _createTextVNode(" Cancel ")),
                                    _createVNode(_component_v_icon, { class: "toggle-edit-button__icon" }, {
                                      default: _withCtx(() => _cache[54] || (_cache[54] = [
                                        _createTextVNode(" mdi-close-circle-outline ")
                                      ])),
                                      _: 1
                                    })
                                  ], 64))
                            ])
                          ])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_expansion_panel_text, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_57, [
                            _createVNode(_component_select_shopify_order_origin, {
                              modelValue: _ctx.localOrderOriginModifier,
                              "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.localOrderOriginModifier) = $event))
                            }, null, 8, ["modelValue"]),
                            _createVNode(_component_gs_button, {
                              size: "large",
                              "full-width": "",
                              disabled: _ctx.loading,
                              onClick: _cache[22] || (_cache[22] = _withModifiers(($event: any) => (
                  _ctx.saveChanges({
                    modifiers: [{ type: 'orderOrigin', stringValue: _ctx.localOrderOriginModifier }],
                  })
                ), ["prevent"]))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t('save')), 1)
                              ]),
                              _: 1
                            }, 8, ["disabled"])
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ], 64))
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}