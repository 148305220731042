import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "loyalty-info-content loyalty-info-content-row" }
const _hoisted_2 = { class: "loyalty-info-wrapper" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "loyalty-info-content" }
const _hoisted_6 = { class: "loyalty-info-wrapper" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "form-controls" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_FullWidthPanel = _resolveComponent("FullWidthPanel")!

  return (_openBlock(), _createBlock(_component_FullWidthPanel, {
    title: _ctx.$t('LoyaltyPointsInfoPopup.title'),
    description: _ctx.$t('LoyaltyPointsInfoPopup.description')
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[2] || (_cache[2] = _createElementVNode("span", { class: "loyalty-info-number" }, "1", -1)),
          _createElementVNode("div", {
            innerHTML: 
            _ctx.$t('LoyaltyPointsInfoPopup.first_step', {
              documentationLink: _ctx.STAMPED_LOYALTY_DOCUMENTATION_URL,
            })
          
          }, null, 8, _hoisted_3)
        ]),
        _createElementVNode("video", {
          src: require('@/assets/videos/loyalty-info-video.mov').default,
          width: "675",
          height: "350",
          autoplay: "",
          muted: "",
          controls: false,
          loop: ""
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _cache[3] || (_cache[3] = _createElementVNode("span", { class: "loyalty-info-number" }, "2", -1)),
          _createElementVNode("div", {
            innerHTML: _ctx.$t('LoyaltyPointsInfoPopup.second_step')
          }, null, 8, _hoisted_7)
        ]),
        _createElementVNode("img", {
          src: require('@/assets/images/loyalty-info-image.png'),
          alt: "stamped admin interface"
        }, null, 8, _hoisted_8)
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_gs_button, {
          type: "outlined",
          size: "large",
          class: "form-control",
          icon: "mdi-chevron-left",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('CommonUi.back')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_gs_button, {
          "full-width": "",
          type: "tertiary",
          size: "large",
          class: "form-control",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('save')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('CommonUi.next')), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["title", "description"]))
}