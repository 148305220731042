import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "impact-settings-wrapper" }
const _hoisted_2 = { class: "filter-button-list" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "filter-button-title" }
const _hoisted_5 = { class: "impact-settings" }
const _hoisted_6 = { class: "markers" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImpactIcon = _resolveComponent("ImpactIcon")!
  const _component_ImpactSettingCard = _resolveComponent("ImpactSettingCard")!
  const _component_v_form = _resolveComponent("v-form")!
  const _directive_observe_intersection = _resolveDirective("observe-intersection")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category, index) => {
        return (_openBlock(), _createElementBlock("button", {
          key: index,
          class: _normalizeClass(['filter-button', { 'is-active': category === _ctx.selectedCategory }]),
          onClick: _withModifiers(($event: any) => (_ctx.searchByCategory(category)), ["prevent"])
        }, [
          (category !== 'all')
            ? (_openBlock(), _createBlock(_component_ImpactIcon, {
                key: 0,
                "icon-type": category,
                class: "filter-button-icon",
                "icon-style": "filled"
              }, null, 8, ["icon-type"]))
            : _createCommentVNode("", true),
          _createElementVNode("span", _hoisted_4, _toDisplayString(category === 'all' ? _ctx.$t(`ProductTable.all_projects`) : _ctx.$t(`${category}.title`)), 1)
        ], 10, _hoisted_3))
      }), 128))
    ]),
    _createVNode(_component_v_form, {
      modelValue: _ctx.isFormValid,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isFormValid) = $event))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredProjects, (project, index) => {
            return _withDirectives((_openBlock(), _createBlock(_component_ImpactSettingCard, {
              key: index,
              modelValue: _ctx.localValues[project.projectId],
              "onUpdate:modelValue": [($event: any) => ((_ctx.localValues[project.projectId]) = $event), ($event: any) => (_ctx.onUpdate($event, project.projectId))],
              position: index,
              "project-id": project.projectId,
              "is-button-active": !!_ctx.localValues[project.projectId],
              "single-selection": _ctx.singleSelection,
              "max-value": _ctx.getMaxValueForProject(project.projectId)
            }, null, 8, ["modelValue", "onUpdate:modelValue", "position", "project-id", "is-button-active", "single-selection", "max-value"])), [
              [_directive_observe_intersection, _ctx.setVisibleIndex, index]
            ])
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createElementVNode("div", _hoisted_6, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredProjects, (_, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass(['marker-dot', { visible: index === _ctx.lastVisibleCardIndex }])
        }, null, 2))
      }), 128))
    ])
  ]))
}