<template>
  <div>
    <div class="image-upload-wrapper">
      <div
        v-if="account.accountType === 'personal' || account.accountType === 'family'"
        class="image-sun"
      >
        <img
          class="profile-pic"
          :style="
            account.imageURL === ''
              ? 'border-radius: 50%; width: 100%; height: 100%;'
              : png
              ? 'max-width: 100%; object-fit: contain;'
              : 'max-height: 100%;'
          "
          alt="profile-pic"
          :src="url"
        />
      </div>
      <div v-else class="image-sun-business">
        <img
          class="profile-pic"
          :style="
            account.imageURL === ''
              ? 'border-radius: 50%; width: 100%; height: 100%;'
              : png
              ? 'max-width: 100%; object-fit: contain;'
              : 'max-height: 100%;'
          "
          alt="profile-pic"
          :src="url"
        />
      </div>
      <gs-button type="secondary" @click="$emit('uploadClick')">
        {{ isBusiness ? t('upload') : t('upload_picture') }}
      </gs-button>
    </div>

    <div class="divider-line" />

    <v-form v-model="isFormValid" class="form-wrapper">
      <!-- First & Last Name -->
      <div class="left-column">
        <v-text-field
          v-model="firstName"
          class="input-field"
          :label="t('first_name')"
          variant="outlined"
          flat
          color="#3B755F"
          style="margin-top: 15px"
          :rules="[rules.required]"
        />

        <v-text-field
          v-model="lastName"
          class="input-field"
          :label="t('last_name')"
          variant="outlined"
          flat
          color="#3B755F"
          :rules="[rules.required]"
        />

        <!-- Company & Email Name -->
        <v-text-field
          v-if="account.accountType === 'business' || account.accountType === 'ecommerce'"
          v-model="companyName"
          flat
          class="input-field"
          :label="t('company_name')"
          variant="outlined"
          color="#3B755F"
          :rules="[rules.required]"
        />
        <gs-button :loading="loading" :disabled="!isFormValid" @click.prevent="updateProfile">
          {{ t('save') }}
        </gs-button>
      </div>

      <div class="right-column">
        <!-- Website -->
        <v-text-field
          v-if="account.accountType === 'business' || account.accountType === 'ecommerce'"
          v-model="website"
          prefix="https://"
          :rules="[rules.url]"
          class="input-field"
          :label="t('website')"
          :placeholder="t('website_text')"
          variant="outlined"
          flat
          color="#3B755F"
          style="margin-top: 0"
        />
        <v-textarea
          v-model="profileDescription"
          variant="outlined"
          color="#3B755F"
          clearable
          flat
          no-resize
          name="input-7-4"
          :label="t('profile_description')"
          :placeholder="t('profile_description_placeholder')"
          :rules="[rules.maxlength(1500)]"
        />
      </div>
    </v-form>
  </div>
</template>

<script lang="ts">
import { updateAccount, updateUser } from '@api/index'
import type { Notification } from '@/store/notification'
import { RulesMixin } from '@/helpers/mixins/RulesMixin.vue'
import type { Account } from '@/store'
import { User } from '@/store/users'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'UpdateProfile',
  emits: ['uploadClick'],
  mixins: [RulesMixin],
  data() {
    return {
      isFormValid: true,
      firstName: '',
      lastName: '',
      companyName: '',
      website: '',
      profileDescription: '',
      loading: false,
    }
  },
  computed: {
    isBusiness(): boolean {
      return this.account.accountType === 'ecommerce' || this.account.accountType === 'business'
    },
    account(): Account {
      return this.$store.getters['getAccount']
    },
    currentUser(): User {
      return this.$store.getters['getCurrentUser']
    },
  },
  created() {
    this.setInputs()
  },
  methods: {
    t(key: string) {
      return this.$t(`UpdateProfile.${key}`)
    },
    setInputs() {
      if (this.currentUser.firstName !== '') {
        this.firstName = this.currentUser.firstName
      }
      if (this.currentUser.lastName !== '') {
        this.lastName = this.currentUser.lastName
      }
      if (this.account.companyName !== '') {
        this.companyName = this.account.companyName
      }
      if (this.account.website !== '') {
        this.website = this.account.website.replace('https://', '')
      }
      if (this.account.profileDescription !== '') {
        this.profileDescription = this.account.profileDescription
      }
    },
    async updateProfile() {
      this.loading = true
      try {
        await Promise.all([this.updateAccount(), this.updateUser()])

        this.$store.dispatch('notification/notify', { text: 'Profile updated' } as Notification)
        setTimeout(() => {
          this.loading = false
        }, 500)
      } catch (e) {
        this.$store.dispatch('notification/notify', {
          text: e?.message,
          isError: true,
        } as Notification)
      }
    },
    async updateAccount() {
      let accountUpdatePayload: object = {
        userId: this.account.accountId,
        profileDescription: this.profileDescription,
      }

      if (this.isBusiness) {
        accountUpdatePayload = {
          ...accountUpdatePayload,
          companyName: this.companyName,
          website: this.website ? 'https://' + this.website : '',
        }
      }

      await updateAccount(accountUpdatePayload)
      this.setAccount(accountUpdatePayload)
    },
    async updateUser() {
      const { data } = await updateUser(this.currentUser.id, this.firstName, this.lastName)
      this.updateCurrentUser(new User(data))
    },
    updateCurrentUser(user: Partial<User>): Promise<void> {
      return this.$store.dispatch('updateCurrentUser', user)
    },
    setAccount(account: Partial<Account>): void {
      return this.$store.commit('setAccount', account)
    },
  },
  props: {
    url: {
      type: String,
    },
    png: {
      type: Boolean,
    },
  },
})
</script>

<style lang="scss" scoped>
.image-upload-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 35px;
}

.button-text {
  text-transform: capitalize;
}

.image-sun {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-sun-business {
  width: 150px;
  height: 150px;
  display: flex;
  border-radius: 50%;
  overflow: hidden;
}

.profile-pic {
  background: none;
}

.divider-line {
  height: 1px;
  width: 100%;
  background-color: black;
  opacity: 0.2;
}

.form-wrapper {
  margin-top: 35px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  width: 100%;
}

.left-column {
  width: 50%;
  padding-right: 10px;
}

.right-column {
  width: 50%;
  margin-top: 15px;
  padding-left: 10px;
  min-height: 269px;
}

.input-field {
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
}

.button-save {
  width: 100%;
}

.button-text-save {
  color: #f9f9f9;
  font-weight: 600;
  font-size: 12px;
}

@media screen and (max-width: 700px) {
  .form-wrapper {
    flex-direction: column-reverse;
  }

  .left-column {
    width: 100%;
    margin-top: 18px;
    padding: 0;
  }

  .right-column {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}

@media screen and (max-width: 440px) {
  .image-upload-wrapper {
    flex-direction: column;
  }

  .right-column {
    min-height: 178px;
  }

  .form-wrapper {
    padding: 0;
  }
}
</style>
