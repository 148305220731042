import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "dialog-wrapper"
}
const _hoisted_2 = { class: "close-wrapper" }
const _hoisted_3 = { class: "back" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "dialog-title" }
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChoosePath = _resolveComponent("ChoosePath")!
  const _component_Referrals = _resolveComponent("Referrals")!
  const _component_Partners = _resolveComponent("Partners")!

  return (!_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.progressItem !== 0 && !_ctx.hideBack)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  class: "chevron",
                  style: {"width":"30px"},
                  alt: "back",
                  src: require('@/assets/icons/chevron_left.svg'),
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.progressItemClick(0)))
                }, null, 8, _hoisted_4))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.dialogTitle), 1)
          ]),
          _createElementVNode("img", {
            style: {"width":"25px"},
            class: "close-icon",
            src: require('@/assets/icons/close_icon.svg'),
            alt: "close",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('dialogClick')))
          }, null, 8, _hoisted_6)
        ]),
        (_ctx.progressItem === 0)
          ? (_openBlock(), _createBlock(_component_ChoosePath, {
              key: 0,
              onProgressItemClick: _ctx.progressItemClick
            }, null, 8, ["onProgressItemClick"]))
          : _createCommentVNode("", true),
        (_ctx.progressItem === 1)
          ? (_openBlock(), _createBlock(_component_Referrals, { key: 1 }))
          : _createCommentVNode("", true),
        (_ctx.progressItem === 2)
          ? (_openBlock(), _createBlock(_component_Partners, {
              key: 2,
              "is-edit": false,
              "is-contact-edit": false,
              onGetFreshDataClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('getFreshDataClick'))),
              onDialogClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('dialogClick'))),
              onShowErrorAlert: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('showErrorAlert'))),
              onShowSuccessAlert: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('showSuccessAlert')))
            }))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}