<template>
  <div class="enterprise-plan-card">
    <div :class="['enterprise-plan-card-header', { 'is-open': isOpen }]">
      <div class="header-text-wrapper">
        <h3 class="header-title">
          {{ t('header_title') }}
        </h3>
        <p class="header-desc">
          {{ t('header_desc') }}
        </p>
      </div>
      <div class="header-button-wrapper">
        <gs-button
          class="header-button"
          type="tertiary"
          href="https://www.getgreenspark.com/book-a-demo"
        >
          {{ t('start') }}
        </gs-button>
        <button
          :class="['toggle-content-button', { 'is-open': isOpen }]"
          @click.prevent="toggleContent"
        >
          {{ t('see_details') }}
        </button>
      </div>
    </div>
    <v-expand-transition>
      <div v-show="isOpen" :class="['content']">
        <ul class="feature-list">
          <li
            v-for="(item, index) in featureListItems"
            :key="index"
            class="feature-item"
            v-html="t(`${featuresTranslationKey}.${index}`)"
          />
        </ul>
      </div>
    </v-expand-transition>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Utils } from '@/helpers/mixins/utilsMixin'
import messages from '@/i18n/langs'

export default defineComponent({
  name: 'EnterprisePlanCard',
  mixins: [Utils],
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    featuresTranslationKey() {
      return 'features_new'
    },
    featureListItems() {
      return messages[this.$i18n.locale].EnterprisePlanCard.features_new
    },
  },
  methods: {
    t(key: string, params?: { [key: string]: string | number }) {
      return this.$t(`EnterprisePlanCard.${key}`, params ?? {})
    },
    toggleContent() {
      this.isOpen = !this.isOpen
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.enterprise-plan-card {
  border-radius: var(--border-radius-big);
  width: 100%;
  max-width: 1220px;
  margin: 0 auto 80px;
  padding: 24px;
  text-align: left;
  border: 1px solid white;
  background-color: var(--ui-white, #f9f9f9);
  backdrop-filter: blur(10px);

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding: 32px;
  }
}

.enterprise-plan-card-header {
  display: flex;
  align-items: center;
  transition: all ease-in-out 0.6s;
  flex-direction: column;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    justify-content: space-between;
    flex-direction: row;
  }

  .header-button-wrapper {
    display: flex;
    gap: 16px;
    flex-direction: column;
    align-items: center;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      width: auto;
    }

    .header-button {
      min-width: 240px;
    }
  }

  &.is-open {
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid var(--gray-light-E4);
    transition: none;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      padding-bottom: 32px;
      margin-bottom: 32px;
    }

    &::after {
      transform: rotate(180deg);
    }
  }
}

.header-text-wrapper {
  margin-bottom: 24px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    margin-bottom: 0;
  }
}

.header-title {
  font-size: 30px;
  margin-bottom: 8px;
}

.header-desc {
  font-size: 14px;
  max-width: 60ch;
  margin-bottom: 0px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    font-size: 18px;
  }
}

.toggle-content-button {
  color: var(--ui-dark-blue);
  font-size: 16px;
  display: flex;
  gap: 5px;
  align-items: center;
  text-decoration: underline;

  &::after {
    display: block;
    content: '';
    border: 6px solid transparent;
    border-top: 6px solid var(--font-color-primary);
    border-bottom: 0;
    transition: 0.3s ease-in-out;
  }
}

.toggle-content-button-icon {
  color: inherit;
  font-size: inherit;
}

.feature-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.feature-item {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 18px;
  padding-left: 30px;
  position: relative;

  &::before {
    content: '';
    background: url('../../assets/icons/check_green.svg') no-repeat center/contain;
    height: 24px;
    width: 24px;
    position: absolute;
    left: 0;
  }
}
</style>
