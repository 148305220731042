import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bees-comparison-container" }
const _hoisted_2 = { class: "comparison-data" }
const _hoisted_3 = { class: "comparison-amount" }
const _hoisted_4 = { class: "info-text" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "comparison-icon-wrapper" }
const _hoisted_7 = ["alt", "src"]
const _hoisted_8 = { class: "comparison-icon-wrapper" }
const _hoisted_9 = ["alt", "src"]
const _hoisted_10 = { class: "comparison-data" }
const _hoisted_11 = { class: "comparison-amount" }
const _hoisted_12 = { class: "info-text" }
const _hoisted_13 = ["href"]
const _hoisted_14 = { class: "comparison-data" }
const _hoisted_15 = { class: "comparison-amount" }
const _hoisted_16 = { class: "info-text" }
const _hoisted_17 = ["href"]
const _hoisted_18 = { class: "comparison-icon-wrapper" }
const _hoisted_19 = ["alt", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      style: _normalizeStyle({ 'background-color': _ctx.colorSettings.bees }),
      class: "hives comparison-card"
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.formatTotalAmounts(_ctx.comparisons.hives.amount, _ctx.comparisons.hives.suffix)), 1),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.comparisons.hives.amountDescription), 1),
        _createElementVNode("a", {
          href: _ctx.comparisons.hives.link,
          class: "info-sub-text",
          target: "_blank"
        }, _toDisplayString(_ctx.comparisons.hives.description), 9, _hoisted_5)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("img", {
          alt: _ctx.comparisons.hives.amountDescription,
          src: require('@/assets/icons/comparisons/bees-hives.svg'),
          class: "comparison-icon"
        }, null, 8, _hoisted_7)
      ])
    ], 4),
    _createElementVNode("div", {
      style: _normalizeStyle({ 'background-color': _ctx.colorSettings.bees }),
      class: "honey comparison-card"
    }, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("img", {
          alt: _ctx.comparisons.honey.amountDescription,
          src: require('@/assets/icons/comparisons/bees-honey.svg'),
          class: "comparison-icon"
        }, null, 8, _hoisted_9)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.formatTotalAmounts(_ctx.comparisons.honey.amount, _ctx.comparisons.honey.suffix)), 1),
        _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.comparisons.honey.amountDescription), 1),
        _createElementVNode("a", {
          href: _ctx.comparisons.honey.link,
          class: "info-sub-text",
          target: "_blank"
        }, _toDisplayString(_ctx.comparisons.honey.description), 9, _hoisted_13)
      ])
    ], 4),
    _createElementVNode("div", {
      style: _normalizeStyle({ 'background-color': _ctx.colorSettings.bees }),
      class: "flowers comparison-card"
    }, [
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.formatTotalAmounts(_ctx.comparisons.flowers.amount, _ctx.comparisons.flowers.suffix)), 1),
        _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.comparisons.flowers.amountDescription), 1),
        _createElementVNode("a", {
          href: _ctx.comparisons.flowers.link,
          class: "info-sub-text",
          target: "_blank"
        }, _toDisplayString(_ctx.comparisons.flowers.description), 9, _hoisted_17)
      ]),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("img", {
          alt: _ctx.comparisons.flowers.amountDescription,
          src: require('@/assets/icons/comparisons/bees-flowers.svg'),
          class: "comparison-icon"
        }, null, 8, _hoisted_19)
      ])
    ], 4)
  ]))
}