import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "dialog-container" }
const _hoisted_2 = { class: "impact-action-header" }
const _hoisted_3 = { class: "image-wrapper" }
const _hoisted_4 = { class: "impact-action-title" }
const _hoisted_5 = { class: "date" }
const _hoisted_6 = {
  key: 0,
  class: "info-container"
}
const _hoisted_7 = { class: "product-info-title" }
const _hoisted_8 = { class: "product-list" }
const _hoisted_9 = { class: "product-info" }
const _hoisted_10 = ["src", "alt"]
const _hoisted_11 = { class: "product-name" }
const _hoisted_12 = {
  key: 0,
  class: "product-impacts"
}
const _hoisted_13 = { class: "overall-impact-by-brand-title" }
const _hoisted_14 = { class: "impact-by-brand-container" }
const _hoisted_15 = ["src", "alt"]
const _hoisted_16 = { class: "impact-wrapper" }
const _hoisted_17 = { class: "view-details-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_ImpactCards = _resolveComponent("ImpactCards")!
  const _component_impact_pill = _resolveComponent("impact-pill")!
  const _component_LoaderBlock = _resolveComponent("LoaderBlock")!
  const _component_GreenSparkLogo = _resolveComponent("GreenSparkLogo")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_v_icon, { class: "impact-action-icon material-icons-outlined" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.iconName), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.title), 1),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.date), 1)
    ]),
    _createVNode(_component_ImpactCards, {
      impacts: _ctx.impactAction?.action.impacts
    }, null, 8, ["impacts"]),
    (!!_ctx.impactAction?.action.lineItems && !!_ctx.impactAction?.action.lineItems.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("h3", _hoisted_7, [
            _createVNode(_component_v_icon, { class: "product-info-title-icon" }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode(" mdi-shape-outline ")
              ])),
              _: 1
            }),
            _createTextVNode(_toDisplayString(_ctx.t('product_info_title')), 1)
          ]),
          _createElementVNode("ul", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.impactAction.action.lineItems, (product, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                class: "product-list-item"
              }, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("img", {
                    src: product.image || require('@/assets/images/leaf.svg'),
                    alt: product.title,
                    height: "70",
                    width: "70",
                    class: "product-image"
                  }, null, 8, _hoisted_10),
                  _createElementVNode("p", _hoisted_11, _toDisplayString(product.title), 1)
                ]),
                (product.impacts.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(product.impacts.filter((f) => f.amount > 0), (impact, key) => {
                        return (_openBlock(), _createBlock(_component_impact_pill, {
                          key: key,
                          impact: { type: impact.type || 'multiple', amount: impact.amount }
                        }, null, 8, ["impact"]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_LoaderBlock, {
          key: 1,
          "background-color": "transparent"
        }))
      : _createCommentVNode("", true),
    _createElementVNode("button", {
      class: "info-container brand",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openSelectedBrand && _ctx.openSelectedBrand(...args)))
    }, [
      _createElementVNode("h3", _hoisted_13, [
        _createVNode(_component_GreenSparkLogo, { class: "overall-icon" }),
        _createTextVNode(" " + _toDisplayString(_ctx.isImpactFromGreenSpark
            ? 'Your impact'
            : _ctx.t('overall_impact_title', {
                companyName: _ctx.brand.companyName,
              })), 1)
      ]),
      _createElementVNode("div", _hoisted_14, [
        (_ctx.brand.imageURL)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              height: "80",
              width: "80",
              class: "brand-logo",
              src: _ctx.brand.imageURL,
              alt: _ctx.brand.companyName + 'logo'
            }, null, 8, _hoisted_15))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_16, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.impactsForPills, (impact, index) => {
            return (_openBlock(), _createBlock(_component_impact_pill, {
              key: index,
              impact: { type: impact.type || 'multiple', amount: impact.amount }
            }, null, 8, ["impact"]))
          }), 128))
        ])
      ]),
      _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.isImpactFromGreenSpark ? _ctx.t('view_your_impact') : _ctx.t('view_details')), 1)
    ])
  ]))
}