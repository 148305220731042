import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "ma-0 mt-7 api-usage" }
const _hoisted_2 = { class: "tab-title" }
const _hoisted_3 = { class: "usage-period" }
const _hoisted_4 = { class: "mb-8" }
const _hoisted_5 = { class: "api-usage-row" }
const _hoisted_6 = { class: "section-header" }
const _hoisted_7 = { class: "section-header-title" }
const _hoisted_8 = {
  key: 0,
  class: "limit-description"
}
const _hoisted_9 = {
  key: 0,
  class: "unlimited-pill"
}
const _hoisted_10 = { class: "counter" }
const _hoisted_11 = {
  key: 0,
  class: "section-header"
}
const _hoisted_12 = { class: "section-header-title" }
const _hoisted_13 = { class: "section-header-title" }
const _hoisted_14 = {
  key: 0,
  class: "limit-description"
}
const _hoisted_15 = {
  key: 0,
  class: "unlimited-pill"
}
const _hoisted_16 = { class: "counter" }
const _hoisted_17 = {
  key: 1,
  class: "divider"
}
const _hoisted_18 = { class: "api-usage-expansion-panel-header" }
const _hoisted_19 = { class: "section-header-title expansion" }
const _hoisted_20 = { class: "section-header" }
const _hoisted_21 = { class: "section-header-title" }
const _hoisted_22 = {
  key: 0,
  class: "limit-description"
}
const _hoisted_23 = {
  class: /*@__PURE__*/_normalizeClass(['counter', 'expansion'])
}
const _hoisted_24 = { class: "contact-us" }
const _hoisted_25 = { class: "description ma-0 mr-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")!
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!
  const _component_LoadingOpaque = _resolveComponent("LoadingOpaque")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("h2", _hoisted_2, [
            _createTextVNode(_toDisplayString(_ctx.t('usage')) + " ", 1),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.getUsagePeriod), 1)
          ]),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.t('description')), 1),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("h3", _hoisted_7, [
                _createTextVNode(_toDisplayString(_ctx.t(_ctx.getOwnApiUsage.widgets.apiModule)) + " ", 1),
                (_ctx.showHelpText(_ctx.getOwnApiUsage.widgets.billing))
                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.getHelpText(
                  _ctx.getOwnApiUsage.widgets.apiModule,
                  _ctx.getOwnApiUsage.widgets.limit,
                  _ctx.getOwnApiUsage.widgets.billing,
                )), 1))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.showUnlimitedPill(_ctx.getOwnApiUsage.widgets.limit, _ctx.getOwnApiUsage.widgets.billing))
                ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.t('unlimited')), 1))
                : _createCommentVNode("", true)
            ]),
            _createVNode(_component_v_progress_linear, {
              color: "green",
              "bg-color": "#F2EBDB",
              rounded: "",
              "model-value": 
            _ctx.getProgressValue(_ctx.getOwnApiUsage.widgets.count, _ctx.getOwnApiUsage.widgets.limit)
          ,
              height: "29"
            }, {
              default: _withCtx(() => [
                _createElementVNode("strong", _hoisted_10, [
                  _createTextVNode(_toDisplayString(_ctx.getLabel(_ctx.getOwnApiUsage.widgets.count, _ctx.getOwnApiUsage.widgets.limit.amount)) + " ", 1),
                  (_ctx.getOwnApiUsage.widgets.limit.amount === null)
                    ? (_openBlock(), _createBlock(_component_v_icon, {
                        key: 0,
                        style: {"color":"inherit"}
                      }, {
                        default: _withCtx(() => _cache[2] || (_cache[2] = [
                          _createTextVNode("mdi-all-inclusive")
                        ])),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            }, 8, ["model-value"])
          ]),
          _cache[5] || (_cache[5] = _createElementVNode("hr", { class: "divider" }, null, -1)),
          (!_ctx.isApiUsageAnOverallReport)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createElementVNode("h3", _hoisted_12, _toDisplayString(_ctx.t('overall')), 1)
              ]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getOwnApiUsage.modules, ({ apiModule, billing, limit, count }, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "api-usage-row"
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(['section-header', { downsize: !_ctx.isApiUsageAnOverallReport }])
              }, [
                _createElementVNode("h3", _hoisted_13, [
                  _createTextVNode(_toDisplayString(_ctx.t(apiModule)) + " ", 1),
                  (_ctx.showHelpText(billing))
                    ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.getHelpText(apiModule, limit, billing)), 1))
                    : _createCommentVNode("", true)
                ]),
                (_ctx.showUnlimitedPill(limit, billing))
                  ? (_openBlock(), _createElementBlock("p", _hoisted_15, _toDisplayString(_ctx.t('unlimited')), 1))
                  : _createCommentVNode("", true)
              ], 2),
              _createVNode(_component_v_progress_linear, {
                color: "green",
                "bg-color": "#F2EBDB",
                rounded: "",
                "model-value": _ctx.getProgressValue(count, limit),
                height: "29"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("strong", _hoisted_16, [
                    _createTextVNode(_toDisplayString(_ctx.getLabel(count, limit.amount)) + " ", 1),
                    (limit.amount === null)
                      ? (_openBlock(), _createBlock(_component_v_icon, {
                          key: 0,
                          style: {"color":"inherit"}
                        }, {
                          default: _withCtx(() => _cache[3] || (_cache[3] = [
                            _createTextVNode("mdi-all-inclusive")
                          ])),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 2
              }, 1032, ["model-value"])
            ]))
          }), 128)),
          (_ctx.hasVendors)
            ? (_openBlock(), _createElementBlock("hr", _hoisted_17))
            : _createCommentVNode("", true),
          (_ctx.hasVendors)
            ? (_openBlock(), _createBlock(_component_v_expansion_panels, {
                key: 2,
                modelValue: _ctx.expansionPanels,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.expansionPanels) = $event)),
                flat: "",
                multiple: ""
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getVendorApiUsageGroupedByAccountId, (value, key) => {
                    return (_openBlock(), _createBlock(_component_v_expansion_panel, {
                      key: key,
                      class: "mb-6"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_expansion_panel_title, null, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_18, [
                              _createElementVNode("p", _hoisted_19, _toDisplayString(key), 1)
                            ])
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_v_expansion_panel_text, null, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(value, ({ apiModule, limit, count, billing }, index) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: index,
                                class: "api-usage-row"
                              }, [
                                _createElementVNode("div", _hoisted_20, [
                                  _createElementVNode("h3", _hoisted_21, [
                                    _createTextVNode(_toDisplayString(_ctx.t(apiModule)) + " ", 1),
                                    (_ctx.showHelpText(billing))
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(_ctx.getHelpText(apiModule, limit, billing)), 1))
                                      : _createCommentVNode("", true)
                                  ])
                                ]),
                                _createVNode(_component_v_progress_linear, {
                                  "bg-color": "white",
                                  color: "black",
                                  rounded: "",
                                  height: "29",
                                  "model-value": _ctx.getProgressValue(count, limit)
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("strong", _hoisted_23, [
                                      _createTextVNode(_toDisplayString(_ctx.getLabel(count, limit.amount)) + " ", 1),
                                      (limit.amount === null)
                                        ? (_openBlock(), _createBlock(_component_v_icon, {
                                            key: 0,
                                            style: {"color":"inherit"}
                                          }, {
                                            default: _withCtx(() => _cache[4] || (_cache[4] = [
                                              _createTextVNode("mdi-all-inclusive")
                                            ])),
                                            _: 1
                                          }))
                                        : _createCommentVNode("", true)
                                    ])
                                  ]),
                                  _: 2
                                }, 1032, ["model-value"])
                              ]))
                            }), 128))
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_24, [
            _createElementVNode("p", _hoisted_25, _toDisplayString(_ctx.t('increase')), 1),
            _createElementVNode("button", {
              class: "contact-us-button",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openChat()))
            }, _toDisplayString(_ctx.t('contact_us')), 1)
          ])
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_LoadingOpaque, { key: 1 }))
      : _createCommentVNode("", true)
  ]))
}