import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "logo-wrapper" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "central-menu-items" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_navigation_drawer, {
      modelValue: _ctx.sidebarMenu,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.sidebarMenu) = $event)),
      floating: "",
      color: "#F9F9F9",
      permanent: _ctx.sidebarMenu,
      rail: _ctx.toggleMini
    }, {
      prepend: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (!_ctx.toggleMini)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: "logo",
                src: require('@/assets/greenspark-logo.svg'),
                alt: "greenspark-app"
              }, null, 8, _hoisted_2))
            : _createCommentVNode("", true),
          (_ctx.toggleMini)
            ? (_openBlock(), _createElementBlock("img", {
                key: 1,
                class: "logo-small",
                src: require('@/assets/greenspark-logo.svg'),
                alt: "greenspark-app"
              }, null, 8, _hoisted_3))
            : _createCommentVNode("", true)
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_v_divider),
        _cache[17] || (_cache[17] = _createElementVNode("div", { class: "business-name" }, "Admin", -1)),
        _createVNode(_component_v_divider),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            class: "menu-item",
            style: _normalizeStyle(_ctx.activeMenuItem === 0 ? 'background-color: #dddcdc' : ''),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/admin/home')))
          }, _cache[9] || (_cache[9] = [
            _createElementVNode("div", { class: "menu-item-text" }, "Home", -1)
          ]), 4),
          _createElementVNode("div", {
            class: "menu-item",
            style: _normalizeStyle(_ctx.activeMenuItem === 1 ? 'background-color: #dddcdc' : ''),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push('/admin/ledger')))
          }, _cache[10] || (_cache[10] = [
            _createElementVNode("div", { class: "menu-item-text" }, "Public Ledger", -1)
          ]), 4),
          _createElementVNode("div", {
            class: "menu-item",
            style: _normalizeStyle(_ctx.activeMenuItem === 2 ? 'background-color: #dddcdc' : ''),
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push('/admin/accounts')))
          }, _cache[11] || (_cache[11] = [
            _createElementVNode("div", { class: "menu-item-text" }, "Add Transferred Impacts", -1)
          ]), 4),
          _createElementVNode("div", {
            class: "menu-item",
            style: _normalizeStyle(_ctx.activeMenuItem === 3 ? 'background-color: #dddcdc' : ''),
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.push('/admin/change-email')))
          }, _cache[12] || (_cache[12] = [
            _createElementVNode("div", { class: "menu-item-text" }, "Change Email", -1)
          ]), 4),
          _createElementVNode("div", {
            class: "menu-item",
            style: _normalizeStyle(_ctx.activeMenuItem === 4 ? 'background-color: #dddcdc' : ''),
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$router.push('/admin/reward')))
          }, _cache[13] || (_cache[13] = [
            _createElementVNode("div", { class: "menu-item-text" }, "Give Reward", -1)
          ]), 4),
          _createElementVNode("div", {
            style: _normalizeStyle(_ctx.activeMenuItem === 5 ? 'background-color: #dddcdc' : ''),
            class: "menu-item",
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$router.push('/admin/upload-shopify-payout')))
          }, _cache[14] || (_cache[14] = [
            _createElementVNode("div", { class: "menu-item-text" }, "Upload Shopify Payout", -1)
          ]), 4),
          _createElementVNode("div", {
            class: "menu-item",
            style: _normalizeStyle(_ctx.activeMenuItem === 6 ? 'background-color: #dddcdc' : ''),
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$router.push('/admin/delete-subscription')))
          }, _cache[15] || (_cache[15] = [
            _createElementVNode("div", { class: "menu-item-text" }, "Delete Subscription", -1)
          ]), 4),
          _createElementVNode("div", {
            style: _normalizeStyle(_ctx.activeMenuItem === 7 ? 'background-color: #dddcdc' : ''),
            class: "menu-item",
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$router.push('/admin/create-script-tag')))
          }, _cache[16] || (_cache[16] = [
            _createElementVNode("div", { class: "menu-item-text" }, "Create Script Tag", -1)
          ]), 4)
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "permanent", "rail"])
  ]))
}