import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "empty-wrapper" }
const _hoisted_2 = { class: "empty" }
const _hoisted_3 = { class: "empty-text" }
const _hoisted_4 = {
  key: 0,
  class: "button-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gs_button = _resolveComponent("gs-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "reath-left" }, null, -1)),
      _createElementVNode("div", _hoisted_3, [
        _createTextVNode(_toDisplayString(_ctx.text) + " ", 1),
        _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
        _createTextVNode(" " + _toDisplayString(_ctx.text1), 1)
      ]),
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "reath-right" }, null, -1))
    ]),
    (_ctx.buttonText !== 'hide')
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_gs_button, {
            class: "button",
            type: "primary",
            size: "large",
            "full-width": "",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('buttonClick')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}