<template>
  <div class="user-component">
    <div class="user-component-wrapper">
      <div class="user-wrapper">
        <div class="back" />
        <div class="form-wrapper">
          <div class="header">
            {{ t('header') }}
          </div>

          <div class="how-it-works-text">
            {{ t('text') }}
          </div>

          <div class="divider-line" />

          <div class="number-explanation-wrapper">
            <div class="number">1</div>
            <div class="explanation">
              {{ t('account') }}
            </div>
          </div>

          <div class="number-explanation-wrapper">
            <div class="number">2</div>
            <i18n-t tag="div" keypath="ShopifySetupInstructions.automations" class="explanation">
              <a :href="PRIVACY_POLICY_URL" target="_blank" class="link">{{ t('privacy') }}</a>
            </i18n-t>
          </div>

          <div class="number-explanation-wrapper" style="margin-bottom: 0">
            <div class="number">✓</div>
            <div class="explanation">
              {{ t('done') }}
            </div>
          </div>
          <gs-button
            class="button"
            type="primary"
            size="large"
            full-width
            @click="$emit('nextClick')"
          >
            {{ t('continue') }}
          </gs-button>

          <div class="already-member">
            {{ t('already_member') }}
            <span class="green-text" @click="navigateToLogin">{{ t('login') }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PRIVACY_POLICY_URL } from '@/helpers/constants'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ShopifySetupInstructions',
  emits: ['nextClick'],
  data() {
    return {
      PRIVACY_POLICY_URL: '',
    }
  },
  async created() {
    this.PRIVACY_POLICY_URL = PRIVACY_POLICY_URL
  },
  methods: {
    t(key: string) {
      return this.$t(`ShopifySetupInstructions.${key}`)
    },
    navigateToLogin() {
      this.$router.push({
        path: '/login',
        query: { app: '', withPublicToken: 'true', ...this.$route.query },
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.user-component {
  min-height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  max-width: 900px;
}

.user-component-wrapper {
  width: 100%;
  display: flex;
  border-radius: 8px;
  background: #f9f9f9;
  box-shadow: 0 42px 76px rgba(0, 0, 0, 0.05), 0 27.2222px 44.5093px rgba(0, 0, 0, 0.037963),
    0 16.1778px 24.2074px rgba(0, 0, 0, 0.0303704), 0 8.4px 12.35px rgba(0, 0, 0, 0.025),
    0 3.42222px 6.19259px rgba(0, 0, 0, 0.0196296), 0 0.777778px 2.99074px rgba(0, 0, 0, 0.012037);
  // filter: drop-shadow(0px 42px 76px rgba(0, 0, 0, 0.05)) drop-shadow(0px 27.2222px 44.5093px rgba(0, 0, 0, 0.037963)) drop-shadow(0px 16.1778px 24.2074px rgba(0, 0, 0, 0.0303704)) drop-shadow(0px 8.4px 12.35px rgba(0, 0, 0, 0.025)) drop-shadow(0px 3.42222px 6.19259px rgba(0, 0, 0, 0.0196296)) drop-shadow(0px 0.777778px 2.99074px rgba(0, 0, 0, 0.012037));
}

.user-wrapper {
  width: 100%;
}

.form-wrapper {
  padding: 30px 35px 20px;
  height: 100%;
}

.header {
  color: #212121;
  font-weight: bold;
  font-size: 35px;
  margin-bottom: 8px;
}

.how-it-works-text {
  color: #212121;
  font-size: 16px;
  margin-top: 4px;
}

.divider-line {
  height: 1px;
  width: 100%;
  background-color: black;
  opacity: 0.2;
  margin-top: 14px;
  margin-bottom: 20px;
}

.number-explanation-wrapper {
  display: flex;
  margin-bottom: 14px;
}

.number {
  border: 2px solid #3b755f;
  border-radius: 50%;
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3b755f;
  font-weight: 600;
  margin-right: 8px;
}

.explanation {
  font-size: 14px;
}

.button {
  margin-top: 45px;
}

.already-member {
  margin-top: 15px;
  font-size: 14px;
  color: #212121;
  display: flex;
  align-items: center;
  justify-content: center;
}

.green-text {
  color: #3b755f;
  cursor: pointer;
  margin-left: 3px;
}

@media screen and (max-width: 550px) {
  .form-wrapper {
    padding-left: 24px;
    padding-right: 24px;
  }
}
</style>
