<template>
  <div v-if="loaded">
    <div class="topstat-wrapper">
      <!-- tonnes of CO2  -->
      <div v-if="carbon && carbon > 0" class="stat carbon-card" style="margin-left: 0px">
        <div class="text-number-wrapper">
          <div class="number">{{ formatTotalAmounts(carbon) }} {{ t('carbon') }}</div>
          <div>
            {{ fundingType === 'oneTimeImpact' ? t('co2_once') : t('co2') }}
          </div>
        </div>
      </div>

      <!-- Plastic Bottles  -->
      <div v-if="plastic && plastic > 0" class="stat plastic-card">
        <div class="text-number-wrapper">
          <div class="number">{{ formatTotalAmounts(plastic) }} {{ t('plastic') }}</div>
          <div>
            {{ fundingType === 'oneTimeImpact' ? t('plastic_bottles_once') : t('plastic_bottles') }}
          </div>
        </div>
      </div>

      <!-- Trees  -->
      <div v-if="trees && trees > 0" class="stat tree-card">
        <div class="text-number-wrapper">
          <div class="number">{{ formatTotalAmounts(trees) }} {{ t('tree') }}</div>
          <div>
            {{ fundingType === 'oneTimeImpact' ? t('trees_once') : t('trees') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Utils } from '@/helpers/mixins/utilsMixin'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TotalFunding',
  components: {},
  mixins: [Utils],
  data() {
    return {
      loaded: false,
    }
  },
  created() {
    this.loaded = true
  },
  methods: {
    t(key: string) {
      return this.$t(`TotalFunding.${key}`)
    },
  },
  props: {
    carbon: {
      type: Number,
    },
    plastic: {
      type: Number,
    },
    trees: {
      type: Number,
    },
    fundingType: {
      type: String,
    },
  },
})
</script>

<style lang="scss" scoped>
.topstat-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  max-width: 100%;
}

.stat {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  min-height: 80px;
  background-color: #f2ebdb;
  justify-content: center;
  margin-bottom: 16px;
  margin-left: 6px;
  margin-right: 6px;
}

.text-number-wrapper {
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #212121;
  flex-direction: column;
}

.number {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #212121;
}

.stat-icon {
  width: 65px;
}

.carbon-card {
  background-image: url('../../../assets/gifs/comparisons/stat_carbon_background.svg');
  background-color: var(--ui-beige);
}

.plastic-card {
  background-image: url('../../../assets/gifs/comparisons/stat_plastic_background.svg');
  background-color: var(--ui-beige);
}

.tree-card {
  background-image: url('../../../assets/gifs/comparisons/stat_tree_background.svg');
  background-color: var(--ui-beige);
  margin-right: 0;
}

.referral-card {
  background: #3b755f;
  margin-left: 0px;
}

@media screen and (max-width: 1080px) {
  .text-number-wrapper {
    font-size: 16px;
    margin-left: -4px;
    text-align: center;
  }

  .number {
    font-size: 20px;
  }
}
</style>
