import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "quick-menu" }
const _hoisted_2 = { class: "menu-wrapper" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_menu, { "min-width": "200" }, {
      activator: _withCtx(({ props }) => [
        _createElementVNode("button", _mergeProps({ class: "quick-menu-open" }, props), [
          _createVNode(_component_v_icon, null, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("mdi-dots-horizontal")
            ])),
            _: 1
          })
        ], 16)
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (item, a) => {
            return (_openBlock(), _createElementBlock("div", {
              key: a,
              class: _normalizeClass(item.value === 'remindNotActive' ? 'menu-item-not-active' : 'menu-item'),
              onClick: ($event: any) => (_ctx.quickMenuClick(item.value))
            }, [
              _createElementVNode("img", {
                src: item.image,
                class: "quick-menu-icon",
                alt: "icon"
              }, null, 8, _hoisted_4),
              _createTextVNode(" " + _toDisplayString(item.label), 1)
            ], 10, _hoisted_3))
          }), 128))
        ])
      ]),
      _: 1
    })
  ]))
}