<template>
  <div class="empty-wrapper">
    <div class="empty">
      <div class="reath-left" />
      <div class="empty-text">
        {{ text }} <br />
        {{ text1 }}
      </div>
      <div class="reath-right" />
    </div>
    <div v-if="buttonText !== 'hide'" class="button-wrapper">
      <gs-button
        class="button"
        type="primary"
        size="large"
        full-width
        @click="$emit('buttonClick')"
      >
        {{ buttonText }}
      </gs-button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Empty',
  emits: ['buttonClick'],
  methods: {
    t(key: string) {
      return this.$t(`Empty.${key}`)
    },
  },
  props: {
    text: {
      type: String,
    },
    text1: {
      type: String,
    },
    buttonText: {
      type: String,
    },
  },
})
</script>

<style lang="scss" scoped>
.empty-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.empty {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.reath-left {
  background-image: url('../../assets/reath-left.svg');
  background-position-x: center;
  min-height: 79px;
  min-width: 79px;
}

.reath-right {
  background-image: url('../../assets/reath-right.svg');
  background-position-x: center;
  min-height: 79px;
  min-width: 79px;
}

.button-wrapper {
  width: 240px;
  margin-top: 25px;
}
</style>
