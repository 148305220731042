<template>
  <component :is="componentName" />
</template>

<script lang="ts">
import PlasticImpactIconFlat from '@/components/icons/ImpactIcons/flat/PlasticImpactIconFlat.vue'
import PlasticImpactIconIsometric from '@/components/icons/ImpactIcons/isometric/PlasticImpactIconIsometric.vue'
import PlasticImpactIconSimple from '@/components/icons/ImpactIcons/simple/PlasticImpactIconSimple.vue'
import PlasticImpactIconFilled from '@/components/icons/ImpactIcons/filled/PlasticImpactIconFilled.vue'
import type { IconStyleType } from '@/helpers/interfaces'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PlasticImpactIcon',
  components: {
    PlasticImpactIconSimple,
    PlasticImpactIconIsometric,
    PlasticImpactIconFlat,
    PlasticImpactIconFilled,
  },
  computed: {
    componentName(): string {
      return (
        'PlasticImpactIcon' + this.iconStyle.slice(0, 1).toUpperCase() + this.iconStyle.slice(1)
      )
    },
  },
  props: {
    iconStyle: {
      type: String as PropType<IconStyleType>,
      required: false,
      default: 'flat',
    },
  },
})
</script>

<style scoped lang="scss"></style>
