import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "type-text" }
const _hoisted_6 = {
  role: "button",
  class: "select"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "account-type",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('typeClick')))
  }, [
    (_ctx.text === 'Business')
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "image",
          src: require('@/assets/icons/main-shape.svg'),
          alt: "e-commerce"
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.text === 'Team')
      ? (_openBlock(), _createElementBlock("img", {
          key: 1,
          class: "image",
          src: require('@/assets/icons/main-shape.svg'),
          alt: "team"
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.text === 'Personal')
      ? (_openBlock(), _createElementBlock("img", {
          key: 2,
          class: "image",
          src: require('@/assets/icons/main-shape.svg'),
          alt: "personal"
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true),
    (_ctx.text === 'Family')
      ? (_openBlock(), _createElementBlock("img", {
          key: 3,
          class: "image",
          src: require('@/assets/icons/main-shape.svg'),
          alt: "family"
        }, null, 8, _hoisted_4))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.text), 1),
    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.t('select')), 1)
  ]))
}