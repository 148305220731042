<template>
  <component :is="componentName" />
</template>

<script lang="ts">
import KelpImpactIconFlat from '@/components/icons/ImpactIcons/flat/KelpImpactIconFlat.vue'
import KelpImpactIconIsometric from '@/components/icons/ImpactIcons/isometric/KelpImpactIconIsometric.vue'
import KelpImpactIconSimple from '@/components/icons/ImpactIcons/simple/KelpImpactIconSimple.vue'
import KelpImpactIconFilled from '@/components/icons/ImpactIcons/filled/KelpImpactIconFilled.vue'
import type { IconStyleType } from '@/helpers/interfaces'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'KelpImpactIcon',
  components: {
    KelpImpactIconSimple,
    KelpImpactIconIsometric,
    KelpImpactIconFlat,
    KelpImpactIconFilled,
  },
  computed: {
    componentName(): string {
      return 'KelpImpactIcon' + this.iconStyle.slice(0, 1).toUpperCase() + this.iconStyle.slice(1)
    },
  },
  props: {
    iconStyle: {
      type: String as PropType<IconStyleType>,
      required: false,
      default: 'flat',
    },
  },
})
</script>

<style scoped lang="scss"></style>
