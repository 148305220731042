import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "onboarding-panel" }
const _hoisted_2 = { class: "onboarding-panel__text-wrapper" }
const _hoisted_3 = { class: "onboarding-panel__title" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _renderSlot(_ctx.$slots, "navigationBack", {}, undefined, true),
      _createElementVNode("p", {
        class: "onboarding-panel__description",
        innerHTML: _ctx.description
      }, null, 8, _hoisted_4)
    ]),
    (_ctx.isSkippable)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "skip-button",
          role: "button",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSkip && _ctx.onSkip(...args)), ["prevent"]))
        }, [
          _createTextVNode(_toDisplayString(_ctx.skipButtonText) + " ", 1),
          _createVNode(_component_v_icon, {
            color: "green",
            size: 24
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode(" mdi-chevron-right ")
            ])),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "onboarding-panel__content",
      style: _normalizeStyle({ backgroundColor: _ctx.backgroundColor })
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 4)
  ]))
}