import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "user-component" }
const _hoisted_2 = { class: "user-component-wrapper" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "user-wrapper" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "form-wrapper" }
const _hoisted_7 = { class: "header" }
const _hoisted_8 = { class: "name-wrapper" }
const _hoisted_9 = { class: "text-field-wrapper" }
const _hoisted_10 = {
  key: 0,
  class: "name-wrapper"
}
const _hoisted_11 = { class: "text-field-wrapper" }
const _hoisted_12 = { class: "name-wrapper" }
const _hoisted_13 = { class: "text-field-wrapper" }
const _hoisted_14 = { class: "name-wrapper" }
const _hoisted_15 = { class: "text-field-wrapper" }
const _hoisted_16 = { class: "name-wrapper" }
const _hoisted_17 = { class: "text-field-wrapper" }
const _hoisted_18 = { class: "button-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CarouselCard = _resolveComponent("CarouselCard")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_gs_button = _resolveComponent("gs-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.$route.name === 'DirectMarketplaceSignup')
        ? (_openBlock(), _createBlock(_component_CarouselCard, {
            key: 0,
            class: "carousel-card"
          }))
        : (_openBlock(), _createElementBlock("img", {
            key: 1,
            class: "user-image",
            src: require('@/assets/user-info-shape.jpg'),
            alt: "signup"
          }, null, 8, _hoisted_3)),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: "back",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.accountType === 'ecommerce' ? _ctx.$emit('ecommerceBackClick') : _ctx.$emit('backClick')))
        }, [
          _createElementVNode("img", {
            class: "chevron",
            alt: "chevron",
            src: require('@/assets/icons/chevron_left.svg'),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('backClick')))
          }, null, 8, _hoisted_5),
          _createElementVNode("div", null, _toDisplayString(_ctx.t('back')), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.t('header')), 1),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.firstName,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.firstName) = $event)),
                label: _ctx.t('first_name'),
                variant: "outlined",
                type: "text",
                flat: "",
                color: "#3B755F",
                class: "left-input",
                rules: [_ctx.rules.required]
              }, null, 8, ["modelValue", "label", "rules"]),
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.lastName,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.lastName) = $event)),
                label: _ctx.t('last_name'),
                variant: "outlined",
                type: "text",
                flat: "",
                color: "#3B755F",
                class: "right-input",
                rules: [_ctx.rules.required]
              }, null, 8, ["modelValue", "label", "rules"])
            ])
          ]),
          (_ctx.accountType === 'business' || _ctx.accountType === 'ecommerce')
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.companyName,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.companyName) = $event)),
                    label: _ctx.t('company_name'),
                    variant: "outlined",
                    type: "text",
                    flat: "",
                    color: "#3B755F",
                    class: "left-input",
                    rules: [_ctx.rules.required]
                  }, null, 8, ["modelValue", "label", "rules"]),
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.vatId,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.vatId) = $event)),
                    rules: _ctx.isEUCountry(_ctx.country) ? [_ctx.rules.required] : [],
                    label: _ctx.isEUCountry(_ctx.country) ? _ctx.t('vat_id_required') : _ctx.t('vat_id'),
                    variant: "outlined",
                    type: "text",
                    flat: "",
                    color: "#3B755F",
                    class: "right-input"
                  }, null, 8, ["modelValue", "rules", "label"])
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_v_select, {
                modelValue: _ctx.country,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.country) = $event)),
                items: _ctx.countriesTemp,
                label: _ctx.t('country'),
                variant: "outlined",
                color: "#3B755F",
                "item-color": "#3B755F",
                "item-title": "name",
                "item-value": "alpha2",
                rules: [_ctx.rules.required]
              }, null, 8, ["modelValue", "items", "label", "rules"])
            ])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.street,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.street) = $event)),
                label: _ctx.t('street'),
                variant: "outlined",
                type: "text",
                flat: "",
                color: "#3B755F",
                rules: [_ctx.rules.required]
              }, null, 8, ["modelValue", "label", "rules"])
            ])
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.postCode,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.postCode) = $event)),
                label: _ctx.t('postal_code'),
                variant: "outlined",
                type: "text",
                flat: "",
                color: "#3B755F",
                class: "left-input",
                rules: [_ctx.rules.required]
              }, null, 8, ["modelValue", "label", "rules"]),
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.city,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.city) = $event)),
                label: _ctx.t('city'),
                variant: "outlined",
                type: "text",
                flat: "",
                color: "#3B755F",
                class: "right-input",
                rules: [_ctx.rules.required]
              }, null, 8, ["modelValue", "label", "rules"])
            ])
          ]),
          _createElementVNode("div", _hoisted_18, [
            _createVNode(_component_gs_button, {
              disabled: !_ctx.buttonActive,
              "full-width": true,
              size: "large",
              onClick: _withModifiers(_ctx.onNextClicked, ["prevent"])
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('continue')), 1)
              ]),
              _: 1
            }, 8, ["disabled", "onClick"])
          ])
        ])
      ])
    ])
  ]))
}