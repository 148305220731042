import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "analytics__title" }
const _hoisted_2 = { class: "analytics__description" }
const _hoisted_3 = {
  key: 0,
  class: "analytics__menu-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopStatsPublic = _resolveComponent("TopStatsPublic")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_panel = _resolveComponent("panel")!

  return (_openBlock(), _createBlock(_component_panel, { class: "analytics" }, {
    default: _withCtx(() => [
      _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.t('title')), 1),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.t('description')), 1),
      _createVNode(_component_TopStatsPublic, {
        theme: "guarded",
        class: "analytics__top-stats"
      }),
      _createElementVNode("h2", {
        class: _normalizeClass(['analytics__title', { 'no-subscription': !_ctx.subscription }])
      }, _toDisplayString(_ctx.t('overview_title')), 3),
      (!!_ctx.subscription)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nestedRoutes, (route, index) => {
              return (_openBlock(), _createBlock(_component_router_link, {
                key: index,
                class: "analytics__menu-item",
                to: { name: route.name }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t(_ctx.snakeCase(route.name as string))), 1)
                ]),
                _: 2
              }, 1032, ["to"]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (_openBlock(), _createBlock(_component_router_view, {
        key: _ctx.$route.fullPath
      }))
    ]),
    _: 1
  }))
}