import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "popup-header" }
const _hoisted_2 = { class: "popup-title" }
const _hoisted_3 = { class: "totals-wrapper section" }
const _hoisted_4 = { class: "cart-items-text" }
const _hoisted_5 = { class: "total-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImpactSettings = _resolveComponent("ImpactSettings")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    width: "1175",
    modelValue: _ctx.isOpen,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isOpen) = $event)),
    persistent: "",
    fullscreen: _ctx.$vuetify.display.xs
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, { class: "card d-flex flex-column" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.selectedProducts && _ctx.selectedProducts?.length > 1
              ? _ctx.t('choose_bulk_impact', { count: _ctx.selectedProducts?.length.toString() ?? '0' })
              : _ctx.t('choose_impact')), 1),
            _createElementVNode("button", {
              class: "close-button",
              onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["prevent"])),
              variant: "plain"
            })
          ]),
          _createVNode(_component_ImpactSettings, {
            modelValue: _ctx.impactSettings,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.impactSettings) = $event)),
            "max-values": _ctx.maxValues
          }, null, 8, ["modelValue", "max-values"]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.getImpactSettingCartText(_ctx.impactSettings, _ctx.selectedTrigger)), 1),
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.t('total', {
              price: _ctx.userCurrencySymbol + _ctx.getImpactSettingTotal(_ctx.impactSettings),
            })), 1)
          ]),
          _createVNode(_component_gs_button, {
            onClick: _ctx.handleSave,
            type: _ctx.automationId ? 'primary' : 'tertiary',
            size: "large",
            "full-width": ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t(_ctx.automationId ? 'save_impact' : 'set_impact')), 1)
            ]),
            _: 1
          }, 8, ["onClick", "type"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue", "fullscreen"]))
}