<template>
  <svg fill="none" height="17" viewBox="0 0 16 17" width="16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.8283 3.61028C11.6516 3.60078 11.5062 3.75415 11.4089 3.85678C11.1888 4.08902 11.1353 4.40032 11.1608 4.6202C11.1671 4.67399 11.1282 4.66676 11.2069 4.8513C11.3192 5.11509 11.3215 5.2542 11.2628 5.32794C11.1418 5.48055 10.8337 5.31901 10.4979 5.52217C10.2974 5.64342 10.2075 5.83309 10.1763 5.89885C9.99562 6.28028 10.2336 6.47812 10.1338 6.83256C9.98572 7.35918 9.3445 7.33372 9.28072 7.779C9.23236 8.11653 9.57144 8.27123 9.51871 8.62833C9.49357 8.79861 9.36697 8.98429 9.03589 9.18612C8.91347 9.46568 9.36506 9.68652 9.3485 10.0501C9.33384 10.3686 8.97306 10.5068 8.76059 10.7711C8.73089 10.8164 8.69148 10.8874 8.66825 10.9815C8.65054 11.0524 8.64502 11.125 8.65092 11.2044C8.67053 11.4631 8.80742 11.7191 8.84398 11.8633C8.88795 12.0368 8.85273 12.3096 8.47177 12.7324C8.44835 12.772 8.41751 12.8392 8.42398 12.9206C8.42493 12.9335 8.42513 12.9664 8.44359 13.0038C8.52146 13.1602 8.76249 13.3073 8.87596 13.5196C8.90452 13.5694 8.92527 13.6234 8.93308 13.6836C8.93555 13.7021 8.93631 13.7197 8.93612 13.7368V13.7397C8.94279 14.0468 8.63531 14.1127 8.64445 14.466C8.65169 14.7424 8.88643 14.8142 8.85921 15.0862C8.82113 15.4667 8.21703 15.6755 8.22332 15.6869C8.23055 15.7 9.06939 15.4929 9.7089 14.7253C10.2062 14.1283 10.3328 13.493 10.3716 13.2074C10.3476 12.4242 11.4934 12.3282 10.8954 11.4372C10.1824 10.3274 11.7057 10.628 11.1496 9.70932C10.3513 9.07399 11.6946 9.10117 11.2459 8.36188C11.1665 8.23094 11.1945 8.13819 11.1958 8.10626C11.2072 7.85882 11.6006 7.83697 11.8311 7.56349C12.1293 7.20999 11.802 6.85479 12.0548 6.17632C12.178 5.84583 12.3018 5.8063 12.4031 5.6459C12.4031 5.6459 12.4501 5.56721 12.4865 5.46041C12.6007 5.12611 12.3627 3.63898 11.8285 3.61009L11.8283 3.61028Z"
      fill="#F9F9F9"
    />
    <path
      d="M7.82962 13.5945C7.84542 13.6205 7.87227 13.6639 7.90482 13.7228C7.99354 13.883 8.26028 14.3803 8.26218 14.9989C8.26351 15.4292 8.13595 15.6411 8.22372 15.6881C8.32044 15.7398 8.55519 15.5252 8.67894 15.3338C8.72787 15.2584 8.79203 15.1713 8.80097 15.0459C8.81678 14.8235 8.64905 14.7587 8.63838 14.4837C8.62391 14.1067 8.93082 14.0078 8.93634 13.7408V13.738C8.93405 13.658 8.91121 13.5863 8.87618 13.5207C8.76118 13.3204 8.51597 13.1885 8.44381 13.005C8.43372 12.9795 8.41982 12.9447 8.41906 12.9004C8.41754 12.8046 8.47427 12.7332 8.51787 12.6767C8.63515 12.5251 8.72253 12.3998 8.73377 12.3835C8.79793 12.29 8.86171 12.1387 8.84819 11.8896C8.83429 11.863 8.8103 11.8166 8.78289 11.758C8.6757 11.5288 8.59231 11.3458 8.6104 11.1362C8.61668 11.0638 8.63324 11.0096 8.63915 10.9914C8.66618 10.908 8.70902 10.8365 8.76061 10.7725C8.91997 10.4404 9.07913 10.1373 8.52244 9.7403C7.17069 8.76497 9.53092 9.1808 8.41373 8.08155C7.80449 7.63988 7.99259 7.36849 8.31244 7.19156C9.34472 6.74171 8.69264 6.50434 8.69264 6.50434C7.90977 6.15826 8.53215 5.59211 8.53196 5.59211C9.57509 4.40715 8.33586 5.0366 8.52967 4.44003C8.52967 4.44003 9.01611 3.58215 8.46037 3.63176C7.55356 3.66445 8.64314 2.34323 7.37879 2C6.98868 2 7.00829 2.31149 7.09244 2.56615C7.23999 2.8632 6.65627 3.27466 7.14423 3.36474C7.78374 3.56505 7.36641 3.90372 7.36641 3.90372C6.85123 4.1715 7.47836 4.36953 7.5469 4.66505C7.60877 4.79999 7.67903 5.01094 7.59621 5.19681C7.40011 5.63658 6.55099 5.47561 6.40934 5.85114C6.28502 6.18088 6.87026 6.49009 6.75375 6.90933C6.65208 7.27499 6.15098 7.24059 6.07787 7.58477C5.99449 7.97665 6.59858 8.23378 6.50206 8.65664C6.43923 8.93259 6.15041 8.96205 6.14527 9.22622C6.14356 9.30889 6.08758 9.46169 6.49482 9.79503C6.71586 9.97596 6.87007 10.2274 6.92643 10.3836C6.98507 10.5457 7.01686 10.8508 6.67264 11.3683C6.75108 11.4462 6.86722 11.5762 6.96393 11.7605C7.17184 12.1562 7.08254 12.4048 7.20706 12.7731C7.34147 13.1707 7.51091 13.0734 7.82924 13.5954L7.82962 13.5945Z"
      fill="#F9F9F9"
    />
    <path
      d="M8.22381 15.6878C8.25522 15.4322 8.26645 15.2036 8.26226 14.9987C8.25027 14.6277 8.17907 14.3332 8.0755 14.0789C8.07511 14.0779 8.07492 14.0772 8.07492 14.0772C8.03018 13.9571 7.95479 13.7834 7.82971 13.5943C7.59229 13.2351 7.44684 13.2299 7.2932 12.9595C7.11309 12.6425 7.28006 12.5916 7.08434 12.0799C6.96897 11.7785 6.88482 11.5581 6.67311 11.3671C6.57106 11.2749 6.47948 11.2257 6.40428 11.0845C6.30947 10.9062 6.3763 10.8359 6.29728 10.6664C6.23503 10.5329 6.16668 10.5187 5.86168 10.257C5.64331 10.0696 5.53402 9.97573 5.5028 9.91643C5.3444 9.61539 5.61665 9.41128 5.50966 9.09827C5.37772 8.71209 4.88195 8.78355 4.79513 8.43633C4.71612 8.12028 5.08109 7.87892 4.93773 7.58321C4.88728 7.47925 4.83435 7.47868 4.7264 7.32512C4.66053 7.23143 4.59561 7.09193 4.55391 6.84715C4.62512 6.45964 5.23873 6.24013 4.99866 5.74201C4.69709 4.94096 5.3682 4.89402 5.10965 4.21478C5.04854 3.83317 4.79437 2.47033 4.28242 2.46387C3.63911 2.5644 4.88538 2.84301 4.29461 4.44645C4.29385 4.47572 4.28585 4.63745 4.2135 4.6747C3.36209 4.48161 3.97057 5.49951 3.71317 5.71579C3.64444 5.77356 3.58523 5.70039 3.45005 5.73688C3.21835 5.7996 3.04034 6.08201 3.00607 6.32869C2.94077 6.79811 3.42454 6.934 3.3897 7.39696C3.35924 7.80309 2.95657 7.94335 3.05081 8.21949C3.13325 8.46104 3.42987 8.42265 3.53478 8.6834C3.65453 8.98101 3.3244 9.21002 3.39065 9.53234C3.43387 9.74273 3.6393 9.97136 4.23426 10.2367C4.47928 10.6574 3.61988 11.4368 4.91451 11.3809C5.05482 11.3963 5.28367 11.4427 5.4453 11.6162C5.81713 12.0155 5.35658 12.5764 5.6534 13.1138C5.98258 13.7102 6.81342 13.4981 7.08453 14.1481C7.19991 14.4246 7.06245 14.4938 7.2199 14.8573C7.22428 14.8672 7.23018 14.8811 7.23817 14.8982C7.50395 15.4719 8.00791 15.639 8.00791 15.639C8.05417 15.6546 8.12804 15.6759 8.22381 15.6878Z"
      fill="#F9F9F9"
    />
  </svg>
</template>
