import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = {
  key: 0,
  class: "parter-image-wrapper"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  class: "impact-pills"
}
const _hoisted_6 = {
  key: 3,
  style: {"min-width":"24px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuickMenu = _resolveComponent("QuickMenu")!

  return (_openBlock(), _createElementBlock("div", {
    id: `number${_ctx.elementId}`,
    class: _normalizeClass(
      _ctx.numbers && _ctx.numbers < (_ctx.totalRows ?? 0 - 1) && !_ctx.hidePartnerImage
        ? 'table-row-outer' + ' ' + 'hideScrollBar' + ' ' + _ctx.rowColour
        : _ctx.numbers && _ctx.numbers < (_ctx.totalRows ?? 0 - 1) && _ctx.hidePartnerImage
        ? 'table-row-outer' + ' ' + 'hideScrollBar' + ' ' + 'no-padding' + ' ' + _ctx.rowColour
        : _ctx.numbers && _ctx.numbers >= (_ctx.totalRows ?? 0 - 1) && _ctx.hidePartnerImage
        ? 'table-row-outer' + ' ' + 'showScrollBar' + ' ' + 'no-padding' + ' ' + _ctx.rowColour
        : 'table-row-outer' + ' ' + 'showScrollBar' + ' ' + _ctx.rowColour
    )
  }, [
    (!_ctx.hidePartnerImage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("img", {
            src: _ctx.partnerImage,
            class: "partner-image",
            alt: "image"
          }, null, 8, _hoisted_3)
        ]))
      : _createCommentVNode("", true),
    (_ctx.name)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          style: _normalizeStyle(`width: ${_ctx.columnWidth}px; min-width: 115px;`),
          class: "name"
        }, _toDisplayString(_ctx.name), 5))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.labels, (item, a) => {
      return (_openBlock(), _createElementBlock("div", {
        key: a,
        class: "items-outer",
        style: _normalizeStyle(
        item.customWidth
          ? `min-width: ${item.customWidth}px`
          : `width: ${_ctx.columnWidth}px; min-width: 115px;`
      )
      }, [
        (item.type === 'text')
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(item.text), 1))
          : _createCommentVNode("", true),
        (item.type === 'impact' && item.labels.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.labels, (impactItem, b) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: b,
                  style: _normalizeStyle(b < item.labels.length - 1 ? 'margin-bottom: 6px;' : '')
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass('pill' + ' ' + impactItem.type)
                  }, [
                    _createElementVNode("div", null, _toDisplayString(impactItem.text), 1)
                  ], 2)
                ], 4))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ], 4))
    }), 128)),
    _createElementVNode("div", {
      style: _normalizeStyle(
        _ctx.status?.customWidth
          ? `min-width: ${_ctx.status?.customWidth}px`
          : `width: ${_ctx.columnWidth}px; min-width: 115px;`
      )
    }, [
      (_ctx.status)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.status.text)
          }, _toDisplayString(_ctx.status.text), 3))
        : _createCommentVNode("", true)
    ], 4),
    (_ctx.quickMenuItems && _ctx.quickMenuItems.length > 0)
      ? (_openBlock(), _createBlock(_component_QuickMenu, {
          key: 2,
          "quick-menu-items": _ctx.quickMenuItems,
          "user-id": _ctx.userId,
          onQuickMenuClick: _ctx.quickMenuClick
        }, null, 8, ["quick-menu-items", "user-id", "onQuickMenuClick"]))
      : _createCommentVNode("", true),
    (_ctx.quickMenuItems && _ctx.quickMenuItems.length === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}