<template>
  <path
    d="M26.0001 20.5189C27.7952 20.5189 29.2586 19.0302 29.2586 17.207C29.2586 16.3385 28.927 15.5456 28.3847 14.9522C28.6427 14.4937 28.9006 14.0406 29.1638 13.5821C29.148 13.5336 29.0112 13.1722 28.6374 13.0427C28.3953 12.9618 28.19 13.0158 28.111 13.0427C27.9215 13.4581 27.7267 13.868 27.5372 14.2834C27.0792 14.0352 26.5581 13.895 26.0054 13.895C25.4526 13.895 24.9315 14.0352 24.4735 14.2834C24.284 13.868 24.0892 13.4581 23.8997 13.0427C23.8207 13.0158 23.6154 12.9618 23.3733 13.0427C22.9995 13.1722 22.8627 13.5336 22.8469 13.5821C23.1048 14.0406 23.3628 14.4937 23.626 14.9522C23.0838 15.5456 22.7521 16.3331 22.7521 17.207C22.7521 19.0356 24.2155 20.5189 26.0106 20.5189H26.0001Z"
    fill="white"
  />
  <path
    d="M21.6886 17.2016C21.6886 17.029 21.6992 16.8617 21.715 16.6945C18.925 16.1174 16.4719 16.9912 16.056 18.7443C15.6401 20.5459 17.5826 22.504 20.4779 23.2106C20.5674 23.2322 20.6516 23.2484 20.7411 23.2645C20.7569 23.2052 20.7727 23.1459 20.7937 23.0865C21.1833 21.8243 21.8097 20.7239 22.6099 19.9094C22.0308 19.165 21.6886 18.2211 21.6886 17.2016Z"
    fill="white"
  />
  <path
    d="M26 23.8313C24.4629 23.8313 22.9626 24.0741 21.5255 24.5326C21.4571 24.9263 21.4097 25.3363 21.3939 25.7516C22.8784 25.2985 24.4208 25.0558 26 25.0558C27.5792 25.0558 29.1322 25.2931 30.6061 25.7301C30.5851 25.3093 30.5377 24.8994 30.4693 24.5056C28.5847 23.923 26.8896 23.8259 26 23.8259V23.8313Z"
    fill="white"
  />
  <path
    d="M21.4202 26.8737C21.5044 27.5318 21.715 28.1629 21.9992 28.7454C23.2942 28.4488 24.6313 28.2977 25.9947 28.2977C27.6108 28.2977 29.0163 28.5243 29.9955 28.7454C30.2797 28.1575 30.4903 27.5264 30.5745 26.8683C29.1216 26.4044 27.5213 26.1401 25.9947 26.1401C24.4681 26.1401 22.8836 26.3936 21.4149 26.8737H21.4202Z"
    fill="white"
  />
  <path
    d="M22.5678 29.7264C23.8101 31.5928 25.5947 32.8334 25.7105 32.909C25.8895 33.033 26.1106 33.0276 26.2895 32.909C26.4054 32.8334 28.1899 31.5928 29.4322 29.7264C28.5321 29.5431 27.3424 29.3758 26 29.3758C24.8314 29.3758 23.6838 29.4999 22.5678 29.7264Z"
    fill="white"
  />
  <path
    d="M26 21.5985C25.0261 21.5985 24.1312 21.264 23.4048 20.7085C23.389 20.6977 23.3732 20.6815 23.3521 20.6707C22.6994 21.3396 22.1782 22.2512 21.836 23.3084C23.1837 22.9416 24.5787 22.7528 26.0052 22.7528C27.4318 22.7528 28.8216 22.947 30.1692 23.303C29.827 22.2458 29.3006 21.3342 28.6531 20.6707L28.6005 20.7085C27.874 21.264 26.9791 21.5985 26.0052 21.5985H26Z"
    fill="white"
  />
  <path
    d="M35.9439 18.7389C35.528 16.9913 33.0749 16.1174 30.2849 16.6946C30.3007 16.8618 30.3113 17.029 30.3113 17.2016C30.3113 18.2211 29.9691 19.1597 29.39 19.9041L29.4006 19.9148C30.1902 20.724 30.8166 21.8244 31.2114 23.092C31.2272 23.1459 31.2483 23.2052 31.2588 23.2646C31.3483 23.2484 31.4378 23.2268 31.5273 23.2052C34.4173 22.504 36.3597 20.546 35.9439 18.7389Z"
    fill="white"
  />
</template>
