import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "employee-wrapper" }
const _hoisted_2 = { class: "main-wrapper" }
const _hoisted_3 = { class: "header-wrapper" }
const _hoisted_4 = { class: "header" }
const _hoisted_5 = { class: "columns-wrapper" }
const _hoisted_6 = { class: "column" }
const _hoisted_7 = { class: "employee-type-wrapper" }
const _hoisted_8 = { class: "plan-name" }
const _hoisted_9 = {
  key: 0,
  class: "plan-price"
}
const _hoisted_10 = {
  key: 1,
  class: "plan-price"
}
const _hoisted_11 = {
  key: 2,
  class: "plan-price"
}
const _hoisted_12 = { class: "per-employee" }
const _hoisted_13 = { class: "plan-description" }
const _hoisted_14 = { class: "text-field-wrapper" }
const _hoisted_15 = { class: "plus-minus" }
const _hoisted_16 = { class: "description" }
const _hoisted_17 = { class: "item" }
const _hoisted_18 = ["src"]
const _hoisted_19 = { class: "text" }
const _hoisted_20 = { class: "item" }
const _hoisted_21 = ["src"]
const _hoisted_22 = { class: "text" }
const _hoisted_23 = { class: "column" }
const _hoisted_24 = { class: "employee-type-wrapper" }
const _hoisted_25 = { class: "plan-name" }
const _hoisted_26 = {
  key: 0,
  class: "plan-price"
}
const _hoisted_27 = {
  key: 1,
  class: "plan-price"
}
const _hoisted_28 = {
  key: 2,
  class: "plan-price"
}
const _hoisted_29 = { class: "per-employee" }
const _hoisted_30 = { class: "plan-description" }
const _hoisted_31 = { class: "text-field-wrapper" }
const _hoisted_32 = { class: "plus-minus" }
const _hoisted_33 = { class: "description" }
const _hoisted_34 = { class: "item" }
const _hoisted_35 = ["src"]
const _hoisted_36 = { class: "text" }
const _hoisted_37 = { class: "item" }
const _hoisted_38 = ["src"]
const _hoisted_39 = { class: "text" }
const _hoisted_40 = { class: "column" }
const _hoisted_41 = { class: "employee-type-wrapper" }
const _hoisted_42 = { class: "plan-name" }
const _hoisted_43 = {
  key: 0,
  class: "plan-price"
}
const _hoisted_44 = {
  key: 1,
  class: "plan-price"
}
const _hoisted_45 = {
  key: 2,
  class: "plan-price"
}
const _hoisted_46 = { class: "per-employee" }
const _hoisted_47 = { class: "plan-description" }
const _hoisted_48 = { class: "text-field-wrapper" }
const _hoisted_49 = { class: "plus-minus" }
const _hoisted_50 = { class: "description" }
const _hoisted_51 = { class: "item" }
const _hoisted_52 = ["src"]
const _hoisted_53 = { class: "text" }
const _hoisted_54 = { class: "item" }
const _hoisted_55 = ["src"]
const _hoisted_56 = { class: "text" }
const _hoisted_57 = { class: "button-wrapper" }
const _hoisted_58 = { class: "small-info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_gs_button = _resolveComponent("gs-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t('subscription')), 1),
        _createElementVNode("div", {
          class: "back",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/choose-signup')))
        }, [
          _createElementVNode("div", null, _toDisplayString(_ctx.t('cancel')), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.t('personal')), 1),
            (_ctx.account.currency === 'euro')
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.currencyEuro(_ctx.personalPrice)), 1))
              : _createCommentVNode("", true),
            (_ctx.account.currency === 'pound')
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.currencyPound(_ctx.personalPrice)), 1))
              : _createCommentVNode("", true),
            (_ctx.account.currency === 'dollar')
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.currencyDollar(_ctx.personalPrice)), 1))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.t('per_month')), 1),
            _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.t('personal_description')), 1),
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.personalAmount,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.personalAmount) = $event)),
                modelModifiers: { number: true },
                label: _ctx.t('amount'),
                variant: "outlined",
                type: "number",
                flat: "",
                min: "0",
                color: "#3B755F",
                class: "employee-input"
              }, null, 8, ["modelValue", "label"]),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", {
                  class: "add",
                  style: {"margin-bottom":"1px"},
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (!!_ctx.personalAmount ? _ctx.personalAmount++ : ''))
                }, " + "),
                _createElementVNode("div", {
                  class: "add",
                  style: {"margin-top":"1px"},
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.personalAmount !== 0 && !!_ctx.personalAmount ? _ctx.personalAmount-- : ''))
                }, " - ")
              ])
            ]),
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("img", {
                  class: "icon",
                  alt: "check",
                  src: require('@/assets/icons/check_simple.svg')
                }, null, 8, _hoisted_18),
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("strong", null, _toDisplayString(_ctx.t('plastic')), 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.t('removed_month')), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("img", {
                  class: "icon",
                  alt: "check",
                  src: require('@/assets/icons/check_simple.svg')
                }, null, 8, _hoisted_21),
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("strong", null, "1 " + _toDisplayString(_ctx.t('mads_footprint')) + "*", 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.t('removed_year')), 1)
                ])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_23, [
          _createElementVNode("div", _hoisted_24, [
            _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.t('plastic_hero')), 1),
            (_ctx.account.currency === 'euro')
              ? (_openBlock(), _createElementBlock("div", _hoisted_26, _toDisplayString(_ctx.currencyEuro(_ctx.plasticHeroPrice)), 1))
              : _createCommentVNode("", true),
            (_ctx.account.currency === 'pound')
              ? (_openBlock(), _createElementBlock("div", _hoisted_27, _toDisplayString(_ctx.currencyPound(_ctx.plasticHeroPrice)), 1))
              : _createCommentVNode("", true),
            (_ctx.account.currency === 'dollar')
              ? (_openBlock(), _createElementBlock("div", _hoisted_28, _toDisplayString(_ctx.currencyDollar(_ctx.plasticHeroPrice)), 1))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.t('per_month')), 1),
            _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.t('plastic_hero_description')), 1),
            _createElementVNode("div", _hoisted_31, [
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.plasticHeroAmount,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.plasticHeroAmount) = $event)),
                modelModifiers: { number: true },
                label: _ctx.t('amount'),
                variant: "outlined",
                type: "number",
                flat: "",
                min: "0",
                color: "#3B755F",
                class: "employee-input"
              }, null, 8, ["modelValue", "label"]),
              _createElementVNode("div", _hoisted_32, [
                _createElementVNode("div", {
                  class: "add",
                  style: {"margin-bottom":"1px"},
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (!!_ctx.plasticHeroAmount ? _ctx.plasticHeroAmount++ : ''))
                }, " + "),
                _createElementVNode("div", {
                  class: "add",
                  style: {"margin-top":"1px"},
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.plasticHeroAmount !== 0 && !!_ctx.plasticHeroAmount ? _ctx.plasticHeroAmount-- : ''))
                }, " - ")
              ])
            ]),
            _createElementVNode("div", _hoisted_33, [
              _createElementVNode("div", _hoisted_34, [
                _createElementVNode("img", {
                  class: "icon",
                  alt: "check",
                  src: require('@/assets/icons/check_simple.svg')
                }, null, 8, _hoisted_35),
                _createElementVNode("div", _hoisted_36, [
                  _createElementVNode("strong", null, _toDisplayString(_ctx.t('plastic_plus')), 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.t('removed_month')), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_37, [
                _createElementVNode("img", {
                  class: "icon",
                  alt: "check",
                  src: require('@/assets/icons/check_simple.svg')
                }, null, 8, _hoisted_38),
                _createElementVNode("div", _hoisted_39, [
                  _createElementVNode("strong", null, "1 " + _toDisplayString(_ctx.t('mads_footprint')) + "s*", 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.t('removed_year')), 1)
                ])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_40, [
          _createElementVNode("div", _hoisted_41, [
            _createElementVNode("div", _hoisted_42, _toDisplayString(_ctx.t('earth_hero')), 1),
            (_ctx.account.currency === 'euro')
              ? (_openBlock(), _createElementBlock("div", _hoisted_43, _toDisplayString(_ctx.currencyEuro(_ctx.earthHeroPrice)), 1))
              : _createCommentVNode("", true),
            (_ctx.account.currency === 'pound')
              ? (_openBlock(), _createElementBlock("div", _hoisted_44, _toDisplayString(_ctx.currencyPound(_ctx.earthHeroPrice)), 1))
              : _createCommentVNode("", true),
            (_ctx.account.currency === 'dollar')
              ? (_openBlock(), _createElementBlock("div", _hoisted_45, _toDisplayString(_ctx.currencyDollar(_ctx.earthHeroPrice)), 1))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_46, _toDisplayString(_ctx.t('per_month')), 1),
            _createElementVNode("div", _hoisted_47, _toDisplayString(_ctx.t('earth_hero_description')), 1),
            _createElementVNode("div", _hoisted_48, [
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.earthHeroAmount,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.earthHeroAmount) = $event)),
                modelModifiers: { number: true },
                label: _ctx.t('amount'),
                variant: "outlined",
                type: "number",
                flat: "",
                min: "0",
                color: "#3B755F",
                class: "employee-input"
              }, null, 8, ["modelValue", "label"]),
              _createElementVNode("div", _hoisted_49, [
                _createElementVNode("div", {
                  class: "add",
                  style: {"margin-bottom":"1px"},
                  onClick: _cache[8] || (_cache[8] = ($event: any) => (!!_ctx.earthHeroAmount ? _ctx.earthHeroAmount++ : ''))
                }, " + "),
                _createElementVNode("div", {
                  class: "add",
                  style: {"margin-top":"1px"},
                  onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.earthHeroAmount !== 0 && !!_ctx.earthHeroAmount ? _ctx.earthHeroAmount-- : ''))
                }, " - ")
              ])
            ]),
            _createElementVNode("div", _hoisted_50, [
              _createElementVNode("div", _hoisted_51, [
                _createElementVNode("img", {
                  class: "icon",
                  alt: "check",
                  src: require('@/assets/icons/check_simple.svg')
                }, null, 8, _hoisted_52),
                _createElementVNode("div", _hoisted_53, [
                  _createElementVNode("strong", null, _toDisplayString(_ctx.t('plastic_plus')), 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.t('removed_month')), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_54, [
                _createElementVNode("img", {
                  class: "icon",
                  alt: "check",
                  src: require('@/assets/icons/check_simple.svg')
                }, null, 8, _hoisted_55),
                _createElementVNode("div", _hoisted_56, [
                  _createElementVNode("strong", null, "4 " + _toDisplayString(_ctx.t('mads_footprint')) + "s*", 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.t('removed_year')), 1)
                ])
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_57, [
        _createVNode(_component_gs_button, {
          disabled: _ctx.totalEmployees === 0,
          "full-width": true,
          size: "large",
          onClick: _withModifiers(_ctx.onNextClicked, ["prevent"])
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('continue')), 1)
          ]),
          _: 1
        }, 8, ["disabled", "onClick"])
      ]),
      _createElementVNode("div", _hoisted_58, "*" + _toDisplayString(_ctx.t('info')), 1)
    ])
  ]))
}