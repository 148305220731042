import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "update-wrapper" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "button-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_gs_button = _resolveComponent("gs-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_v_text_field, {
        modelValue: _ctx.receiptName,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.receiptName) = $event)),
        class: "input-field",
        label: "Receipt Name",
        variant: "outlined",
        flat: "",
        color: "#3B755F",
        style: {"margin-right":"5px"}
      }, null, 8, ["modelValue"]),
      _createVNode(_component_v_text_field, {
        modelValue: _ctx.newImageUrl,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newImageUrl) = $event)),
        class: "input-field",
        label: "Image URL",
        variant: "outlined",
        flat: "",
        color: "#3B755F",
        style: {"margin-right":"5px"}
      }, null, 8, ["modelValue"]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_gs_button, {
          type: "danger",
          class: "button",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('deleteReceiptItem', _ctx.receiptId, _ctx.item.id)))
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode(" DELETE RECEIPT ")
          ])),
          _: 1
        })
      ])
    ])
  ]))
}