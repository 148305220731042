<template>
  <v-dialog v-model="isOpen" persistent max-width="540">
    <v-card class="card d-flex flex-column">
      <div class="popup-header">
        <p class="upgrade-title">
          {{ popupTitle }}
        </p>
        <button
          class="upgrade-close-button"
          @click.prevent="
            () => {
              isOpen = !isOpen
              $emit('close')
            }
          "
          variant="plain"
        >
          <v-icon class="close-icon"> mdi-close </v-icon>
        </button>
      </div>
      <div class="upgrade-text">
        <p v-if="popupType === 'generic'" v-html="planNames" />
        <div class="mb-0" v-html="popupText" />
      </div>
      <gs-button type="primary" size="large" full-width @click="clickOnUpgrade">
        {{ buttonLabel }}
      </gs-button>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { includes } from '@/helpers/parsers'
import type { TechnicalAndBusinessSubscriptionType } from '@/helpers/pricing'
import type { SubscriptionItem } from '@/store/subscriptions'
import { Utils } from '@/helpers/mixins/utilsMixin'
import type { TranslateResult } from 'vue-i18n'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import type { IntegratorSource } from '@/helpers/interfaces'

export type CallToUpgradePopupType =
  | 'generic'
  | 'template'
  | 'domain'
  | 'trigger'
  | 'integration'
  | 'team'
  | 'integrator'

export default defineComponent({
  name: 'CallToUpgradePopup',
  emits: ['close'],
  mixins: [Utils],
  data() {
    return {
      isOpen: true,
      currentPlanType: '',
    } as {
      isOpen: boolean
      currentPlanType: TechnicalAndBusinessSubscriptionType | ''
    }
  },
  computed: {
    nextPlan(): '' | 'growthBusiness' | 'premiumBusiness' | 'enterpriseBusiness' {
      switch (this.currentPlanType) {
        case 'starterBusiness':
        case 'starterBusinessYearly':
        case 'freeBusiness':
          return 'growthBusiness'
        case 'growthBusiness':
        case 'growthBusinessYearly':
          return 'premiumBusiness'
        case 'premiumBusiness':
        case 'premiumBusinessYearly':
          return 'enterpriseBusiness'
        case 'enterpriseBusiness':
          throw new Error('Enterprise plan does not support upgrade and downgrade')
        case 'ecommerce':
        case 'ECOMMERCE':
        case 'businessLegacy':
          return 'growthBusiness'
        case 'zero':
        case 'free':
          return this.isPremiumOnly ? 'premiumBusiness' : 'growthBusiness'
        default:
          return ''
      }
    },
    planNames(): TranslateResult {
      if (this.isPremiumOnly) return this.t('plans_text3')
      if (
        includes(['starterBusiness', 'starterBusinessYearly', 'freeBusiness'], this.currentPlanType)
      )
        return this.t('plans_text2')
      return this.t('plans_text1')
    },
    popupText(): TranslateResult {
      if (this.hasOwnPaidSubscriptionItem) {
        switch (this.popupType) {
          case 'trigger':
            return this.t('trigger_text', {
              currentPlan: this.formatPlanName(this.currentPlanType),
            })
          case 'integration':
            return this.isPremiumOnly
              ? this.t('integration_text_premium', {
                  currentPlan: this.formatPlanName(this.currentPlanType),
                  maxAmount: this.maxActiveIntegrationSources,
                  nextPlan: this.formatPlanName(this.nextPlan),
                  nextPlanMaxAmount: this.getSettingsByPlanName(this.nextPlan)
                    .maxActiveIntegrationSources,
                })
              : this.t('integration_text', {
                  currentPlan: this.formatPlanName(this.currentPlanType),
                  maxAmount: this.maxActiveIntegrationSources,
                  nextPlanMaxAmount: this.getSettingsByPlanName(this.nextPlan)
                    .maxActiveIntegrationSources,
                })
          case 'domain':
            return this.t('domain_text', {
              currentPlan: this.formatPlanName(this.currentPlanType),
              maxAmount: this.maxCustomDomainAmount,
              nextPlan: this.formatPlanName(this.nextPlan),
              nextPlanMaxAmount: this.getSettingsByPlanName(this.nextPlan).maxCustomDomainAmount,
            })
          case 'template':
            return this.t('template_text', {
              currentPlan: this.formatPlanName(this.currentPlanType),
              maxAmount: this.maxCustomEmailTemplateAmount,
              nextPlan: this.formatPlanName(this.nextPlan),
              nextPlanMaxAmount: this.getSettingsByPlanName(this.nextPlan)
                .maxCustomEmailTemplateAmount,
            })
          case 'generic':
            return this.t('upgrade_text', {
              currentPlan: this.formatPlanName(this.currentPlanType),
            })
          case 'team':
            return this.t('team_text', {
              currentPlan: this.formatPlanName(this.currentPlanType),
              maxAmount: this.maxTeamMemberCount,
              nextPlan: this.formatPlanName(this.nextPlan),
              nextPlanMaxAmount: this.getSettingsByPlanName(this.nextPlan).maxTeamMemberCount,
            })
          case 'integrator':
            return this.t('integrator_text', {
              currentPlan: this.formatPlanName(this.currentPlanType),
              integrator: this.capitalizeFirstLetter(this.integratorType || ''),
            })
          default:
            return this.t('upgrade_text', {
              currentPlan: this.formatPlanName(this.currentPlanType),
            })
        }
      } else if (this.hasPaidSubscriptionItem) {
        switch (this.popupType) {
          case 'domain':
            return this.t('domain_text_partner', {
              currentPlan: this.formatPlanName(this.currentPlanType),
              maxAmount: this.maxCustomDomainAmount,
              nextPlan: this.formatPlanName(this.nextPlan),
              nextPlanMaxAmount: this.getSettingsByPlanName(this.nextPlan).maxCustomDomainAmount,
            })
          case 'template':
            return this.t('template_text_partner', {
              currentPlan: this.formatPlanName(this.currentPlanType),
              maxAmount: this.maxCustomEmailTemplateAmount,
              nextPlan: this.formatPlanName(this.nextPlan),
              nextPlanMaxAmount: this.getSettingsByPlanName(this.nextPlan)
                .maxCustomEmailTemplateAmount,
            })
          case 'generic':
            return this.t('upgrade_text_partner', {
              currentPlan: this.formatPlanName(this.currentPlanType),
            })
          case 'team':
            return this.t('team_text_partner', {
              currentPlan: this.formatPlanName(this.currentPlanType),
              maxAmount: this.maxTeamMemberCount,
              nextPlan: this.formatPlanName(this.nextPlan),
              nextPlanMaxAmount: this.getSettingsByPlanName(this.nextPlan).maxTeamMemberCount,
            })
          default:
            return this.t('upgrade_text_partner', {
              currentPlan: this.formatPlanName(this.currentPlanType),
            })
        }
      } else {
        return this.t('select_plan_text_partner')
      }
    },
    popupTitle(): TranslateResult {
      if (this.popupType === 'integrator') {
        return this.t('integrator_title')
      } else if (this.hasPaidSubscriptionItem || !this.hasOwnPaidSubscriptionItem) {
        return this.t('call_to_upgrade_title')
      } else {
        return this.t('call_to_subscribe_title')
      }
    },
    buttonLabel(): TranslateResult {
      if (this.popupType === 'integrator') {
        return this.t('manage')
      } else if (this.hasPaidSubscriptionItem || !this.hasOwnPaidSubscriptionItem) {
        return this.t('upgrade')
      } else {
        return this.t('subscribe')
      }
    },
    highestActiveSubscriptionItem(): SubscriptionItem {
      return this.$store.getters['getHighestActiveSubscriptionItem']
    },
    hasPaidSubscriptionItem(): boolean {
      return this.$store.getters['hasPaidSubscriptionItem']
    },
    hasOwnPaidSubscriptionItem(): boolean {
      return this.$store.getters['hasOwnPaidSubscriptionItem']
    },
    customDomainAmount(): number {
      return this.$store.getters['getCustomDomainAmount']
    },
    activeEmailTemplateAmount(): number {
      return this.$store.getters['getActiveEmailTemplateAmount']
    },
    maxCustomDomainAmount(): number {
      return this.$store.getters['getMaxCustomDomainAmount']
    },
    maxActiveIntegrationSources(): number {
      return this.$store.getters['getMaxActiveIntegrationSources']
    },
    maxCustomEmailTemplateAmount(): number {
      return this.$store.getters['getMaxCustomEmailTemplateAmount']
    },
    maxTeamMemberCount(): number {
      return this.$store.getters['getMaxTeamMemberCount']
    },
  },
  async created() {
    this.currentPlanType = this.highestActiveSubscriptionItem
      .product as TechnicalAndBusinessSubscriptionType
  },
  methods: {
    t(key: string, params?: { [key: string]: string | number }) {
      return this.$t(`CallToUpgradePopup.${key}`, params ?? {})
    },
    async clickOnUpgrade() {
      if (document.documentElement.classList.contains('modal-open'))
        document.documentElement.classList.remove('modal-open')
      this.isOpen = false
      if (this.popupType === 'integrator') {
        await this.$router.push('/add-impact/manage-integrations-automations/automations')
      } else {
        await this.$router.push('/upgrade-plan')
      }
    },
  },
  props: {
    isPremiumOnly: {
      type: Boolean,
      required: true,
    },
    popupType: {
      default: 'generic',
      type: String as PropType<CallToUpgradePopupType>,
    },
    integratorType: {
      type: Object as PropType<IntegratorSource>,
    },
  },
})
</script>

<style lang="scss" scoped>
.card {
  position: relative;
  padding: 20px;
}

.upgrade-title {
  font-size: 26px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 20px;
  padding-right: 40px;
}

.upgrade-text {
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 40px;
}

.upgrade-text:deep(span) {
  background: var(--ui-beige);
  padding: 0 5px 0;
  border-radius: 6px;
  font-weight: 600;
  white-space: nowrap;
}

.upgrade-close-button {
  position: absolute;
  right: 20px;
  top: 20px;
  height: 20px;
  width: 20px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-icon {
  color: var(--font-color-primary);
}
</style>
