import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "loading-wrapper" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "progress-bar-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: "loading-logo",
      alt: "Loading ...",
      src: require('@/assets/icons/main-shape.svg')
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_v_progress_linear, {
        color: "#3B755F",
        indeterminate: "",
        rounded: "",
        height: "6"
      })
    ])
  ]))
}