<template>
  <section class="faq-card-section">
    <h3 class="section-title">{{ $t('FrequentlyAskedQuestions.title') }}</h3>
    <div class="faq-list">
      <div
        class="faq-list-content"
        v-for="(list, listIndex) in [firstHalfOfQuestions, secondHalfOfQuestions]"
        :key="listIndex"
      >
        <div class="faq-card" v-for="(faq, index) in list" :key="index">
          <div class="faq-card-header" @click="faq.expanded.value = !faq.expanded.value">
            <div class="faq-card-toggle">
              <v-icon color="#FAFAFA" size="20">
                {{ faq.expanded.value ? 'mdi-close' : 'mdi-chevron-down' }}
              </v-icon>
            </div>
            <h5>{{ faq.question }}</h5>
          </div>
          <v-expand-transition>
            <p v-show="faq.expanded.value" v-html="faq.answer" />
          </v-expand-transition>
        </div>
        <div v-if="listIndex === 0" class="faq-card more-questions">
          <h5>{{ $t('FrequentlyAskedQuestions.questions') }}</h5>
          <p>
            <span v-html="$t('FrequentlyAskedQuestions.get_in_touch')" />&nbsp;
            <span class="support-cta" @click="() => openChat()">
              {{ $t('FrequentlyAskedQuestions.support_team') }}
            </span>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, type Ref, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import messages from '@/i18n/langs'
import { LoadCrispMixin } from '@/helpers/mixins/loadCrispMixin'

interface FAQItem {
  question: string
  answer: string
  expanded: Ref<boolean>
}

export default defineComponent({
  name: 'FrequentlyAskedQuestions',
  mixins: [LoadCrispMixin],
  setup(props) {
    const isBusiness = props.isBusiness
    const accountType = isBusiness ? 'business' : 'personal'
    const { locale } = useI18n()

    const questions = messages[locale.value].FrequentlyAskedQuestions[accountType] as Record<
      string,
      FAQItem
    >

    const faqList = Object.values(questions).map((item) => ({
      question: item.question,
      answer: item.answer,
      expanded: ref<boolean>(false),
    }))

    const firstHalfOfQuestions = faqList.slice(0, Math.ceil(faqList.length / 2))
    const secondHalfOfQuestions = faqList.slice(Math.ceil(faqList.length / 2), faqList.length)

    return {
      firstHalfOfQuestions,
      secondHalfOfQuestions,
    }
  },
  props: {
    isBusiness: {
      type: Boolean,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';
.section-title {
  font-size: 40px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 32px;
  text-align: center;
}

.faq-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  margin-bottom: 24px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  p,
  h5 {
    margin-bottom: 0;
  }

  .faq-list-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    gap: 24px;

    &:nth-child(2) {
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        order: -1;
      }
    }
  }
}

.faq-card {
  text-align: left;
  width: 100%;
  border-radius: 10px;
  background: var(--gray-light-FC);
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding: 32px;
  }

  &.more-questions {
    background: var(--ui-black);
    color: white;

    h5 {
      cursor: default;
    }
    p {
      font-size: 16px;

      :deep(a),
      .support-cta {
        cursor: pointer;
        color: white;
        text-decoration-line: underline;
        text-decoration-style: solid;
      }
    }
  }

  h5 {
    cursor: pointer;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    padding-right: 24px;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      padding-right: 32px;
    }
  }

  p {
    font-family: Cabin;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      font-size: 16px;
    }
  }

  .faq-card-toggle {
    position: absolute;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 24px;
    right: 24px;
    border-radius: 20px;
    background: var(--ui-black);
    cursor: pointer;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      width: 30px;
      height: 30px;
      top: 32px;
      right: 32px;
    }
  }
}
</style>
