import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "success-feedback" }
const _hoisted_2 = { class: "progress-bar-wrapper" }

import { useStore } from 'vuex'
import { computed, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'


export default /*@__PURE__*/_defineComponent({
  __name: 'ShopifySetupSuccessView',
  setup(__props) {

const store = useStore()
const route = useRoute()
const router = useRouter()
const i18n = useI18n()

const setupIsFinished = computed(() => !store.getters['getShouldDisplayShopifyOverlay'])
const nextParam = route.query.next?.toString()
const nextRoute = computed(() => (nextParam ? decodeURIComponent(nextParam) : '/'))

const t = (key: string) => i18n.t(`ShopifySetupSuccessView.${key}`)
const validateCurrentRoute = () => setupIsFinished.value && router.push(nextRoute.value)

watch(setupIsFinished, validateCurrentRoute, { immediate: true })

return (_ctx: any,_cache: any) => {
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", null, _toDisplayString(t('header')), 1),
    _createElementVNode("p", null, _toDisplayString(t('description')), 1),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_v_progress_linear, {
        color: "#3B755F",
        indeterminate: "",
        rounded: "",
        height: "6"
      })
    ])
  ]))
}
}

})