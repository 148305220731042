import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "faq-card-section" }
const _hoisted_2 = { class: "section-title" }
const _hoisted_3 = { class: "faq-list" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "faq-card-toggle" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = {
  key: 0,
  class: "faq-card more-questions"
}
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_expand_transition = _resolveComponent("v-expand-transition")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t('FrequentlyAskedQuestions.title')), 1),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([_ctx.firstHalfOfQuestions, _ctx.secondHalfOfQuestions], (list, listIndex) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "faq-list-content",
          key: listIndex
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list, (faq, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "faq-card",
              key: index
            }, [
              _createElementVNode("div", {
                class: "faq-card-header",
                onClick: ($event: any) => (faq.expanded.value = !faq.expanded.value)
              }, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_v_icon, {
                    color: "#FAFAFA",
                    size: "20"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(faq.expanded.value ? 'mdi-close' : 'mdi-chevron-down'), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _createElementVNode("h5", null, _toDisplayString(faq.question), 1)
              ], 8, _hoisted_4),
              _createVNode(_component_v_expand_transition, null, {
                default: _withCtx(() => [
                  _withDirectives(_createElementVNode("p", {
                    innerHTML: faq.answer
                  }, null, 8, _hoisted_6), [
                    [_vShow, faq.expanded.value]
                  ])
                ]),
                _: 2
              }, 1024)
            ]))
          }), 128)),
          (listIndex === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("h5", null, _toDisplayString(_ctx.$t('FrequentlyAskedQuestions.questions')), 1),
                _createElementVNode("p", null, [
                  _createElementVNode("span", {
                    innerHTML: _ctx.$t('FrequentlyAskedQuestions.get_in_touch')
                  }, null, 8, _hoisted_8),
                  _cache[1] || (_cache[1] = _createTextVNode("  ")),
                  _createElementVNode("span", {
                    class: "support-cta",
                    onClick: _cache[0] || (_cache[0] = () => _ctx.openChat())
                  }, _toDisplayString(_ctx.$t('FrequentlyAskedQuestions.support_team')), 1)
                ])
              ]))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ])
  ]))
}