<template>
  <div class="overlay-text overlay-text--full-width">
    <strong>{{ t('code_snippet_tutorial.title') }}</strong>
    <p class="mt-3 mb-0">
      {{ t('code_snippet_tutorial.description') }}
    </p>

    <div class="d-flex flex-column my-4">
      <v-textarea
        :model-value="codeSnippet"
        :rows="5"
        hide-details
        no-resize
        readonly
        variant="outlined"
      >
      </v-textarea>
    </div>
    <gs-button capitalized full-width size="large" type="primary" @click="handleCopy">
      {{ t('code_snippet_tutorial.copy') }}
    </gs-button>
  </div>
</template>
<script lang="ts">
import { LoadCrispMixin } from '@/helpers/mixins/loadCrispMixin'
import { Utils } from '@/helpers/mixins/utilsMixin'
import { defineComponent } from 'vue'
import type { Notification } from '@/store/notification'

export default defineComponent({
  name: 'CodeSnippet',
  mixins: [LoadCrispMixin, Utils],
  components: {},
  computed: {
    displayName(): string {
      return this.$store.getters['getAccountDisplayName']
    },
    codeSnippet(): string {
      return `<a href="https://public.getgreenspark.com/${this.account.livePageId}/${this.displayName}?utm_source=${this.displayName}" rel="nofollow" target="_blank" style="border: 0;"><img loading="lazy" src="${this.url}" alt="Greenspark - Impact-as-a-Service" /></a>`
    },
  },
  methods: {
    t(key: string) {
      return this.$t(`Badge.${key}`)
    },
    async handleCopy() {
      this.copyText(this.codeSnippet)
      this.$store.dispatch('notification/notify', {
        text: this.t('code_snippet_tutorial.copied'),
        isError: false,
        buttonText: '',
      } as Notification)
    },
  },
  props: {
    url: {
      type: String,
      required: false,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.button {
  margin-top: 18px;
  border-radius: 0;
  padding: 0 !important;
}

.overlay-text {
  text-align: center;
  color: #212121;
  font-size: 15px;
  max-width: 440px;
  margin-top: 12px;
  margin-left: auto;
  margin-right: auto;

  &--full-width {
    text-align: left;
    margin-top: 24px;
    max-width: 100%;
  }

  strong {
    font-size: 18px;
  }

  :deep(a) {
    font-weight: 700;
  }
}
</style>
