import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_footer = _resolveComponent("v-footer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_footer, {
      class: "footer py-3",
      height: "52",
      color: "#F2EBDB",
      absolute: ""
    }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createElementVNode("span", { class: "ml-auto text-overline" }, "Greenspark ©2021", -1)
      ])),
      _: 1
    })
  ]))
}