<template>
  <v-dialog
    width="600"
    persistent
    :fullscreen="isMobile"
    :model-value="modelValue"
    @update:model-value="onUpdate"
  >
    <div class="pop-up">
      <div class="header">
        {{ t('title') }}
      </div>

      <div class="text">
        {{ t('description') }}
      </div>

      <gs-button full-width size="large" @click="handleClickContinue">
        <div class="button-text">
          {{ t('continue') }}
        </div>
      </gs-button>
      <gs-button full-width type="info" size="large" @click="$emit('update:model-value', false)">
        {{ t('cancel') }}
      </gs-button>
    </div>
  </v-dialog>
</template>

<script lang="ts">
import { Utils } from '@/helpers/mixins/utilsMixin'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'EtsyIntegrationAlert',
  emits: ['update:model-value', 'submit'],
  mixins: [Utils],
  created() {
    window.addEventListener('resize', this.reportWindowSize)
    this.reportWindowSize()
  },
  unmounted() {
    window.removeEventListener('resize', this.reportWindowSize)
  },
  methods: {
    onUpdate(val: boolean): void {
      this.$emit('update:model-value', val)
    },
    handleClickContinue() {
      this.$emit('update:model-value', false)
      this.$emit('submit')
    },
    t(key: string) {
      return this.$t(`EtsyIntegrationAlert.${key}`)
    },
  },
  props: {
    modelValue: {
      type: Boolean,
    },
  },
})
</script>

<style lang="scss" scoped>
.text {
  font-size: 16px;
  text-align: left;
  margin-bottom: 25px;
  width: 100%;
}

.pop-up {
  background: white;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow-y: scroll;
}

.header {
  font-weight: 500;
  font-size: 20px;
  text-align: left;
  width: 100%;
}
</style>
