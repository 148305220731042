import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "totals-wrapper" }
const _hoisted_2 = { class: "updates-wrapper" }
const _hoisted_3 = { class: "pending-note" }
const _hoisted_4 = { class: "upgrades-wrapper" }
const _hoisted_5 = { class: "pending-note" }
const _hoisted_6 = { class: "cart-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TeamTotals = _resolveComponent("TeamTotals")!
  const _component_UpdatePlans = _resolveComponent("UpdatePlans")!
  const _component_Upgrades = _resolveComponent("Upgrades")!
  const _component_Cart = _resolveComponent("Cart")!
  const _component_AddCardPopUp = _resolveComponent("AddCardPopUp")!
  const _component_Alert = _resolveComponent("Alert")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_TeamTotals, { "total-employees": _ctx.getTotalEmployees }, null, 8, ["total-employees"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_UpdatePlans, {
        "standard-amount": _ctx.account.employeePlans.standard ?? 0,
        "superior-amount": _ctx.account.employeePlans.superior ?? 0,
        "premium-amount": _ctx.account.employeePlans.premium ?? 0,
        "total-trees": _ctx.account.employeePlanAddons.treePlanter,
        "updated-total-pending-standard": _ctx.updatedTotalPendingStandard,
        "updated-total-pending-superior": _ctx.updatedTotalPendingSuperior,
        "updated-total-pending-premium": _ctx.updatedTotalPendingPremium,
        account: _ctx.account,
        "reset-inputs": _ctx.resetInputs,
        onGetUpdatedValues: _ctx.getUpdatedValues
      }, null, 8, ["standard-amount", "superior-amount", "premium-amount", "total-trees", "updated-total-pending-standard", "updated-total-pending-superior", "updated-total-pending-premium", "account", "reset-inputs", "onGetUpdatedValues"]),
      _createElementVNode("div", _hoisted_3, "*" + _toDisplayString(_ctx.t('pending_note_team')), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_Upgrades, {
        "tree-planter": _ctx.account.employeePlanAddons.treePlanter ?? 0,
        "plastic-hero": _ctx.account.employeePlanAddons.plasticHero ?? 0,
        "pending-tree-planter": _ctx.updatedTotalPendingTreePlanter,
        "pending-plastic-hero": _ctx.updatedTotalPendingPlastic,
        "reset-inputs": _ctx.resetInputs,
        onGetUpdatedValues: _ctx.getUpdatedValues
      }, null, 8, ["tree-planter", "plastic-hero", "pending-tree-planter", "pending-plastic-hero", "reset-inputs", "onGetUpdatedValues"]),
      _createElementVNode("div", _hoisted_5, "*" + _toDisplayString(_ctx.t('pending_note_upgrade')), 1)
    ]),
    (_ctx.showCart)
      ? (_openBlock(), _createBlock(_Transition, {
          key: 0,
          name: "fade"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_Cart, {
                "add-standard": _ctx.addStandard ?? 0,
                "add-superior": _ctx.addSuperior ?? 0,
                "add-premium": _ctx.addPremium ?? 0,
                "add-trees": _ctx.addTreePlanter ?? 0,
                "add-plastic": _ctx.addPlasticHero ?? 0,
                "total-employees": _ctx.getTotalEmployees,
                "number-of-plastic-heros": Number(_ctx.account.employeePlanAddons.plasticHero),
                "updated-total-pending-standard": _ctx.updatedTotalPendingStandard,
                "updated-total-pending-superior": _ctx.updatedTotalPendingSuperior,
                "updated-total-pending-premium": _ctx.updatedTotalPendingPremium,
                account: _ctx.account,
                "window-width": _ctx.windowWidth,
                loading: _ctx.loading,
                onUpdateSubscriptionClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateSubscriptions())),
                onNoSubscriptionClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dialog = true))
              }, null, 8, ["add-standard", "add-superior", "add-premium", "add-trees", "add-plastic", "total-employees", "number-of-plastic-heros", "updated-total-pending-standard", "updated-total-pending-superior", "updated-total-pending-premium", "account", "window-width", "loading"])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_AddCardPopUp, {
      dialog: _ctx.dialog,
      type: 'teams',
      "from-page": 'teams',
      onCloseDialogClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.dialog = false))
    }, null, 8, ["dialog"]),
    _createVNode(_component_Alert, {
      alert: _ctx.alert,
      error: _ctx.error,
      "show-buttons": _ctx.error,
      text: _ctx.error ? _ctx.t('error') : _ctx.t('success'),
      onCloseAlertClick: _cache[3] || (_cache[3] = ($event: any) => {;(_ctx.alert = false), (_ctx.error = false)})
    }, null, 8, ["alert", "error", "show-buttons", "text"])
  ]))
}