import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createTextVNode as _createTextVNode, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "gs-radio-group-content" }
const _hoisted_5 = {
  key: 0,
  class: "gs-radio-group-image"
}
const _hoisted_6 = ["alt", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_radio = _resolveComponent("v-radio")!
  const _component_v_radio_group = _resolveComponent("v-radio-group")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_loading = _resolveComponent("loading")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _cache[13] || (_cache[13] = _createElementVNode("h1", null, "Create Script Tag", -1)),
          _cache[14] || (_cache[14] = _createElementVNode("span", { class: "mb-4 d-block" }, " Create and update a new script tag for the selected account with specified widget settings. You can see existing script tags by filling out the form and submit it. ", -1)),
          _createVNode(_component_v_form, {
            ref: "form",
            modelValue: _ctx.valid,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.valid) = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_autocomplete, {
                modelValue: _ctx.selectedAccountId,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedAccountId) = $event)),
                "item-title": _ctx.getAccountId,
                "item-value": _ctx.getAccountId,
                items: _ctx.accounts.map((account) => account.accountId),
                rules: [_ctx.rules.required],
                class: "mb-4",
                color: "#3B755F",
                "item-color": "#3B755F",
                label: "Account",
                variant: "outlined"
              }, null, 8, ["modelValue", "item-title", "item-value", "items", "rules"]),
              _createVNode(_component_v_select, {
                modelValue: _ctx.type,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.type) = $event)),
                items: _ctx.widgetTypes,
                rules: [_ctx.rules.required],
                class: "mb-4",
                color: "#3B755F",
                "item-color": "#3B755F",
                label: "Widget Type",
                variant: "outlined"
              }, null, 8, ["modelValue", "items", "rules"]),
              _createVNode(_component_v_select, {
                modelValue: _ctx.color,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.color) = $event)),
                items: _ctx.widgetColors,
                rules: [_ctx.rules.required],
                class: "mb-4",
                color: "#3B755F",
                "item-color": "#3B755F",
                label: "Widget Color",
                variant: "outlined"
              }, null, 8, ["modelValue", "items", "rules"]),
              _createVNode(_component_v_select, {
                modelValue: _ctx.style,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.style) = $event)),
                items: _ctx.widgetStyles,
                rules: [_ctx.rules.required],
                class: "mb-4",
                color: "#3B755F",
                "item-color": "#3B755F",
                label: "Widget Style",
                variant: "outlined"
              }, null, 8, ["modelValue", "items", "rules"]),
              _createVNode(_component_v_radio_group, {
                modelValue: _ctx.withPopup,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.withPopup) = $event)),
                class: "mb-4",
                "hide-details": ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_radio, {
                    disabled: _ctx.type === 'static',
                    value: true,
                    label: "Activate popup"
                  }, null, 8, ["disabled"]),
                  (_ctx.withPopup)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createVNode(_component_v_radio_group, {
                          modelValue: _ctx.popupTheme,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.popupTheme) = $event)),
                          class: "nested-radio-group"
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.popupThemeOptions, (item, index) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: index,
                                class: _normalizeClass([{ active: item.value === _ctx.popupTheme }, "gs-radio-group-item"]),
                                onClick: ($event: any) => (_ctx.type !== 'static' && (_ctx.popupTheme = item.value))
                              }, [
                                _createVNode(_component_v_radio, {
                                  disabled: _ctx.type === 'static',
                                  label: item.label,
                                  value: item.value
                                }, null, 8, ["disabled", "label", "value"]),
                                _createElementVNode("div", _hoisted_4, [
                                  (item.imageUrl)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                        _createElementVNode("img", {
                                          alt: item.label,
                                          src: item.imageUrl
                                        }, null, 8, _hoisted_6)
                                      ]))
                                    : _createCommentVNode("", true)
                                ])
                              ], 10, _hoisted_3))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_v_radio, {
                    disabled: _ctx.type === 'static',
                    value: false,
                    label: "Deactivate popup"
                  }, null, 8, ["disabled"])
                ]),
                _: 1
              }, 8, ["modelValue"]),
              _createVNode(_component_v_textarea, {
                modelValue: _ctx.scriptTag,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.scriptTag) = $event)),
                rows: 3,
                class: "mb-4",
                label: "Generated Script Tag",
                readonly: "",
                variant: "outlined"
              }, {
                "append-inner": _withCtx(() => [
                  _createVNode(_component_v_tooltip, {
                    "open-on-click": true,
                    "open-on-hover": false,
                    "close-delay": "5000",
                    location: "top",
                    text: "Copied to clipboard"
                  }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_icon, _mergeProps(props, {
                        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.copyText(_ctx.scriptTag)))
                      }), {
                        default: _withCtx(() => _cache[10] || (_cache[10] = [
                          _createTextVNode(" mdi-content-copy")
                        ])),
                        _: 2
                      }, 1040)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["modelValue"]),
              _createVNode(_component_v_textarea, {
                "model-value": _ctx.shopifyScriptTag,
                rows: 3,
                class: "mb-4",
                label: "Shopify Script Tag",
                readonly: "",
                variant: "outlined"
              }, {
                "append-inner": _withCtx(() => [
                  _createVNode(_component_v_tooltip, {
                    "open-on-click": true,
                    "open-on-hover": false,
                    "close-delay": "5000",
                    location: "top",
                    text: "Copied to clipboard"
                  }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_icon, _mergeProps(props, {
                        onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.copyText(_ctx.shopifyScriptTag)))
                      }), {
                        default: _withCtx(() => _cache[11] || (_cache[11] = [
                          _createTextVNode(" mdi-content-copy ")
                        ])),
                        _: 2
                      }, 1040)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["model-value"]),
              _createVNode(_component_gs_button, {
                disabled: _ctx.loading || !_ctx.valid,
                "full-width": true,
                loading: _ctx.loading,
                size: "large",
                type: "primary",
                onClick: _ctx.createScriptTag
              }, {
                default: _withCtx(() => _cache[12] || (_cache[12] = [
                  _createTextVNode(" Generate Script Tag ")
                ])),
                _: 1
              }, 8, ["disabled", "loading", "onClick"])
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_loading, { key: 1 }))
      : _createCommentVNode("", true)
  ]))
}