import { createElementVNode as _createElementVNode, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "menu" }
const _hoisted_2 = { class: "drawer-list" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "menu-item__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_menu, {
      modelValue: _ctx.open,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.open) = $event)),
      "close-delay": "250",
      "content-class": "elevation-3",
      transition: "slide-y",
      location: "left"
    }, {
      activator: _withCtx(({ props }) => [
        _createElementVNode("button", _mergeProps({
          class: { active: _ctx.open }
        }, props), _cache[1] || (_cache[1] = [
          _createElementVNode("i", null, null, -1)
        ]), 16)
      ]),
      default: _withCtx(() => [
        _createElementVNode("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, a) => {
            return (_openBlock(), _createElementBlock("li", {
              key: a,
              class: "menu-item",
              onClick: () => _ctx.onMenuItemClick(item)
            }, [
              _createElementVNode("span", _hoisted_4, _toDisplayString(item.text), 1)
            ], 8, _hoisted_3))
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}