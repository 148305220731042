import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "checkout-item" }
const _hoisted_2 = { class: "type" }
const _hoisted_3 = {
  key: 0,
  class: "amount"
}
const _hoisted_4 = {
  key: 1,
  class: "amount"
}
const _hoisted_5 = {
  key: 2,
  class: "amount"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.amount) + " ", 1),
      _cache[0] || (_cache[0] = _createElementVNode("span", { style: {"text-transform":"lowercase"} }, "x", -1)),
      _createTextVNode(" " + _toDisplayString(_ctx.trial && _ctx.type
          ? _ctx.t(_ctx.snakeCase(_ctx.type) + '_trial')
          : `${_ctx.t(`${_ctx.snakeCase(_ctx.type ?? '')}`)}${_ctx.country ? ` (${_ctx.country})` : ''}`), 1)
    ]),
    (_ctx.currency === 'euro')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.currencyEuro(_ctx.displayedCost ?? -1)), 1))
      : _createCommentVNode("", true),
    (_ctx.currency === 'pound')
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.currencyPound(_ctx.displayedCost ?? -1)), 1))
      : _createCommentVNode("", true),
    (_ctx.currency === 'dollar')
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.currencyDollar(_ctx.displayedCost ?? -1)), 1))
      : _createCommentVNode("", true)
  ]))
}