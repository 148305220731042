import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "api-keygen" }
const _hoisted_2 = {
  class: "d-md-inline-flex d-flex justify-md-space-between align-md-center flex-column flex-md-row mb-4",
  style: {"width":"100%"}
}
const _hoisted_3 = { class: "mb-3 mb-md-0" }
const _hoisted_4 = { class: "description ma-0" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "text-left" }
const _hoisted_7 = { class: "text-left" }
const _hoisted_8 = { class: "text-left" }
const _hoisted_9 = { class: "text-left" }
const _hoisted_10 = ["href"]
const _hoisted_11 = {
  key: 0,
  class: "mx-4 mb-4"
}
const _hoisted_12 = { class: "text-black text-body-1" }
const _hoisted_13 = { class: "text-red font-weight-bold" }
const _hoisted_14 = {
  id: "checkboxLabel",
  class: "text-black"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_LoadingTable = _resolveComponent("LoadingTable")!
  const _component_QuickMenu = _resolveComponent("QuickMenu")!
  const _component_v_table = _resolveComponent("v-table")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_WarningDialog = _resolveComponent("WarningDialog")!
  const _component_ApiUsage = _resolveComponent("ApiUsage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.t('title')), 1),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.t('description_custom_integrations')), 1),
        _createVNode(_component_i18n_t, {
          keypath: "ApiKey.help_text",
          tag: "div",
          class: "help-text"
        }, {
          default: _withCtx(() => [
            _createElementVNode("a", {
              href: _ctx.API_DOCUMENTATION_URL,
              target: "_blank",
              style: {"text-decoration":"none"},
              class: "font-weight-bold"
            }, _toDisplayString(_ctx.t('help_link')), 9, _hoisted_5)
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_gs_button, {
        size: "large",
        width: "227",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isCreateApiKeyDialogOpen = true))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('generate')), 1)
        ]),
        _: 1
      })
    ]),
    (_ctx.isApiKeysLoading)
      ? (_openBlock(), _createBlock(_component_LoadingTable, { key: 0 }))
      : _createCommentVNode("", true),
    (!_ctx.isApiKeysLoading && _ctx.apiKeys.length > 0)
      ? (_openBlock(), _createBlock(_component_v_table, {
          key: 1,
          class: "keys table",
          "fixed-header": ""
        }, {
          default: _withCtx(() => [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", _hoisted_6, _toDisplayString(_ctx.t('table_name')), 1),
                _createElementVNode("th", _hoisted_7, _toDisplayString(_ctx.t('table_type')), 1),
                _createElementVNode("th", _hoisted_8, _toDisplayString(_ctx.t('placeholder')), 1),
                _createElementVNode("th", _hoisted_9, _toDisplayString(_ctx.t('table_created')), 1),
                _cache[14] || (_cache[14] = _createElementVNode("th", null, null, -1))
              ])
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.apiKeys, (item, index) => {
                return (_openBlock(), _createElementBlock("tr", { key: index }, [
                  _createElementVNode("td", null, _toDisplayString(item.name), 1),
                  _createElementVNode("td", null, _toDisplayString(_ctx.apiKeyTypeFilter(item.subType)), 1),
                  _createElementVNode("td", null, _toDisplayString(_ctx.visibleKeyFilter(item.visibleValue)), 1),
                  _createElementVNode("td", null, _toDisplayString(_ctx.dateFormatFilter(item.activationDate, _ctx.userLocale)), 1),
                  _createElementVNode("td", null, [
                    _createVNode(_component_QuickMenu, {
                      "quick-menu-items": _ctx.menuItems,
                      onQuickMenuClick: ($event: any) => (_ctx.quickMenuClick($event, item))
                    }, null, 8, ["quick-menu-items", "onQuickMenuClick"])
                  ])
                ]))
              }), 128))
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.isCreateApiKeyDialogOpen,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isCreateApiKeyDialogOpen) = $event)),
      persistent: "",
      width: "572",
      transition: "fade"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, { class: "text-black font-weight-bold text-h5 d-flex py-6 pt-4 pb-3 align-center" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('create_key_title')) + " ", 1),
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  icon: "",
                  onClick: _ctx.resetState,
                  variant: "plain"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, { color: "black" }, {
                      default: _withCtx(() => _cache[15] || (_cache[15] = [
                        _createTextVNode(" mdi-close ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_text, { class: "text-black text-body-1" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('create_key_text')) + " ", 1),
                _createVNode(_component_i18n_t, {
                  keypath: "ApiKey.help_text",
                  tag: "div",
                  class: "help-text"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("a", {
                      href: _ctx.API_DOCUMENTATION_URL,
                      target: "_blank",
                      style: {"text-decoration":"none"},
                      class: "font-weight-bold"
                    }, _toDisplayString(_ctx.t('help_link')), 9, _hoisted_10)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_form, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_text_field, {
                      modelValue: _ctx.createName,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.createName) = $event)),
                      class: "mb-5",
                      variant: "outlined",
                      flat: "",
                      label: _ctx.t('create_key_name'),
                      required: "",
                      disabled: !_ctx.apiFeature
                    }, null, 8, ["modelValue", "label", "disabled"]),
                    _createVNode(_component_v_select, {
                      modelValue: _ctx.createType,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.createType) = $event)),
                      items: _ctx.keyTypeList,
                      label: _ctx.t('table_type'),
                      variant: "outlined",
                      flat: "",
                      required: ""
                    }, null, 8, ["modelValue", "items", "label"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_actions, null, {
              default: _withCtx(() => [
                _createVNode(_component_gs_button, {
                  "full-width": "",
                  size: "large",
                  loading: _ctx.isCreateApiKeyLoading,
                  disabled: _ctx.isCreateButtonDisabled,
                  onClick: _ctx.createApiKey,
                  class: "mx-2 mb-4"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('create_key_title')), 1)
                  ]),
                  _: 1
                }, 8, ["loading", "disabled", "onClick"])
              ]),
              _: 1
            }),
            (!_ctx.apiFeature)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.t('upgrade_text')), 1),
                  _createVNode(_component_gs_button, {
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.push('/upgrade-plan?tab=apiKeys'))),
                    class: "mt-1"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('upgrade')), 1)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.isYourApiKeyDialogOpen,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.isYourApiKeyDialogOpen) = $event)),
      persistent: "",
      width: "572",
      transition: "fade"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, { class: "text-black font-weight-bold text-h5 d-flex py-6 pt-4 pb-3 align-center" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('your_key_title')) + " ", 1),
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  icon: "",
                  onClick: _ctx.resetState,
                  variant: "plain"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, { color: "black" }, {
                      default: _withCtx(() => _cache[16] || (_cache[16] = [
                        _createTextVNode(" mdi-close ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_text, { class: "text-black text-body-1" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('your_key_text_1')) + " ", 1),
                _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.t('your_key_text_2')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_form, { class: "d-flex" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_text_field, {
                      modelValue: _ctx.createdKey,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.createdKey) = $event)),
                      variant: "outlined",
                      flat: "",
                      disabled: "",
                      class: "pr-2"
                    }, null, 8, ["modelValue"]),
                    _createVNode(_component_gs_button, {
                      size: "large",
                      type: "primary",
                      "min-width": "134",
                      onClick: _ctx.copyToClipboard
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.isCopied ? _ctx.t('is_copied') : _ctx.t('copy')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_actions, null, {
              default: _withCtx(() => [
                _createVNode(_component_gs_button, {
                  height: "50px",
                  "full-width": "",
                  type: "outlined",
                  class: "mx-2 mb-4",
                  size: "large",
                  onClick: _ctx.resetState
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('close')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.isDeleteApiKeyDialogOpen,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.isDeleteApiKeyDialogOpen) = $event)),
      persistent: "",
      width: "572",
      transition: "fade"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, { class: "text-red font-weight-bold text-h5 d-flex py-6 pt-4 pb-3 align-center" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('delete_key_title')) + " ", 1),
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  icon: "",
                  onClick: _ctx.resetState,
                  variant: "plain"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, { color: "black" }, {
                      default: _withCtx(() => _cache[17] || (_cache[17] = [
                        _createTextVNode(" mdi-close ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_text, { class: "text-black text-body-1" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('delete_key_text')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_v_checkbox, {
              modelValue: _ctx.isDeleteApiKeyDialogChecked,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.isDeleteApiKeyDialogChecked) = $event)),
              class: "delete checkbox"
            }, {
              label: _withCtx(() => [
                _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.t('delete_key_checkbox')), 1)
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_v_card_actions, { class: "ga-2" }, {
              default: _withCtx(() => [
                _createVNode(_component_gs_button, {
                  type: "danger",
                  width: "272",
                  size: "large",
                  loading: _ctx.isDeleteApiKeyLoading,
                  disabled: _ctx.isDeleteApiKeyLoading || !_ctx.isDeleteApiKeyDialogChecked,
                  onClick: _ctx.deleteApiKey
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('delete_key_title')), 1)
                  ]),
                  _: 1
                }, 8, ["loading", "disabled", "onClick"]),
                _createVNode(_component_gs_button, {
                  width: "272",
                  size: "large",
                  type: "outlined",
                  onClick: _ctx.resetState
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('close')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.isEditApiKeyDialogOpen,
      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.isEditApiKeyDialogOpen) = $event)),
      persistent: "",
      width: "572",
      transition: "fade"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, { height: "296" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, { class: "text-black font-weight-bold text-h5 d-flex py-6 pt-4 pb-3 align-center" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('edit_key_title')) + " ", 1),
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  icon: "",
                  onClick: _ctx.resetState,
                  variant: "plain"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, { color: "black" }, {
                      default: _withCtx(() => _cache[18] || (_cache[18] = [
                        _createTextVNode(" mdi-close ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_form, { class: "mt-5" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_text_field, {
                      modelValue: _ctx.editName,
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.editName) = $event)),
                      variant: "outlined",
                      flat: "",
                      label: _ctx.t('create_key_name'),
                      required: ""
                    }, null, 8, ["modelValue", "label"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_actions, { class: "mt-1" }, {
              default: _withCtx(() => [
                _createVNode(_component_gs_button, {
                  loading: _ctx.isEditApiKeyLoading,
                  disabled: _ctx.isEditApiKeyLoading || _ctx.editName.length < 1 || _ctx.editName === _ctx.currentItem?.name,
                  size: "large",
                  "full-width": "",
                  onClick: _ctx.editApiKey
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('save')), 1)
                  ]),
                  _: 1
                }, 8, ["loading", "disabled", "onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.isWarningDialogOpen,
      "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.isWarningDialogOpen) = $event)),
      width: "600",
      persistent: "",
      transition: "fade"
    }, {
      default: _withCtx(() => [
        (_ctx.createType)
          ? (_openBlock(), _createBlock(_component_WarningDialog, {
              key: 0,
              "api-key-type": _ctx.createType,
              onGenerateApiKey: _cache[11] || (_cache[11] = 
          () => {
            _ctx.isWarningDialogOpen = false
            _ctx.isCreateApiKeyDialogOpen = true
            _ctx.createType && _ctx.generateApiKey(_ctx.createType)
          }
        ),
              onClosePopup: _cache[12] || (_cache[12] = 
          () => {
            _ctx.isWarningDialogOpen = false
            _ctx.isCreateApiKeyDialogOpen = true
          }
        )
            }, null, 8, ["api-key-type"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_ApiUsage)
  ]))
}