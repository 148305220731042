import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "outer-wrapper" }
const _hoisted_2 = { class: "success-wrapper" }
const _hoisted_3 = {
  key: 0,
  class: "success-ecommerce"
}
const _hoisted_4 = {
  class: "header",
  style: {"text-align":"left"}
}
const _hoisted_5 = { class: "subheader" }
const _hoisted_6 = { class: "path-wrapper" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "path-content" }
const _hoisted_9 = { class: "path-title" }
const _hoisted_10 = { class: "path-text" }
const _hoisted_11 = { class: "path-button-wrapper" }
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "path-content" }
const _hoisted_14 = { class: "path-title" }
const _hoisted_15 = { class: "path-text" }
const _hoisted_16 = { class: "path-button-wrapper" }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { class: "header" }
const _hoisted_19 = {
  key: 0,
  class: "text"
}
const _hoisted_20 = {
  key: 1,
  class: "text"
}
const _hoisted_21 = { class: "button-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_Loading = _resolveComponent("Loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.account.accountType === 'ecommerce' ? 'success-e' : 'success-t')
    }, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.account.accountType === 'ecommerce')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t('success')), 1),
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.t('success_sub')), 1),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", {
                  class: "path",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.integration()))
                }, [
                  _createElementVNode("div", null, [
                    _createElementVNode("img", {
                      class: "path-icon",
                      src: require('@/assets/icons/integrations-icon.svg'),
                      alt: "integrate"
                    }, null, 8, _hoisted_7)
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("div", null, _toDisplayString(_ctx.t('add_integration')), 1)
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", null, _toDisplayString(_ctx.t('add_integration_text')), 1)
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _createVNode(_component_gs_button, {
                        "full-width": true,
                        size: "large",
                        onClick: _withModifiers(_ctx.integration, ["prevent"])
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('continue')), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ])
                  ])
                ]),
                _createElementVNode("div", {
                  class: "path",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.team()))
                }, [
                  _createElementVNode("div", null, [
                    _createElementVNode("img", {
                      class: "path-icon",
                      src: require('@/assets/icons/team-icon.svg'),
                      alt: "integrate"
                    }, null, 8, _hoisted_12)
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("div", null, _toDisplayString(_ctx.t('add_team')), 1)
                    ]),
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("div", null, _toDisplayString(_ctx.t('add_team_text')), 1)
                    ]),
                    _createElementVNode("div", _hoisted_16, [
                      _createVNode(_component_gs_button, {
                        "full-width": true,
                        size: "large",
                        onClick: _withModifiers(_ctx.team, ["prevent"])
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('continue')), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ])
                  ])
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.account.accountType !== 'ecommerce')
          ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.t('thanks')), 1),
              (_ctx.account.accountType === 'business' || _ctx.account.accountType === 'ecommerce')
                ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                    _createTextVNode(_toDisplayString(_ctx.t('text1')) + " ", 1),
                    _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
                    _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
                    _createTextVNode(" " + _toDisplayString(_ctx.t('text2')), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.account.accountType !== 'business' && _ctx.account.accountType !== 'ecommerce')
                ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                    _createTextVNode(_toDisplayString(_ctx.t('text_personal1')) + " ", 1),
                    _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
                    _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
                    _createTextVNode(" " + _toDisplayString(_ctx.t('text_personal2')) + " ", 1),
                    _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
                    _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
                    _createTextVNode(" " + _toDisplayString(_ctx.t('text_personal3')), 1)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_21, [
                _createVNode(_component_gs_button, {
                  "full-width": true,
                  size: "large",
                  onClick: _withModifiers(_ctx.goHome, ["prevent"])
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('continue_dashboard')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ], 2),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.loading)
          ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}