import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "connected-banks" }
const _hoisted_2 = { class: "connected-banks__title" }
const _hoisted_3 = ["id"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "connected-banks__bank-name" }
const _hoisted_6 = { class: "mb-0" }
const _hoisted_7 = { class: "connected-banks__accounts-label" }
const _hoisted_8 = ["id"]
const _hoisted_9 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.t('connect_account_title')), 1),
    _createElementVNode("ul", {
      class: _normalizeClass([
        'connected-banks__list',
        { 'is-expanded': _ctx.isExpanded },
        { 'is-account-expanded': _ctx.isAccountExpanded !== null },
      ])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getBankAccounts, ({ id, name, accounts, logo }, index) => {
        return (_openBlock(), _createElementBlock(_Fragment, null, [
          (_ctx.isVisible(index))
            ? (_openBlock(), _createElementBlock("li", {
                id: id,
                key: id,
                class: _normalizeClass(['connected-banks__list-item', { 'is-expanded': _ctx.isAccountExpanded === index }])
              }, [
                _createVNode(_component_v_tooltip, {
                  "open-on-click": _ctx.isTouch,
                  "content-class": "carbon-footprint-introduction__tooltip-content",
                  location: "top",
                  "max-width": "480px"
                }, {
                  activator: _withCtx(({ props }) => [
                    _createElementVNode("div", _mergeProps({
                      class: "connected-banks__logo-wrapper",
                      ref_for: true
                    }, props), [
                      logo
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            class: "connected-banks__bank-logo",
                            src: `data:image/png;base64,${logo}`,
                            height: "40",
                            loading: "lazy"
                          }, null, 8, _hoisted_4))
                        : _createCommentVNode("", true),
                      _createElementVNode("p", _hoisted_5, _toDisplayString(name), 1),
                      _createVNode(_component_v_icon, { class: "connected-banks__status" }, {
                        default: _withCtx(() => _cache[2] || (_cache[2] = [
                          _createTextVNode(" mdi-check-circle ")
                        ])),
                        _: 1
                      })
                    ], 16)
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("p", _hoisted_6, _toDisplayString(name), 1)
                  ]),
                  _: 2
                }, 1032, ["open-on-click"]),
                _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.t('label')), 1),
                _createElementVNode("ul", {
                  class: _normalizeClass([
              'connected-banks__accounts-list',
              { 'is-expanded': _ctx.isAccountExpanded === index },
            ])
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(accounts, (account, accountIndex) => {
                    return (_openBlock(), _createElementBlock(_Fragment, null, [
                      (_ctx.isAccountVisible(index, accountIndex))
                        ? (_openBlock(), _createElementBlock("li", {
                            id: account.id,
                            key: account.id,
                            class: "connected-banks__accounts-list-item"
                          }, _toDisplayString(account.name), 9, _hoisted_8))
                        : _createCommentVNode("", true)
                    ], 64))
                  }), 256)),
                  (accounts.length > 3)
                    ? _withDirectives((_openBlock(), _createElementBlock("button", {
                        key: 0,
                        class: _normalizeClass([
                'expand-bank-account-list-button',
                { 'is-expanded': _ctx.isAccountExpanded === index },
              ]),
                        onClick: ($event: any) => (_ctx.toggleExpandCardAccounts(index))
                      }, [
                        _createVNode(_component_v_icon, { class: "expand-bank-account-list-button__icon" }, {
                          default: _withCtx(() => _cache[3] || (_cache[3] = [
                            _createTextVNode(" mdi-plus ")
                          ])),
                          _: 1
                        })
                      ], 10, _hoisted_9)), [
                        [_directive_ripple]
                      ])
                    : _createCommentVNode("", true)
                ], 2)
              ], 10, _hoisted_3))
            : _createCommentVNode("", true)
        ], 64))
      }), 256)),
      _withDirectives((_openBlock(), _createElementBlock("button", {
        id: "add-bank-account-button",
        class: "add-bank-account-button",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.connectBankAccount && _ctx.connectBankAccount(...args)))
      }, [
        _createVNode(_component_v_icon, { class: "add-bank-account-button__icon" }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode(" mdi-plus ")
          ])),
          _: 1
        })
      ])), [
        [_directive_ripple]
      ])
    ], 2),
    (_ctx.isMoreButtonVisible)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "connected-banks__more-button",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleExpand && _ctx.toggleExpand(...args)))
        }, [
          _createTextVNode(_toDisplayString(_ctx.t('more')) + " ", 1),
          _createVNode(_component_v_icon, {
            class: _normalizeClass(['toggle-expand-icon', { 'is-expanded': _ctx.isExpanded }])
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode(" mdi-menu-down ")
            ])),
            _: 1
          }, 8, ["class"])
        ]))
      : _createCommentVNode("", true)
  ]))
}