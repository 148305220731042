import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pop-up" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = {
  key: 0,
  class: "text"
}
const _hoisted_4 = { class: "text" }
const _hoisted_5 = { style: {"width":"100%"} }
const _hoisted_6 = { class: "text" }
const _hoisted_7 = {
  key: 0,
  class: "text"
}
const _hoisted_8 = { key: 1 }
const _hoisted_9 = {
  key: 2,
  class: "text"
}
const _hoisted_10 = { key: 3 }
const _hoisted_11 = {
  key: 4,
  class: "text"
}
const _hoisted_12 = { key: 5 }
const _hoisted_13 = {
  key: 6,
  class: "text"
}
const _hoisted_14 = { key: 7 }
const _hoisted_15 = {
  key: 8,
  class: "text"
}
const _hoisted_16 = { key: 9 }
const _hoisted_17 = {
  key: 10,
  class: "text"
}
const _hoisted_18 = { class: "button-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    width: "600",
    persistent: "",
    fullscreen: _ctx.isMobile,
    "model-value": _ctx.modelValue,
    "onUpdate:modelValue": _ctx.onUpdate
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.t('shopify_header')), 1),
        (_ctx.account.currency === 'dollar')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.t('shopify_text_dollar')), 1))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t('shopify_text')), 1),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.isBusinessPlanAndYearly
                ? _ctx.t('plan_yearly', { price: _ctx.currencyDollar(_ctx.dollarPricing.planPrice) })
                : _ctx.t('plan_monthly', { price: _ctx.currencyDollar(_ctx.dollarPricing.planPrice) })), 1),
                _cache[1] || (_cache[1] = _createElementVNode("hr", null, null, -1)),
                (_ctx.dollarPricing.standard)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_7, " Standard Team Member: " + _toDisplayString(_ctx.currencyDollar(_ctx.dollarPricing.standard)), 1))
                  : _createCommentVNode("", true),
                (_ctx.dollarPricing.standard)
                  ? (_openBlock(), _createElementBlock("hr", _hoisted_8))
                  : _createCommentVNode("", true),
                (_ctx.dollarPricing.superior)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_9, " Superior Team Member: " + _toDisplayString(_ctx.currencyDollar(_ctx.dollarPricing.superior)), 1))
                  : _createCommentVNode("", true),
                (_ctx.dollarPricing.superior)
                  ? (_openBlock(), _createElementBlock("hr", _hoisted_10))
                  : _createCommentVNode("", true),
                (_ctx.dollarPricing.premium)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_11, " Premium Team Member: " + _toDisplayString(_ctx.currencyDollar(_ctx.dollarPricing.premium)), 1))
                  : _createCommentVNode("", true),
                (_ctx.dollarPricing.premium)
                  ? (_openBlock(), _createElementBlock("hr", _hoisted_12))
                  : _createCommentVNode("", true),
                (_ctx.dollarPricing.treePlanter)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_13, " Tree Planters: " + _toDisplayString(_ctx.currencyDollar(_ctx.dollarPricing.treePlanter)), 1))
                  : _createCommentVNode("", true),
                (_ctx.dollarPricing.treePlanter)
                  ? (_openBlock(), _createElementBlock("hr", _hoisted_14))
                  : _createCommentVNode("", true),
                (_ctx.dollarPricing.plasticHero)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_15, " Plastic Heros: " + _toDisplayString(_ctx.currencyDollar(_ctx.dollarPricing.plasticHero)), 1))
                  : _createCommentVNode("", true),
                (_ctx.dollarPricing.plasticHero)
                  ? (_openBlock(), _createElementBlock("hr", _hoisted_16))
                  : _createCommentVNode("", true),
                (_ctx.totalNewCost)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_17, [
                      _createElementVNode("strong", null, "Total in USD " + _toDisplayString(_ctx.isActiveSubscriptionOnTrial ? '(after trial period)' : '') + ":", 1),
                      _createTextVNode(" " + _toDisplayString(_ctx.currencyDollar(_ctx.totalNewCost)), 1)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ], 64)),
        _createVNode(_component_gs_button, {
          class: "mt-2",
          "full-width": "",
          size: "large",
          onClick: _ctx.handleClickContinue
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.t('continue')), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_gs_button, {
          "full-width": "",
          type: "info",
          size: "large",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:model-value', false)))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('cancel')), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["fullscreen", "model-value", "onUpdate:modelValue"]))
}