import axios from 'axios'
import { getApiUrl } from './helpers'
import type {
  AccountDto,
  CreateWidgetEditorSettingsRequestBody,
  PaymentPayloadImpact,
  UserDto,
  WidgetEditorSettings,
} from './index'
import type { TransferredImpact } from '@/store'
import { tokenInterceptor } from '@/auth'

const adminApi = axios.create({
  baseURL: getApiUrl('dashboard'),
  timeout: 100000,
})

// interceptor adds the token
adminApi.interceptors.request.use(tokenInterceptor)

export interface UpdateTransferredImpactsRequestBody {
  accountId: string
  impacts: TransferredImpact[]
}

export const updateTransferredImpacts = async (
  payload: UpdateTransferredImpactsRequestBody,
): Promise<void> => {
  const { data } = await adminApi.post<void>('/admin/transfer-impact', payload)
  return data
}

export const fetchAllAccount = async (search = ''): Promise<AccountDto[]> => {
  const url = search ? `admin/accounts?search=${search}` : `admin/accounts`
  const { data } = await adminApi.get<AccountDto[]>(url)
  return data
}

export const fetchAllUser = async (search = ''): Promise<UserDto[]> => {
  const url = search ? `admin/users?search=${search}` : `admin/users`
  const { data } = await adminApi.get<UserDto[]>(url)
  return data
}

export const changeEmail = async (userId: string, email: string): Promise<void> => {
  await adminApi.put<void>('admin/email', { userId, email })
}

export const createReward = async (
  accountId: string,
  impacts: PaymentPayloadImpact[],
  reason: string,
): Promise<void> => {
  await adminApi.post<void>('admin/reward', { accountId, impacts, reason })
}

export const adminDeleteSubscription = async (accountId: string, reason: string): Promise<void> => {
  await adminApi.delete<void>('admin/subscription', { data: { accountId, reason } })
}

export const uploadShopifyPayoutCSV = async (file: Blob | string): Promise<void> => {
  const form = new FormData()
  form.append('file', file)
  await adminApi.post<void>('admin/payout-csv', form)
}

export const getLatestPayoutDate = async (): Promise<string> => {
  const { data } = await adminApi.get<string>('admin/latest-payout-date')
  return data
}

export interface CreateWidgetEditorSettingsAdminRequestBody
  extends CreateWidgetEditorSettingsRequestBody {
  accountId: string
}

export const upsertWidgetEditorSettingsForAccount = async (
  payload: CreateWidgetEditorSettingsAdminRequestBody,
): Promise<WidgetEditorSettings> => {
  const { data } = await adminApi.post<WidgetEditorSettings>(
    'admin/widget-editor-settings',
    payload,
  )
  return data
}
