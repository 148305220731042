import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "onboarding-pill__impact-icon"
}
const _hoisted_2 = ["href"]
const _hoisted_3 = {
  class: "onboarding-pill__content",
  ref: "slot"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), {
    class: _normalizeClass(["onboarding-pill", { disabled: _ctx.disabled }]),
    onClick: _withModifiers(_ctx.copyToClipboard, ["prevent"])
  }, {
    default: _withCtx(() => [
      (_ctx.mode !== 'impact')
        ? (_openBlock(), _createBlock(_component_v_icon, {
            key: 0,
            class: "onboarding-pill__icon"
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode(" mdi-check ")
            ])),
            _: 1
          }))
        : (_openBlock(), _createElementBlock("svg", _hoisted_1, [
            _createElementVNode("use", {
              href: '#' + _ctx.offsetType + '-icon'
            }, null, 8, _hoisted_2)
          ])),
      _createElementVNode("span", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 512),
      (_ctx.isCopyEnabled)
        ? (_openBlock(), _createBlock(_component_v_icon, {
            key: 2,
            class: "onboarding-pill__icon copy"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.isCopied ? 'mdi-check-circle' : 'mdi-content-copy'), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["class", "onClick"]))
}