import { withAsyncContext as _withAsyncContext, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "impact-pricing-comparison" }
const _hoisted_2 = { scope: "col" }
const _hoisted_3 = { scope: "col" }
const _hoisted_4 = { scope: "col" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { key: 0 }

import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import type { OffsetType } from '@/helpers/interfaces'

type PricingCopies = {
  title: string
  freePlan: string
  paidPlans: string
  enterprise: string
}

export default /*@__PURE__*/_defineComponent({
  __name: 'ImpactPricingComparisonTable',
  async setup(__props) {

let __temp: any, __restore: any

const { t } = useI18n()
const store = useStore()

;(
  ([__temp,__restore] = _withAsyncContext(() => store.dispatch('setProjectList'))),
  await __temp,
  __restore()
)

const pricingPerImpact = computed<Record<OffsetType, PricingCopies>>(() => {
  const formatter = new Intl.NumberFormat(store.getters['getUserLocale'], {
    style: 'currency',
    currency: store.getters['getUserCurrencyCode'],
    minimumFractionDigits: 2,
    maximumFractionDigits: 3,
  })
  const treeFreePrice = store.getters['getDefaultProjectPriceByType']('trees', true)
  const treePaidPrice = store.getters['getDefaultPaidProjectPriceByType']('trees')

  const plasticFreePrice = store.getters['getDefaultProjectPriceByType']('plastic', true)
  const plasticPaidPrice = store.getters['getDefaultPaidProjectPriceByType']('plastic')

  const carbonFreePrice = store.getters['getDefaultProjectPriceByType']('carbon', true)
  const carbonPaidPrice = store.getters['getDefaultPaidProjectPriceByType']('carbon')

  const kelpFreePrice = store.getters['getDefaultProjectPriceByType']('kelp', true)
  const kelpPaidPrice = store.getters['getDefaultPaidProjectPriceByType']('kelp')

  const waterFreePrice = store.getters['getDefaultProjectPriceByType']('water', true)
  const waterPaidPrice = store.getters['getDefaultPaidProjectPriceByType']('water')

  const beesFreePrice = store.getters['getDefaultProjectPriceByType']('bees', true)
  const beesPaidPrice = store.getters['getDefaultPaidProjectPriceByType']('bees')

  return {
    trees: {
      title: t('trees.billed_action'),
      freePlan: t('trees.from_short_price', {
        price: formatter.format(treeFreePrice),
      }),
      paidPlans: t('trees.from_short_price', {
        price: formatter.format(treePaidPrice),
      }),
      enterprise: t('PlanComparisonTable.custom'),
    },
    plastic: {
      title: t('plastic.billed_action'),
      freePlan: t('plastic.short_price', {
        price: formatter.format(plasticFreePrice),
      }),
      paidPlans: t('plastic.short_price', {
        price: formatter.format(plasticPaidPrice),
      }),
      enterprise: t('PlanComparisonTable.custom'),
    },
    carbon: {
      title: t('carbon.billed_action'),
      freePlan: t('carbon.short_price', {
        price: formatter.format(carbonFreePrice),
      }),
      paidPlans: t('carbon.short_price', {
        price: formatter.format(carbonPaidPrice),
      }),
      enterprise: t('PlanComparisonTable.custom'),
    },
    kelp: {
      title: t('kelp.billed_action'),
      freePlan: t('kelp.short_price', {
        price: formatter.format(kelpFreePrice),
      }),

      paidPlans: t('kelp.short_price', {
        price: formatter.format(kelpPaidPrice),
      }),
      enterprise: t('PlanComparisonTable.custom'),
    },
    water: {
      title: t('water.billed_action'),
      freePlan: t('water.short_price', {
        price: formatter.format(waterFreePrice),
      }),

      paidPlans: t('water.short_price', {
        price: formatter.format(waterPaidPrice),
      }),
      enterprise: t('PlanComparisonTable.custom'),
    },
    bees: {
      title: t('bees.billed_action'),
      freePlan: t('bees.short_price', {
        price: formatter.format(beesFreePrice),
      }),

      paidPlans: t('bees.short_price', {
        price: formatter.format(beesPaidPrice),
      }),
      enterprise: t('PlanComparisonTable.custom'),
    },
  } satisfies Record<OffsetType, PricingCopies>
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", null, _toDisplayString(_unref(t)('PlanComparisonTable.impact_pricing')), 1),
    _createElementVNode("table", null, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _cache[0] || (_cache[0] = _createElementVNode("th", { scope: "col" }, null, -1)),
          _createElementVNode("th", _hoisted_2, [
            _createElementVNode("h5", null, _toDisplayString(_unref(t)('PlanComparisonTable.free_plan')), 1)
          ]),
          _createElementVNode("th", _hoisted_3, [
            _createElementVNode("h5", null, _toDisplayString(_unref(t)('PlanComparisonTable.paid_plans')), 1),
            _createElementVNode("p", null, _toDisplayString(_unref(t)('PlanComparisonTable.paid_plan_list')), 1)
          ]),
          _createElementVNode("th", _hoisted_4, [
            _createElementVNode("h5", null, _toDisplayString(_unref(t)('PlanComparisonTable.enterprise')), 1)
          ])
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values(pricingPerImpact.value), (impact, index) => {
          return (_openBlock(), _createElementBlock("tr", { key: index }, [
            _createElementVNode("th", null, _toDisplayString(impact.title), 1),
            _createElementVNode("td", {
              innerHTML: impact.freePlan
            }, null, 8, _hoisted_5),
            _createElementVNode("td", {
              innerHTML: impact.paidPlans
            }, null, 8, _hoisted_6),
            _createElementVNode("td", null, _toDisplayString(impact.enterprise), 1),
            (index < Object.values(pricingPerImpact.value).length - 1)
              ? (_openBlock(), _createElementBlock("hr", _hoisted_7))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ])
    ])
  ]))
}
}

})