<template>
  <div>
    <RuleImpactSettingsPopup
      v-if="impactSettingsPopupIsOpen"
      :integration-id="integrationId"
      :selected-rule="selectedRule"
      :rule-list="rulesWithOffsets"
      @close="handleClose"
      @save="handleSaveRule"
    />
    <v-data-table
      :loading="loading"
      class="data-table"
      :items="rulesWithOffsets"
      :headers="headers"
      hide-default-footer
    >
      <template v-slot:no-data>
        <div class="data-table-empty">
          <v-icon :size="24">mdi-file-alert-outline</v-icon>
          {{ $t('RuleTable.no_data') }}
        </div>
      </template>
      <template v-slot:[`item.ruleId`]="{ item }">
        <span @click.prevent="copyToClipboard(item)">
          {{ shortenText(item.ruleId, 20) }}
          <v-icon :size="18" class="copy ml-1">
            {{ item.isCopied ? 'mdi-check-circle' : 'mdi-content-copy' }}
          </v-icon>
        </span>
      </template>
      <template v-slot:[`item.offsetType`]="{ item }">
        <div class="impact-pill-wrapper">
          <div class="impact-pill-row">
            <template v-if="getRuleOffsets(item.ruleId).length <= 3">
              <impact-pill
                v-for="(offset, index) in getRuleOffsets(item.ruleId)"
                :key="index"
                class="ma-0"
                :impact="{ type: offset.type, amount: getTotalRuleOffset([{ ...offset }]) }"
              />
            </template>
            <impact-pill
              v-else
              :impact="{
                type: 'multiple',
                amount: getTotalRuleOffset(getRuleOffsets(item.ruleId)),
              }"
            />
          </div>
        </div>
      </template>
      <template v-slot:[`item.cost`]="{ item }">
        {{ userCurrencySymbol }}{{ getImpactSettingTotal(item.offsets) }}
      </template>
      <template v-slot:[`item.context`]="{ item }">
        <div class="impact-pill-wrapper justify-end">
          <gs-button
            @click.prevent="editRule(item)"
            class="icon"
            size="small"
            type="outlined"
            icon="mdi-pencil"
          />
          <gs-button
            @click="openDeleteRuleDialog(item)"
            class="icon"
            size="small"
            type="outlined"
            icon="mdi-delete"
          />
        </div>
      </template>
    </v-data-table>
    <gs-button
      @click.prevent="impactSettingsPopupIsOpen = true"
      type="tertiary"
      icon="mdi-plus"
      full-width
    >
      {{ $t('RuleTable.add_new') }}
    </gs-button>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

import { getRules, type Rule, type RuleWithOffsets } from '@api/index'
import type { Automation, AutomationOffset, IntegrationOption } from '@/store/integrations'
import type { IntegrationPlatform } from '@/helpers/interfaces'
import { Utils } from '@/helpers/mixins/utilsMixin'
import { IntegrationsMixin } from '@/helpers/mixins/integrationsMixin'

import RuleImpactSettingsPopup from '@/components/onboarding/RuleImpactSettingsPopup.vue'
import ImpactPill from '@/components/ImpactWallet/ImpactPill.vue'
import type { DialogUpdatePayload } from '@/store/dialog'
import { Dialogs } from '@/store/dialog'
import type { Project } from '@/store/projects'
import { IMPACT_TYPE_DEFAULT_PROJECT_ID_MAP } from '@/helpers/projects'

interface RuleTableItemView extends RuleWithOffsets {
  isCopied?: boolean
}

export default defineComponent({
  name: 'RuleTable',
  emits: ['save'],
  mixins: [Utils, IntegrationsMixin],
  components: {
    RuleImpactSettingsPopup,
    ImpactPill,
  },
  data() {
    return {
      rulesWithOffsets: [],
      rules: [],
      selectedRule: undefined,
      loading: false,
      impactSettingsPopupIsOpen: false,
      headers: [
        {
          title: this.$t('RuleTable.headers.ruleName'),
          align: 'start',
          sortable: true,
          key: 'name',
          width: 180,
        },
        {
          title: this.$t('RuleTable.headers.ruleId'),
          align: 'start',
          key: 'ruleId',
          sortable: false,
          width: 260,
        },
        {
          title: this.$t('RuleTable.headers.impact'),
          align: 'start',
          key: 'offsetType',
          sortable: false,
          width: 260,
        },
        {
          title: this.$t('RuleTable.headers.cost'),
          align: 'start',
          key: 'cost',
          sortable: false,
          width: 260,
        },
        {
          title: '',
          align: 'end',
          key: 'context',
          sortable: false,
        },
      ],
    } as {
      rulesWithOffsets: RuleTableItemView[]
      rules: Rule[]
      selectedRule: RuleWithOffsets | undefined
      loading: boolean
      impactSettingsPopupIsOpen: boolean
      headers: {
        title: string
        align: 'start' | 'end' | 'center'
        sortable: boolean
        key: string
        width?: number | string
      }[]
    }
  },
  async created() {
    await this.fetchRules()
  },
  computed: {
    getAutomationById(): (automationId: string) => Automation {
      return this.$store.getters['getAutomationById']
    },
    automation(): Automation | undefined {
      return this.automationId ? this.getAutomationById(this.automationId) : undefined
    },
    integrationOptionList(): IntegrationOption[] {
      return this.$store.getters['getIntegrationOptionList']
    },
    userCurrencySymbol(): string {
      return this.$store.getters['getUserCurrencySymbol']
    },
    projects(): Project[] {
      return this.$store.getters['getAppProjects']
    },
  },
  methods: {
    getImpactPillAmount(offset: AutomationOffset): number {
      const project = this.projects.find(
        ({ projectId }) =>
          projectId === (offset.projectId || IMPACT_TYPE_DEFAULT_PROJECT_ID_MAP[offset.type]),
      )
      return (offset.amount || 0) * (project?.unit || 1)
    },
    handleClose() {
      this.impactSettingsPopupIsOpen = false
      this.selectedRule = undefined
    },
    openDialog(dialog: DialogUpdatePayload): Promise<void> {
      return this.$store.dispatch('openDialog', dialog)
    },
    copyToClipboard(item: RuleTableItemView) {
      navigator.clipboard.writeText(item.ruleId).then(() => {
        item.isCopied = true
        setTimeout(() => {
          item.isCopied = false
        }, 2000)
      })
    },
    openDeleteRuleDialog(rule: RuleWithOffsets) {
      this.openDialog({
        name: Dialogs.TEMPLATE.DELETE,
        texts: {
          title: this.$t('RuleTable.delete_confirm.title'),
          description: this.$t('RuleTable.delete_confirm.description'),
          primaryButtonText: this.$t('CommonUi.delete'),
          secondaryButtonText: this.$t('CommonUi.cancel'),
          deleteCheckboxLabel: this.$t('RuleTable.delete_confirm.checkbox'),
        },
        options: {
          persistent: true,
        },
        actions: {
          onPrimaryButtonClick: async () => {
            await this.deleteRule(rule)
          },
        },
      })
    },
    getRuleOffsets(ruleId: string): AutomationOffset[] {
      return this.rulesWithOffsets.find((item) => item.ruleId === ruleId)?.offsets || []
    },
    getTotalRuleOffset(offsets: AutomationOffset[]): number {
      return offsets.reduce((total, offset) => {
        return total + this.getImpactPillAmount(offset)
      }, 0)
    },
    mapOffsetsToRules() {
      const rulesMap = this.mapAutomationOffsets(this.automation)

      this.rulesWithOffsets = this.rules
        .filter((rule) => Object.keys(rulesMap).includes(rule.ruleId))
        .map((rule) => ({
          ...rule,
          offsets: rulesMap[rule.ruleId] || [],
        }))
    },
    async fetchRules() {
      this.loading = true

      try {
        const { data } = await getRules(this.integrationId)
        this.rules = data

        if (this.automation) {
          this.mapOffsetsToRules()
        }

        return Promise.resolve()
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    editRule(rule: RuleWithOffsets) {
      this.impactSettingsPopupIsOpen = true
      this.selectedRule = rule
    },
    deleteRule(rule: RuleWithOffsets) {
      const index = this.rulesWithOffsets.findIndex((item) => item.id === rule.id)
      this.rulesWithOffsets.splice(index, 1)

      this.$emit('save', this.rulesWithOffsets)
    },
    async handleSaveRule(savedRule: RuleWithOffsets) {
      const existingRule = this.rulesWithOffsets.find((rule) => rule.ruleId === savedRule.ruleId)

      if (existingRule) {
        existingRule.name = savedRule.name
        existingRule.offsets = [...savedRule.offsets]
      } else {
        this.rulesWithOffsets.push({ ...savedRule })
      }

      this.$emit('save', this.rulesWithOffsets)
    },
  },
  props: {
    automationId: {
      type: String,
    },
    integrationId: {
      type: String,
    },
    platform: {
      type: String as PropType<IntegrationPlatform>,
    },
  },
})
</script>

<style lang="scss" scoped>
.data-table {
  padding: 0 8px 0;
  border-radius: 10px;
  border: 1px solid var(--gray-light-CD);
  margin-bottom: 24px;
}

.data-table-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 12px;
  flex-direction: column;
  font-size: 18px;
  padding: 16px 0;
}

:deep(.v-data-table-header__content) {
  font-size: 15px;
  font-weight: bold;
}

.impact-pill-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;

  .gs-button {
    padding-left: 4px !important;
    border: none !important;
    text-wrap: nowrap;
  }

  .icon {
    padding: 0 !important;
    min-width: 28px;
    margin-left: 8px;
    background-color: transparent !important;

    :deep(.text) {
      margin-left: 0 !important;
    }
  }
}

.impact-pill-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.copy {
  cursor: pointer;
}
</style>
