import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, Fragment as _Fragment, toDisplayString as _toDisplayString, renderSlot as _renderSlot, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "feed-item-title-wrapper" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "feed-item-title" }
const _hoisted_4 = { class: "feed-item-text" }
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 1,
  class: "feed-video-wrapper"
}
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "cms-content" }
const _hoisted_9 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GreenSparkLogo = _resolveComponent("GreenSparkLogo")!
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createElementBlock("li", {
    class: _normalizeClass([
      'news-feed-item',
      {
        'simple-background':
          _ctx.feedItem?.type === 'WHY_WE_JOINED' || _ctx.feedItem?.type === 'IMPACT_TARGET',
      },
      { highlight: _ctx.feedItem?.type === 'CAMPAIGN' },
      { 'margin-offset': _ctx.feedItem?.type === 'ONE_OFF' || _ctx.feedItem?.byImpactType === 'plastic' },
    ])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.feedItem?.byImpactType && _ctx.feedItem.byImpactType !== 'all')
        ? (_openBlock(), _createElementBlock("svg", {
            key: 0,
            class: _normalizeClass(['feed-item-icon', _ctx.feedItem.byImpactType])
          }, [
            _createElementVNode("use", {
              href: '#' + _ctx.feedItem.byImpactType + '-icon'
            }, null, 8, _hoisted_2)
          ], 2))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_ctx.iconName === 'GreenSparkLogo')
              ? (_openBlock(), _createBlock(_component_GreenSparkLogo, {
                  key: 0,
                  class: "feed-item-icon material-icons-outlined"
                }))
              : (_openBlock(), _createBlock(_component_v_icon, {
                  key: 1,
                  class: "feed-item-icon material-icons-outlined",
                  icon: _ctx.iconName
                }, null, 8, ["icon"]))
          ], 64)),
      _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.feedItem?.type === 'GOAL' ? _ctx.t('did_you_know') : _ctx.feedItem?.title), 1)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass([
        'content-wrapper',
        { business: _ctx.feedItem?.type === 'WHY_WE_JOINED' || _ctx.feedItem?.type === 'IMPACT_TARGET' },
      ])
    }, [
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.feedItem?.description), 1),
      (_ctx.feedItem?.image)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _ctx.feedItem?.image,
            alt: "feed item image",
            height: "100",
            width: "100",
            loading: "lazy",
            class: "feed-item-image"
          }, null, 8, _hoisted_5))
        : _createCommentVNode("", true),
      (_ctx.feedItem?.videoURL)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("iframe", {
              class: "feed-video",
              src: `${_ctx.feedItem?.videoURL}?h=fb4c767926&badge=0&autopause=0&player_id=0&app_id=58479`,
              allow: "autoplay; fullscreen; picture-in-picture",
              allowfullscreen: ""
            }, null, 8, _hoisted_7)
          ]))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", _hoisted_8, [
      _renderSlot(_ctx.$slots, "cms-content", {}, undefined, true)
    ]),
    (_ctx.isImpactActionType)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "feed-item-link",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.openOrderDetails && _ctx.openOrderDetails(...args)), ["prevent"]))
        }, _toDisplayString(_ctx.feedItem?.link?.label), 1))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.feedItem?.link)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                href: _ctx.feedItem?.link?.url,
                target: "_blank",
                class: "feed-item-link"
              }, _toDisplayString(_ctx.feedItem?.link?.label), 9, _hoisted_9))
            : _createCommentVNode("", true)
        ], 64))
  ], 2))
}