import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "image-upload-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "image-sun"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  class: "image-sun-business"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "left-column" }
const _hoisted_7 = { class: "right-column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.account.accountType === 'personal' || _ctx.account.accountType === 'family')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("img", {
              class: "profile-pic",
              style: _normalizeStyle(
            _ctx.account.imageURL === ''
              ? 'border-radius: 50%; width: 100%; height: 100%;'
              : _ctx.png
              ? 'max-width: 100%; object-fit: contain;'
              : 'max-height: 100%;'
          ),
              alt: "profile-pic",
              src: _ctx.url
            }, null, 12, _hoisted_3)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("img", {
              class: "profile-pic",
              style: _normalizeStyle(
            _ctx.account.imageURL === ''
              ? 'border-radius: 50%; width: 100%; height: 100%;'
              : _ctx.png
              ? 'max-width: 100%; object-fit: contain;'
              : 'max-height: 100%;'
          ),
              alt: "profile-pic",
              src: _ctx.url
            }, null, 12, _hoisted_5)
          ])),
      _createVNode(_component_gs_button, {
        type: "secondary",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('uploadClick')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.isBusiness ? _ctx.t('upload') : _ctx.t('upload_picture')), 1)
        ]),
        _: 1
      })
    ]),
    _cache[7] || (_cache[7] = _createElementVNode("div", { class: "divider-line" }, null, -1)),
    _createVNode(_component_v_form, {
      modelValue: _ctx.isFormValid,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.isFormValid) = $event)),
      class: "form-wrapper"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_v_text_field, {
            modelValue: _ctx.firstName,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.firstName) = $event)),
            class: "input-field",
            label: _ctx.t('first_name'),
            variant: "outlined",
            flat: "",
            color: "#3B755F",
            style: {"margin-top":"15px"},
            rules: [_ctx.rules.required]
          }, null, 8, ["modelValue", "label", "rules"]),
          _createVNode(_component_v_text_field, {
            modelValue: _ctx.lastName,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.lastName) = $event)),
            class: "input-field",
            label: _ctx.t('last_name'),
            variant: "outlined",
            flat: "",
            color: "#3B755F",
            rules: [_ctx.rules.required]
          }, null, 8, ["modelValue", "label", "rules"]),
          (_ctx.account.accountType === 'business' || _ctx.account.accountType === 'ecommerce')
            ? (_openBlock(), _createBlock(_component_v_text_field, {
                key: 0,
                modelValue: _ctx.companyName,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.companyName) = $event)),
                flat: "",
                class: "input-field",
                label: _ctx.t('company_name'),
                variant: "outlined",
                color: "#3B755F",
                rules: [_ctx.rules.required]
              }, null, 8, ["modelValue", "label", "rules"]))
            : _createCommentVNode("", true),
          _createVNode(_component_gs_button, {
            loading: _ctx.loading,
            disabled: !_ctx.isFormValid,
            onClick: _withModifiers(_ctx.updateProfile, ["prevent"])
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('save')), 1)
            ]),
            _: 1
          }, 8, ["loading", "disabled", "onClick"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          (_ctx.account.accountType === 'business' || _ctx.account.accountType === 'ecommerce')
            ? (_openBlock(), _createBlock(_component_v_text_field, {
                key: 0,
                modelValue: _ctx.website,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.website) = $event)),
                prefix: "https://",
                rules: [_ctx.rules.url],
                class: "input-field",
                label: _ctx.t('website'),
                placeholder: _ctx.t('website_text'),
                variant: "outlined",
                flat: "",
                color: "#3B755F",
                style: {"margin-top":"0"}
              }, null, 8, ["modelValue", "rules", "label", "placeholder"]))
            : _createCommentVNode("", true),
          _createVNode(_component_v_textarea, {
            modelValue: _ctx.profileDescription,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.profileDescription) = $event)),
            variant: "outlined",
            color: "#3B755F",
            clearable: "",
            flat: "",
            "no-resize": "",
            name: "input-7-4",
            label: _ctx.t('profile_description'),
            placeholder: _ctx.t('profile_description_placeholder'),
            rules: [_ctx.rules.maxlength(1500)]
          }, null, 8, ["modelValue", "label", "placeholder", "rules"])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}