import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "add-integration-view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_integration = _resolveComponent("select-integration")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_select_integration, { onIntegrationSelected: _ctx.navigate }, null, 8, ["onIntegrationSelected"])
  ]))
}