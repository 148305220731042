import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "update-wrapper" }
const _hoisted_2 = { class: "subheader" }
const _hoisted_3 = { class: "plan-name-total" }
const _hoisted_4 = { class: "plan" }
const _hoisted_5 = { class: "number" }
const _hoisted_6 = { class: "pending-number" }
const _hoisted_7 = { class: "text-field-wrapper" }
const _hoisted_8 = { class: "plus-minus" }
const _hoisted_9 = { class: "plan-name-total" }
const _hoisted_10 = { class: "plan" }
const _hoisted_11 = { class: "number" }
const _hoisted_12 = { class: "tree-text" }
const _hoisted_13 = { class: "pending-number" }
const _hoisted_14 = { class: "text-field-wrapper" }
const _hoisted_15 = { class: "plus-minus" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_v_text_field = _resolveComponent("v-text-field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.t('your_upgrades')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.plasticHero), 1),
        _cache[6] || (_cache[6] = _createElementVNode("span", { class: "x" }, "x", -1)),
        _createElementVNode("div", null, _toDisplayString(_ctx.t('plastic_hero')), 1),
        _createVNode(_component_Tooltip, {
          class: "tooltip",
          body: _ctx.t('plastic_hero_tooltip'),
          "link-text": 'Source',
          link: 'https://ourworldindata.org/plastic-pollution'
        }, null, 8, ["body"]),
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.t('pending', { count: _ctx.pendingPlasticHero })), 1)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_v_text_field, {
          modelValue: _ctx.addPlasticHero,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.addPlasticHero) = $event)),
          modelModifiers: { number: true },
          label: _ctx.t('adjust_plastic_hero'),
          variant: "outlined",
          clearable: "",
          type: "number",
          flat: "",
          color: "#3B755F",
          class: "employee-input"
        }, null, 8, ["modelValue", "label"]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", {
            class: _normalizeClass('add'),
            style: {"margin-bottom":"1px"},
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addPlasticHero ? _ctx.addPlasticHero++ : (_ctx.addPlasticHero = 1)))
          }, " + "),
          _createElementVNode("div", {
            class: _normalizeClass(['add', { disabled: _ctx.isPlasticHeroMinusDisabled }]),
            style: {"margin-top":"1px"},
            onClick: _cache[2] || (_cache[2] = ($event: any) => (!_ctx.isPlasticHeroMinusDisabled ? _ctx.addPlasticHero && _ctx.addPlasticHero-- : null))
          }, " - ", 2)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.treePlanter), 1),
        _cache[7] || (_cache[7] = _createElementVNode("span", { class: "x" }, "x", -1)),
        _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.t('tree_planter')), 1),
        _createVNode(_component_Tooltip, {
          class: "tooltip",
          body: _ctx.t('trees_tooltip_new')
        }, null, 8, ["body"]),
        _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.t('pending', { count: _ctx.pendingTreePlanter })), 1)
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createVNode(_component_v_text_field, {
          modelValue: _ctx.addTreePlanter,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.addTreePlanter) = $event)),
          modelModifiers: { number: true },
          label: _ctx.t('adjust_tree_planter'),
          variant: "outlined",
          clearable: "",
          type: "number",
          flat: "",
          color: "#3B755F",
          class: "employee-input"
        }, null, 8, ["modelValue", "label"]),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("div", {
            class: _normalizeClass('add'),
            style: {"margin-bottom":"1px"},
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.addTreePlanter ? _ctx.addTreePlanter++ : (_ctx.addTreePlanter = 1)))
          }, " + "),
          _createElementVNode("div", {
            class: _normalizeClass(['add', { disabled: _ctx.isTreePlanterMinusDisabled }]),
            style: {"margin-top":"1px"},
            onClick: _cache[5] || (_cache[5] = ($event: any) => (!_ctx.isTreePlanterMinusDisabled ? _ctx.addTreePlanter && _ctx.addTreePlanter-- : null))
          }, " - ", 2)
        ])
      ])
    ])
  ]))
}