import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "create-api-key" }
const _hoisted_2 = { class: "integration-name-wrapper section border-bottom border-top" }
const _hoisted_3 = { class: "label integration" }
const _hoisted_4 = {
  key: 0,
  class: "integration-name-wrapper section border-bottom"
}
const _hoisted_5 = { class: "label integration" }
const _hoisted_6 = {
  key: 1,
  class: "integration-name-wrapper section border-bottom"
}
const _hoisted_7 = { class: "label integration" }
const _hoisted_8 = { class: "trigger-wrapper section" }
const _hoisted_9 = { class: "label-wrapper" }
const _hoisted_10 = { class: "label" }
const _hoisted_11 = { class: "help-text" }
const _hoisted_12 = { class: "button-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OnboardingPill = _resolveComponent("OnboardingPill")!
  const _component_gs_input = _resolveComponent("gs-input")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_gs_button = _resolveComponent("gs-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("dl", _hoisted_2, [
      _createElementVNode("dd", _hoisted_3, _toDisplayString(_ctx.t('integration')), 1),
      _createVNode(_component_OnboardingPill, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.getIntegrationPlatformName(_ctx.customIntegrationType as IntegrationPlatform) ?? ''), 1)
        ]),
        _: 1
      })
    ]),
    (!_ctx.isSimplifiedCustomIntegration)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.t('source_detail')), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customIntegrationSetting?.sourceDetails, (item, key) => {
            return (_openBlock(), _createBlock(_component_OnboardingPill, {
              key: key,
              "is-copy-enabled": key.includes('Id')
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item), 1)
              ]),
              _: 2
            }, 1032, ["is-copy-enabled"]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (!_ctx.isSimplifiedCustomIntegration)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.t('trigger_detail')), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customIntegrationSetting?.triggerDetails, (item, key) => {
            return (_openBlock(), _createBlock(_component_OnboardingPill, {
              key: key,
              "is-copy-enabled": key.includes('Id')
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(key === 'triggerType' ? _ctx.t(_ctx.snakeCase(item)) : item), 1)
              ]),
              _: 2
            }, 1032, ["is-copy-enabled"]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_v_form, {
        modelValue: _ctx.isFormValid,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isFormValid) = $event))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.t(`apikey_label_${_ctx.customIntegrationType}`)), 1)
          ]),
          _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.t(`apikey_help_${_ctx.customIntegrationType}`)), 1),
          _createVNode(_component_gs_input, {
            modelValue: _ctx.apiKeyName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.apiKeyName) = $event)),
            "hide-details": false,
            class: "custom-input",
            label: _ctx.t('apikey_name'),
            rules: [_ctx.rules.required, _ctx.rules.maxlength(_ctx.maxLength), _ctx.rules.minlength(_ctx.minLength)],
            counter: "",
            maxlength: _ctx.maxLength
          }, null, 8, ["modelValue", "label", "rules", "maxlength"])
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createVNode(_component_gs_button, {
        class: "form-control",
        size: "large",
        type: "outlined",
        icon: "mdi-chevron-left",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('back')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('CommonUi.back')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_gs_button, {
        "full-width": "",
        size: "large",
        type: "tertiary",
        disabled: !_ctx.isFormValid || _ctx.loading,
        loading: _ctx.loading,
        onClick: _withModifiers(_ctx.generateApiKey, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('next')), 1)
        ]),
        _: 1
      }, 8, ["disabled", "loading", "onClick"])
    ])
  ]))
}