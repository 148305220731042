import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "manage-integrations-automations" }
const _hoisted_2 = { class: "integrations-title" }
const _hoisted_3 = { class: "text-content" }
const _hoisted_4 = { class: "description" }
const _hoisted_5 = { class: "tab-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_navigate_back = _resolveComponent("navigate-back")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.t('title')), 1),
    _createVNode(_component_navigate_back, {
      mode: "programmatic",
      onBack: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ path: '/add-impact' })))
    }),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.t('description')), 1),
      (_ctx.hasAnyAutomation)
        ? (_openBlock(), _createBlock(_component_gs_button, {
            key: 0,
            size: "large",
            width: "200",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push({ name: 'AddIntegrationsAndAutomations' })))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('add_automation')), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_router_link, {
        to: "/add-impact/manage-integrations-automations/automations",
        class: "tab-pill"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('automations')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: "/add-impact/manage-integrations-automations/integrations",
        class: "tab-pill"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('integrations')), 1)
        ]),
        _: 1
      })
    ]),
    (_openBlock(), _createBlock(_component_router_view, {
      key: _ctx.$route.fullPath
    }))
  ]))
}