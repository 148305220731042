import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "menu-bar" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "menu-item-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: _normalizeClass(['menu-item', { 'menu-item-active': item.active }]),
        onClick: ($event: any) => (_ctx.$emit('menuClick', item.value, index))
      }, [
        _createElementVNode("p", _hoisted_3, _toDisplayString(item.label), 1)
      ], 10, _hoisted_2))
    }), 128))
  ]))
}