import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "button-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createBlock(_component_v_btn, {
    class: "small-button",
    color: `${_ctx.type === 'green' ? '#3B755F' : '#E57066'}`,
    height: 28,
    width: "auto",
    disabled: _ctx.disabled || false,
    onClick: _ctx.click
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.label), 1)
    ]),
    _: 1
  }, 8, ["color", "disabled", "onClick"]))
}