import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "dialog-wrapper"
}
const _hoisted_2 = { class: "close-wrapper" }
const _hoisted_3 = { class: "dialog-title" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "dialog-text" }
const _hoisted_6 = { class: "button-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gs_button = _resolveComponent("gs-button")!

  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t('stop')), 1),
            _createElementVNode("img", {
              style: {"width":"25px"},
              class: "close-icon",
              src: require('@/assets/icons/close_icon.svg'),
              alt: "close",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('dialogClick')))
            }, null, 8, _hoisted_4)
          ]),
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.t('text')), 1),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_gs_button, {
              type: "danger",
              size: "large",
              "full-width": "",
              loading: _ctx.loading,
              disabled: _ctx.loading,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.stop()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('stop')), 1)
              ]),
              _: 1
            }, 8, ["loading", "disabled"])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}