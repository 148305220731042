import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "popup-header" }
const _hoisted_2 = { class: "delete-title" }
const _hoisted_3 = { class: "button-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.isOpen,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isOpen) = $event)),
    persistent: "",
    "max-width": "500"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, { class: "card d-flex flex-column" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.deleteType === 'domain' ? 'Delete custom domain' : 'Delete email template '), 1),
            _createElementVNode("button", {
              class: "close-button",
              onClick: _cache[0] || (_cache[0] = 
            () => {
              _ctx.isOpen = !_ctx.isOpen
              _ctx.$emit('close')
            }
          )
            })
          ]),
          (_ctx.deleteType === 'domain')
            ? (_openBlock(), _createBlock(_component_i18n_t, {
                key: 0,
                keypath: "DeleteDomainDialog.delete_text",
                tag: "p",
                class: "delete-text"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("b", null, _toDisplayString(_ctx.selectedDomain?.domainName), 1)
                ]),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_i18n_t, {
                key: 1,
                keypath: "DeleteDomainDialog.delete_text_template",
                tag: "p",
                class: "delete-text"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("b", null, _toDisplayString(_ctx.emailTemplateName), 1)
                ]),
                _: 1
              })),
          _createVNode(_component_v_form, {
            modelValue: _ctx.isFormValid,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isFormValid) = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_checkbox, {
                id: "approve-delete-checkbox",
                rules: [_ctx.rules.required],
                color: "green",
                class: "checkbox",
                "hide-details": "",
                label: _ctx.deleteType === 'domain' ? _ctx.t('approve_delete') : _ctx.t('approve_delete_template')
              }, null, 8, ["rules", "label"]),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_gs_button, {
                  type: "danger",
                  size: "large",
                  loading: _ctx.loading,
                  disabled: !_ctx.isFormValid,
                  "full-width": "",
                  onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (
              _ctx.deleteType === 'domain'
                ? _ctx.deleteDomain()
                : _ctx.emailTemplateID && _ctx.deleteTemplate(_ctx.emailTemplateID)
            ), ["prevent"]))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.deleteType === 'domain' ? _ctx.t('delete_domain') : _ctx.t('delete_template')), 1)
                  ]),
                  _: 1
                }, 8, ["loading", "disabled"]),
                _createVNode(_component_gs_button, {
                  type: "outlined",
                  size: "large",
                  "full-width": "",
                  onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => {;(_ctx.isOpen = false), _ctx.$emit('close')}, ["prevent"]))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('cancel')), 1)
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}