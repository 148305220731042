<template>
  <v-dialog v-model="isOpen" persistent max-width="500">
    <v-card class="card d-flex flex-column">
      <div class="popup-header">
        <p class="delete-title">
          {{ deleteType === 'domain' ? 'Delete custom domain' : 'Delete email template ' }}
        </p>
        <button
          class="close-button"
          @click="
            () => {
              isOpen = !isOpen
              $emit('close')
            }
          "
        />
      </div>
      <template v-if="deleteType === 'domain'">
        <i18n-t keypath="DeleteDomainDialog.delete_text" tag="p" class="delete-text">
          <b>{{ selectedDomain?.domainName }}</b>
        </i18n-t>
      </template>
      <template v-else>
        <i18n-t keypath="DeleteDomainDialog.delete_text_template" tag="p" class="delete-text">
          <b>{{ emailTemplateName }}</b>
        </i18n-t>
      </template>

      <v-form v-model="isFormValid">
        <v-checkbox
          id="approve-delete-checkbox"
          :rules="[rules.required]"
          color="green"
          class="checkbox"
          hide-details
          :label="deleteType === 'domain' ? t('approve_delete') : t('approve_delete_template')"
        />
        <div class="button-wrapper">
          <gs-button
            type="danger"
            size="large"
            :loading="loading"
            :disabled="!isFormValid"
            full-width
            @click.prevent="
              deleteType === 'domain'
                ? deleteDomain()
                : emailTemplateID && deleteTemplate(emailTemplateID)
            "
          >
            {{ deleteType === 'domain' ? t('delete_domain') : t('delete_template') }}
          </gs-button>
          <gs-button
            type="outlined"
            size="large"
            full-width
            @click.prevent=";(isOpen = false), $emit('close')"
          >
            {{ t('cancel') }}
          </gs-button>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import type { Notification } from '@/store/notification'
import { deleteDomain, deleteEmailTemplate } from '@api/index'
import { RulesMixin } from '@/helpers/mixins/RulesMixin.vue'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DeleteDomainDialog',
  emits: ['close', 'deleted'],
  mixins: [RulesMixin],
  data() {
    return {
      isOpen: true,
      isFormValid: false,
      loading: false,
    }
  },
  methods: {
    t(key: string) {
      return this.$t(`DeleteDomainDialog.${key}`)
    },
    async deleteDomain() {
      this.loading = true
      try {
        if (this.selectedDomain && 'domainId' in this.selectedDomain)
          await deleteDomain(this.selectedDomain.domainId)
        await this.setDomainList()
        this.$emit('deleted')
        this.$emit('close')
        this.isOpen = false
        this.$store.dispatch('notification/notify', {
          text: this.t('domain_deleted'),
        } as Notification)
      } catch (e) {
        this.$store.dispatch('notification/notify', {
          text: this.t('error'),
          isError: true,
        } as Notification)
      }
      this.loading = false
    },
    async deleteTemplate(id: string) {
      this.loading = true
      try {
        await deleteEmailTemplate(id)
        await this.setEmailTemplateList()
        this.$emit('deleted')
        this.$emit('close')
        this.isOpen = false
        this.$store.dispatch('notification/notify', {
          text: this.t('template_deleted'),
        } as Notification)
      } catch (e) {
        this.loading = false
        console.error(e.response.data)
        this.$store.dispatch('notification/notify', {
          text: this.t('error'),
          isError: true,
          isClosable: true,
          buttonText: 'close',
        } as Notification)
      }
    },
    setDomainList() {
      return this.$store.dispatch('setDomainList')
    },
    setEmailTemplateList() {
      return this.$store.dispatch('setEmailTemplateList')
    },
  },
  props: {
    selectedDomain: {
      type: Object as PropType<Record<string, string>>,
    },
    deleteType: {
      type: String as PropType<'domain' | 'template'>,
    },
    emailTemplateID: {
      type: String,
    },
    emailTemplateName: {
      type: String,
    },
  },
})
</script>

<style lang="scss" scoped>
.card {
  position: relative;
  padding: 20px;
}

.delete-title {
  font-size: 26px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 20px;
  color: var(--ui-red);
}

.button-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  flex: 1 1 0;
  gap: 20px;
}

.close-button {
  position: absolute;
  right: 20px;
  top: 25px;
  height: 20px;
}

.checkbox {
  margin-bottom: 20px;
}
</style>
