import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "account-wrapper" }
const _hoisted_2 = { class: "tab-header" }
const _hoisted_3 = { class: "tab-header" }
const _hoisted_4 = { class: "tab-header" }
const _hoisted_5 = { class: "tab-header" }
const _hoisted_6 = { class: "tab-content" }
const _hoisted_7 = { class: "tab-content" }
const _hoisted_8 = { class: "tab-content" }
const _hoisted_9 = { class: "tab-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_UpdateProfile = _resolveComponent("UpdateProfile")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_Users = _resolveComponent("Users")!
  const _component_Billing = _resolveComponent("Billing")!
  const _component_ApiKey = _resolveComponent("ApiKey")!
  const _component_v_window = _resolveComponent("v-window")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_tabs, {
      modelValue: _ctx.tab,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.tab) = $event)),
      "bg-color": "transparent",
      color: "green",
      "center-active": "",
      grow: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_tab, {
          class: "tabs",
          onClick: _cache[0] || (_cache[0] = () => _ctx.selectTab('profile'))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.t('profile')), 1)
          ]),
          _: 1
        }),
        (_ctx.isBusiness)
          ? (_openBlock(), _createBlock(_component_v_tab, {
              key: 0,
              class: "tabs",
              onClick: _cache[1] || (_cache[1] = () => _ctx.selectTab('users'))
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t('users')), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_v_tab, {
          class: "tabs",
          onClick: _cache[2] || (_cache[2] = () => _ctx.selectTab('billing'))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t('billing')), 1)
          ]),
          _: 1
        }),
        (_ctx.isBusiness)
          ? (_openBlock(), _createBlock(_component_v_tab, {
              key: 1,
              class: "tabs",
              onClick: _cache[3] || (_cache[3] = () => _ctx.selectTab('apiKey'))
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.t('api_key')), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_window, {
      touch: false,
      modelValue: _ctx.tab,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.tab) = $event)),
      class: "tabitem-wrapper"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_window_item, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_UpdateProfile, {
                png: _ctx.png,
                url: _ctx.url,
                onUploadClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.openUploadModal()))
              }, null, 8, ["png", "url"])
            ])
          ]),
          _: 1
        }),
        (_ctx.isBusiness)
          ? (_openBlock(), _createBlock(_component_v_window_item, { key: 0 }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_Users)
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_v_window_item, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_Billing, { account: _ctx.account }, null, 8, ["account"])
            ])
          ]),
          _: 1
        }),
        (_ctx.isBusiness)
          ? (_openBlock(), _createBlock(_component_v_window_item, { key: 1 }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_ApiKey)
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}