import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "custom-integration" }
const _hoisted_2 = { class: "integration-name-wrapper section" }
const _hoisted_3 = { class: "label" }
const _hoisted_4 = {
  key: 0,
  class: "source-wrapper section"
}
const _hoisted_5 = { class: "label-wrapper" }
const _hoisted_6 = { class: "label" }
const _hoisted_7 = { class: "help-text" }
const _hoisted_8 = {
  key: 1,
  class: "icon-wrapper section"
}
const _hoisted_9 = { class: "label" }
const _hoisted_10 = { class: "help-text" }
const _hoisted_11 = { class: "trigger-wrapper section" }
const _hoisted_12 = { class: "label-wrapper" }
const _hoisted_13 = { class: "label" }
const _hoisted_14 = { class: "help-text" }
const _hoisted_15 = { class: "trigger-type-wrapper section" }
const _hoisted_16 = { class: "label-wrapper" }
const _hoisted_17 = { class: "label" }
const _hoisted_18 = { class: "help-text" }
const _hoisted_19 = { class: "button-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OnboardingPill = _resolveComponent("OnboardingPill")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_gs_input = _resolveComponent("gs-input")!
  const _component_UploadIcon = _resolveComponent("UploadIcon")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_gs_button = _resolveComponent("gs-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("dl", _hoisted_2, [
      _createElementVNode("dd", _hoisted_3, _toDisplayString(_ctx.t('integration')), 1),
      _createVNode(_component_OnboardingPill, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.onboardingPillText), 1)
        ]),
        _: 1
      }),
      (_ctx.type === 'custom' && _ctx.customIntegrationSettings?.sourceDetails.sourceName)
        ? (_openBlock(), _createBlock(_component_OnboardingPill, { key: 0 }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.customIntegrationSettings?.sourceDetails.sourceName), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_v_form, {
      modelValue: _ctx.isFormValid,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isFormValid) = $event)),
      class: "custom-integration-form"
    }, {
      default: _withCtx(() => [
        (_ctx.showSourceNameAndIconSections)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.t('source_label')), 1),
                (_ctx.type === 'custom')
                  ? (_openBlock(), _createBlock(_component_v_tooltip, {
                      key: 0,
                      "open-on-click": _ctx.isTouch,
                      color: "black",
                      location: "top",
                      "max-width": "240px"
                    }, {
                      activator: _withCtx(({ props }) => [
                        _createVNode(_component_v_icon, _mergeProps({ class: "tooltip-icon" }, props), {
                          default: _withCtx(() => _cache[6] || (_cache[6] = [
                            _createTextVNode(" mdi-information-outline")
                          ])),
                          _: 2
                        }, 1040)
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("span", null, _toDisplayString(_ctx.t('source_tooltip')), 1)
                      ]),
                      _: 1
                    }, 8, ["open-on-click"]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.t('source_help')), 1),
              _createVNode(_component_gs_input, {
                modelValue: _ctx.payload.sourceDetails.sourceName,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.payload.sourceDetails.sourceName) = $event)),
                "hide-details": false,
                class: "custom-input",
                label: _ctx.t('source_name'),
                rules: [_ctx.rules.required, _ctx.rules.maxlength(_ctx.maxLength), _ctx.rules.minlength(_ctx.minLength)],
                counter: "",
                maxlength: _ctx.maxLength
              }, null, 8, ["modelValue", "label", "rules", "maxlength"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.showSourceNameAndIconSections)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.t('icon_label')), 1),
              _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.t('icon_help')), 1),
              _createVNode(_component_UploadIcon, {
                modelValue: _ctx.payload.imageUrl,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.payload.imageUrl) = $event))
              }, null, 8, ["modelValue"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.t('trigger_label')), 1),
            _createVNode(_component_v_tooltip, {
              "open-on-click": _ctx.isTouch,
              color: "black",
              location: "top",
              "max-width": "240px"
            }, {
              activator: _withCtx(({ props }) => [
                _createVNode(_component_v_icon, _mergeProps({ class: "tooltip-icon" }, props), {
                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                    _createTextVNode(" mdi-information-outline")
                  ])),
                  _: 2
                }, 1040)
              ]),
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.t('trigger_tooltip')), 1)
              ]),
              _: 1
            }, 8, ["open-on-click"])
          ]),
          _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.t(_ctx.getTriggerHelpKey, {
              source: _ctx.type ? _ctx.getIntegrationPlatformName(_ctx.type) || '' : '',
            })), 1),
          _createVNode(_component_gs_input, {
            modelValue: _ctx.payload.triggerDetails.triggerName,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.payload.triggerDetails.triggerName) = $event)),
            "hide-details": false,
            class: "custom-input",
            label: _ctx.t('trigger_name'),
            rules: [_ctx.rules.required, _ctx.rules.maxlength(_ctx.maxLength), _ctx.rules.minlength(_ctx.minLength)],
            counter: "",
            maxlength: _ctx.maxLength
          }, null, 8, ["modelValue", "label", "rules", "maxlength"])
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.t('trigger_type_label')), 1)
          ]),
          _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.t('trigger_type_help')), 1),
          _createVNode(_component_v_select, {
            modelValue: _ctx.payload.triggerDetails.triggerType,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.payload.triggerDetails.triggerType) = $event)),
            variant: "outlined",
            flat: "",
            color: "green",
            items: _ctx.triggerTypes,
            class: "custom-input",
            label: _ctx.t('trigger_type'),
            rules: [_ctx.rules.required]
          }, null, 8, ["modelValue", "items", "label", "rules"])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createElementVNode("div", _hoisted_19, [
      _createVNode(_component_gs_button, {
        class: "form-control",
        icon: "mdi-chevron-left",
        size: "large",
        type: "outlined",
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('back')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('CommonUi.back')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_gs_button, {
        type: "tertiary",
        size: "large",
        "full-width": "",
        disabled: !_ctx.isFormValid || _ctx.loading,
        loading: _ctx.loading,
        onClick: _withModifiers(_ctx.createCustomIntegration, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('next')), 1)
        ]),
        _: 1
      }, 8, ["disabled", "loading", "onClick"])
    ])
  ]))
}