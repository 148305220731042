import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "text-left" }
const _hoisted_3 = { class: "text-left" }
const _hoisted_4 = { class: "text-left hidden" }
const _hoisted_5 = { class: "text-left" }
const _hoisted_6 = { class: "text-body-1 text-no-wrap" }
const _hoisted_7 = {
  key: 0,
  class: "current-user-indicator"
}
const _hoisted_8 = { class: "hidden" }
const _hoisted_9 = { class: "text-body-1 pr-0 text-no-wrap d-flex flex-row-reverse align-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_LoaderBlock = _resolveComponent("LoaderBlock")!
  const _component_QuickMenu = _resolveComponent("QuickMenu")!
  const _component_v_table = _resolveComponent("v-table")!
  const _component_call_to_upgrade_popup = _resolveComponent("call-to-upgrade-popup")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("header", _hoisted_1, [
      _createElementVNode("section", null, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.t('title')), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.t('description')), 1)
      ]),
      _createVNode(_component_gs_button, {
        class: "invite-btn",
        type: "primary",
        size: "large",
        onClick: _ctx.onInviteUserClick
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('invite_user')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_LoaderBlock, {
          key: 0,
          "background-color": "transparent"
        }))
      : (_openBlock(), _createBlock(_component_v_table, {
          key: 1,
          "fixed-header": "",
          height: _ctx.tableHeight,
          class: "table"
        }, {
          default: _withCtx(() => [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", _hoisted_2, _toDisplayString(_ctx.t('name')), 1),
                _createElementVNode("th", _hoisted_3, _toDisplayString(_ctx.t('email')), 1),
                _createElementVNode("th", _hoisted_4, _toDisplayString(_ctx.t('role')), 1),
                _createElementVNode("th", _hoisted_5, _toDisplayString(_ctx.t('status')), 1),
                _cache[1] || (_cache[1] = _createElementVNode("th", null, null, -1))
              ])
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedTeamMembers, (teamMember) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: teamMember.email
                }, [
                  _createElementVNode("td", _hoisted_6, [
                    _createElementVNode("span", null, _toDisplayString(teamMember.fullName), 1),
                    (_ctx.currentUser.id === teamMember.id)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.t('you')), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("td", null, _toDisplayString(teamMember.email), 1),
                  _createElementVNode("td", _hoisted_8, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.t(teamMember.role.toLowerCase())), 1)
                  ]),
                  _createElementVNode("td", null, [
                    _createElementVNode("span", {
                      class: _normalizeClass(['status', teamMember.status.toLowerCase()])
                    }, _toDisplayString(_ctx.t(teamMember.status.toLowerCase())), 3)
                  ]),
                  _createElementVNode("td", _hoisted_9, [
                    (_ctx.isUserInvite(teamMember) && !_ctx.isCurrentUser(teamMember.id))
                      ? (_openBlock(), _createBlock(_component_QuickMenu, {
                          key: 0,
                          class: "pr-2",
                          style: {"height":"25px"},
                          "quick-menu-items": _ctx.getMenuItems(teamMember.status),
                          onQuickMenuClick: (itemName) => _ctx.onMenuItemClick(itemName, teamMember)
                        }, null, 8, ["quick-menu-items", "onQuickMenuClick"]))
                      : _createCommentVNode("", true)
                  ])
                ]))
              }), 128))
            ])
          ]),
          _: 1
        }, 8, ["height"])),
    (_ctx.isCallToUpgradePopupOpen)
      ? (_openBlock(), _createBlock(_component_call_to_upgrade_popup, {
          key: 2,
          "popup-type": "team",
          "is-premium-only": false,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isCallToUpgradePopupOpen = false))
        }))
      : _createCommentVNode("", true)
  ]))
}