import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "calendar" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "subtitle" }
const _hoisted_4 = { class: "calendar-list-container" }
const _hoisted_5 = { class: "calendar-list" }
const _hoisted_6 = { class: "event-date" }
const _hoisted_7 = { class: "right-side" }
const _hoisted_8 = {
  key: 0,
  class: "next-event-marker"
}
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "markers" }
const _hoisted_11 = { class: "useful-resources" }
const _hoisted_12 = { class: "resource-links" }
const _hoisted_13 = {
  href: "https://drive.google.com/drive/folders/1dG7XV1gr8DI198xzRyYDHW8c37C0F5NF",
  target: "_blank"
}
const _hoisted_14 = {
  href: "https://www.getgreenspark.com/case-studies",
  target: "_blank"
}

import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import type { Calendar, CalendarEvent } from '@/helpers/event-calendar'


export default /*@__PURE__*/_defineComponent({
  __name: 'YearlyCalendar',
  props: {
    calendar: {},
    year: {},
    isClosable: { type: Boolean }
  },
  emits: ['close'],
  setup(__props: any) {

const i18n = useI18n()
const t = (key: string) => i18n.t(`YearlyCalendar.${key}`)




const visibleIndex = ref<number>()

const nextEvent = computed<CalendarEvent>(() => {
  const now = new Date()
  const { Q1, Q2, Q3, Q4 } = __props.calendar
  const events = [...Q1, ...Q2, ...Q3, ...Q4]
  const futureEvents = events.filter((e) => new Date(e.startDate).getTime() - now.getTime() > 0)
  const currentYear = now.getFullYear()

  return futureEvents.sort((a, b) => {
    const aDate = new Date(a.startDate)
    const bDate = new Date(b.startDate)
    // This makes the calendar repeat each year, which might not be correct,
    // because holidays might not have a fixed day + month.
    aDate.setFullYear(currentYear)
    bDate.setFullYear(currentYear)
    return aDate.getTime() - bDate.getTime()
  })[0]
})

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_observe_intersection = _resolveDirective("observe-intersection")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, "🎉 " + _toDisplayString(t('title')) + " " + _toDisplayString(_ctx.year), 1),
    _createElementVNode("p", _hoisted_3, _toDisplayString(t('subtitle')), 1),
    (_ctx.isClosable)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "dialog-close-button",
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('close')), ["prevent"]))
        }, [
          _createVNode(_component_v_icon, { variant: "plain" }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode(" mdi-close ")
            ])),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.calendar, (eventsInQuarter, key, index) => {
          return _withDirectives((_openBlock(), _createElementBlock("div", {
            class: "quarter",
            key: key
          }, [
            _createElementVNode("h4", null, _toDisplayString(key), 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(eventsInQuarter, (event) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(['event-item', { 'next-event': event.name === nextEvent.value?.name }]),
                key: event.name
              }, [
                _createElementVNode("span", _hoisted_6, _toDisplayString(event.displayDates), 1),
                _createElementVNode("div", _hoisted_7, [
                  (event.name === nextEvent.value?.name)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(t('next_event')), 1))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", {
                    class: "event-name",
                    innerHTML: event.stylizedName ?? event.name
                  }, null, 8, _hoisted_9)
                ])
              ], 2))
            }), 128))
          ])), [
            [_directive_observe_intersection, (i) => (visibleIndex.value = i), index]
          ])
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_10, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.calendar, (_, key, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: key,
            class: _normalizeClass(['marker-dot', { visible: visibleIndex.value === index }])
          }, null, 2))
        }), 128))
      ])
    ]),
    _cache[4] || (_cache[4] = _createElementVNode("hr", null, null, -1)),
    _createElementVNode("h3", _hoisted_11, _toDisplayString(t('useful_resources')), 1),
    _createElementVNode("p", _hoisted_12, [
      _createElementVNode("a", _hoisted_13, _toDisplayString(t('download_media')), 1),
      _cache[2] || (_cache[2] = _createElementVNode("span", { class: "separator" }, " | ", -1)),
      _createElementVNode("a", _hoisted_14, _toDisplayString(t('boost_engagement')), 1),
      _cache[3] || (_cache[3] = _createElementVNode("span", { class: "separator" }, " | ", -1)),
      _createVNode(_component_router_link, { to: { name: 'Automations' } }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(t('active_automations')), 1)
        ]),
        _: 1
      })
    ])
  ]))
}
}

})