<template>
  <div class="public-top-stat-card" :style="{ backgroundColor }">
    <component :is="currentIcon" class="public-top-stat-card-icon" />
    <div class="text-content">
      <strong class="public-top-stat-card-text amount">
        <span v-html="getTopStatsCartTextAmount(amount)" />
        <BaseTooltip
          class="stat-tooltip"
          v-if="!!relationImpact || !!transferredImpact || !!impactActionImpact"
          :content="tooltipContent"
        />
      </strong>
      <p class="public-top-stat-card-text">
        {{ getTopStatsCartText(type, amount) }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import CarbonImpactIcon from '@/components/icons/ImpactIcons/CarbonImpactIcon.vue'
import MonthsEarthPositiveImpactIcon from '@/components/icons/ImpactIcons/MonthsEarthPositiveImpactIcon.vue'
import PlasticImpactIcon from '@/components/icons/ImpactIcons/PlasticImpactIcon.vue'
import TreesImpactIcon from '@/components/icons/ImpactIcons/TreesImpactIcon.vue'
import WaterImpactIcon from '@/components/icons/ImpactIcons/WaterImpactIcon.vue'
import KelpImpactIcon from '@/components/icons/ImpactIcons/KelpImpactIcon.vue'
import type { IconStyleType, OffsetType } from '@/helpers/interfaces'
import { Utils } from '@/helpers/mixins/utilsMixin'
import BaseTooltip from '@/components/tools/BaseTooltip.vue'
import type { Component, PropType } from 'vue'
import { defineComponent } from 'vue'
import type { TranslateResult } from 'vue-i18n'
import BeesImpactIcon from '@/components/icons/ImpactIcons/BeesImpactIcon.vue'

export default defineComponent({
  name: 'TopStatsCard',
  components: {
    BaseTooltip,
    CarbonImpactIcon,
    MonthsEarthPositiveImpactIcon,
    PlasticImpactIcon,
    TreesImpactIcon,
    WaterImpactIcon,
    KelpImpactIcon,
  },
  mixins: [Utils],
  computed: {
    isOffset(): boolean {
      return this.type !== 'monthsEarthPositive'
    },
    currentIcon(): Component {
      const icons: Record<OffsetType | 'monthsEarthPositive', Component> = {
        monthsEarthPositive: MonthsEarthPositiveImpactIcon,
        trees: TreesImpactIcon,
        carbon: CarbonImpactIcon,
        plastic: PlasticImpactIcon,
        kelp: KelpImpactIcon,
        water: WaterImpactIcon,
        bees: BeesImpactIcon,
      }
      return icons[this.type]
    },
    relationImpactText(): TranslateResult {
      return this.$t(`${this.type}.partner_impact_disclaimer`, this.relationImpact || 0)
    },
    transferredImpactText(): TranslateResult {
      return this.$t(`${this.type}.transferred_impact_disclaimer`, this.transferredImpact || 0)
    },
    impactActionImpactText(): TranslateResult {
      return this.$t(`${this.type}.impact_action_label`, this.impactActionImpact || 0)
    },
    tooltipContent(): string {
      let tooltipText = ''

      if (!!this.relationImpact && this.relationImpact > 0) {
        tooltipText = `${tooltipText} <p><strong>${this.t('partner_impact')}</strong> ${
          this.relationImpactText
        }</p>`
      }

      if (!!this.transferredImpact && this.transferredImpact > 0) {
        tooltipText = `${tooltipText} <p><strong>${this.t('transfarred_impact')}</strong> ${
          this.transferredImpactText
        }</p>`
      }

      if (!!this.impactActionImpact && this.impactActionImpact > 0) {
        tooltipText = `${tooltipText} ${this.impactActionImpactText}`
      }

      return tooltipText
    },
  },
  methods: {
    t(key: string, params?: { [key: string]: string | number }) {
      return this.$t(`TopStatsCard.${key}`, params ?? {})
    },
    tc(key: string, count: number) {
      return this.$t(`TopStatsCard.${key}`, count)
    },
    getTopStatsCartTextAmount(amount: number) {
      return this.isOffset ? this.formatTotalAmountsV2(amount) : this.tc('months_amount', amount)
    },
    getTopStatsCartText(type: OffsetType | 'monthsEarthPositive', amount: number) {
      const formattedType = this.snakeCase(type as string)
      if (type === 'monthsEarthPositive') return this.tc(formattedType, amount)
      return this.$t(`${formattedType}.executed_action`, amount)
    },
  },
  props: {
    type: {
      type: String as PropType<OffsetType | 'monthsEarthPositive'>,
      required: true,
    },
    amount: {
      type: Number,
      required: true,
    },
    backgroundColor: {
      type: String,
    },
    iconStyle: {
      type: String as PropType<IconStyleType>,
    },
    relationImpact: {
      type: Number,
    },
    transferredImpact: {
      type: Number,
    },
    impactActionImpact: {
      type: Number,
    },
  },
})
</script>

<style scoped lang="scss">
@import '~vuetify/settings';

.public-top-stat-card {
  border-radius: 10px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 164px;
  max-width: calc(50% - var(--dashboard--add-more-impact-button-width) / 2);
  height: auto;
  flex: 1;
  flex-grow: 1;
  flex-shrink: 0;
  gap: 16px;

  @media (max-width: 380px) {
    max-width: unset;
    width: 100%;
  }

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    min-width: 240px;
    max-width: 308px;
    height: 144px;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    gap: 18px;
    padding: 24px 20px;
  }

  :deep(.tooltip) {
    margin-top: 3px;
  }
}

.public-top-stat-card-icon {
  height: 40px;
  width: auto;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    height: 84px;
  }
}

.text-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  justify-content: flex-start;
}

.public-top-stat-card-text {
  color: var(--ui-black);
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 0;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    font-size: 16px;
    line-height: 19px;
  }

  &.amount {
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 8px;
    align-items: center;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      font-size: 32px;
      line-height: 38px;
    }
  }

  :deep(.smaller) {
    font-size: 24px;
    line-height: 30px;
  }
}

.stat-tooltip {
  $mobile-size: 16px;
  $desktop-size: 24px;

  width: $mobile-size;
  height: $mobile-size;
  font-size: $mobile-size;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    width: $desktop-size;
    height: $desktop-size;
    font-size: $desktop-size;
  }
}
</style>
