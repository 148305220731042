import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "popup-header" }
const _hoisted_2 = { class: "popup-title" }
const _hoisted_3 = { class: "popup-description" }
const _hoisted_4 = { class: "input-wrapper" }
const _hoisted_5 = { class: "input-wrapper-link" }
const _hoisted_6 = { for: "rule-id" }
const _hoisted_7 = ["href"]
const _hoisted_8 = { for: "rule-name" }
const _hoisted_9 = { class: "totals-wrapper section" }
const _hoisted_10 = { class: "cart-items-text" }
const _hoisted_11 = { class: "total-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gs_input = _resolveComponent("gs-input")!
  const _component_ImpactSettings = _resolveComponent("ImpactSettings")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    width: "1175",
    modelValue: _ctx.isOpen,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.isOpen) = $event)),
    persistent: "",
    fullscreen: _ctx.$vuetify.display.xs
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, { class: "card d-flex flex-column" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_form, {
            modelValue: _ctx.isFormValid,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isFormValid) = $event))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.t('title')), 1),
                _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.t('description')), 1),
                _createElementVNode("button", {
                  class: "close-button",
                  onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["prevent"])),
                  variant: "plain"
                })
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.t('fields.ruleId')), 1),
                    _createElementVNode("a", {
                      href: _ctx.STAMPED_LOYALTY_DOCUMENTATION_URL,
                      target: "_blank",
                      class: "documentation-url"
                    }, _toDisplayString(_ctx.t('where_to_find_rule_id')), 9, _hoisted_7)
                  ]),
                  _createVNode(_component_gs_input, {
                    modelValue: _ctx.localRule.ruleId,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localRule.ruleId) = $event)),
                    id: "rule-id",
                    "hide-details": false,
                    disabled: _ctx.selectedRule?.ruleId,
                    placeholder: _ctx.t('headers.ruleId'),
                    rules: [_ctx.rules.required]
                  }, null, 8, ["modelValue", "disabled", "placeholder", "rules"])
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.t('fields.ruleName')), 1),
                  _createVNode(_component_gs_input, {
                    modelValue: _ctx.localRule.name,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localRule.name) = $event)),
                    id: "rule-name",
                    "hide-details": false,
                    placeholder: _ctx.t('headers.ruleName'),
                    rules: [_ctx.rules.required]
                  }, null, 8, ["modelValue", "placeholder", "rules"])
                ])
              ]),
              _createVNode(_component_ImpactSettings, {
                modelValue: _ctx.impactSettings,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.impactSettings) = $event)),
                "max-values": _ctx.maxValues
              }, null, 8, ["modelValue", "max-values"]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.getImpactSettingCartText(_ctx.impactSettings, _ctx.selectedTrigger)), 1),
                _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.t('total', {
                price: _ctx.userCurrencySymbol + _ctx.getImpactSettingTotal(_ctx.impactSettings),
              })), 1)
              ]),
              _createVNode(_component_gs_button, {
                onClick: _withModifiers(_ctx.handleSave, ["prevent"]),
                loading: _ctx.loading,
                disabled: _ctx.loading || !_ctx.isFormValid,
                "full-width": "",
                type: "tertiary",
                size: "large"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('save')), 1)
                ]),
                _: 1
              }, 8, ["onClick", "loading", "disabled"])
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue", "fullscreen"]))
}