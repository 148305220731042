<template>
  <component :is="componentName" />
</template>

<script lang="ts">
import CarbonImpactIconFlat from '@/components/icons/ImpactIcons/flat/CarbonImpactIconFlat.vue'
import CarbonImpactIconIsometric from '@/components/icons/ImpactIcons/isometric/CarbonImpactIconIsometric.vue'
import CarbonImpactIconSimple from '@/components/icons/ImpactIcons/simple/CarbonImpactIconSimple.vue'
import CarbonImpactIconFilled from '@/components/icons/ImpactIcons/filled/CarbonImpactIconFilled.vue'
import type { IconStyleType } from '@/helpers/interfaces'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CarbonImpactIcon',
  components: {
    CarbonImpactIconSimple,
    CarbonImpactIconIsometric,
    CarbonImpactIconFlat,
    CarbonImpactIconFilled,
  },
  computed: {
    componentName(): string {
      return 'CarbonImpactIcon' + this.iconStyle.slice(0, 1).toUpperCase() + this.iconStyle.slice(1)
    },
  },
  props: {
    iconStyle: {
      type: String as PropType<IconStyleType>,
      required: false,
      default: 'flat',
    },
  },
})
</script>

<style scoped lang="scss"></style>
