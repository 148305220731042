import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createTextVNode as _createTextVNode, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "overlay-text overlay-text--full-width" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "pop-up-content-wrapper" }
const _hoisted_4 = { class: "how-it-works" }
const _hoisted_5 = { class: "number-explanation-wrapper mb-2" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { key: 0 }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "gs-radio-group-content" }
const _hoisted_10 = {
  key: 0,
  class: "gs-radio-group-image"
}
const _hoisted_11 = ["alt", "src"]
const _hoisted_12 = { class: "pop-up-content-wrapper" }
const _hoisted_13 = { class: "how-it-works" }
const _hoisted_14 = { class: "number-explanation-wrapper" }
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = { class: "number-explanation-wrapper" }
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = { class: "d-flex flex-column my-4" }
const _hoisted_19 = {
  class: "key-input-label",
  for: "shopify-script-div-copy"
}
const _hoisted_20 = ["innerHTML"]
const _hoisted_21 = { class: "number-explanation-wrapper" }
const _hoisted_22 = ["innerHTML"]
const _hoisted_23 = { class: "d-flex flex-column my-4" }
const _hoisted_24 = {
  class: "key-input-label",
  for: "shopify-script-copy"
}
const _hoisted_25 = { class: "number-explanation-wrapper" }
const _hoisted_26 = ["innerHTML"]
const _hoisted_27 = { class: "d-flex ga-2 align-start" }
const _hoisted_28 = { class: "switch-wrapper" }
const _hoisted_29 = { class: "d-flex flex-column" }
const _hoisted_30 = ["innerHTML"]
const _hoisted_31 = { class: "d-flex w-100 ga-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_radio = _resolveComponent("v-radio")!
  const _component_v_radio_group = _resolveComponent("v-radio-group")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_StepperContent = _resolveComponent("StepperContent")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_gs_alert_bar = _resolveComponent("gs-alert-bar")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_Stepper = _resolveComponent("Stepper")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("strong", null, _toDisplayString(_ctx.t('shopify_script_tag_tutorial.title')), 1),
      _createElementVNode("p", {
        class: "mt-3 mb-0",
        innerHTML: _ctx.t('shopify_script_tag_tutorial.description')
      }, null, 8, _hoisted_2)
    ]),
    _createVNode(_component_Stepper, {
      modelValue: _ctx.activeStep,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.activeStep) = $event)),
      "max-value": 2,
      "show-logo": false
    }, {
      default: _withCtx(() => [
        _createVNode(_component_StepperContent, {
          "is-visible": _ctx.activeStep === 1,
          transition: "fade"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_5, [
                    _cache[8] || (_cache[8] = _createElementVNode("div", { class: "number" }, [
                      _createElementVNode("span", null, "1")
                    ], -1)),
                    _createElementVNode("div", {
                      class: "explanation",
                      innerHTML: _ctx.t('shopify_script_tag_tutorial.step_1')
                    }, null, 8, _hoisted_6)
                  ]),
                  _createVNode(_component_v_radio_group, {
                    modelValue: _ctx.withPopup,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.withPopup) = $event)),
                    class: "mb-4",
                    "hide-details": ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_radio, {
                        label: _ctx.t('shopify_script_tag_tutorial.activate_popup'),
                        value: true,
                        disabled: _ctx.type === 'static'
                      }, null, 8, ["label", "disabled"]),
                      (_ctx.withPopup)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                            _createVNode(_component_v_radio_group, {
                              modelValue: _ctx.popupTheme,
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.popupTheme) = $event)),
                              class: "nested-radio-group"
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shopifyScriptTagItems, (item, index) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: index,
                                    class: _normalizeClass([{ active: item.popupTheme === _ctx.popupTheme }, "gs-radio-group-item"]),
                                    onClick: ($event: any) => (_ctx.popupTheme = item.popupTheme)
                                  }, [
                                    _createVNode(_component_v_radio, {
                                      label: item.label,
                                      value: item.popupTheme
                                    }, null, 8, ["label", "value"]),
                                    _createElementVNode("div", _hoisted_9, [
                                      (item.imageUrl)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                            _createElementVNode("img", {
                                              alt: item.label,
                                              src: item.imageUrl
                                            }, null, 8, _hoisted_11)
                                          ]))
                                        : _createCommentVNode("", true)
                                    ])
                                  ], 10, _hoisted_8))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["modelValue"])
                          ]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_v_radio, {
                        label: _ctx.t('shopify_script_tag_tutorial.deactivate_popup'),
                        value: false
                      }, null, 8, ["label"])
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ])
              ]),
              _createVNode(_component_gs_button, {
                uppercased: false,
                capitalized: "",
                "full-width": "",
                size: "large",
                target: "_blank",
                type: "tertiary",
                disabled: _ctx.loading,
                loading: _ctx.loading,
                onClick: _ctx.upsertWidgetEditorSettings
              }, {
                default: _withCtx(() => _cache[9] || (_cache[9] = [
                  _createTextVNode(" Next ")
                ])),
                _: 1
              }, 8, ["disabled", "loading", "onClick"])
            ])
          ]),
          _: 1
        }, 8, ["is-visible"]),
        _createVNode(_component_StepperContent, {
          "is-visible": _ctx.activeStep === 2,
          transition: "fade"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _cache[10] || (_cache[10] = _createElementVNode("div", { class: "number" }, [
                    _createElementVNode("span", null, "1")
                  ], -1)),
                  _createElementVNode("div", {
                    class: "explanation",
                    innerHTML: _ctx.t('shopify_script_tag_tutorial.step_2')
                  }, null, 8, _hoisted_15)
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _cache[11] || (_cache[11] = _createElementVNode("div", { class: "number" }, [
                    _createElementVNode("span", null, "2")
                  ], -1)),
                  _createElementVNode("div", {
                    class: "explanation",
                    innerHTML: _ctx.t('shopify_script_tag_tutorial.step_3')
                  }, null, 8, _hoisted_17)
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("label", _hoisted_19, [
                    _createElementVNode("span", {
                      innerHTML: _ctx.t('shopify_script_tag_tutorial.div_tag_label')
                    }, null, 8, _hoisted_20)
                  ]),
                  _createVNode(_component_v_textarea, {
                    id: "shopify-script-div-copy",
                    "model-value": _ctx.shopifyDivTag,
                    rows: 2,
                    class: "shopify-script-key-input",
                    "hide-details": "",
                    "no-resize": "",
                    readonly: "",
                    variant: "outlined"
                  }, {
                    "append-inner": _withCtx(() => [
                      _createVNode(_component_v_tooltip, {
                        "open-on-click": true,
                        "open-on-hover": false,
                        text: _ctx.t('copy_action_success_feedback'),
                        "close-delay": "5000",
                        location: "top",
                        "max-width": "480px"
                      }, {
                        activator: _withCtx(({ props }) => [
                          _createVNode(_component_v_icon, _mergeProps(props, {
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.copyText(_ctx.shopifyDivTag)))
                          }), {
                            default: _withCtx(() => _cache[12] || (_cache[12] = [
                              _createTextVNode(" mdi-content-copy ")
                            ])),
                            _: 2
                          }, 1040)
                        ]),
                        _: 1
                      }, 8, ["text"])
                    ]),
                    _: 1
                  }, 8, ["model-value"])
                ]),
                _createElementVNode("div", _hoisted_21, [
                  _cache[13] || (_cache[13] = _createElementVNode("div", { class: "number" }, [
                    _createElementVNode("span", null, "3")
                  ], -1)),
                  _createElementVNode("div", {
                    class: "explanation",
                    innerHTML: _ctx.t('shopify_script_tag_tutorial.step_4')
                  }, null, 8, _hoisted_22)
                ]),
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("label", _hoisted_24, _toDisplayString(_ctx.t('shopify_script_tag_tutorial.script_tag_label')), 1),
                  _createVNode(_component_v_textarea, {
                    id: "shopify-script-copy",
                    "model-value": _ctx.shopifyScriptTag,
                    rows: 2,
                    class: "shopify-script-key-input",
                    "hide-details": "",
                    "no-resize": "",
                    readonly: "",
                    variant: "outlined"
                  }, {
                    "append-inner": _withCtx(() => [
                      _createVNode(_component_v_tooltip, {
                        "open-on-click": true,
                        "open-on-hover": false,
                        text: _ctx.t('copy_action_success_feedback'),
                        "close-delay": "5000",
                        location: "top",
                        "max-width": "480px"
                      }, {
                        activator: _withCtx(({ props }) => [
                          _createVNode(_component_v_icon, _mergeProps(props, {
                            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.copyText(_ctx.shopifyScriptTag)))
                          }), {
                            default: _withCtx(() => _cache[14] || (_cache[14] = [
                              _createTextVNode(" mdi-content-copy ")
                            ])),
                            _: 2
                          }, 1040)
                        ]),
                        _: 1
                      }, 8, ["text"])
                    ]),
                    _: 1
                  }, 8, ["model-value"])
                ]),
                _createElementVNode("div", _hoisted_25, [
                  _cache[15] || (_cache[15] = _createElementVNode("div", { class: "number" }, [
                    _createElementVNode("span", null, "4")
                  ], -1)),
                  _createElementVNode("div", {
                    class: "explanation",
                    innerHTML: _ctx.t('shopify_script_tag_tutorial.step_5')
                  }, null, 8, _hoisted_26)
                ]),
                _createVNode(_component_gs_alert_bar, {
                  message: _ctx.t('shopify_script_tag_tutorial.only_once'),
                  "full-width": "",
                  type: "warning"
                }, null, 8, ["message"]),
                _createVNode(_component_v_select, {
                  modelValue: _ctx.shopify,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.shopify) = $event)),
                  items: _ctx.displayUrls,
                  class: "dropdown dropdown-block",
                  color: "#3B755F",
                  "hide-details": "",
                  "item-color": "#3B755F",
                  label: "Shop Name",
                  variant: "outlined"
                }, null, 8, ["modelValue", "items"]),
                _createElementVNode("div", _hoisted_27, [
                  _createElementVNode("div", _hoisted_28, [
                    _createVNode(_component_v_switch, {
                      modelValue: _ctx.isActive,
                      "onUpdate:modelValue": [
                        _cache[5] || (_cache[5] = ($event: any) => ((_ctx.isActive) = $event)),
                        _ctx.upsertWidgetEditorSettings
                      ],
                      color: '#3B755F',
                      class: "email-data-sync-toggle__switch",
                      "hide-details": "",
                      inset: ""
                    }, null, 8, ["modelValue", "onUpdate:modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_29, [
                    _createElementVNode("span", {
                      innerHTML: _ctx.t('shopify_script_tag_tutorial.deactivate')
                    }, null, 8, _hoisted_30),
                    _createTextVNode(" " + _toDisplayString(_ctx.t('shopify_script_tag_tutorial.deactivate_description')), 1)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_31, [
                _createVNode(_component_gs_button, {
                  class: "form-control",
                  icon: "mdi-chevron-left",
                  size: "large",
                  type: "outlined",
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.activeStep = 1))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('CommonUi.back')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_gs_button, {
                  disabled: _ctx.loading || !_ctx.shopify,
                  loading: _ctx.loading,
                  uppercased: false,
                  capitalized: "",
                  "full-width": "",
                  size: "large",
                  target: "_blank",
                  type: "primary",
                  onClick: _ctx.handleClickOnGoToShopify
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('go_to_shopify')), 1)
                  ]),
                  _: 1
                }, 8, ["disabled", "loading", "onClick"])
              ])
            ])
          ]),
          _: 1
        }, 8, ["is-visible"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}