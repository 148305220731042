import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "how-it-works-wrapper" }
const _hoisted_2 = { class: "how-it-works-header" }
const _hoisted_3 = { class: "how-it-works-text" }
const _hoisted_4 = {
  key: 0,
  class: "small"
}
const _hoisted_5 = { class: "how-it-works-subheader" }
const _hoisted_6 = { class: "number-explanation-wrapper" }
const _hoisted_7 = { class: "explanation" }
const _hoisted_8 = { class: "number-explanation-wrapper" }
const _hoisted_9 = { class: "explanation" }
const _hoisted_10 = {
  class: "number-explanation-wrapper",
  style: {"margin-bottom":"0"}
}
const _hoisted_11 = { class: "explanation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.t('header')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createTextVNode(_toDisplayString(_ctx.isBusiness ? _ctx.t('text') : _ctx.t('text_personal')) + " ", 1),
      (_ctx.isBusiness)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
            _createTextVNode("( " + _toDisplayString(_ctx.t('integrations')) + " ", 1),
            _createElementVNode("span", {
              class: "link",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('integrationClick')))
            }, _toDisplayString(_ctx.t('click_here')), 1),
            _cache[1] || (_cache[1] = _createTextVNode(" )"))
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.t('how_it_works')), 1),
    _createElementVNode("div", _hoisted_6, [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "number" }, "1", -1)),
      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.t('choose_impact')), 1)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "number" }, "2", -1)),
      _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.account.accountType === 'personal' || _ctx.account.accountType === 'family'
            ? _ctx.t('decide_personal')
            : _ctx.t('decide')), 1)
    ]),
    _createElementVNode("div", _hoisted_10, [
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "number" }, "✓", -1)),
      _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.t('impact_added')), 1)
    ])
  ]))
}