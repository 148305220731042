import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "demo-feed" }
const _hoisted_2 = { class: "demo-feed-title" }
const _hoisted_3 = { class: "desc" }
const _hoisted_4 = { class: "feed" }
const _hoisted_5 = { class: "image-wrapper" }
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewsFeedItem = _resolveComponent("NewsFeedItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.t('title')), 1),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.t('description')), 1),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("ul", {
        class: _normalizeClass(['feed-wrapper', _ctx.type])
      }, [
        _createVNode(_component_NewsFeedItem, { "feed-item": _ctx.dummyFeedItem }, null, 8, ["feed-item"]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedFeedItems, (feedItem, index) => {
          return (_openBlock(), _createBlock(_component_NewsFeedItem, {
            key: index,
            "feed-item": {
            ...feedItem,
            type: _ctx.type,
            companyName: _ctx.account.companyName,
            url: _ctx.sustainabilitySite,
          }
          }, null, 8, ["feed-item"]))
        }), 128)),
        _createVNode(_component_NewsFeedItem, { "feed-item": _ctx.dummyFeedItemMap }, {
          "cms-content": _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("img", {
                class: "map-image",
                width: "600",
                height: "300",
                src: require('@/assets/backgrounds/maps-image.svg'),
                alt: "map"
              }, null, 8, _hoisted_6)
            ])
          ]),
          _: 1
        }, 8, ["feed-item"])
      ], 2)
    ])
  ]))
}