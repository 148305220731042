<!-- TODO: Remove these eslint comments. We need to fix this prop mutation ASAP. -->
<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <ul class="upgrade-card-list">
      <upgrade-card
        v-for="(item, index) in upgradeCardItems"
        :key="index"
        class="upgrade-card-item"
        :class="index === 2 ? 'center-card' : ''"
        :plan-type="item.planType"
        :amount="item.amount"
        :plan-billing="item.planBilling ?? ''"
        :plan-check-items="item.planCheckItems"
        :description="item.description"
        :is-highlighted="item.isHighlighted"
        v-model:path-to-navigate="pathToNavigate"
        :is-subscription-yearly="isSubscriptionYearly"
        :is-current-subscription-yearly="isCurrentSubscriptionYearly"
        :current-plan-type="currentPlanType"
        :is-trial-period="isTrialPeriod"
        :has-payment-details="hasPaymentDetails"
        :theme="item.planType === 'freeBusiness' ? 'unpriced' : 'priced'"
        @openChangePopup="openChangePopup"
        @setLoading="setLoading"
        v-observe-intersection:[index]="setVisibleIndex"
      />
    </ul>
    <div class="markers">
      <div
        v-for="(_, index) in upgradeCardItems"
        :key="index"
        :class="['marker-dot', { visible: index === lastVisibleCardIndex }]"
      />
    </div>
    <change-plan-popup
      v-if="isChangePlanPopupOpen"
      :current-plan-type="currentPlanType"
      :selected-plan-type="selectedPlan"
      :selected-plan-price="selectedPlanPrice"
      :is-current-plan-yearly="isCurrentSubscriptionYearly"
      :is-selected-plan-yearly="isSubscriptionYearly"
      @close="isChangePlanPopupOpen = false"
    />

    <LoadingOpaque v-if="loading" />
  </div>
</template>

<script lang="ts">
import UpgradeCard from '@/components/checkout/UpgradeCard.vue'
import type {
  TechnicalAndBusinessSubscriptionType,
  NewPaidBusinessSubscriptionType,
  PersonalSubscriptionType,
} from '@/helpers/pricing'
import {
  getEarthHeroPrice,
  getGrowthPrice,
  getPersonalPrice,
  getPlasticHeroPricePersonal,
  getPremiumPlanPrice,
  getStarterPrice,
} from '@/helpers/pricing'
import { NEW_PAID_BUSINESS_SUBSCRIPTION_YEARLY_TYPES } from '@/helpers/constants'
import { includes } from '@/helpers/parsers'
import type { Currency } from '@/helpers/interfaces'
import ChangePlanPopup from '@/components/checkout/ChangePlanPopup.vue'
import type { SubscriptionItem } from '@/store/subscriptions'
import type { Subscription } from '@api/index'
import { getSubscriptions } from '@api/index'
import LoadingOpaque from '@/components/tools/LoadingOpaque.vue'
import type { Integration } from '@/store/integrations'
import type { Account } from '@/store'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'UpgradeCardList',
  emits: ['turnOffYearlyToggle'],
  components: {
    ChangePlanPopup,
    UpgradeCard,
    LoadingOpaque,
  },
  data() {
    return {
      currentPlanType: undefined,
      selectedPlan: undefined,
      isCurrentSubscriptionYearly: false,
      isTrialPeriod: false,
      isChangePlanPopupOpen: false,
      hasPaymentDetails: false,
      loading: false,
      lastVisibleCardIndex: 0,
    } as {
      isCurrentSubscriptionYearly: boolean
      isTrialPeriod: boolean
      isChangePlanPopupOpen: boolean
      hasPaymentDetails: boolean
      loading: boolean
      currentPlanType?: TechnicalAndBusinessSubscriptionType
      selectedPlan?: NewPaidBusinessSubscriptionType | PersonalSubscriptionType
      lastVisibleCardIndex: number
    }
  },
  computed: {
    upgradeCardItems(): {
      planType: NewPaidBusinessSubscriptionType | PersonalSubscriptionType
      currency: string | null
      amount: number
      planBilling: string | null
      planCheckItems: string[]
      description?: string
      isHighlighted: boolean
    }[] {
      if (this.isBusiness) {
        return [
          // freeBusiness
          {
            planType: 'freeBusiness',
            currency: null,
            amount: 0,
            planBilling: null,
            planCheckItems: [
              this.t('free.create_impact'),
              this.t('free.users'),
              this.t('free.integration'),
              this.t('free.public_profile'),
              this.t('free.team_offset'),
              this.t('free.analytics'),
            ],
            description: this.t('free.info'),
            isHighlighted: false,
          },
          // starterBusiness
          {
            planType: this.isSubscriptionYearly ? 'starterBusinessYearly' : 'starterBusiness',
            currency: this.userCurrencySymbol,
            amount: getStarterPrice(this.userCurrency, this.isSubscriptionYearly),
            planBilling: this.isSubscriptionYearly
              ? `${this.t('per_year')}`
              : `${this.t('per_month')}`,
            planCheckItems: [
              this.t('starter.everything'),
              this.t('starter.impact_price'),
              this.t('starter.media_pack'),
              this.t('starter.extra_tree'),
            ],
            description: this.t('starter.info'),
            isHighlighted: false,
          },
          // growthBusiness
          {
            planType: this.isSubscriptionYearly ? 'growthBusinessYearly' : 'growthBusiness',
            currency: this.userCurrencySymbol,
            amount: getGrowthPrice(this.userCurrency, this.isSubscriptionYearly),
            planBilling: this.isSubscriptionYearly
              ? `${this.t('per_year')}`
              : `${this.t('per_month')}`,
            planCheckItems: [
              this.t('growth_new.everything'),
              this.t('growth_new.active_integration'),
              this.t('growth_new.users'),
              this.t('growth_new.engagement_tools'),
              this.t('growth_new.public_profile'),
              this.t('growth_new.network'),
              this.t('growth_new.advanced_analytics'),
              this.t('growth_new.qr_code'),
              this.t('growth_new.extra_trees'),
            ],
            description: this.t('growth_new.info'),
            isHighlighted: true,
          },
          // premiumBusiness
          {
            planType: this.isSubscriptionYearly ? 'premiumBusinessYearly' : 'premiumBusiness',
            currency: this.userCurrencySymbol,
            amount: getPremiumPlanPrice(this.userCurrency, this.isSubscriptionYearly),
            planBilling: this.isSubscriptionYearly
              ? `${this.t('per_year')}`
              : `${this.t('per_month')}`,
            planCheckItems: [
              this.t('premium_new.everything'),
              this.t('premium_new.active_integration'),
              this.t('premium_new.users'),
              this.t('premium_new.full_api'),
              this.t('premium_new.post_purchase'),
              this.t('premium_new.priority_technical'),
              this.t('premium_new.enhanced_onboarding'),
              this.t('premium_new.extra_trees'),
            ],
            description: this.t('premium_new.info'),
            isHighlighted: false,
          },
        ]
      } else {
        return [
          // personal plan
          {
            planType: 'personal',
            currency: this.userCurrencySymbol,
            amount: getPersonalPrice(this.userCurrency),
            planBilling: 'per month',
            planCheckItems: [
              this.t('personal.plastic_bottles'),
              this.t('personal.greenspark_footprint'),
              this.t('personal.plant_trees'),
              this.t('personal.track_share'),
            ],
            isHighlighted: false,
          },
          // plastic hero plan
          {
            planType: 'plasticHeroPersonal',
            currency: this.userCurrencySymbol,
            amount: getPlasticHeroPricePersonal(this.userCurrency),
            planBilling: 'per month',
            planCheckItems: [
              this.t('plastic_hero_personal.plastic_bottles'),
              this.t('plastic_hero_personal.greenspark_footprint'),
              this.t('plastic_hero_personal.plant_trees'),
              this.t('plastic_hero_personal.track_share'),
            ],
            isHighlighted: false,
          },
          // earth-hero plan
          {
            planType: 'earthHero',
            currency: this.userCurrencySymbol,
            amount: getEarthHeroPrice(this.userCurrency),
            planBilling: 'per month',
            planCheckItems: [
              this.t('earth_hero.plastic_bottles'),
              this.t('earth_hero.greenspark_footprint'),
              this.t('earth_hero.plant_trees'),
              this.t('earth_hero.track_share'),
            ],
            isHighlighted: false,
          },
        ]
      }
    },
    selectedPlanPrice(): number {
      if (!this.selectedPlan) return 0

      switch (this.selectedPlan) {
        case 'starterBusiness':
          return getStarterPrice(this.userCurrency)
        case 'starterBusinessYearly':
          return getStarterPrice(this.userCurrency, this.isSubscriptionYearly)
        case 'growthBusiness':
          return getGrowthPrice(this.userCurrency)
        case 'growthBusinessYearly':
          return getGrowthPrice(this.userCurrency, this.isSubscriptionYearly)
        case 'premiumBusiness':
          return getPremiumPlanPrice(this.userCurrency)
        case 'premiumBusinessYearly':
          return getPremiumPlanPrice(this.userCurrency, this.isSubscriptionYearly)
        case 'freeBusiness':
          throw new Error('Enterprise plan has custom price')
        default:
          return 0
      }
    },
    userCurrency(): Currency {
      return this.account.currency
    },
    account(): Account {
      return this.$store.getters['getAccount']
    },
    userCurrencySymbol(): string {
      return this.$store.getters['getUserCurrencySymbol']
    },
    getUserStores(): Integration[] {
      return this.$store.getters['getUserStores']
    },
    activePaidSubscriptionItem(): SubscriptionItem {
      return this.$store.getters['getActivePaidSubscriptionItem']
    },
    ownFreeSubscriptionItem(): SubscriptionItem {
      return this.$store.getters['getOwnFreeSubscriptionItem']
    },
    hasPaymentDetailsGetter(): (subscriptions: Subscription[]) => boolean {
      return this.$store.getters['hasPaymentDetails']
    },
  },
  async mounted() {
    if (this.$route.path === '/upgrade-plan') {
      const subscriptions = await getSubscriptions()
      this.hasPaymentDetails = this.hasPaymentDetailsGetter(subscriptions)
      if (this.activePaidSubscriptionItem) {
        this.currentPlanType = this.activePaidSubscriptionItem
          .product as TechnicalAndBusinessSubscriptionType
        this.isCurrentSubscriptionYearly = includes(
          NEW_PAID_BUSINESS_SUBSCRIPTION_YEARLY_TYPES,
          this.activePaidSubscriptionItem.product,
        )
        if (this.isCurrentSubscriptionYearly) this.$emit('turnOffYearlyToggle')
        this.isTrialPeriod =
          !!this.activePaidSubscriptionItem.trialEndDate &&
          new Date(this.activePaidSubscriptionItem.trialEndDate) > new Date()
      }
      if (this.ownFreeSubscriptionItem) {
        this.currentPlanType = this.ownFreeSubscriptionItem
          .product as TechnicalAndBusinessSubscriptionType
      }
    }

    this.scrollToCenterCard()
  },
  methods: {
    setVisibleIndex(payload: number) {
      this.lastVisibleCardIndex = payload
    },
    scrollToCenterCard() {
      if (this.$vuetify.display.smAndDown) {
        const cardList = document.querySelector('.upgrade-card-list') as HTMLElement | null
        const centerCard = document.querySelector('.center-card') as HTMLElement | null

        if (cardList && centerCard) {
          const cardListRect = cardList.getBoundingClientRect()
          const centerCardRect = centerCard.getBoundingClientRect()
          const scrollLeft =
            centerCardRect.left -
            cardListRect.left +
            cardList.scrollLeft -
            cardList.clientWidth / 2 +
            centerCard.clientWidth / 2
          cardList.scrollTo({ left: scrollLeft, behavior: 'smooth' })
        }
      }
    },
    t(key: string) {
      return this.$t(`UpgradeCardList.${key}`)
    },
    setLoading(value: boolean) {
      this.loading = value
    },
    openChangePopup(selectedPlan: NewPaidBusinessSubscriptionType) {
      this.selectedPlan = selectedPlan
      this.isChangePlanPopupOpen = true
    },
  },
  props: {
    isBusiness: {
      type: Boolean,
    },
    isSubscriptionYearly: {
      type: Boolean,
    },
    pathToNavigate: {
      type: String,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.upgrade-card-list {
  display: flex;
  overflow-x: scroll;
  gap: 20px;
  padding: 40px 12px 32px;
  width: 100%;
  list-style-type: none;

  .upgrade-card-item {
    &.center-card {
      scroll-margin-left: 150px;
    }
  }

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    &::-webkit-scrollbar {
      display: none;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    padding: 40px 0 40px;
  }
}

.markers {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 0 0 16px;
  gap: 16px;
}

.marker-dot {
  height: 10px;
  width: 10px;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid var(--gray-light-A9);
  transition: all ease 0.3s;
}

.marker-dot.visible {
  background-color: var(--ui-dark-gray);
  border: none;
  transition: all ease 0.3s;
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .markers {
    display: none;
  }
}
</style>
