import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, mergeProps as _mergeProps, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "sidebar" }
const _hoisted_2 = { class: "logo-wrapper" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "member-since" }
const _hoisted_8 = { class: "central-menu-items" }
const _hoisted_9 = { class: "menu-item-list" }
const _hoisted_10 = { class: "menu-item-text" }
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "menu-item-text" }
const _hoisted_13 = ["src"]
const _hoisted_14 = { class: "menu-item-text" }
const _hoisted_15 = ["src"]
const _hoisted_16 = { class: "menu-item-text" }
const _hoisted_17 = ["src"]
const _hoisted_18 = { class: "menu-item-text" }
const _hoisted_19 = { class: "menu-item-text" }
const _hoisted_20 = ["src"]
const _hoisted_21 = { class: "menu-item-text" }
const _hoisted_22 = ["src"]
const _hoisted_23 = { class: "menu-item-text" }
const _hoisted_24 = ["src"]
const _hoisted_25 = { class: "menu-item-text" }
const _hoisted_26 = ["src"]
const _hoisted_27 = { class: "menu-item-text" }
const _hoisted_28 = ["src"]
const _hoisted_29 = { class: "menu-item-text" }
const _hoisted_30 = { class: "menu-item-text" }
const _hoisted_31 = { class: "drawer-menu" }
const _hoisted_32 = { class: "segment-header" }
const _hoisted_33 = { class: "drawer-menu__list-item" }
const _hoisted_34 = ["href"]
const _hoisted_35 = { class: "drawer-menu__list-item" }
const _hoisted_36 = ["href"]
const _hoisted_37 = { class: "drawer-menu__list-item" }
const _hoisted_38 = { class: "beamer-trigger-button drawer-menu__link" }
const _hoisted_39 = { class: "notification-item-text" }
const _hoisted_40 = { class: "segment-header" }
const _hoisted_41 = { class: "drawer-menu__list-item" }
const _hoisted_42 = { class: "drawer-menu__list-item" }
const _hoisted_43 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!

  return (_openBlock(), _createElementBlock("aside", _hoisted_1, [
    _createVNode(_component_v_navigation_drawer, {
      modelValue: _ctx.sidebarMenu,
      "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.sidebarMenu) = $event)),
      floating: "",
      permanent: _ctx.sidebarMenu,
      rail: _ctx.toggleMini,
      elevation: "1"
    }, {
      prepend: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (!_ctx.toggleMini)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: "logo",
                src: require('@/assets/greenspark-logo.svg'),
                alt: "greenspark-app"
              }, null, 8, _hoisted_3))
            : _createCommentVNode("", true),
          (_ctx.toggleMini)
            ? (_openBlock(), _createElementBlock("img", {
                key: 1,
                class: "logo-small",
                src: require('@/assets/greenspark-logo.svg'),
                alt: "greenspark-app"
              }, null, 8, _hoisted_4))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_v_divider)
      ]),
      append: _withCtx(() => [
        _createVNode(_component_v_divider),
        _createElementVNode("div", {
          onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.setBurgerMenuClick()))
        }, [
          _createVNode(_component_router_link, {
            class: "menu-item",
            to: "/account?tab=profile"
          }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                class: "icon",
                alt: "resources",
                src: require('@/assets/icons/menu/new-account-icon.svg')
              }, null, 8, _hoisted_28),
              _createElementVNode("span", _hoisted_29, _toDisplayString(_ctx.t('account')), 1)
            ]),
            _: 1
          })
        ]),
        _createVNode(_component_v_menu, {
          modelValue: _ctx.isSupportContextMenuOpen,
          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.isSupportContextMenuOpen) = $event)),
          "close-delay": "250",
          "content-class": "elevation-3",
          transition: "slide-y",
          location: "right",
          eager: ""
        }, {
          activator: _withCtx(({ props }) => [
            _createElementVNode("button", _mergeProps({
              class: [
                'menu-item',
                'menu-item-button',
                { 'router-link-active': _ctx.isSupportContextMenuOpen },
              ]
            }, props), [
              _createVNode(_component_v_icon, { class: "icon" }, {
                default: _withCtx(() => _cache[21] || (_cache[21] = [
                  _createTextVNode(" mdi-tooltip-question ")
                ])),
                _: 1
              }),
              _createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.t('support')), 1)
            ], 16)
          ]),
          default: _withCtx(() => [
            _createElementVNode("ul", _hoisted_31, [
              _createElementVNode("p", _hoisted_32, _toDisplayString(_ctx.t('documentation')), 1),
              _createElementVNode("li", _hoisted_33, [
                _createElementVNode("a", {
                  href: _ctx.KNOWLEDGE_BASE_URL,
                  target: "_blank",
                  class: "drawer-menu__link"
                }, [
                  _createVNode(_component_v_icon, { class: "icon material-icons-outlined" }, {
                    default: _withCtx(() => _cache[22] || (_cache[22] = [
                      _createTextVNode(" mdi-book-outline ")
                    ])),
                    _: 1
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.t('knowledge_base')), 1)
                ], 8, _hoisted_34)
              ]),
              _createElementVNode("li", _hoisted_35, [
                _createElementVNode("a", {
                  href: _ctx.API_DOCUMENTATION_URL,
                  target: "_blank",
                  class: "drawer-menu__link"
                }, [
                  _createVNode(_component_v_icon, { class: "icon" }, {
                    default: _withCtx(() => _cache[23] || (_cache[23] = [
                      _createTextVNode(" mdi-link-variant ")
                    ])),
                    _: 1
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.t('api_docs')), 1)
                ], 8, _hoisted_36)
              ]),
              (_ctx.getAccountTypeIsBusiness)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _cache[25] || (_cache[25] = _createElementVNode("hr", { class: "divider" }, null, -1)),
                    _createElementVNode("li", _hoisted_37, [
                      _createElementVNode("a", _hoisted_38, [
                        _createVNode(_component_v_icon, { class: "icon" }, {
                          default: _withCtx(() => _cache[24] || (_cache[24] = [
                            _createTextVNode(" mdi-bell-outline ")
                          ])),
                          _: 1
                        }),
                        _createElementVNode("span", _hoisted_39, _toDisplayString(_ctx.t('whats_new')), 1)
                      ])
                    ])
                  ], 64))
                : _createCommentVNode("", true),
              _cache[28] || (_cache[28] = _createElementVNode("hr", { class: "divider" }, null, -1)),
              _createElementVNode("p", _hoisted_40, _toDisplayString(_ctx.t('contact')), 1),
              _createElementVNode("li", _hoisted_41, [
                _createElementVNode("button", {
                  class: "drawer-menu__link",
                  onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.openChat()))
                }, [
                  _createVNode(_component_v_icon, { class: "icon material-icons-outlined" }, {
                    default: _withCtx(() => _cache[26] || (_cache[26] = [
                      _createTextVNode(" mdi-message-text-outline ")
                    ])),
                    _: 1
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.t('open_chat')), 1)
                ])
              ]),
              _createElementVNode("li", _hoisted_42, [
                _createElementVNode("a", {
                  href: _ctx.SUPPORT_EMAIL_URL,
                  target: "_blank",
                  class: "drawer-menu__link"
                }, [
                  _createVNode(_component_v_icon, { class: "icon material-icons-outlined" }, {
                    default: _withCtx(() => _cache[27] || (_cache[27] = [
                      _createTextVNode(" mdi-email-outline ")
                    ])),
                    _: 1
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.t('email')), 1)
                ], 8, _hoisted_43)
              ])
            ]),
            _createVNode(_component_v_menu)
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      default: _withCtx(() => [
        (_ctx.account.accountType === 'personal' || _ctx.account.accountType === 'family')
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "business-logo",
              onClick: _cache[0] || (_cache[0] = 
          () => {
            _ctx.$router.push('/account?tab=profile')
            _ctx.setBurgerMenuClick()
          }
        )
            }, [
              _createElementVNode("img", {
                style: _normalizeStyle(
            _ctx.account.imageURL === ''
              ? 'border-radius: 50%; width: 100%; height: 100%;'
              : _ctx.png
              ? 'max-width: 100%; object-fit: contain;'
              : ''
          ),
                src: 
            !_ctx.account.imageURL ? require('@/assets/icons/menu/upload-picture.svg') : _ctx.account.imageURL
          ,
                alt: "logo"
              }, null, 12, _hoisted_5)
            ]))
          : _createCommentVNode("", true),
        (_ctx.account.accountType !== 'personal' && _ctx.account.accountType !== 'family')
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "business-logo-business",
              onClick: _cache[1] || (_cache[1] = 
          () => {
            _ctx.$router.push('/account?tab=profile')
            _ctx.setBurgerMenuClick()
          }
        )
            }, [
              _createElementVNode("img", {
                style: _normalizeStyle(
            _ctx.account.imageURL === ''
              ? 'border-radius: 50%; width: 100%; height: 100%;'
              : _ctx.png
              ? 'max-width: 100%; object-fit: contain;'
              : ''
          ),
                src: 
            !_ctx.account.imageURL ? require('@/assets/icons/menu/upload-logo.svg') : _ctx.account.imageURL
          ,
                alt: "logo"
              }, null, 12, _hoisted_6)
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_v_tooltip, {
          "open-on-click": _ctx.isTouch,
          location: "top",
          color: "black",
          disabled: _ctx.disableAccountNameTooltip
        }, {
          activator: _withCtx(({ props }) => [
            _createElementVNode("div", _mergeProps({
              id: "accountName",
              class: "business-name",
              onMouseover: _cache[2] || (_cache[2] = ($event: any) => (_ctx.disableAccountNameTooltip = !_ctx.isAccountNameTruncated())),
              onMouseleave: _cache[3] || (_cache[3] = ($event: any) => (_ctx.disableAccountNameTooltip = !_ctx.isAccountNameTruncated()))
            }, props), _toDisplayString(_ctx.accountName), 17)
          ]),
          default: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(_ctx.accountName), 1)
          ]),
          _: 1
        }, 8, ["open-on-click", "disabled"]),
        _createElementVNode("div", _hoisted_7, "Member Since: " + _toDisplayString(_ctx.memberSince), 1),
        _createVNode(_component_v_divider),
        _createElementVNode("nav", _hoisted_8, [
          _createElementVNode("ul", _hoisted_9, [
            (_ctx.carbonFootprint)
              ? (_openBlock(), _createElementBlock("li", {
                  key: 0,
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setBurgerMenuClick()))
                }, [
                  _createVNode(_component_router_link, {
                    class: "menu-item",
                    to: "/carbon-report"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, { class: "icon component" }, {
                        default: _withCtx(() => _cache[18] || (_cache[18] = [
                          _createTextVNode(" mdi-shoe-print ")
                        ])),
                        _: 1
                      }),
                      _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.t('carbon_footprint')), 1)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("li", {
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.setBurgerMenuClick()))
            }, [
              _createVNode(_component_router_link, {
                class: "menu-item",
                to: "/"
              }, {
                default: _withCtx(() => [
                  (_ctx.hasImpactActions && !_ctx.getAccountTypeIsBusiness)
                    ? (_openBlock(), _createBlock(_component_v_icon, {
                        key: 0,
                        class: "icon component"
                      }, {
                        default: _withCtx(() => _cache[19] || (_cache[19] = [
                          _createTextVNode("mdi-shopping")
                        ])),
                        _: 1
                      }))
                    : (_openBlock(), _createElementBlock("img", {
                        key: 1,
                        class: "icon",
                        alt: "your-impact",
                        src: require('@/assets/icons/menu/greenspark-icon.svg')
                      }, null, 8, _hoisted_11)),
                  _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.hasImpactActions && !_ctx.getAccountTypeIsBusiness
                    ? _ctx.t('impact_wallet')
                    : _ctx.t('your_impact')), 1)
                ]),
                _: 1
              })
            ]),
            (_ctx.hasImpactActions && !_ctx.getAccountTypeIsBusiness)
              ? (_openBlock(), _createElementBlock("li", {
                  key: 1,
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.setBurgerMenuClick()))
                }, [
                  _createVNode(_component_router_link, {
                    class: "menu-item",
                    to: "/your-impact"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("img", {
                        class: "icon",
                        alt: "your-impact",
                        src: require('@/assets/icons/menu/greenspark-icon.svg')
                      }, null, 8, _hoisted_13),
                      _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.t('your_impact')), 1)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("li", {
              onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.setBurgerMenuClick()))
            }, [
              _createVNode(_component_router_link, {
                class: _normalizeClass(["menu-item", {
                'router-link-active':
                  _ctx.$route.name === 'Integrations' ||
                  _ctx.$route.name === 'Automations' ||
                  _ctx.$route.name === 'tailoredImpact',
              }]),
                to: _ctx.getAccountTypeIsBusiness ? '/add-impact' : '/add-impact/tailored-impact'
              }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    class: "icon",
                    alt: "your-impact",
                    src: require('@/assets/icons/menu/add-impact-icon.svg')
                  }, null, 8, _hoisted_15),
                  _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.t('add_impact')), 1)
                ]),
                _: 1
              }, 8, ["class", "to"])
            ]),
            _createElementVNode("li", {
              onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.setBurgerMenuClick()))
            }, [
              _createVNode(_component_router_link, {
                class: "menu-item",
                to: "/shareables"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    class: "icon",
                    alt: "your-impact",
                    src: require('@/assets/icons/menu/shareables-icon.svg')
                  }, null, 8, _hoisted_17),
                  _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.getAccountTypeIsBusiness ? _ctx.t('engagement_hub') : _ctx.t('shareables')), 1)
                ]),
                _: 1
              })
            ]),
            (_ctx.getAccountTypeIsBusiness)
              ? (_openBlock(), _createElementBlock("li", {
                  key: 2,
                  onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.setBurgerMenuClick()))
                }, [
                  _createVNode(_component_router_link, {
                    class: "menu-item",
                    to: "/analytics"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, { class: "icon component" }, {
                        default: _withCtx(() => _cache[20] || (_cache[20] = [
                          _createTextVNode(" mdi-chart-box-outline ")
                        ])),
                        _: 1
                      }),
                      _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.t('analytics')), 1)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            (_ctx.getAccountTypeIsBusiness)
              ? (_openBlock(), _createElementBlock("li", {
                  key: 3,
                  onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.setBurgerMenuClick()))
                }, [
                  _createVNode(_component_router_link, {
                    class: "menu-item",
                    to: "/team"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("img", {
                        class: "icon",
                        alt: "your-impact",
                        src: require('@/assets/icons/menu/your_team.svg')
                      }, null, 8, _hoisted_20),
                      _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.t('team')), 1)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            (_ctx.getAccountTypeIsBusiness)
              ? (_openBlock(), _createElementBlock("li", {
                  key: 4,
                  onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.setBurgerMenuClick()))
                }, [
                  _createVNode(_component_router_link, {
                    class: "menu-item",
                    to: "/network"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("img", {
                        class: "icon",
                        alt: "your-impact",
                        src: require('@/assets/icons/menu/share-icon.svg')
                      }, null, 8, _hoisted_22),
                      _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.t('network')), 1)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("li", {
              onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.setBurgerMenuClick()))
            }, [
              _createVNode(_component_router_link, {
                class: "menu-item",
                to: "/projects"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    class: "icon",
                    alt: "your-impact",
                    src: require('@/assets/icons/menu/supported-projects-icon.svg')
                  }, null, 8, _hoisted_24),
                  _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.t('supported_projects')), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("li", {
              onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.setBurgerMenuClick()))
            }, [
              _createVNode(_component_router_link, {
                class: "menu-item",
                to: "/ledger"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    class: "icon",
                    alt: "your-impact",
                    src: require('@/assets/icons/menu/public.svg')
                  }, null, 8, _hoisted_26),
                  _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.t('public_ledger')), 1)
                ]),
                _: 1
              })
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "permanent", "rail"])
  ]))
}