import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "reference-box-title" }
const _hoisted_2 = { class: "reference-box-companies-list" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "reasons-to-join-list" }
const _hoisted_5 = { class: "reasons-to-join-list-item" }
const _hoisted_6 = { class: "reason-icon" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "text-wrapper" }
const _hoisted_9 = { class: "reason-title" }
const _hoisted_10 = { class: "reason-text" }
const _hoisted_11 = { class: "reasons-to-join-list-item" }
const _hoisted_12 = { class: "reason-icon" }
const _hoisted_13 = ["src"]
const _hoisted_14 = { class: "text-wrapper" }
const _hoisted_15 = { class: "reason-title" }
const _hoisted_16 = { class: "reason-text" }
const _hoisted_17 = { class: "reasons-to-join-list-item" }
const _hoisted_18 = { class: "reason-icon" }
const _hoisted_19 = ["src"]
const _hoisted_20 = { class: "text-wrapper" }
const _hoisted_21 = { class: "reason-title" }
const _hoisted_22 = { class: "reason-text" }
const _hoisted_23 = { class: "reasons-to-join-list-item" }
const _hoisted_24 = { class: "reason-icon" }
const _hoisted_25 = ["src"]
const _hoisted_26 = { class: "text-wrapper" }
const _hoisted_27 = { class: "reason-title" }
const _hoisted_28 = { class: "reason-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['reference-box', _ctx.theme])
  }, [
    _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.t('title')), 1),
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.clientLogos, (image, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: index,
          class: "reference-box-company-list-item"
        }, [
          _createElementVNode("img", {
            class: "company-logo",
            src: image,
            alt: ""
          }, null, 8, _hoisted_3)
        ]))
      }), 128))
    ]),
    _createElementVNode("ul", _hoisted_4, [
      _createElementVNode("li", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("img", {
            src: require('@/assets/icons/checkout/chartIncrease.svg'),
            alt: ""
          }, null, 8, _hoisted_7)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.t('increase_title')), 1),
          _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.t('increase_text')), 1)
        ])
      ]),
      _createElementVNode("li", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("img", {
            src: require('@/assets/icons/checkout/chartRise.svg'),
            alt: ""
          }, null, 8, _hoisted_13)
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.t('rise_title')), 1),
          _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.t('rise_text')), 1)
        ])
      ]),
      _createElementVNode("li", _hoisted_17, [
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("img", {
            src: require('@/assets/icons/checkout/chartMore.svg'),
            alt: ""
          }, null, 8, _hoisted_19)
        ]),
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("p", _hoisted_21, _toDisplayString(_ctx.t('more_title')), 1),
          _createElementVNode("p", _hoisted_22, _toDisplayString(_ctx.t('more_text')), 1)
        ])
      ]),
      _createElementVNode("li", _hoisted_23, [
        _createElementVNode("div", _hoisted_24, [
          _createElementVNode("img", {
            src: require('@/assets/icons/checkout/chartUplift.svg'),
            alt: ""
          }, null, 8, _hoisted_25)
        ]),
        _createElementVNode("div", _hoisted_26, [
          _createElementVNode("p", _hoisted_27, _toDisplayString(_ctx.t('uplift_title')), 1),
          _createElementVNode("p", _hoisted_28, _toDisplayString(_ctx.t('uplift_text')), 1)
        ])
      ])
    ])
  ], 2))
}