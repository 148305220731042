import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "impact-wrapper" }
const _hoisted_3 = { class: "select" }
const _hoisted_4 = { class: "text-field-wrapper" }
const _hoisted_5 = { class: "plus-minus" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SmallButton = _resolveComponent("SmallButton")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_text_field = _resolveComponent("v-text-field")!

  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_SmallButton, {
            label: _ctx.t('add_impact'),
            "on-click": _ctx.addImpact,
            type: 'green',
            disabled: _ctx.impactArray.length > 2
          }, null, 8, ["label", "on-click", "disabled"]),
          _createVNode(_component_SmallButton, {
            label: _ctx.t('remove_impact'),
            "on-click": _ctx.removeImpacts,
            type: 'red',
            disabled: _ctx.impactArray.length < 2
          }, null, 8, ["label", "on-click", "disabled"])
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.impactArray, (item, index) => {
          return (_openBlock(), _createBlock(_Transition, {
            key: index,
            name: "fade"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_v_select, {
                  modelValue: item.type,
                  "onUpdate:modelValue": ($event: any) => ((item.type) = $event),
                  items: _ctx.items,
                  label: _ctx.t('impact'),
                  variant: "outlined",
                  color: "#3B755F",
                  "item-color": "#3B755F",
                  class: "dropdown",
                  rules: [_ctx.rules.required]
                }, null, 8, ["modelValue", "onUpdate:modelValue", "items", "label", "rules"]),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_v_text_field, {
                    modelValue: item.amount,
                    "onUpdate:modelValue": ($event: any) => ((item.amount) = $event),
                    modelModifiers: { number: true },
                    label: _ctx.t('amount'),
                    variant: "outlined",
                    clearable: "",
                    type: "number",
                    flat: "",
                    min: "0",
                    color: "#3B755F",
                    class: "employee-input",
                    rules: [_ctx.rules.required]
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "rules"]),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", {
                      class: "add",
                      style: {"margin-bottom":"1px"},
                      onClick: ($event: any) => (item.amount++)
                    }, "+", 8, _hoisted_6),
                    _createElementVNode("div", {
                      class: "add",
                      style: {"margin-top":"1px"},
                      onClick: ($event: any) => (item.amount--)
                    }, "-", 8, _hoisted_7)
                  ])
                ])
              ])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}