<template>
  <div v-if="loaded" class="dialog-wrapper">
    <div>
      <div class="close-wrapper">
        <div class="dialog-title">
          {{ t('stop') }}
        </div>

        <img
          style="width: 25px"
          class="close-icon"
          :src="require('@/assets/icons/close_icon.svg')"
          alt="close"
          @click="$emit('dialogClick')"
        />
      </div>

      <div class="dialog-text">
        {{ t('text') }}
      </div>

      <div class="button-wrapper">
        <gs-button
          type="danger"
          size="large"
          full-width
          :loading="loading"
          :disabled="loading"
          @click="stop()"
        >
          {{ t('stop') }}
        </gs-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { stopPartnership } from '@api/index'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'StopPartnership',
  emits: ['dialogClick', 'getFreshDataClick', 'showSuccessAlert', 'showErrorAlert'],
  components: {},
  data() {
    return {
      loaded: true,
      loading: false,
    }
  },
  methods: {
    t(key: string) {
      return this.$t(`StopPartnership.${key}`)
    },
    async stop() {
      try {
        this.loading = true
        await stopPartnership(this.relationsId ?? '')
        this.$emit('getFreshDataClick')
        this.$emit('dialogClick')
        this.$emit('showSuccessAlert')
      } catch (error) {
        console.error('error:', error)
        this.loading = false
        this.$emit('dialogClick')
        this.$emit('showErrorAlert')
      }
    },
  },
  props: {
    relationsId: {
      type: String,
    },
  },
})
</script>

<style lang="scss" scoped>
.dialog-wrapper {
  background: #f9f9f9;
  padding: 25px;
  padding-bottom: 34px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  height: 100%;
}

.close-wrapper {
  margin-top: -10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.close-icon {
  cursor: pointer;
}

.dialog-title {
  color: #212121;
  font-weight: 600;
  font-size: 20px;
  text-align: left;
  width: 100%;
}

.dialog-text {
  color: #212121;
  font-weight: normall;
  font-size: 16px;
  text-align: left;
  width: 100%;
}

.button-wrapper {
  width: 100%;
  margin-top: 34px;
}
</style>
