<template>
  <div>
    <div v-if="loaded" class="outer-network-wrapper">
      <div class="header">
        {{ t('header') }}
      </div>
      <div class="text-button-wrapper">
        <div class="text">
          {{ t('text') }}
        </div>
        <div
          v-if="
            !hideButton &&
            ((activeMenuItem === 'referral' && !referralEmpty) ||
              (activeMenuItem === 'partner' && !partnerEmpty))
          "
          class="button-wrapper"
        >
          <gs-button type="primary" size="large" full-width @click="dialogClick()">
            {{ t('invite') }}
          </gs-button>
        </div>
      </div>

      <CustomMenu :menu-items="menuItems" @menuClick="menuClick" />

      <transition-group name="fade" tag="div" style="width: 100%">
        <!-- Referrals -->
        <ReferralGrid
          v-if="activeMenuItem === 'referral'"
          key="0"
          :window-width="windowWidth"
          :get-fresh-data="getFreshData"
          @dialogClick="dialog = false"
          @showErrorAlert="showErrorAlert()"
          @showSuccessAlert="showSuccessAlert()"
          @getFreshDataClick="getFreshDataClick()"
          @emptyClick="emptyClick"
          @emptyButtonClick="emptyDialogClick('referral')"
          @hideButtonClick="hideButtonClick"
          @setCardDialogClick="setCardDialogClick()"
        />

        <!-- Partners -->
        <PartnerGrid
          v-if="activeMenuItem === 'partner'"
          key="1"
          :get-fresh-data="getFreshData"
          @dialogClick="dialog = false"
          @showErrorAlert="showErrorAlert()"
          @showSuccessAlert="showSuccessAlert()"
          @getFreshDataClick="getFreshDataClick()"
          @emptyClick="emptyClick"
          @emptyButtonClick="emptyDialogClick('partner')"
          @hideButtonClick="hideButtonClick"
          @setCardDialogClick="setCardDialogClick()"
        />
      </transition-group>
    </div>

    <div
      v-if="activeMenuItem === 'referral' && !referralEmpty"
      class="outer-network-wrapper"
      style="margin-top: 24px; min-height: unset"
    >
      <ReferralImpact :get-fresh-data="getFreshData" @getFreshDataClick="getFreshDataClick()" />
    </div>

    <div
      v-if="activeMenuItem === 'partner' && !partnerEmpty"
      class="outer-network-wrapper"
      style="margin-top: 24px; min-height: unset"
    >
      <PartnerImpact :get-fresh-data="getFreshData" @getFreshDataClick="getFreshDataClick()" />
    </div>

    <v-dialog v-model="dialog" width="600" persistent :fullscreen="isMobile" scrollable>
      <!-- Invite new referral or partner -->
      <InvitePopUp
        :progress-item="dialogProgressItem"
        @dialogClick="dialogClick()"
        @getFreshDataClick="getFreshDataClick()"
        @dialogProgressItemClick="dialogProgressItemClick"
        @showErrorAlert="showErrorAlert()"
        @showSuccessAlert="showSuccessAlert()"
      />
    </v-dialog>
    <v-dialog v-model="isComparisonPopupOpen" fullscreen scroll-strategy="none">
      <comparison-dialog @close="isComparisonPopupOpen = false" />
    </v-dialog>

    <call-to-upgrade-popup
      v-if="isCallToUpgradePopupOpen"
      @close="isCallToUpgradePopupOpen = false"
      :is-premium-only="false"
    />

    <AddCardPopUp
      :dialog="isAddCardPopupOpen"
      :type="'partner'"
      :from-page="'partner'"
      :has-subscription="hasSubscription"
      @closeDialogClick="isAddCardPopupOpen = false"
    />

    <Alert
      :alert="showAlert"
      :error="isErrorAlert"
      :show-buttons="isErrorAlert"
      :text="alertText"
      @closeAlertClick="showAlert = false"
    />
  </div>
</template>

<script lang="ts">
import CustomMenu from '@/components/Network/CustomMenu.vue'
import ReferralGrid from '@/components/Network/ReferralGrid.vue'
import PartnerGrid from '@/components/Network/PartnerGrid.vue'
import ReferralImpact from '@/components/referrals/ReferralImpact.vue'
import PartnerImpact from '@/components/Network/PartnerImpact.vue'
import InvitePopUp from '@/components/Network/InvitePopUp.vue'
import AddCardPopUp from '@/components/checkout/AddCardPopUp.vue'
import Alert from '@/components/tools/Alert.vue'
import type { Subscription } from '@api/index'
import { getSubscriptions } from '@api/index'
import { eventBus } from '@/main'
import ComparisonDialog from '@/components/Network/ComparisonDialog.vue'
import CallToUpgradePopup from '@/components/common/CallToUpgradePopup.vue'
import type { Account } from '@/store'
import { Utils } from '@/helpers/mixins/utilsMixin'
import type { TranslateResult } from 'vue-i18n'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'NetworkView',
  mixins: [Utils],
  components: {
    CallToUpgradePopup,
    ComparisonDialog,
    CustomMenu,
    ReferralGrid,
    PartnerGrid,
    ReferralImpact,
    PartnerImpact,
    InvitePopUp,
    AddCardPopUp,
    Alert,
  },
  data() {
    return {
      isComparisonPopupOpen: false,
      loaded: false,
      dialog: false,
      isCallToUpgradePopupOpen: false,
      isAddCardPopupOpen: false,
      dialogProgressItem: 0,
      alertText: '',
      menuItems: [],
      activeMenuItem: '',
      showAlert: false,
      isErrorAlert: false,
      getFreshData: false,
      referralEmpty: false,
      partnerEmpty: true,
      hideButton: true,
      windowWidth: 0,
      hasSubscription: false,
    } as {
      isComparisonPopupOpen: boolean
      loaded: boolean
      dialog: boolean
      isCallToUpgradePopupOpen: boolean
      isAddCardPopupOpen: boolean
      dialogProgressItem: number
      alertText: TranslateResult
      menuItems: { label: TranslateResult; value: string; active: boolean }[]
      activeMenuItem: string
      showAlert: boolean
      isErrorAlert: boolean
      getFreshData: boolean
      referralEmpty: boolean
      partnerEmpty: boolean
      hideButton: boolean
      windowWidth: number
      hasSubscription: boolean
    }
  },
  computed: {
    account(): Account {
      return this.$store.getters['getAccount']
    },
    hasPaidSubscription(): boolean {
      return this.$store.getters['hasPaidSubscriptionItem']
    },
    isPartnerRewardsAllowed(): boolean {
      return this.$store.getters['getPartnerRewardsFeatureSetting']
    },
    hasPaymentDetails(): (subscriptions: Subscription[]) => boolean {
      return this.$store.getters['hasPaymentDetails']
    },
  },
  async created() {
    window.addEventListener('resize', this.reportWindowSize)
    this.reportWindowSize()
    this.setMenuItems()
    await this.setAccount()
    this.activeMenuItem = 'referral'
    const subscriptions = await getSubscriptions()
    this.hasSubscription = this.hasPaymentDetails(subscriptions)
    this.loaded = true
    eventBus.on('openComparisonPopup', this.openComparisonPopup)
  },
  unmounted() {
    window.removeEventListener('resize', this.reportWindowSize)
  },
  methods: {
    t(key: string) {
      return this.$t(`NetworkView.${key}`)
    },
    beforeDestroy() {
      eventBus.off('openComparisonPopup', this.openComparisonPopup)
    },
    reportWindowSize() {
      this.windowWidth = window.innerWidth
    },
    hideButtonClick(value) {
      this.hideButton = value
    },
    emptyClick(value) {
      switch (value) {
        case 'referral':
          this.referralEmpty = true
          break
        case 'partner':
          this.partnerEmpty = true
          break
        default:
          this.partnerEmpty = false
          break
      }
    },
    setMenuItems() {
      this.menuItems = [
        { label: this.t('refer'), value: 'referral', active: true },
        { label: this.t('partners'), value: 'partner', active: false },
      ]
    },
    menuClick(value, index) {
      for (let i = 0; i < this.menuItems.length; i++) {
        if (this.menuItems[i].active) this.menuItems[i].active = false
      }

      this.menuItems[index].active = !this.menuItems[index].active
      this.activeMenuItem = value
    },
    dialogClick() {
      if (this.dialog) {
        this.dialog = false
      } else {
        this.dialogProgressItem = 0
        this.dialog = true
      }
    },
    emptyDialogClick(value) {
      if (this.dialog) {
        this.dialog = false
      } else {
        if (value === 'referral') this.dialogProgressItem = 1
        if (value === 'partner') this.dialogProgressItem = 2
        this.dialog = true
      }
    },
    getFreshDataClick() {
      if (this.getFreshData) {
        this.getFreshData = false
      } else {
        this.getFreshData = true
      }
    },
    dialogProgressItemClick(number) {
      if (number === 2 && !this.hasPaidSubscription) {
        this.dialog = false
        this.isCallToUpgradePopupOpen = true
      } else if (number === 2 && !this.hasSubscription) {
        this.dialog = false
        this.isAddCardPopupOpen = true
      } else {
        this.dialogProgressItem = number
      }
    },
    showErrorAlert() {
      this.showAlert = true
      this.isErrorAlert = true
      this.alertText = this.t('error')
    },
    showSuccessAlert() {
      this.showAlert = true
      this.isErrorAlert = false
      this.alertText = this.t('saved')
      setTimeout(() => {
        this.showAlert = false
      }, 2500)
    },
    setCardDialogClick() {
      if (!this.hasSubscription) this.isCallToUpgradePopupOpen = true
    },
    openComparisonPopup() {
      this.isComparisonPopupOpen = true
    },
    onWindowWidthChange() {
      this.reportWindowSize()
    },
    setAccount(): Promise<void> {
      return this.$store.dispatch('setAccount')
    },
  },
  watch: {
    windowWidth: [
      {
        handler: 'onWindowWidthChange',
      },
    ],
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.outer-network-wrapper {
  background: #f9f9f9;
  border-radius: 5px;
  padding: 30px;
  min-height: 640px;
  box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
}

.header {
  color: #212121;
  font-weight: bold;
  font-size: 35px;
  margin-bottom: 0px;
  width: 100%;
}

.text-button-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.text {
  margin-bottom: 30px;
  max-width: 574px;
  margin-right: 12px;
}

.button-wrapper {
  width: 190px;
}

@media #{map-get($display-breakpoints, 'xs')} {
  .text-button-wrapper {
    flex-direction: column;
    //justify-content: space-between;
  }

  .button-wrapper {
    width: 100%;
    margin-bottom: 34px;
  }
}
</style>
