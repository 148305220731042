import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "qr-code-main-wrapper" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "content-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HowItWorksUtility = _resolveComponent("HowItWorksUtility")!
  const _component_FunkyBackground = _resolveComponent("FunkyBackground")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "back",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1)))
    }, [
      _createElementVNode("img", {
        class: "chevron",
        alt: "chevron",
        src: require('@/assets/icons/chevron_left.svg')
      }, null, 8, _hoisted_2),
      _createElementVNode("div", null, _toDisplayString(_ctx.t('back')), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_HowItWorksUtility, {
        header: `${_ctx.t('how_it_works')}`,
        subheader: 
          _ctx.account.accountType === 'business' || _ctx.account.accountType === 'ecommerce'
            ? `${_ctx.t('how_it_works1')}`
            : `${_ctx.t('how_it_works1_personal')}`
        ,
        text: `${_ctx.t('customise')}`,
        text1: `${_ctx.t('download')}`,
        text2: 
          _ctx.account.accountType === 'business' || _ctx.account.accountType === 'ecommerce'
            ? `${_ctx.t('done')}`
            : `${_ctx.t('done_personal')}`
        
      }, null, 8, ["header", "subheader", "text", "text1", "text2"]),
      _createVNode(_component_FunkyBackground)
    ])
  ]))
}