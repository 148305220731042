import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "setting-card-alert" }
const _hoisted_2 = { class: "setting-card-wrapper" }
const _hoisted_3 = { class: "setting-card-text-wrapper" }
const _hoisted_4 = { class: "setting-card-title-wrapper" }
const _hoisted_5 = { class: "checkmark" }
const _hoisted_6 = { class: "card-title" }
const _hoisted_7 = {
  key: 0,
  class: "setting-card-pill setting-card-pill--green"
}
const _hoisted_8 = {
  key: 1,
  class: "setting-card-pill setting-card-pill--red"
}
const _hoisted_9 = {
  key: 2,
  class: "setting-card-pill"
}
const _hoisted_10 = { class: "card-description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_gs_button = _resolveComponent("gs-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['setting-card', { 'is-active': _ctx.isActive, invalid: _ctx.hasError }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "alert", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_v_icon, {
              class: "checkmark-icon",
              icon: _ctx.hasError ? 'mdi-exclamation' : 'mdi-check'
            }, null, 8, ["icon"])
          ]),
          _createElementVNode("p", _hoisted_6, [
            _renderSlot(_ctx.$slots, "title", {}, undefined, true)
          ]),
          (_ctx.activeAmount)
            ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.tc('active', _ctx.activeAmount)), 1))
            : _createCommentVNode("", true),
          (_ctx.hasError)
            ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.tc('not_valid')), 1))
            : _createCommentVNode("", true),
          (_ctx.isRequired)
            ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.tc('required')), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("p", _hoisted_10, [
          _renderSlot(_ctx.$slots, "desc", {}, undefined, true)
        ])
      ]),
      _renderSlot(_ctx.$slots, "button", {}, () => [
        _createVNode(_component_gs_button, {
          type: _ctx.isActive ? 'secondary' : 'primary',
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('button-click')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
          ]),
          _: 1
        }, 8, ["type"])
      ], true)
    ])
  ], 2))
}