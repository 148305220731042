import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card-texts-wrapper" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { class: "dialog-card" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_v_card_title, {
          class: _normalizeClass([
          `${_ctx.dialog?.options.titleColor}--text`,
          'font-weight-bold',
          'text-h5 d-flex py-6 pt-4 pb-3 align-center',
        ])
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.dialog?.texts.title) + " ", 1),
            _createVNode(_component_v_spacer),
            _createVNode(_component_v_btn, {
              icon: "",
              onClick: _ctx.onClose,
              variant: "plain"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, { color: "black" }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createTextVNode(" mdi-close ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_v_card_text, {
          class: _normalizeClass([`${_ctx.dialog?.options.descriptionColor}--text`, 'text-body-1'])
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", {
              class: "description",
              innerHTML: _ctx.dialog?.texts.description
            }, null, 8, _hoisted_2)
          ]),
          _: 1
        }, 8, ["class"])
      ]),
      _createVNode(_component_v_card_actions, null, {
        default: _withCtx(() => [
          _createVNode(_component_gs_button, {
            class: "primary-button",
            type: "primary",
            size: "large",
            loading: _ctx.isLoading,
            onClick: _ctx.onApprove
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.dialog?.texts.primaryButtonText), 1)
            ]),
            _: 1
          }, 8, ["loading", "onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}