import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "total-wrapper" }
const _hoisted_2 = { class: "stat" }
const _hoisted_3 = { class: "text-number-wrapper" }
const _hoisted_4 = { class: "text" }
const _hoisted_5 = { class: "number-members" }
const _hoisted_6 = { class: "text-number-wrapper" }
const _hoisted_7 = { class: "number" }
const _hoisted_8 = { class: "text" }
const _hoisted_9 = { class: "stat stat-total" }
const _hoisted_10 = { class: "text-number-wrapper" }
const _hoisted_11 = { class: "text-total" }
const _hoisted_12 = {
  key: 0,
  class: "number-total"
}
const _hoisted_13 = {
  key: 1,
  class: "number-total"
}
const _hoisted_14 = {
  key: 2,
  class: "number-total"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.tc('member', _ctx.totalMembers)), 1),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.formatNumberByLocale(_ctx.totalMembers)), 1)
      ])
    ]),
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "sign" }, "x", -1)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.SUBSCRIPTION_OFFSET_TYPES(), (type, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: _normalizeClass(['stat', type])
      }, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.formatNumberByLocale(_ctx.getPriceByOffsetType(type))), 1),
          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.tc(type, _ctx.getPriceByOffsetType(type))), 1)
        ])
      ], 2))
    }), 128)),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "sign" }, "=", -1)),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.t('total')), 1),
        (_ctx.account.currency === 'euro')
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.currencyEuro(_ctx.totalCost)) + " " + _toDisplayString(_ctx.getUserCurrencyCode), 1))
          : _createCommentVNode("", true),
        (_ctx.account.currency === 'pound')
          ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.currencyPound(_ctx.totalCost)) + " " + _toDisplayString(_ctx.getUserCurrencyCode), 1))
          : _createCommentVNode("", true),
        (_ctx.account.currency === 'dollar')
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(_ctx.currencyDollar(_ctx.totalCost)) + " " + _toDisplayString(_ctx.getUserCurrencyCode), 1))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}