import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "greeting-title" }
const _hoisted_2 = { class: "greeting-text" }
const _hoisted_3 = { class: "menu-wrapper" }
const _hoisted_4 = {
  key: 0,
  class: "content-wrapper"
}
const _hoisted_5 = { class: "container-8" }
const _hoisted_6 = { class: "container-4" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "container-12" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "container-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!
  const _component_CustomMenu = _resolveComponent("CustomMenu")!
  const _component_NewsFeed = _resolveComponent("NewsFeed")!
  const _component_LatestImpactActionsList = _resolveComponent("LatestImpactActionsList")!
  const _component_ImpactActions = _resolveComponent("ImpactActions")!
  const _component_ImpactsByBrands = _resolveComponent("ImpactsByBrands")!
  const _component_ImpactActionDetailDialog = _resolveComponent("ImpactActionDetailDialog")!
  const _component_BrandDetailDialog = _resolveComponent("BrandDetailDialog")!
  const _component_FullWidthPopup = _resolveComponent("FullWidthPopup")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_loading, { key: 0 }))
      : _createCommentVNode("", true),
    _cache[0] || (_cache[0] = _createElementVNode("h1", { style: {"display":"none"} }, "Impact Wallet", -1)),
    _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.t('greeting_title', { username: _ctx.currentUser.firstName })), 1),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.t('greeting_text')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_CustomMenu, {
        "menu-items": _ctx.menuItems,
        onMenuClick: _ctx.menuClick
      }, null, 8, ["menu-items", "onMenuClick"])
    ]),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.activeMenuItem === 'updates')
          ? (_openBlock(), _createElementBlock("section", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_NewsFeed, { onOpenOrderDetails: _ctx.openImpactActionDetailPopupById }, null, 8, ["onOpenOrderDetails"])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_LatestImpactActionsList, {
                  class: "latest-order-list",
                  onOpenPopup: _ctx.openImpactActionDetailPopup,
                  onImpactActions: _ctx.menuClick
                }, null, 8, ["onOpenPopup", "onImpactActions"])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.activeMenuItem === 'impact_actions')
          ? (_openBlock(), _createElementBlock("section", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_ImpactActions, { onOpenPopup: _ctx.openImpactActionDetailPopup }, null, 8, ["onOpenPopup"])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.activeMenuItem === 'brands')
          ? (_openBlock(), _createElementBlock("section", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_ImpactsByBrands, {
                  "impacts-by-brands": _ctx.impactsByBrands,
                  onOpenPopup: _ctx.openBrandDetailsPopup
                }, null, 8, ["impacts-by-brands", "onOpenPopup"])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_FullWidthPopup, {
      ref: "popup",
      "popup-title": _ctx.popupTitle
    }, {
      default: _withCtx(() => [
        (_ctx.isImpactActionDetailActive)
          ? (_openBlock(), _createBlock(_component_ImpactActionDetailDialog, {
              key: 0,
              "impact-action": _ctx.selectedImpactAction,
              onOpenBrandDetail: _ctx.openBrandDetails
            }, null, 8, ["impact-action", "onOpenBrandDetail"]))
          : _createCommentVNode("", true),
        (!_ctx.isImpactActionDetailActive)
          ? (_openBlock(), _createBlock(_component_BrandDetailDialog, {
              key: 1,
              brand: _ctx.selectedBrand,
              onOpenOrderDetails: _ctx.openImpactActionDetail
            }, null, 8, ["brand", "onOpenOrderDetails"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["popup-title"])
  ]))
}