import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "pages" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 0,
  class: "table-footer-item-dots"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 1,
  class: "table-footer-item-dots"
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(
      _ctx.footerItems && _ctx.footerItems.length > 1 ? 'table-footer-outer' : 'table-footer-outer center'
    )
      }, [
        (_ctx.footerItems && _ctx.footerItems.length > 1)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "arrow",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.arrowClick('back')))
            }, [
              _createElementVNode("img", {
                class: "arrow-icon",
                src: require('@/assets/icons/partners/arrow-left.svg'),
                alt: "left"
              }, null, 8, _hoisted_1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.firstFooterItems, (item, a) => {
            return (_openBlock(), _createElementBlock("div", {
              key: a,
              class: _normalizeClass(item.active ? 'active table-footer-item' : 'table-footer-item'),
              onClick: ($event: any) => {;(_ctx.activePage && _ctx.activePage + 1) === item.value ? '' : _ctx.footerClick(item.value)}
            }, [
              _createElementVNode("div", null, _toDisplayString(item.label), 1)
            ], 10, _hoisted_3))
          }), 128)),
          (_ctx.firstDotsActive)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[2] || (_cache[2] = [
                _createElementVNode("div", null, "...", -1)
              ])))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.secondFooterItems, (item, a) => {
            return (_openBlock(), _createElementBlock("div", {
              key: a,
              class: _normalizeClass(item.active ? 'active table-footer-item' : 'table-footer-item'),
              onClick: ($event: any) => {;(_ctx.activePage && _ctx.activePage + 1) === item.value ? '' : _ctx.footerClick(item.value)}
            }, [
              _createElementVNode("div", null, _toDisplayString(item.label), 1)
            ], 10, _hoisted_5))
          }), 128)),
          (_ctx.secondDotsActive)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[3] || (_cache[3] = [
                _createElementVNode("div", null, "...", -1)
              ])))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.thirdFooterItems, (item, a) => {
            return (_openBlock(), _createElementBlock("div", {
              key: a,
              class: _normalizeClass(item.active ? 'active table-footer-item' : 'table-footer-item'),
              onClick: ($event: any) => {;(_ctx.activePage && _ctx.activePage + 1) === item.value ? '' : _ctx.footerClick(item.value)}
            }, [
              _createElementVNode("div", null, _toDisplayString(item.label), 1)
            ], 10, _hoisted_7))
          }), 128))
        ]),
        (_ctx.footerItems && _ctx.footerItems.length > 1)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "arrow",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.arrowClick('next')))
            }, [
              _createElementVNode("img", {
                class: "arrow-icon",
                src: require('@/assets/icons/partners/arrow-right.svg'),
                alt: "right"
              }, null, 8, _hoisted_8)
            ]))
          : _createCommentVNode("", true)
      ], 2))
    : _createCommentVNode("", true)
}