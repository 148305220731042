import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "panel" }
const _hoisted_2 = { class: "description" }
const _hoisted_3 = {
  id: "cert",
  ref: "cert",
  class: "certificate"
}
const _hoisted_4 = { class: "logo-wrapper" }
const _hoisted_5 = {
  key: 0,
  class: "x-mark"
}
const _hoisted_6 = {
  key: 1,
  class: "side float-right"
}
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "text-wrapper" }
const _hoisted_9 = { class: "impact-text" }
const _hoisted_10 = { class: "impact-date" }
const _hoisted_11 = { class: "certificate-description" }
const _hoisted_12 = ["src"]
const _hoisted_13 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.t('description')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "side float-left" }, [
          _createElementVNode("svg", { class: "greenspark-logo" }, [
            _createElementVNode("use", { href: "#greenspark-logo" })
          ])
        ], -1)),
        (_ctx.userLogoUrl)
          ? (_openBlock(), _createElementBlock("p", _hoisted_5, "X"))
          : _createCommentVNode("", true),
        (_ctx.userLogoUrl)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("img", {
                class: "user-logo",
                src: _ctx.userLogoUrl,
                height: "80"
              }, null, 8, _hoisted_7)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("h2", _hoisted_8, [
        _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.t('impact_text')), 1)
      ]),
      _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.date), 1),
      _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.t('cert_description')), 1),
      _createElementVNode("img", {
        src: require('@/assets/signiture.svg'),
        class: "signature",
        height: "67",
        width: "176"
      }, null, 8, _hoisted_12),
      _createElementVNode("img", {
        class: "footer-logo",
        src: require('@/assets/greenspark-logo-beige.svg'),
        height: "30",
        width: "120"
      }, null, 8, _hoisted_13)
    ], 512)
  ]))
}