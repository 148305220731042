import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "table-wrapper" }
const _hoisted_5 = { class: "d-md-inline-flex flex-md-row justify-md-space-between align-md-start d-flex flex-column mb-2 table-text-wrapper" }
const _hoisted_6 = { class: "mr-16" }
const _hoisted_7 = { class: "text-h5 font-weight-bold mb-2" }
const _hoisted_8 = { class: "text-body-1 mb-4" }
const _hoisted_9 = { class: "text-left" }
const _hoisted_10 = { class: "text-left" }
const _hoisted_11 = { class: "text-left" }
const _hoisted_12 = { class: "text-left" }
const _hoisted_13 = { class: "text-body-1 text-no-wrap" }
const _hoisted_14 = { class: "text-capitalize text-body-1 text-no-wrap" }
const _hoisted_15 = { class: "text-body-1 text-no-wrap" }
const _hoisted_16 = ["src"]
const _hoisted_17 = ["src"]
const _hoisted_18 = { class: "text-body-1 text-no-wrap" }
const _hoisted_19 = { class: "text-body-1 pr-0 text-no-wrap d-flex flex-row-reverse align-center" }
const _hoisted_20 = { class: "table-wrapper" }
const _hoisted_21 = { class: "d-md-inline-flex flex-md-row justify-md-space-between align-md-start d-flex flex-column table-text-wrapper" }
const _hoisted_22 = { class: "mr-16" }
const _hoisted_23 = { class: "text-h5 font-weight-bold mb-2" }
const _hoisted_24 = { class: "text-body-1 mb-0" }
const _hoisted_25 = ["href"]
const _hoisted_26 = { class: "text-left" }
const _hoisted_27 = { class: "text-left" }
const _hoisted_28 = { class: "text-left" }
const _hoisted_29 = { class: "text-body-1 text-no-wrap" }
const _hoisted_30 = { class: "text-body-1 text-no-wrap" }
const _hoisted_31 = ["src"]
const _hoisted_32 = ["src"]
const _hoisted_33 = { class: "text-body-1 text-no-wrap" }
const _hoisted_34 = { class: "text-body-1 pl-5 pr-0 text-no-wrap d-flex flex-row-reverse align-center" }
const _hoisted_35 = { class: "font-weight-bold" }
const _hoisted_36 = {
  id: "checkboxLabel",
  class: "text-black"
}
const _hoisted_37 = { class: "d-flex align-center" }
const _hoisted_38 = {
  key: 0,
  class: "font-weight-bold text-body-1 ma-0"
}
const _hoisted_39 = {
  key: 1,
  class: "font-weight-bold text-body-1 ma-0"
}
const _hoisted_40 = ["src"]
const _hoisted_41 = { class: "font-weight-bold" }
const _hoisted_42 = {
  id: "checkboxLabel",
  class: "text-black"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_QuickMenu = _resolveComponent("QuickMenu")!
  const _component_v_table = _resolveComponent("v-table")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_gs_input = _resolveComponent("gs-input")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_LoadingOpaque = _resolveComponent("LoadingOpaque")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: "back",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clickOnBack()))
    }, [
      _createVNode(_component_v_icon, { class: "chevron" }, {
        default: _withCtx(() => _cache[20] || (_cache[20] = [
          _createTextVNode(" mdi-chevron-left ")
        ])),
        _: 1
      }),
      _createElementVNode("div", null, _toDisplayString(_ctx.$t(`CommonUi.back`)), 1)
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.customIntegration.source), 1),
      _createVNode(_component_i18n_t, {
        keypath: `ManageCustomIntegrationView.description_${_ctx.customIntegrationType}`,
        tag: "div",
        class: "mt-1 mb-4"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.customIntegration.source) + " ", 1),
          _createElementVNode("a", {
            href: _ctx.API_DOCUMENTATION_URL,
            target: "_blank",
            style: {"text-decoration":"none"},
            class: "font-weight-bold"
          }, _toDisplayString(_ctx.t('link')), 9, _hoisted_3)
        ]),
        _: 1
      }, 8, ["keypath"]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.t('triggers')), 1),
            _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.t(`triggers_description_${_ctx.customIntegrationType}`)), 1)
          ]),
          _createVNode(_component_gs_button, {
            type: "primary",
            size: "large",
            width: "227",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isCreateTriggerDialogOpen = true))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('create_trigger')), 1)
            ]),
            _: 1
          })
        ]),
        _createVNode(_component_v_table, {
          class: "table",
          "fixed-header": ""
        }, {
          default: _withCtx(() => [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", _hoisted_9, _toDisplayString(_ctx.t('name')), 1),
                _createElementVNode("th", _hoisted_10, _toDisplayString(_ctx.t('type')), 1),
                _createElementVNode("th", _hoisted_11, _toDisplayString(_ctx.t('trigger_id')), 1),
                _createElementVNode("th", _hoisted_12, _toDisplayString(_ctx.t('created')), 1),
                _cache[21] || (_cache[21] = _createElementVNode("th", null, null, -1))
              ])
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customIntegration.triggers, (item, index) => {
                return (_openBlock(), _createElementBlock("tr", { key: index }, [
                  _createElementVNode("td", _hoisted_13, _toDisplayString(item.name), 1),
                  _createElementVNode("td", _hoisted_14, _toDisplayString(item.type), 1),
                  _createElementVNode("td", _hoisted_15, [
                    _createTextVNode(_toDisplayString(item._id) + " ", 1),
                    _createVNode(_component_v_btn, {
                      icon: "",
                      onClick: ($event: any) => (_ctx.copyToClipboard(item)),
                      variant: "plain"
                    }, {
                      default: _withCtx(() => [
                        (!item.isCopied)
                          ? (_openBlock(), _createElementBlock("img", {
                              key: 0,
                              src: require('@/assets/icons/file_copy.svg'),
                              alt: "copy"
                            }, null, 8, _hoisted_16))
                          : _createCommentVNode("", true),
                        (item.isCopied)
                          ? (_openBlock(), _createElementBlock("img", {
                              key: 1,
                              src: require('@/assets/icons/check_circle_2.svg'),
                              alt: "copy"
                            }, null, 8, _hoisted_17))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _createElementVNode("td", _hoisted_18, _toDisplayString(_ctx.dateFormatFilter(item.createdAt, _ctx.userLocale)), 1),
                  _createElementVNode("td", _hoisted_19, [
                    _createVNode(_component_QuickMenu, {
                      class: "pr-2",
                      style: {"height":"25px"},
                      "quick-menu-items": _ctx.menuItems,
                      onQuickMenuClick: ($event: any) => (_ctx.quickMenuClickTrigger($event, item))
                    }, null, 8, ["quick-menu-items", "onQuickMenuClick"])
                  ])
                ]))
              }), 128))
            ])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_20, [
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("p", _hoisted_23, _toDisplayString(_ctx.t('source')), 1),
            _createElementVNode("p", _hoisted_24, " \\|||||||||||||||||||||||||||||\\\\ " + _toDisplayString(_ctx.t(`source_description_${_ctx.customIntegrationType}`)), 1),
            (_ctx.customIntegrationType === 'custom')
              ? (_openBlock(), _createBlock(_component_i18n_t, {
                  key: 0,
                  keypath: "ManageCustomIntegrationView.source_description_help",
                  tag: "div",
                  class: "text-body-1"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("a", {
                      href: _ctx.API_DOCUMENTATION_URL,
                      target: "_blank",
                      style: {"text-decoration":"none"},
                      class: "font-weight-bold"
                    }, _toDisplayString(_ctx.t('source_description_help_link')), 9, _hoisted_25)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ])
        ]),
        _createVNode(_component_v_table, {
          class: "table",
          "fixed-header": ""
        }, {
          default: _withCtx(() => [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", _hoisted_26, _toDisplayString(_ctx.t('name')), 1),
                _createElementVNode("th", _hoisted_27, _toDisplayString(_ctx.t('source_id')), 1),
                _createElementVNode("th", _hoisted_28, _toDisplayString(_ctx.t('created')), 1),
                _cache[22] || (_cache[22] = _createElementVNode("th", null, null, -1))
              ])
            ]),
            _createElementVNode("tbody", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("td", _hoisted_29, _toDisplayString(_ctx.customIntegration.source), 1),
                _createElementVNode("td", _hoisted_30, [
                  _createTextVNode(_toDisplayString(_ctx.customIntegration._id) + " ", 1),
                  _createVNode(_component_v_btn, {
                    icon: "",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.copyToClipboard(_ctx.customIntegration))),
                    variant: "plain"
                  }, {
                    default: _withCtx(() => [
                      (!_ctx.customIntegration.isCopied)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            src: require('@/assets/icons/file_copy.svg'),
                            alt: "copy"
                          }, null, 8, _hoisted_31))
                        : _createCommentVNode("", true),
                      (_ctx.customIntegration.isCopied)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 1,
                            src: require('@/assets/icons/check_circle_2.svg'),
                            alt: "copy"
                          }, null, 8, _hoisted_32))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("td", _hoisted_33, _toDisplayString(_ctx.dateFormatFilter(_ctx.customIntegration.createdAt, _ctx.userLocale)), 1),
                _createElementVNode("td", _hoisted_34, [
                  _createVNode(_component_QuickMenu, {
                    class: "pr-2",
                    style: {"height":"25px"},
                    "quick-menu-items": _ctx.sourceMenuItems,
                    onQuickMenuClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.quickMenuClickSource($event)))
                  }, null, 8, ["quick-menu-items"])
                ])
              ])
            ])
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.isEditTriggerDialogOpen,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.isEditTriggerDialogOpen) = $event)),
          persistent: "",
          width: "572"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, {
              class: "d-flex flex-column",
              height: "370"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, { class: "text-black font-weight-bold text-h5 pb-4 d-flex py-6 pt-4 align-center" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('edit_trigger_title')) + " ", 1),
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      icon: "",
                      onClick: _ctx.resetState,
                      variant: "plain"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, { color: "black" }, {
                          default: _withCtx(() => _cache[23] || (_cache[23] = [
                            _createTextVNode(" mdi-close ")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_form, {
                      modelValue: _ctx.isTriggerEditFormValid,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.isTriggerEditFormValid) = $event))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          modelValue: _ctx.editTriggerName,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editTriggerName) = $event)),
                          class: "mb-10",
                          variant: "outlined",
                          flat: "",
                          type: "text",
                          label: _ctx.t('trigger_name'),
                          rules: [_ctx.localRules.maxLength, _ctx.rules.required],
                          maxlength: "40",
                          counter: ""
                        }, null, 8, ["modelValue", "label", "rules"]),
                        _createVNode(_component_v_select, {
                          modelValue: _ctx.editTriggerType,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editTriggerType) = $event)),
                          items: _ctx.triggerTypes,
                          label: _ctx.t('trigger_type'),
                          variant: "outlined",
                          flat: "",
                          rules: [_ctx.rules.required],
                          "hide-details": ""
                        }, null, 8, ["modelValue", "items", "label", "rules"])
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, { class: "d-flex mt-auto mb-0" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_gs_button, {
                      class: "create button",
                      type: "primary",
                      size: "large",
                      loading: _ctx.isEditTriggerLoading,
                      disabled: 
                _ctx.isEditTriggerLoading ||
                !_ctx.isTriggerEditFormValid ||
                (_ctx.selectedTrigger.name === _ctx.editTriggerName &&
                  _ctx.selectedTrigger.type.charAt(0).toUpperCase() + _ctx.selectedTrigger.type.slice(1) ===
                    _ctx.editTriggerType)
              ,
                      onClick: _ctx.editTrigger
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('CommonUi.save')), 1)
                      ]),
                      _: 1
                    }, 8, ["loading", "disabled", "onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.isCreateTriggerDialogOpen,
          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.isCreateTriggerDialogOpen) = $event)),
          persistent: "",
          width: "572"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, {
              class: "d-flex flex-column",
              height: "370"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, { class: "text-black font-weight-bold text-h5 d-flex py-6 pt-4 pb-3 align-center" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('create_trigger_title')) + " ", 1),
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      icon: "",
                      onClick: _ctx.resetState,
                      variant: "plain"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, { color: "black" }, {
                          default: _withCtx(() => _cache[24] || (_cache[24] = [
                            _createTextVNode(" mdi-close ")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_form, {
                      ref: "createTriggerForm",
                      modelValue: _ctx.isCreateTriggerFormValid,
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.isCreateTriggerFormValid) = $event))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_gs_input, {
                          modelValue: _ctx.createTriggerName,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.createTriggerName) = $event)),
                          class: "mb-10",
                          label: _ctx.t('trigger_name'),
                          rules: [_ctx.localRules.maxLength, _ctx.rules.required],
                          maxlength: "40",
                          counter: "",
                          "hide-details": false
                        }, null, 8, ["modelValue", "label", "rules"]),
                        _createVNode(_component_v_select, {
                          modelValue: _ctx.createTriggerType,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.createTriggerType) = $event)),
                          items: _ctx.triggerTypes,
                          label: _ctx.t('trigger_type'),
                          variant: "outlined",
                          flat: "",
                          rules: [_ctx.rules.required],
                          "hide-details": ""
                        }, null, 8, ["modelValue", "items", "label", "rules"])
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, { class: "d-flex mt-auto mb-0" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_gs_button, {
                      class: "create button",
                      type: "primary",
                      size: "large",
                      loading: _ctx.isCreateTriggerLoading,
                      disabled: _ctx.isCreateTriggerLoading || !_ctx.isCreateTriggerFormValid,
                      onClick: _ctx.createTrigger
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('create_trigger_title')), 1)
                      ]),
                      _: 1
                    }, 8, ["loading", "disabled", "onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.isDeleteTriggerDialogOpen,
          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.isDeleteTriggerDialogOpen) = $event)),
          persistent: "",
          width: "572"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, {
              class: "d-flex flex-column",
              height: "370"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, { class: "text-red font-weight-bold text-h5 pb-4 d-flex py-6 pt-4 align-center" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('delete_trigger_title')) + " ", 1),
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      icon: "",
                      onClick: _ctx.resetState,
                      variant: "plain"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, { color: "black" }, {
                          default: _withCtx(() => _cache[25] || (_cache[25] = [
                            _createTextVNode(" mdi-close ")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, { class: "text-black text-body-1 mb-16" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_i18n_t, {
                      keypath: "ManageCustomIntegrationView.delete_trigger_text",
                      tag: "div"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_35, _toDisplayString(_ctx.selectedTrigger.name), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, { class: "pb-0" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_checkbox, {
                      modelValue: _ctx.isDeleteTriggerDialogChecked,
                      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.isDeleteTriggerDialogChecked) = $event))
                    }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_36, _toDisplayString(_ctx.t('delete_trigger_checkbox')), 1)
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, { class: "d-flex mt-auto mb-0 pt-0" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_gs_button, {
                      class: "delete button",
                      type: "danger",
                      size: "large",
                      loading: _ctx.isDeleteTriggerLoading,
                      disabled: _ctx.isDeleteTriggerLoading || !_ctx.isDeleteTriggerDialogChecked,
                      onClick: _ctx.deleteTrigger
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('delete_trigger_title')), 1)
                      ]),
                      _: 1
                    }, 8, ["loading", "disabled", "onClick"]),
                    _createVNode(_component_gs_button, {
                      class: "cancel button",
                      type: "outlined",
                      size: "large",
                      onClick: _ctx.resetState
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('CommonUi.cancel')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.isEditSourceDialogOpen,
          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.isEditSourceDialogOpen) = $event)),
          persistent: "",
          width: "572"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, {
              class: "d-flex flex-column",
              height: "370"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, { class: "text-black font-weight-bold text-h5 pb-4 d-flex py-6 pt-4 align-center" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('edit_source_title')) + " ", 1),
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      icon: "",
                      onClick: _ctx.resetState,
                      variant: "plain"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, { color: "black" }, {
                          default: _withCtx(() => _cache[26] || (_cache[26] = [
                            _createTextVNode(" mdi-close ")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_form, {
                      ref: "editSourceForm",
                      modelValue: _ctx.isSourceEditFormValid,
                      "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.isSourceEditFormValid) = $event))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          modelValue: _ctx.editSourceName,
                          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.editSourceName) = $event)),
                          class: "mb-5",
                          variant: "outlined",
                          flat: "",
                          label: _ctx.t('source_name'),
                          maxlength: "40",
                          counter: "",
                          rules: [_ctx.localRules.maxLength, _ctx.rules.required]
                        }, null, 8, ["modelValue", "label", "rules"])
                      ]),
                      _: 1
                    }, 8, ["modelValue"]),
                    _createElementVNode("div", _hoisted_37, [
                      (!_ctx.customIntegration.imageUrl)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_38, _toDisplayString(_ctx.t('upload_icon')), 1))
                        : _createCommentVNode("", true),
                      (_ctx.customIntegration.imageUrl)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_39, _toDisplayString(_ctx.t('edit_icon')), 1))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", {
                        class: "business-logo ml-3",
                        onClick: _cache[16] || (_cache[16] = 
//@ts-ignore
(...args) => (_ctx.openUploadModal && _ctx.openUploadModal(...args)))
                      }, [
                        _createElementVNode("img", {
                          style: 'max-width: 100%; object-fit: contain;',
                          src: 
                    _ctx.editSourceUrl
                      ? _ctx.editSourceUrl
                      : require('@/assets/icons/custom-integration-upload.svg')
                  ,
                          alt: "logo"
                        }, null, 8, _hoisted_40)
                      ])
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, { class: "d-flex mt-auto mb-0" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_gs_button, {
                      class: "create button",
                      type: "primary",
                      size: "large",
                      loading: _ctx.isEditSourceLoading,
                      disabled: 
                _ctx.isEditSourceLoading ||
                !_ctx.isSourceEditFormValid ||
                (_ctx.editSourceName === _ctx.customIntegration.source &&
                  _ctx.editSourceUrl === _ctx.customIntegration.imageUrl)
              ,
                      onClick: _ctx.editSource
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('CommonUi.save')), 1)
                      ]),
                      _: 1
                    }, 8, ["loading", "disabled", "onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.isDeleteSourceDialogOpen,
          "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.isDeleteSourceDialogOpen) = $event)),
          persistent: "",
          width: "572"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, {
              class: "d-flex flex-column",
              height: "370"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, { class: "text-red font-weight-bold text-h5 d-flex py-6 pt-4 pb-3 align-center" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('delete_source_title')) + " ", 1),
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      icon: "",
                      onClick: _ctx.resetState,
                      variant: "plain"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, { color: "black" }, {
                          default: _withCtx(() => _cache[27] || (_cache[27] = [
                            _createTextVNode(" mdi-close ")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, { class: "text-black text-body-1 mb-16" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_i18n_t, {
                      keypath: "ManageCustomIntegrationView.delete_source_text",
                      tag: "div"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_41, _toDisplayString(_ctx.customIntegration.source), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, { class: "pb-0" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_checkbox, {
                      modelValue: _ctx.isDeleteSourceDialogChecked,
                      "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.isDeleteSourceDialogChecked) = $event)),
                      class: "delete checkbox"
                    }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_42, _toDisplayString(_ctx.t('delete_source_checkbox')), 1)
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, { class: "d-flex mt-auto mb-0 pt-0" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_gs_button, {
                      class: "delete button",
                      type: "danger",
                      size: "large",
                      loading: _ctx.isDeleteSourceLoading,
                      disabled: _ctx.isDeleteSourceLoading || !_ctx.isDeleteSourceDialogChecked,
                      onClick: _ctx.deleteSource
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('delete_source_title')), 1)
                      ]),
                      _: 1
                    }, 8, ["loading", "disabled", "onClick"]),
                    _createVNode(_component_gs_button, {
                      class: "cancel button",
                      type: "outlined",
                      size: "large",
                      onClick: _ctx.resetState
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('CommonUi.cancel')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_LoadingOpaque, { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}