import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pop-up" }
const _hoisted_2 = { class: "overlay-header" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "expect" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "list" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.t('header')), 1),
    _createElementVNode("div", {
      class: "tooltip-text",
      innerHTML: _ctx.t('sub_header')
    }, null, 8, _hoisted_3),
    _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.t('expect')), 1),
    _createElementVNode("div", {
      innerHTML: _ctx.t('list_header')
    }, null, 8, _hoisted_5),
    _createElementVNode("ul", _hoisted_6, [
      _createElementVNode("li", {
        innerHTML: _ctx.t('list_1')
      }, null, 8, _hoisted_7),
      _createElementVNode("li", {
        innerHTML: _ctx.t('list_2')
      }, null, 8, _hoisted_8),
      _createElementVNode("li", {
        innerHTML: _ctx.t('list_3')
      }, null, 8, _hoisted_9)
    ]),
    _createElementVNode("div", {
      innerHTML: _ctx.t('cta_description'),
      class: "cta_description"
    }, null, 8, _hoisted_10),
    _createVNode(_component_v_card_actions, { class: "button-wrapper" }, {
      default: _withCtx(() => [
        _createVNode(_component_gs_button, {
          class: "primary-button",
          type: "primary",
          size: "large",
          onClick: _ctx.logOut
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('log_out')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    })
  ]))
}