import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  id: "news-feed-list",
  class: "news-feed-list"
}
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_news_feed_item = _resolveComponent("news-feed-item")!
  const _component_LoaderBlock = _resolveComponent("LoaderBlock")!
  const _component_v_panel = _resolveComponent("v-panel")!
  const _directive_observe_visibility = _resolveDirective("observe-visibility")!

  return (_openBlock(), _createBlock(_component_v_panel, { class: "news-feed" }, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.t('updates')), 1)
    ]),
    default: _withCtx(() => [
      _createElementVNode("ul", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.feedItems, (feedItem, index) => {
          return (_openBlock(), _createBlock(_component_news_feed_item, {
            key: index,
            "feed-item": feedItem
          }, null, 8, ["feed-item"]))
        }), 128))
      ]),
      (_ctx.feedItems.length && _ctx.isScrollingOn)
        ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, null, 512)), [
            [_directive_observe_visibility, { callback: _ctx.handleScrollBottom, throttle: 300 }]
          ])
        : _createCommentVNode("", true),
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_LoaderBlock, {
            key: 1,
            "background-color": "transparent"
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}