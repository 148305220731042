<template>
  <div class="dialog">
    <panel padding="small" background="secondary" class="templates-panel">
      <p class="description">
        {{ t('template_description') }}
      </p>
      <div class="templates">
        <div class="header">
          {{ t('header_title') }}
          <button :disabled="isAddNewOpen" class="add-button" @click.prevent="openAddNewPanel">
            New +
          </button>
        </div>

        <div class="template-list">
          <div
            v-for="(notificationTemplate, index) in localEmailTemplates"
            :key="index"
            class="template-list-item"
          >
            <div class="list-item-header">
              <div class="title-wrapper">
                <v-icon
                  :class="[
                    'checkmark-icon',
                    { active: notificationTemplate.isActive && !!notificationTemplate.id },
                    { new: !notificationTemplate.id },
                  ]"
                >
                  {{ notificationTemplate.isActive ? 'mdi-play-circle' : 'mdi-stop-circle' }}
                </v-icon>
                <p class="template-name mb-0">
                  {{
                    !!notificationTemplate.id ? notificationTemplate.type.name : t('new_template')
                  }}
                </p>
                <p
                  v-if="notificationTemplate.type.template && !!notificationTemplate.id"
                  class="type-pill mb-0"
                >
                  {{ t(notificationTemplate.type.template) }}
                </p>
                <p v-if="notificationTemplate.isCustom" class="custom-pill mb-0">Custom</p>
              </div>
              <v-menu>
                <template v-slot:activator="{ props }">
                  <button class="open-drawer-button" v-bind="props">
                    <v-icon>mdi-dots-horizontal</v-icon>
                  </button>
                </template>
                <div class="drawer">
                  <button class="drawer-button" @click="openEditPanel(index)">
                    <v-icon class="drawer-icon"> mdi-pencil-outline </v-icon
                    >{{ $t('CommonUi.edit') }}
                  </button>
                  <button
                    v-if="!notificationTemplate.isActive"
                    :disabled="
                      !isDomainValid(notificationTemplate.settings.from.domain) ||
                      !notificationTemplate.settings.from.name
                    "
                    class="drawer-button"
                    @click="activateState(index)"
                  >
                    <v-icon class="drawer-icon"> mdi-play-outline </v-icon>
                    Activate
                  </button>
                  <button v-else class="drawer-button" @click="deactivateState(index)">
                    <v-icon class="drawer-icon"> mdi-stop-circle-outline </v-icon>
                    Deactivate
                  </button>
                  <button
                    class="drawer-button"
                    @click.prevent="
                      deleteTemplate(notificationTemplate.id, notificationTemplate.type.name)
                    "
                  >
                    <v-icon class="drawer-icon"> mdi-delete-outline </v-icon
                    >{{ $t('CommonUi.delete') }}
                  </button>
                </div>
              </v-menu>
            </div>

            <div :class="['input-wrapper', { 'is-open': openedIndex === index }]">
              <div class="activation-wrapper">
                <div class="label-wrapper">
                  <p class="label">
                    {{ t('activate') }}
                  </p>
                  <p class="hint">
                    {{ t('activate_hint') }}
                  </p>
                </div>
                <v-switch
                  v-model="notificationTemplate.isActive"
                  :disabled="
                    !isDomainValid(notificationTemplate.settings.from.domain) &&
                    !notificationTemplate.isActive
                  "
                  inset
                  class="activate-switch"
                  hide-details
                  color="green"
                  @change="
                    !notificationTemplate.isActive ? deactivateState(index) : activateState(index)
                  "
                />
              </div>
              <gs-alert-bar
                v-if="
                  selectableDomains.length > 0 &&
                  !notificationTemplate.isActive &&
                  isDomainValid(notificationTemplate.settings.from.domain)
                "
                :message="t('activate_warning')"
                full-width
                type="info"
              />
              <gs-alert-bar
                v-if="
                  notificationTemplate.settings.from.domain &&
                  !isDomainValid(notificationTemplate.settings.from.domain) &&
                  !notificationTemplate.isActive
                "
                :message="t('activate_error')"
                full-width
                type="warning"
              />
              <v-expansion-panels
                :model-value="panelsOpen"
                multiple
                class="accordion-wrapper"
                flat
                @click="validateForms"
              >
                <!--Type-->
                <v-expansion-panel :class="['accordion', { 'has-error': !isTypeFormValid }]">
                  <v-expansion-panel-title class="accordion-header">
                    <p class="accordion-header__title">
                      {{ t('type') }}
                    </p>
                  </v-expansion-panel-title>
                  <v-expansion-panel-text class="accordion-content">
                    <v-form ref="form" v-model="isTypeFormValid">
                      <p class="label">
                        {{ t('template_name') }}
                      </p>
                      <p class="hint">
                        {{ t('template_name_hint') }}
                      </p>
                      <v-text-field
                        id="template-name"
                        v-model="notificationTemplate.type.name"
                        color="green"
                        variant="outlined"
                        flat
                        :rules="[rules.maxlength(inputMaxLength), rules.required]"
                        counter
                        :maxlength="inputMaxLength"
                        class="form-input"
                        @focus="isNameFilled = true"
                      />
                      <p class="label">
                        {{ t('template_type') }}
                      </p>
                      <div class="template-type-wrapper">
                        <div
                          v-for="item in templateTypes"
                          :key="item"
                          :class="[
                            'template-type',
                            { selected: item === notificationTemplate.type.template },
                          ]"
                          @click.prevent="selectTemplateType(item, index)"
                        >
                          <v-icon class="material-icons-outlined template-icon">
                            {{ getIconName(item) }}
                          </v-icon>
                          <p class="template-type-name">
                            {{ t(item) }}
                          </p>
                          <p class="template-type-desc">
                            {{ t(item + '_desc') }}
                          </p>
                        </div>
                      </div>
                      <template v-if="notificationTemplate.type.template !== 'nudge'">
                        <p class="label">
                          {{ t('trigger') }}
                        </p>
                        <p class="hint">
                          {{ t('trigger_hint') }}
                        </p>
                        <v-checkbox
                          v-model="notificationTemplate.type.isTriggerCustom"
                          hide-details
                          class="mb-2"
                          :label="t('trigger_checkbox')"
                        />
                        <div v-if="notificationTemplate.type.isTriggerCustom" class="radio-wrapper">
                          <v-checkbox
                            v-model="isAllSelected"
                            :label="t('all')"
                            hide-details
                            @update:model-value="selectAll($event, index)"
                          />
                          <v-checkbox
                            v-for="trigger in templateTriggers"
                            :key="trigger"
                            v-model="notificationTemplate.type.trigger"
                            :label="getTemplateTriggerLabel(trigger)"
                            :value="trigger"
                            hide-details
                            @update:model-value="checkAllState(index)"
                          />
                        </div>
                      </template>
                      <template v-if="notificationTemplate.type.template === 'nudge'">
                        <p class="label">
                          {{ t('nudge_schedule') }}
                        </p>
                        <p class="hint">
                          {{ t('nudge_schedule_hint') }}
                        </p>
                        <v-checkbox
                          v-model="notificationTemplate.type.isScheduleCustom"
                          hide-details
                          class="mb-2"
                          :label="t('nudge_schedule_checkbox')"
                        />
                        <SchedulePicker
                          v-if="notificationTemplate.type.isScheduleCustom"
                          v-model="notificationTemplate.type.schedule"
                        />
                      </template>
                    </v-form>
                  </v-expansion-panel-text>
                </v-expansion-panel>
                <!--Settings-->
                <v-expansion-panel
                  :class="[
                    'accordion',
                    {
                      'has-error':
                        !isSettingsFormValid ||
                        !isDomainValid(notificationTemplate.settings.from.domain) ||
                        !isEmailValid ||
                        !notificationTemplate.settings.from.name,
                    },
                  ]"
                >
                  <v-expansion-panel-title class="accordion-header">
                    <p class="accordion-header__title">
                      {{ t('settings') }}
                    </p>
                  </v-expansion-panel-title>
                  <v-expansion-panel-text class="accordion-content">
                    <v-form ref="form" v-model="isSettingsFormValid">
                      <p class="label">
                        {{ t('from') }}
                      </p>
                      <p class="hint">
                        {{ t('from_hint') }}
                      </p>
                      <div class="compo-input-container">
                        <div
                          :class="[
                            'combo-input-wrapper',
                            {
                              'has-error':
                                !isEmailValid ||
                                !isDomainValid(notificationTemplate.settings.from.domain) ||
                                !notificationTemplate.settings.from.name,
                            },
                          ]"
                        >
                          <v-text-field
                            v-model="notificationTemplate.settings.from.name"
                            :rules="[
                              rules.required,
                              rules.emailName,
                              rules.maxlength(inputMaxLength),
                            ]"
                            hide-details
                            flat
                            color="green"
                            class="combo-text"
                            type="text"
                            variant="solo"
                            :disabled="!selectableDomains.length"
                            @update:model-value="
                              validateEmail(notificationTemplate.settings.from.name)
                            "
                          />
                          <v-select
                            v-model="notificationTemplate.settings.from.domain"
                            placeholder="Select a domain"
                            flat
                            :rules="[rules.required]"
                            hide-details
                            color="green"
                            class="combo-select"
                            variant="solo"
                            :items="selectableDomains"
                            :disabled="!selectableDomains.length"
                          />
                        </div>
                        <i18n-t
                          v-if="!selectableDomains.length"
                          keypath="NotificationTemplates.no_domain_text"
                          tag="p"
                          class="error-message"
                        >
                          <button
                            class="no_domain_link"
                            @click.prevent="$emit('openCustomDomains')"
                          >
                            {{ t('link_here') }}
                          </button>
                        </i18n-t>
                        <i18n-t
                          v-if="
                            notificationTemplate.settings.from.domain &&
                            !isDomainValid(notificationTemplate.settings.from.domain)
                          "
                          keypath="NotificationTemplates.domain_invalid"
                          tag="p"
                          class="error-message"
                        >
                          <button
                            class="no_domain_link"
                            @click.prevent="$emit('openCustomDomains')"
                          >
                            {{ t('link_here') }}
                          </button>
                        </i18n-t>
                        <p
                          v-if="
                            notificationTemplate.settings.from.name &&
                            inputMaxLength < notificationTemplate.settings.from.name.length
                          "
                          class="error-text email-name"
                        >
                          {{ $t('RulesMixin.max_length', { limit: inputMaxLength }) }}
                        </p>
                      </div>
                      <p class="label">
                        {{ t('alias') }}
                      </p>
                      <p class="hint">
                        {{ t('alias_hint') }}
                        <b>{{
                          t('alias_hint_bold', {
                            default: getDefaultTemplateValue(notificationTemplate.type.template)
                              .settings.alias,
                          })
                        }}</b>
                      </p>
                      <v-checkbox
                        v-model="notificationTemplate.settings.isAliasCustom"
                        hide-details
                        class="mb-2"
                        :label="t('custom_alias_checkbox')"
                        @update:model-value="setValuesOnCustomState(index, 'isAliasCustom')"
                      />
                      <transition name="fade">
                        <v-text-field
                          v-if="notificationTemplate.settings.isAliasCustom"
                          v-model="notificationTemplate.settings.alias"
                          :rules="[rules.required, rules.maxlength(inputMaxLength)]"
                          counter
                          :maxlength="inputMaxLength"
                          variant="outlined"
                          flat
                          color="green"
                          class="mb-5"
                        />
                      </transition>
                      <p class="label">
                        {{ t('subject') }}
                      </p>
                      <p class="hint">
                        {{ t('subject_hint') }}
                        <b>{{
                          t('subject_hint_bold', {
                            default: getDefaultTemplateValue(notificationTemplate.type.template)
                              .settings.subject,
                          })
                        }}</b>
                      </p>
                      <v-checkbox
                        v-model="notificationTemplate.settings.isSubjectCustom"
                        hide-details
                        class="mb-2"
                        :label="t('custom_subject_checkbox')"
                        @update:model-value="setValuesOnCustomState(index, 'isSubjectCustom')"
                      />
                      <transition name="fade">
                        <v-text-field
                          v-if="notificationTemplate.settings.isSubjectCustom"
                          v-model="notificationTemplate.settings.subject"
                          :rules="[rules.required, rules.maxlength(inputMaxLength)]"
                          counter
                          :maxlength="inputMaxLength"
                          variant="outlined"
                          flat
                          color="green"
                          class="mb-5"
                        />
                      </transition>
                      <p class="label">
                        {{ t('pre_header') }}
                      </p>
                      <p class="hint">
                        {{ t('pre_header_hint') }}
                        <b>{{
                          t('pre_header_hint_bold', {
                            default: getDefaultTemplateValue(notificationTemplate.type.template)
                              .settings.preheader,
                          })
                        }}</b>
                      </p>
                      <v-checkbox
                        v-model="notificationTemplate.settings.isPreheaderCustom"
                        hide-details
                        class="mb-2"
                        :label="t('custom_pre_header_checkbox')"
                        @update:model-value="setValuesOnCustomState(index, 'isPreHeaderCustom')"
                      />
                      <transition name="fade">
                        <v-text-field
                          v-if="notificationTemplate.settings.isPreheaderCustom"
                          v-model="notificationTemplate.settings.preheader"
                          :rules="[rules.required, rules.maxlength(inputMaxLength)]"
                          counter
                          :maxlength="inputMaxLength"
                          variant="outlined"
                          flat
                          color="green"
                          class="mb-5"
                        />
                      </transition>
                      <p id="schedule-date" ref="schedule-date" class="label">
                        {{ t('schedule') }}
                      </p>
                      <p class="hint">
                        {{ t('schedule_hint') }}
                      </p>

                      <p class="mb-1">
                        {{ t('start_date') }}
                      </p>
                      <DateTimePicker
                        v-model="notificationTemplate.settings.schedule.startDate"
                        :min-value="initialDate"
                        class="mb-2"
                        :clearable="false"
                        :date-picker-rules="[rules.required]"
                      />

                      <p class="mb-1">
                        {{ t('end_date') }}
                      </p>
                      <v-checkbox
                        v-model="isEndDateAdded"
                        hide-details
                        class="mb-2"
                        :label="t('end_date_checkbox')"
                        @update:model-value="
                          setValuesOnCustomState(index, 'isEndDateAdded'), clearEndDate(index)
                        "
                      />
                      <transition name="fade">
                        <div>
                          <DateTimePicker
                            v-if="isEndDateAdded"
                            v-model="notificationTemplate.settings.schedule.endDate"
                            :min-value="
                              new Date(
                                notificationTemplate.settings.schedule.startDate,
                              ).toISOString()
                            "
                            class="mb-2"
                            :error="!isScheduleValid"
                            @focus="
                              validateSchedule(
                                notificationTemplate.settings.schedule.startDate,
                                notificationTemplate.settings.schedule.endDate,
                              )
                            "
                          />
                          <p v-if="!isScheduleValid && isEndDateAdded" class="error-message">
                            {{ t('schedule_error') }}
                          </p>
                        </div>
                      </transition>
                    </v-form>
                  </v-expansion-panel-text>
                </v-expansion-panel>

                <!--Content-->
                <v-expansion-panel :class="['accordion', { 'has-error': !isContentFormValid }]">
                  <v-expansion-panel-title class="accordion-header">
                    <p class="accordion-header__title">
                      {{ t('content') }}
                    </p>
                  </v-expansion-panel-title>
                  <v-expansion-panel-text>
                    <v-form ref="form" v-model="isContentFormValid">
                      <p class="label">
                        {{ t('header_image') }}
                      </p>
                      <p class="hint">
                        {{ t('header_image_hint') }} <b> {{ t('header_image_hint_bold') }}</b>
                      </p>
                      <v-checkbox
                        v-model="notificationTemplate.content.isHeaderImageCustom"
                        hide-details
                        class="mb-4"
                        :label="t('header_image_checkbox')"
                        @update:model-value="setValuesOnCustomState(index, 'isHeaderImageCustom')"
                      />
                      <transition name="fade">
                        <div
                          v-if="notificationTemplate.content.isHeaderImageCustom"
                          class="custom-header-image-wrapper"
                        >
                          <img
                            :src="notificationTemplate.content.headerImage"
                            alt="asd"
                            class="custom-header-image-preview"
                          />
                          <gs-button
                            icon="mdi-upload"
                            class="upload-button"
                            type="secondary"
                            @click.prevent="openUploadModal(index)"
                          >
                            upload image
                          </gs-button>
                        </div>
                      </transition>
                      <p class="label">
                        {{ t('body') }}
                      </p>
                      <p class="hint">
                        {{ t('body_hint') }}
                      </p>
                      <v-checkbox
                        v-model="notificationTemplate.content.isBodyCustom"
                        hide-details
                        class="mb-2"
                        :label="t('custom_body_checkbox')"
                        @update:model-value="setValuesOnCustomState(index, 'isBodyCustom')"
                      />
                      <v-textarea
                        v-if="notificationTemplate.content.isBodyCustom"
                        v-model="notificationTemplate.content.body"
                        :rules="[rules.required]"
                        :error="!isBodyValid"
                        :error-messages="bodyErrorMessage"
                        variant="outlined"
                        color="green"
                        class="intro-textarea"
                        no-resize
                        @update:model-value="validateBody(notificationTemplate.content.body)"
                      />
                      <p class="label">
                        {{ t('overview') }}
                      </p>
                      <p class="hint">
                        {{ t('overview_hint') }}
                      </p>
                      <v-checkbox
                        v-model="notificationTemplate.content.isFooterHidden"
                        hide-details
                        class="mb-4"
                        :label="t('hide_overview_checkbox')"
                      />
                      <p class="label">
                        {{ t('total_impact') }}
                      </p>
                      <p class="hint">
                        {{ t('total_impact_hint') }}
                      </p>
                      <v-checkbox
                        v-model="notificationTemplate.content.isTotalImpactHidden"
                        hide-details
                        class="mb-4"
                        :label="t('hide_total_impact')"
                      />
                    </v-form>
                  </v-expansion-panel-text>
                </v-expansion-panel>
                <v-expansion-panel
                  v-if="!!maxCustomEmailTemplateAmount"
                  :class="['accordion', { 'has-error': !isTestEmailValid }]"
                >
                  <v-expansion-panel-title class="accordion-header" @click="validateTestEmail">
                    <p class="accordion-header__title">
                      {{ t('test') }}
                    </p>
                  </v-expansion-panel-title>
                  <v-expansion-panel-text class="accordion-content">
                    <v-form ref="testEmailForm" v-model="isTestEmailValid">
                      <p class="label">
                        {{ t('send_test') }}
                      </p>
                      <p class="hint">
                        {{ t('send_test_hint') }}
                      </p>
                      <div class="send-test-wrapper">
                        <v-text-field
                          v-model="testEmailAddress"
                          type="email"
                          variant="outlined"
                          :error-messages="!isEmailTemplateFormValid ? t('form_error_test') : ''"
                          :error="!isEmailTemplateFormValid"
                          flat
                          color="green"
                          :rules="[rules.email]"
                        />
                        <gs-button
                          :disabled="!isTestEmailValid || !isEmailTemplateFormValid || loading"
                          size="large"
                          width="100"
                          :loading="loading"
                          @click.prevent="sendOutTestEmail(index)"
                        >
                          {{ t('send') }}
                        </gs-button>
                      </div>
                    </v-form>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
              <div class="save-wrapper">
                <gs-button
                  size="large"
                  :disabled="
                    !isEveryFormValid ||
                    loading ||
                    !isDomainValid(notificationTemplate.settings.from.domain) ||
                    !notificationTemplate.settings.from.name
                  "
                  :loading="loading"
                  @click="handleSaveClick(index)"
                >
                  {{ t('save') }}
                </gs-button>
                <gs-button size="large" type="info" @click="handleCancelClick(index)">
                  {{ t('cancel') }}
                </gs-button>
              </div>
              <p
                v-if="
                  !isEveryFormValid || !isDomainValid(notificationTemplate.settings.from.domain)
                "
                class="error-message text-right"
              >
                {{ t('form_error_save') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </panel>

    <panel
      v-if="openedIndex !== null"
      padding="small"
      class="email-preview-panel"
      background="secondary"
    >
      <CustomMenu :menu-items="menuItems" class="menu-bar" @menuClick="handleMenuClick" />
      <EmailPreview
        v-bind="localEmailTemplates[openedIndex]"
        v-if="activeMenuItemValue === 'preview'"
        :email-domain="localEmailTemplates[openedIndex].settings.from.domain"
        :email-name="localEmailTemplates[openedIndex].settings.from.name"
        :template-type="localEmailTemplates[openedIndex].type.template"
      />
      <VariablesPreview v-if="activeMenuItemValue === 'variables'" />
    </panel>
    <CallToUpgradePopup
      v-if="isCallToUpgradePopupOpen"
      :popup-type="'template'"
      :is-premium-only="false"
      @close="isCallToUpgradePopupOpen = false"
    />
    <DeleteDomainDialog
      v-if="isDeleteModalOpen"
      delete-type="template"
      :email-template-i-d="templateToDelete"
      :email-template-name="selectedEmailTemplateName"
      @close="isDeleteModalOpen = false"
      @deleted="refreshAfterDelete()"
    />
  </div>
</template>

<script lang="ts">
import type { CustomIntegrationResponse, CustomIntegrationTrigger } from '@api/index'
import {
  addEmailTemplate,
  disableEmailTemplate,
  getCustomIntegrations,
  testEmailTemplate,
  updateEmailTemplate,
} from '@api/index'
import type { Notification } from '@/store/notification'
import { RulesMixin } from '@/helpers/mixins/RulesMixin.vue'
import type {
  Domain,
  EmailTemplateWithStatus,
  TemplateTrigger,
  TemplateType,
} from '@/store/customer-engagement'
import { TEMPLATE_TRIGGER, TEMPLATE_TYPE } from '@/store/customer-engagement'
import Panel from '@/components/common/Panel.vue'
import DateTimePicker from '@/components/common/DateTimePicker.vue'
import { Utils } from '@/helpers/mixins/utilsMixin'
import SchedulePicker from '@/components/form/SchedulePicker.vue'
import EmailPreview from '@/components/CustomerEngagementPortal/EmailTemplates/EmailPreview.vue'
import VariablesPreview from '@/components/CustomerEngagementPortal/EmailTemplates/VariablesPreview.vue'
import CustomMenu from '@/components/Network/CustomMenu.vue'
import { GREENSPARK_EMAIL_DOMAIN, type MenuItem } from '@/helpers/interfaces'
import CallToUpgradePopup from '@/components/common/CallToUpgradePopup.vue'
import DeleteDomainDialog from '@/components/CustomerEngagementPortal/EmailTemplates/DeleteDomainDialog.vue'
import type { TranslateResult } from 'vue-i18n'
import { defineComponent } from 'vue'
import { IntegrationsMixin } from '@/helpers/mixins/integrationsMixin'

const ONLY_CAN_HAVE_AT_MAX_1_CUSTOM_NOTIFICATION_TEMPLATE =
  'ONLY_CAN_HAVE_AT_MAX_1_CUSTOM_NOTIFICATION_TEMPLATE'

export default defineComponent({
  name: 'NotificationTemplates',
  emits: ['openCustomDomains'],
  components: {
    DeleteDomainDialog,
    EmailPreview,
    Panel,
    DateTimePicker,
    SchedulePicker,
    VariablesPreview,
    CustomMenu,
    CallToUpgradePopup,
  },
  mixins: [RulesMixin, Utils, IntegrationsMixin],
  data() {
    return {
      loading: false,
      isAddNewOpen: false,
      isTypeFormValid: true,
      isSettingsFormValid: true,
      isContentFormValid: true,
      bodyErrorMessage: '',
      menuItems: [
        { active: true, label: 'Preview', value: 'preview' },
        { active: false, label: 'Variables', value: 'variables' },
      ],
      localEmailTemplates: [],
      openedIndex: null,
      customIntegrations: [],
      templateTypes: [],
      panelsOpen: [0],
      isEndDateAdded: false,
      isTestEmailValid: true,
      testEmailAddress: '',
      isAllSelected: false,
      activeMenuItemValue: 'preview',
      isCallToUpgradePopupOpen: false,
      isScheduleValid: true,
      isNameFilled: true,
      templateToDelete: '',
      isDeleteModalOpen: false,
      selectedEmailTemplateName: '',
      inputMaxLength: 200,
      bodyMaxLength: 10000,
      isBodyValid: true,
      isEmailValid: true,
      initialDate: new Date(Date.now()).toISOString(),
    } as {
      loading: boolean
      isAddNewOpen: boolean
      isTypeFormValid: boolean
      isSettingsFormValid: boolean
      isContentFormValid: boolean
      bodyErrorMessage: string | TranslateResult
      menuItems: MenuItem[]
      localEmailTemplates: EmailTemplateWithStatus[]
      openedIndex: number | null
      customIntegrations: CustomIntegrationResponse[]
      templateTypes: TemplateType[]
      panelsOpen: number[]
      isEndDateAdded: boolean
      isTestEmailValid: boolean
      testEmailAddress: string
      isAllSelected: boolean
      activeMenuItemValue: string
      isCallToUpgradePopupOpen: boolean
      isScheduleValid: boolean
      isNameFilled: boolean
      templateToDelete: string
      isDeleteModalOpen: boolean
      selectedEmailTemplateName: string
      inputMaxLength: number
      bodyMaxLength: number
      isBodyValid: boolean
      isEmailValid: boolean
      initialDate: string
    }
  },
  computed: {
    isMoreCustomTemplateAllowed(): boolean {
      return (
        this.localEmailTemplates.filter((template) => template.isCustom).length >=
        this.maxCustomEmailTemplateAmount
      )
    },
    defaultTemplateTriggers(): TemplateTrigger[] {
      const fallbackTemplateTriggers = [...new Set(TEMPLATE_TRIGGER)]

      return (
        this.defaultTemplates.find((template) => !!template.type.trigger)?.type.trigger ||
        fallbackTemplateTriggers
      )
    },
    templateTriggers(): TemplateTrigger[] {
      return [...this.defaultTemplateTriggers, ...this.customTemplateTriggers]
    },
    customTemplateTriggers(): TemplateTrigger[] {
      const flattenedTriggerNames = this.customIntegrations
        .flatMap((integration: CustomIntegrationResponse) => integration.triggers)
        .map((trigger: CustomIntegrationTrigger) => trigger.name)
      return [...new Set(flattenedTriggerNames)]
    },
    selectableDomains(): string[] {
      return this.domainList.map((domain) => domain.domain)
    },
    isEveryFormValid(): boolean {
      return this.isSettingsFormValid && this.isContentFormValid && this.isTypeFormValid
    },
    isEmailTemplateFormValid(): boolean {
      return this.isContentFormValid && this.isTypeFormValid
    },
    emailTemplateList(): EmailTemplateWithStatus[] {
      return this.$store.getters['getCustomerEngagementEmailTemplateList']
    },
    domainList(): Domain[] {
      return this.$store.getters['getSavedDomainList']
    },
    defaultTemplates(): EmailTemplateWithStatus[] {
      return this.$store.getters['getCustomerEngagementDefaultTemplates']
    },
    maxCustomEmailTemplateAmount(): number {
      return this.$store.getters['getMaxCustomEmailTemplateAmount']
    },
  },
  async created() {
    this.templateTypes = TEMPLATE_TYPE
    await this.loadCustomIntegrations()
    this.updateEmailTemplateList()
    if (!this.localEmailTemplates.length) {
      this.openAddNewPanel()
    }
  },
  methods: {
    validateBody(value: string) {
      const length = this.formatWhiteSpacesToLineBreak(value).length
      this.isBodyValid = this.bodyMaxLength >= length
      this.bodyErrorMessage = this.isBodyValid
        ? ''
        : this.t('body_length_exceeded', { limit: this.bodyMaxLength })
    },
    validateEmail(email: string) {
      this.isEmailValid = email.length < this.inputMaxLength && this.rules.emailName(email)
    },
    isDomainValid(domain: string): boolean | undefined {
      const selectedDomain = this.domainList.find((item) => item.domain === domain)
      return selectedDomain?.validationStatus?.valid
    },
    deepCopyDefaultTemplate(index: number): EmailTemplateWithStatus {
      return {
        ...this.defaultTemplates[index],
        type: { ...this.defaultTemplates[index].type },
        content: { ...this.defaultTemplates[index].content },
        settings: {
          ...this.defaultTemplates[index].settings,
          from: { ...this.defaultTemplates[index].settings.from },
          schedule: {
            ...this.defaultTemplates[index].settings.schedule,
            startDate: new Date(Date.now()).toISOString(),
          },
        },
      }
    },
    getTemplateTriggerLabel(trigger: TemplateTrigger): TranslateResult | string {
      if (TEMPLATE_TRIGGER.includes(trigger)) {
        return this.t(this.snakeCase(trigger))
      } else {
        const customTriggerType = this.getCustomTriggerType(trigger)
        return `${trigger} (${customTriggerType})`
      }
    },
    openAddNewPanel() {
      this.isAddNewOpen = true
      this.localEmailTemplates.push(this.deepCopyDefaultTemplate(0))
      this.openEditPanel(this.localEmailTemplates.length - 1)
      this.validateForms()
    },
    openEditPanel(index: number) {
      const unsavedNewTemplateIndex = this.localEmailTemplates.findIndex((template) => !template.id)
      if (index !== unsavedNewTemplateIndex && this.isAddNewOpen) {
        this.isAddNewOpen = false
        this.localEmailTemplates.splice(unsavedNewTemplateIndex, 1)
      }
      this.localEmailTemplates[index].content.body = this.formatLineBreaksToWhiteSpace(
        this.localEmailTemplates[index].content.body,
      )
      this.openedIndex = index
      this.isAllSelected =
        this.localEmailTemplates[index].type.trigger?.length === this.templateTriggers.length
      this.isEndDateAdded = !!this.localEmailTemplates[index].settings.schedule.endDate
      this.validateForms()
    },
    async activateState(index) {
      this.localEmailTemplates[index].isActive = true

      if (this.localEmailTemplates[index].id) {
        await this.editTemplate(this.localEmailTemplates[index])
      } else {
        await this.saveTemplate(this.localEmailTemplates[index])
      }
    },
    async deactivateState(index) {
      this.localEmailTemplates[index].isActive = false
      await this.deactivateTemplate(this.localEmailTemplates[index])
    },
    async deactivateTemplate(template: EmailTemplateWithStatus) {
      this.loading = true

      try {
        await disableEmailTemplate(template.id)
        await this.setEmailTemplateList()
        this.updateEmailTemplateList()

        this.loading = false
        this.$store.dispatch('notification/notify', {
          text: this.t('template_saved'),
        } as Notification)
      } catch (e) {
        this.loading = false
        console.error(e.response.data)
        this.$store.dispatch('notification/notify', {
          text: this.t('error'),
          isError: true,
          isClosable: true,
          buttonText: 'close',
        } as Notification)
      }
    },
    async sendOutTestEmail(index: number) {
      const body = {
        customerEmail: this.testEmailAddress,
        notificationTemplate: {
          ...this.localEmailTemplates[index],
          content: {
            ...this.localEmailTemplates[index].content,
            body: this.formatWhiteSpacesToLineBreak(this.localEmailTemplates[index].content.body),
          },
          settings: {
            ...this.localEmailTemplates[index].settings,
            from: {
              name: this.localEmailTemplates[index].settings.from.name || 'hello',
              domain:
                this.localEmailTemplates[index].settings.from.domain || GREENSPARK_EMAIL_DOMAIN,
            },
          },
        },
      }

      this.loading = true
      try {
        await testEmailTemplate(body)
        this.loading = false
        this.$store.dispatch('notification/notify', { text: this.t('email_sent') } as Notification)
      } catch (e) {
        this.loading = false
        console.error(e.response.data)
        this.$store.dispatch('notification/notify', {
          text: this.t('error'),
          isError: true,
          isClosable: true,
          buttonText: 'close',
        } as Notification)
      }
    },
    handleCancelClick(index: number) {
      this.isAddNewOpen = false
      this.openedIndex = null
      if (!this.localEmailTemplates[index].id) {
        this.localEmailTemplates.splice(index, 1)
      }
    },
    t(key: string, params?: { [key: string]: string | number }) {
      return this.$t(`NotificationTemplates.${key}`, params ?? {})
    },
    checkIfCustom(index: number) {
      return (
        (this.localEmailTemplates[index].settings.isAliasCustom &&
          !!this.localEmailTemplates[index].settings.alias) ||
        (this.localEmailTemplates[index].settings.isSubjectCustom &&
          !!this.localEmailTemplates[index].settings.subject) ||
        (this.localEmailTemplates[index].settings.isPreheaderCustom &&
          !!this.localEmailTemplates[index].settings.preheader) ||
        this.localEmailTemplates[index].type.isScheduleCustom ||
        this.localEmailTemplates[index].type.isTriggerCustom ||
        (this.localEmailTemplates[index].content.isBodyCustom &&
          !!this.localEmailTemplates[index].content.body) ||
        (this.localEmailTemplates[index].content.isHeaderImageCustom &&
          this.localEmailTemplates[index].content.headerImage !==
            this.defaultTemplates[0].content.headerImage) ||
        this.localEmailTemplates[index].content.isFooterHidden ||
        this.localEmailTemplates[index].content.isTotalImpactHidden
      )
    },
    async handleSaveClick(index: number) {
      if (
        this.isMoreCustomTemplateAllowed &&
        !this.localEmailTemplates[index].isCustom &&
        this.checkIfCustom(index)
      ) {
        this.isCallToUpgradePopupOpen = true
      } else {
        if (
          this.isEndDateAdded &&
          !this.validateSchedule(
            this.localEmailTemplates[index].settings.schedule.startDate,
            this.localEmailTemplates[index].settings.schedule.endDate,
          )
        ) {
          this.isScheduleValid = false
          const scheduleDatePickers = document.getElementById('schedule-date') as HTMLElement
          scheduleDatePickers.scrollIntoView({ behavior: 'smooth' })
          return
        }
        if (!this.localEmailTemplates[index].type.name) {
          this.isNameFilled = false
          const nameInput = document.getElementById('template-name') as HTMLElement
          nameInput.scrollIntoView({ behavior: 'smooth' })
          return
        }
        const formattedTemplate: EmailTemplateWithStatus = {
          ...this.localEmailTemplates[index],
          type: {
            ...this.localEmailTemplates[index].type,
            trigger: this.localEmailTemplates[index].type.isTriggerCustom
              ? this.getFormattedTemplateTrigger(index)
              : this.defaultTemplateTriggers,
          },
          content: {
            ...this.localEmailTemplates[index].content,
            body: this.formatWhiteSpacesToLineBreak(this.localEmailTemplates[index].content.body),
          },
        }

        if (this.localEmailTemplates[index].id) {
          await this.editTemplate(formattedTemplate)
        } else {
          await this.saveTemplate(formattedTemplate)
        }
      }
    },
    getDefaultTemplateValue(templateType: TemplateType): EmailTemplateWithStatus {
      return (
        this.defaultTemplates.find(
          (defaultTemplate) => defaultTemplate.type.template === templateType,
        ) || this.defaultTemplates[0]
      )
    },
    setValuesOnCustomState(index: number, id: string) {
      const defaultTemplateIndex =
        this.defaultTemplates.findIndex(
          (defaultTemplate) =>
            defaultTemplate.type.template === this.localEmailTemplates[index].type.template,
        ) || 0
      const defaultTemplate = this.deepCopyDefaultTemplate(defaultTemplateIndex)

      if (id === 'isPreHeaderCustom')
        this.localEmailTemplates[index].settings.preheader = !this.localEmailTemplates[index]
          .settings.isPreheaderCustom
          ? defaultTemplate.settings.preheader
          : ''
      if (id === 'isAliasCustom')
        this.localEmailTemplates[index].settings.alias = !this.localEmailTemplates[index].settings
          .isAliasCustom
          ? defaultTemplate.settings.alias
          : ''
      if (id === 'isSubjectCustom')
        this.localEmailTemplates[index].settings.subject = !this.localEmailTemplates[index].settings
          .isSubjectCustom
          ? defaultTemplate.settings.subject
          : ''
      if (!this.localEmailTemplates[index].content.isHeaderImageCustom)
        this.localEmailTemplates[index].content.headerImage = defaultTemplate.content.headerImage
      if (id === 'isBodyCustom')
        this.localEmailTemplates[index].content.body = !this.localEmailTemplates[index].content
          .isBodyCustom
          ? defaultTemplate.content.body
          : ''

      this.validateForms()
    },
    validateForms() {
      this.$nextTick(() => {
        const formArray = this.$refs.form as HTMLFormElement[]
        formArray.forEach((form) => form.validate())
      })
    },
    validateTestEmail() {
      setTimeout(() => {
        const formArray = this.$refs.testEmailForm as HTMLFormElement[]
        formArray.forEach((form) => form.validate())
      }, 200)
    },
    updateEmailTemplateList() {
      this.localEmailTemplates = [...this.emailTemplateList].map((template) => ({
        ...template,
        isActive: template?.status?.status === 'active',
      }))
    },
    async saveTemplate(template: EmailTemplateWithStatus) {
      this.loading = true
      try {
        await addEmailTemplate(template)
        await this.setEmailTemplateList()
        await this.setIntegrations()
        this.$nextTick(() => {
          this.updateEmailTemplateList()
          this.isAddNewOpen = false
          this.openedIndex = null
          this.loading = false
          this.$store.dispatch('notification/notify', {
            text: this.t('template_saved'),
          } as Notification)
        })
      } catch (e) {
        this.loading = false
        console.error(e.response.data)
        const errorCode = e.response?.data?.message

        if (errorCode === ONLY_CAN_HAVE_AT_MAX_1_CUSTOM_NOTIFICATION_TEMPLATE) {
          this.isCallToUpgradePopupOpen = true
        }

        this.$store.dispatch('notification/notify', {
          text: this.t('error'),
          isError: true,
          isClosable: true,
          buttonText: 'close',
        } as Notification)
      }
    },
    async editTemplate(template: EmailTemplateWithStatus) {
      this.loading = true
      try {
        await updateEmailTemplate(template.id, template)
        await this.setEmailTemplateList()
        await this.setIntegrations()
        this.updateEmailTemplateList()

        this.openedIndex = null

        this.loading = false
        this.$store.dispatch('notification/notify', {
          text: this.t('template_saved'),
        } as Notification)
      } catch (e) {
        this.loading = false
        console.error(e.response.data)
        const errorCode = e.response?.data?.message

        if (errorCode === ONLY_CAN_HAVE_AT_MAX_1_CUSTOM_NOTIFICATION_TEMPLATE) {
          this.isCallToUpgradePopupOpen = true
        }

        this.$store.dispatch('notification/notify', {
          text: this.t('error'),
          isError: true,
          isClosable: true,
          buttonText: 'close',
        } as Notification)
      }
    },
    async deleteTemplate(id: string, name: string) {
      this.templateToDelete = id
      this.isDeleteModalOpen = true
      this.selectedEmailTemplateName = name
    },
    refreshAfterDelete() {
      this.updateEmailTemplateList()
      if (!this.localEmailTemplates.length) {
        this.openAddNewPanel()
      }
      this.openedIndex = null
      this.loading = false
    },
    getIconName(templateType: TemplateType) {
      switch (templateType) {
        case 'nudge':
          return 'mdi-bell-plus-outline'
        case 'invitation':
          return 'mdi-send-outline'
        case 'update':
          return 'mdi-update'
      }
    },
    selectTemplateType(templateType: TemplateType, index: number) {
      try {
        const selectedTemplateIndex: number = this.defaultTemplates.findIndex(
          (template) => template.type.template === templateType,
        )
        const selectedTemplate = this.deepCopyDefaultTemplate(selectedTemplateIndex)

        for (const [key, value] of Object.entries(selectedTemplate)) {
          if (key !== 'isActive' && key !== 'type' && key !== 'id') {
            this.localEmailTemplates[index][key] = value
          }
        }

        for (const [key, value] of Object.entries(selectedTemplate.type)) {
          if (key !== 'name') {
            this.localEmailTemplates[index].type[key] = value
          }
        }
      } catch (e) {
        console.error(e.response.data)
      }
    },
    selectAll(event, index: number) {
      this.localEmailTemplates[index].type.trigger = this.isAllSelected ? this.templateTriggers : []
    },
    checkAllState(index: number) {
      this.isAllSelected =
        this.templateTriggers.length === this.localEmailTemplates[index].type.trigger?.length
    },
    openUploadModal(index) {
      if (window.cloudinary) {
        window.cloudinary
          .createUploadWidget(
            {
              cloud_name: process.env.VUE_APP_CLOUDNAME,
              upload_preset: process.env.VUE_APP_UPLOAD_PRESET,
              folder: 'templates',
              sources: ['local', 'url', 'camera'],
              cropping: true,
              cropping_aspect_ratio: 2,
              showSkipCropButton: false,
              showPoweredBy: false,
              clientAllowedFormats: ['png', 'jpg', 'jpeg'],
            },
            (error, result) => {
              if (!error && result && result.event === 'success') {
                this.localEmailTemplates[index].content.headerImage = result.info.url
              }
            },
          )
          .open()
      }
    },
    handleMenuClick(value: string) {
      this.menuItems.map((item) => {
        item.active = item.value === value
      })
      this.activeMenuItemValue = value
    },
    validateSchedule(startDate: string, endDate?: string): boolean {
      this.isScheduleValid = endDate ? new Date(endDate) > new Date(startDate) : false
      return endDate ? new Date(endDate) > new Date(startDate) : false
    },
    clearEndDate(index: number) {
      if (!this.isEndDateAdded) {
        this.localEmailTemplates[index].settings.schedule.endDate = undefined
      }
    },
    async loadCustomIntegrations() {
      try {
        const { data } = await getCustomIntegrations()
        this.customIntegrations = data
      } catch (e) {
        console.error(e)
      }
    },
    getFormattedTemplateTrigger(index: number) {
      const template = this.localEmailTemplates[index]
      const triggers = template.type.trigger
      return triggers?.filter((trigger) => this.templateTriggers.includes(trigger)) || null
    },
    getCustomTriggerType(trigger: string) {
      const integration: CustomIntegrationResponse | undefined = this.customIntegrations.find(
        (integration) =>
          integration.triggers.some((integrationTrigger) => integrationTrigger.name === trigger),
      )
      return this.getIntegrationPlatformName(integration?.type || 'custom')
    },
    setEmailTemplateList() {
      return this.$store.dispatch('setEmailTemplateList')
    },
    setIntegrations(): Promise<void> {
      return this.$store.dispatch('setIntegrations')
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.dialog {
  display: flex;
  gap: 40px;
  width: 100%;
  position: relative;
}

.templates-panel {
  background-color: var(--ui-white);
  border-radius: var(--border-radius-big);
  box-shadow: var(--box-shadow-wide);
  padding: 20px;
  flex: 1 1 100%;
}

.email-preview-panel {
  display: none;
}

.error-text.email-name {
  color: var(--ui-red);
  font-size: 14px;
}

.templates {
  background-color: white;
  border-radius: var(--border-radius-big);
}

.template-name {
  font-size: 18px;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@container list-item-header (width > 510px) {
  p.template-name {
    max-width: 765px;
  }
}

@container list-item-header not (width > 510px) {
  p.template-name {
    max-width: 285px;
  }
}

.type-pill {
  margin-left: 5px;
  color: var(--ui-white);
  background-color: var(--ui-blue);
  border-radius: 6px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  padding: 0 4px;
}

.custom-pill {
  margin-left: 5px;
  color: var(--font-color-primary);
  background-color: var(--ui-yellow);
  border-radius: 6px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  padding: 0 4px;
}

.label {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 0;
}

.hint {
  font-size: 14px;
  line-height: 16px;
  color: var(--gray-light);
  margin-bottom: 15px;
  max-width: 45ch;
  font-weight: 400;
}

.form-input {
  margin-bottom: 20px;
}

.activation-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  .hint {
    margin-bottom: 0;
  }
}

.activate-switch {
  margin: 0;
  padding: 0;
}

.accordion-wrapper {
  background-color: var(--ui-white);
  padding: 10px;
}

.accordion {
  margin: 10px 0;
  border-radius: 4px;
  position: relative;
}

.accordion.has-error {
  border-radius: 4px;
  border: 2px solid var(--ui-red);
}

.accordion-header {
  padding: 15px;
}

.accordion-header:hover {
  background-color: var(--ui-green-light-20);
}

.accordion-header__title {
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 0;
}

:deep(.v-expansion-panel-content__wrap) {
  padding: 10px 15px;
}

.template-type-wrapper {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
  padding-top: 10px;
  margin-bottom: 20px;
}

.template-type {
  border-radius: 10px;
  border: 2px solid var(--gray-light);
  cursor: pointer;
  padding: 10px 15px;
}

.template-type:hover {
  box-shadow: var(--box-shadow-light);
}

.template-type.selected {
  cursor: default;
  border: 2px solid var(--ui-green);
}

.template-type.selected:hover {
  box-shadow: none;
}

.template-icon {
  font-size: 30px;
  margin-bottom: 10px;
  color: var(--gray-light);
}

.template-type.selected .template-icon {
  color: var(--ui-green);
}

.template-type-name {
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 10px;
}

.template-type.selected .template-type-name {
  color: var(--ui-green);
}

.template-type-desc {
  color: var(--gray-light);
  max-width: 35ch;
  font-size: 14px;
  line-height: 18px;
}

.template-type.selected .template-type-desc {
  color: var(--ui-green-light);
}

.radio-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.radio-wrapper > * {
  flex: 1 1 100%;
}

.combo-input-wrapper {
  display: flex;
  border-radius: 4px;
  position: relative;
  margin-bottom: 20px;
}

.combo-input-wrapper::before {
  position: absolute;
  background: transparent;
  height: calc(100% + 2px);
  content: '';
  width: calc(100% + 2px);
  border-radius: inherit;
  top: -1px;
  left: -1px;
  border: 1px solid var(--gray);
  z-index: 0;
}

.combo-input-wrapper:focus-within::before {
  height: calc(100% + 4px);
  width: calc(100% + 4px);
  top: -2px;
  left: -2px;
  border: 2px solid var(--ui-green);
}

.combo-input-wrapper.has-error::before {
  height: calc(100% + 4px);
  width: calc(100% + 4px);
  top: -2px;
  left: -2px;
  border: 2px solid var(--ui-red);
}

.combo-text {
  flex: 0 1 60%;
  position: relative;
}

.combo-text::after {
  content: '';
  height: 100%;
  width: 1px;
  background-color: var(--gray);
  position: absolute;
  right: 0;
  top: 0;
}

.combo-select {
  flex: 0 1 40%;
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.intro-textarea {
  max-height: none; // .v-input reset
  margin-bottom: 20px;
  width: 100%;
}

.send-test-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.save-wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
}

.save-wrapper:deep(.gs-button) {
  width: 100%;
}

.input-wrapper {
  max-height: 0;
  overflow: hidden;
  visibility: hidden;
  padding: 0 10px;
  transition: all linear 0.2s;
}

.input-wrapper.is-open {
  max-height: 20000px;
  padding: 20px 10px;
  transition: all linear 0.2s;
  visibility: visible;
  border-top: 1px solid var(--gray);
}

.template-list-item .input-wrapper.is-open {
  border-top: none;
}

.template-list-item:not(:last-of-type) .input-wrapper.is-open {
  border-bottom: 1px solid var(--gray);
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 18px;
  padding: 15px 10px;
  border-bottom: 1px solid var(--gray);
}

.add-button {
  border: 1px solid var(--ui-green);
  border-radius: 10px;
  padding: 0 5px;
  color: var(--ui-green);
  font-size: 14px;
}

.list-item-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  padding: 15px 10px;
  position: relative;
  container-name: list-item-header;
  container-type: inline-size;
}

.template-list-item:not(:last-of-type) .list-item-header {
  border-bottom: 1px solid var(--gray);
}

.list-item-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  padding: 15px 10px;
  position: relative;
  width: 100%;
}

.list-item-header:hover {
  background-color: var(--ui-green-light-20);
}

.title-wrapper {
  align-items: center;
  display: flex;
}

.checkmark-icon.new {
  color: transparent;
  border: 1px solid var(--gray-light);
  border-radius: 50%;
  margin-right: 6px;
  height: 24px;
  width: 24px;
  font-size: 24px;
  line-height: 24px;
}

.checkmark-icon.active {
  border: none;
  color: var(--ui-green);
  margin-right: 6px;
}

.checkmark-icon:not(.active):not(.new) {
  border: none;
  color: var(--ui-red);
  margin-right: 6px;
}

.input {
  margin-bottom: 20px;
}

.textarea {
  margin-bottom: 20px;
}

.drawer {
  background-color: #ffffff;
  padding: 5px;
}

.drawer-button {
  align-items: center;
  display: flex;
  border-radius: var(--border-radius-big);
  text-align: left;
  padding: 5px;
  width: 100px;
  font-size: 14px;
  line-height: 16px;

  &[disabled] {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
  }
}

.drawer-icon {
  font-size: 18px;
  color: var(--font-color-primary);
  margin-right: 10px;
}

.drawer-button:hover {
  background-color: var(--ui-green-light-20);
}

.title-wrapper.add-new {
  margin-bottom: 15px;
}

.open-drawer-button {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.open-drawer-button:hover {
  background-color: var(--gray-light);
}

.open-drawer-button.is-active {
  background-color: var(--gray-light);
}

.custom-header-image-preview {
  width: 100%;
  margin-bottom: 20px;
  object-fit: contain;
  border-radius: var(--border-radius-big);
  border: 1px solid var(--gray-light-20);
  aspect-ratio: 2;
}

.upload-button {
  margin-bottom: 20px;
}

.menu-bar {
  margin-bottom: 20px;
}

.no_domain_text {
  font-weight: 600;
}

.no_domain_link {
  font-weight: 600;
  text-decoration: underline;
  color: var(--ui-green);
}

.error-message {
  font-weight: 600;
  color: var(--ui-red);
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .dialog {
    flex-direction: column;
  }

  .email-preview-panel {
    display: block;
    flex: 1 0 100%;
  }

  .save-wrapper {
    display: flex;
    flex-direction: row-reverse;
    padding: 20px 0;
  }

  .save-wrapper:deep(.gs-button:first-child) {
    width: 200px;
  }

  .save-wrapper:deep(.gs-button:last-child) {
    width: 100px;
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .dialog {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
  }

  .templates-panel {
    flex: 1 1 50%;
  }

  .email-preview-panel {
    position: sticky;
    display: block;
    flex: 0 1 50%;
    top: 0;
  }

  .template-type-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }

  .radio-wrapper > * {
    flex: 1 1 50%;
  }

  .button-wrapper {
    justify-content: flex-end;
    flex-direction: row;
    gap: 10px;
  }

  .cancel-button {
    order: 1;
  }

  .drawer-button {
    text-transform: capitalize;
  }
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
  .dialog {
    margin: 0 auto;
    max-width: 1080px;
  }

  .templates-panel {
    flex: 1 1 52%;
  }

  .email-preview-panel {
    display: block;
    flex: 0 1 48%;
  }
}
</style>
