import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
        _createElementVNode("div", { class: "description" }, [
          _createElementVNode("div", { class: "description-header" }, "Admin Panel"),
          _createElementVNode("div", { class: "description-text" }, "Admin Panel for making data changes available for CS")
        ], -1)
      ])))
    : _createCommentVNode("", true)
}