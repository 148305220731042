import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "how-it-works-wrapper" }
const _hoisted_2 = { style: {"margin-bottom":"30px"} }
const _hoisted_3 = {
  key: 0,
  class: "how-it-works-header"
}
const _hoisted_4 = {
  key: 1,
  class: "how-it-works-text"
}
const _hoisted_5 = { class: "how-it-works-subheader" }
const _hoisted_6 = {
  key: 0,
  class: "number-explanation-wrapper"
}
const _hoisted_7 = { class: "explanation" }
const _hoisted_8 = {
  key: 1,
  class: "number-explanation-wrapper"
}
const _hoisted_9 = { class: "explanation" }
const _hoisted_10 = {
  key: 2,
  class: "number-explanation-wrapper"
}
const _hoisted_11 = { class: "explanation" }
const _hoisted_12 = {
  key: 3,
  class: "number-explanation-wrapper",
  style: {"margin-bottom":"0"}
}
const _hoisted_13 = { class: "explanation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.header !== '' && _ctx.header !== undefined)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.header), 1))
        : _createCommentVNode("", true),
      (_ctx.subheader !== '' && _ctx.subheader !== undefined)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.subheader), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.t('how_it_works')), 1),
    (_ctx.text !== '' && _ctx.text !== undefined)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "number" }, "1", -1)),
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.text), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.text1 !== '' && _ctx.text1 !== undefined)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "number" }, "2", -1)),
          _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.text1), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.text2 !== '' && _ctx.text2 !== undefined)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "number" }, "3", -1)),
          _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.text2), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.text3 !== '' && _ctx.text3 !== undefined)
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "number" }, "4", -1)),
          _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.text3), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}