<template>
  <li class="impact-actions-list-item">
    <button class="impact-actions-button" @click="openPopup">
      <img
        height="70"
        width="70"
        :src="
          impactAction?.imageURL || require('@/assets/icons/greenspark-logo-vertical-green.svg')
        "
        :alt="impactAction?.sellerUsername + 'logo'"
        class="company-logo"
        loading="lazy"
      />
      <div class="order-info">
        <div class="text-wrapper">
          <p class="impact-action-text">
            <v-icon class="impact-action-icon material-icons-outlined">
              {{ iconName }}
            </v-icon>
            {{ label }}
          </p>
          <p class="impact-action-text">
            <v-icon class="impact-action-icon"> mdi-shopping-outline </v-icon>
            {{ impactAction?.sellerUsername || 'Greenspark' }}
          </p>
        </div>
        <div class="order-impacts">
          <impact-pill
            v-for="(impact, index) in impactAction?.action.impacts"
            :key="index"
            :impact="{ type: impact.type || 'multiple', amount: impact.amount }"
          />
        </div>
      </div>
    </button>
  </li>
</template>

<script lang="ts">
import type { ImpactAction } from '@/helpers/interfaces'
import ImpactPill from '@/components/ImpactWallet/ImpactPill.vue'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import type { TranslateResult } from 'vue-i18n'

export default defineComponent({
  name: 'ImpactActionsItem',
  components: { ImpactPill },
  computed: {
    iconName(): string {
      switch (this.impactAction?.type) {
        case 'ORDER_CREATED':
          return 'mdi-basket-outline'
        case 'PER_SUBSCRIBER':
          return 'mdi-email-check-outline'
        case 'PER_PRODUCT_REVIEW':
          return 'mdi-message-draw'
        case 'PER_COMPANY_REVIEW':
          return 'mdi-message-draw'
        case 'ONE_OFF':
          return 'mdi-flash-outline'
        case 'REFERRAL':
          return 'mdi-bullhorn-variant-outline'
        case 'SUBSCRIPTION':
          return 'mdi-tag-heart-outline'
        case 'INVOICE':
          return 'mdi-cart-outline'
        case 'GIFT':
          return 'mdi-gift-outline'
        case 'REDEEM_LOYALTY_POINTS':
          return 'mdi-star'
        default:
          return 'mdi-basket-outline'
      }
    },
    label(): TranslateResult {
      switch (this.impactAction?.type) {
        case 'ORDER_CREATED':
          return this.tc('product', this.impactAction?.action.lineItems?.length ?? 0)
        case 'PER_SUBSCRIBER':
          return this.t('per_subscriber')
        case 'PER_PRODUCT_REVIEW':
          return this.t('per_product_review')
        case 'PER_COMPANY_REVIEW':
          return this.t('per_company_review')
        case 'ONE_OFF':
          return this.t('one_off')
        case 'REFERRAL':
          return this.t('referral')
        case 'SUBSCRIPTION':
          return this.t('subscription')
        case 'INVOICE':
          return this.t('purchase')
        case 'REDEEM_LOYALTY_POINTS':
          return this.t('loyalty_points')
        default:
          return this.t('subscription')
      }
    },
  },
  methods: {
    t(key: string) {
      return this.$t(`ImpactActionsItem.${key}`)
    },
    tc(key: string, amount: number) {
      return this.$t(`ImpactActionsItem.${key}`, amount ?? 0)
    },
    openPopup() {
      this.$parent?.$parent?.$emit('open-popup', this.impactAction)
    },
  },
  props: {
    impactAction: {
      type: Object as PropType<ImpactAction>,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.impact-actions {
  border-radius: var(--border-radius-big);
  box-shadow: var(--box-shadow-wide);
  background: white;
  padding: 32px;
}

.impact-actions-label {
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 24px;
}

.impact-actions-list {
  list-style-type: none;
  padding: 0;
}

.impact-actions-list-item {
  border-radius: var(--border-radius-big);
}

.impact-actions-list-item:not(:last-of-type) {
  margin-bottom: 20px;
}

.impact-actions-button {
  align-items: center;
  background-color: var(--ui-beige);
  border-radius: var(--border-radius-big);
  color: var(--font-color-primary);
  display: flex;
  padding: 10px 40px 10px 20px;
  position: relative;
  text-decoration: none;
  gap: 15px;
  width: 100%;
}

.impact-actions-button.tailored {
  padding-bottom: 15px;
  padding-top: 15px;
}

.impact-actions-button:hover {
  box-shadow: var(--box-shadow-light);
}

.company-logo {
  border-radius: 50%;
  object-fit: contain;
}

.impact-actions-button.tailored .company-logo {
  height: 70px;
  width: 70px;
  margin: 0 5px;
}

.order-info {
  width: 100%;
}

.text-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 15px;
}

.order-impacts {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 1;
  width: 100%;
  gap: 5px;
}

.order-impact-text {
  align-items: center;
  color: var(--font-color-primary);
  display: flex;
  margin: 0;
}

.impact-action-icon {
  color: inherit;
  flex-shrink: 0;
  height: 24px;
  margin-right: 5px;
  width: 24px;
}

.impact-action-text {
  align-items: center;
  color: var(--font-color-primary);
  display: flex;
  margin: 0;
  white-space: nowrap;
}

.impact-actions-button::after {
  border-right: 2px solid var(--ui-green);
  border-top: 2px solid var(--ui-green);
  bottom: 50%;
  content: '';
  display: block;
  height: 8px;
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translate(-50%, -50%) rotate(40deg);
  width: 8px;
}

.impact-actions-button:hover::after {
  opacity: 0;
  transform: translate(calc(-50% + 10px), -50%) rotate(40deg);
  transition: all linear 0.2s;
}

.impact-actions-button::before {
  border-right: 2px solid var(--ui-green);
  border-top: 2px solid var(--ui-green);
  bottom: 50%;
  content: '';
  display: block;
  height: 8px;
  opacity: 0;
  position: absolute;
  right: 34px;
  top: 50%;
  transform: translate(-50%, -50%) rotate(40deg);
  width: 8px;
}

.impact-actions-button:hover::before {
  opacity: 1;
  transform: translate(calc(-50% + 10px), -50%) rotate(40deg);
  transition: all linear 0.2s;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .impact-actions-button {
    padding: 10px 60px 10px 24px;
  }

  .order-info {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .text-wrapper {
    margin-bottom: 0;
    gap: 10px;
  }

  .order-impacts {
    width: auto;
    gap: 10px;
  }
}
</style>
