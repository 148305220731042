import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "outer-wrapper" }
const _hoisted_2 = { class: "success-t" }
const _hoisted_3 = { class: "success-wrapper" }
const _hoisted_4 = { class: "header" }
const _hoisted_5 = { class: "text" }
const _hoisted_6 = { class: "button-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_Loading = _resolveComponent("Loading")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.dialog,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dialog) = $event)),
    fullscreen: true
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.text), 1),
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.subtext), 1),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_gs_button, {
                  disabled: _ctx.buttonLoading,
                  "full-width": true,
                  size: "large",
                  onClick: _withModifiers(_ctx.goHome, ["prevent"])
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.label), 1)
                  ]),
                  _: 1
                }, 8, ["disabled", "onClick"])
              ])
            ])
          ])
        ]),
        _createVNode(_Transition, { name: "fade" }, {
          default: _withCtx(() => [
            (_ctx.loading)
              ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}