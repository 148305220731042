<template>
  <div class="edit-custom-integration">
    <dl class="integration-name-wrapper section border-bottom border-top">
      <dd class="label">
        {{ t('integration') }}
      </dd>
      <OnboardingPill>
        {{ getIntegrationPlatformName(customIntegrationType as IntegrationPlatform) ?? '' }}
      </OnboardingPill>
    </dl>
    <dl class="integration-name-wrapper section">
      <dd class="label">
        {{
          t('table_title', {
            source: getIntegrationPlatformName(customIntegrationType as IntegrationPlatform) ?? '',
          })
        }}
      </dd>
      <dd class="description" v-html="getDescription"></dd>
    </dl>
    <LoadingTable v-if="loading" />
    <v-table v-if="!loading && apiKeys.length > 0" class="keys table" fixed-header>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              {{ t('table_name') }}
            </th>
            <th class="text-left">
              {{ t('table_type') }}
            </th>
            <th class="text-left">
              {{ t('placeholder') }}
            </th>
            <th class="text-left">
              {{ t('table_created') }}
            </th>
            <th class="text-right">
              {{ t('manage') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in apiKeys" :key="index">
            <td>{{ item.name }}</td>
            <td>{{ apiKeyTypeFilter(item.subType) }}</td>
            <td>{{ visibleKeyFilter(item.visibleValue) }}</td>
            <td>{{ dateFormatFilter(item.activationDate, userLocale) }}</td>
            <td class="text-right">
              <QuickMenu
                :quick-menu-items="menuItems"
                @quickMenuClick="quickMenuClick($event, item)"
              />
            </td>
          </tr>
        </tbody>
      </template>
    </v-table>
    <div class="button-wrapper">
      <gs-button
        class="form-control"
        icon="mdi-chevron-left"
        size="large"
        type="outlined"
        @click="$emit('back')"
      >
        {{ $t('CommonUi.back') }}
      </gs-button>
      <gs-button
        :loading="loading"
        full-width
        size="large"
        type="tertiary"
        @click.prevent="$emit('back')"
      >
        {{ t('next') }}
      </gs-button>
    </div>
  </div>
  <v-dialog v-model="isEditApiKeyDialogOpen" persistent transition="fade" width="700">
    <v-card class="card d-flex flex-column">
      <div>
        <div class="popup-header">
          {{
            t('edit_key_title', {
              source:
                getIntegrationPlatformName(customIntegrationType as IntegrationPlatform) ?? '',
            })
          }}
        </div>
        <button class="close-button" variant="plain" @click="isEditApiKeyDialogOpen = false">
          <v-icon class="close-icon"> mdi-close</v-icon>
        </button>
      </div>
      <div class="rename-description">
        {{ t('edit_key_description') }}
      </div>
      <div>
        <v-form v-model="isFormValid" class="mt-5">
          <gs-input
            v-model="editName"
            :hide-details="false"
            :maxlength="maxLength"
            :label="t('create_key_name')"
            :rules="[rules.required, rules.maxlength(maxLength), rules.minlength(minLength)]"
            class="apy-key-input"
            counter
          />
        </v-form>
      </div>
      <gs-button
        :disabled="isEditApiKeyLoading || !isFormValid || editName === currentItem?.name"
        :loading="isEditApiKeyLoading"
        full-width
        height="50px"
        size="large"
        @click="editApiKey"
      >
        {{ t('rename') }}
      </gs-button>
    </v-card>
  </v-dialog>
  <v-dialog v-model="isRegenerateApiKeyDialogOpen" persistent transition="fade" width="700">
    <v-card class="card d-flex flex-column">
      <div>
        <div class="popup-header-regenerate">
          {{
            t('regenerate_key_title', {
              source:
                getIntegrationPlatformName(customIntegrationType as IntegrationPlatform) ?? '',
            })
          }}
        </div>
      </div>
      <div class="regenerate-wrapper">
        <div class="regenerate-description">
          {{ getRegenerateKeyDescription }}
        </div>
        <div class="input-wrapper">
          <v-text-field
            :model-value="apiKey"
            class="apy-key-input"
            color="green"
            disabled
            flat
            hide-details
            readonly
            variant="outlined"
          />
          <button class="apy-key-button" @click.prevent="copy">
            <v-icon class="copy-icon">
              {{ isCopied ? 'mdi-check' : 'mdi-content-copy' }}
            </v-icon>
          </button>
        </div>
        <div class="alert-text">
          {{ getAlertText }}
        </div>
      </div>
      <gs-button
        :disabled="!wasCopiedAtLeastOnce"
        :loading="isRegenerateApiKeyLoading"
        :uppercased="false"
        full-width
        height="50px"
        size="large"
        @click="isRegenerateApiKeyDialogOpen = false"
      >
        {{ t('done') }}
      </gs-button>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Utils } from '@/helpers/mixins/utilsMixin'
import { RulesMixin } from '@/helpers/mixins/RulesMixin.vue'
import OnboardingPill from '@/components/onboarding/OnboardingPill.vue'
import { type ApiKeyResponse, generateApiKey, getApiKeys, updateApiKey } from '@api/index'
import type { Notification } from '@/store/notification'
import { defineComponent } from 'vue'
import { IntegrationsMixin } from '@/helpers/mixins/integrationsMixin'
import type { CustomIntegrationType, IntegrationPlatform } from '@/helpers/interfaces'
import QuickMenu from '@/components/tools/Table/QuickMenu.vue'
import LoadingTable from '@/components/tools/Table/LoadingTable.vue'

export default defineComponent({
  name: 'EditSimplifiedCustomIntegration',
  emits: ['back', 'keyGenerated', 'update:api-key-name'],
  components: {
    LoadingTable,
    QuickMenu,
    OnboardingPill,
  },
  mixins: [RulesMixin, Utils, IntegrationsMixin],
  data() {
    return {
      loading: false,
      apiKeys: [],
      currentItem: undefined,
      isEditApiKeyDialogOpen: false,
      isEditApiKeyLoading: false,
      isRegenerateApiKeyDialogOpen: false,
      isRegenerateApiKeyLoading: false,
      editName: '',
      apiKey: '',
      maxLength: 40,
      minLength: 3,
      isFormValid: false,
      isCopied: false,
      wasCopiedAtLeastOnce: false,
      menuItems: [
        {
          value: 'rename',
          label: this.t('rename'),
          image: '',
        },
        {
          value: 'regenerateApiKey',
          label: this.t('regenerate'),
          image: '',
        },
      ],
    } as {
      loading: boolean
      apiKeys: ApiKeyResponse[]
      currentItem?: ApiKeyResponse
      isEditApiKeyDialogOpen: boolean
      isEditApiKeyLoading: boolean
      isRegenerateApiKeyDialogOpen: boolean
      isRegenerateApiKeyLoading: boolean
      editName: string
      apiKey: string
      maxLength: number
      minLength: number
      isFormValid: boolean
      menuItems: {
        value: string
        label: string
        image: string
      }[]
      isCopied: boolean
      wasCopiedAtLeastOnce: boolean
    }
  },
  computed: {
    getDescription() {
      return this.t('table_description', {
        source:
          this.getIntegrationPlatformName(this.customIntegrationType as IntegrationPlatform) ?? '',
        documentationUrl: this.getDocumentationUrl(
          this.customIntegrationType as CustomIntegrationType,
        ),
      })
    },
    getAlertText() {
      return this.t('alert', {
        source:
          this.getIntegrationPlatformName(this.customIntegrationType as IntegrationPlatform) || '',
      })
    },
    getRegenerateKeyDescription() {
      return this.t('regenerate_key_description', {
        source:
          this.getIntegrationPlatformName(this.customIntegrationType as IntegrationPlatform) || '',
      })
    },
  },
  async created() {
    await this.loadApiKeys()
  },
  methods: {
    t(key: string, params?: { [key: string]: string }) {
      return this.$t(`EditSimplifiedCustomIntegration.${key}`, params || {})
    },
    async loadApiKeys() {
      this.loading = true
      try {
        const { data } = await getApiKeys()
        this.apiKeys = data.filter(
          (key) =>
            key.subType === this.getApiKeyType(this.customIntegrationType as CustomIntegrationType),
        )
      } catch (e) {
        this.$store.dispatch('notification/notify', {
          text: 'An unexpected error occurred. Please try again or get in touch.',
          isError: true,
        } as Notification)
      } finally {
        this.loading = false
      }
    },
    async generateApiKey(name: string) {
      this.loading = true
      try {
        const { data } = await generateApiKey(
          name,
          this.getApiKeyType(this.customIntegrationType as CustomIntegrationType),
        )
        this.apiKey = data.apiKey
      } catch (e) {
        console.error(e)
        this.$store.dispatch('notification/notify', {
          text: this.$t('CommonUi.error_generic'),
          isError: true,
          isClosable: true,
          buttonText: 'close',
        } as Notification)
      }
      this.loading = false
    },
    dateFormatFilter: function (value, userLocale) {
      if (!value) return ''
      value = value.toString()
      return new Date(value).toLocaleDateString(userLocale || 'en')
    },
    visibleKeyFilter: function (value) {
      if (!value) return ''
      value = value.toString()
      return value + '••••••••••••••••••••••••••••••••••••••'
    },
    apiKeyTypeFilter: function (value) {
      return this.getCustomIntegrationTypeByApiKeyType(value)
    },
    async quickMenuClick(value: string, item: ApiKeyResponse) {
      switch (value) {
        case 'rename':
          this.currentItem = item
          this.editName = item.name
          this.isEditApiKeyDialogOpen = true
          break
        case 'regenerateApiKey':
          this.currentItem = item
          await this.generateApiKey(this.apiKeys[0].name)
          this.isRegenerateApiKeyDialogOpen = true
          break
      }
    },
    async editApiKey() {
      this.isEditApiKeyLoading = true
      try {
        await updateApiKey(this.editName, this.currentItem?._id)
        await this.loadApiKeys()
        this.isEditApiKeyDialogOpen = false
        this.editName = ''
      } catch (e) {
        this.$store.dispatch('notification/notify', {
          text: 'An unexpected error occurred. Please try again or get in touch.',
          isError: true,
        } as Notification)
      } finally {
        this.isEditApiKeyLoading = false
      }
    },
    copy() {
      navigator.clipboard.writeText(this.apiKey ?? '').then(() => {
        this.isCopied = true
        setTimeout(() => {
          this.isCopied = false
        }, 2000)
      })
      if (!this.wasCopiedAtLeastOnce) this.wasCopiedAtLeastOnce = true
    },
  },
  props: {
    customIntegrationType: {
      type: String,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.edit-custom-integration {
  padding-top: 32px;
}

.section {
  padding: 15px 15px 15px 0;
}

.border-bottom {
  border-bottom: 2px solid #c5c5c5;
}

.border-top {
  border-top: 2px solid #c5c5c5;
}

.integration-name-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.label {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin: 0;
  width: 100%;
}

.input-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
  width: 100%;
}

.alert-text {
  font-size: 20px;
  font-weight: 400;
  line-height: 140%;
  color: var(--red);
  margin-bottom: 24px;
}

.description {
  font-weight: 400;
  font-size: 18px;
  line-height: normal;
}

.regenerate-description {
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 4px;
}

.v-card-text {
  padding: 0;
}

.label-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  gap: 10px;
}

.button-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px 0;
}

.custom-input {
  max-width: 340px;
}

.keys.table {
  border: 1px solid #bbbbbb;
  border-radius: 10px;
}

.keys.table th {
  padding: 8px 16px;
  background: none !important;
}

.apy-key-button {
  padding: 5px;
  border-radius: 5px;
  border: 2px solid var(--ui-dark-gray);
  color: var(--ui-dark-gray);
  height: 56px;
  width: 56px;
  flex-shrink: 0;
}

.card {
  position: relative;
  padding: 24px;
}

.close-icon {
  color: var(--font-color-primary);
}

.close-button {
  position: absolute;
  right: 24px;
  top: 2rpx;
  height: 20px;
  width: 20px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-header {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  padding-right: 40px;
}

.popup-header-regenerate {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  padding-right: 40px;
  margin-bottom: 24px;
}

.rename-description {
  margin-bottom: 4px;
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .section {
    padding: 24px 18px 24px 0;
  }
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
}
</style>
