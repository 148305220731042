import type {
  FamilySubscriptionType,
  NEW_PAID_BUSINESS_SUBSCRIPTION_TYPES,
  PERSONAL_SUBSCRIPTION_TYPES,
  TECHNICAL_AND_BUSINESS_SUBSCRIPTION_TYPES,
} from '@/helpers/constants'
import store from '@/store'
import type { Currency, CurrencyCode, Emptyable } from '@/helpers/interfaces'

export type PersonalSubscriptionType = (typeof PERSONAL_SUBSCRIPTION_TYPES)[number]
export type TechnicalAndBusinessSubscriptionType =
  (typeof TECHNICAL_AND_BUSINESS_SUBSCRIPTION_TYPES)[number]
export type NewPaidBusinessSubscriptionType = (typeof NEW_PAID_BUSINESS_SUBSCRIPTION_TYPES)[number]
export type SubscriptionType =
  | TechnicalAndBusinessSubscriptionType
  | PersonalSubscriptionType
  | FamilySubscriptionType

export const currencySymbols: { [key in CurrencyCode]: string } = {
  USD: '$',
  EUR: '€',
  GBP: '£',
  CAD: 'CA$',
  HKD: 'HK$',
  AUD: 'AUD$',
}

export const getCurrencySymbol = (currencyCode: CurrencyCode): string => {
  return (0)
    .toLocaleString('en-En', {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim()
}

export const formatCurrencyValueForDisplay = (value: number): string => {
  if (value % 1 !== 0) {
    return value.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 3,
    })
  }

  return value.toFixed(0)
}

export const getPlasticHeroPricePersonal = (currency: Currency): number => {
  switch (currency) {
    case 'euro':
      return 11.5
    case 'pound':
      return 9.2
    case 'dollar':
      return 12.5
    default:
      return 0
  }
}

export const getEarthHeroPrice = (currency: Currency): number => {
  switch (currency) {
    case 'euro':
      return 23.0
    case 'pound':
      return 18.6
    case 'dollar':
      return 25.0
    default:
      return 0
  }
}

export const getPersonalPrice = (currency: Currency): number => {
  switch (currency) {
    case 'euro':
      return 6.0
    case 'pound':
      return 4.8
    case 'dollar':
      return 6.5
    default:
      return 0
  }
}

export const getEcommercePrice = (currency: Currency) => {
  switch (currency) {
    case 'euro':
      return 5.0
    case 'pound':
      return 4.0
    case 'dollar':
      return 6.0
    default:
      return 0
  }
}

export const getStarterPrice = (currency: Currency, isYearly = false): number => {
  switch (currency) {
    case 'euro':
      return isYearly ? 7.0 : 9.0
    case 'pound':
      return isYearly ? 7.0 : 9.0
    case 'dollar':
      return isYearly ? 7.0 : 9.0
    default:
      return 0
  }
}

export const getGrowthPrice = (currency: Currency, isYearly = false) => {
  switch (currency) {
    case 'euro':
      return isYearly ? 29.0 : 39.0
    case 'pound':
      return isYearly ? 29.0 : 39.0
    case 'dollar':
      return isYearly ? 29.0 : 39.0
    default:
      return 0
  }
}

export const getPremiumPlanPrice = (currency: Currency, isYearly = false) => {
  switch (currency) {
    case 'euro':
      return isYearly ? 79.0 : 99.0
    case 'pound':
      return isYearly ? 79.0 : 99.0
    case 'dollar':
      return isYearly ? 79.0 : 99.0
    default:
      return 0
  }
}

export const getPlanPrice = (
  planType: PersonalSubscriptionType | TechnicalAndBusinessSubscriptionType,
  currency: Currency,
) => {
  switch (planType) {
    case 'ecommerce':
    case 'ECOMMERCE':
      return getEcommercePrice(currency)
    case 'earthHero':
      return getEarthHeroPrice(currency)
    case 'personal':
      return getPersonalPrice(currency)
    case 'plasticHeroPersonal':
      return getPlasticHeroPricePersonal(currency)
    case 'growthBusiness':
      return getGrowthPrice(currency)
    case 'starterBusiness':
      return getStarterPrice(currency)
    case 'premiumBusiness':
      return getPremiumPlanPrice(currency)
    case 'starterBusinessYearly':
      return getStarterPrice(currency, true)
    case 'growthBusinessYearly':
      return getGrowthPrice(currency, true)
    case 'premiumBusinessYearly':
      return getPremiumPlanPrice(currency, true)
    case 'enterpriseBusiness':
      throw new Error('Enterprise plan has a custom price')
    case 'free':
    case 'zero':
    case 'freeBusiness':
    case 'businessLegacy':
      return 0
    default:
      throw new Error('exhaustiveCheck@getPlanPrice')
  }
}

export const isProductUpgrade = (
  fromProduct: string,
  toProduct: string,
): 'downgrade' | 'current' | 'upgrade' => {
  const subscriptionsOrder: Record<TechnicalAndBusinessSubscriptionType, number> = {
    ECOMMERCE: store.getters.getIntegrationsAmount <= 3 ? 3 : 6,
    ecommerce: store.getters.getIntegrationsAmount <= 3 ? 3 : 6,
    businessLegacy: store.getters.getIntegrationsAmount <= 3 ? 3 : 6,
    free: 0,
    freeBusiness: 0,
    zero: 0,
    starterBusiness: 1,
    starterBusinessYearly: 2,
    growthBusiness: 4,
    growthBusinessYearly: 5,
    premiumBusiness: 7,
    premiumBusinessYearly: 8,
    enterpriseBusiness: 9,
  }
  if ((subscriptionsOrder[fromProduct] || 0) === subscriptionsOrder[toProduct]) return 'current'
  if ((subscriptionsOrder[fromProduct] || 0) > subscriptionsOrder[toProduct]) return 'downgrade'
  return 'upgrade'
}

export const getCurrencyCode = (currency: Emptyable<Currency>): CurrencyCode => {
  switch (currency) {
    case 'euro':
      return 'EUR'
    case 'pound':
      return 'GBP'
    case 'dollar':
      return 'USD'
    case '':
      throw new Error('Currency is empty')
    default: {
      const exhaustiveCheck: never = currency
      throw new Error(exhaustiveCheck)
    }
  }
}
