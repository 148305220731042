import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "label-wrapper" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 0,
  class: "progress-wrapper"
}
const _hoisted_5 = { class: "progress-percent" }
const _hoisted_6 = { class: "progress-bar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass([
      'floating-button',
      { 'is-open': _ctx.isChecklistOpen },
      { 'is-mobile': _ctx.isMobile },
      `theme-${_ctx.theme}`,
      { progress: _ctx.hasChecklistBeenOpened && !_ctx.isChecklistOpen },
    ]),
    disabled: _ctx.loading,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openChecklist()))
  }, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_v_progress_circular, {
          key: 0,
          id: "loader",
          size: 26,
          indeterminate: "",
          color: _ctx.color
        }, null, 8, ["color"]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("span", _hoisted_2, [
            _createVNode(_component_v_icon, {
              style: {"color":"inherit"},
              size: "md"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.buttonIcon), 1)
              ]),
              _: 1
            }),
            (!_ctx.isChecklistOpen)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Setup checklist"))
              : _createCommentVNode("", true)
          ]),
          (_ctx.hasChecklistBeenOpened && !_ctx.isChecklistOpen)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.getProgressPercent + '%'), 1),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", {
                    class: "progress-value",
                    style: _normalizeStyle(`width: ${_ctx.getProgressPercent}%`)
                  }, null, 4)
                ])
              ]))
            : _createCommentVNode("", true)
        ], 64))
  ], 10, _hoisted_1))
}