import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createElementBlock("button", {
    class: "icon-wrapper",
    disabled: _ctx.disabled,
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.shareClick && _ctx.shareClick(...args)))
  }, [
    _createVNode(_component_v_icon, { class: "share-icon" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString('mdi-' + _ctx.platform), 1)
      ]),
      _: 1
    })
  ], 8, _hoisted_1))
}