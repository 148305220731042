import { defineComponent } from 'vue'
import i18n from '@/i18n'
import type { AutomationTrigger, Currency, CurrencyCode, OffsetType } from '@/helpers/interfaces'
import { CURRENCY_MODIFIER_TRIGGERS, PERCENTAGE_TRIGGERS } from '@/helpers/interfaces'
import type { TranslateResult } from 'vue-i18n'
import type { CustomIntegrationTriggerType } from '@api/index'
import { getCountryCodeByIP } from '@api/index'
import { Decimal } from 'decimal.js'
import { includes } from '@/helpers/parsers'

export const Utils = defineComponent({
  data() {
    return {
      windowWidth: 0,
    }
  },
  computed: {
    isTouch() {
      return matchMedia('(hover: none), (pointer: coarse)').matches
    },
    isMobile(): boolean {
      return this.$vuetify.display.xs
    },
    isMobileLarge(): boolean {
      return this.$vuetify.display.sm
    },
    isTablet(): boolean {
      return this.$vuetify.display.md
    },
    isDesktop(): boolean {
      return this.$vuetify.display.lg
    },
    isDesktopLarge(): boolean {
      return this.$vuetify.display.xlAndUp
    },
    euroCountries(): string[] {
      return [
        'AT', // Austria
        'BE', // Belgium
        'HR', // Croatia
        'CY', // Cyprus
        'EE', // Estonia
        'FI', // Finland
        'FR', // France
        'DE', // Germany
        'GR', // Greece
        'IE', // Ireland
        'IT', // Italy
        'LV', // Latvia
        'LT', // Lithuania
        'LU', // Luxembourg
        'MT', // Malta
        'NL', // Netherlands
        'PT', // Portugal
        'SK', // Slovakia
        'SI', // Slovenia
        'ES', // Spain
      ]
    },
    euCountries(): string[] {
      return [
        'AT',
        'BE',
        'BG',
        'CY',
        'CZ',
        'DK',
        'DE',
        'EE',
        'FI',
        'FR',
        'GR',
        'IE',
        'IT',
        'LV',
        'LT',
        'LU',
        'MT',
        'NL',
        'PT',
        'SK',
        'ES',
        'HR',
        'HU',
        'PL',
        'RO',
        'SI',
        'SE',
      ]
    },
    poundCountries(): string[] {
      return [
        'GB', // United Kingdom
      ]
    },
    userLocale(): string {
      return this.$store.getters['getUserLocale']
    },
    getUserCurrencyCode(): CurrencyCode {
      return this.$store.getters['getUserCurrencyCode']
    },
  },
  created() {
    window.addEventListener('resize', this.reportWindowSize)
    this.reportWindowSize()
  },
  unmounted() {
    window.removeEventListener('resize', this.reportWindowSize)
  },
  methods: {
    tUtils(key: string, params?: { [key: string]: string | number }) {
      return this.$t(`UtilsMixin.${key}`, params ?? {})
    },
    formatTotalAmounts(value: number, suffix?: string): string {
      const locale = this.userLocale || 'en-EN'

      if (value < 100) {
        const amount = new Decimal(value).toDecimalPlaces(2, Decimal.ROUND_FLOOR).toNumber()
        return new Intl.NumberFormat(locale).format(amount) + ' ' + (suffix || '')
      } else if (value < 10 ** 6) {
        const amount = new Decimal(value).toDecimalPlaces(0).toNumber()
        return new Intl.NumberFormat(locale).format(amount) + ' ' + (suffix || '')
      } else if (value < 10 ** 9) {
        const amount = new Decimal(value)
          .round()
          .dividedBy(10 ** 6)
          .toDecimalPlaces(2)
          .toNumber()
        return (
          new Intl.NumberFormat(locale).format(amount) +
          ' ' +
          i18n.global.t('ComparisonCardPublic.million') +
          ' ' +
          (suffix || '')
        )
      } else {
        const amount = new Decimal(value)
          .round()
          .dividedBy(10 ** 9)
          .toDecimalPlaces(2)
          .toNumber()
        return (
          new Intl.NumberFormat(locale).format(amount) +
          ' ' +
          i18n.global.t('ComparisonCardPublic.billion') +
          ' ' +
          (suffix || '')
        )
      }
    },
    formatTotalAmountsV2(value: number, suffix?: string): string {
      const locale = this.userLocale || 'en-EN'

      if (value < 100) {
        const amount = new Decimal(value).toDecimalPlaces(3, Decimal.ROUND_FLOOR).toNumber()
        return new Intl.NumberFormat(locale).format(amount) + ' ' + (suffix || '')
      } else if (value < 10 ** 6) {
        const amount = new Decimal(value).toDecimalPlaces(0).toNumber()
        return new Intl.NumberFormat(locale).format(amount) + ' ' + (suffix || '')
      } else if (value < 10 ** 9) {
        const amount = new Decimal(value)
          .round()
          .dividedBy(10 ** 6)
          .toDecimalPlaces(3)
          .toNumber()
        return (
          new Intl.NumberFormat(locale).format(amount) +
          ' ' +
          i18n.global.t('ComparisonCardPublic.million')
        )
      } else {
        const amount = new Decimal(value)
          .round()
          .dividedBy(10 ** 9)
          .toDecimalPlaces(3)
          .toNumber()
        return (
          new Intl.NumberFormat(locale).format(amount) +
          ' ' +
          i18n.global.t('ComparisonCardPublic.billion')
        )
      }
    },
    formatNumberByLocale(value: number | null): string {
      if (value === null) {
        throw new Error('Value is null')
      }
      const locale = this.userLocale || 'en-EN'
      return new Intl.NumberFormat(locale).format(value)
    },
    formatDateToMonthYear(dateString: string): string {
      const locale = this.userLocale || 'en-EN'
      const date = new Date(dateString)
      return date.toLocaleString(locale, { month: 'long', year: 'numeric' })
    },
    snakeCase(string: string) {
      return string
        .split('')
        .map((letter, idx) => {
          if (letter === ' ') {
            return '_'
          } else if (isNaN(parseFloat(letter)) && letter.toUpperCase() === letter) {
            return `${idx !== 0 ? '_' : ''}${letter.toLowerCase()}`
          } else {
            return letter
          }
        })
        .join('')
    },
    capitalizeFirstLetter(string: string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    replaceValuesInTripleCurlyBrackets(
      string: string,
      replaceVariables: string[],
      replaceValues: string[],
    ): string {
      let returnValue = string
      replaceVariables.forEach((e, i) => {
        const match = new RegExp('{{{(?:\\s+)?(' + e + ')(?:\\s+)?}}}', 'gi')
        returnValue = returnValue.replace(match, replaceValues[i])
      })
      return returnValue
    },
    formatWhiteSpacesToLineBreak(string: string): string {
      let returnValue = string
      returnValue = string.replace(new RegExp(/\r?\n/, 'g'), '<br />')
      return returnValue
    },
    formatLineBreaksToWhiteSpace(string: string) {
      let returnValue = string
      returnValue = string.replace('<br />', '\n')
      return returnValue
    },
    getSettingsByPlanName(planType) {
      switch (planType) {
        case 'starterBusiness':
        case 'starterBusinessYearly':
          return {
            maxActiveIntegrationSources: 1,
            maxCustomDomainAmount: 0,
            maxCustomEmailTemplateAmount: 0,
            advancedAnalytics: false,
            advancedAutomations: false,
            dataAPI: false,
            impactWallet: false,
            partnerRewards: false,
            purchaseAndAutomationsApi: false,
            qrCode: false,
            maxTeamMemberCount: 1,
          }
        case 'growthBusiness':
        case 'growthBusinessYearly':
          return {
            maxActiveIntegrationSources: 3,
            maxCustomDomainAmount: 1,
            maxCustomEmailTemplateAmount: 1,
            advancedAnalytics: true,
            advancedAutomations: true,
            dataAPI: false,
            impactWallet: true,
            partnerRewards: true,
            purchaseAndAutomationsApi: false,
            qrCode: true,
            maxTeamMemberCount: 3,
          }
        case 'premiumBusiness':
        case 'premiumBusinessYearly':
          return {
            maxActiveIntegrationSources: 'Unlimited',
            maxCustomDomainAmount: 'Unlimited',
            maxCustomEmailTemplateAmount: 'Unlimited',
            advancedAnalytics: true,
            advancedAutomations: true,
            dataAPI: true,
            impactWallet: true,
            partnerRewards: true,
            purchaseAndAutomationsApi: true,
            qrCode: true,
            maxTeamMemberCount: 8,
          }
        case 'enterpriseBusiness':
          return {
            maxActiveIntegrationSources: 'Unlimited',
            maxCustomDomainAmount: 'Unlimited',
            maxCustomEmailTemplateAmount: 'Unlimited',
            advancedAnalytics: true,
            advancedAutomations: true,
            dataAPI: true,
            impactWallet: true,
            partnerRewards: true,
            purchaseAndAutomationsApi: true,
            qrCode: true,
            maxTeamMemberCount: 'Unlimited',
          }
        case 'ecommerce':
        case 'ECOMMERCE':
        case 'businessLegacy':
          return {
            maxActiveIntegrationSources: 'Unlimited',
            maxCustomDomainAmount: 0,
            maxCustomEmailTemplateAmount: 0,
            advancedAnalytics: false,
            advancedAutomations: true,
            dataAPI: false,
            impactWallet: true,
            partnerRewards: true,
            purchaseAndAutomationsApi: false,
            qrCode: true,
            maxTeamMemberCount: 1,
          }
        default:
          return {
            maxActiveIntegrationSources: 'Unlimited',
            maxCustomDomainAmount: 0,
            maxCustomEmailTemplateAmount: 0,
            advancedAnalytics: false,
            advancedAutomations: true,
            dataAPI: false,
            impactWallet: true,
            partnerRewards: true,
            purchaseAndAutomationsApi: true,
            qrCode: true,
            maxTeamMemberCount: 1,
          }
      }
    },
    reportWindowSize() {
      this.windowWidth = window.innerWidth
    },
    getCurrencySymbol(currencyCode: CurrencyCode): string {
      return (0)
        .toLocaleString('en-En', {
          style: 'currency',
          currency: currencyCode,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
        .replace(/\d/g, '')
        .trim()
    },
    formatPlanName(planType): string {
      switch (planType) {
        case 'starterBusiness':
        case 'starterBusinessYearly':
          return 'Starter'
        case 'growthBusiness':
        case 'growthBusinessYearly':
          return 'Growth'
        case 'premiumBusiness':
        case 'premiumBusinessYearly':
          return 'Premium'
        case 'enterpriseBusiness':
          return 'Enterprise'
        case 'ecommerce':
        case 'ECOMMERCE':
        case 'businessLegacy':
          return 'Ecommerce'
        case 'zero':
        case 'free':
        case 'freeBusiness':
          return 'Free'
        default:
          return ''
      }
    },
    getCurrencyByLocation(location: string): Currency {
      if (this.euroCountries.some((country) => country === location.toUpperCase())) return 'euro'
      if (this.poundCountries.some((country) => country === location.toUpperCase())) return 'pound'
      return 'dollar'
    },
    async getLocation(): Promise<string> {
      try {
        const countryCode = await getCountryCodeByIP()
        return countryCode || ''
      } catch (e) {
        console.error('getLocation', e)
        throw e
      }
    },
    isEUCountry(countryCode = ''): boolean {
      return this.euCountries.some((country) => country === countryCode.toUpperCase())
    },
    currencyFormatter(value: number, currencyCode?: CurrencyCode): string {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currencyCode || this.getUserCurrencyCode,
        minimumFractionDigits: 2,
        maximumFractionDigits: 15,
      })
      return formatter.format(value)
    },
    getCartText(
      impactSetting: { [key in OffsetType]: number },
      selectedTrigger: AutomationTrigger | CustomIntegrationTriggerType,
      additionalImpactSetting?: {
        amount?: number | null
        currencyCode?: CurrencyCode
        percentage?: number | null
      },
    ): string | TranslateResult {
      // if there is no value set
      if (Object.values(impactSetting).every((value) => !value)) return ''
      // if there is no spend level amount or percentage set
      else if (
        includes(CURRENCY_MODIFIER_TRIGGERS, selectedTrigger) &&
        additionalImpactSetting?.amount
      )
        return ''
      else if (
        includes(PERCENTAGE_TRIGGERS, selectedTrigger) &&
        !additionalImpactSetting?.percentage
      )
        return ''
      else {
        let impactText = ''
        // filter the object properties to the properties with values
        const impactSettingWithValues = Object.fromEntries(
          Object.entries(impactSetting).filter(([, value]) => !!value),
        )
        const lastItemIndex = Object.keys(impactSettingWithValues).length - 1
        Object.entries(impactSettingWithValues).forEach(([key, value], index) => {
          if (value && !index) {
            impactText += this.getTextForTriggerAndImpactType(
              selectedTrigger,
              key as OffsetType,
              value,
            )
          }
          // if case there are 2 impacts set, the text concatenates with 'and'
          else if (value && lastItemIndex === 1) {
            impactText +=
              ' and ' +
              this.getTextForTriggerAndImpactType(selectedTrigger, key as OffsetType, value)
          }
          // if case there are more than impacts set, the text concatenates with 'and' if the impact is not in the last position, and with a comma if it is in the last position
          else if (value && lastItemIndex > 1) {
            impactText +=
              (index === lastItemIndex ? ' and ' : ', ') +
              this.getTextForTriggerAndImpactType(selectedTrigger, key as OffsetType, value)
          }
        })
        const textValues = { amount: impactText, type: '' }
        // in case there are additional impact settings set up
        if (additionalImpactSetting?.amount && additionalImpactSetting?.currencyCode) {
          textValues.type =
            this.getCurrencySymbol(additionalImpactSetting?.currencyCode) +
            additionalImpactSetting?.amount
        } else if (additionalImpactSetting?.percentage) {
          textValues.type = additionalImpactSetting?.percentage + '%'
        }
        return this.capitalizeFirstLetter(
          this.tUtils(`${this.snakeCase(selectedTrigger)}.text`, textValues),
        )
      }
    },
    getTextForTriggerAndImpactType(
      trigger: AutomationTrigger | CustomIntegrationTriggerType,
      type: OffsetType,
      amount?: number,
    ): string {
      const numericAmounts: Record<OffsetType, string> = {
        trees: 'trees.amount_label',
        carbon: 'carbon.amount_label',
        plastic: 'plastic.amount_label',
        kelp: 'kelp.amount_label',
        water: 'water.amount_label',
        bees: 'bees.amount_label',
      }

      const offsetAmounts: Record<OffsetType, string> = {
        trees: 'trees.offset_amount',
        carbon: 'carbon.offset_amount',
        plastic: 'plastic.offset_amount',
        kelp: 'kelp.offset_amount',
        water: 'water.offset_amount',
        bees: 'bees.offset_amount',
      }

      const ongoingActions: Record<OffsetType, string> = {
        trees: 'trees.ongoing_action',
        carbon: 'carbon.ongoing_action',
        plastic: 'plastic.ongoing_action',
        kelp: 'kelp.ongoing_action',
        water: 'water.ongoing_action',
        bees: 'bees.ongoing_action',
      }

      switch (trigger) {
        case 'offsetBySpend':
        case 'offsetAllProducts':
        case 'offsetPerProduct':
        case 'offsetPerOrder':
        case 'perSelectedAnswer':
        case 'perCompletedForm':
        case 'perCompanyReview':
        case 'perProductReview':
        case 'perSmsSubscriber':
        case 'perSubscriber':
        case 'byLoyaltyPoints':
          return this.$t(numericAmounts[type], {
            amount,
            count: amount ?? 1,
          })

        case 'bySpendOfPaidInvoice':
        case 'perPaidInvoice':
        case 'offsetByStoreRevenue':
        case 'byTieredSpendOfPaidInvoice':
          return this.$t(offsetAmounts[type], { amount, count: amount ?? 1 })

        case 'offsetByPercentageOrder':
        case 'byPercentageOfPaidInvoice':
        case 'byPercentageOfRevenue':
          return this.$t(ongoingActions[type], amount ?? 1)
        default:
          throw new Error(trigger)
      }
    },
    deepCopy(obj: unknown): unknown {
      return JSON.parse(JSON.stringify(obj))
    },
    encodeForCSS(url: string) {
      return url.replace(/'/g, '%27')
    },
    isElementInViewport(element: HTMLElement | null): boolean {
      if (!element) return false
      const rect = element.getBoundingClientRect()
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      )
    },
    debounce(func: (...a: unknown[]) => unknown, delay: number) {
      let timeoutId: ReturnType<typeof setTimeout>

      return (...args: unknown[]) => {
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
          func(...args)
        }, delay)
      }
    },
    getCSSVariable(key: string) {
      const style = getComputedStyle(document.body)
      return style.getPropertyValue(`--${key}`)
    },
    shortenText(text: string, maxChars: number = 8): string {
      return text.substring(0, maxChars) + '..'
    },
    includes<T extends U, U>(coll: ReadonlyArray<T>, el: U): el is T {
      return coll.includes(el as T)
    },
    copyText(text: string) {
      if (!text) return
      navigator.clipboard.writeText(text)
    },
    desanitizeHTML(str: string): string {
      return str.replace(/&lt;|&gt;|&quot;|&#039;|&amp;/g, (entity) => {
        return (
          {
            '&lt;': '<',
            '&gt;': '>',
            '&quot;': '"',
            '&#039;': "'",
            '&amp;': '&',
          }[entity] || entity
        )
      })
    },
  },
})
