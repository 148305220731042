import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "dialog-wrapper"
}
const _hoisted_2 = { class: "close-wrapper" }
const _hoisted_3 = { class: "dialog-title" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainTable = _resolveComponent("MainTable")!
  const _component_ReferralEmpty = _resolveComponent("ReferralEmpty")!
  const _component_LoadingOpaque = _resolveComponent("LoadingOpaque")!

  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t('history')), 1),
            _createElementVNode("img", {
              style: {"width":"25px"},
              class: "close-icon",
              src: require('@/assets/icons/close_icon.svg'),
              alt: "close",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('dialogClick')))
            }, null, 8, _hoisted_4)
          ]),
          (_ctx.rowItems.length > 0)
            ? (_openBlock(), _createBlock(_component_MainTable, {
                key: 0,
                "menu-items": _ctx.menuItems,
                rows: _ctx.rowItems,
                "column-width": _ctx.columnWidth,
                "footer-items": _ctx.footerItems,
                "hide-partner-image": true,
                "hide-search": true,
                "table-loading": _ctx.tableLoading,
                "active-page": _ctx.activePage,
                onEmitSort: _ctx.sort,
                onFooterClick: _ctx.footerClick,
                onArrowClick: _ctx.arrowClick
              }, null, 8, ["menu-items", "rows", "column-width", "footer-items", "table-loading", "active-page", "onEmitSort", "onFooterClick", "onArrowClick"]))
            : _createCommentVNode("", true),
          (_ctx.rowItems.length < 1)
            ? (_openBlock(), _createBlock(_component_ReferralEmpty, {
                key: 1,
                text: _ctx.t('empty'),
                text1: _ctx.t('empty1')
              }, null, 8, ["text", "text1"]))
            : _createCommentVNode("", true),
          (!_ctx.loaded)
            ? (_openBlock(), _createBlock(_component_LoadingOpaque, { key: 2 }))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}