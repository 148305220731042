import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "warning-dialog" }
const _hoisted_2 = { class: "warning-text" }
const _hoisted_3 = { class: "action-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_gs_button = _resolveComponent("gs-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_icon, { class: "warning-icon" }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode(" mdi-alert-outline ")
      ])),
      _: 1
    }),
    _createElementVNode("h2", _hoisted_2, [
      _createElementVNode("span", null, _toDisplayString(_ctx.t('warning_text', { apiKeyName: _ctx.apiKeyName })), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_gs_button, {
        type: "danger",
        size: "large",
        width: "150",
        onClick: _ctx.generateApiKey
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('accept')), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_gs_button, {
        type: "outlined",
        size: "large",
        onClick: _ctx.closePopup
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('cancel')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}