import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "content-wrapper" }
const _hoisted_3 = { class: "page-title" }
const _hoisted_4 = { class: "subtitle" }
const _hoisted_5 = { class: "section-title" }
const _hoisted_6 = { class: "section-title" }
const _hoisted_7 = { class: "coming-soon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_SettingCard = _resolveComponent("SettingCard")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_gs_alert_bar = _resolveComponent("gs-alert-bar")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!
  const _component_IntroSettingDialog = _resolveComponent("IntroSettingDialog")!
  const _component_TargetGoalSettingDialog = _resolveComponent("TargetGoalSettingDialog")!
  const _component_Certificate = _resolveComponent("Certificate")!
  const _component_CustomDomain = _resolveComponent("CustomDomain")!
  const _component_NotificationTemplates = _resolveComponent("NotificationTemplates")!
  const _component_full_width_popup = _resolveComponent("full-width-popup")!
  const _component_panel = _resolveComponent("panel")!
  const _component_call_to_upgrade_popup = _resolveComponent("call-to-upgrade-popup")!
  const _component_loading_opaque = _resolveComponent("loading-opaque")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("button", {
      class: "back",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.navigateBack && _ctx.navigateBack(...args)))
    }, [
      _createElementVNode("img", {
        class: "chevron",
        alt: "chevron",
        src: require('@/assets/icons/chevron_left.svg')
      }, null, 8, _hoisted_1),
      _createElementVNode("div", null, _toDisplayString(_ctx.$t('CommonUi.back')), 1)
    ]),
    _createVNode(_component_panel, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.t('title')), 1),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.t('subtitle')), 1),
          _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.t('manage_campaign.title')), 1),
          _createVNode(_component_v_expansion_panels, {
            modelValue: _ctx.selectedCampaignPlatform,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedCampaignPlatform) = $event)),
            class: "platform-accordion",
            flat: "",
            "selected-class": "active"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_expansion_panel, { value: "email_platform" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_expansion_panel_title, null, {
                    default: _withCtx(() => [
                      _createElementVNode("h3", null, _toDisplayString(_ctx.t('manage_campaign.email_platform')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_expansion_panel_text, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_SettingCard, {
                        "button-text": 
                  _ctx.getActiveEmailSyncAmount > 0
                    ? `${_ctx.$t('CommonUi.edit')}`
                    : `${_ctx.$t('CommonUi.manage')}`
                ,
                        "is-active": _ctx.getActiveEmailSyncAmount > 0,
                        "active-amount": _ctx.getActiveEmailSyncAmount,
                        onButtonClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openSyncDialog()))
                      }, {
                        title: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('email_sync_title')), 1)
                        ]),
                        desc: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('email_sync_text')), 1)
                        ]),
                        _: 1
                      }, 8, ["button-text", "is-active", "active-amount"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_expansion_panel, { value: "greenspark" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_expansion_panel_title, null, {
                    default: _withCtx(() => [
                      _createElementVNode("h3", null, _toDisplayString(_ctx.t('manage_campaign.greenspark')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_expansion_panel_text, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_SettingCard, {
                        "button-text": 
                  !!_ctx.customDomainAmount ? `${_ctx.$t('CommonUi.edit')}` : `${_ctx.$t('CommonUi.manage')}`
                ,
                        "has-error": _ctx.isCustomDomainInvalid,
                        "is-required": !_ctx.customDomainAmount,
                        "is-active": !!_ctx.customDomainAmount && !_ctx.isCustomDomainInvalid,
                        "active-amount": _ctx.customDomainAmount,
                        onButtonClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openPopup('custom_domain')))
                      }, _createSlots({
                        title: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('custom_domain_title')), 1)
                        ]),
                        desc: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('custom_domain_text')), 1)
                        ]),
                        _: 2
                      }, [
                        (_ctx.isCustomDomainInvalid)
                          ? {
                              name: "alert",
                              fn: _withCtx(() => [
                                _createVNode(_component_gs_alert_bar, {
                                  message: _ctx.t('custom_domain_alert.not_valid'),
                                  "full-width": "",
                                  type: "warning"
                                }, null, 8, ["message"])
                              ]),
                              key: "0"
                            }
                          : undefined
                      ]), 1032, ["button-text", "has-error", "is-required", "is-active", "active-amount"]),
                      _createVNode(_component_SettingCard, {
                        "button-text": 
                  !!_ctx.activeEmailTemplateAmount
                    ? `${_ctx.$t('CommonUi.edit')}`
                    : `${_ctx.$t('CommonUi.manage')}`
                ,
                        "is-active": !!_ctx.activeEmailTemplateAmount,
                        "active-amount": _ctx.activeEmailTemplateAmount,
                        onButtonClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openPopup('notification_template')))
                      }, {
                        alert: _withCtx(() => [
                          (!_ctx.customDomainAmount && !_ctx.activeEmailTemplateAmount)
                            ? (_openBlock(), _createBlock(_component_gs_alert_bar, {
                                key: 0,
                                message: _ctx.t('custom_domain_alert.activate_email_campaigns'),
                                "full-width": "",
                                type: "info"
                              }, null, 8, ["message"]))
                            : _createCommentVNode("", true)
                        ]),
                        title: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('notification_template_title')), 1)
                        ]),
                        desc: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('notification_template_text')), 1)
                        ]),
                        _: 1
                      }, 8, ["button-text", "is-active", "active-amount"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.t('impact_wallet')), 1),
          (!_ctx.account.imageURL)
            ? (_openBlock(), _createBlock(_component_SettingCard, {
                key: 0,
                "button-text": `${_ctx.t('upload_logo')}`,
                onButtonClick: _ctx.uploadLogo
              }, {
                title: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('upload_logo_title')), 1)
                ]),
                desc: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('upload_logo_text')), 1)
                ]),
                _: 1
              }, 8, ["button-text", "onButtonClick"]))
            : _createCommentVNode("", true),
          _createVNode(_component_SettingCard, {
            "button-text": 
            !!_ctx.account.profileDescription ? `${_ctx.$t('CommonUi.edit')}` : `${_ctx.$t('CommonUi.add')}`
          ,
            "is-active": !!_ctx.account.profileDescription,
            onButtonClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.openPopup('intro')))
          }, {
            title: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('intro_title')), 1)
            ]),
            desc: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('set_intro_text')), 1)
            ]),
            _: 1
          }, 8, ["button-text", "is-active"]),
          _createVNode(_component_SettingCard, {
            "button-text": 
            !!_ctx.sustainabilityGoals.length ? `${_ctx.$t('CommonUi.edit')}` : `${_ctx.t('set_targets')}`
          ,
            "is-active": !!_ctx.sustainabilityGoals.length,
            "active-amount": _ctx.sustainabilityGoals.length,
            onButtonClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.openPopup('target_goal')))
          }, {
            title: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('target_goal_title')), 1)
            ]),
            desc: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('set_target_goal_text')), 1)
            ]),
            _: 1
          }, 8, ["button-text", "is-active", "active-amount"]),
          _createVNode(_component_SettingCard, null, {
            title: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('reengage_title')), 1)
            ]),
            desc: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('reengage_text')), 1)
            ]),
            button: _withCtx(() => [
              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.t('coming_soon')), 1)
            ]),
            _: 1
          })
        ]),
        _createVNode(_component_full_width_popup, {
          ref: "popup",
          "popup-title": _ctx.popupTitle
        }, {
          default: _withCtx(() => [
            (_ctx.activeContent === 'intro')
              ? (_openBlock(), _createBlock(_component_IntroSettingDialog, { key: 0 }))
              : _createCommentVNode("", true),
            (_ctx.activeContent === 'target_goal')
              ? (_openBlock(), _createBlock(_component_TargetGoalSettingDialog, { key: 1 }))
              : _createCommentVNode("", true),
            (_ctx.activeContent === 'certificate')
              ? (_openBlock(), _createBlock(_component_Certificate, { key: 2 }))
              : _createCommentVNode("", true),
            (_ctx.activeContent === 'custom_domain')
              ? (_openBlock(), _createBlock(_component_CustomDomain, { key: 3 }))
              : _createCommentVNode("", true),
            (_ctx.activeContent === 'notification_template')
              ? (_openBlock(), _createBlock(_component_NotificationTemplates, {
                  key: 4,
                  onOpenCustomDomains: _cache[7] || (_cache[7] = ($event: any) => (_ctx.activeContent = 'custom_domain'))
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["popup-title"]),
        (_ctx.getIfAtLeastOneAutomationActive)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "animated-link view-certificate",
              onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.openPopup('certificate')))
            }, _toDisplayString(_ctx.t('certificate_button')), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_ctx.isCallToUpgradePopupOpen)
      ? (_openBlock(), _createBlock(_component_call_to_upgrade_popup, {
          key: 0,
          "is-premium-only": false,
          onClose: _cache[9] || (_cache[9] = ($event: any) => (_ctx.isCallToUpgradePopupOpen = false))
        }))
      : _createCommentVNode("", true),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_loading_opaque, { key: 1 }))
      : _createCommentVNode("", true)
  ]))
}