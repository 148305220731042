import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "kelp-comparison-container" }
const _hoisted_2 = { class: "comparison-data" }
const _hoisted_3 = { class: "comparison-amount" }
const _hoisted_4 = { class: "info-text" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "comparison-icon-wrapper" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "comparison-icon-wrapper" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "comparison-data" }
const _hoisted_11 = { class: "comparison-amount" }
const _hoisted_12 = { class: "info-text" }
const _hoisted_13 = ["href"]
const _hoisted_14 = { class: "comparison-data" }
const _hoisted_15 = { class: "comparison-amount" }
const _hoisted_16 = { class: "info-text" }
const _hoisted_17 = ["href"]
const _hoisted_18 = { class: "comparison-icon-wrapper" }
const _hoisted_19 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "carbon-sequestered comparison-card",
      style: _normalizeStyle({ 'background-color': _ctx.colorSettings.kelp })
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.formatTotalAmounts(
              _ctx.comparisons.carbonSequestered.amount,
              _ctx.comparisons.carbonSequestered.suffix,
            )), 1),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.comparisons.carbonSequestered.amountDescription), 1),
        _createElementVNode("a", {
          href: _ctx.comparisons.carbonSequestered.link,
          target: "_blank",
          class: "info-sub-text"
        }, _toDisplayString(_ctx.comparisons.carbonSequestered.description), 9, _hoisted_5)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("img", {
          id: "carbonSequesteredComparisonIcon",
          class: "comparison-icon",
          alt: "carbon_sequestered",
          src: require('@/assets/icons/comparisons/carbon_sequestered_kelp.svg')
        }, null, 8, _hoisted_7)
      ])
    ], 4),
    _createElementVNode("div", {
      class: "hockey-rinks comparison-card",
      style: _normalizeStyle({ 'background-color': _ctx.colorSettings.kelp })
    }, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("img", {
          id: "iceHockeyRinksComparisonIcon",
          class: "comparison-icon",
          alt: "babyWhales",
          src: require('@/assets/icons/comparisons/ice_hockey_rinks_v2.svg')
        }, null, 8, _hoisted_9)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.formatTotalAmounts(_ctx.comparisons.iceHockeyRinks.amount, _ctx.comparisons.iceHockeyRinks.suffix)), 1),
        _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.comparisons.iceHockeyRinks.amountDescription), 1),
        _createElementVNode("a", {
          href: _ctx.comparisons.iceHockeyRinks.link,
          target: "_blank",
          class: "info-sub-text"
        }, _toDisplayString(_ctx.comparisons.iceHockeyRinks.description), 9, _hoisted_13)
      ])
    ], 4),
    _createElementVNode("div", {
      class: "baby-whales comparison-card",
      style: _normalizeStyle({ 'background-color': _ctx.colorSettings.kelp })
    }, [
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.formatTotalAmounts(_ctx.comparisons.babyWhales.amount, _ctx.comparisons.babyWhales.suffix)), 1),
        _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.comparisons.babyWhales.amountDescription), 1),
        _createElementVNode("a", {
          href: _ctx.comparisons.babyWhales.link,
          target: "_blank",
          class: "info-sub-text"
        }, _toDisplayString(_ctx.comparisons.babyWhales.description), 9, _hoisted_17)
      ]),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("img", {
          id: "babyWhalesComparisonIcon",
          class: "comparison-icon",
          alt: "baby whales",
          src: require('@/assets/icons/comparisons/baby_whales_v2.svg')
        }, null, 8, _hoisted_19)
      ])
    ], 4)
  ]))
}