import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "email-data-sync-section"
}
const _hoisted_2 = { class: "email-data-sync-toggle__description" }
const _hoisted_3 = { class: "email-data-sync-toggle__disclaimer" }
const _hoisted_4 = { class: "email-data-sync-list" }
const _hoisted_5 = { class: "email-data-sync-list__title mb-4" }
const _hoisted_6 = { class: "email-data-sync-list__title" }
const _hoisted_7 = { class: "list" }
const _hoisted_8 = { class: "list__item-text" }
const _hoisted_9 = ["href"]
const _hoisted_10 = { class: "email-data-sync-test" }
const _hoisted_11 = { class: "email-data-sync-test__title" }
const _hoisted_12 = { class: "email-data-sync-test__disclaimer" }
const _hoisted_13 = {
  key: 0,
  class: "list"
}
const _hoisted_14 = { class: "list__item-text" }
const _hoisted_15 = {
  key: 2,
  class: "timestamp"
}
const _hoisted_16 = {
  key: 0,
  class: "timestamp"
}
const _hoisted_17 = {
  key: 1,
  class: "success-alert"
}
const _hoisted_18 = {
  key: 2,
  class: "error-alert"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_onboarding_pill = _resolveComponent("onboarding-pill")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_onboarding_panel = _resolveComponent("onboarding-panel")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
    : (_ctx.selectedIntegration && _ctx.selectedIntegrationOptions?.writeDirection && !_ctx.loading)
      ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.navigateBack && _ctx.navigateBack(...args))),
            class: "close-button"
          }),
          _createVNode(_component_onboarding_panel, {
            class: "email-data-sync",
            title: _ctx.t('title', { emailProvider: _ctx.capitalizeFirstLetter(_ctx.selectedIntegration?.platform) }),
            description: _ctx.t('description'),
            "background-color": "transparent"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(["email-data-sync-toggle", {
          active: _ctx.syncData,
          error:
            _ctx.testConnection.status === 'error' &&
            _ctx.testConnection.errorCode === _ctx.INTEGRATION_WRITE_DIRECTION_TEST_FORBIDDEN,
        }])
              }, [
                _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.t('sync_toggle.description', {
              emailProvider: _ctx.capitalizeFirstLetter(_ctx.selectedIntegration?.platform),
            })), 1),
                _createVNode(_component_v_switch, {
                  modelValue: _ctx.syncData,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.syncData) = $event)),
                  onChange: _ctx.handleEmailSync,
                  class: "email-data-sync-toggle__switch",
                  label: _ctx.t('sync_toggle.title'),
                  "hide-details": "",
                  inset: "",
                  color: "green"
                }, null, 8, ["modelValue", "onChange", "label"]),
                _createElementVNode("small", _hoisted_3, _toDisplayString(_ctx.t('sync_toggle.disclaimer')), 1)
              ], 2),
              (
          _ctx.testConnection.status === 'error' &&
          _ctx.testConnection.errorCode === _ctx.INTEGRATION_WRITE_DIRECTION_TEST_FORBIDDEN
        )
                ? (_openBlock(), _createElementBlock("p", {
                    key: 0,
                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleIntegrationUpdate && _ctx.handleIntegrationUpdate(...args))),
                    class: "error-alert"
                  }, _toDisplayString(_ctx.t('test_connection.error.update_key')), 1))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.t('sync_list.title')), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.syncedProperties, (syncedProp, key) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "email-data-sync-list__options",
                    key: key
                  }, [
                    _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.t(`sync_list.${key}`)), 1),
                    _createElementVNode("ul", _hoisted_7, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(syncedProp, (item, index) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: index,
                          class: "list__item"
                        }, [
                          _createElementVNode("div", _hoisted_8, [
                            _createVNode(_component_v_icon, {
                              color: "light-green",
                              size: "18"
                            }, {
                              default: _withCtx(() => _cache[5] || (_cache[5] = [
                                _createTextVNode("mdi-check")
                              ])),
                              _: 1
                            }),
                            _createElementVNode("span", null, _toDisplayString(_ctx.t(`sync_list.fields.${item}`)), 1)
                          ]),
                          _createVNode(_component_onboarding_pill, { disabled: "" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]))
                      }), 128))
                    ])
                  ]))
                }), 128)),
                _createElementVNode("a", {
                  href: _ctx.selectedIntegrationOptions.writeDirection.documentationLink,
                  target: "_blank",
                  class: "email-data-sync-list__link"
                }, _toDisplayString(_ctx.t('sync_list.documentation_link')), 9, _hoisted_9)
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("h2", _hoisted_11, _toDisplayString(_ctx.t('test_connection.title')), 1),
                _createElementVNode("p", null, _toDisplayString(_ctx.t('test_connection.description', {
              emailProvider: _ctx.capitalizeFirstLetter(_ctx.selectedIntegration?.platform),
            })), 1),
                _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.t('test_connection.event.disclaimer', {
              emailProvider: _ctx.capitalizeFirstLetter(_ctx.selectedIntegration?.platform),
            })), 1),
                (_ctx.syncedTestData)
                  ? (_openBlock(), _createElementBlock("ul", _hoisted_13, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.syncedTestData, (value, key) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: key,
                          class: "list__item"
                        }, [
                          _createElementVNode("div", _hoisted_14, [
                            _createVNode(_component_v_icon, {
                              color: "green",
                              size: "18"
                            }, {
                              default: _withCtx(() => _cache[6] || (_cache[6] = [
                                _createTextVNode("mdi-check")
                              ])),
                              _: 1
                            }),
                            _createElementVNode("span", null, _toDisplayString(_ctx.t(`test_connection.event.${key}`, { value })), 1)
                          ])
                        ]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true),
                _createVNode(_component_gs_button, {
                  onClick: _ctx.handleTestConnection,
                  class: "mt-6",
                  type: "primary",
                  loading: _ctx.testConnection.loading,
                  disabled: _ctx.testConnection.loading,
                  size: "large",
                  "full-width": "",
                  capitalized: "",
                  uppercased: false
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('test_connection.status.default')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick", "loading", "disabled"]),
                (
            _ctx.testConnection.status === 'error' &&
            _ctx.testConnection.errorCode === _ctx.INTEGRATION_WRITE_DIRECTION_TEST_FORBIDDEN
          )
                  ? (_openBlock(), _createElementBlock("p", {
                      key: 1,
                      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleIntegrationUpdate && _ctx.handleIntegrationUpdate(...args))),
                      class: "error-alert"
                    }, _toDisplayString(_ctx.t('test_connection.error.update_key')), 1))
                  : _createCommentVNode("", true),
                (_ctx.testConnection.date)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_15, [
                      _createTextVNode(_toDisplayString(_ctx.t('test_connection.date', { date: _ctx.testConnectionTimestamp })) + ", ", 1),
                      (_ctx.testConnection.status)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createTextVNode(_toDisplayString(_ctx.t('test_connection.status.title')) + " ", 1),
                            _createElementVNode("span", {
                              class: _normalizeClass(`email-data-sync-test__connection--${_ctx.testConnection.status}`)
                            }, _toDisplayString(_ctx.testConnection.status === 'success'
                  ? _ctx.t('test_connection.status.success')
                  : _ctx.t('test_connection.status.error')), 3)
                          ], 64))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }, 8, ["title", "description"]),
          _createVNode(_component_onboarding_panel, {
            class: _normalizeClass(["email-data-sync-historical", { disabled: _ctx.hasOngoingHistoricalSync }]),
            title: _ctx.t('historical_data.title'),
            description: 
        _ctx.t('historical_data.description', {
          emailProvider: _ctx.capitalizeFirstLetter(_ctx.selectedIntegration?.platform),
        })
      ,
            "background-color": "transparent"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_checkbox, {
                modelValue: _ctx.syncHistory.confirm,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.syncHistory.confirm) = $event)),
                color: "green",
                "hide-details": "",
                label: _ctx.t('historical_data.confirm'),
                disabled: _ctx.hasOngoingHistoricalSync
              }, null, 8, ["modelValue", "label", "disabled"]),
              _createVNode(_component_gs_button, {
                class: "mt-2",
                type: "primary",
                size: "large",
                onClick: _ctx.handleSyncHistory,
                disabled: 
          _ctx.hasOngoingHistoricalSync || !_ctx.syncHistory.confirm || _ctx.syncHistory.loading || !_ctx.syncData
        ,
                "full-width": "",
                capitalized: "",
                uppercased: false
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('historical_data.sync')), 1)
                ]),
                _: 1
              }, 8, ["onClick", "disabled"]),
              (_ctx.syncHistory.date && !_ctx.syncHistory.loading)
                ? (_openBlock(), _createElementBlock("p", _hoisted_16, [
                    _createTextVNode(_toDisplayString(_ctx.t('historical_data.date', { date: _ctx.syncHistoryTimestamp })) + " ", 1),
                    (_ctx.syncHistory.count !== null)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createTextVNode(" , " + _toDisplayString(_ctx.t('historical_data.impact_actions')) + " " + _toDisplayString(_ctx.formatNumberByLocale(_ctx.syncHistory.count ?? 0)), 1)
                        ], 64))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              (
          _ctx.syncHistory.loading ||
          (!_ctx.syncHistory.date && _ctx.selectedIntegration.writeDirection?.syncHistory)
        )
                ? (_openBlock(), _createElementBlock("p", _hoisted_17, [
                    _createVNode(_component_v_icon, { color: "#47d18f" }, {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createTextVNode("mdi-check-circle")
                      ])),
                      _: 1
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.t('historical_data.in_progress')), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.syncHistory.errorCode)
                ? (_openBlock(), _createElementBlock("p", _hoisted_18, _toDisplayString(_ctx.t(`historical_data.error.${_ctx.syncHistory.errorCode}`)), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["class", "title", "description"])
        ]))
      : _createCommentVNode("", true)
}