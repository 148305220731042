import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "tab-header" }
const _hoisted_3 = { class: "ledger-container" }
const _hoisted_4 = { class: "header-wrapper" }
const _hoisted_5 = { class: "header" }
const _hoisted_6 = { class: "header" }
const _hoisted_7 = { class: "buttons-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_AddMonth = _resolveComponent("AddMonth")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_v_window = _resolveComponent("v-window")!

  return (!_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[7] || (_cache[7] = _createElementVNode("div", { class: "description" }, [
          _createElementVNode("div", { class: "description-header" }, "Update Ledger"),
          _createElementVNode("div", { class: "description-text" }, " Update the receipt ledger below. These are shown to all users in their dashboard. ")
        ], -1)),
        _createVNode(_component_gs_button, {
          class: "button-add",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addMonth = true))
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode(" Add Month ")
          ])),
          _: 1
        }),
        (_ctx.addMonth)
          ? (_openBlock(), _createBlock(_component_AddMonth, {
              key: 0,
              loading: _ctx.loading,
              onAddMonthToArray: _ctx.addMonthToArray
            }, null, 8, ["loading", "onAddMonthToArray"]))
          : _createCommentVNode("", true),
        _createVNode(_component_v_tabs, {
          modelValue: _ctx.tab,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tab) = $event)),
          "bg-color": "transparent",
          color: "#3B755F",
          grow: "",
          style: {"margin-bottom":"20px"}
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.OFFSET_TYPES(), (type) => {
              return (_openBlock(), _createBlock(_component_v_tab, {
                key: type,
                class: "tabs"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, _toDisplayString(type), 1)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_v_window, {
          modelValue: _ctx.tab,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.tab) = $event))
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.OFFSET_TYPES(), (impactType) => {
              return (_openBlock(), _createBlock(_component_v_window_item, {
                key: impactType,
                transition: "fade-transition"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_v_expansion_panels, {
                      variant: "accordion",
                      flat: ""
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items.filter(({ type }) => type === impactType), (item, a) => {
                          return (_openBlock(), _createBlock(_component_v_expansion_panel, { key: a }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_expansion_panel_title, null, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_4, [
                                    _createElementVNode("div", _hoisted_5, _toDisplayString(item.date), 1),
                                    _createElementVNode("div", _hoisted_6, _toDisplayString(item.type), 1)
                                  ])
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_v_expansion_panel_text, null, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_7, [
                                    _createVNode(_component_gs_button, {
                                      class: "button",
                                      type: "danger",
                                      onClick: ($event: any) => (_ctx.deleteReceipt(item.receiptId))
                                    }, {
                                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                                        _createTextVNode(" Delete Month ")
                                      ])),
                                      _: 2
                                    }, 1032, ["onClick"]),
                                    _createVNode(_component_gs_button, {
                                      class: "button",
                                      onClick: ($event: any) => (_ctx.addReceipt(item.receiptId))
                                    }, {
                                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                                        _createTextVNode(" Add Receipt ")
                                      ])),
                                      _: 2
                                    }, 1032, ["onClick"])
                                  ]),
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.receipts, (thing, b) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      key: b,
                                      class: "accordion-content"
                                    }, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.receipts, (receipt, c) => {
                                        return (_openBlock(), _createBlock(_resolveDynamicComponent(receipt.template), {
                                          key: c,
                                          item: thing,
                                          "item-index": a,
                                          "receipt-id": item.receiptId,
                                          "number-of-receipts": item.receipts.length,
                                          "this-is-receipt-number": b,
                                          onDeleteReceiptItem: _ctx.deleteReceiptItem,
                                          onSetReceiptClick: _ctx.setReceipt
                                        }, null, 40, ["item", "item-index", "receipt-id", "number-of-receipts", "this-is-receipt-number", "onDeleteReceiptItem", "onSetReceiptClick"]))
                                      }), 128))
                                    ]))
                                  }), 128)),
                                  _createVNode(_component_gs_button, {
                                    class: "button",
                                    onClick: ($event: any) => (_ctx.saveReceipts(item.receiptId)),
                                    disabled: _ctx.loading,
                                    loading: _ctx.loading
                                  }, {
                                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                                      _createTextVNode(" Save ")
                                    ])),
                                    _: 2
                                  }, 1032, ["onClick", "disabled", "loading"])
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]),
                      _: 2
                    }, 1024)
                  ])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]))
    : _createCommentVNode("", true)
}