import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-number-wrapper" }
const _hoisted_2 = { class: "number mb-0" }
const _hoisted_3 = { class: "mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TooltipImpact = _resolveComponent("TooltipImpact")!

  return (_openBlock(), _createElementBlock("div", {
    class: "stat",
    style: _normalizeStyle(_ctx.offsetType ? { backgroundImage: _ctx.impactBackgrounds[_ctx.offsetType] } : '')
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.formatNumberByLocale(_ctx.modelValue ?? 0)), 1),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.label || _ctx.$t(`${_ctx.offsetType}.unit`, _ctx.modelValue ?? 0)), 1),
      (!!_ctx.transferredImpact || !!_ctx.relationImpact)
        ? (_openBlock(), _createBlock(_component_TooltipImpact, {
            key: 0,
            "transferred-text": _ctx.transferredText,
            "partner-text": _ctx.relationImpactText,
            "transferred-impact": _ctx.transferredImpact
          }, null, 8, ["transferred-text", "partner-text", "transferred-impact"]))
        : _createCommentVNode("", true)
    ]),
    _cache[0] || (_cache[0] = _createElementVNode("div", null, null, -1))
  ], 4))
}