import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "top-stats-container section section--lg" }
const _hoisted_2 = { class: "section-header" }
const _hoisted_3 = { class: "section-title" }
const _hoisted_4 = { class: "section-description" }
const _hoisted_5 = { class: "top-stat-card-list" }
const _hoisted_6 = { class: "add-more-impact-card-content" }
const _hoisted_7 = { class: "add-more-impact-card-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopStatsCard = _resolveComponent("TopStatsCard")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.t(`title`)), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.t('description')), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.topStatDataToDisplay, (value, key) => {
        return (_openBlock(), _createBlock(_component_TopStatsCard, {
          key: key,
          type: key,
          amount: value,
          "transferred-impact": _ctx.getTransferredImpactByOffsetType(key),
          "relation-impact": _ctx.getRelationImpactByOffsetType(key),
          "impact-action-impact": _ctx.getImpactActionImpactByOffsetType(key),
          "background-color": _ctx.colorSettings[key] || '#A9A9A9',
          "icon-style": 'simple'
        }, null, 8, ["type", "amount", "transferred-impact", "relation-impact", "impact-action-impact", "background-color"]))
      }), 128)),
      _createElementVNode("div", {
        class: "add-more-impact-card",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onAddMoreImpactClick && _ctx.onAddMoreImpactClick(...args)))
      }, [
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "add-more-impact-card-icon" }, null, -1)),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.t('add_more_impact')), 1)
        ])
      ])
    ])
  ]))
}