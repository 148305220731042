import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "user-component-wrapper" }
const _hoisted_2 = { class: "user-wrapper" }
const _hoisted_3 = { class: "form-wrapper" }
const _hoisted_4 = { class: "header" }
const _hoisted_5 = { class: "name-wrapper" }
const _hoisted_6 = { class: "text-field-wrapper" }
const _hoisted_7 = {
  key: 0,
  class: "name-wrapper"
}
const _hoisted_8 = { class: "text-field-wrapper" }
const _hoisted_9 = { class: "name-wrapper" }
const _hoisted_10 = { class: "text-field-wrapper" }
const _hoisted_11 = { class: "name-wrapper" }
const _hoisted_12 = { class: "text-field-wrapper" }
const _hoisted_13 = { class: "error-message" }
const _hoisted_14 = {
  class: "error-message",
  style: 'background: #2E3A8C'
}
const _hoisted_15 = {
  key: 1,
  class: "error-message"
}
const _hoisted_16 = { class: "checkbox-wrapper" }
const _hoisted_17 = ["href"]
const _hoisted_18 = ["href"]
const _hoisted_19 = { class: "button-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CarouselCard = _resolveComponent("CarouselCard")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CarouselCard, { class: "carousel" }),
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.isInvite)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "back",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onBackClick && _ctx.onBackClick(...args)))
          }, [
            _createVNode(_component_v_icon, { class: "chevron" }, {
              default: _withCtx(() => _cache[11] || (_cache[11] = [
                _createTextVNode(" mdi-chevron-left ")
              ])),
              _: 1
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.t('back')), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_v_form, {
          modelValue: _ctx.isFormValid,
          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.isFormValid) = $event))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t(_ctx.isInvite ? 'create_account_invite' : 'create_account')), 1),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_v_text_field, {
                  modelValue: _ctx.userObject.firstName,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.userObject.firstName) = $event)),
                  label: _ctx.t('first_name'),
                  variant: "outlined",
                  rules: [_ctx.rules.required],
                  type: "text",
                  flat: "",
                  "hide-details": "",
                  color: "green",
                  class: "left-input"
                }, null, 8, ["modelValue", "label", "rules"]),
                _createVNode(_component_v_text_field, {
                  modelValue: _ctx.userObject.lastName,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.userObject.lastName) = $event)),
                  label: _ctx.t('last_name'),
                  variant: "outlined",
                  rules: [_ctx.rules.required],
                  type: "text",
                  flat: "",
                  "hide-details": "",
                  color: "green",
                  class: "right-input"
                }, null, 8, ["modelValue", "label", "rules"])
              ])
            ]),
            (!_ctx.isInvite)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_v_text_field, {
                      modelValue: _ctx.userObject.companyName,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.userObject.companyName) = $event)),
                      label: _ctx.t('company_name'),
                      variant: "outlined",
                      rules: [_ctx.rules.required],
                      type: "text",
                      flat: "",
                      "hide-details": "",
                      color: "green",
                      class: "left-input"
                    }, null, 8, ["modelValue", "label", "rules"]),
                    _createVNode(_component_v_text_field, {
                      modelValue: _ctx.userObject.vatId,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.userObject.vatId) = $event)),
                      label: _ctx.t('vat_id'),
                      variant: "outlined",
                      type: "text",
                      flat: "",
                      "hide-details": "",
                      color: "green",
                      class: "right-input"
                    }, null, 8, ["modelValue", "label"])
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_v_text_field, {
                  id: "Email",
                  modelValue: _ctx.userObject.email,
                  "onUpdate:modelValue": [
                    _cache[5] || (_cache[5] = ($event: any) => ((_ctx.userObject.email) = $event)),
                    _ctx.onEmailInput
                  ],
                  class: "input-field",
                  name: "Email",
                  rules: [_ctx.rules.required, _ctx.rules.email],
                  autocomplete: "email",
                  type: "email",
                  "hide-details": "",
                  label: _ctx.t('email'),
                  variant: "outlined",
                  flat: "",
                  color: "green",
                  disabled: _ctx.isInvite
                }, null, 8, ["modelValue", "rules", "label", "disabled", "onUpdate:modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_v_text_field, {
                  id: "Password",
                  modelValue: _ctx.userObject.password,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.userObject.password) = $event)),
                  class: "input-field",
                  name: "password",
                  rules: [_ctx.rules.required, _ctx.rules.password],
                  autocomplete: "password",
                  type: _ctx.showPassword ? 'text' : 'password',
                  label: _ctx.t('password'),
                  "append-inner-icon": _ctx.showPassword ? 'mdi-eye' : 'mdi-eye-off',
                  variant: "outlined",
                  flat: "",
                  "hide-details": "",
                  color: "green",
                  "onClick:appendInner": _cache[7] || (_cache[7] = ($event: any) => (_ctx.showPassword = !_ctx.showPassword))
                }, null, 8, ["modelValue", "rules", "type", "label", "append-inner-icon"])
              ])
            ]),
            (_ctx.error.length)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  (_ctx.error === 'emailAlreadyUsed')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.t('already_used')), 1),
                        _createElementVNode("div", _hoisted_14, [
                          _createTextVNode(_toDisplayString(_ctx.t('ask_to_login')) + " ", 1),
                          _createElementVNode("span", {
                            class: "login-link",
                            onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$router.push('/login')))
                          }, _toDisplayString(_ctx.t('login')), 1)
                        ])
                      ], 64))
                    : (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(_ctx.t(_ctx.snakeCase(_ctx.error))), 1))
                ], 64))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: _normalizeClass(['password-hint', { invite: _ctx.isInvite }]),
              style: _normalizeStyle(_ctx.hasPasswordError ? 'background: red; color: #F9F9F9' : '')
            }, _toDisplayString(_ctx.t('password_hint')), 7),
            _createElementVNode("div", _hoisted_16, [
              _createVNode(_component_v_checkbox, {
                modelValue: _ctx.checkbox,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.checkbox) = $event)),
                color: "green",
                "hide-details": "",
                rules: [_ctx.rules.required],
                style: {"margin":"0"}
              }, null, 8, ["modelValue", "rules"]),
              _createVNode(_component_i18n_t, {
                tag: "p",
                keypath: "Signup.accept",
                class: "mb-0 terms"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("a", {
                    href: _ctx.TERMS_CONDITIONS_URL,
                    target: "_blank",
                    class: "link"
                  }, _toDisplayString(_ctx.t('terms')), 9, _hoisted_17),
                  _createElementVNode("a", {
                    href: _ctx.PRIVACY_POLICY_URL,
                    target: "_blank",
                    class: "link"
                  }, _toDisplayString(_ctx.t('privacy')), 9, _hoisted_18)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_19, [
              _createVNode(_component_gs_button, {
                disabled: !_ctx.isFormValid || _ctx.loading,
                loading: _ctx.loading,
                "full-width": true,
                size: "large",
                onClick: _withModifiers(_ctx.signup, ["prevent"])
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('create')), 1)
                ]),
                _: 1
              }, 8, ["disabled", "loading", "onClick"])
            ])
          ]),
          _: 1
        }, 8, ["modelValue"])
      ])
    ])
  ]))
}