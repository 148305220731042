import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "impact-actions-list-item" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "order-info" }
const _hoisted_4 = { class: "text-wrapper" }
const _hoisted_5 = { class: "impact-action-text" }
const _hoisted_6 = { class: "impact-action-text" }
const _hoisted_7 = { class: "order-impacts" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_impact_pill = _resolveComponent("impact-pill")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("button", {
      class: "impact-actions-button",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openPopup && _ctx.openPopup(...args)))
    }, [
      _createElementVNode("img", {
        height: "70",
        width: "70",
        src: 
          _ctx.impactAction?.imageURL || require('@/assets/icons/greenspark-logo-vertical-green.svg')
        ,
        alt: _ctx.impactAction?.sellerUsername + 'logo',
        class: "company-logo",
        loading: "lazy"
      }, null, 8, _hoisted_2),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("p", _hoisted_5, [
            _createVNode(_component_v_icon, { class: "impact-action-icon material-icons-outlined" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.iconName), 1)
              ]),
              _: 1
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.label), 1)
          ]),
          _createElementVNode("p", _hoisted_6, [
            _createVNode(_component_v_icon, { class: "impact-action-icon" }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode(" mdi-shopping-outline ")
              ])),
              _: 1
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.impactAction?.sellerUsername || 'Greenspark'), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.impactAction?.action.impacts, (impact, index) => {
            return (_openBlock(), _createBlock(_component_impact_pill, {
              key: index,
              impact: { type: impact.type || 'multiple', amount: impact.amount }
            }, null, 8, ["impact"]))
          }), 128))
        ])
      ])
    ])
  ]))
}