import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "pop-up" }
const _hoisted_3 = { class: "overlay-header" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "overlay-text" }
const _hoisted_6 = { class: "dialog-button-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (!_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.dialog,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dialog) = $event)),
          width: "510",
          persistent: "",
          fullscreen: _ctx.isMobile
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", {
                class: "close-wrapper",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeDialogClick')))
              }, [
                _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t('header')), 1),
                _createElementVNode("img", {
                  style: {"width":"25px"},
                  class: "close-icon",
                  src: require('@/assets/icons/close_icon.svg'),
                  alt: "close"
                }, null, 8, _hoisted_4)
              ]),
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.text), 1),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_gs_button, {
                  type: "primary",
                  size: "large",
                  "full-width": "",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.checkout()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('button')), 1)
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _: 1
        }, 8, ["modelValue", "fullscreen"])
      ]))
    : _createCommentVNode("", true)
}