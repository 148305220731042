import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "select-integration" }
const _hoisted_2 = {
  key: 0,
  class: "search-menu-wrapper"
}
const _hoisted_3 = {
  key: 0,
  class: "search-menu-list"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 1,
  class: "filter-button-list"
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = {
  key: 3,
  class: "integration-list"
}
const _hoisted_9 = ["disabled", "onClick"]
const _hoisted_10 = ["src"]
const _hoisted_11 = {
  key: 2,
  class: "active-marker active-marker--filled"
}
const _hoisted_12 = {
  key: 3,
  class: "active-marker"
}
const _hoisted_13 = {
  key: 4,
  class: "no-result"
}
const _hoisted_14 = { class: "no-result__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gs_input = _resolveComponent("gs-input")!
  const _component_LoaderBlock = _resolveComponent("LoaderBlock")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_ShopifyIntegrationAlert = _resolveComponent("ShopifyIntegrationAlert")!
  const _component_RebuyIntegrationPopup = _resolveComponent("RebuyIntegrationPopup")!
  const _component_EtsyIntegrationAlert = _resolveComponent("EtsyIntegrationAlert")!
  const _component_CallToUpgradePopup = _resolveComponent("CallToUpgradePopup")!
  const _component_loading = _resolveComponent("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.hideSearch)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_gs_input, {
            id: "search-input",
            ref: "searchInput",
            modelValue: _ctx.searchValue,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchValue) = $event)),
            placeholder: _ctx.t('placeholder'),
            "append-inner-icon": "mdi-magnify",
            class: "search-field",
            type: "search",
            onBlur: _cache[1] || (_cache[1] = 
          () => {
            _ctx.isMenuOpen = false
            _ctx.fetchIntegrationOptions(_ctx.searchValue)
          }
        )
          }, null, 8, ["modelValue", "placeholder"]),
          _createVNode(_Transition, { name: "fade" }, {
            default: _withCtx(() => [
              (_ctx.isMenuOpen)
                ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchItems, (item, index) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: index,
                        class: "search-menu-item"
                      }, [
                        _createElementVNode("button", {
                          class: "search-menu-item-button",
                          onClick: ($event: any) => (_ctx.handleAutoSuggestClick(item.value))
                        }, [
                          _createElementVNode("span", {
                            class: "match-text",
                            innerHTML: item.text
                          }, null, 8, _hoisted_5)
                        ], 8, _hoisted_4)
                      ]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (!_ctx.hideSearch)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.integrationCategories, (category, index) => {
            return (_openBlock(), _createElementBlock("button", {
              key: index,
              class: _normalizeClass(['filter-button', { 'is-active': _ctx.categoryActiveIndex === index }]),
              onClick: ($event: any) => (_ctx.searchByCategory(category, index))
            }, _toDisplayString(_ctx.t(category)), 11, _hoisted_7))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.areIntegrationsLoading)
      ? (_openBlock(), _createBlock(_component_LoaderBlock, {
          key: 2,
          "background-color": "white"
        }))
      : (!_ctx.areIntegrationsLoading && _ctx.integrationOptionList.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getOptionsForSelectionList, (integration, index) => {
              return _withDirectives((_openBlock(), _createElementBlock("button", {
                key: index,
                class: _normalizeClass([
          'integration-button',
          { 'is-active': _ctx.activeIndex === index },
          { 'is-integrated': integration.activeNumber },
          { planned: integration.status === 'planned' },
        ]),
                disabled: 
          integration.status === 'planned' || _ctx.isIntegrationEmailSyncCompatible(integration)
        ,
                onClick: ($event: any) => (_ctx.handleClick(integration, index))
              }, [
                (integration.slug === 'custom' && !integration.icon)
                  ? (_openBlock(), _createBlock(_component_v_icon, {
                      key: 0,
                      class: "integration-logo placeholder"
                    }, {
                      default: _withCtx(() => _cache[6] || (_cache[6] = [
                        _createTextVNode(" mdi-puzzle-outline ")
                      ])),
                      _: 1
                    }))
                  : (_openBlock(), _createElementBlock("img", {
                      key: 1,
                      src: integration.icon,
                      alt: "",
                      class: "integration-logo"
                    }, null, 8, _hoisted_10)),
                _createElementVNode("span", null, _toDisplayString(integration.name), 1),
                (integration.activeNumber && !_ctx.isIntegrationEmailSyncCompatible(integration))
                  ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.getIfPlatformCustom(integration) ? '' : integration.activeNumber) + " " + _toDisplayString(_ctx.t('active')), 1))
                  : _createCommentVNode("", true),
                (_ctx.isIntegrationEmailSyncCompatible(integration))
                  ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.t('coming_soon')), 1))
                  : _createCommentVNode("", true)
              ], 10, _hoisted_9)), [
                [_vShow, _ctx.showIntegration(integration)]
              ])
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_13, [
            _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.t('no_result', { searchValue: _ctx.searchValue })), 1),
            _createVNode(_component_gs_button, {
              size: "large",
              onClick: _withModifiers(_ctx.openRequestPopup, ["prevent"])
            }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode(" Request integration ")
              ])),
              _: 1
            }, 8, ["onClick"])
          ])),
    _createVNode(_component_ShopifyIntegrationAlert, {
      modelValue: _ctx.isShopifyAlertPopupOpen,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isShopifyAlertPopupOpen) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_RebuyIntegrationPopup, {
      "is-open": _ctx.isRebuyPopupOpen,
      "onUpdate:isOpen": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isRebuyPopupOpen) = $event))
    }, null, 8, ["is-open"]),
    _createVNode(_component_EtsyIntegrationAlert, {
      modelValue: _ctx.isEtsyAlertPopupOpen,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isEtsyAlertPopupOpen) = $event)),
      onSubmit: _ctx.handleClickContinue
    }, null, 8, ["modelValue", "onSubmit"]),
    (_ctx.isCallToUpgradePopupOpen)
      ? (_openBlock(), _createBlock(_component_CallToUpgradePopup, {
          key: 5,
          "is-premium-only": _ctx.isPremiumOnly,
          "popup-type": _ctx.popupType,
          "integrator-type": _ctx.integratorType || undefined,
          onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.isCallToUpgradePopupOpen = false))
        }, null, 8, ["is-premium-only", "popup-type", "integrator-type"]))
      : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_loading, { key: 6 }))
      : _createCommentVNode("", true)
  ]))
}