<template>
  <div>
    <div v-if="!loading">
      <h1>Upload Shopify Payout</h1>
      <div style="margin-bottom: 18px">
        This functionality enables uploading Shopify payout files.
      </div>
      <v-file-input
        v-model="file"
        accept="text/csv"
        label="Upload Shopify payload csv"
        placeholder="Upload Shopify payload csv"
        prepend-icon="mdi-file-delimited-outline"
      ></v-file-input>

      <gs-button
        :disabled="loading || !file"
        :full-width="true"
        :loading="loading"
        size="large"
        @click.prevent="upload"
      >
        Upload
      </gs-button>
      <div>
        <div style="margin-top: 18px">
          <strong>Latest payout date:</strong> {{ latestPayoutDay }}
        </div>
      </div>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script lang="ts">
import Loading from '@/components/tools/Loading.vue'
import { RulesMixin } from '@/helpers/mixins/RulesMixin.vue'
import { defineComponent } from 'vue'
import { getLatestPayoutDate, uploadShopifyPayoutCSV } from '@api/admin-api'
import type { Notification } from '@/store/notification'

export default defineComponent({
  name: 'UploadShopifyPayoutView',
  components: { Loading },
  mixins: [RulesMixin],
  data() {
    return {
      loading: false,
      file: null,
      latestPayoutDay: '',
    } as {
      loading: boolean
      file: File | null
      latestPayoutDay: string
    }
  },
  async created() {
    this.loading = true
    await this.getLatestPayoutDate()
    this.loading = false
  },
  methods: {
    async getLatestPayoutDate() {
      this.latestPayoutDay = await getLatestPayoutDate()
    },
    async upload() {
      try {
        if (this.file) {
          this.loading = true
          await uploadShopifyPayoutCSV(this.file)
          await this.getLatestPayoutDate()
          this.$store.dispatch('notification/notify', {
            text: 'Successfully uploaded',
          } as Notification)
        }
      } catch (e) {
        this.$store.dispatch('notification/notify', {
          text: e?.response?.data?.message || 'There was an error, try it again!',
          isError: true,
        } as Notification)
      } finally {
        this.file = null
        this.loading = false
      }
    },
  },
  computed: {},
})
</script>

<style lang="scss" scoped>
.dropdown {
  margin: 10px 0;
}

.input-field {
  margin: 10px 0;
}
</style>
