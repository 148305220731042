import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withModifiers as _withModifiers, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "impact-settings-wrapper"
}
const _hoisted_2 = { class: "question-editor" }
const _hoisted_3 = { class: "answer-data-wrapper" }
const _hoisted_4 = { class: "answer-impacts" }
const _hoisted_5 = { class: "cost-per-answer" }
const _hoisted_6 = { class: "impact-settings" }
const _hoisted_7 = { class: "markers" }
const _hoisted_8 = { class: "totals-wrapper section" }
const _hoisted_9 = { class: "cart-items-text" }
const _hoisted_10 = { class: "total-text" }
const _hoisted_11 = {
  key: 0,
  class: "action-button-group question-actions"
}
const _hoisted_12 = { class: "action-buttons-line" }
const _hoisted_13 = { class: "action-buttons-line" }
const _hoisted_14 = {
  key: 1,
  class: "action-button-group answer-edit-actions"
}
const _hoisted_15 = {
  key: 1,
  class: "question-summary"
}
const _hoisted_16 = { class: "question-data-table" }
const _hoisted_17 = { class: "text-left question-th" }
const _hoisted_18 = { class: "text-left impact-th" }
const _hoisted_19 = { class: "text-left cost-th" }
const _hoisted_20 = { class: "question-text" }
const _hoisted_21 = ["src"]
const _hoisted_22 = ["src"]
const _hoisted_23 = ["rowspan"]
const _hoisted_24 = { class: "answer-text" }
const _hoisted_25 = ["src"]
const _hoisted_26 = ["src"]
const _hoisted_27 = { class: "summary-answer-impacts" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gs_input = _resolveComponent("gs-input")!
  const _component_impact_pill = _resolveComponent("impact-pill")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_ImpactSettingCard = _resolveComponent("ImpactSettingCard")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _directive_observe_intersection = _resolveDirective("observe-intersection")!

  return (_ctx.isEditing)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createVNode(_component_v_form, {
          ref: "form",
          modelValue: _ctx.isFormValid,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isFormValid) = $event)),
          class: "impact-settings-form"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_gs_input, {
                modelValue: _ctx.localValue.question,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue.question) = $event)),
                class: "question-input",
                label: _ctx.t('question'),
                "hide-details": false,
                rules: [_ctx.rules.required],
                disabled: _ctx.questionInputDisabled
              }, null, 8, ["modelValue", "label", "rules", "disabled"]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localValue.answers, (answerData, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, null, [
                  (answerData.id !== _ctx.editAnswerId)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: answerData.id,
                        class: "answer-data-row"
                      }, [
                        _createElementVNode("div", _hoisted_3, [
                          _createVNode(_component_gs_input, {
                            class: "answer-input",
                            label: _ctx.t('answer'),
                            "hide-details": true,
                            "model-value": answerData.answer,
                            disabled: ""
                          }, null, 8, ["label", "model-value"]),
                          _createElementVNode("div", _hoisted_4, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getImpactPillOffsetsFromAnswer(answerData), (offset, i) => {
                              return (_openBlock(), _createBlock(_component_impact_pill, {
                                key: i,
                                class: "impact-pill",
                                impact: { type: offset.type, amount: _ctx.getImpactPillAmount(offset) }
                              }, null, 8, ["impact"]))
                            }), 128))
                          ]),
                          _createVNode(_component_v_icon, {
                            class: "delete-answer-icon",
                            onClick: () => _ctx.deleteAnswer(answerData.id)
                          }, {
                            default: _withCtx(() => _cache[4] || (_cache[4] = [
                              _createTextVNode(" mdi-delete ")
                            ])),
                            _: 2
                          }, 1032, ["onClick"]),
                          _createVNode(_component_v_icon, {
                            class: "edit-answer-icon",
                            onClick: () => _ctx.editAnswer(answerData.id)
                          }, {
                            default: _withCtx(() => _cache[5] || (_cache[5] = [
                              _createTextVNode(" mdi-pencil ")
                            ])),
                            _: 2
                          }, 1032, ["onClick"])
                        ]),
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("p", null, _toDisplayString(_ctx.getCostPerAnswerText(index)), 1)
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ], 64))
              }), 256)),
              (_openBlock(), _createBlock(_component_gs_input, {
                key: _ctx.newAnswerInputId,
                modelValue: _ctx.newAnswer,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newAnswer) = $event)),
                rules: [_ctx.rules.required],
                class: "new-answer-input",
                label: _ctx.t('answer')
              }, null, 8, ["modelValue", "rules", "label"])),
              _createElementVNode("div", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getAppProjectIds, (projectId, index) => {
                  return _withDirectives((_openBlock(), _createBlock(_component_ImpactSettingCard, {
                    key: index,
                    modelValue: _ctx.impactSettings[projectId],
                    "onUpdate:modelValue": ($event: any) => ((_ctx.impactSettings[projectId]) = $event),
                    position: index,
                    "project-id": projectId,
                    "is-button-active": !!_ctx.impactSettings[projectId]
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "position", "project-id", "is-button-active"])), [
                    [_directive_observe_intersection, _ctx.setVisibleIndex, index]
                  ])
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getAppProjectIds, (_, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: _normalizeClass(['marker-dot', { visible: index === _ctx.lastVisibleCardIndex }])
                  }, null, 2))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.cartText), 1),
                _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.t('total', {
                price: _ctx.userCurrencySymbol + _ctx.formTotal,
              })), 1)
              ]),
              (!_ctx.editAnswerId)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createVNode(_component_gs_button, {
                        "full-width": "",
                        type: "tertiary",
                        size: "large",
                        class: "add-new-answer-button",
                        icon: _ctx.editAnswerId ? '' : 'mdi-plus',
                        disabled: _ctx.isCreateUpdateAnswerButtonDisabled,
                        onClick: _ctx.addAnswer
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.saveAnswerButtonText), 1)
                        ]),
                        _: 1
                      }, 8, ["icon", "disabled", "onClick"])
                    ]),
                    _createElementVNode("div", _hoisted_13, [
                      (!_ctx.hideQuestionEditCancelButton)
                        ? (_openBlock(), _createBlock(_component_gs_button, {
                            key: 0,
                            class: "cancel-edit-button",
                            type: "outlined",
                            size: "large",
                            onClick: _withModifiers(_ctx.cancelEdit, ["prevent"])
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t('cancel')), 1)
                            ]),
                            _: 1
                          }, 8, ["onClick"]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_gs_button, {
                        "full-width": "",
                        type: "primary",
                        size: "large",
                        disabled: _ctx.saveQuestionButtonDisabled,
                        onClick: _ctx.saveQuestion
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('save_question')), 1)
                        ]),
                        _: 1
                      }, 8, ["disabled", "onClick"])
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    _createVNode(_component_gs_button, {
                      class: "cancel-edit-button",
                      type: "info",
                      size: "large",
                      onClick: _withModifiers(_ctx.resetEditorState, ["prevent"])
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('cancel')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_gs_button, {
                      "full-width": "",
                      type: "primary",
                      size: "large",
                      disabled: _ctx.isCreateUpdateAnswerButtonDisabled,
                      onClick: _ctx.saveEditedAnswer
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.updateAnswerButtonText), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled", "onClick"])
                  ]))
            ])
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]))
    : (_openBlock(), _createElementBlock("section", _hoisted_15, [
        _createElementVNode("table", _hoisted_16, [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", _hoisted_17, _toDisplayString(_ctx.t('question')), 1),
              _cache[6] || (_cache[6] = _createElementVNode("th", { class: "text-left copy-th" }, null, -1)),
              _createElementVNode("th", _hoisted_18, _toDisplayString(_ctx.t('impact')), 1),
              _createElementVNode("th", _hoisted_19, _toDisplayString(_ctx.t('cost')), 1),
              _cache[7] || (_cache[7] = _createElementVNode("th", { class: "text-left edit-question-th" }, null, -1))
            ])
          ]),
          _createElementVNode("tbody", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("td", _hoisted_20, [
                _createVNode(_component_v_tooltip, {
                  "open-on-click": _ctx.isTouch,
                  color: "black",
                  location: "top"
                }, {
                  activator: _withCtx(({ props }) => [
                    _createElementVNode("span", _normalizeProps(_guardReactiveProps(props)), _toDisplayString(_ctx.localValue.question), 17)
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.localValue.question), 1)
                  ]),
                  _: 1
                }, 8, ["open-on-click"])
              ]),
              _createElementVNode("td", null, [
                _createVNode(_component_v_btn, {
                  class: "copy-button",
                  icon: "",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.copyToClipboard('question', _ctx.localValue.question)))
                }, {
                  default: _withCtx(() => [
                    (_ctx.copiedItemIds.includes('question'))
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          src: require('@/assets/icons/check_circle_2.svg'),
                          alt: "copy"
                        }, null, 8, _hoisted_21))
                      : (_openBlock(), _createElementBlock("img", {
                          key: 1,
                          src: require('@/assets/icons/file_copy.svg'),
                          alt: "copy"
                        }, null, 8, _hoisted_22))
                  ]),
                  _: 1
                })
              ]),
              _cache[9] || (_cache[9] = _createElementVNode("td", null, null, -1)),
              _cache[10] || (_cache[10] = _createElementVNode("td", null, null, -1)),
              _createElementVNode("td", {
                rowspan: _ctx.localValue.answers.length + 1,
                class: "edit-question-td"
              }, [
                _createVNode(_component_v_icon, {
                  class: "edit-answer-icon",
                  onClick: _ctx.editQuestion
                }, {
                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                    _createTextVNode(" mdi-pencil ")
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ], 8, _hoisted_23)
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localValue.answers, (answerData) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: answerData.id
              }, [
                _createElementVNode("td", _hoisted_24, [
                  _createVNode(_component_v_tooltip, {
                    "open-on-click": _ctx.isTouch,
                    color: "black",
                    location: "top"
                  }, {
                    activator: _withCtx(({ props }) => [
                      _createElementVNode("span", _mergeProps({ ref_for: true }, props), _toDisplayString(answerData.answer), 17)
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("span", null, _toDisplayString(answerData.answer), 1)
                    ]),
                    _: 2
                  }, 1032, ["open-on-click"])
                ]),
                _createElementVNode("td", null, [
                  _createVNode(_component_v_btn, {
                    class: "copy-button",
                    icon: "",
                    onClick: ($event: any) => (_ctx.copyToClipboard(answerData.id, answerData.answer))
                  }, {
                    default: _withCtx(() => [
                      (_ctx.copiedItemIds.includes(answerData.id))
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            src: require('@/assets/icons/check_circle_2.svg'),
                            alt: "copy"
                          }, null, 8, _hoisted_25))
                        : (_openBlock(), _createElementBlock("img", {
                            key: 1,
                            src: require('@/assets/icons/file_copy.svg'),
                            alt: "copy"
                          }, null, 8, _hoisted_26))
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_27, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getImpactPillOffsetsFromAnswer(answerData), (offset, i) => {
                      return (_openBlock(), _createBlock(_component_impact_pill, {
                        key: i,
                        class: "impact-pill",
                        impact: { type: offset.type, amount: _ctx.getImpactPillAmount(offset) }
                      }, null, 8, ["impact"]))
                    }), 128))
                  ])
                ]),
                _createElementVNode("td", null, _toDisplayString(_ctx.userCurrencySymbol + _ctx.calculateImpactsCosts(answerData.offsets)), 1)
              ]))
            }), 128))
          ])
        ])
      ]))
}