<template>
  <div class="total-wrapper">
    <!-- Team Members  -->
    <div class="stat">
      <div class="text-number-wrapper">
        <!-- TODO: Add Dynamic Number -->
        <div class="text">
          {{ tc('member') }}
        </div>
        <div class="number-members">
          {{ formatNumberByLocale(totalMembers) }}
        </div>
      </div>
    </div>

    <div class="sign">x</div>

    <div
      v-for="offsetType in SUBSCRIPTION_OFFSET_TYPES()"
      :key="offsetType"
      :class="['stat', offsetType]"
    >
      <div class="text-number-wrapper">
        <div class="number">
          {{ formatNumberByLocale(getTotalOffsetAmountByOffsetType(offsetType)) }}
        </div>
        <div class="text">
          {{ tc(offsetType, getTotalOffsetAmountByOffsetType(offsetType)) }}
        </div>
      </div>
    </div>

    <div class="sign">=</div>

    <!-- Total Cost  -->
    <div class="stat stat-total">
      <div class="text-number-wrapper">
        <div class="text-total">
          {{ t('total') }}
        </div>
        <div v-if="account.currency === 'euro'" class="number-total">
          {{ currencyEuro(totalCost) }} {{ getUserCurrencyCode }}
        </div>
        <div v-if="account.currency === 'pound'" class="number-total">
          {{ currencyPound(totalCost) }} {{ getUserCurrencyCode }}
        </div>
        <div v-if="account.currency === 'dollar'" class="number-total">
          {{ currencyDollar(totalCost) }} {{ getUserCurrencyCode }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { Account } from '@/store'
import { Decimal } from 'decimal.js'
import type { CurrencyCode, OffsetType } from '@/helpers/interfaces'
import { SUBSCRIPTION_OFFSET_TYPES } from '@/helpers/interfaces'
import { defineComponent } from 'vue'
import { Utils } from '@/helpers/mixins/utilsMixin'

export default defineComponent({
  name: 'TotalsFamily',
  mixins: [Utils],
  methods: {
    SUBSCRIPTION_OFFSET_TYPES() {
      return SUBSCRIPTION_OFFSET_TYPES
    },
    t(key: string) {
      return this.$t(`TotalsFamily.${key}`)
    },
    tc(key: string, count?: number) {
      return this.$t(`TotalsFamily.${key}`, count ?? 1)
    },
    setTotals() {
      this.setPrices()
      this.setTotalMembers()
      this.setTonnesCO2()
      this.setPlastic()
      this.setTrees()
      this.setTotalCost()
    },
    setPrices() {
      this.treeAddonPrice = this.getDefaultProjectPriceByType('trees')
    },
    setTotalMembers() {
      this.totalMembers = 0

      this.totalMembers =
        parseInt(`${this.positiveFamilyAdultAmount}`) +
        parseInt(`${this.positiveFamilyChildAmount}`) +
        parseInt(`${this.positiveFamilyPetAmount}`) +
        parseInt(`${this.earthPositiveFamilyAdultAmount}`) +
        parseInt(`${this.earthPositiveFamilyChildAmount}`) +
        parseInt(`${this.earthPositiveFamilyPetAmount}`)

      if (isNaN(this.totalMembers)) {
        this.totalMembers = 0
      }
    },
    setTonnesCO2() {
      this.totalCarbon = 0
      this.totalCarbon =
        parseInt(`${this.positiveFamilyAdultAmount}`) * this.positiveFamilyAdultCarbon +
        parseInt(`${this.positiveFamilyChildAmount}`) * this.positiveFamilyChildCarbon +
        parseInt(`${this.positiveFamilyPetAmount}`) * this.positiveFamilyPetCarbon +
        parseInt(`${this.earthPositiveFamilyAdultAmount}`) * this.positiveFamilyAdultCarbon +
        parseInt(`${this.earthPositiveFamilyChildAmount}`) * this.positiveFamilyChildCarbon +
        parseInt(`${this.earthPositiveFamilyPetAmount}`) * this.positiveFamilyPetCarbon

      if (isNaN(this.totalCarbon)) {
        this.totalCarbon = 0
      }
    },
    setPlastic() {
      this.totalPlastic = 0

      this.totalPlastic =
        parseInt(`${this.positiveFamilyAdultAmount}`) * this.positiveFamilyAdultPlastic +
        parseInt(`${this.positiveFamilyChildAmount}`) * this.positiveFamilyChildPlastic +
        parseInt(`${this.positiveFamilyPetAmount}`) * this.positiveFamilyPetPlastic +
        parseInt(`${this.earthPositiveFamilyAdultAmount}`) * this.earthPositiveFamilyAdultPlastic +
        parseInt(`${this.earthPositiveFamilyChildAmount}`) * this.earthPositiveFamilyChildPlastic +
        parseInt(`${this.earthPositiveFamilyPetAmount}`) * this.earthPositiveFamilyPetPlastic
    },
    setTrees() {
      this.totalTrees = 0
      this.totalTrees = this.account.personalPlanAddons.treeAddon || 0

      if (isNaN(this.totalTrees)) {
        this.totalTrees = 0
      }
    },
    setTotalCost() {
      this.totalCost = 0
      const positiveFamilyAdultTotal = new Decimal(
        parseInt(`${this.positiveFamilyAdultAmount}`),
      ).times(this.positiveFamilyAdultPrice ?? 0)
      const positiveFamilyChildTotal = new Decimal(
        parseInt(`${this.positiveFamilyChildAmount}`),
      ).times(this.positiveFamilyChildPrice ?? 0)
      const positiveFamilyPetTotal = new Decimal(parseInt(`${this.positiveFamilyPetAmount}`)).times(
        this.positiveFamilyPetPrice ?? 0,
      )
      const earthPositiveFamilyAdultTotal = new Decimal(
        parseInt(`${this.earthPositiveFamilyAdultAmount}`),
      ).times(this.earthPositiveFamilyAdultPrice ?? 0)
      const earthPositiveFamilyChildTotal = new Decimal(
        parseInt(`${this.earthPositiveFamilyChildAmount}`),
      ).times(this.earthPositiveFamilyChildPrice ?? 0)
      const earthPositiveFamilyPetTotal = new Decimal(
        parseInt(`${this.earthPositiveFamilyPetAmount}`),
      ).times(this.earthPositiveFamilyPetPrice ?? 0)
      const treeAddonTotal = new Decimal(parseInt(`${this.totalTrees}`)).times(this.treeAddonPrice)

      this.totalCost = positiveFamilyAdultTotal
        .plus(positiveFamilyChildTotal)
        .plus(positiveFamilyPetTotal)
        .plus(earthPositiveFamilyAdultTotal)
        .plus(earthPositiveFamilyChildTotal)
        .plus(earthPositiveFamilyPetTotal)
        .plus(treeAddonTotal)
        .toDP(2)
        .toNumber()

      if (!this.totalCost) {
        this.totalCost = 0
      }

      if (isNaN(this.totalCost)) {
        this.totalCost = 0
      }
    },
    getTotalOffsetAmountByOffsetType(type: OffsetType): number {
      switch (type) {
        case 'carbon':
          return this.totalCarbon
        case 'plastic':
          return this.totalPlastic
        case 'trees':
          return this.totalTrees
        case 'kelp':
        case 'water':
        case 'bees':
          return 0
        default: {
          const exhaustiveCheck: never = type
          throw new Error(exhaustiveCheck)
        }
      }
    },
    onpositiveFamilyAdultPlanChange() {
      this.setTotals()
    },
    onpositiveFamilyChildPlanChange() {
      this.setTotals()
    },
    onpositiveFamilyPetPlanChange() {
      this.setTotals()
    },
    onEarthPositiveFamilyAdultPlanChange() {
      this.setTotals()
    },
    onEarthPositiveFamilyChildPlanChange() {
      this.setTotals()
    },
    onEarthPositiveFamilyPetPlanChange() {
      this.setTotals()
    },
    onTreeAddonChange() {
      this.setTotals()
    },
    setProjectList(): Promise<void> {
      return this.$store.dispatch('setProjectList')
    },
  },
  components: {},
  data() {
    return {
      positiveFamilyAdultCarbon: 1.42,
      positiveFamilyAdultPlastic: 100,
      earthPositiveFamilyAdultPlastic: 350,
      positiveFamilyChildCarbon: 0.71,
      positiveFamilyChildPlastic: 50,
      earthPositiveFamilyChildPlastic: 175,
      positiveFamilyPetCarbon: 0.14,
      positiveFamilyPetPlastic: 5,
      earthPositiveFamilyPetPlastic: 18,
      treeAddon: 1,
      treeAddonPrice: 0,
      totalCarbon: 0,
      totalMembers: 0,
      totalPlastic: 0,
      totalTrees: 0,
      totalCost: 0,
    } as {
      positiveFamilyAdultCarbon: number
      positiveFamilyAdultPlastic: number
      earthPositiveFamilyAdultPlastic: number
      positiveFamilyChildCarbon: number
      positiveFamilyChildPlastic: number
      earthPositiveFamilyChildPlastic: number
      positiveFamilyPetCarbon: number
      positiveFamilyPetPlastic: number
      earthPositiveFamilyPetPlastic: number
      treeAddon: number
      treeAddonPrice: number
      totalCarbon: number
      totalMembers: number
      totalPlastic: number
      totalTrees: number
      totalCost: number
    }
  },
  created() {
    this.setProjectList()
    this.setTotals()
  },
  props: {
    positiveFamilyAdultPrice: {
      type: Number,
    },
    positiveFamilyChildPrice: {
      type: Number,
    },
    positiveFamilyPetPrice: {
      type: Number,
    },
    earthPositiveFamilyAdultPrice: {
      type: Number,
    },
    earthPositiveFamilyChildPrice: {
      type: Number,
    },
    earthPositiveFamilyPetPrice: {
      type: Number,
    },
    progressItem: {
      type: Number,
    },
  },
  watch: {
    positiveFamilyAdultAmount: [
      {
        handler: 'onpositiveFamilyAdultPlanChange',
      },
    ],
    positiveFamilyChildAmount: [
      {
        handler: 'onpositiveFamilyChildPlanChange',
      },
    ],
    positiveFamilyPetAmount: [
      {
        handler: 'onpositiveFamilyPetPlanChange',
      },
    ],
    earthPositiveFamilyAdultAmount: [
      {
        handler: 'onEarthPositiveFamilyAdultPlanChange',
      },
    ],
    earthPositiveFamilyChildAmount: [
      {
        handler: 'onEarthPositiveFamilyChildPlanChange',
      },
    ],
    earthPositiveFamilyPetAmount: [
      {
        handler: 'onEarthPositiveFamilyPetPlanChange',
      },
    ],
    'account.personalPlanAddons.treeAddon': [
      {
        handler: 'onTreeAddonChange',
      },
    ],
  },
  computed: {
    positiveFamilyAdultAmount(): number {
      return this.account.personalPlans.positiveFamilyAdult || 0
    },
    positiveFamilyChildAmount(): number {
      return this.account.personalPlans.positiveFamilyChild || 0
    },
    positiveFamilyPetAmount(): number {
      return this.account.personalPlans.positiveFamilyPet || 0
    },
    earthPositiveFamilyAdultAmount(): number {
      return this.account.personalPlans.earthPositiveFamilyAdult || 0
    },
    earthPositiveFamilyChildAmount(): number {
      return this.account.personalPlans.earthPositiveFamilyChild || 0
    },
    earthPositiveFamilyPetAmount(): number {
      return this.account.personalPlans.earthPositiveFamilyPet || 0
    },
    account(): Account {
      return this.$store.getters['getAccount']
    },
    getUserCurrencyCode(): CurrencyCode {
      return this.$store.getters['getUserCurrencyCode']
    },
    getDefaultProjectPriceByType(): (type: OffsetType) => number {
      return this.$store.getters['getDefaultProjectPriceByType']
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.total-wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px 28px;
  justify-content: space-between;
  width: 230px;
  align-items: center;
  background: var(--ui-white);
  box-shadow: var(--box-shadow-wide);
  border-radius: 8px;
  height: 100%;
}

.stat {
  width: 100%;
  min-width: 190px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 14px 24px;
  height: 90px;
  border-radius: 8px;
  background-color: var(--ui-beige);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.stat.trees {
  background-image: url('../../assets/gifs/comparisons/stat_tree_background.svg');
}

.stat.carbon {
  background-image: url('../../assets/gifs/comparisons/stat_carbon_background.svg');
}

.stat.plastic {
  background-image: url('../../assets/gifs/comparisons/stat_plastic_background.svg');
}

.stat-total {
  background-color: var(--ui-blue);
}

.text-number-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.number {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #212121;
}

.number-total {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: var(--ui-beige);
}

.number-members {
  font-weight: bold;
  font-size: 30px;
  line-height: 29px;
  text-align: center;
  color: #212121;
}

.text {
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #212121;
}

.text-total {
  text-align: center;
  font-size: 16px;
  color: var(--ui-beige);
}

.stat-icon {
  width: 55px;
}

@media #{map-get($display-breakpoints, 'md-and-down')} {
  .total-wrapper {
    padding: 25px 10px;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    height: auto;
  }

  .stat {
    width: 19%;
    min-width: unset;
    margin-bottom: 10px;
  }

  .text-total {
    font-size: 18px;
  }

  .text {
    font-size: 15px;
  }

  .number {
    font-size: 20px;
  }

  .number-total {
    font-size: 24px;
  }

  .number-members {
    font-size: 24px;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .total-wrapper {
    padding: 25px 10px;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    height: auto;
  }

  .stat {
    width: 49%;
    min-width: unset;
    margin-bottom: 10px;
  }

  .sign {
    display: none;
  }

  .stat:last-of-type {
    flex-grow: 2;
  }

  .text-total {
    font-size: 18px;
  }

  .text {
    font-size: 15px;
  }

  .number {
    font-size: 20px;
  }

  .number-total {
    font-size: 24px;
  }

  .number-members {
    font-size: 24px;
  }
}
</style>
