<template>
  <div class="update-wrapper">
    <div class="row">
      <v-text-field
        v-model="receiptName"
        class="input-field"
        label="Receipt Name"
        variant="outlined"
        flat
        color="#3B755F"
        style="margin-right: 5px"
      />
      <v-text-field
        v-model="newImageUrl"
        class="input-field"
        label="Image URL"
        variant="outlined"
        flat
        color="#3B755F"
        style="margin-right: 5px"
      />
      <div class="button-wrapper">
        <gs-button
          type="danger"
          class="button"
          @click="$emit('deleteReceiptItem', receiptId, item.id)"
        >
          DELETE RECEIPT
        </gs-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'UpdateProjectReceipt',
  emits: ['setReceiptClick', 'deleteReceiptItem'],
  components: {},
  data() {
    return {
      receiptName: '',
      newImageUrl: '',
    } as {
      receiptName: string
      newImageUrl: string
    }
  },
  created() {
    this.setData()
  },
  methods: {
    t(key: string) {
      return this.$t(`UpdateProjectReceipt.${key}`)
    },
    setData() {
      if (this.thisIsReceiptNumber < this.numberOfReceipts) {
        this.receiptName = this.item.receiptName
        this.newImageUrl = this.item.imageUrl
      }
    },
    onReceiptNameChange() {
      this.$emit(
        'setReceiptClick',
        this.receiptName,
        this.newImageUrl,
        this.receiptId,
        this.thisIsReceiptNumber,
      )
    },
    onNewImageUrlChange() {
      this.$emit(
        'setReceiptClick',
        this.receiptName,
        this.newImageUrl,
        this.receiptId,
        this.thisIsReceiptNumber,
      )
    },
  },
  props: {
    item: {
      type: Object as PropType<{ receiptName: string; imageUrl: string; id: string }>,
      required: true,
    },
    numberOfReceipts: {
      type: Number as PropType<number>,
      required: true,
    },
    thisIsReceiptNumber: {
      type: Number as PropType<number>,
      required: true,
    },
    receiptId: {
      type: String as PropType<string>,
    },
  },
  watch: {
    receiptName: [
      {
        handler: 'onReceiptNameChange',
      },
    ],
    newImageUrl: [
      {
        handler: 'onNewImageUrlChange',
      },
    ],
  },
})
</script>

<style lang="scss" scoped>
.update-wrapper {
  padding: 20px 10px 13px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 5px;
  align-items: center;
}

.input-field {
  max-width: 75%;
}

.button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  margin-left: 7px;
}
</style>
