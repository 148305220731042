import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "employee-wrapper" }
const _hoisted_2 = { class: "main-wrapper" }
const _hoisted_3 = { class: "header-wrapper" }
const _hoisted_4 = { class: "header" }
const _hoisted_5 = { class: "columns-wrapper" }
const _hoisted_6 = { class: "column" }
const _hoisted_7 = { class: "employee-type-wrapper" }
const _hoisted_8 = { class: "plan-name" }
const _hoisted_9 = { class: "plan-price-input-wrapper" }
const _hoisted_10 = { class: "plan-price-wrapper" }
const _hoisted_11 = {
  key: 0,
  class: "plan-price"
}
const _hoisted_12 = {
  key: 1,
  class: "plan-price"
}
const _hoisted_13 = {
  key: 2,
  class: "plan-price"
}
const _hoisted_14 = { class: "per-employee" }
const _hoisted_15 = { class: "text-field-wrapper" }
const _hoisted_16 = { class: "plus-minus" }
const _hoisted_17 = { class: "plan-price-input-wrapper" }
const _hoisted_18 = { class: "plan-price-wrapper" }
const _hoisted_19 = {
  key: 0,
  class: "plan-price"
}
const _hoisted_20 = {
  key: 1,
  class: "plan-price"
}
const _hoisted_21 = {
  key: 2,
  class: "plan-price"
}
const _hoisted_22 = { class: "per-employee" }
const _hoisted_23 = { class: "text-field-wrapper" }
const _hoisted_24 = { class: "plus-minus" }
const _hoisted_25 = { class: "plan-price-input-wrapper" }
const _hoisted_26 = { class: "plan-price-wrapper" }
const _hoisted_27 = {
  key: 0,
  class: "plan-price"
}
const _hoisted_28 = {
  key: 1,
  class: "plan-price"
}
const _hoisted_29 = {
  key: 2,
  class: "plan-price"
}
const _hoisted_30 = { class: "per-employee" }
const _hoisted_31 = { class: "text-field-wrapper" }
const _hoisted_32 = { class: "plus-minus" }
const _hoisted_33 = { class: "column" }
const _hoisted_34 = { class: "employee-type-wrapper" }
const _hoisted_35 = { class: "plan-name" }
const _hoisted_36 = { class: "plan-price-input-wrapper" }
const _hoisted_37 = { class: "plan-price-wrapper" }
const _hoisted_38 = {
  key: 0,
  class: "plan-price"
}
const _hoisted_39 = {
  key: 1,
  class: "plan-price"
}
const _hoisted_40 = {
  key: 2,
  class: "plan-price"
}
const _hoisted_41 = { class: "per-employee" }
const _hoisted_42 = { class: "text-field-wrapper" }
const _hoisted_43 = { class: "plus-minus" }
const _hoisted_44 = { class: "plan-price-input-wrapper" }
const _hoisted_45 = { class: "plan-price-wrapper" }
const _hoisted_46 = {
  key: 0,
  class: "plan-price"
}
const _hoisted_47 = {
  key: 1,
  class: "plan-price"
}
const _hoisted_48 = {
  key: 2,
  class: "plan-price"
}
const _hoisted_49 = { class: "per-employee" }
const _hoisted_50 = { class: "text-field-wrapper" }
const _hoisted_51 = { class: "plus-minus" }
const _hoisted_52 = { class: "plan-price-input-wrapper" }
const _hoisted_53 = { class: "plan-price-wrapper" }
const _hoisted_54 = {
  key: 0,
  class: "plan-price"
}
const _hoisted_55 = {
  key: 1,
  class: "plan-price"
}
const _hoisted_56 = {
  key: 2,
  class: "plan-price"
}
const _hoisted_57 = { class: "per-employee" }
const _hoisted_58 = { class: "text-field-wrapper" }
const _hoisted_59 = { class: "plus-minus" }
const _hoisted_60 = { class: "button-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_gs_button = _resolveComponent("gs-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t('subscription')), 1),
        _createElementVNode("div", {
          class: "back",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/choose-signup')))
        }, [
          _createElementVNode("div", null, _toDisplayString(_ctx.t('cancel')), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.t('positive_family')), 1),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                (_ctx.account?.currency === 'euro')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.currencyEuro(_ctx.positiveFamilyAdultPrice)), 1))
                  : _createCommentVNode("", true),
                (_ctx.account?.currency === 'pound')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.currencyPound(_ctx.positiveFamilyAdultPrice)), 1))
                  : _createCommentVNode("", true),
                (_ctx.account?.currency === 'dollar')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.currencyDollar(_ctx.positiveFamilyAdultPrice)), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.t('per_adult_month')), 1)
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createVNode(_component_v_text_field, {
                  modelValue: _ctx.positiveFamilyAdultAmount,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.positiveFamilyAdultAmount) = $event)),
                  label: _ctx.t('amount_adult'),
                  variant: "outlined",
                  type: "number",
                  flat: "",
                  min: "0",
                  color: "#3B755F",
                  class: "employee-input",
                  "hide-details": ""
                }, null, 8, ["modelValue", "label"]),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("div", {
                    class: "add",
                    style: {"margin-bottom":"1px"},
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (
                      _ctx.positiveFamilyAdultAmount !== null
                        ? _ctx.positiveFamilyAdultAmount++
                        : (_ctx.positiveFamilyAdultAmount = 1)
                    ))
                  }, " + "),
                  _createElementVNode("div", {
                    class: "add",
                    style: {"margin-top":"1px"},
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.positiveFamilyAdultAmount ? _ctx.positiveFamilyAdultAmount-- : null))
                  }, " - ")
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, [
                (_ctx.account?.currency === 'euro')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_19, _toDisplayString(_ctx.currencyEuro(_ctx.positiveFamilyChildPrice)), 1))
                  : _createCommentVNode("", true),
                (_ctx.account?.currency === 'pound')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_20, _toDisplayString(_ctx.currencyPound(_ctx.positiveFamilyChildPrice)), 1))
                  : _createCommentVNode("", true),
                (_ctx.account?.currency === 'dollar')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_21, _toDisplayString(_ctx.currencyDollar(_ctx.positiveFamilyChildPrice)), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.t('per_child_month')), 1)
              ]),
              _createElementVNode("div", _hoisted_23, [
                _createVNode(_component_v_text_field, {
                  modelValue: _ctx.positiveFamilyChildAmount,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.positiveFamilyChildAmount) = $event)),
                  label: _ctx.t('amount_child'),
                  variant: "outlined",
                  type: "number",
                  flat: "",
                  min: "0",
                  color: "#3B755F",
                  class: "employee-input",
                  "hide-details": ""
                }, null, 8, ["modelValue", "label"]),
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("div", {
                    class: "add",
                    style: {"margin-bottom":"1px"},
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (
                      _ctx.positiveFamilyChildAmount !== null
                        ? _ctx.positiveFamilyChildAmount++
                        : (_ctx.positiveFamilyChildAmount = 1)
                    ))
                  }, " + "),
                  _createElementVNode("div", {
                    class: "add",
                    style: {"margin-top":"1px"},
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.positiveFamilyChildAmount ? _ctx.positiveFamilyChildAmount-- : null))
                  }, " - ")
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_25, [
              _createElementVNode("div", _hoisted_26, [
                (_ctx.account?.currency === 'euro')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_27, _toDisplayString(_ctx.currencyEuro(_ctx.positiveFamilyPetPrice)), 1))
                  : _createCommentVNode("", true),
                (_ctx.account?.currency === 'pound')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_28, _toDisplayString(_ctx.currencyPound(_ctx.positiveFamilyPetPrice)), 1))
                  : _createCommentVNode("", true),
                (_ctx.account?.currency === 'dollar')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_29, _toDisplayString(_ctx.currencyDollar(_ctx.positiveFamilyPetPrice)), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.t('per_pet_month')), 1)
              ]),
              _createElementVNode("div", _hoisted_31, [
                _createVNode(_component_v_text_field, {
                  modelValue: _ctx.positiveFamilyPetAmount,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.positiveFamilyPetAmount) = $event)),
                  label: _ctx.t('amount_pet'),
                  variant: "outlined",
                  type: "number",
                  flat: "",
                  min: "0",
                  color: "#3B755F",
                  class: "employee-input",
                  "hide-details": ""
                }, null, 8, ["modelValue", "label"]),
                _createElementVNode("div", _hoisted_32, [
                  _createElementVNode("div", {
                    class: "add",
                    style: {"margin-bottom":"1px"},
                    onClick: _cache[8] || (_cache[8] = ($event: any) => (
                      _ctx.positiveFamilyPetAmount !== null
                        ? _ctx.positiveFamilyPetAmount++
                        : (_ctx.positiveFamilyPetAmount = 1)
                    ))
                  }, " + "),
                  _createElementVNode("div", {
                    class: "add",
                    style: {"margin-top":"1px"},
                    onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.positiveFamilyPetAmount ? _ctx.positiveFamilyPetAmount-- : null))
                  }, " - ")
                ])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_33, [
          _createElementVNode("div", _hoisted_34, [
            _createElementVNode("div", _hoisted_35, _toDisplayString(_ctx.t('earth_positive_family')), 1),
            _createElementVNode("div", _hoisted_36, [
              _createElementVNode("div", _hoisted_37, [
                (_ctx.account?.currency === 'euro')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_38, _toDisplayString(_ctx.currencyEuro(_ctx.earthPositiveFamilyAdultPrice)), 1))
                  : _createCommentVNode("", true),
                (_ctx.account?.currency === 'pound')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_39, _toDisplayString(_ctx.currencyPound(_ctx.earthPositiveFamilyAdultPrice)), 1))
                  : _createCommentVNode("", true),
                (_ctx.account?.currency === 'dollar')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_40, _toDisplayString(_ctx.currencyDollar(_ctx.earthPositiveFamilyAdultPrice)), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_41, _toDisplayString(_ctx.t('per_adult_month')), 1)
              ]),
              _createElementVNode("div", _hoisted_42, [
                _createVNode(_component_v_text_field, {
                  modelValue: _ctx.earthPositiveFamilyAdultAmount,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.earthPositiveFamilyAdultAmount) = $event)),
                  label: _ctx.t('amount_adult'),
                  variant: "outlined",
                  type: "number",
                  flat: "",
                  min: "0",
                  color: "#3B755F",
                  class: "employee-input",
                  "hide-details": ""
                }, null, 8, ["modelValue", "label"]),
                _createElementVNode("div", _hoisted_43, [
                  _createElementVNode("div", {
                    class: "add",
                    style: {"margin-bottom":"1px"},
                    onClick: _cache[11] || (_cache[11] = ($event: any) => (
                      _ctx.earthPositiveFamilyAdultAmount !== null
                        ? _ctx.earthPositiveFamilyAdultAmount++
                        : (_ctx.earthPositiveFamilyAdultAmount = 1)
                    ))
                  }, " + "),
                  _createElementVNode("div", {
                    class: "add",
                    style: {"margin-top":"1px"},
                    onClick: _cache[12] || (_cache[12] = ($event: any) => (
                      _ctx.earthPositiveFamilyAdultAmount ? _ctx.earthPositiveFamilyAdultAmount-- : null
                    ))
                  }, " - ")
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_44, [
              _createElementVNode("div", _hoisted_45, [
                (_ctx.account?.currency === 'euro')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_46, _toDisplayString(_ctx.currencyEuro(_ctx.earthPositiveFamilyChildPrice)), 1))
                  : _createCommentVNode("", true),
                (_ctx.account?.currency === 'pound')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_47, _toDisplayString(_ctx.currencyPound(_ctx.earthPositiveFamilyChildPrice)), 1))
                  : _createCommentVNode("", true),
                (_ctx.account?.currency === 'dollar')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_48, _toDisplayString(_ctx.currencyDollar(_ctx.earthPositiveFamilyChildPrice)), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_49, _toDisplayString(_ctx.t('per_child_month')), 1)
              ]),
              _createElementVNode("div", _hoisted_50, [
                _createVNode(_component_v_text_field, {
                  modelValue: _ctx.earthPositiveFamilyChildAmount,
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.earthPositiveFamilyChildAmount) = $event)),
                  label: _ctx.t('amount_child'),
                  variant: "outlined",
                  type: "number",
                  flat: "",
                  min: "0",
                  color: "#3B755F",
                  class: "employee-input",
                  "hide-details": ""
                }, null, 8, ["modelValue", "label"]),
                _createElementVNode("div", _hoisted_51, [
                  _createElementVNode("div", {
                    class: "add",
                    style: {"margin-bottom":"1px"},
                    onClick: _cache[14] || (_cache[14] = ($event: any) => (
                      _ctx.earthPositiveFamilyChildAmount !== null
                        ? _ctx.earthPositiveFamilyChildAmount++
                        : (_ctx.earthPositiveFamilyChildAmount = 1)
                    ))
                  }, " + "),
                  _createElementVNode("div", {
                    class: "add",
                    style: {"margin-top":"1px"},
                    onClick: _cache[15] || (_cache[15] = ($event: any) => (
                      _ctx.earthPositiveFamilyChildAmount ? _ctx.earthPositiveFamilyChildAmount-- : null
                    ))
                  }, " - ")
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_52, [
              _createElementVNode("div", _hoisted_53, [
                (_ctx.account?.currency === 'euro')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_54, _toDisplayString(_ctx.currencyEuro(_ctx.earthPositiveFamilyPetPrice)), 1))
                  : _createCommentVNode("", true),
                (_ctx.account?.currency === 'pound')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_55, _toDisplayString(_ctx.currencyPound(_ctx.earthPositiveFamilyPetPrice)), 1))
                  : _createCommentVNode("", true),
                (_ctx.account?.currency === 'dollar')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_56, _toDisplayString(_ctx.currencyDollar(_ctx.earthPositiveFamilyPetPrice)), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_57, _toDisplayString(_ctx.t('per_pet_month')), 1)
              ]),
              _createElementVNode("div", _hoisted_58, [
                _createVNode(_component_v_text_field, {
                  modelValue: _ctx.earthPositiveFamilyPetAmount,
                  "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.earthPositiveFamilyPetAmount) = $event)),
                  label: _ctx.t('amount_pet'),
                  variant: "outlined",
                  type: "number",
                  flat: "",
                  min: "0",
                  color: "#3B755F",
                  class: "employee-input",
                  "hide-details": ""
                }, null, 8, ["modelValue", "label"]),
                _createElementVNode("div", _hoisted_59, [
                  _createElementVNode("div", {
                    class: "add",
                    style: {"margin-bottom":"1px"},
                    onClick: _cache[17] || (_cache[17] = ($event: any) => (
                      _ctx.earthPositiveFamilyPetAmount !== null
                        ? _ctx.earthPositiveFamilyPetAmount++
                        : (_ctx.earthPositiveFamilyPetAmount = 1)
                    ))
                  }, " + "),
                  _createElementVNode("div", {
                    class: "add",
                    style: {"margin-top":"1px"},
                    onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.earthPositiveFamilyPetAmount ? _ctx.earthPositiveFamilyPetAmount-- : null))
                  }, " - ")
                ])
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_60, [
        _createVNode(_component_gs_button, {
          disabled: _ctx.totalMembers === 0,
          "full-width": true,
          size: "large",
          onClick: _withModifiers(_ctx.onContinue, ["prevent"])
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('continue')), 1)
          ]),
          _: 1
        }, 8, ["disabled", "onClick"])
      ])
    ])
  ]))
}