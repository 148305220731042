import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, TransitionGroup as _TransitionGroup, withCtx as _withCtx, Transition as _Transition } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "title-wrapper" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "subheader" }
const _hoisted_5 = { class: "input-outer" }
const _hoisted_6 = { class: "select" }
const _hoisted_7 = { class: "title-wrapper" }
const _hoisted_8 = { class: "subheader" }
const _hoisted_9 = { class: "select" }
const _hoisted_10 = { class: "title-wrapper" }
const _hoisted_11 = { class: "subheader" }
const _hoisted_12 = {
  key: 0,
  class: "edit-help-text"
}
const _hoisted_13 = {
  key: "0",
  class: "impact-input"
}
const _hoisted_14 = {
  key: "1",
  class: "allowance-input"
}
const _hoisted_15 = {
  key: 0,
  class: "select",
  style: {"margin-top":"12px"}
}
const _hoisted_16 = { class: "title-wrapper" }
const _hoisted_17 = { class: "subheader" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!
  const _component_ImpactInput = _resolveComponent("ImpactInput")!
  const _component_v_text_field = _resolveComponent("v-text-field")!

  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            class: "info-icon",
            src: require('@/assets/icons/partners/funded_impact.svg'),
            alt: "info"
          }, null, 8, _hoisted_3),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.t('funded')), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.t('plan_type_label')), 1),
              _createElementVNode("button", {
                class: "open-popup-button",
                onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.openComparisonPopup && _ctx.openComparisonPopup(...args)), ["prevent"]))
              }, _toDisplayString(_ctx.t('plan_type_help_link')), 1)
            ]),
            _createVNode(_component_v_select, {
              ref: "planType",
              modelValue: _ctx.fundingSetting.planType,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.fundingSetting.planType) = $event)),
              class: "input-field",
              items: _ctx.planSelectOptions,
              variant: "outlined",
              clearable: "",
              color: "#3B755F",
              style: {"margin-bottom":"25px"}
            }, null, 8, ["modelValue", "items"])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.t('impact')), 1)
            ]),
            _createVNode(_component_v_select, {
              ref: "impactType",
              modelValue: _ctx.fundingSetting.fundingType,
              "onUpdate:modelValue": [
                _cache[2] || (_cache[2] = ($event: any) => ((_ctx.fundingSetting.fundingType) = $event)),
                _ctx.onTypeChange
              ],
              items: _ctx.typeItems,
              variant: "outlined",
              color: "#3B755F",
              "item-color": "#3B755F",
              clearable: "",
              class: "dropdown",
              "item-disabled.disabled": "disable"
            }, null, 8, ["modelValue", "items", "onUpdate:modelValue"])
          ]),
          (_ctx.isEdit)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.t('edit_help_text')), 1))
            : _createCommentVNode("", true),
          _createVNode(_TransitionGroup, {
            name: "fade",
            tag: "div",
            style: {"width":"100%"}
          }, {
            default: _withCtx(() => [
              (_ctx.showImpactInput)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createVNode(_component_ImpactInput, {
                      "impact-array-set": _ctx.fundingSetting.impactArray,
                      "is-edit": _ctx.isEdit,
                      "reset-type": _ctx.resetType,
                      onSetImpactType: _ctx.setImpactType
                    }, null, 8, ["impact-array-set", "is-edit", "reset-type", "onSetImpactType"])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_14, [
                (
              _ctx.fundingSetting.fundingType === 'monthlyAllowance' ||
              _ctx.fundingSetting.fundingType === 'oneTimeAllowance'
            )
                  ? (_openBlock(), _createBlock(_component_v_text_field, {
                      key: 0,
                      modelValue: _ctx.fundingSetting.monthlyAllowance,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.fundingSetting.monthlyAllowance) = $event)),
                      modelModifiers: { number: true },
                      class: "input-field",
                      label: _ctx.t('monthly_amount_label', { currency: _ctx.userCurrencySymbol }),
                      variant: "outlined",
                      type: "number",
                      min: "0",
                      flat: "",
                      color: "#3B755F",
                      style: {"margin-top":"25px"},
                      rules: [_ctx.rules.required, _ctx.rules.maxTwoDecimal]
                    }, null, 8, ["modelValue", "label", "rules"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }),
          _createVNode(_Transition, { name: "fade" }, {
            default: _withCtx(() => [
              (
            !!_ctx.fundingSetting.planType ||
            _ctx.fundingSetting.fundingType === 'monthlyImpact' ||
            _ctx.fundingSetting.fundingType === 'monthlyAllowance'
          )
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.t('length')), 1)
                    ]),
                    _createVNode(_component_v_select, {
                      modelValue: _ctx.fundingSetting.fundingLength,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.fundingSetting.fundingLength) = $event)),
                      items: _ctx.lengthItems,
                      variant: "outlined",
                      color: "#3B755F",
                      "item-color": "#3B755F",
                      class: "dropdown",
                      rules: [_ctx.rules.required]
                    }, null, 8, ["modelValue", "items", "rules"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ])
      ]))
    : _createCommentVNode("", true)
}