import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "signup"
}
const _hoisted_2 = { class: "signup-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SignupPreInfo = _resolveComponent("SignupPreInfo")!
  const _component_SignupInfoPartners = _resolveComponent("SignupInfoPartners")!

  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.progressItem === 0)
            ? (_openBlock(), _createBlock(_component_SignupPreInfo, {
                key: 0,
                error: _ctx.errorMessage,
                onNextClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.progressItem++))
              }, null, 8, ["error"]))
            : _createCommentVNode("", true),
          (_ctx.progressItem === 1)
            ? (_openBlock(), _createBlock(_component_SignupInfoPartners, {
                key: 1,
                "relations-id": _ctx.relationsId,
                "user-objects": _ctx.userObject,
                onBackClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.progressItem--))
              }, null, 8, ["relations-id", "user-objects"]))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}