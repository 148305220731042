<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import { Utils } from '@/helpers/mixins/utilsMixin'
import type { OffsetType, WaterComparison } from '@/helpers/interfaces'

export default defineComponent({
  name: 'WaterComparison',
  mixins: [Utils],
  props: {
    comparisons: {
      required: true,
      type: Object as PropType<WaterComparison>,
    },
    colorSettings: {
      required: true,
      type: Object as PropType<Record<OffsetType, string>>,
    },
  },
})
</script>

<template>
  <div class="water-comparison-container">
    <div
      class="litres-provided comparison-card"
      :style="{ 'background-color': colorSettings.water }"
    >
      <div class="comparison-data">
        <div class="comparison-amount">
          {{ formatTotalAmounts(comparisons.litres.amount, comparisons.litres.suffix) }}
        </div>
        <div class="info-text">
          {{ comparisons.litres.amountDescription }}
        </div>
        <a :href="comparisons.litres.link" class="info-sub-text" target="_blank">
          {{ comparisons.litres.description }}
        </a>
      </div>
      <div class="comparison-icon-wrapper">
        <img
          class="comparison-icon"
          :alt="comparisons.litres.amountDescription"
          :src="require('@/assets/icons/comparisons/water-litres-provided.svg')"
        />
      </div>
    </div>
    <div
      class="children-with-education comparison-card"
      :style="{ 'background-color': colorSettings.water }"
    >
      <div class="comparison-icon-wrapper">
        <img
          class="comparison-icon"
          :alt="comparisons.brighterEducation.amountDescription"
          :src="require('@/assets/icons/comparisons/water-children-with-education.svg')"
        />
      </div>
      <div class="comparison-data">
        <div class="comparison-amount">
          {{
            formatTotalAmounts(
              comparisons.brighterEducation.amount,
              comparisons.brighterEducation.suffix,
            )
          }}
        </div>
        <div class="info-text">
          {{ comparisons.brighterEducation.amountDescription }}
        </div>
        <a :href="comparisons.brighterEducation.link" class="info-sub-text" target="_blank">
          {{ comparisons.brighterEducation.description }}
        </a>
      </div>
    </div>
    <div
      class="women-saved-from-fetching comparison-card"
      :style="{ 'background-color': colorSettings.water }"
    >
      <div class="comparison-data">
        <div class="comparison-amount">
          {{
            formatTotalAmounts(comparisons.fetchingWater.amount, comparisons.fetchingWater.suffix)
          }}
        </div>
        <div class="info-text">
          {{ comparisons.fetchingWater.amountDescription }}
        </div>
        <a :href="comparisons.fetchingWater.link" class="info-sub-text" target="_blank">
          {{ comparisons.fetchingWater.description }}
        </a>
      </div>
      <div class="comparison-icon-wrapper">
        <img
          class="comparison-icon"
          :alt="comparisons.fetchingWater.amountDescription"
          :src="require('@/assets/icons/comparisons/water-women-saved-from-fetching.svg')"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '~vuetify/settings';

.water-comparison-container {
  color: var(--ui-black);
  display: grid;
  gap: 16px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    gap: 26px;
  }
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    gap: 32px;
  }
}

.comparison-card {
  display: grid;
  height: 170px;
  border-radius: 10px;
}

.comparison-card.women-saved-from-fetching {
  grid-template-columns: auto 100px;
  padding: 6px 11px 6px 22px;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    grid-template-columns: auto 140px;
  }
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    grid-template-columns: auto 150px;
  }
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    grid-column: 2 / span 3;
    grid-row: 1;
    height: auto;
    min-height: 180px;
    grid-template-columns: auto 165px;
    gap: 16px;
  }

  .comparison-data {
    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      grid-template-columns: 1fr auto;
      column-gap: 16px;
    }

    .info-sub-text {
      @media #{map-get($display-breakpoints, 'lg-and-up')} {
        grid-column: 2;
      }
    }
  }

  .comparison-amount {
    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      margin: auto;
    }
  }

  .comparison-icon {
    height: 84px;
    left: 4px;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      height: 118px;
      left: unset;
    }
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      height: 130px;
    }
  }
}

.comparison-card.litres-provided {
  grid-template-columns: 160px auto;
  padding: 6px 11px 6px 22px;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    grid-template-columns: auto 140px;
  }
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    grid-template-columns: auto 200px;
  }
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    height: 360px;
    margin-bottom: auto;
    grid-column: 1;
    grid-row: 1 / span 2;
    min-height: 370px;
    grid-template-columns: auto;
    grid-template-rows: auto 100px;
    gap: 16px;
    padding: 22px 40px 0 40px;
    width: 314px;
  }

  .comparison-data {
    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      display: flex;
      text-align: center;
      flex-direction: column;
      justify-content: start;
    }
  }

  .comparison-icon {
    height: 88px;
    left: 6px;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      height: 120px;
      left: unset;
    }
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      height: 154px;
    }
    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      top: -6px;
      height: 166px;
      grid-row: 2;
    }
  }
}

.comparison-card.children-with-education {
  grid-template-columns: 100px auto;
  padding: 6px 22px 6px 11px;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    grid-template-columns: 120px auto;
  }
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    grid-template-columns: 200px auto;
  }
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    grid-column: 2 / span 3;
    height: auto;
    min-height: 180px;
    grid-row: 2;
    grid-template-columns: 200px auto;
  }

  .comparison-data {
    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      grid-template-columns: auto 1fr;
      column-gap: 16px;
    }

    .info-sub-text {
      @media #{map-get($display-breakpoints, 'lg-and-up')} {
        grid-column: 1 / span 2;
      }
    }
  }

  .comparison-amount {
    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      margin: auto;
    }
  }

  .comparison-icon {
    height: 66px;
    right: 6px;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      height: 86px;
      right: unset;
    }
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      height: 126px;
    }
    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      right: 20px;
    }
  }
}

.comparison-data {
  font-family: Cabin;
  display: grid;
  align-content: center;

  .info-text {
    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      font-size: 24px;
    }
  }
}

.comparison-card:nth-child(even) .comparison-data {
  text-align: right;
}

.comparison-amount {
  color: var(--ui-black);
  font-size: 54px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
}

.info-text {
  line-height: normal;
  font-size: 16px;
  margin-bottom: 6px;
}

.info-sub-text {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  color: var(--ui-black);
}

.comparison-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.comparison-icon {
  position: relative;
}
</style>
