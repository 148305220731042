import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "carts-wrapper" }
const _hoisted_2 = { class: "outer-totals-wrapper" }
const _hoisted_3 = {
  key: 0,
  class: "chevron-left"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  id: "totals",
  class: "totals-cart-wrapper"
}
const _hoisted_6 = {
  key: 0,
  class: "text-number"
}
const _hoisted_7 = { class: "number" }
const _hoisted_8 = { class: "text" }
const _hoisted_9 = {
  key: 1,
  class: "divider-wrapper"
}
const _hoisted_10 = {
  key: 2,
  class: "text-number"
}
const _hoisted_11 = { class: "number" }
const _hoisted_12 = { class: "text" }
const _hoisted_13 = {
  key: 3,
  class: "divider-wrapper"
}
const _hoisted_14 = {
  key: 4,
  class: "text-number"
}
const _hoisted_15 = { class: "number" }
const _hoisted_16 = { class: "text" }
const _hoisted_17 = {
  key: 5,
  class: "divider-wrapper"
}
const _hoisted_18 = {
  key: 6,
  class: "text-number"
}
const _hoisted_19 = { class: "number" }
const _hoisted_20 = { class: "text" }
const _hoisted_21 = {
  key: 7,
  class: "divider-wrapper"
}
const _hoisted_22 = {
  key: 8,
  class: "text-number"
}
const _hoisted_23 = { class: "number" }
const _hoisted_24 = { class: "text" }
const _hoisted_25 = {
  key: 9,
  class: "divider-wrapper"
}
const _hoisted_26 = {
  key: 1,
  class: "chevron-cart"
}
const _hoisted_27 = ["src"]
const _hoisted_28 = { class: "button-wrapper" }
const _hoisted_29 = {
  key: 0,
  class: "button-text"
}
const _hoisted_30 = { key: 0 }
const _hoisted_31 = { key: 1 }
const _hoisted_32 = { key: 2 }
const _hoisted_33 = { class: "confirm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.showLeftArrow)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("img", {
              style: {"min-width":"20px","filter":"invert(90%) sepia(1%) saturate(7437%) hue-rotate(194deg) brightness(116%)\n              contrast(97%)"},
              class: "chevron-icon",
              src: require('@/assets/icons/chevron_left.svg'),
              alt: "left"
            }, null, 8, _hoisted_4)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        (_ctx.addStandard)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, _toDisplayString(Math.sign(_ctx.addStandard) === 1 ? '+' : '') + " " + _toDisplayString(_ctx.addStandard), 1),
              _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.t('standard')), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.addStandard)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[1] || (_cache[1] = [
              _createElementVNode("div", { class: "line" }, null, -1)
            ])))
          : _createCommentVNode("", true),
        (_ctx.addSuperior)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, _toDisplayString(Math.sign(_ctx.addSuperior) === 1 ? '+' : '') + " " + _toDisplayString(_ctx.addSuperior), 1),
              _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.t('superior')), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.addSuperior)
          ? (_openBlock(), _createElementBlock("div", _hoisted_13, _cache[2] || (_cache[2] = [
              _createElementVNode("div", { class: "line" }, null, -1)
            ])))
          : _createCommentVNode("", true),
        (_ctx.addPremium)
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, _toDisplayString(Math.sign(_ctx.addPremium) === 1 ? '+' : '') + " " + _toDisplayString(_ctx.addPremium), 1),
              _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.t('premium')), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.addPremium)
          ? (_openBlock(), _createElementBlock("div", _hoisted_17, _cache[3] || (_cache[3] = [
              _createElementVNode("div", { class: "line" }, null, -1)
            ])))
          : _createCommentVNode("", true),
        (_ctx.addTrees)
          ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
              _createElementVNode("div", _hoisted_19, _toDisplayString(Math.sign(_ctx.addTrees) === 1 ? '+' : '') + " " + _toDisplayString(_ctx.addTrees), 1),
              _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.t('tree_planter')), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.addTrees)
          ? (_openBlock(), _createElementBlock("div", _hoisted_21, _cache[4] || (_cache[4] = [
              _createElementVNode("div", { class: "line" }, null, -1)
            ])))
          : _createCommentVNode("", true),
        (_ctx.addPlastic)
          ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
              _createElementVNode("div", _hoisted_23, _toDisplayString(Math.sign(_ctx.addPlastic) === 1 ? '+' : '') + " " + _toDisplayString(_ctx.addPlastic), 1),
              _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.t('plastic_hero')), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.addPlastic)
          ? (_openBlock(), _createElementBlock("div", _hoisted_25, _cache[5] || (_cache[5] = [
              _createElementVNode("div", { class: "line" }, null, -1)
            ])))
          : _createCommentVNode("", true)
      ]),
      (_ctx.showRightArrow)
        ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
            _createElementVNode("img", {
              style: {"width":"20px","filter":"invert(90%) sepia(1%) saturate(7437%) hue-rotate(194deg) brightness(116%)\n              contrast(97%)"},
              class: "chevron-icon",
              src: require('@/assets/icons/chevron_right.svg'),
              alt: "right"
            }, null, 8, _hoisted_27)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_28, [
      _createVNode(_component_v_btn, {
        class: "button",
        color: "#3B755F",
        height: "54",
        variant: "flat",
        disabled: _ctx.loading,
        style: {"width":"100%","height":"100%","border-radius":"0"},
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.cartClick()))
      }, {
        default: _withCtx(() => [
          (!_ctx.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                (_ctx.account.currency === 'euro')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_30, _toDisplayString(_ctx.currencyEuro(_ctx.totalCost ? _ctx.totalCost : 0)), 1))
                  : _createCommentVNode("", true),
                (_ctx.account.currency === 'pound')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_31, _toDisplayString(_ctx.currencyPound(_ctx.totalCost ? _ctx.totalCost : 0)), 1))
                  : _createCommentVNode("", true),
                (_ctx.account.currency === 'dollar')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_32, _toDisplayString(_ctx.currencyDollar(_ctx.totalCost ? _ctx.totalCost : 0)), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.t('confirm')), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_v_progress_circular, {
                key: 1,
                indeterminate: "",
                color: "#F9F9F9"
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["disabled"])
    ])
  ]))
}