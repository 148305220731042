import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "impact-by-brands-list-item" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "flavor-text" }
const _hoisted_4 = { class: "flavor-text brand-name" }
const _hoisted_5 = { class: "impacts" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_impact_pill = _resolveComponent("impact-pill")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("button", {
      class: "impact-by-brands-button",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openPopup && _ctx.openPopup(...args)))
    }, [
      _createElementVNode("img", {
        height: "80",
        width: "80",
        src: _ctx.brand?.imageURL || require('@/assets/icons/greenspark-logo-vertical-green.svg'),
        alt: _ctx.brand?.companyName,
        class: "company-logo",
        loading: "lazy"
      }, null, 8, _hoisted_2),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.t('flavor_text')), 1),
      _createElementVNode("p", _hoisted_4, [
        _createElementVNode("b", null, _toDisplayString(_ctx.brand?.companyName), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.brand?.impacts || [], (impact, index) => {
          return (_openBlock(), _createBlock(_component_impact_pill, {
            key: index,
            impact: { type: impact.type || 'multiple', amount: impact.amount }
          }, null, 8, ["impact"]))
        }), 128))
      ])
    ])
  ]))
}