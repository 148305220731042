<template>
  <div :class="['impact-pill', theme, impact.type]">
    <svg class="impact-icon">
      <use :href="iconIds[impact.type]" />
    </svg>
    <p v-if="impact?.amount" class="impact-amount mb-0">
      {{ formatTotalAmounts(impact.amount) }}
    </p>
  </div>
</template>

<script lang="ts">
import type { OffsetType } from '@/helpers/interfaces'
import { Utils } from '@/helpers/mixins/utilsMixin'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ImpactPill',
  mixins: [Utils],
  props: {
    impact: {
      type: Object as PropType<{ type: OffsetType | 'multiple'; amount: number }>,
      required: true,
    },
    theme: {
      default: 'outlined',
      type: String as PropType<'outlined' | 'filled'>,
    },
  },
  computed: {
    iconIds(): Record<OffsetType | 'multiple', string> {
      return {
        trees: '#trees-icon',
        carbon: '#carbon-icon',
        plastic: '#plastic-icon',
        kelp: '#kelp-icon',
        water: '#water-icon',
        bees: '#bees-icon',
        multiple: '#multiple-icon',
      } satisfies Record<OffsetType | 'multiple', string>
    },
  },
})
</script>

<style lang="scss" scoped>
.impact-pill {
  align-items: center;
  display: flex;
  flex-shrink: 0;

  &.outlined {
    color: var(--ui-green);
    border-radius: 4px;
    border: 1px solid var(--ui-green);
    padding: 4px 8px;
    gap: 4px;
    min-height: 28px;

    .impact-icon {
      height: 19px;
      width: 20px;
      font-size: 20px;
      font-weight: 700;
      flex-shrink: 0;
    }

    .impact-amount {
      font-size: 14px;
      line-height: 14px;
      font-weight: 500;
      flex-shrink: 0;
    }

    &.trees {
      border-color: var(--ui-green);
      color: var(--ui-green);

      .impact-icon {
        height: 15px;
      }
    }

    &.kelp {
      border-color: var(--ui-torquoise);
      color: var(--ui-torquoise);
    }

    &.carbon {
      color: var(--ui-orange);
      border-color: var(--ui-orange);
    }

    &.plastic {
      border-color: var(--ui-blue-light);
      color: var(--ui-blue-light);
    }

    &.water {
      border-color: var(--ui-purple-light);
      color: var(--ui-purple-light);
    }

    &.bees {
      border-color: var(--ui-brown);
      color: var(--ui-brown);
    }

    &.multiple {
      border-color: var(--ui-black);
      color: var(--ui-black);
    }
  }

  &.filled {
    color: var(--ui-beige);
    border-radius: 10px;
    padding: 5px;
    gap: 4px;

    .impact-icon {
      height: 16px;
      width: 16px;
      font-size: 16px;
    }

    .impact-amount {
      font-size: 14px;
      line-height: 14px;
      font-weight: 500;
      flex-shrink: 0;
    }

    &.trees {
      background-color: var(--ui-green);
    }

    &.kelp {
      background-color: var(--ui-torquoise);
    }

    &.carbon {
      background-color: var(--ui-beige);
      color: var(--ui-blue);
    }

    &.plastic {
      background-color: var(--ui-blue);
    }
  }
}
</style>
