import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "signup"
}
const _hoisted_2 = { class: "signup-wrapper" }
const _hoisted_3 = { class: "totals-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChoosePersonalPlan = _resolveComponent("ChoosePersonalPlan")!
  const _component_AddOnsPersonal = _resolveComponent("AddOnsPersonal")!
  const _component_UserInfo1 = _resolveComponent("UserInfo1")!
  const _component_Signup1 = _resolveComponent("Signup1")!
  const _component_TotalsPersonal = _resolveComponent("TotalsPersonal")!

  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.progressItem === 0)
            ? (_openBlock(), _createBlock(_component_ChoosePersonalPlan, {
                key: "0",
                account: _ctx.account,
                "earth-hero-price": _ctx.earthHeroPrice,
                "personal-price": _ctx.personalPrice,
                "plastic-hero-price": _ctx.plasticHeroPrice,
                onNextClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.progressItem++))
              }, null, 8, ["account", "earth-hero-price", "personal-price", "plastic-hero-price"]))
            : _createCommentVNode("", true),
          (_ctx.progressItem === 1)
            ? (_openBlock(), _createBlock(_component_AddOnsPersonal, {
                key: "1",
                onBackClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.progressItem--)),
                onNextClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.progressItem++))
              }))
            : _createCommentVNode("", true),
          (_ctx.progressItem === 2)
            ? (_openBlock(), _createBlock(_component_UserInfo1, {
                key: "2",
                onBackClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.progressItem--)),
                onNextClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.progressItem++))
              }))
            : _createCommentVNode("", true),
          (_ctx.progressItem === 3)
            ? (_openBlock(), _createBlock(_component_Signup1, {
                key: "3",
                onBackClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.progressItem--)),
                onCheckoutClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.checkout())),
                onSetLineItemsClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.setLineItems()))
              }))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_TotalsPersonal, {
              "earth-hero-price": _ctx.earthHeroPrice,
              "personal-price": _ctx.personalPrice,
              "plastic-hero-price": _ctx.plasticHeroPrice,
              "progress-item": _ctx.progressItem
            }, null, 8, ["earth-hero-price", "personal-price", "plastic-hero-price", "progress-item"])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}