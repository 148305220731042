import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 0,
  class: "sort"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: `number${_ctx.elementId}`,
    class: _normalizeClass(_ctx.hidePartnerImage ? 'table-header-outer' + ' ' + 'no-padding' : 'table-header-outer')
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (item, a) => {
      return (_openBlock(), _createElementBlock("div", {
        key: a,
        class: _normalizeClass(!item.sortable ? 'table-header-item' : 'table-header-item pointer'),
        style: _normalizeStyle(
        item.customWidth
          ? `width: ${item.customWidth}px; min-width: ${item.customWidth}px`
          : `width: ${_ctx.columnWidth}px; min-width: 115px;`
      ),
        onClick: ($event: any) => (item.sortable ? _ctx.sort(item.value, item.sortDirection, a) : '')
      }, [
        _createElementVNode("div", null, _toDisplayString(item.label), 1),
        (item.sortedBy)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (item.sortDirection === 'asc')
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    style: {"width":"10px","margin-bottom":"1px"},
                    class: "top",
                    src: require('@/assets/icons/arrow-up.svg'),
                    alt: "up"
                  }, null, 8, _hoisted_4))
                : _createCommentVNode("", true),
              (item.sortDirection === 'desc')
                ? (_openBlock(), _createElementBlock("img", {
                    key: 1,
                    style: {"width":"10px","margin-top":"1px"},
                    class: "bottom",
                    src: require('@/assets/icons/arrow-down.svg'),
                    alt: "down"
                  }, null, 8, _hoisted_5))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ], 14, _hoisted_2))
    }), 128))
  ], 10, _hoisted_1))
}