import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WidgetDialog = _resolveComponent("WidgetDialog")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['widget-wrapper', { 'cart-based': _ctx.type === 'orderImpacts' }])
  }, [
    _renderSlot(_ctx.$slots, "widget", {}, undefined, true),
    _createVNode(_component_WidgetDialog, {
      mode: _ctx.dialogMode,
      type: _ctx.type,
      "widget-api-key": _ctx.apiKey,
      color: _ctx.color,
      style: _normalizeStyle(_ctx.style),
      url: _ctx.url
    }, {
      default: _withCtx(() => [
        (_ctx.type !== 'banner')
          ? _renderSlot(_ctx.$slots, "widget", { key: 0 }, undefined, true)
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 8, ["mode", "type", "widget-api-key", "color", "style", "url"])
  ], 2))
}