<!-- TODO: Remove these eslint comments. We need to fix this prop mutation ASAP. -->
<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div v-if="!loading">
    <v-dialog v-model="dialog" width="510" persistent :fullscreen="isMobile">
      <div class="pop-up">
        <div class="close-wrapper" @click="$emit('closeDialogClick')">
          <div class="overlay-header">
            {{ t('header') }}
          </div>

          <img
            style="width: 25px"
            class="close-icon"
            :src="require('@/assets/icons/close_icon.svg')"
            alt="close"
          />
        </div>
        <div class="overlay-text">
          {{ text }}
        </div>

        <div class="dialog-button-wrapper">
          <gs-button type="primary" size="large" full-width @click="checkout()">
            {{ t('button') }}
          </gs-button>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import type { Account } from '@/store'
import { defineComponent } from 'vue'
import type { TranslateResult } from 'vue-i18n'
import { Utils } from '@/helpers/mixins/utilsMixin'

export default defineComponent({
  name: 'AddCardPopUp',
  emits: ['closeDialogClick'],
  mixins: [Utils],
  data() {
    return {
      windowWidth: 0,
      loading: false,
    }
  },
  computed: {
    text(): string | TranslateResult {
      switch (this.type) {
        case 'integration':
          if (!this.hasSubscription && !this.accountNeedsUpdate) {
            return this.t('text_integration')
          } else if (this.accountNeedsUpdate) {
            return this.t('text_upgrade')
          }
          break
        case 'partner':
          return this.t('text_partner')
        case 'teams':
          return this.t('text_teams')
      }
      return ''
    },
    account(): Account {
      return this.$store.getters['getAccount']
    },
  },
  async created() {
    this.loading = true
    window.addEventListener('resize', this.reportWindowSize)
    this.reportWindowSize()
    this.loading = false
  },
  unmounted() {
    window.removeEventListener('resize', this.reportWindowSize)
  },
  methods: {
    t(key: string) {
      return this.$t(`AddCardPopUp.${key}`)
    },
    reportWindowSize() {
      this.windowWidth = window.innerWidth
    },
    checkout() {
      if (!this.accountNeedsUpdate) {
        const searchParams = `type=addcard&total=0&currency=${this.account.currency}&frompage=${this.fromPage}`
        this.$router.push(`/checkout?${searchParams}`)
      } else {
        this.$router.push('/upgrade-plan')
      }
    },
  },
  props: {
    dialog: {
      type: Boolean,
    },
    type: {
      type: String,
    },
    fromPage: {
      type: String,
    },
    accountNeedsUpdate: {
      type: Boolean,
    },
    hasSubscription: {
      type: Boolean,
    },
  },
})
</script>

<style lang="scss" scoped>
.pop-up {
  background: #f9f9f9;
  padding: 25px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  height: 100%;
  overflow-y: scroll;
}

.close-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: -10px;
}

.close-icon {
  cursor: pointer;
}

.overlay-header {
  color: #212121;
  font-weight: 500;
  font-size: 20px;
  text-align: left;
  width: 100%;
}

.overlay-text {
  color: #212121;
  font-weight: 400;
  font-size: 16px;
  text-align: left;
  margin-top: 25px;
  width: 100%;
}

.dialog-button-wrapper {
  width: 100%;
  margin-top: 24px;
}
</style>
