<template>
  <v-dialog width="1175" v-model="isOpen" persistent :fullscreen="$vuetify.display.xs">
    <v-card class="card d-flex flex-column">
      <v-form v-model="isFormValid">
        <div class="popup-header">
          <p class="popup-title">{{ t('title') }}</p>
          <p class="popup-description">
            {{ t('description') }}
          </p>
          <button class="close-button" @click.prevent="close" variant="plain"></button>
        </div>
        <div class="input-wrapper">
          <div>
            <div class="input-wrapper-link">
              <label for="rule-id">{{ t('fields.ruleId') }}</label>
              <a
                :href="STAMPED_LOYALTY_DOCUMENTATION_URL"
                target="_blank"
                class="documentation-url"
              >
                {{ t('where_to_find_rule_id') }}
              </a>
            </div>
            <gs-input
              v-model="localRule.ruleId"
              id="rule-id"
              :hide-details="false"
              :disabled="selectedRule?.ruleId"
              :placeholder="t('headers.ruleId')"
              :rules="[rules.required]"
            />
          </div>
          <div>
            <label for="rule-name">{{ t('fields.ruleName') }}</label>
            <gs-input
              v-model="localRule.name"
              id="rule-name"
              :hide-details="false"
              :placeholder="t('headers.ruleName')"
              :rules="[rules.required]"
            />
          </div>
        </div>
        <ImpactSettings v-model="impactSettings" :max-values="maxValues" />
        <div class="totals-wrapper section">
          <p class="cart-items-text">
            {{ getImpactSettingCartText(impactSettings, selectedTrigger) }}
          </p>
          <p class="total-text">
            {{
              t('total', {
                price: userCurrencySymbol + getImpactSettingTotal(impactSettings),
              })
            }}
          </p>
        </div>
        <gs-button
          @click.prevent="handleSave"
          :loading="loading"
          :disabled="loading || !isFormValid"
          full-width
          type="tertiary"
          size="large"
        >
          {{ t('save') }}
        </gs-button>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import type { AutomationOffset } from '@/store/integrations'
import ImpactSettings from '@/components/onboarding/ImpactSettings.vue'
import { STAMPED_LOYALTY_DOCUMENTATION_URL } from '@/helpers/constants'
import { PROJECT_ID_IMPACT_TYPE_MAP } from '@/helpers/projects'
import { Utils } from '@/helpers/mixins/utilsMixin'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import type { AutomationTrigger } from '@/helpers/interfaces'
import { createRule, type RuleWithOffsets, updateRule } from '@api/index'
import { RulesMixin } from '@/helpers/mixins/RulesMixin.vue'
import type { Notification } from '@/store/notification'
import { IntegrationsMixin } from '@/helpers/mixins/integrationsMixin'

export default defineComponent({
  name: 'RuleImpactSettingsPopup',
  emits: ['close', 'save'],
  mixins: [Utils, RulesMixin, IntegrationsMixin],
  components: {
    ImpactSettings,
  },
  data() {
    return {
      isOpen: true,
      localRule: {} as RuleWithOffsets,
      projectImpacts: {},
      impactSettings: [],
      isFormValid: true,
      lastVisibleCardIndex: 0,
      loading: false,
    } as {
      isOpen: boolean
      localRule: RuleWithOffsets
      projectImpacts: Record<string, number>
      impactSettings: AutomationOffset[]
      isFormValid: boolean
      lastVisibleCardIndex: number
      loading: boolean
    }
  },
  created() {
    if (this.selectedRule) {
      this.localRule = { ...this.selectedRule }

      if (this.selectedRule.offsets.length) {
        this.selectedRule.offsets.forEach((offset) => {
          const { projectId, amount } = offset

          if (projectId && amount) {
            this.projectImpacts[projectId] = amount
          }
        })

        this.onProjectImpactUpdate()
      }
    } else {
      this.localRule = {} as RuleWithOffsets
    }
  },
  computed: {
    selectedTrigger(): AutomationTrigger {
      return 'byLoyaltyPoints'
    },
    userCurrencySymbol(): string {
      return this.$store.getters['getUserCurrencySymbol']
    },
    STAMPED_LOYALTY_DOCUMENTATION_URL() {
      return STAMPED_LOYALTY_DOCUMENTATION_URL
    },
  },
  methods: {
    t(key: string, params?: Record<string, string>) {
      return this.$t(`RuleTable.${key}`, params ?? {})
    },
    close() {
      this.isOpen = !this.isOpen
      this.localRule = {} as RuleWithOffsets
      this.$emit('close')
    },
    setVisibleIndex(payload: number) {
      this.lastVisibleCardIndex = payload
    },
    onProjectImpactUpdate() {
      this.impactSettings = Object.entries(this.projectImpacts).map(([key, value]) => ({
        type: PROJECT_ID_IMPACT_TYPE_MAP[key],
        amount: value,
        projectId: key,
      })) as AutomationOffset[]
    },
    async handleSave() {
      this.loading = true

      try {
        if (this.selectedRule?.id) {
          const { data: updatedRule } = await updateRule(this.integrationId, this.localRule.id, {
            name: this.localRule.name,
          })

          this.localRule = {
            ...updatedRule,
            offsets: this.impactSettings.filter((impact) => !!impact.amount),
          }
        } else {
          const existingRule = this.ruleList.find((rule) => rule.ruleId === this.localRule.ruleId)

          if (!existingRule) {
            const { data: newRule } = await createRule(this.integrationId, {
              ruleId: this.localRule.ruleId,
              name: this.localRule.name,
            })

            this.localRule = {
              ...newRule,
              offsets: this.impactSettings.filter((impact) => !!impact.amount),
            }
          } else {
            return this.$store.dispatch('notification/notify', {
              text: this.t('existing_rule_validation'),
              isError: true,
            } as Notification)
          }
        }

        this.$emit('save', this.localRule)

        this.close()
      } catch (error) {
        this.$store.dispatch('notification/notify', {
          text: 'An unexpected error occurred. Please try again or get in touch.',
          isError: true,
        } as Notification)
        console.error(error)
      } finally {
        this.loading = false
      }
    },
  },
  props: {
    maxValues: {
      default: () => [],
      type: Array as PropType<Omit<Required<AutomationOffset>, 'type' | 'source'>[]>,
    },
    selectedRule: {
      type: Object as PropType<RuleWithOffsets>,
    },
    ruleList: {
      type: Array as PropType<RuleWithOffsets[]>,
      default: () => [],
    },
    integrationId: {
      type: String,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.card {
  padding: 16px;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    padding: 32px;
  }

  :deep(.gs-button) {
    overflow: visible;
    padding: 19px 0 !important;
  }
}

.popup-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  padding-right: 40px;
}

.impact-settings {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  column-gap: 24px;
  row-gap: 14px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    overflow: visible;
    flex-wrap: wrap;
  }

  :deep(.select-project-wrapper) {
    padding-bottom: 0;
  }
}

.impact-settings-wrapper {
  position: relative;
}

.markers {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 25px 0 20px;
  gap: 15px;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    display: none;
  }
}

.marker-dot {
  height: 10px;
  width: 10px;
  background-color: var(--gray-light-20);
  box-shadow: var(--box-shadow);
  border-radius: 50%;
  border: 1px solid var(--gray-light);
  transition: all ease 0.3s;
}

.marker-dot.visible {
  background-color: var(--ui-green-light);
  border: none;
  transition: all ease 0.3s;
}

.totals-wrapper {
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 24px;
  margin: 21px 0;
  border-top: 1px solid var(--gray-light-CC);
}

.cart-items-text {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  color: #7c7c7c;
  margin-bottom: 10px;
}

.total-text {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 0;
}

.filter-button-list {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.filter-button {
  border-radius: var(--border-radius-big);
  padding: 5px 8px;
  font-size: 16px;
  line-height: 20px;
  color: var(--gray-light-FF);
  background-color: #7c7c7c;
}

.filter-button.is-active {
  background-color: var(--ui-black);
  cursor: auto;
}

.input-wrapper {
  display: flex;
  gap: 16px;
  flex-direction: column;
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--gray-light-CC);

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    flex-direction: row;
    gap: 32px;
  }

  label {
    display: inline-block;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  > div {
    width: 100%;
  }

  .input-wrapper-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      flex-direction: row;
    }
  }
}

.documentation-url {
  color: var(--ui-dark-blue);
  font-size: 14px;
  font-weight: 700;
}
</style>
