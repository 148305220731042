<template>
  <v-dialog
    class="change-plan-popup"
    :model-value="isOpen"
    @update:model-value="
      (value) => {
        isOpen = value
        if (!value) $emit('close')
      }
    "
    :fullscreen="isMobile"
    max-width="695"
  >
    <v-card class="card d-flex flex-column">
      <div class="popup-header">
        <p class="upgrade-title">
          {{ isUpgrade ? t('upgrade_title') : t('downgrade_title') }}
        </p>
        <button
          class="close-button"
          @click="
            () => {
              isOpen = false
              $emit('close')
            }
          "
        />
      </div>
      <p
        v-if="isUpgrade"
        class="upgrade-text"
        v-html="
          t(isTrial ? 'upgrade_from_trial.subtitle' : 'upgrade.subtitle', {
            currentPlan: currentPlanType && getPlanName(currentPlanType),
            selectedPlan: selectedPlanType && getPlanName(selectedPlanType),
          })
        "
      />
      <p
        v-else
        class="upgrade-text"
        v-html="
          t(
            isTrial ? 'downgrade_from_trial.subtitle' : 'downgrade.subtitle',
            currentPlanType &&
              selectedPlanType && {
                currentPlan: getPlanName(currentPlanType),
                selectedPlan: getPlanName(selectedPlanType),
              },
          )
        "
      />
      <div class="price-difference-wrapper" v-if="selectedPlanType">
        <div class="price-title-wrapper">
          <span class="upgrade-icon-wrapper">
            <img
              class="upgrade-icon"
              :src="
                require(isUpgrade ? '@/assets/icons/upgrade.svg' : '@/assets/icons/downgrade.svg')
              "
            />
          </span>
          <span class="plan-name">{{ getPlanName(selectedPlanType) }} {{ t('plan') }}</span>
        </div>
        <span class="plan-price">{{ displayPrice }}</span>
      </div>
      <p
        class="upgrade-disclaimer"
        v-if="isUpgrade"
        v-html="
          t(
            isTrial
              ? isSelectedPlanYearly
                ? 'upgrade_from_trial.disclaimer_per_year'
                : 'upgrade_from_trial.disclaimer_per_month'
              : 'upgrade.disclaimer',
            {
              totalAmount: displayPrice,
            },
          )
        "
      />
      <p
        class="upgrade-disclaimer"
        v-else
        v-html="
          t(
            isTrial
              ? isSelectedPlanYearly
                ? 'downgrade_from_trial.disclaimer_per_year'
                : 'downgrade_from_trial.disclaimer_per_month'
              : 'downgrade.disclaimer',
            {
              totalAmount: displayPrice,
            },
          )
        "
      />
      <div v-if="isDowngradeDisabled" class="error-message">
        <ul class="issue-list">
          <li
            v-for="({ issue, currentAmount, maxAmount }, index) in downgradeIssues"
            :key="index"
            class="issue-list-item"
          >
            <v-icon size="24px" class="issue-icon"> mdi-information-outline </v-icon>
            <div>{{ t(snakeCase(issue), { currentAmount, maxAmount }) }}</div>
          </li>
        </ul>
      </div>
      <v-checkbox
        v-if="!isDowngradeDisabled"
        v-model="checkbox"
        color="green"
        hide-details
        :rules="[rules.required]"
        class="terms-checkbox"
      >
        <template v-slot:label>
          <i18n-t tag="p" keypath="Signup.accept" class="terms">
            <a :href="TERMS_CONDITIONS_URL" target="_blank" class="link">{{ t('terms') }}</a>
            <a :href="PRIVACY_POLICY_URL" target="_blank" class="link">{{ t('privacy') }}</a>
          </i18n-t>
        </template>
      </v-checkbox>
      <gs-button
        type="tertiary"
        size="large"
        class="upgrade-button"
        full-width
        :disabled="!checkbox || loading || isDowngradeDisabled"
        @click="changePlan()"
      >
        <v-progress-circular v-if="loading" indeterminate color="#F9F9F9" />
        <span v-else-if="isUpgrade">{{
          isTrial ? t('upgrade_from_trial_button_text') : t('upgrade_button_text')
        }}</span>
        <span v-else>{{
          isTrial ? t('downgrade_from_trial_button_text') : t('downgrade_button_text')
        }}</span>
      </gs-button>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { getSubscriptionItems, getSubscriptions, updateAccountSubscription } from '@api/index'
import {
  NEW_PAID_BUSINESS_SUBSCRIPTION_TYPES,
  PRIVACY_POLICY_URL,
  TERMS_CONDITIONS_URL,
} from '@/helpers/constants'
import { formatCurrencyValueForDisplay, isProductUpgrade } from '@/helpers/pricing'
import { RulesMixin } from '@/helpers/mixins/RulesMixin.vue'
import { type DowngradeIssue, DowngradeMixin } from '@/helpers/mixins/downgradeMixin'
import { Utils } from '@/helpers/mixins/utilsMixin'
import { defineComponent } from 'vue'

import type { SubscriptionItem } from '@/store/subscriptions'
import type { CurrencyCode } from '@/helpers/interfaces'
import type { PropType } from 'vue'
import type { Notification } from '@/store/notification'
import type { Account } from '@/store'
import type { Subscription } from '@api/index'
import type {
  NewPaidBusinessSubscriptionType,
  PersonalSubscriptionType,
  SubscriptionType,
  TechnicalAndBusinessSubscriptionType,
} from '@/helpers/pricing'

export default defineComponent({
  name: 'ChangePlanPopup',
  emits: ['close'],
  mixins: [RulesMixin, DowngradeMixin, Utils],
  data() {
    return {
      isOpen: true,
      loading: false,
      checkbox: false,
      PRIVACY_POLICY_URL: '',
      TERMS_CONDITIONS_URL: '',
      hasActivePartners: false,
      hasPaymentDetails: false,
    }
  },
  computed: {
    isUpgrade(): boolean {
      if (!this.currentPlanType || !this.selectedPlanType) return false
      return isProductUpgrade(this.currentPlanType, this.selectedPlanType) === 'upgrade'
    },
    isTrial(): boolean {
      return this.$store.getters['isActiveSubscriptionOnTrial']
    },
    isBusinessPlanSelected() {
      return NEW_PAID_BUSINESS_SUBSCRIPTION_TYPES.includes(
        this.selectedPlanType as NewPaidBusinessSubscriptionType,
      )
    },
    downgradeIssues(): DowngradeIssue[] {
      return this.selectedPlanType && this.isBusinessPlanSelected
        ? this.reportDowngradeIssues(this.selectedPlanType as NewPaidBusinessSubscriptionType)
        : []
    },
    isDowngradeDisabled(): boolean {
      if (this.isUpgrade || !this.selectedPlanType) return false

      if (this.isBusinessPlanSelected) {
        return !!this.reportDowngradeIssues(
          this.selectedPlanType as NewPaidBusinessSubscriptionType,
        ).length
      }

      return false
    },
    price(): number {
      const selectedPrice = this.selectedPlanPrice ?? 0
      return this.isSelectedPlanYearly ? selectedPrice * 12 : selectedPrice
    },
    displayPrice(): string {
      return `${this.userCurrencySymbol}${formatCurrencyValueForDisplay(this.price)} ${
        this.userCurrencyCode
      }`
    },
    userCurrencySymbol(): string {
      return this.$store.getters['getUserCurrencySymbol']
    },
    userCurrencyCode(): CurrencyCode {
      return this.$store.getters['getUserCurrencyCode']
    },
    account(): Account {
      return this.$store.getters['getAccount']
    },
    impactWallet(): boolean {
      return this.$store.getters['getImpactWalletFeatureSetting']
    },
    maxCustomEmailTemplateAmount(): number {
      return this.$store.getters['getMaxCustomEmailTemplateAmount']
    },
    maxCustomDomainAmount(): number {
      return this.$store.getters['getMaxCustomDomainAmount']
    },
    isActiveSubscriptionOnTrial(): boolean {
      return this.$store.getters['isActiveSubscriptionOnTrial']
    },
    hasOwnPaidSubscriptionItem(): boolean {
      return this.$store.getters['hasOwnPaidSubscriptionItem']
    },
    hasOwnFreeSubscriptionItem(): boolean {
      return this.$store.getters['hasOwnFreeSubscriptionItem']
    },
    hasPaymentDetailsGetter(): (subscriptions: Subscription[]) => boolean {
      return this.$store.getters['hasPaymentDetails']
    },
  },
  async created() {
    this.PRIVACY_POLICY_URL = PRIVACY_POLICY_URL
    this.TERMS_CONDITIONS_URL = TERMS_CONDITIONS_URL
    const subscriptions = await getSubscriptions()
    this.hasPaymentDetails = this.hasPaymentDetailsGetter(subscriptions)
    if (this.maxCustomDomainAmount) await this.setDomainList()
    if (this.maxCustomEmailTemplateAmount) await this.setEmailTemplateList()
  },
  methods: {
    t(key: string, params?: { [key: string]: string | number | undefined }) {
      return this.$t(`ChangePlanPopup.${key}`, params ?? {})
    },
    async changePlan() {
      this.loading = true
      // in case user has a free plan, navigate to checkout
      if (!this.hasPaymentDetails) {
        await this.$router.push({
          path: '/checkout',
          query: {
            type: 'subscription',
            plan: this.selectedPlanType,
            total: this.price.toString(),
            trial: this.$route.path === '/select-plan' ? 'true' : 'false',
          },
        })
      } else {
        try {
          if (!this.selectedPlanType) return

          await updateAccountSubscription({ product: this.selectedPlanType })
          this.setSubscriptionItems(await getSubscriptionItems())
          await this.setAccount()
          await this.setRequiredActionAlert()
          this.loading = false
          this.$emit('close')
          this.$store.dispatch('notification/notify', {
            text: this.t('plan_updated'),
          } as Notification)
          if (this.$route.query.tab)
            await this.$router.push({ path: '/account', query: { tab: this.$route.query.tab } })
          else await this.$router.back()
        } catch (e) {
          console.error(e?.message)
          this.$emit('close')
          this.$store.dispatch('notification/notify', {
            text: this.t('error'),
            isError: true,
          } as Notification)
        }
      }
    },
    getPlanName(planType: SubscriptionType): string {
      switch (planType) {
        case 'free':
        case 'zero':
        case 'freeBusiness':
          return 'Free'
        case 'ECOMMERCE':
        case 'ecommerce':
        case 'businessLegacy':
          return 'Ecommerce'
        case 'starterBusiness':
          return 'Starter'
        case 'starterBusinessYearly':
          return 'Yearly Starter'
        case 'growthBusiness':
          return 'Growth'
        case 'growthBusinessYearly':
          return 'Yearly Growth'
        case 'premiumBusiness':
          return 'Premium'
        case 'premiumBusinessYearly':
          return 'Yearly Premium'
        case 'enterpriseBusiness':
          return 'Enterprise'
        default:
          throw new Error('Unknown plan type')
      }
    },
    setAccount(): Promise<void> {
      return this.$store.dispatch('setAccount')
    },
    setDomainList() {
      return this.$store.dispatch('setDomainList')
    },
    setEmailTemplateList() {
      return this.$store.dispatch('setEmailTemplateList')
    },
    setRequiredActionAlert(): Promise<void> {
      return this.$store.dispatch('setRequiredActionAlert')
    },
    setSubscriptionItems(items: SubscriptionItem[]) {
      return this.$store.commit('setSubscriptionItems', items)
    },
  },
  props: {
    currentPlanType: {
      type: String as PropType<TechnicalAndBusinessSubscriptionType>,
    },
    selectedPlanType: {
      type: String as PropType<NewPaidBusinessSubscriptionType | PersonalSubscriptionType>,
    },
    selectedPlanPrice: {
      type: Number,
    },
    isCurrentPlanYearly: {
      type: Boolean,
    },
    isSelectedPlanYearly: {
      type: Boolean,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.change-plan-popup {
  .card.v-card {
    position: relative;
    padding: 32px;
    border-radius: 0;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      border-radius: 20px;
    }
  }
}

.upgrade-title {
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  margin-bottom: 24px;
  padding-right: 16px;
}

.upgrade-text {
  font-size: 24px;
  font-weight: 700;
  line-height: 39px;
  margin-bottom: 20px;
}

.upgrade-text:deep(span) {
  background: var(--ui-beige);
  border-radius: 8px;
  line-height: 24px;
  padding: 4px 6px;
  font-weight: 700;
  white-space: nowrap;
}

.price-difference-wrapper {
  border-radius: 20px;
  border: 2px solid var(--ui-green);
  margin-bottom: 20px;
  padding: 20px 28px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    flex-direction: row;
  }

  .price-title-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: inherit;
    flex-grow: 1;
    flex-shrink: 0;

    .upgrade-icon-wrapper {
      flex-shrink: 0;
      border-radius: 4px;
      background-color: var(--ui-green);
      height: 40px;
      width: 40px;
      padding: 6px;

      .upgrade-icon {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .plan-name {
      flex-shrink: 1;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
    }
  }

  .plan-price {
    font-size: 40px;
    font-weight: 700;
    line-height: 49px;
    justify-self: flex-end;
  }
}

.upgrade-disclaimer {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
}

.upgrade-button {
  color: var(--ui-white);
  font-weight: 600;
  font-size: 20px;
}

.close-button {
  position: absolute;
  right: 20px;
  top: 25px;
  height: 20px;
}

.terms-checkbox {
  width: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  .terms {
    color: var(--font-color-primary);
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    flex-wrap: wrap;
    margin-bottom: 0;
    margin-left: 8px;
  }
}

.link {
  margin-left: 3px;
  margin-right: 3px;
  color: var(--ui-green);
  font-weight: bold;
}

.error-message {
  width: 100%;
  padding: 8px;
  background: var(--ui-red);
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}

.issue-list {
  list-style-type: none;
  padding-left: 0;
}

.issue-list-item {
  position: relative;
  line-height: 20px;
  font-size: 16px;
  font-weight: 700;
  color: var(--ui-white);
  text-align: left;
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 15px;
}

.issue-icon {
  color: inherit;
  font-size: inherit;
  padding: 4px;
}

.error-message .issue-list-item:last-child {
  margin: 0;
}
</style>
