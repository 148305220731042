import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, mergeProps as _mergeProps, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "pop-up" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_loading = _resolveComponent("loading")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _cache[8] || (_cache[8] = _createElementVNode("h1", null, "Delete subscription", -1)),
          _cache[9] || (_cache[9] = _createElementVNode("span", null, " This functionality delete the subscription of the selected account. ", -1)),
          _createVNode(_component_v_autocomplete, {
            modelValue: _ctx.selectedAccountItem,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedAccountItem) = $event)),
            search: _ctx.search,
            "onUpdate:search": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.search) = $event)),
            loading: _ctx.loading,
            items: _ctx.accounts.map((account) => `${account.accountId} - ${account.companyName}`),
            label: 'Accounts',
            variant: "outlined",
            color: "#3B755F",
            "item-color": "#3B755F",
            class: "dropdown",
            placeholder: 'Min 3 characters to search',
            rules: [_ctx.rules.required]
          }, null, 8, ["modelValue", "search", "loading", "items", "rules"]),
          _createVNode(_component_v_text_field, {
            id: "Reason",
            modelValue: _ctx.reason,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.reason) = $event)),
            class: "input-field",
            name: "Reason",
            rules: [_ctx.rules.required],
            label: 'Reason',
            variant: "outlined",
            flat: "",
            color: "green",
            "hide-details": ""
          }, null, 8, ["modelValue", "rules"]),
          _createVNode(_component_v_dialog, {
            modelValue: _ctx.dialog,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.dialog) = $event)),
            width: "480"
          }, {
            activator: _withCtx(({ props }) => [
              _createVNode(_component_gs_button, _mergeProps(props, {
                disabled: _ctx.loading || !_ctx.selectedAccountItem || !_ctx.reason,
                loading: _ctx.loading,
                "full-width": true,
                size: "large",
                onClick: _withModifiers(_ctx.openDialog, ["prevent"])
              }), {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode(" Save ")
                ])),
                _: 2
              }, 1040, ["disabled", "loading", "onClick"])
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", {
                  class: "close-wrapper",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.dialog = false))
                }, [
                  _createElementVNode("img", {
                    style: {"width":"25px"},
                    class: "close-icon",
                    src: require('@/assets/icons/close_icon.svg'),
                    alt: "close"
                  }, null, 8, _hoisted_3)
                ]),
                _createElementVNode("div", null, [
                  _cache[7] || (_cache[7] = _createElementVNode("h2", null, "Are you sure you want to delete the following subscription?", -1)),
                  _createElementVNode("div", null, "Company name: " + _toDisplayString(_ctx.getCompanyName()), 1),
                  _createElementVNode("div", null, "Fist name: " + _toDisplayString(_ctx.user?.firstName), 1),
                  _createElementVNode("div", null, "Last name: " + _toDisplayString(_ctx.user?.lastName), 1),
                  _createElementVNode("div", null, "Email: " + _toDisplayString(_ctx.user?.email), 1),
                  _createVNode(_component_gs_button, {
                    disabled: _ctx.loading || !_ctx.selectedAccountItem || !_ctx.reason,
                    loading: _ctx.loading,
                    "full-width": true,
                    size: "large",
                    onClick: _withModifiers(_ctx.deleteSubscription, ["prevent"])
                  }, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createTextVNode(" Confirm ")
                    ])),
                    _: 1
                  }, 8, ["disabled", "loading", "onClick"])
                ])
              ])
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_loading, { key: 1 }))
      : _createCommentVNode("", true)
  ]))
}