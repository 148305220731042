import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "price-comparison-dialog" }
const _hoisted_2 = { class: "price-comparison-dialog-header" }
const _hoisted_3 = { class: "dialog-title" }
const _hoisted_4 = { class: "dialog-desc" }
const _hoisted_5 = { colspan: "2" }
const _hoisted_6 = { colspan: "2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_gs_button = _resolveComponent("gs-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.t('title')), 1),
      _createVNode(_component_v_btn, {
        class: "price-comparison-dialog-close-button",
        icon: "",
        onClick: _ctx.close,
        variant: "plain"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_icon, { color: "black" }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode(" mdi-close ")
            ])),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.t('desc')), 1),
    _createElementVNode("table", null, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _cache[1] || (_cache[1] = _createElementVNode("th", null, null, -1)),
          _createElementVNode("th", null, _toDisplayString(_ctx.t('current_plan')), 1),
          _createElementVNode("th", null, _toDisplayString(_ctx.t('new_plan')), 1)
        ])
      ]),
      _createElementVNode("tbody", null, [
        _createElementVNode("tr", null, [
          _createElementVNode("td", null, _toDisplayString(_ctx.$t('trees.kenya_feature_label')), 1),
          _createElementVNode("td", null, _toDisplayString(_ctx.$t('trees.short_price', {
                price: _ctx.getFormattedProjectPriceById(_ctx.VERITREE_EARTHLUNGS_PROJECT_ID),
              })), 1),
          _createElementVNode("td", null, _toDisplayString(_ctx.$t('trees.short_price', { price: _ctx.paidPlanPrice(_ctx.VERITREE_EARTHLUNGS_PROJECT_ID) })), 1)
        ]),
        _createElementVNode("tr", null, [
          _createElementVNode("td", null, _toDisplayString(_ctx.$t('trees.oregon_feature_label')), 1),
          _createElementVNode("td", null, _toDisplayString(_ctx.$t('trees.short_price', {
                price: _ctx.getFormattedProjectPriceById(_ctx.OREGON_TREES_PROJECT_ID),
              })), 1),
          _createElementVNode("td", null, _toDisplayString(_ctx.$t('trees.short_price', { price: _ctx.paidPlanPrice(_ctx.OREGON_TREES_PROJECT_ID) })), 1)
        ]),
        _createElementVNode("tr", null, [
          _createElementVNode("td", null, _toDisplayString(_ctx.$t('trees.florida_feature_label')), 1),
          _createElementVNode("td", null, _toDisplayString(_ctx.$t('trees.short_price', {
                price: _ctx.getFormattedProjectPriceById(_ctx.FLORIDA_TREES_PROJECT_ID),
              })), 1),
          _createElementVNode("td", null, _toDisplayString(_ctx.$t('trees.short_price', { price: _ctx.paidPlanPrice(_ctx.FLORIDA_TREES_PROJECT_ID) })), 1)
        ]),
        _createElementVNode("tr", null, [
          _createElementVNode("td", null, _toDisplayString(_ctx.$t('trees.haiti_feature_label')), 1),
          _createElementVNode("td", null, _toDisplayString(_ctx.$t('trees.short_price', {
                price: _ctx.getFormattedProjectPriceById(_ctx.HAITI_TREES_PROJECT_ID),
              })), 1),
          _createElementVNode("td", null, _toDisplayString(_ctx.$t('trees.short_price', { price: _ctx.paidPlanPrice(_ctx.HAITI_TREES_PROJECT_ID) })), 1)
        ]),
        _createElementVNode("tr", null, [
          _createElementVNode("td", null, _toDisplayString(_ctx.$t('trees.ontario_feature_label')), 1),
          _createElementVNode("td", null, _toDisplayString(_ctx.$t('trees.short_price', {
                price: _ctx.getFormattedProjectPriceById(_ctx.ONTARIO_TREES_PROJECT_ID),
              })), 1),
          _createElementVNode("td", null, _toDisplayString(_ctx.$t('trees.short_price', { price: _ctx.paidPlanPrice(_ctx.ONTARIO_TREES_PROJECT_ID) })), 1)
        ]),
        _createElementVNode("tr", null, [
          _createElementVNode("td", null, _toDisplayString(_ctx.$t('trees.brazil_feature_label')), 1),
          _createElementVNode("td", null, _toDisplayString(_ctx.$t('trees.short_price', {
                price: _ctx.getFormattedProjectPriceById(_ctx.BRAZIL_TREES_PROJECT_ID),
              })), 1),
          _createElementVNode("td", null, _toDisplayString(_ctx.$t('trees.short_price', { price: _ctx.paidPlanPrice(_ctx.BRAZIL_TREES_PROJECT_ID) })), 1)
        ]),
        _createElementVNode("tr", null, [
          _createElementVNode("td", null, _toDisplayString(_ctx.$t('kelp.feature_label')), 1),
          _createElementVNode("td", null, _toDisplayString(_ctx.$t('kelp.short_price', { price: _ctx.getFormattedProjectPriceById(_ctx.KELP_PROJECT_ID) })), 1),
          _createElementVNode("td", null, _toDisplayString(_ctx.$t('kelp.short_price', { price: _ctx.paidPlanPrice(_ctx.KELP_PROJECT_ID) })), 1)
        ]),
        _createElementVNode("tr", null, [
          _createElementVNode("td", null, _toDisplayString(_ctx.$t('plastic.feature_label')), 1),
          _createElementVNode("td", null, _toDisplayString(_ctx.$t('plastic.short_price', {
                price: _ctx.getFormattedProjectPriceById(_ctx.PLASTIC_BANK_PROJECT_ID),
              })), 1),
          _createElementVNode("td", null, _toDisplayString(_ctx.$t('plastic.short_price', { price: _ctx.paidPlanPrice(_ctx.PLASTIC_BANK_PROJECT_ID) })), 1)
        ]),
        _createElementVNode("tr", null, [
          _createElementVNode("td", null, _toDisplayString(_ctx.$t('carbon.feature_label')), 1),
          _createElementVNode("td", null, _toDisplayString(_ctx.$t('carbon.short_price', {
                price: _ctx.getFormattedProjectPriceById(_ctx.CARBON_POOL_PROJECT_ID),
              })), 1),
          _createElementVNode("td", null, _toDisplayString(_ctx.$t('carbon.short_price', { price: _ctx.paidPlanPrice(_ctx.CARBON_POOL_PROJECT_ID) })), 1)
        ]),
        _createElementVNode("tr", null, [
          _createElementVNode("td", null, _toDisplayString(_ctx.$t('water.feature_label')), 1),
          _createElementVNode("td", null, _toDisplayString(_ctx.$t('water.short_price', { price: _ctx.getFormattedProjectPriceById(_ctx.WATER_PROJECT_ID) })), 1),
          _createElementVNode("td", null, _toDisplayString(_ctx.$t('water.short_price', { price: _ctx.paidPlanPrice(_ctx.WATER_PROJECT_ID) })), 1)
        ]),
        _createElementVNode("tr", null, [
          _createElementVNode("td", null, _toDisplayString(_ctx.$t('bees.feature_label')), 1),
          _createElementVNode("td", null, _toDisplayString(_ctx.$t('bees.short_price', { price: _ctx.getFormattedProjectPriceById(_ctx.BEES_PROJECT_ID) })), 1),
          _createElementVNode("td", null, _toDisplayString(_ctx.$t('bees.short_price', { price: _ctx.paidPlanPrice(_ctx.BEES_PROJECT_ID) })), 1)
        ]),
        _createElementVNode("tr", null, [
          _createElementVNode("td", _hoisted_5, _toDisplayString(_ctx.t('sum_current')), 1),
          _createElementVNode("td", null, _toDisplayString(_ctx.currencyFormatter(_ctx.currentSum)), 1)
        ]),
        _createElementVNode("tr", null, [
          _createElementVNode("td", _hoisted_6, _toDisplayString(_ctx.t('sum_new')), 1),
          _createElementVNode("td", null, _toDisplayString(_ctx.currencyFormatter(_ctx.paidPlanSum)), 1)
        ])
      ])
    ]),
    _createVNode(_component_gs_button, {
      size: "large",
      "full-width": "",
      onClick: _withModifiers(_ctx.navigate, ["prevent"])
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t('change_plan')), 1)
      ]),
      _: 1
    }, 8, ["onClick"])
  ]))
}