import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "badges-main-wrapper" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 0,
  class: "how-it-works"
}
const _hoisted_4 = { class: "control-wrapper" }
const _hoisted_5 = { class: "filter-wrapper" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "accordion-wrapper" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "accordion-header-wrapper" }
const _hoisted_10 = { class: "header-pill-wrapper" }
const _hoisted_11 = { class: "header-pill" }
const _hoisted_12 = { class: "header-pill" }
const _hoisted_13 = { class: "header-pill" }
const _hoisted_14 = { class: "header-pill" }
const _hoisted_15 = { class: "badge-container" }
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = ["src"]
const _hoisted_18 = { class: "accordion-header-wrapper" }
const _hoisted_19 = { class: "header-pill-wrapper" }
const _hoisted_20 = { class: "header-pill" }
const _hoisted_21 = { class: "header-pill" }
const _hoisted_22 = { class: "header-pill" }
const _hoisted_23 = { class: "header-pill" }
const _hoisted_24 = { class: "badge-container" }
const _hoisted_25 = ["innerHTML"]
const _hoisted_26 = ["src"]
const _hoisted_27 = { class: "accordion-header-wrapper" }
const _hoisted_28 = { class: "header-pill-wrapper" }
const _hoisted_29 = { class: "header-pill" }
const _hoisted_30 = { class: "header-pill" }
const _hoisted_31 = { class: "header-pill" }
const _hoisted_32 = { class: "badge-container" }
const _hoisted_33 = ["innerHTML"]
const _hoisted_34 = ["src"]
const _hoisted_35 = { class: "accordion-header-wrapper" }
const _hoisted_36 = { class: "header-pill-wrapper" }
const _hoisted_37 = { class: "header-pill" }
const _hoisted_38 = { class: "header-pill" }
const _hoisted_39 = { class: "header-pill" }
const _hoisted_40 = {
  key: 0,
  class: "header-pill"
}
const _hoisted_41 = { class: "badge-container tight-container" }
const _hoisted_42 = ["innerHTML"]
const _hoisted_43 = ["innerHTML"]
const _hoisted_44 = ["innerHTML"]
const _hoisted_45 = ["innerHTML"]
const _hoisted_46 = ["innerHTML"]
const _hoisted_47 = ["innerHTML"]
const _hoisted_48 = ["innerHTML"]
const _hoisted_49 = ["src"]
const _hoisted_50 = { class: "accordion-header-wrapper" }
const _hoisted_51 = { class: "header-pill-wrapper" }
const _hoisted_52 = { class: "header-pill" }
const _hoisted_53 = { class: "header-pill" }
const _hoisted_54 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HowItWorkShare = _resolveComponent("HowItWorkShare")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_chip_group = _resolveComponent("v-chip-group")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_WidgetPreview = _resolveComponent("WidgetPreview")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!
  const _component_LoadingOpaque = _resolveComponent("LoadingOpaque")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "back",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1)))
    }, [
      _createElementVNode("img", {
        class: "chevron",
        alt: "chevron",
        src: require('@/assets/icons/chevron_left.svg')
      }, null, 8, _hoisted_2),
      _createElementVNode("div", null, _toDisplayString(_ctx.t('back')), 1)
    ]),
    (_ctx.account.accountType === 'business' || _ctx.account.accountType === 'ecommerce')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_HowItWorkShare)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_v_chip_group, {
        "model-value": _ctx.activeFilter,
        class: "filter-group",
        "selected-class": "active-filter",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = 
          (v) => {
            if (!v) return
            _ctx.openPanels = []
            _ctx.activeFilter = v
          }
        )
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_chip, {
            class: "filter-item",
            rounded: "lg",
            value: "all"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('all')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_chip, {
            class: "filter-item",
            rounded: "lg",
            value: "badges"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('badges')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_chip, {
            class: "filter-item",
            rounded: "lg",
            value: "banners"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('banners')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_chip, {
            class: "filter-item",
            rounded: "lg",
            value: "widgets"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('widgets')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model-value"]),
      _createElementVNode("div", _hoisted_5, [
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "filter-title" }, "Preview in:", -1)),
        _createElementVNode("div", null, [
          _createVNode(_component_v_select, {
            modelValue: _ctx.selectedLanguage,
            "onUpdate:modelValue": [
              _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedLanguage) = $event)),
              _ctx.switchLanguage
            ],
            items: _ctx.LANGUAGES(),
            width: '210px',
            class: "language-selector",
            density: "compact",
            "hide-details": "",
            variant: "outlined"
          }, null, 8, ["modelValue", "items", "onUpdate:modelValue"])
        ])
      ])
    ]),
    (_ctx.account.accountType === 'business' || _ctx.account.accountType === 'ecommerce')
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_v_expansion_panels, {
              variant: "accordion",
              flat: "",
              multiple: "",
              modelValue: _ctx.openPanels,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.openPanels) = $event))
            }, {
              default: _withCtx(() => [
                (_ctx.activeFilter === 'all' || _ctx.activeFilter === 'badges')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createVNode(_component_v_expansion_panel, {
                        class: "v-expansion-panel-headers",
                        value: "badges"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_expansion_panel_title, { class: "widget-header" }, {
                            default: _withCtx(() => [
                              _createElementVNode("img", {
                                src: require('@/assets/icons/leaf-icon.svg'),
                                style: {"max-width":"25px","margin-right":"8px"},
                                alt: "leaf icon"
                              }, null, 8, _hoisted_8),
                              _createElementVNode("div", _hoisted_9, [
                                _createTextVNode(_toDisplayString(_ctx.t('static_header')) + " ", 1),
                                _createElementVNode("div", _hoisted_10, [
                                  _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.t('shopify_two')), 1),
                                  _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.t('shopify_script_tag')), 1),
                                  _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.t('widget_api')), 1),
                                  _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.t('code_snippet')), 1)
                                ])
                              ])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_expansion_panel_text, { class: "accordion-content" }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_15, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.staticWidgets, (widget, index) => {
                                  return (_openBlock(), _createBlock(_component_WidgetPreview, {
                                    key: index,
                                    "api-key": _ctx.widgetApiKey,
                                    color: widget.color,
                                    style: _normalizeStyle(widget.style),
                                    url: widget.url,
                                    type: "static"
                                  }, {
                                    widget: _withCtx(() => [
                                      _createElementVNode("div", {
                                        innerHTML: widget.html
                                      }, null, 8, _hoisted_16)
                                    ]),
                                    _: 2
                                  }, 1032, ["api-key", "color", "style", "url"]))
                                }), 128))
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_expansion_panel, {
                        class: "v-expansion-panel-headers",
                        value: "round-badges"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_expansion_panel_title, { class: "widget-header" }, {
                            default: _withCtx(() => [
                              _createElementVNode("img", {
                                src: require('@/assets/icons/leaf-icon.svg'),
                                style: {"max-width":"25px","margin-right":"8px"},
                                alt: "leaf icon"
                              }, null, 8, _hoisted_17),
                              _createElementVNode("div", _hoisted_18, [
                                _createTextVNode(_toDisplayString(_ctx.t('round_header')) + " ", 1),
                                _createElementVNode("div", _hoisted_19, [
                                  _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.t('shopify_two')), 1),
                                  _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.t('shopify_script_tag')), 1),
                                  _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.t('widget_api')), 1),
                                  _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.t('code_snippet')), 1)
                                ])
                              ])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_expansion_panel_text, { class: "accordion-content" }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_24, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.roundWidgets, (widget, index) => {
                                  return (_openBlock(), _createBlock(_component_WidgetPreview, {
                                    key: index,
                                    "api-key": _ctx.widgetApiKey,
                                    color: widget.color,
                                    style: _normalizeStyle(widget.style),
                                    url: widget.url,
                                    type: "static"
                                  }, {
                                    widget: _withCtx(() => [
                                      _createElementVNode("div", {
                                        innerHTML: widget.html
                                      }, null, 8, _hoisted_25)
                                    ]),
                                    _: 2
                                  }, 1032, ["api-key", "color", "style", "url"]))
                                }), 128))
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ], 64))
                  : _createCommentVNode("", true),
                (_ctx.activeFilter === 'all' || _ctx.activeFilter === 'widgets')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createVNode(_component_v_expansion_panel, {
                        class: "v-expansion-panel-headers",
                        value: "dynamic-widgets"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_expansion_panel_title, { class: "widget-header" }, {
                            default: _withCtx(() => [
                              _createElementVNode("img", {
                                src: require('@/assets/icons/leaf-icon.svg'),
                                style: {"max-width":"25px","margin-right":"8px"},
                                alt: "leaf icon"
                              }, null, 8, _hoisted_26),
                              _createElementVNode("div", _hoisted_27, [
                                _createTextVNode(_toDisplayString(_ctx.t('dynamic_header')) + " ", 1),
                                _createElementVNode("div", _hoisted_28, [
                                  _createElementVNode("span", _hoisted_29, _toDisplayString(_ctx.t('shopify_two')), 1),
                                  _createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.t('shopify_script_tag')), 1),
                                  _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.t('widget_api')), 1)
                                ])
                              ])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_expansion_panel_text, { class: "accordion-content" }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_32, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.topStatWidgets, (widget, index) => {
                                  return (_openBlock(), _createBlock(_component_WidgetPreview, {
                                    key: index,
                                    "api-key": _ctx.widgetApiKey,
                                    color: widget.props?.color,
                                    style: _normalizeStyle(widget.props?.style),
                                    type: "stats"
                                  }, {
                                    widget: _withCtx(() => [
                                      _createElementVNode("div", {
                                        innerHTML: widget.html
                                      }, null, 8, _hoisted_33)
                                    ]),
                                    _: 2
                                  }, 1032, ["api-key", "color", "style"]))
                                }), 128))
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.impactSettingWidgets, (item, a) => {
                        return (_openBlock(), _createBlock(_component_v_expansion_panel, {
                          key: a,
                          class: "v-expansion-panel-headers",
                          value: `widget-${item.trigger}`
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_expansion_panel_title, { class: "widget-header" }, {
                              default: _withCtx(() => [
                                _createElementVNode("img", {
                                  src: require('@/assets/icons/leaf-icon.svg'),
                                  style: {"max-width":"25px","margin-right":"8px"},
                                  alt: "leaf icon"
                                }, null, 8, _hoisted_34),
                                _createElementVNode("div", _hoisted_35, [
                                  _createTextVNode(_toDisplayString(_ctx.t(`${item.trigger}SectionTitle`)) + " ", 1),
                                  _createElementVNode("div", _hoisted_36, [
                                    _createElementVNode("span", _hoisted_37, _toDisplayString(_ctx.t('shopify_two')), 1),
                                    _createElementVNode("span", _hoisted_38, _toDisplayString(_ctx.t('shopify_script_tag')), 1),
                                    _createElementVNode("span", _hoisted_39, _toDisplayString(_ctx.t('widget_api')), 1),
                                    (_ctx.isRebuyCompatible(item.trigger) && _ctx.isRebuyEnabled)
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_40, _toDisplayString(_ctx.t('rebuy_smart_cart')), 1))
                                      : _createCommentVNode("", true)
                                  ])
                                ])
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_v_expansion_panel_text, { class: "accordion-content" }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", null, [
                                  _createElementVNode("div", _hoisted_41, [
                                    (item.trigger === 'orderImpacts')
                                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.cartWidgets, (widget, index) => {
                                          return (_openBlock(), _createBlock(_component_WidgetPreview, {
                                            key: index,
                                            "api-key": _ctx.widgetApiKey,
                                            type: "orderImpacts",
                                            color: widget.props?.color,
                                            style: _normalizeStyle(widget.props?.style)
                                          }, {
                                            widget: _withCtx(() => [
                                              _createElementVNode("div", {
                                                innerHTML: widget.html
                                              }, null, 8, _hoisted_42)
                                            ]),
                                            _: 2
                                          }, 1032, ["api-key", "color", "style"]))
                                        }), 128))
                                      : (item.trigger === 'offsetPerOrder')
                                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.perOrderWidgets, (widget, index) => {
                                            return (_openBlock(), _createBlock(_component_WidgetPreview, {
                                              key: index,
                                              "api-key": _ctx.widgetApiKey,
                                              color: widget.props?.color,
                                              style: _normalizeStyle(widget.props?.style),
                                              type: "offsetPerOrder"
                                            }, {
                                              widget: _withCtx(() => [
                                                _createElementVNode("div", {
                                                  innerHTML: widget.html
                                                }, null, 8, _hoisted_43)
                                              ]),
                                              _: 2
                                            }, 1032, ["api-key", "color", "style"]))
                                          }), 128))
                                        : (item.trigger === 'offsetPerProduct')
                                          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.perProductWidgets, (widget, index) => {
                                              return (_openBlock(), _createBlock(_component_WidgetPreview, {
                                                key: index,
                                                "api-key": _ctx.widgetApiKey,
                                                color: widget.props?.color,
                                                style: _normalizeStyle(widget.props?.style),
                                                type: "offsetByProduct"
                                              }, {
                                                widget: _withCtx(() => [
                                                  _createElementVNode("div", {
                                                    innerHTML: widget.html
                                                  }, null, 8, _hoisted_44)
                                                ]),
                                                _: 2
                                              }, 1032, ["api-key", "color", "style"]))
                                            }), 128))
                                          : (item.trigger === 'offsetByPercentageOrder')
                                            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 3 }, _renderList(_ctx.perPercentageWidgets, (widget, index) => {
                                                return (_openBlock(), _createBlock(_component_WidgetPreview, {
                                                  key: index,
                                                  "api-key": _ctx.widgetApiKey,
                                                  color: widget.props?.color,
                                                  style: _normalizeStyle(widget.props?.style),
                                                  type: "byPercentage"
                                                }, {
                                                  widget: _withCtx(() => [
                                                    _createElementVNode("div", {
                                                      innerHTML: widget.html
                                                    }, null, 8, _hoisted_45)
                                                  ]),
                                                  _: 2
                                                }, 1032, ["api-key", "color", "style"]))
                                              }), 128))
                                            : (item.trigger === 'offsetBySpend')
                                              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 4 }, _renderList(_ctx.perSpendLevelWidgets, (widget, index) => {
                                                  return (_openBlock(), _createBlock(_component_WidgetPreview, {
                                                    key: index,
                                                    "api-key": _ctx.widgetApiKey,
                                                    color: widget.props?.color,
                                                    style: _normalizeStyle(widget.props?.style),
                                                    type: "offsetBySpend"
                                                  }, {
                                                    widget: _withCtx(() => [
                                                      _createElementVNode("div", {
                                                        innerHTML: widget.html
                                                      }, null, 8, _hoisted_46)
                                                    ]),
                                                    _: 2
                                                  }, 1032, ["api-key", "color", "style"]))
                                                }), 128))
                                              : (item.trigger === 'offsetByStoreRevenue')
                                                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 5 }, _renderList(_ctx.perTieredSpendLevelWidgets, (widget, index) => {
                                                    return (_openBlock(), _createBlock(_component_WidgetPreview, {
                                                      key: index,
                                                      "api-key": _ctx.widgetApiKey,
                                                      color: widget.props?.color,
                                                      style: _normalizeStyle(widget.props?.style),
                                                      type: "offsetByStoreRevenue"
                                                    }, {
                                                      widget: _withCtx(() => [
                                                        _createElementVNode("div", {
                                                          innerHTML: widget.html
                                                        }, null, 8, _hoisted_47)
                                                      ]),
                                                      _: 2
                                                    }, 1032, ["api-key", "color", "style"]))
                                                  }), 128))
                                                : (item.trigger === 'byPercentageOfRevenue')
                                                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 6 }, _renderList(_ctx.perTieredSpendLevelWidgets, (widget, index) => {
                                                      return (_openBlock(), _createBlock(_component_WidgetPreview, {
                                                        key: index,
                                                        "api-key": _ctx.widgetApiKey,
                                                        color: widget.props?.color,
                                                        style: _normalizeStyle(widget.props?.style),
                                                        type: "byPercentageOfRevenue"
                                                      }, {
                                                        widget: _withCtx(() => [
                                                          _createElementVNode("div", {
                                                            innerHTML: widget.html
                                                          }, null, 8, _hoisted_48)
                                                        ]),
                                                        _: 2
                                                      }, 1032, ["api-key", "color", "style"]))
                                                    }), 128))
                                                  : _createCommentVNode("", true)
                                  ])
                                ])
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ], 64))
                  : _createCommentVNode("", true),
                (_ctx.activeFilter === 'all' || _ctx.activeFilter === 'banners')
                  ? (_openBlock(), _createBlock(_component_v_expansion_panel, {
                      key: 2,
                      class: "v-expansion-panel-headers",
                      value: "banners"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_expansion_panel_title, { class: "widget-header" }, {
                          default: _withCtx(() => [
                            _createElementVNode("img", {
                              src: require('@/assets/icons/leaf-icon.svg'),
                              style: {"max-width":"25px","margin-right":"8px"},
                              alt: "leaf icon"
                            }, null, 8, _hoisted_49),
                            _createElementVNode("div", _hoisted_50, [
                              _createTextVNode(_toDisplayString(_ctx.t('full_width_banner_header')) + " ", 1),
                              _createElementVNode("div", _hoisted_51, [
                                _createElementVNode("span", _hoisted_52, _toDisplayString(_ctx.t('shopify_two')), 1),
                                _createElementVNode("span", _hoisted_53, _toDisplayString(_ctx.t('widget_api')), 1)
                              ])
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_expansion_panel_text, { class: "accordion-content" }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", null, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fullWidthBannerWidgets, (widget, index) => {
                                return (_openBlock(), _createBlock(_component_WidgetPreview, {
                                  key: index,
                                  "widget-api-key": _ctx.widgetApiKey,
                                  type: "banner"
                                }, {
                                  widget: _withCtx(() => [
                                    _createElementVNode("div", {
                                      style: {"width":"100%"},
                                      innerHTML: widget.html
                                    }, null, 8, _hoisted_54)
                                  ]),
                                  _: 2
                                }, 1032, ["widget-api-key"]))
                              }), 128))
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["modelValue"])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_LoadingOpaque, { key: 2 }))
      : _createCommentVNode("", true)
  ]))
}