import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "stepper" }
const _hoisted_2 = { class: "step-indicator-list" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "step-indicator-number" }
const _hoisted_5 = {
  class: "stepper-items",
  ref: "stepper-items"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (!_ctx.isMobile && _ctx.showLogo)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: require('@/assets/greenspark-logo.svg'),
            alt: "",
            class: "greenspark-logo"
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.maxValue, (item, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: index,
          class: _normalizeClass(['step-indicator', { 'is-active': index <= _ctx.modelValue - 1 }, 'animate', 'pop '])
        }, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(index + 1), 1)
        ], 2))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 512)
  ]))
}