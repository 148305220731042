import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "font-weight-bold text-body-1 ma-0" }
const _hoisted_2 = { class: "business-logo ml-3" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createElementBlock("button", {
    class: "d-flex align-center",
    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.openUploadModal && _ctx.openUploadModal(...args)), ["prevent"]))
  }, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.buttonText), 1),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.imageUrl)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "uploaded-icon",
            src: _ctx.imageUrl,
            alt: ""
          }, null, 8, _hoisted_3))
        : (_openBlock(), _createBlock(_component_v_icon, {
            key: 1,
            class: "image-placeholder"
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode(" mdi-cloud-upload ")
            ])),
            _: 1
          }))
    ])
  ]))
}