import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gs_input = _resolveComponent("gs-input")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { class: "request-integration" }, {
    default: _withCtx(() => [
      _cache[7] || (_cache[7] = _createElementVNode("h3", { class: "request-integration__title" }, "Integration Request", -1)),
      _cache[8] || (_cache[8] = _createElementVNode("p", { class: "request-integration__desc" }, " We are constantly adding new integrations. Please give us more details on what you what you want to achieve below. ", -1)),
      _createVNode(_component_v_form, {
        modelValue: _ctx.isFormValid,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isFormValid) = $event))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_gs_input, {
              modelValue: _ctx.payload.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.payload.name) = $event)),
              label: "Integration name"
            }, null, 8, ["modelValue"]),
            _createVNode(_component_gs_input, {
              modelValue: _ctx.payload.description,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.payload.description) = $event)),
              label: "What do you want to achieve with this integration?"
            }, null, 8, ["modelValue"]),
            _createVNode(_component_gs_input, {
              modelValue: _ctx.payload.impact,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.payload.impact) = $event)),
              label: "Estimated monthly impact generated using this integration"
            }, null, 8, ["modelValue"]),
            _createVNode(_component_gs_button, {
              "full-width": "",
              size: "large",
              onClick: _withModifiers(_ctx.sendRequestedIntegrationForm, ["prevent"])
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode(" Invite ")
              ])),
              _: 1
            }, 8, ["onClick"])
          ])
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createElementVNode("button", {
        class: "close-request-integration-dialog",
        onClick: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["prevent"]))
      }, [
        _createVNode(_component_v_icon, { class: "close-request-integration-dialog__icon" }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode(" mdi-close ")
          ])),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}