<template>
  <div>
    <div v-if="!loading">
      <h1>Change Email</h1>
      <span>
        This functionality changes the email address of the selected user in our db, in Firebase and
        in Active Campaign.
      </span>
      <v-autocomplete
        v-model="selectedEmail"
        :items="users.map((user) => user.email)"
        :item-title="(user: UserDto) => user.email"
        :item-value="(user: UserDto) => user.userId"
        :label="'Emails'"
        variant="outlined"
        color="#3B755F"
        item-color="#3B755F"
        class="dropdown"
        :rules="[rules.required]"
      />
      <v-text-field
        id="Email"
        v-model="updatedEmail"
        class="input-field"
        name="Email"
        autocomplete="email"
        type="email"
        :rules="[rules.required, rules.email]"
        :label="'Updated Email'"
        variant="outlined"
        flat
        color="green"
        hide-details
      />
      <gs-button
        :disabled="!updatedEmail || loading || !selectedEmail"
        :loading="loading"
        :full-width="true"
        size="large"
        @click.prevent="changeEmailForUser"
      >
        Save
      </gs-button>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script lang="ts">
import type { UserDto } from '@api/index'
import { changeEmail, fetchAllUser } from '@api/index'
import Loading from '@/components/tools/Loading.vue'
import { RulesMixin } from '@/helpers/mixins/RulesMixin.vue'
import type { Notification } from '@/store/notification'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ChangeEmailView',
  components: { Loading },
  mixins: [RulesMixin],
  data() {
    const users: UserDto[] = []

    return {
      loading: false,
      users,
      selectedEmail: '',
      updatedEmail: '',
    }
  },
  async created() {
    this.loading = true
    this.users = await fetchAllUser()
    this.loading = false
  },
  methods: {
    async changeEmailForUser() {
      this.loading = true
      try {
        const user = this.users.find((user) => user.email === this.selectedEmail)
        if (user) {
          await changeEmail(user.userId, this.updatedEmail)
          this.users = await fetchAllUser()
          this.selectedEmail = ''
          this.updatedEmail = ''
          this.$store.dispatch('notification/notify', { text: 'Success' } as Notification)
        }
      } catch (e) {
        this.$store.dispatch('notification/notify', {
          text: 'Error',
          isError: true,
        } as Notification)
      } finally {
        this.loading = false
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.dropdown {
  margin: 10px 0;
}

.input-field {
  margin: 10px 0;
}
</style>
