import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "loading-wrapper" }
const _hoisted_2 = { class: "progress-bar-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GreenSparkLogo = _resolveComponent("GreenSparkLogo")!
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_GreenSparkLogo, { class: "loading-logo" }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_v_progress_linear, {
        color: "#3B755F",
        indeterminate: "",
        rounded: "",
        height: "6"
      })
    ])
  ]))
}