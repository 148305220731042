import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "receipt-wrapper" }
const _hoisted_2 = { class: "date" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.name), 1),
    _createElementVNode("div", {
      class: "download",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openUrl()))
    }, [
      _createTextVNode(_toDisplayString(_ctx.t('download')) + " ", 1),
      _createElementVNode("img", {
        class: "download-icon",
        src: require('@/assets/icons/download.svg'),
        alt: "download"
      }, null, 8, _hoisted_3)
    ])
  ]))
}