import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "content-wrapper" }
const _hoisted_2 = { class: "input-wrapper" }
const _hoisted_3 = {
  key: 0,
  class: "impact-actions-list"
}
const _hoisted_4 = {
  key: 1,
  class: "no-result-wrapper"
}
const _hoisted_5 = { class: "no-result-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_date_input = _resolveComponent("v-date-input")!
  const _component_LoaderBlock = _resolveComponent("LoaderBlock")!
  const _component_ImpactActionsItem = _resolveComponent("ImpactActionsItem")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_pagination = _resolveComponent("v-pagination")!
  const _component_v_panel = _resolveComponent("v-panel")!

  return (_openBlock(), _createBlock(_component_v_panel, { class: "panel" }, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.t('title')), 1)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_v_select, {
            modelValue: _ctx.selectedBrand,
            "onUpdate:modelValue": [
              _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedBrand) = $event)),
              _cache[1] || (_cache[1] = ($event: any) => (_ctx.filterOrders()))
            ],
            items: _ctx.selectBrandOptions,
            label: "Brands",
            "prepend-inner-icon": "mdi-shopping-outline",
            density: "compact",
            color: "#3B755F",
            variant: "outlined",
            clearable: "",
            class: "impact-actions-select",
            "onClick:clear": _cache[2] || (_cache[2] = ($event: any) => (_ctx.clearSelect()))
          }, null, 8, ["modelValue", "items"]),
          _createVNode(_component_v_date_input, {
            modelValue: _ctx.dateRange,
            "onUpdate:modelValue": [
              _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dateRange) = $event)),
              _ctx.filterOrders
            ],
            label: _ctx.t('dates'),
            placeholder: _ctx.t('dates'),
            class: "date-picker",
            "max-width": "320",
            multiple: "range",
            clearable: "",
            "onClick:clear": _cache[4] || (_cache[4] = ($event: any) => (_ctx.clearDateRange())),
            readonly: "",
            elevation: "12",
            color: "#3B755F",
            variant: "outlined",
            "prepend-icon": "",
            "prepend-inner-icon": "mdi-calendar-range-outline"
          }, null, 8, ["modelValue", "label", "placeholder", "onUpdate:modelValue"])
        ]),
        (_ctx.loading)
          ? (_openBlock(), _createBlock(_component_LoaderBlock, {
              key: 0,
              "background-color": "transparent"
            }))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (_ctx.impactActions.length)
                ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.impactActions, (impactAction) => {
                      return (_openBlock(), _createBlock(_component_ImpactActionsItem, {
                        key: impactAction._id,
                        "impact-action": impactAction
                      }, null, 8, ["impact-action"]))
                    }), 128))
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_v_icon, { class: "no-result-icon" }, {
                      default: _withCtx(() => _cache[6] || (_cache[6] = [
                        _createTextVNode(" mdi-filter-remove-outline ")
                      ])),
                      _: 1
                    }),
                    _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.t('no_result')), 1)
                  ]))
            ], 64)),
        (_ctx.paginationLength > 1)
          ? (_openBlock(), _createBlock(_component_v_pagination, {
              key: 2,
              modelValue: _ctx.page,
              "onUpdate:modelValue": [
                _cache[5] || (_cache[5] = ($event: any) => ((_ctx.page) = $event)),
                _ctx.filterOrders
              ],
              class: "mt-8",
              length: _ctx.paginationLength,
              color: "#3B755F"
            }, null, 8, ["modelValue", "length", "onUpdate:modelValue"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}