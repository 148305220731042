import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "feature-table-wrapper" }
const _hoisted_2 = { class: "feature-table" }
const _hoisted_3 = { class: "table-header" }
const _hoisted_4 = { class: "table-head" }
const _hoisted_5 = { class: "table-head" }
const _hoisted_6 = { class: "table-head" }
const _hoisted_7 = { class: "table-row category" }
const _hoisted_8 = { class: "row-label category" }
const _hoisted_9 = { class: "table-row" }
const _hoisted_10 = { class: "row-label" }
const _hoisted_11 = { class: "table-row" }
const _hoisted_12 = { class: "row-label" }
const _hoisted_13 = { class: "row-values" }
const _hoisted_14 = { class: "row-value-thirds" }
const _hoisted_15 = { class: "row-value-thirds" }
const _hoisted_16 = { class: "row-value-thirds" }
const _hoisted_17 = { class: "table-row" }
const _hoisted_18 = { class: "row-label" }
const _hoisted_19 = { class: "row-values" }
const _hoisted_20 = { class: "row-value-thirds" }
const _hoisted_21 = { class: "row-value-thirds" }
const _hoisted_22 = { class: "row-value-thirds" }
const _hoisted_23 = { class: "table-row" }
const _hoisted_24 = { class: "row-label" }
const _hoisted_25 = { class: "row-values" }
const _hoisted_26 = { class: "row-value-thirds" }
const _hoisted_27 = { class: "row-value-thirds" }
const _hoisted_28 = { class: "row-value-thirds" }
const _hoisted_29 = { class: "table-row" }
const _hoisted_30 = { class: "row-label" }
const _hoisted_31 = { class: "row-values" }
const _hoisted_32 = { class: "row-value-thirds" }
const _hoisted_33 = { class: "row-value-thirds" }
const _hoisted_34 = { class: "row-value-thirds" }
const _hoisted_35 = { class: "table-row category" }
const _hoisted_36 = { class: "row-label category" }
const _hoisted_37 = { class: "table-row" }
const _hoisted_38 = { class: "row-label" }
const _hoisted_39 = { class: "row-values" }
const _hoisted_40 = { class: "row-value-thirds" }
const _hoisted_41 = { class: "row-value-thirds" }
const _hoisted_42 = { class: "row-value-thirds" }
const _hoisted_43 = { class: "table-row" }
const _hoisted_44 = { class: "row-label" }
const _hoisted_45 = { class: "row-values" }
const _hoisted_46 = { class: "row-value-thirds" }
const _hoisted_47 = { class: "row-value-thirds" }
const _hoisted_48 = { class: "row-value-thirds" }
const _hoisted_49 = { class: "table-row" }
const _hoisted_50 = { class: "row-label" }
const _hoisted_51 = { class: "row-values" }
const _hoisted_52 = { class: "row-value-thirds" }
const _hoisted_53 = { class: "row-value-thirds" }
const _hoisted_54 = { class: "table-row" }
const _hoisted_55 = { class: "row-label" }
const _hoisted_56 = { class: "row-values" }
const _hoisted_57 = { class: "row-value-thirds" }
const _hoisted_58 = { class: "row-value-thirds" }
const _hoisted_59 = { class: "table-row" }
const _hoisted_60 = { class: "row-label" }
const _hoisted_61 = { class: "row-values" }
const _hoisted_62 = { class: "row-value-thirds" }
const _hoisted_63 = { class: "row-value-thirds" }
const _hoisted_64 = { class: "row-value-thirds" }
const _hoisted_65 = { class: "table-row category" }
const _hoisted_66 = { class: "row-label category" }
const _hoisted_67 = { class: "table-row" }
const _hoisted_68 = { class: "row-label" }
const _hoisted_69 = { class: "row-values" }
const _hoisted_70 = { class: "row-value-thirds" }
const _hoisted_71 = { class: "table-row" }
const _hoisted_72 = { class: "row-label" }
const _hoisted_73 = { class: "row-values" }
const _hoisted_74 = { class: "row-value-thirds" }
const _hoisted_75 = { class: "row-value-thirds" }
const _hoisted_76 = { class: "row-value-thirds" }
const _hoisted_77 = { class: "table-row" }
const _hoisted_78 = { class: "row-label" }
const _hoisted_79 = { class: "row-values" }
const _hoisted_80 = { class: "row-value-thirds" }
const _hoisted_81 = { class: "row-value-thirds" }
const _hoisted_82 = { class: "table-row category" }
const _hoisted_83 = { class: "row-label category" }
const _hoisted_84 = { class: "table-row" }
const _hoisted_85 = { class: "row-label" }
const _hoisted_86 = { class: "row-values" }
const _hoisted_87 = { class: "row-value-thirds" }
const _hoisted_88 = { class: "row-value-thirds" }
const _hoisted_89 = { class: "row-value-thirds" }
const _hoisted_90 = { class: "table-row" }
const _hoisted_91 = { class: "row-label" }
const _hoisted_92 = { class: "row-values" }
const _hoisted_93 = { class: "row-value-thirds" }
const _hoisted_94 = { class: "row-value-thirds" }
const _hoisted_95 = { class: "table-row category" }
const _hoisted_96 = { class: "row-label category" }
const _hoisted_97 = { class: "table-row" }
const _hoisted_98 = { class: "row-label" }
const _hoisted_99 = { class: "row-values" }
const _hoisted_100 = { class: "row-value-thirds" }
const _hoisted_101 = { class: "row-value-thirds" }
const _hoisted_102 = { class: "row-value-thirds" }
const _hoisted_103 = { class: "table-row" }
const _hoisted_104 = { class: "row-label" }
const _hoisted_105 = { class: "row-values" }
const _hoisted_106 = { class: "row-value-thirds" }
const _hoisted_107 = { class: "row-value-thirds" }
const _hoisted_108 = { class: "table-row" }
const _hoisted_109 = { class: "row-label" }
const _hoisted_110 = { class: "row-values" }
const _hoisted_111 = { class: "row-value-thirds" }
const _hoisted_112 = { class: "row-value-thirds" }
const _hoisted_113 = { class: "row-value-thirds" }
const _hoisted_114 = { class: "table-row" }
const _hoisted_115 = { class: "row-label" }
const _hoisted_116 = { class: "row-values" }
const _hoisted_117 = { class: "row-value-thirds" }
const _hoisted_118 = { class: "table-row category" }
const _hoisted_119 = { class: "row-label category" }
const _hoisted_120 = { class: "table-row" }
const _hoisted_121 = { class: "row-label" }
const _hoisted_122 = { class: "row-values" }
const _hoisted_123 = { class: "row-value-thirds" }
const _hoisted_124 = { class: "row-value-thirds" }
const _hoisted_125 = { class: "row-value-thirds" }
const _hoisted_126 = { class: "table-row" }
const _hoisted_127 = { class: "row-label" }
const _hoisted_128 = { class: "table-row" }
const _hoisted_129 = { class: "row-label" }
const _hoisted_130 = { class: "row-values" }
const _hoisted_131 = { class: "row-value-thirds" }
const _hoisted_132 = { class: "table-row" }
const _hoisted_133 = { class: "row-label" }
const _hoisted_134 = { class: "table-row category" }
const _hoisted_135 = { class: "row-label category" }
const _hoisted_136 = { class: "row-label" }
const _hoisted_137 = { class: "row-values" }
const _hoisted_138 = { class: "row-value-thirds" }
const _hoisted_139 = { class: "row-value-thirds" }
const _hoisted_140 = { class: "row-value-thirds" }
const _hoisted_141 = {
  href: "http://getgreenspark.com/book-a-demo",
  target: "_blank",
  class: "link"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_info_tooltip = _resolveComponent("info-tooltip")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_i18n_t = _resolveComponent("i18n-t")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t('starter')), 1),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.t('growth')), 1),
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.t('premium')), 1)
      ]),
      _createElementVNode("section", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.t('features')), 1)
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createTextVNode(_toDisplayString(_ctx.t('users')) + " ", 1),
          _createVNode(_component_info_tooltip, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('users_tooltip')), 1)
            ]),
            _: 1
          })
        ]),
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "row-values" }, [
          _createElementVNode("div", { class: "row-value-thirds" }, "1"),
          _createElementVNode("div", { class: "row-value-thirds" }, "3"),
          _createElementVNode("div", { class: "row-value-thirds" }, "8")
        ], -1))
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.t('dashboard')), 1),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createVNode(_component_v_icon, { class: "icon" }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode(" mdi-check ")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createVNode(_component_v_icon, { class: "icon" }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode(" mdi-check ")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createVNode(_component_v_icon, { class: "icon" }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode(" mdi-check ")
              ])),
              _: 1
            })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("div", _hoisted_18, [
          _createTextVNode(_toDisplayString(_ctx.t('team_offset')) + " ", 1),
          _createVNode(_component_info_tooltip, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('tooltip_team_offset')), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("div", _hoisted_20, [
            _createVNode(_component_v_icon, { class: "icon" }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode(" mdi-check ")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_21, [
            _createVNode(_component_v_icon, { class: "icon" }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode(" mdi-check ")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_22, [
            _createVNode(_component_v_icon, { class: "icon" }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode(" mdi-check ")
              ])),
              _: 1
            })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_23, [
        _createElementVNode("div", _hoisted_24, [
          _createTextVNode(_toDisplayString(_ctx.t('manual_impact')) + " ", 1),
          _createVNode(_component_info_tooltip, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('tooltip_manual_impact')), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_25, [
          _createElementVNode("div", _hoisted_26, [
            _createVNode(_component_v_icon, { class: "icon" }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode(" mdi-check ")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_27, [
            _createVNode(_component_v_icon, { class: "icon" }, {
              default: _withCtx(() => _cache[9] || (_cache[9] = [
                _createTextVNode(" mdi-check ")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_28, [
            _createVNode(_component_v_icon, { class: "icon" }, {
              default: _withCtx(() => _cache[10] || (_cache[10] = [
                _createTextVNode(" mdi-check ")
              ])),
              _: 1
            })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_29, [
        _createElementVNode("div", _hoisted_30, [
          _createTextVNode(_toDisplayString(_ctx.t('automatic_trees')) + " ", 1),
          _createVNode(_component_info_tooltip, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('tooltip_automatic_trees')), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_31, [
          _createElementVNode("div", _hoisted_32, _toDisplayString(_ctx.t('starter_plan_trees')), 1),
          _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.t('growth_plan_trees')), 1),
          _createElementVNode("div", _hoisted_34, _toDisplayString(_ctx.t('premium_plan_trees')), 1)
        ])
      ]),
      _createElementVNode("section", _hoisted_35, [
        _createElementVNode("div", _hoisted_36, _toDisplayString(_ctx.t('engagement_hub')), 1)
      ]),
      _createElementVNode("div", _hoisted_37, [
        _createElementVNode("div", _hoisted_38, [
          _createTextVNode(_toDisplayString(_ctx.t('public_profile')) + " ", 1),
          _createVNode(_component_info_tooltip, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('tooltip_public_profile')), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_39, [
          _createElementVNode("div", _hoisted_40, _toDisplayString(_ctx.t('basic')), 1),
          _createElementVNode("div", _hoisted_41, _toDisplayString(_ctx.t('advanced')), 1),
          _createElementVNode("div", _hoisted_42, _toDisplayString(_ctx.t('advanced')), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_43, [
        _createElementVNode("div", _hoisted_44, [
          _createTextVNode(_toDisplayString(_ctx.t('badges_widgets')) + " ", 1),
          _createVNode(_component_info_tooltip, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('tooltip_badges_widgets')), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_45, [
          _createElementVNode("div", _hoisted_46, [
            _createVNode(_component_v_icon, { class: "icon" }, {
              default: _withCtx(() => _cache[11] || (_cache[11] = [
                _createTextVNode(" mdi-check ")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_47, [
            _createVNode(_component_v_icon, { class: "icon" }, {
              default: _withCtx(() => _cache[12] || (_cache[12] = [
                _createTextVNode(" mdi-check ")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_48, [
            _createVNode(_component_v_icon, { class: "icon" }, {
              default: _withCtx(() => _cache[13] || (_cache[13] = [
                _createTextVNode(" mdi-check ")
              ])),
              _: 1
            })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_49, [
        _createElementVNode("div", _hoisted_50, [
          _createTextVNode(_toDisplayString(_ctx.t('impact_wallet')) + " ", 1),
          _createVNode(_component_info_tooltip, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('tooltip_impact_wallet')), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_51, [
          _cache[16] || (_cache[16] = _createElementVNode("div", { class: "row-value-thirds" }, null, -1)),
          _createElementVNode("div", _hoisted_52, [
            _createVNode(_component_v_icon, { class: "icon" }, {
              default: _withCtx(() => _cache[14] || (_cache[14] = [
                _createTextVNode(" mdi-check ")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_53, [
            _createVNode(_component_v_icon, { class: "icon" }, {
              default: _withCtx(() => _cache[15] || (_cache[15] = [
                _createTextVNode(" mdi-check ")
              ])),
              _: 1
            })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_54, [
        _createElementVNode("div", _hoisted_55, [
          _createTextVNode(_toDisplayString(_ctx.t('qr_code')) + " ", 1),
          _createVNode(_component_info_tooltip, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('tooltip_qr_code')), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_56, [
          _cache[19] || (_cache[19] = _createElementVNode("div", { class: "row-value-thirds" }, null, -1)),
          _createElementVNode("div", _hoisted_57, [
            _createVNode(_component_v_icon, { class: "icon" }, {
              default: _withCtx(() => _cache[17] || (_cache[17] = [
                _createTextVNode(" mdi-check ")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_58, [
            _createVNode(_component_v_icon, { class: "icon" }, {
              default: _withCtx(() => _cache[18] || (_cache[18] = [
                _createTextVNode(" mdi-check ")
              ])),
              _: 1
            })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_59, [
        _createElementVNode("div", _hoisted_60, _toDisplayString(_ctx.t('media_pack')), 1),
        _createElementVNode("div", _hoisted_61, [
          _createElementVNode("div", _hoisted_62, _toDisplayString(_ctx.t('basic')), 1),
          _createElementVNode("div", _hoisted_63, _toDisplayString(_ctx.t('basic')), 1),
          _createElementVNode("div", _hoisted_64, _toDisplayString(_ctx.t('full')), 1)
        ])
      ]),
      _createElementVNode("section", _hoisted_65, [
        _createElementVNode("div", _hoisted_66, _toDisplayString(_ctx.t('integrations_automations')), 1)
      ]),
      _createElementVNode("div", _hoisted_67, [
        _createElementVNode("div", _hoisted_68, [
          _createTextVNode(_toDisplayString(_ctx.t('active_integrations')) + " ", 1),
          _createVNode(_component_info_tooltip, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('tooltip_active_integrations')), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_69, [
          _cache[20] || (_cache[20] = _createElementVNode("div", { class: "row-value-thirds" }, "1", -1)),
          _cache[21] || (_cache[21] = _createElementVNode("div", { class: "row-value-thirds" }, "3", -1)),
          _createElementVNode("div", _hoisted_70, _toDisplayString(_ctx.t('unlimited')), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_71, [
        _createElementVNode("div", _hoisted_72, [
          _createTextVNode(_toDisplayString(_ctx.t('basic_automations')) + " ", 1),
          _createVNode(_component_info_tooltip, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('tooltip_basic_automations')), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_73, [
          _createElementVNode("div", _hoisted_74, [
            _createVNode(_component_v_icon, { class: "icon" }, {
              default: _withCtx(() => _cache[22] || (_cache[22] = [
                _createTextVNode(" mdi-check ")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_75, [
            _createVNode(_component_v_icon, { class: "icon" }, {
              default: _withCtx(() => _cache[23] || (_cache[23] = [
                _createTextVNode(" mdi-check ")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_76, [
            _createVNode(_component_v_icon, { class: "icon" }, {
              default: _withCtx(() => _cache[24] || (_cache[24] = [
                _createTextVNode(" mdi-check ")
              ])),
              _: 1
            })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_77, [
        _createElementVNode("div", _hoisted_78, [
          _createTextVNode(_toDisplayString(_ctx.t('advanced_automations')) + " ", 1),
          _createVNode(_component_info_tooltip, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('tooltip_advanced_automations')), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_79, [
          _cache[27] || (_cache[27] = _createElementVNode("div", { class: "row-value-thirds" }, null, -1)),
          _createElementVNode("div", _hoisted_80, [
            _createVNode(_component_v_icon, { class: "icon" }, {
              default: _withCtx(() => _cache[25] || (_cache[25] = [
                _createTextVNode(" mdi-check ")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_81, [
            _createVNode(_component_v_icon, { class: "icon" }, {
              default: _withCtx(() => _cache[26] || (_cache[26] = [
                _createTextVNode(" mdi-check ")
              ])),
              _: 1
            })
          ])
        ])
      ]),
      _createElementVNode("section", _hoisted_82, [
        _createElementVNode("div", _hoisted_83, _toDisplayString(_ctx.t('network')), 1)
      ]),
      _createElementVNode("div", _hoisted_84, [
        _createElementVNode("div", _hoisted_85, [
          _createTextVNode(_toDisplayString(_ctx.t('refer_partners')) + " ", 1),
          _createVNode(_component_info_tooltip, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('tooltip_refer_partners')), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_86, [
          _createElementVNode("div", _hoisted_87, [
            _createVNode(_component_v_icon, { class: "icon" }, {
              default: _withCtx(() => _cache[28] || (_cache[28] = [
                _createTextVNode(" mdi-check ")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_88, [
            _createVNode(_component_v_icon, { class: "icon" }, {
              default: _withCtx(() => _cache[29] || (_cache[29] = [
                _createTextVNode(" mdi-check ")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_89, [
            _createVNode(_component_v_icon, { class: "icon" }, {
              default: _withCtx(() => _cache[30] || (_cache[30] = [
                _createTextVNode(" mdi-check ")
              ])),
              _: 1
            })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_90, [
        _createElementVNode("div", _hoisted_91, [
          _createTextVNode(_toDisplayString(_ctx.t('reward_partners')) + " ", 1),
          _createVNode(_component_info_tooltip, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('tooltip_reward_partners')), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_92, [
          _cache[33] || (_cache[33] = _createElementVNode("div", { class: "row-value-thirds" }, null, -1)),
          _createElementVNode("div", _hoisted_93, [
            _createVNode(_component_v_icon, { class: "icon" }, {
              default: _withCtx(() => _cache[31] || (_cache[31] = [
                _createTextVNode(" mdi-check ")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_94, [
            _createVNode(_component_v_icon, { class: "icon" }, {
              default: _withCtx(() => _cache[32] || (_cache[32] = [
                _createTextVNode(" mdi-check ")
              ])),
              _: 1
            })
          ])
        ])
      ]),
      _createElementVNode("section", _hoisted_95, [
        _createElementVNode("div", _hoisted_96, _toDisplayString(_ctx.t('analytics')), 1)
      ]),
      _createElementVNode("div", _hoisted_97, [
        _createElementVNode("div", _hoisted_98, [
          _createTextVNode(_toDisplayString(_ctx.t('basic_analytics')) + " ", 1),
          _createVNode(_component_info_tooltip, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('tooltip_basic_analytics')), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_99, [
          _createElementVNode("div", _hoisted_100, [
            _createVNode(_component_v_icon, { class: "icon" }, {
              default: _withCtx(() => _cache[34] || (_cache[34] = [
                _createTextVNode(" mdi-check ")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_101, [
            _createVNode(_component_v_icon, { class: "icon" }, {
              default: _withCtx(() => _cache[35] || (_cache[35] = [
                _createTextVNode(" mdi-check ")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_102, [
            _createVNode(_component_v_icon, { class: "icon" }, {
              default: _withCtx(() => _cache[36] || (_cache[36] = [
                _createTextVNode(" mdi-check ")
              ])),
              _: 1
            })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_103, [
        _createElementVNode("div", _hoisted_104, [
          _createTextVNode(_toDisplayString(_ctx.t('advanced_analytics')) + " ", 1),
          _createVNode(_component_info_tooltip, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('tooltip_advanced_analytics')), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_105, [
          _cache[39] || (_cache[39] = _createElementVNode("div", { class: "row-value-thirds" }, null, -1)),
          _createElementVNode("div", _hoisted_106, [
            _createVNode(_component_v_icon, { class: "icon" }, {
              default: _withCtx(() => _cache[37] || (_cache[37] = [
                _createTextVNode(" mdi-check ")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_107, [
            _createVNode(_component_v_icon, { class: "icon" }, {
              default: _withCtx(() => _cache[38] || (_cache[38] = [
                _createTextVNode(" mdi-check ")
              ])),
              _: 1
            })
          ])
        ])
      ]),
      _cache[54] || (_cache[54] = _createElementVNode("section", { class: "table-row category" }, [
        _createElementVNode("div", { class: "row-label category" }, "API")
      ], -1)),
      _createElementVNode("div", _hoisted_108, [
        _createElementVNode("div", _hoisted_109, [
          _createTextVNode(_toDisplayString(_ctx.t('widget_api')) + " ", 1),
          _createVNode(_component_info_tooltip, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('tooltip_widget_api')), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_110, [
          _createElementVNode("div", _hoisted_111, [
            _createVNode(_component_v_icon, { class: "icon" }, {
              default: _withCtx(() => _cache[40] || (_cache[40] = [
                _createTextVNode(" mdi-check ")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_112, [
            _createVNode(_component_v_icon, { class: "icon" }, {
              default: _withCtx(() => _cache[41] || (_cache[41] = [
                _createTextVNode(" mdi-check ")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_113, [
            _createVNode(_component_v_icon, { class: "icon" }, {
              default: _withCtx(() => _cache[42] || (_cache[42] = [
                _createTextVNode(" mdi-check ")
              ])),
              _: 1
            })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_114, [
        _createElementVNode("div", _hoisted_115, [
          _createTextVNode(_toDisplayString(_ctx.t('greenspark_api')) + " ", 1),
          _createVNode(_component_info_tooltip, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('tooltip_greenspark_api')), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_116, [
          _cache[44] || (_cache[44] = _createElementVNode("div", { class: "row-value-thirds" }, null, -1)),
          _cache[45] || (_cache[45] = _createElementVNode("div", { class: "row-value-thirds" }, null, -1)),
          _createElementVNode("div", _hoisted_117, [
            _createVNode(_component_v_icon, { class: "icon" }, {
              default: _withCtx(() => _cache[43] || (_cache[43] = [
                _createTextVNode(" mdi-check ")
              ])),
              _: 1
            })
          ])
        ])
      ]),
      _createElementVNode("section", _hoisted_118, [
        _createElementVNode("div", _hoisted_119, _toDisplayString(_ctx.t('support')), 1)
      ]),
      _createElementVNode("div", _hoisted_120, [
        _createElementVNode("div", _hoisted_121, _toDisplayString(_ctx.t('chat_email')), 1),
        _createElementVNode("div", _hoisted_122, [
          _createElementVNode("div", _hoisted_123, [
            _createVNode(_component_v_icon, { class: "icon" }, {
              default: _withCtx(() => _cache[46] || (_cache[46] = [
                _createTextVNode(" mdi-check ")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_124, [
            _createVNode(_component_v_icon, { class: "icon" }, {
              default: _withCtx(() => _cache[47] || (_cache[47] = [
                _createTextVNode(" mdi-check ")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_125, [
            _createVNode(_component_v_icon, { class: "icon" }, {
              default: _withCtx(() => _cache[48] || (_cache[48] = [
                _createTextVNode(" mdi-check ")
              ])),
              _: 1
            })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_126, [
        _createElementVNode("div", _hoisted_127, _toDisplayString(_ctx.t('technical')), 1),
        _cache[49] || (_cache[49] = _createElementVNode("div", { class: "row-values" }, [
          _createElementVNode("div", { class: "row-value-thirds" }),
          _createElementVNode("div", { class: "row-value-thirds" }, "Standard"),
          _createElementVNode("div", { class: "row-value-thirds" }, "Priority")
        ], -1))
      ]),
      _createElementVNode("div", _hoisted_128, [
        _createElementVNode("div", _hoisted_129, [
          _createTextVNode(_toDisplayString(_ctx.t('campaign_support')) + " ", 1),
          _createVNode(_component_info_tooltip, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('tooltip_campaign_support')), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_130, [
          _cache[51] || (_cache[51] = _createElementVNode("div", { class: "row-value-thirds" }, null, -1)),
          _cache[52] || (_cache[52] = _createElementVNode("div", { class: "row-value-thirds" }, null, -1)),
          _createElementVNode("div", _hoisted_131, [
            _createVNode(_component_v_icon, { class: "icon" }, {
              default: _withCtx(() => _cache[50] || (_cache[50] = [
                _createTextVNode(" mdi-check ")
              ])),
              _: 1
            })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_132, [
        _createElementVNode("div", _hoisted_133, _toDisplayString(_ctx.t('developer_success')), 1),
        _cache[53] || (_cache[53] = _createElementVNode("div", { class: "row-values" }, [
          _createElementVNode("div", { class: "row-value-thirds" }),
          _createElementVNode("div", { class: "row-value-thirds" }),
          _createElementVNode("div", { class: "row-value-thirds" }, "+Add-on")
        ], -1))
      ]),
      _createElementVNode("section", _hoisted_134, [
        _createElementVNode("div", _hoisted_135, _toDisplayString(_ctx.t('impact_pricing')), 1)
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values(_ctx.impactPricing), (impact, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "table-row",
          key: index
        }, [
          _createElementVNode("div", _hoisted_136, _toDisplayString(impact.title), 1),
          _createElementVNode("div", _hoisted_137, [
            _createElementVNode("div", _hoisted_138, _toDisplayString(impact.starterPrice), 1),
            _createElementVNode("div", _hoisted_139, _toDisplayString(impact.growthPrice), 1),
            _createElementVNode("div", _hoisted_140, _toDisplayString(impact.premiumPrice), 1)
          ])
        ]))
      }), 128))
    ]),
    _createVNode(_component_i18n_t, {
      tag: "p",
      keypath: "FeaturesTable.questions",
      class: "demo"
    }, {
      default: _withCtx(() => [
        _createElementVNode("button", {
          target: "_blank",
          class: "link",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openChat()))
        }, _toDisplayString(_ctx.t('get_in_touch')), 1),
        _createElementVNode("a", _hoisted_141, _toDisplayString(_ctx.t('book_demo')), 1)
      ]),
      _: 1
    })
  ]))
}