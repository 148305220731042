import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "billing-wrapper" }
const _hoisted_2 = { class: "subheader" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 2,
  class: "invoices-wrapper"
}
const _hoisted_5 = {
  key: 4,
  class: "subheader",
  style: {"margin-top":"40px"}
}
const _hoisted_6 = {
  key: 6,
  class: "trial-disclaimer"
}
const _hoisted_7 = { class: "disclaimer-text" }
const _hoisted_8 = {
  key: 7,
  class: "subheader",
  style: {"margin-top":"40px"}
}
const _hoisted_9 = {
  key: 9,
  class: "cancel-div"
}
const _hoisted_10 = { class: "subheader" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingTable = _resolveComponent("LoadingTable")!
  const _component_Invoice = _resolveComponent("Invoice")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.t('your_invoices')), 1),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_LoadingTable, { key: 0 }))
      : _createCommentVNode("", true),
    (!_ctx.hasPaidSubscriptionItem)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.t('no_invoices')), 1))
      : _createCommentVNode("", true),
    (_ctx.isShopifyUser)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.t('shopify')), 1))
      : _createCommentVNode("", true),
    (_ctx.invoices.length)
      ? (_openBlock(), _createElementBlock("div", {
          key: 3,
          class: _normalizeClass(_ctx.invoices.length <= 5 ? 'invoices-wrapper' : 'invoices-wrapper-scroll')
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.invoices, (invoice, a) => {
            return (_openBlock(), _createBlock(_component_Invoice, {
              key: a,
              date: invoice.date,
              url: invoice.url
            }, null, 8, ["date", "url"]))
          }), 128))
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.isBusiness || _ctx.getIfB2B2C)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.t('manage_plan')), 1))
      : _createCommentVNode("", true),
    (_ctx.isBusiness || _ctx.getIfB2B2C)
      ? (_openBlock(), _createBlock(_component_gs_button, {
          key: 5,
          width: "230",
          loading: _ctx.buttonLoading,
          onClick: _cache[0] || (_cache[0] = () => _ctx.$router.push('/upgrade-plan?tab=billing'))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('manage')), 1)
          ]),
          _: 1
        }, 8, ["loading"]))
      : _createCommentVNode("", true),
    (_ctx.isTrial)
      ? (_openBlock(), _createElementBlock("p", _hoisted_6, [
          _createVNode(_component_v_icon, { size: "20px" }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("mdi-information-outline")
            ])),
            _: 1
          }),
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.t('trial_disclaimer')), 1)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.isShopifyUser && _ctx.hasPaidSubscriptionItem)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.t('update_payment')), 1))
      : _createCommentVNode("", true),
    (!_ctx.isShopifyUser && _ctx.hasPaidSubscriptionItem)
      ? (_openBlock(), _createBlock(_component_gs_button, {
          key: 8,
          type: "secondary",
          width: "230",
          loading: _ctx.buttonLoading,
          onClick: _ctx.openPortal
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('update')), 1)
          ]),
          _: 1
        }, 8, ["loading", "onClick"]))
      : _createCommentVNode("", true),
    (_ctx.hasPaidSubscriptionItem)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.t('cancel_subscriptions')), 1),
          _createVNode(_component_gs_button, {
            type: "danger",
            width: "230",
            onClick: _ctx.onCancelClick
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('cancel')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]))
      : _createCommentVNode("", true)
  ]))
}