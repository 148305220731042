import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Transition as _Transition, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "upgrade-wrapper" }
const _hoisted_2 = { class: "pricing-page" }
const _hoisted_3 = { class: "intro-section" }
const _hoisted_4 = {
  key: 0,
  class: "billing-period-selection"
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "upgrade-card-list-container" }
const _hoisted_7 = { class: "benefits-section" }
const _hoisted_8 = ["src", "alt"]
const _hoisted_9 = ["src", "alt"]
const _hoisted_10 = { class: "section-cta-text" }
const _hoisted_11 = {
  href: "http://getgreenspark.com/book-a-demo",
  target: "_blank",
  class: "link"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseNavigation = _resolveComponent("CloseNavigation")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_upgrade_card_list = _resolveComponent("upgrade-card-list")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_EnterprisePlanCard = _resolveComponent("EnterprisePlanCard")!
  const _component_PlanComparisonTable = _resolveComponent("PlanComparisonTable")!
  const _component_ImpactPricingComparisonTable = _resolveComponent("ImpactPricingComparisonTable")!
  const _component_FrequentlyAskedQuestions = _resolveComponent("FrequentlyAskedQuestions")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CloseNavigation),
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.getAccountTypeIsBusiness ? _ctx.t('upgrade_title_business') : _ctx.t('upgrade_title_personal')), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.getAccountTypeIsBusiness ? _ctx.t('upgrade_text_business') : _ctx.t('upgrade_text_personal')), 1)
      ]),
      (!_ctx.hideYearlyToggle && _ctx.getAccountTypeIsBusiness)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("span", {
              class: _normalizeClass({ active: !_ctx.isSubscriptionYearly })
            }, _toDisplayString(_ctx.t('monthly')), 3),
            _createVNode(_component_v_switch, {
              modelValue: _ctx.isSubscriptionYearly,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isSubscriptionYearly) = $event)),
              color: "#3B755F",
              inset: "",
              "hide-details": ""
            }, null, 8, ["modelValue"]),
            _createElementVNode("span", {
              class: _normalizeClass({ active: _ctx.isSubscriptionYearly })
            }, _toDisplayString(_ctx.t('yearly')), 3),
            _createElementVNode("span", {
              class: "free-period-label",
              innerHTML: _ctx.t('months_free', { amount: 3 })
            }, null, 8, _hoisted_5)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_upgrade_card_list, {
          "is-business": _ctx.getAccountTypeIsBusiness,
          "is-subscription-yearly": _ctx.isSubscriptionYearly,
          "path-to-navigate": _ctx.pathToNavigate,
          "onUpdate:pathToNavigate": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pathToNavigate) = $event)),
          onTurnOffYearlyToggle: _ctx.turnOffYearlyToggle
        }, null, 8, ["is-business", "is-subscription-yearly", "path-to-navigate", "onTurnOffYearlyToggle"])
      ]),
      _createVNode(_component_v_container, { class: "container" }, {
        default: _withCtx(() => [
          (_ctx.getAccountTypeIsBusiness)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("button", {
                  class: "compare-plans-cta",
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.scrollToTable && _ctx.scrollToTable(...args)))
                }, [
                  _createTextVNode(_toDisplayString(_ctx.t('compare_plan_features')) + " ", 1),
                  _createVNode(_component_v_icon, {
                    color: "#0017B4",
                    size: "22px"
                  }, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createTextVNode("mdi-arrow-down")
                    ])),
                    _: 1
                  })
                ]),
                _createVNode(_component_EnterprisePlanCard),
                _createElementVNode("section", _hoisted_7, [
                  _createElementVNode("h3", null, _toDisplayString(_ctx.t('enjoy_paid_plan_benefits')), 1),
                  _createElementVNode("ul", null, [
                    _createElementVNode("li", null, [
                      _createVNode(_component_v_icon, {
                        color: "#3B755F",
                        class: "benefit-icon",
                        size: "32px"
                      }, {
                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                          _createTextVNode("mdi-hand-heart")
                        ])),
                        _: 1
                      }),
                      _createElementVNode("h5", null, _toDisplayString(_ctx.t('positive_impact')), 1),
                      _createElementVNode("p", null, _toDisplayString(_ctx.t('support_verified_projects')), 1)
                    ]),
                    _createElementVNode("li", null, [
                      _createVNode(_component_v_icon, {
                        color: "#3B755F",
                        class: "benefit-icon",
                        size: "32px"
                      }, {
                        default: _withCtx(() => _cache[6] || (_cache[6] = [
                          _createTextVNode(" mdi-monitor-cellphone-star ")
                        ])),
                        _: 1
                      }),
                      _createElementVNode("h5", null, _toDisplayString(_ctx.t('private_dashboard')), 1),
                      _createElementVNode("p", null, _toDisplayString(_ctx.t('track_real_time_impact')), 1)
                    ]),
                    _createElementVNode("li", null, [
                      _createElementVNode("img", {
                        class: "benefit-icon",
                        src: require('@/assets/icons/device-hub.svg'),
                        alt: _ctx.t('integration_with_automation')
                      }, null, 8, _hoisted_8),
                      _createElementVNode("h5", null, _toDisplayString(_ctx.t('integration_with_automation')), 1),
                      _createElementVNode("p", null, _toDisplayString(_ctx.t('easily_integrate_sustainability')), 1)
                    ]),
                    _createElementVNode("li", null, [
                      _createElementVNode("img", {
                        class: "benefit-icon",
                        src: require('@/assets/icons/plant-line.svg'),
                        alt: _ctx.t('bonus_tree_planting')
                      }, null, 8, _hoisted_9),
                      _createElementVNode("h5", null, _toDisplayString(_ctx.t('bonus_tree_planting')), 1),
                      _createElementVNode("p", null, _toDisplayString(_ctx.t('well_plant_tress')), 1)
                    ])
                  ])
                ]),
                _createVNode(_Transition, { name: "fade" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_PlanComparisonTable, {
                      id: "comparison-table",
                      class: "mb-5"
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ImpactPricingComparisonTable, {
                  id: "impact-pricing-table",
                  class: "mb-5"
                }),
                _createElementVNode("h4", _hoisted_10, [
                  _createTextVNode(_toDisplayString(_ctx.t('have_more_questions')) + " ", 1),
                  _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
                  _createElementVNode("span", {
                    class: "link",
                    onClick: _cache[3] || (_cache[3] = () => _ctx.openChat())
                  }, _toDisplayString(_ctx.t('get_in_touch')), 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('or')) + " ", 1),
                  _createElementVNode("a", _hoisted_11, _toDisplayString(_ctx.t('book_a_demo')), 1)
                ])
              ], 64))
            : _createCommentVNode("", true),
          _createVNode(_component_FrequentlyAskedQuestions, { "is-business": _ctx.getAccountTypeIsBusiness }, null, 8, ["is-business"])
        ]),
        _: 1
      })
    ])
  ]))
}