import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "header-tooltip-wrapper" }
const _hoisted_3 = { class: "partner-impact-header" }
const _hoisted_4 = { class: "partner-subheader" }
const _hoisted_5 = { class: "partner-text" }
const _hoisted_6 = {
  class: "partner-subheader",
  style: {"margin-top":"24px"}
}
const _hoisted_7 = { class: "partner-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReferralStats = _resolveComponent("ReferralStats")!

  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t('header')), 1)
        ]),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t('funded')), 1),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.t('funded_text')), 1),
        _createVNode(_component_ReferralStats, {
          "referrals-count": _ctx.partnersNumber,
          impacts: _ctx.relationImpact,
          type: "partners"
        }, null, 8, ["referrals-count", "impacts"]),
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.t('total')), 1),
        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.t('total_text')), 1),
        _createVNode(_component_ReferralStats, {
          impacts: _ctx.relationImpactTotal,
          type: "partners"
        }, null, 8, ["impacts"])
      ]))
    : _createCommentVNode("", true)
}