import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "success-page" }
const _hoisted_2 = { class: "success-card" }
const _hoisted_3 = { class: "success-title" }
const _hoisted_4 = { class: "success-text" }
const _hoisted_5 = { class: "success-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gs_button = _resolveComponent("gs-button")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.t('title')), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.t('text')), 1),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.t('greeting')), 1),
      _createVNode(_component_gs_button, {
        "full-width": true,
        size: "large",
        onClick: _withModifiers(_ctx.onClick, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('button_text')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}