import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "checkout-view" }
const _hoisted_2 = { class: "checkout-wrapper" }
const _hoisted_3 = { class: "side-wrapper side-wrapper--left" }
const _hoisted_4 = { class: "subscription-items" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "total" }
const _hoisted_7 = { class: "total-text" }
const _hoisted_8 = {
  key: 0,
  class: "total-number"
}
const _hoisted_9 = {
  key: 1,
  class: "total-number"
}
const _hoisted_10 = {
  key: 2,
  class: "total-number"
}
const _hoisted_11 = { class: "side-wrapper side-wrapper--right" }
const _hoisted_12 = { class: "checkout-header" }
const _hoisted_13 = ["src"]
const _hoisted_14 = { class: "checkout-title" }
const _hoisted_15 = {
  key: 0,
  class: "checkout-checklist"
}
const _hoisted_16 = { class: "checkout-checklist-item" }
const _hoisted_17 = { class: "checkout-checklist-item" }
const _hoisted_18 = { class: "checkout-checklist-item" }
const _hoisted_19 = { id: "payment-form" }
const _hoisted_20 = { class: "button-wrapper" }
const _hoisted_21 = {
  key: 1,
  class: "payment-on-file"
}
const _hoisted_22 = {
  key: 2,
  class: "error-message"
}
const _hoisted_23 = {
  class: "subscription-items mobile",
  style: {"margin-top":"34px"}
}
const _hoisted_24 = { class: "total" }
const _hoisted_25 = { class: "total-text" }
const _hoisted_26 = {
  key: 0,
  class: "total-number"
}
const _hoisted_27 = {
  key: 1,
  class: "total-number"
}
const _hoisted_28 = {
  key: 2,
  class: "total-number"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_CheckoutItem = _resolveComponent("CheckoutItem")!
  const _component_ReferenceBox = _resolveComponent("ReferenceBox")!
  const _component_AddPromoCode = _resolveComponent("AddPromoCode")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_LoadingOpaque = _resolveComponent("LoadingOpaque")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("a", {
            class: "navigate-back",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.back()))
          }, [
            _createVNode(_component_v_icon, { class: "chevron" }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("mdi-chevron-left")
              ])),
              _: 1
            }),
            _createElementVNode("img", {
              class: "logo",
              src: require('@/assets/greenspark-logo.svg'),
              alt: "greenspark-logo"
            }, null, 8, _hoisted_5)
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, a) => {
            return (_openBlock(), _createBlock(_component_CheckoutItem, {
              key: a,
              type: item.type,
              amount: item.amount,
              cost: item.cost,
              currency: _ctx.account.currency,
              trial: _ctx.trial,
              country: item.country
            }, null, 8, ["type", "amount", "cost", "currency", "trial", "country"]))
          }), 128)),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, _toDisplayString(!!_ctx.planType ? _ctx.t('total_today') : _ctx.t('total')), 1),
            (_ctx.account.currency === 'euro')
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.currencyEuro(_ctx.total)) + " " + _toDisplayString(_ctx.getUserCurrencyCode), 1))
              : _createCommentVNode("", true),
            (_ctx.account.currency === 'pound')
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.currencyPound(_ctx.total)) + " " + _toDisplayString(_ctx.getUserCurrencyCode), 1))
              : _createCommentVNode("", true),
            (_ctx.account.currency === 'dollar')
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.currencyDollar(_ctx.total)) + " " + _toDisplayString(_ctx.getUserCurrencyCode), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _createVNode(_component_ReferenceBox)
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("a", {
            class: "navigate-back",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.back()))
          }, [
            _createVNode(_component_v_icon, { class: "chevron" }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("mdi-chevron-left")
              ])),
              _: 1
            }),
            _createElementVNode("img", {
              class: "logo",
              src: require('@/assets/greenspark-logo.svg'),
              alt: "greenspark-logo"
            }, null, 8, _hoisted_13)
          ]),
          _createElementVNode("h2", _hoisted_14, _toDisplayString(_ctx.t('header')), 1),
          (_ctx.shouldShowTrial)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_15, [
                _createElementVNode("li", _hoisted_16, [
                  _createVNode(_component_v_icon, { class: "checkmark" }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode(" mdi-check ")
                    ])),
                    _: 1
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.t('free_trial')), 1)
                ]),
                _createElementVNode("li", _hoisted_17, [
                  _createVNode(_component_v_icon, { class: "checkmark" }, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createTextVNode(" mdi-check ")
                    ])),
                    _: 1
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.t('easy_setup')), 1)
                ]),
                _createElementVNode("li", _hoisted_18, [
                  _createVNode(_component_v_icon, { class: "checkmark" }, {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createTextVNode(" mdi-check ")
                    ])),
                    _: 1
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.t('customisable')), 1)
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("form", _hoisted_19, [
          _cache[8] || (_cache[8] = _createElementVNode("div", { id: "payment-element" }, null, -1)),
          (!_ctx.subscriptionItems.length && _ctx.paymentType === 'subscription')
            ? (_openBlock(), _createBlock(_component_AddPromoCode, {
                key: 0,
                onSetPromoCode: _ctx.setPromoCode
              }, null, 8, ["onSetPromoCode"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_20, [
            _createVNode(_component_gs_button, {
              id: "submit",
              type: "primary",
              size: "large",
              "full-width": "",
              disabled: _ctx.buttonLoading,
              loading: _ctx.buttonLoading,
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.pay()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
              ]),
              _: 1
            }, 8, ["disabled", "loading"])
          ]),
          (_ctx.showUpgradeText && _ctx.shouldShowTrial)
            ? (_openBlock(), _createElementBlock("p", _hoisted_21, _toDisplayString(_ctx.t('payment_on_file')), 1))
            : _createCommentVNode("", true),
          (_ctx.showError)
            ? (_openBlock(), _createElementBlock("div", _hoisted_22, _toDisplayString(_ctx.errorMessage), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_23, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, a) => {
            return (_openBlock(), _createBlock(_component_CheckoutItem, {
              key: a,
              type: item.type,
              amount: item.amount,
              cost: item.cost,
              currency: _ctx.account.currency,
              country: item.country
            }, null, 8, ["type", "amount", "cost", "currency", "country"]))
          }), 128)),
          _createElementVNode("div", _hoisted_24, [
            _createElementVNode("div", _hoisted_25, _toDisplayString(!!_ctx.planType ? _ctx.t('total_today') : _ctx.t('total')), 1),
            (_ctx.account.currency === 'euro')
              ? (_openBlock(), _createElementBlock("div", _hoisted_26, _toDisplayString(_ctx.currencyEuro(_ctx.total)) + " " + _toDisplayString(_ctx.getUserCurrencyCode), 1))
              : _createCommentVNode("", true),
            (_ctx.account.currency === 'pound')
              ? (_openBlock(), _createElementBlock("div", _hoisted_27, _toDisplayString(_ctx.currencyPound(_ctx.total)) + " " + _toDisplayString(_ctx.getUserCurrencyCode), 1))
              : _createCommentVNode("", true),
            (_ctx.account.currency === 'dollar')
              ? (_openBlock(), _createElementBlock("div", _hoisted_28, _toDisplayString(_ctx.currencyDollar(_ctx.total)) + " " + _toDisplayString(_ctx.getUserCurrencyCode), 1))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_LoadingOpaque, { key: 0 }))
        : _createCommentVNode("", true)
    ])
  ]))
}