import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "upcoming-events-card d-flex flex-column ga-2 rounded-lg pa-4" }
const _hoisted_2 = { class: "date mb-0" }
const _hoisted_3 = { class: "name mb-0" }
const _hoisted_4 = { class: "description mb-0" }

import type { CalendarEvent } from '@/helpers/event-calendar'


export default /*@__PURE__*/_defineComponent({
  __name: 'UpcomingEventCard',
  props: {
    calendarEvent: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.calendarEvent.displayDates), 1),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.calendarEvent.name), 1),
    _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.calendarEvent.description), 1)
  ]))
}
}

})