import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "preview-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "preview"
}
const _hoisted_3 = { class: "email" }
const _hoisted_4 = { class: "subject-wrapper" }
const _hoisted_5 = { class: "preview-subject" }
const _hoisted_6 = { class: "subject-icon-wrapper" }
const _hoisted_7 = { class: "icons-left" }
const _hoisted_8 = { class: "icons-right" }
const _hoisted_9 = { class: "preview-pre-header" }
const _hoisted_10 = { class: "address-wrapper" }
const _hoisted_11 = { class: "account-wrapper" }
const _hoisted_12 = { class: "account-icon-wrapper" }
const _hoisted_13 = { class: "sender-details" }
const _hoisted_14 = { class: "email-alias-wrapper" }
const _hoisted_15 = { class: "preview-alias" }
const _hoisted_16 = { class: "address-details-wrapper" }
const _hoisted_17 = { class: "address-details-icons" }
const _hoisted_18 = { class: "email-body" }
const _hoisted_19 = ["src"]
const _hoisted_20 = ["innerHTML"]
const _hoisted_21 = ["src"]
const _hoisted_22 = ["src"]
const _hoisted_23 = {
  key: 0,
  class: "preview-impact"
}
const _hoisted_24 = ["src"]
const _hoisted_25 = {
  key: 1,
  class: "preview-impact"
}
const _hoisted_26 = ["src"]
const _hoisted_27 = ["src"]
const _hoisted_28 = ["src"]
const _hoisted_29 = ["src"]
const _hoisted_30 = { class: "preview-footer" }
const _hoisted_31 = ["src"]
const _hoisted_32 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", null, _toDisplayString(_ctx.t('text')), 1),
    (_ctx.settings && _ctx.content)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.resolveVariables(_ctx.settings.subject)), 1),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_v_icon, { class: "email-icon" }, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createTextVNode(" mdi-label-variant-outline ")
                    ])),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_v_icon, { class: "email-icon" }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode(" mdi-printer ")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_v_icon, { class: "email-icon" }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode(" mdi-open-in-new ")
                    ])),
                    _: 1
                  })
                ])
              ])
            ]),
            _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.resolveVariables(_ctx.settings.preheader)), 1),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(_component_v_icon, { class: "email-icon" }, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode(" mdi-account ")
                    ])),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.resolveVariables(_ctx.settings.alias)) + " " + _toDisplayString(!_ctx.emailName || !_ctx.emailDomain
                      ? `< ${_ctx.defaultEmailAddress}>`
                      : `<
                      ${_ctx.emailName}@${_ctx.emailDomain}>`), 1)
                  ]),
                  _cache[4] || (_cache[4] = _createElementVNode("p", { class: "address-flavor" }, "to me", -1))
                ])
              ]),
              _createElementVNode("div", _hoisted_16, [
                _cache[8] || (_cache[8] = _createElementVNode("p", { class: "address-detail" }, "9:15 AM (8 hours ago)", -1)),
                _createElementVNode("div", _hoisted_17, [
                  _createVNode(_component_v_icon, { class: "email-icon" }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode(" mdi-star-outline ")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_v_icon, { class: "email-icon" }, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createTextVNode(" mdi-share ")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_v_icon, { class: "email-icon" }, {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createTextVNode(" mdi-dots-vertical ")
                    ])),
                    _: 1
                  })
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("img", {
                class: "preview-header-image mb-4",
                src: _ctx.content.headerImage
              }, null, 8, _hoisted_19),
              _createElementVNode("div", {
                class: "preview-body",
                innerHTML: _ctx.formatWhiteSpacesToLineBreak(_ctx.resolveVariables(_ctx.content.body))
              }, null, 8, _hoisted_20),
              _createElementVNode("img", {
                src: require('@/assets/backgrounds/email-preview-impacts.png'),
                alt: "",
                class: "footer-image mb-4"
              }, null, 8, _hoisted_21),
              _createElementVNode("img", {
                src: _ctx.buttonURL,
                alt: "",
                class: "footer-image"
              }, null, 8, _hoisted_22),
              (!_ctx.content.isTotalImpactHidden)
                ? (_openBlock(), _createElementBlock("p", _hoisted_23, [
                    _createElementVNode("img", {
                      src: require('@/assets/backgrounds/email-preview-impacts-2.png'),
                      alt: "",
                      class: "footer-image"
                    }, null, 8, _hoisted_24)
                  ]))
                : _createCommentVNode("", true),
              (!_ctx.content.isFooterHidden)
                ? (_openBlock(), _createElementBlock("p", _hoisted_25, [
                    _createElementVNode("img", {
                      src: require('@/assets/backgrounds/email-preview-footer-1.png'),
                      alt: "",
                      class: "footer-image"
                    }, null, 8, _hoisted_26),
                    _createElementVNode("img", {
                      src: require('@/assets/backgrounds/email-preview-footer-5.png'),
                      alt: "",
                      class: "footer-image"
                    }, null, 8, _hoisted_27),
                    _createElementVNode("img", {
                      src: require('@/assets/backgrounds/email-preview-footer-2.png'),
                      alt: "",
                      class: "footer-image"
                    }, null, 8, _hoisted_28),
                    _createElementVNode("img", {
                      src: _ctx.buttonURL,
                      alt: "",
                      class: "footer-image"
                    }, null, 8, _hoisted_29)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("p", _hoisted_30, [
                (_ctx.settings.from.domain === _ctx.defaultEmailDomain)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: require('@/assets/backgrounds/email-preview-footer-3.png'),
                      alt: "",
                      class: "footer-image"
                    }, null, 8, _hoisted_31))
                  : (_openBlock(), _createElementBlock("img", {
                      key: 1,
                      src: require('@/assets/backgrounds/email-preview-footer-4.png'),
                      alt: "",
                      class: "footer-image"
                    }, null, 8, _hoisted_32))
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}