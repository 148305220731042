export function extractDomain(url: string): string | undefined {
  const domainRegex = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/
  const matches = url.match(domainRegex)
  if (matches && matches.length >= 2) {
    const domainParts = matches[1].split('.').slice(-2) // Extract last two parts of domain
    return '.' + domainParts.join('.')
  }
  return undefined
}

export const formatLineBreaksToWhiteSpace = (string: string) => {
  let returnValue = string
  returnValue = string.replace(new RegExp('<br />', 'g'), '\n')
  return returnValue
}

export const formatWhiteSpacesToLineBreak = (string: string): string => {
  let returnValue = string
  returnValue = string.replace(new RegExp(/\r?\n/, 'g'), '<br />')
  return returnValue
}

export function includes<T extends U, U>(coll: ReadonlyArray<T>, el: U): el is T {
  return coll.includes(el as T)
}

export function formatDateToLocal(date: Date): string {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}

// Turns a Date object into `YYYY/MM/DD` and
// joins the date string into an orderable Number
export const formatDateAsYYYYMMDD = (date: Date): number => {
  const dateSegments = date.toLocaleDateString().split('/')
  return Number(`${dateSegments[2]}${dateSegments[1]}${dateSegments[0]}`)
}

export function formatDateToUTC(dateString: Date | string): string {
  const date = new Date(dateString)

  const dateOptions: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    timeZone: 'UTC',
  }

  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZone: 'UTC',
  }

  const formattedDate = date.toLocaleDateString('en-GB', dateOptions)
  const formattedTime = date.toLocaleTimeString('en-US', timeOptions)

  return `${formattedDate}, ${formattedTime} UTC`
}

export function formatItemsToReadable(items: string[]): string {
  if (items.length === 0) return ''
  if (items.length === 1) return items[0]

  const lastItem = items.pop() // Remove the last element
  return `${items.join(', ')} and ${lastItem}`
}
