import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "impact-setting-card-wrapper" }
const _hoisted_2 = ["id"]
const _hoisted_3 = {
  class: /*@__PURE__*/_normalizeClass(['project-content'])
}
const _hoisted_4 = { class: "project-detail name" }
const _hoisted_5 = { class: "project-detail cost" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImpactSettingCardHeader = _resolveComponent("ImpactSettingCardHeader")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_ImpactAmountStepper = _resolveComponent("ImpactAmountStepper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      id: 'impact-setting-card-' + _ctx.projectId,
      class: _normalizeClass(['impact-setting-card', _ctx.impactType, { 'is-active': !!_ctx.modelValue }])
    }, [
      (_ctx.projectId)
        ? (_openBlock(), _createBlock(_component_ImpactSettingCardHeader, {
            key: 0,
            "project-id": _ctx.projectId
          }, null, 8, ["project-id"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_v_icon, { class: "project-icon" }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode(" mdi-hand-heart ")
            ])),
            _: 1
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t(`Projects.${_ctx.projectId}.name`)), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_v_icon, { class: "project-icon" }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode(" mdi-cash-multiple")
            ])),
            _: 1
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t(`${_ctx.getProjectOffsetTypeById(_ctx.projectId ?? '')}.price_per_unit`, {
              price: _ctx.projectId ? _ctx.getFormattedProjectPriceById(_ctx.projectId) : '',
            })), 1)
        ]),
        (_ctx.singleSelection)
          ? (_openBlock(), _createBlock(_component_gs_button, {
              key: 0,
              size: "large",
              type: "secondary",
              class: "single-select-button",
              active: _ctx.isButtonActive,
              onClick: _cache[0] || (_cache[0] = _withModifiers(() => _ctx.onUpdate(1), ["prevent"]))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.selectButtonText), 1)
              ]),
              _: 1
            }, 8, ["active"]))
          : (_openBlock(), _createBlock(_component_ImpactAmountStepper, {
              key: 1,
              "model-value": _ctx.modelValue,
              "max-value": _ctx.maxValue,
              "onUpdate:modelValue": _ctx.onUpdate
            }, null, 8, ["model-value", "max-value", "onUpdate:modelValue"]))
      ])
    ], 10, _hoisted_2)
  ]))
}