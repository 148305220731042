<script lang="ts">
import type { Component } from 'vue'
import { defineComponent } from 'vue'
import type { Clearable, Comparison, OffsetType } from '@/helpers/interfaces'
import { OFFSET_TYPES } from '@/helpers/interfaces'
import { IMPACT_TYPE_TOP_STAT_CARD_COLOR_MAP } from '@/helpers/projects'
import type { ValueSelectorValue } from '@/components/your-impact/ValueSelector.vue'
import ValueSelector from '@/components/your-impact/ValueSelector.vue'
import TreesComparison from '@/components/your-impact/TreesComparison.vue'
import CarbonComparison from '@/components/your-impact/CarbonComparison.vue'
import PlasticComparison from '@/components/your-impact/PlasticComparison.vue'
import KelpComparison from '@/components/your-impact/KelpComparison.vue'
import WaterComparison from '@/components/your-impact/WaterComparison.vue'
import BeesComparison from '@/components/your-impact/BeesComparison.vue'
import type { TranslateResult } from 'vue-i18n'
import type { ITypedComparison } from '@api/index'
import { getPublicAccount } from '@api/index'

const comparisonComponentsMap: Record<OffsetType, Component> = {
  carbon: CarbonComparison,
  plastic: PlasticComparison,
  trees: TreesComparison,
  kelp: KelpComparison,
  water: WaterComparison,
  bees: BeesComparison,
}
const comparisonComponents = Object.values(comparisonComponentsMap).reduce((acc, component) => {
  acc[`${component?.name}`] = component
  return acc
}, {} as Record<string, Component>)

export default defineComponent({
  name: 'ImpactComparison',
  components: {
    ValueSelector,
    ...comparisonComponents,
  },
  data() {
    return {
      selectedImpactType: null,
      comparisons: [],
    } as {
      selectedImpactType: Clearable<OffsetType>
      comparisons: ITypedComparison[]
    }
  },
  computed: {
    valuesForValueSelector(): ValueSelectorValue[] {
      return OFFSET_TYPES.map((type) => ({
        value: type,
        label: this.$t(`${type}.title`),
      }))
    },
    title(): TranslateResult {
      return this.$t(`${this.selectedImpactType}.equivalent_to`)
    },
    selectedComparisonComponent(): Clearable<string> {
      return this.selectedImpactType
        ? `${
            this.selectedImpactType.slice(0, 1).toUpperCase() + this.selectedImpactType.slice(1)
          }Comparison`
        : null
    },
    colorSettings(): Record<OffsetType, string> {
      return IMPACT_TYPE_TOP_STAT_CARD_COLOR_MAP
    },
    comparisonsBySelectedType(): Comparison | null {
      if (!this.selectedImpactType) return null
      const comparisons =
        this.comparisons.filter((comparison) => comparison.type === this.selectedImpactType) || null
      const result = comparisons.reduce((acc, comparison) => {
        acc[comparison.unit] = {
          link: comparison.link,
          amount: comparison.amount,
          suffix: comparison.suffix,
          unit: comparison.unit,
          amountDescription: comparison.amountDescription,
          description: comparison.description,
          type: comparison.type,
        }
        return acc satisfies Comparison
      }, {} as Comparison)
      return Object.keys(result).length ? result : null
    },
    getTotalImpactsByType(): { type: OffsetType; amount: number }[] {
      return this.$store.getters['getTotalImpactsByType']
    },
    userLocale(): string {
      return this.$store.getters['getUserLocale']
    },
    livePageId(): string {
      return this.$store.getters['getLivePageId']
    },
  },
  async created() {
    this.selectedImpactType = OFFSET_TYPES[0]
    const { data } = await getPublicAccount(this.livePageId)
    this.comparisons = data.comparisons
  },
  methods: {
    onValueSelect(value: OffsetType) {
      this.selectedImpactType = value
    },
  },
})
</script>

<template>
  <section class="impact-comparison-v2-container section section--lg">
    <v-container>
      <div>
        <h2 class="section-title">
          {{ title }}
        </h2>
      </div>
      <ValueSelector
        class="filter-buttons"
        :values="valuesForValueSelector"
        :selected-value="selectedImpactType ?? ''"
        @select="onValueSelect"
      />
      <component
        :is="selectedComparisonComponent"
        v-if="selectedComparisonComponent && comparisonsBySelectedType"
        :color-settings="colorSettings"
        :comparisons="comparisonsBySelectedType"
      />
    </v-container>
  </section>
</template>

<style scoped lang="scss">
@import '~vuetify/settings';

.impact-comparison-v2-container {
  width: 100%;
  background: var(--ui-white);
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0px 4px 4px 0px #0000001a;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.filter-buttons {
  margin-bottom: 16px;
}

@media #{map-get($display-breakpoints, 'xs')} {
  .impact-comparison-v2-container {
    padding: 0px;
  }
}
</style>
