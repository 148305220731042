import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createElementBlock("a", {
    class: "navigate-back",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
  }, [
    _createVNode(_component_v_icon, { class: "navigate-back__icon" }, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [
        _createTextVNode("mdi-chevron-left")
      ])),
      _: 1
    }),
    _createTextVNode(" " + _toDisplayString(_ctx.t('back')), 1)
  ]))
}