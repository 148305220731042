import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "upcoming-events" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "event-list-container" }
const _hoisted_4 = { class: "event-list" }

import { useI18n } from 'vue-i18n'

import type { CalendarEvent } from '@/helpers/event-calendar'
import { CALENDAR_2025 } from '@/helpers/event-calendar'
import { formatDateAsYYYYMMDD } from '@/helpers/parsers'
import UpcomingEventCard from '@/components/event-calendar/UpcomingEventCard.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'UpcomingEvents',
  emits: ['see-more'],
  setup(__props) {

const i18n = useI18n()


const { Q1, Q2, Q3, Q4 } = CALENDAR_2025
const upcomingEvents: CalendarEvent[] = [...Q1, ...Q2, ...Q3, ...Q4].filter(
  (e) => formatDateAsYYYYMMDD(new Date()) <= formatDateAsYYYYMMDD(new Date(e.endDate)),
)

const t = (key: string) => i18n.t(`UpcomingEvents.${key}`)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, _toDisplayString(t('title')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(upcomingEvents).slice(0, 3), (event) => {
          return (_openBlock(), _createBlock(UpcomingEventCard, {
            "calendar-event": event,
            key: event.name
          }, null, 8, ["calendar-event"]))
        }), 128))
      ])
    ]),
    _createElementVNode("a", {
      class: "see-more",
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('see-more')), ["prevent"]))
    }, _toDisplayString(t('see_more')), 1)
  ]))
}
}

})