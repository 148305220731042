import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "reset" }
const _hoisted_2 = { class: "reset-wrapper" }
const _hoisted_3 = { class: "logo-wrapper" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "header" }
const _hoisted_6 = { class: "password-reset-input-wrapper" }
const _hoisted_7 = { class: "button-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_v_form, {
        modelValue: _ctx.isFormValid,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isFormValid) = $event)),
        class: "reset-form",
        autocomplete: "on"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              class: "logo",
              src: require('@/assets/greenspark-logo.svg'),
              alt: "greenspark-app"
            }, null, 8, _hoisted_4)
          ]),
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.t('forgot_password')), 1),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_v_text_field, {
              id: "Email",
              modelValue: _ctx.user.email,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.user.email) = $event)),
              class: "input-field",
              name: "Email",
              autocomplete: "email",
              type: "email",
              label: _ctx.t('email'),
              variant: "outlined",
              flat: "",
              color: "#3B755F",
              onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.resetPassword()), ["enter"])),
              rules: [_ctx.rules.email, _ctx.rules.required]
            }, null, 8, ["modelValue", "label", "rules"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_gs_button, {
              disabled: !_ctx.isFormValid,
              "full-width": true,
              size: "large",
              onClick: _withModifiers(_ctx.resetPassword, ["prevent"])
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('forgot_password')), 1)
              ]),
              _: 1
            }, 8, ["disabled", "onClick"])
          ]),
          (_ctx.passwordReset || _ctx.passwordResetError)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "email-sent",
                style: _normalizeStyle(_ctx.passwordResetError ? 'color: red' : '')
              }, _toDisplayString(_ctx.passwordResetError ? _ctx.t('error') : _ctx.t('email_sent')), 5))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: "login",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (!_ctx.fromAppMarketplace ? _ctx.$router.push('/login') : _ctx.$router.push('/login?app')))
          }, _toDisplayString(_ctx.t('login')), 1)
        ]),
        _: 1
      }, 8, ["modelValue"])
    ])
  ]))
}