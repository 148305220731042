import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "enterprise-plan-card" }
const _hoisted_2 = { class: "header-text-wrapper" }
const _hoisted_3 = { class: "header-title" }
const _hoisted_4 = { class: "header-desc" }
const _hoisted_5 = { class: "header-button-wrapper" }
const _hoisted_6 = {
  class: /*@__PURE__*/_normalizeClass(['content'])
}
const _hoisted_7 = { class: "feature-list" }
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_v_expand_transition = _resolveComponent("v-expand-transition")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(['enterprise-plan-card-header', { 'is-open': _ctx.isOpen }])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.t('header_title')), 1),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.t('header_desc')), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_gs_button, {
          class: "header-button",
          type: "tertiary",
          href: "https://www.getgreenspark.com/book-a-demo"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('start')), 1)
          ]),
          _: 1
        }),
        _createElementVNode("button", {
          class: _normalizeClass(['toggle-content-button', { 'is-open': _ctx.isOpen }]),
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleContent && _ctx.toggleContent(...args)), ["prevent"]))
        }, _toDisplayString(_ctx.t('see_details')), 3)
      ])
    ], 2),
    _createVNode(_component_v_expand_transition, null, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_6, [
          _createElementVNode("ul", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.featureListItems, (item, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                class: "feature-item",
                innerHTML: _ctx.t(`${_ctx.featuresTranslationKey}.${index}`)
              }, null, 8, _hoisted_8))
            }), 128))
          ])
        ], 512), [
          [_vShow, _ctx.isOpen]
        ])
      ]),
      _: 1
    })
  ]))
}