<template>
  <svg fill="none" height="84" viewBox="0 0 84 84" width="84" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M42 34.0465C47.888 34.0465 52.6881 29.2809 52.6881 23.4448C52.6881 20.6648 51.6003 18.1266 49.8218 16.2273C50.6679 14.7596 51.514 13.3092 52.3773 11.8415C52.3255 11.6861 51.8766 10.5292 50.6506 10.1148C49.8564 9.85582 49.183 10.0285 48.924 10.1148C48.3024 11.4444 47.6635 12.7566 47.0419 14.0862C45.5397 13.2919 43.8303 12.843 42.0173 12.843C40.2043 12.843 38.4948 13.2919 36.9926 14.0862C36.371 12.7566 35.7322 11.4444 35.1106 10.1148C34.8516 10.0285 34.1781 9.85582 33.3839 10.1148C32.1579 10.5292 31.709 11.6861 31.6572 11.8415C32.5033 13.3092 33.3493 14.7596 34.2127 16.2273C32.4342 18.1266 31.3464 20.6475 31.3464 23.4448C31.3464 29.2982 36.1466 34.0465 42.0345 34.0465H42Z"
      fill="black"
    />
    <path
      d="M27.8585 23.4274C27.8585 22.8748 27.8931 22.3396 27.9449 21.8043C18.7935 19.9567 10.7472 22.754 9.3831 28.3657C8.01902 34.1327 14.3905 40.4006 23.8872 42.6625C24.1807 42.7316 24.457 42.7834 24.7505 42.8352C24.8023 42.6453 24.8541 42.4553 24.9232 42.2654C26.2009 38.225 28.2557 34.7026 30.8802 32.0953C28.9809 29.7125 27.8585 26.6908 27.8585 23.4274Z"
      fill="black"
    />
    <path
      d="M42 44.6486C36.9581 44.6486 32.0371 45.4256 27.3232 46.8933C27.0988 48.1538 26.9434 49.466 26.8916 50.7956C31.7608 49.3452 36.82 48.5682 42 48.5682C47.18 48.5682 52.2737 49.3279 57.1084 50.7265C57.0393 49.3797 56.8839 48.0674 56.6595 46.807C50.478 44.9421 44.9181 44.6313 42 44.6313V44.6486Z"
      fill="black"
    />
    <path
      d="M26.9779 54.3869C27.2542 56.4934 27.9449 58.5136 28.8773 60.3784C33.1249 59.4287 37.5107 58.9453 41.9827 58.9453C47.2836 58.9453 51.8939 59.6705 55.1055 60.3784C56.0379 58.4963 56.7286 56.4761 57.0048 54.3696C52.2392 52.8846 46.9901 52.0386 41.9827 52.0386C36.9754 52.0386 31.7781 52.8501 26.9607 54.3869H26.9779Z"
      fill="black"
    />
    <path
      d="M30.7421 63.5218C34.8171 69.4961 40.6705 73.4674 41.0504 73.7091C41.6374 74.1063 42.3626 74.089 42.9497 73.7091C43.3296 73.4674 49.183 69.4961 53.2579 63.5218C50.3053 62.9347 46.403 62.3994 42 62.3994C38.1668 62.3994 34.4026 62.7965 30.7421 63.5218Z"
      fill="black"
    />
    <path
      d="M42 37.4997C38.8056 37.4997 35.8703 36.4291 33.4875 34.6507C33.4357 34.6161 33.3839 34.5643 33.3148 34.5298C31.1737 36.6709 29.4643 39.5889 28.342 42.9732C32.7623 41.7991 37.338 41.1948 42.0173 41.1948C46.6965 41.1948 51.255 41.8164 55.6753 42.956C54.5529 39.5717 52.8262 36.6536 50.7024 34.5298L50.5298 34.6507C48.147 36.4291 45.2116 37.4997 42.0173 37.4997H42Z"
      fill="black"
    />
    <path
      d="M74.6169 28.3485C73.2528 22.7541 65.2065 19.9569 56.0552 21.8044C56.107 22.3397 56.1415 22.875 56.1415 23.4275C56.1415 26.6909 55.0192 29.6953 53.1198 32.0782L53.1543 32.1127C55.7444 34.7027 57.7991 38.2251 59.0941 42.2828C59.1459 42.4555 59.215 42.6454 59.2495 42.8354C59.543 42.7836 59.8366 42.7145 60.1301 42.6454C69.6096 40.4007 75.981 34.1329 74.6169 28.3485Z"
      fill="black"
    />
  </svg>
</template>
