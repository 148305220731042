import { withAsyncContext as _withAsyncContext, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "popup-title" }
const _hoisted_2 = { class: "close-button-wrapper" }
const _hoisted_3 = {
  key: 0,
  class: "setup-instruction"
}
const _hoisted_4 = { class: "rebuy-disclaimer" }
const _hoisted_5 = { class: "mb-0" }
const _hoisted_6 = { class: "tutorial-intro" }
const _hoisted_7 = { class: "instruction-list" }
const _hoisted_8 = { class: "tutorial-title" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = ["href"]
const _hoisted_11 = ["href"]
const _hoisted_12 = {
  key: 1,
  class: "shopify-warning"
}

import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import { REBUY_URL, REBUY_INSTALLATION_GUIDE_URL } from '@/helpers/constants'
import { Utils } from '@/helpers/mixins/utilsMixin'
import { getApiKeys } from '@api/index'

import type { ApiKeyResponse } from '@api/index'
import type { Notification } from '@/store/notification'


export default /*@__PURE__*/_defineComponent({
  __name: 'RebuyIntegrationPopup',
  props: {
    isOpen: { type: Boolean }
  },
  emits: ['update:is-open'],
  async setup(__props: any, { emit: __emit }) {

let __temp: any, __restore: any

const store = useStore()
const i18n = useI18n()

const isMobile = ref<boolean>(Utils.isMobile)
const isMobileLarge = ref<boolean>(Utils.isMobileLarge)
const apiKeys = ref<ApiKeyResponse[]>([])

const emit = __emit


const isShopifyActive = computed(() => store.getters['getIsShopifyActive'])
const hasWidgetApiKey = computed<boolean>(() => {
  return !!apiKeys.value.find((k) => k.subType === 'WIDGET_API_KEY')
})

const fetchApiKeys = async () => {
  try {
    const { data } = await getApiKeys()
    apiKeys.value = data
  } catch (error) {
    store.dispatch('notification/notify', {
      text: 'An unexpected error occurred while trying to retrieve your API keys. Please try again or get in touch.',
      isError: true,
    } as Notification)
    console.error(error)
  }
}
const t = (key: string) => i18n.t(`RebuyIntegrationPopup.${key}`)
const close = () => emit('update:is-open', false)

;(
  ([__temp,__restore] = _withAsyncContext(() => fetchApiKeys())),
  await __temp,
  __restore()
)

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    "model-value": _ctx.isOpen,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = (v) => emit('update:is-open', v)),
    width: "700",
    transition: "fade",
    fullscreen: isMobile.value || isMobileLarge.value
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, { class: "rebuy-setup-container" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, { class: "popup-header" }, {
            default: _withCtx(() => [
              _createElementVNode("h5", _hoisted_1, _toDisplayString(isShopifyActive.value ? t('setup_title') : t('default_title')), 1),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_v_icon, {
                  size: "26px",
                  color: "black",
                  onClick: close
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode(" mdi-close ")
                  ])),
                  _: 1
                })
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_text, { class: "popup-content" }, {
            default: _withCtx(() => [
              (isShopifyActive.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_v_icon, null, {
                        default: _withCtx(() => _cache[2] || (_cache[2] = [
                          _createTextVNode("mdi-information-outline")
                        ])),
                        _: 1
                      }),
                      _createElementVNode("p", _hoisted_5, _toDisplayString(t('rebuy_disclaimer')), 1)
                    ]),
                    _createElementVNode("p", _hoisted_6, _toDisplayString(t('in_order_to_use')), 1),
                    _createElementVNode("ol", _hoisted_7, [
                      _createElementVNode("p", _hoisted_8, _toDisplayString(t('setting_up')), 1),
                      (!hasWidgetApiKey.value)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_9, [
                            _createTextVNode(_toDisplayString(t('setup_tutorial.step_1')) + " ", 1),
                            _createVNode(_component_router_link, { to: { name: 'Account', query: { tab: 'apiKey' } } }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(t('setup_tutorial.access_dashboard')) + " ", 1),
                                _createVNode(_component_v_icon, {
                                  color: "#0017B4",
                                  size: "20px"
                                }, {
                                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                                    _createTextVNode("mdi-open-in-new")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("li", null, [
                        _createTextVNode(_toDisplayString(t(hasWidgetApiKey.value ? 'setup_tutorial.step_2_1' : 'setup_tutorial.step_2_1_alt')) + " ", 1),
                        _createElementVNode("a", {
                          href: _unref(REBUY_URL),
                          target: "_blank"
                        }, [
                          _createTextVNode(_toDisplayString(t('setup_tutorial.login_to_rebuy')) + " ", 1),
                          _createVNode(_component_v_icon, {
                            color: "#0017B4",
                            size: "20px"
                          }, {
                            default: _withCtx(() => _cache[4] || (_cache[4] = [
                              _createTextVNode("mdi-open-in-new")
                            ])),
                            _: 1
                          })
                        ], 8, _hoisted_10),
                        _createTextVNode(" " + _toDisplayString(t('setup_tutorial.step_2_3')), 1)
                      ]),
                      _createElementVNode("li", null, [
                        _createTextVNode(_toDisplayString(t('setup_tutorial.step_3')) + " ", 1),
                        _createElementVNode("a", {
                          href: _unref(REBUY_INSTALLATION_GUIDE_URL),
                          target: "_blank"
                        }, [
                          _createTextVNode(_toDisplayString(t('setup_tutorial.installation_guide')) + " ", 1),
                          _createVNode(_component_v_icon, {
                            color: "#0017B4",
                            size: "20px"
                          }, {
                            default: _withCtx(() => _cache[5] || (_cache[5] = [
                              _createTextVNode("mdi-open-in-new")
                            ])),
                            _: 1
                          })
                        ], 8, _hoisted_11)
                      ]),
                      _createElementVNode("li", null, [
                        _createElementVNode("strong", null, _toDisplayString(t('setup_tutorial.all_set')), 1),
                        _createTextVNode(" " + _toDisplayString(t('setup_tutorial.step_4')), 1)
                      ])
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _createVNode(_component_v_icon, null, {
                      default: _withCtx(() => _cache[6] || (_cache[6] = [
                        _createTextVNode("mdi-information-outline")
                      ])),
                      _: 1
                    }),
                    _createElementVNode("p", null, [
                      _createElementVNode("strong", null, _toDisplayString(t('shopify_required_warning')), 1),
                      _createTextVNode(" " + _toDisplayString(t('shopify_required_feedback')), 1)
                    ])
                  ]))
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_actions, { class: "popup-actions" }, {
            default: _withCtx(() => [
              (isShopifyActive.value)
                ? (_openBlock(), _createBlock(_component_gs_button, {
                    key: 0,
                    "full-width": "",
                    size: "large",
                    type: "tertiary",
                    href: "/shareables/badges?compatibility=rebuy"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(t('setup_complete')), 1)
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_gs_button, {
                    key: 1,
                    "full-width": "",
                    size: "large",
                    type: "tertiary",
                    href: "/add-impact/manage-integrations-automations/integrations"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(t('setup_shopify_cta')), 1)
                    ]),
                    _: 1
                  }))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model-value", "fullscreen"]))
}
}

})