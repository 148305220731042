import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "login" }
const _hoisted_2 = { class: "user-component" }
const _hoisted_3 = { class: "user-component-wrapper" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "user-wrapper" }
const _hoisted_6 = { class: "form-wrapper" }
const _hoisted_7 = { class: "logo-wrapper" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "header" }
const _hoisted_10 = { class: "name-wrapper" }
const _hoisted_11 = { class: "text-field-wrapper" }
const _hoisted_12 = { class: "name-wrapper" }
const _hoisted_13 = { class: "text-field-wrapper" }
const _hoisted_14 = { class: "forgot-password" }
const _hoisted_15 = { class: "button-wrapper" }
const _hoisted_16 = {
  key: 0,
  class: "login-error"
}
const _hoisted_17 = {
  key: 1,
  class: "not-member"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_Loading = _resolveComponent("Loading")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("img", {
            class: "user-image",
            src: require('@/assets/login-image.svg'),
            alt: "login"
          }, null, 8, _hoisted_4),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("img", {
                  class: "logo",
                  src: require('@/assets/greenspark-logo.svg'),
                  alt: "greenspark-app"
                }, null, 8, _hoisted_8)
              ]),
              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.t('login')), 1),
              _createVNode(_component_v_form, {
                modelValue: _ctx.isFormValid,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isFormValid) = $event))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _createVNode(_component_v_text_field, {
                        id: "Email",
                        modelValue: _ctx.loginDetails.email,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.loginDetails.email) = $event)),
                        name: "Email",
                        autocomplete: "email",
                        type: "email",
                        rules: [_ctx.rules.required, _ctx.rules.email],
                        label: _ctx.t('email'),
                        variant: "outlined",
                        flat: "",
                        color: "green",
                        "hide-details": "",
                        onKeyup: _withKeys(_ctx.login, ["enter"])
                      }, null, 8, ["modelValue", "rules", "label", "onKeyup"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _createVNode(_component_v_text_field, {
                        id: "Password",
                        modelValue: _ctx.loginDetails.password,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.loginDetails.password) = $event)),
                        name: "password",
                        autocomplete: "password",
                        type: _ctx.showPassword ? 'text' : 'password',
                        label: _ctx.t('password'),
                        rules: [_ctx.rules.required],
                        "append-inner-icon": _ctx.showPassword ? 'mdi-eye' : 'mdi-eye-off',
                        variant: "outlined",
                        flat: "",
                        color: "green",
                        "hide-details": "",
                        "onClick:appendInner": _cache[2] || (_cache[2] = ($event: any) => (_ctx.showPassword = !_ctx.showPassword)),
                        onKeyup: _withKeys(_ctx.login, ["enter"])
                      }, null, 8, ["modelValue", "type", "label", "rules", "append-inner-icon", "onKeyup"])
                    ])
                  ])
                ]),
                _: 1
              }, 8, ["modelValue"]),
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_router_link, {
                  to: _ctx.fromAppMarketplace ? '/reset-password?app' : '/reset-password',
                  class: "forgot-password-text"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('forgot_password')), 1)
                  ]),
                  _: 1
                }, 8, ["to"])
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createVNode(_component_gs_button, {
                  disabled: !_ctx.loading && !_ctx.isFormValid,
                  "full-width": true,
                  size: "large",
                  onClick: _withModifiers(_ctx.login, ["prevent"])
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('login')), 1)
                  ]),
                  _: 1
                }, 8, ["disabled", "onClick"])
              ]),
              (_ctx.loginError)
                ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(_ctx.t('login_error')), 1))
                : _createCommentVNode("", true),
              (!_ctx.fromAppMarketplace)
                ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                    _createTextVNode(_toDisplayString(_ctx.t('not_member')) + " ", 1),
                    _createElementVNode("span", {
                      class: "green-text",
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$router.push('/choose-signup')))
                    }, _toDisplayString(_ctx.t('signup')), 1)
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ])
    ]),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_Transition, {
          key: 0,
          name: "fade"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Loading)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}