import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, Fragment as _Fragment, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "pop-up" }
const _hoisted_2 = { class: "pop-up-content-wrapper" }
const _hoisted_3 = {
  key: 0,
  class: "filter-container"
}
const _hoisted_4 = { class: "title-wrapper" }
const _hoisted_5 = { class: "compatible-text" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { key: 2 }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "overlay-text" }
const _hoisted_10 = { class: "how-it-works" }
const _hoisted_11 = { class: "hiw-title" }
const _hoisted_12 = { class: "number-explanation-wrapper" }
const _hoisted_13 = { class: "explanation" }
const _hoisted_14 = { class: "number-explanation-wrapper" }
const _hoisted_15 = { class: "explanation" }
const _hoisted_16 = { class: "number-explanation-wrapper" }
const _hoisted_17 = { class: "explanation" }
const _hoisted_18 = { class: "number-explanation-wrapper" }
const _hoisted_19 = { class: "explanation" }
const _hoisted_20 = { class: "number-explanation-wrapper" }
const _hoisted_21 = { class: "explanation" }
const _hoisted_22 = { class: "number-explanation-wrapper" }
const _hoisted_23 = { class: "explanation" }
const _hoisted_24 = { class: "support" }
const _hoisted_25 = {
  href: "https://help.shopify.com/en/manual/online-store/themes/theme-structure/extend/apps#app-blocks",
  target: "_blank",
  class: "green-text"
}
const _hoisted_26 = { class: "overlay-text" }
const _hoisted_27 = { class: "how-it-works" }
const _hoisted_28 = { class: "hiw-title" }
const _hoisted_29 = { class: "number-explanation-wrapper" }
const _hoisted_30 = { class: "explanation" }
const _hoisted_31 = { class: "number-explanation-wrapper" }
const _hoisted_32 = { class: "explanation" }
const _hoisted_33 = ["href"]
const _hoisted_34 = { class: "number-explanation-wrapper" }
const _hoisted_35 = { class: "explanation" }
const _hoisted_36 = { class: "support" }
const _hoisted_37 = { class: "how-it-works" }
const _hoisted_38 = { class: "hiw-title" }
const _hoisted_39 = {
  key: 0,
  class: "number-explanation-wrapper"
}
const _hoisted_40 = { class: "explanation" }
const _hoisted_41 = { class: "number-explanation-wrapper step-with-input" }
const _hoisted_42 = { class: "number" }
const _hoisted_43 = { class: "explanation" }
const _hoisted_44 = { class: "number-explanation-wrapper" }
const _hoisted_45 = { class: "number" }
const _hoisted_46 = { class: "explanation" }
const _hoisted_47 = { class: "number-explanation-wrapper" }
const _hoisted_48 = { class: "number" }
const _hoisted_49 = { class: "explanation" }
const _hoisted_50 = ["href"]
const _hoisted_51 = {
  for: "rebuy-key-copy",
  class: "key-input-label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_chip_group = _resolveComponent("v-chip-group")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_ScriptTag = _resolveComponent("ScriptTag")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_CodeSnippet = _resolveComponent("CodeSnippet")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.dialog,
    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.dialog) = $event)),
    width: "700",
    fullscreen: _ctx.isMobile,
    scrollable: ""
  }, {
    activator: _withCtx(({ props }) => [
      _createVNode(_component_v_btn, _mergeProps({
        class: "button",
        color: "#3B755F",
        height: "auto",
        variant: "text"
      }, props), {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.useButtonText), 1)
        ]),
        _: 2
      }, 1040)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.shopifySteps)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.t('compatible_platforms')), 1),
                  _createElementVNode("div", {
                    class: "close-wrapper",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dialog = false))
                  }, [
                    _createElementVNode("img", {
                      src: require('@/assets/icons/close_icon.svg'),
                      alt: "close",
                      class: "close-icon",
                      style: {"width":"25px"}
                    }, null, 8, _hoisted_6)
                  ])
                ]),
                _createVNode(_component_v_chip_group, {
                  "selected-class": "active-filter",
                  "model-value": _ctx.activeFilter,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = 
              (v) => {
                if (!v) return
                _ctx.activeFilter = v
              }
            ),
                  class: "platform-filter-group"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_chip, {
                      value: "shopify",
                      class: "platform-filter",
                      rounded: "lg"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('shopify')), 1)
                      ]),
                      _: 1
                    }),
                    (_ctx.isShopifyScriptTagCompatible)
                      ? (_openBlock(), _createBlock(_component_v_chip, {
                          key: 0,
                          value: "shopify-script-tag",
                          class: "platform-filter",
                          rounded: "lg"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t('shopify_script_tag')), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_v_chip, {
                      value: "widget-api",
                      class: "platform-filter",
                      rounded: "lg"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('widget_api')), 1)
                      ]),
                      _: 1
                    }),
                    (_ctx.isRebuyCompatible)
                      ? (_openBlock(), _createBlock(_component_v_chip, {
                          key: 1,
                          value: "rebuy",
                          class: "platform-filter",
                          rounded: "lg"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t('rebuy_smart_cart')), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.isCodeSnippetCompatible)
                      ? (_openBlock(), _createBlock(_component_v_chip, {
                          key: 2,
                          class: "platform-filter",
                          rounded: "lg",
                          value: "code-snippet"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t('code_snippet')), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["model-value"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.activeFilter !== 'code-snippet')
            ? _renderSlot(_ctx.$slots, "default", { key: 1 }, undefined, true)
            : _createCommentVNode("", true),
          (_ctx.activeFilter === 'code-snippet')
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("img", {
                  src: _ctx.url,
                  alt: "Greenspark - Impact-as-a-Service",
                  loading: "lazy"
                }, null, 8, _hoisted_8)
              ]))
            : _createCommentVNode("", true),
          (_ctx.shopifySteps && _ctx.activeFilter === 'shopify')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.overlayText), 1),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.t('how_it_works')), 1),
                  _createElementVNode("div", _hoisted_12, [
                    _cache[8] || (_cache[8] = _createElementVNode("div", { class: "number" }, [
                      _createElementVNode("span", null, "1")
                    ], -1)),
                    _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.t('shopify_tutorial.step_1')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    _cache[9] || (_cache[9] = _createElementVNode("div", { class: "number" }, [
                      _createElementVNode("span", null, "2")
                    ], -1)),
                    _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.t('shopify_tutorial.step_2')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_16, [
                    _cache[10] || (_cache[10] = _createElementVNode("div", { class: "number" }, [
                      _createElementVNode("span", null, "3")
                    ], -1)),
                    _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.t('shopify_tutorial.step_3')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_18, [
                    _cache[11] || (_cache[11] = _createElementVNode("div", { class: "number" }, [
                      _createElementVNode("span", null, "4")
                    ], -1)),
                    _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.t('shopify_tutorial.step_4')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_20, [
                    _cache[12] || (_cache[12] = _createElementVNode("div", { class: "number" }, [
                      _createElementVNode("span", null, "5")
                    ], -1)),
                    _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.t('shopify_tutorial.step_5')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_22, [
                    _cache[13] || (_cache[13] = _createElementVNode("div", { class: "number" }, [
                      _createElementVNode("span", null, "6")
                    ], -1)),
                    _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.t('shopify_tutorial.step_6')), 1)
                  ])
                ]),
                _createVNode(_component_v_select, {
                  modelValue: _ctx.shopify,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.shopify) = $event)),
                  items: _ctx.displayUrls,
                  label: "Shop Name",
                  variant: "outlined",
                  color: "#3B755F",
                  "item-color": "#3B755F",
                  class: "dropdown dropdown-block",
                  style: {"height":"46px"},
                  "hide-details": ""
                }, null, 8, ["modelValue", "items"]),
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.t('questions')), 1),
                  _createVNode(_component_i18n_t, {
                    tag: "span",
                    keypath: "Badge.help"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("a", _hoisted_25, _toDisplayString(_ctx.t('help_read')), 1),
                      _createElementVNode("button", {
                        class: "green-text",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openChat()))
                      }, _toDisplayString(_ctx.t('help_chat')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _createVNode(_component_gs_button, {
                  "full-width": "",
                  size: "large",
                  type: "primary",
                  target: "_blank",
                  disabled: !_ctx.buttonActive,
                  href: _ctx.shopifyUrl
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('proceed_to_shopify')), 1)
                  ]),
                  _: 1
                }, 8, ["disabled", "href"])
              ], 64))
            : _createCommentVNode("", true),
          (_ctx.shopifySteps && _ctx.activeFilter === 'shopify-script-tag')
            ? (_openBlock(), _createBlock(_component_ScriptTag, {
                key: 4,
                color: _ctx.color,
                style: _normalizeStyle(_ctx.style),
                type: _ctx.type as WidgetEditorSettingsType
              }, null, 8, ["color", "style", "type"]))
            : _createCommentVNode("", true),
          (!_ctx.shopifySteps || _ctx.activeFilter === 'widget-api')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
                _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.overlayText), 1),
                _createElementVNode("div", _hoisted_27, [
                  _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.t('how_it_works')), 1),
                  _createElementVNode("div", _hoisted_29, [
                    _cache[14] || (_cache[14] = _createElementVNode("div", { class: "number" }, [
                      _createElementVNode("span", null, "1")
                    ], -1)),
                    _createElementVNode("div", _hoisted_30, [
                      _createVNode(_component_i18n_t, {
                        tag: "span",
                        keypath: "Badge.api_tutorial.step_1"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_router_link, { to: "/account?tab=apiKey" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t('account_link')), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_31, [
                    _cache[15] || (_cache[15] = _createElementVNode("div", { class: "number" }, [
                      _createElementVNode("span", null, "2")
                    ], -1)),
                    _createElementVNode("div", _hoisted_32, [
                      _createVNode(_component_i18n_t, {
                        tag: "span",
                        keypath: "Badge.api_tutorial.step_2"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("a", {
                            href: _ctx.API_DOCUMENTATION_URL,
                            target: "_blank"
                          }, _toDisplayString(_ctx.t('documentation_link')), 9, _hoisted_33)
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_34, [
                    _cache[16] || (_cache[16] = _createElementVNode("div", { class: "number" }, [
                      _createElementVNode("span", null, "3")
                    ], -1)),
                    _createElementVNode("div", _hoisted_35, _toDisplayString(_ctx.t('api_tutorial.step_3')), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_36, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.t('help_more')), 1),
                  _createElementVNode("button", {
                    class: "green-text",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.openChat()))
                  }, _toDisplayString(_ctx.t('help_chat')), 1)
                ])
              ], 64))
            : _createCommentVNode("", true),
          (_ctx.shopifySteps && _ctx.activeFilter === 'rebuy')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 6 }, [
                _createElementVNode("div", _hoisted_37, [
                  _createElementVNode("div", _hoisted_38, _toDisplayString(_ctx.t('rebuy_tutorial.title')), 1),
                  (!_ctx.widgetApiKey)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
                        _cache[18] || (_cache[18] = _createElementVNode("div", { class: "number" }, [
                          _createElementVNode("span", null, "1")
                        ], -1)),
                        _createElementVNode("div", _hoisted_40, [
                          _createTextVNode(_toDisplayString(_ctx.t('rebuy_tutorial.step_1')) + " ", 1),
                          _createVNode(_component_router_link, { to: { name: 'Account', query: { tab: 'apiKey' } } }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t('rebuy_tutorial.click_here')) + " ", 1),
                              _createVNode(_component_v_icon, {
                                color: "#0017B4",
                                size: "20px"
                              }, {
                                default: _withCtx(() => _cache[17] || (_cache[17] = [
                                  _createTextVNode("mdi-open-in-new")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_41, [
                    _createElementVNode("div", _hoisted_42, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.widgetApiKey ? '1' : '2'), 1)
                    ]),
                    _createElementVNode("div", _hoisted_43, _toDisplayString(_ctx.t('rebuy_tutorial.step_2')), 1)
                  ]),
                  _createVNode(_component_v_select, {
                    modelValue: _ctx.shopify,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.shopify) = $event)),
                    items: _ctx.displayUrls,
                    density: "compact",
                    variant: "outlined",
                    color: "#3B755F",
                    "item-color": "#3B755F",
                    class: "dropdown dropdown-block",
                    "hide-details": ""
                  }, null, 8, ["modelValue", "items"]),
                  _createElementVNode("div", _hoisted_44, [
                    _createElementVNode("div", _hoisted_45, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.widgetApiKey ? '2' : '3'), 1)
                    ]),
                    _createElementVNode("div", _hoisted_46, _toDisplayString(_ctx.t('rebuy_tutorial.step_3')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_47, [
                    _createElementVNode("div", _hoisted_48, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.widgetApiKey ? '3' : '4'), 1)
                    ]),
                    _createElementVNode("div", _hoisted_49, [
                      _createTextVNode(_toDisplayString(_ctx.t('rebuy_tutorial.step_4')) + " ", 1),
                      _createElementVNode("a", {
                        href: _ctx.rebuyInstallationGuideUrl,
                        target: "_blank"
                      }, [
                        _createTextVNode(_toDisplayString(_ctx.t('rebuy_tutorial.installation_guide')) + " ", 1),
                        _createVNode(_component_v_icon, {
                          color: "#0017B4",
                          size: "20px"
                        }, {
                          default: _withCtx(() => _cache[19] || (_cache[19] = [
                            _createTextVNode("mdi-open-in-new")
                          ])),
                          _: 1
                        })
                      ], 8, _hoisted_50)
                    ])
                  ]),
                  _createElementVNode("label", _hoisted_51, _toDisplayString(_ctx.t('rebuy_tutorial.key_input_label')), 1),
                  _createVNode(_component_v_text_field, {
                    id: "rebuy-key-copy",
                    class: "rebuy-key-input",
                    "model-value": _ctx.rebuySmartCartAppKey,
                    variant: "outlined",
                    "hide-details": "",
                    placeholder: _ctx.t('rebuy_tutorial.key_input_placeholder'),
                    readonly: ""
                  }, {
                    "append-inner": _withCtx(() => [
                      _createVNode(_component_v_tooltip, {
                        "open-on-hover": false,
                        "open-on-click": true,
                        location: "top",
                        "max-width": "480px",
                        "close-delay": "5000",
                        text: 
                    _ctx.rebuySmartCartAppKey
                      ? _ctx.t('copy_action_success_feedback')
                      : _ctx.t('copy_action_error_feedback')
                  
                      }, {
                        activator: _withCtx(({ props }) => [
                          _createVNode(_component_v_icon, _mergeProps({
                            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.copyText(_ctx.rebuySmartCartAppKey)))
                          }, props), {
                            default: _withCtx(() => _cache[20] || (_cache[20] = [
                              _createTextVNode(" mdi-content-copy ")
                            ])),
                            _: 2
                          }, 1040)
                        ]),
                        _: 1
                      }, 8, ["text"])
                    ]),
                    _: 1
                  }, 8, ["model-value", "placeholder"])
                ]),
                _createVNode(_component_gs_button, {
                  "full-width": "",
                  size: "large",
                  type: "primary",
                  target: "_blank",
                  disabled: !_ctx.buttonActive,
                  href: _ctx.rebuyUrl
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('go_to_rebuy')), 1)
                  ]),
                  _: 1
                }, 8, ["disabled", "href"])
              ], 64))
            : _createCommentVNode("", true),
          (_ctx.activeFilter === 'code-snippet')
            ? (_openBlock(), _createBlock(_component_CodeSnippet, {
                key: 7,
                url: _ctx.url
              }, null, 8, ["url"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 3
  }, 8, ["modelValue", "fullscreen"]))
}