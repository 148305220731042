import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "description" }
const _hoisted_2 = {
  href: "https://help.getgreenspark.com/en/article/how-do-i-add-a-custom-domain-np3n2h/",
  target: "_blank"
}
const _hoisted_3 = { class: "accordion" }
const _hoisted_4 = { class: "header" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = {
  key: 0,
  class: "domain-list"
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "title-wrapper" }
const _hoisted_9 = { class: "domain-name mb-0" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "label-wrapper" }
const _hoisted_12 = { class: "label" }
const _hoisted_13 = { class: "hint" }
const _hoisted_14 = {
  key: 0,
  class: "dns-setting-wrapper"
}
const _hoisted_15 = { class: "label" }
const _hoisted_16 = { class: "hint" }
const _hoisted_17 = { class: "text-body-1 text-no-wrap" }
const _hoisted_18 = { class: "text-body-1 text-no-wrap text-uppercase" }
const _hoisted_19 = { class: "text-body-1 text-no-wrap" }
const _hoisted_20 = { class: "text-body-1 text-no-wrap" }
const _hoisted_21 = { class: "label" }
const _hoisted_22 = { class: "hint" }
const _hoisted_23 = { class: "button-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_copy_button = _resolveComponent("copy-button")!
  const _component_v_table = _resolveComponent("v-table")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_DeleteDomainDialog = _resolveComponent("DeleteDomainDialog")!
  const _component_CallToUpgradePopup = _resolveComponent("CallToUpgradePopup")!
  const _component_panel = _resolveComponent("panel")!

  return (_openBlock(), _createBlock(_component_panel, {
    background: "secondary",
    class: "custom-domain-dialog"
  }, {
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, [
        _createTextVNode(_toDisplayString(_ctx.t('custom_domain_description')) + " ", 1),
        _createElementVNode("a", _hoisted_2, _toDisplayString(_ctx.t('custom_domain_docs')), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createTextVNode(_toDisplayString(_ctx.t('email_domains')) + " ", 1),
          _createElementVNode("button", {
            class: "add-button",
            disabled: _ctx.isNewDomainOpen,
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.openAddNewPanel && _ctx.openAddNewPanel(...args)), ["prevent"]))
          }, " New + ", 8, _hoisted_5)
        ]),
        (_ctx.localDomainList.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localDomainList, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "domain-list-item"
                }, [
                  _createElementVNode("div", {
                    class: "list-item-header",
                    onClick: _withModifiers(($event: any) => (_ctx.openEditPanel(index)), ["prevent","stop"])
                  }, [
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_v_icon, {
                        class: _normalizeClass([
                  'domain-status-icon',
                  { verified: item.validationStatus?.valid },
                  { new: !item.id },
                ])
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.validationStatus?.valid ? 'mdi-play-circle' : 'mdi-stop-circle'), 1)
                        ]),
                        _: 2
                      }, 1032, ["class"]),
                      _createElementVNode("p", _hoisted_9, _toDisplayString(item.id ? item.domain : _ctx.t('new_domain')), 1)
                    ]),
                    (item.id)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          class: _normalizeClass(['delete-button', { 'is-active': _ctx.openedDrawerIndex === index }]),
                          onClick: _withModifiers(($event: any) => (_ctx.openDeleteModal(item.id, item.domain)), ["prevent","stop"])
                        }, [
                          _createVNode(_component_v_icon, null, {
                            default: _withCtx(() => _cache[5] || (_cache[5] = [
                              _createTextVNode("mdi-delete-outline")
                            ])),
                            _: 1
                          })
                        ], 10, _hoisted_10))
                      : _createCommentVNode("", true)
                  ], 8, _hoisted_7),
                  _createElementVNode("div", {
                    class: _normalizeClass([
              'input-wrapper',
              { 'is-open': _ctx.localDomainList.length === 1 || _ctx.openedIndex === index },
            ])
                  }, [
                    _createVNode(_component_v_form, {
                      ref_for: true,
                      ref: "domainForm",
                      modelValue: _ctx.isFormValid,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isFormValid) = $event))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_11, [
                          _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.t('from_domain')), 1),
                          _createVNode(_component_v_tooltip, {
                            "open-on-click": _ctx.isTouch,
                            color: "black",
                            location: "top",
                            "max-width": "240px"
                          }, {
                            activator: _withCtx(({ props }) => [
                              _createVNode(_component_v_icon, _mergeProps({
                                class: "tooltip-icon",
                                ref_for: true
                              }, props), {
                                default: _withCtx(() => _cache[6] || (_cache[6] = [
                                  _createTextVNode(" mdi-information-outline ")
                                ])),
                                _: 2
                              }, 1040)
                            ]),
                            default: _withCtx(() => [
                              _createElementVNode("span", null, _toDisplayString(_ctx.t('domain_tooltip')), 1)
                            ]),
                            _: 1
                          }, 8, ["open-on-click"])
                        ]),
                        _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.t('domain_hint')), 1),
                        _createVNode(_component_v_text_field, {
                          id: item.id,
                          modelValue: item.domain,
                          "onUpdate:modelValue": ($event: any) => ((item.domain) = $event),
                          placeholder: _ctx.t('new_domain_hint'),
                          class: "input-field",
                          type: "text",
                          rules: [_ctx.rules.required, _ctx.rules.domain, _ctx.rules.maxlength(100)],
                          variant: "outlined",
                          flat: "",
                          color: "green",
                          "hide-details": "",
                          autofocus: "",
                          readonly: _ctx.isReadOnly,
                          disabled: _ctx.isReadOnly
                        }, null, 8, ["id", "modelValue", "onUpdate:modelValue", "placeholder", "rules", "readonly", "disabled"]),
                        (item.id)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                              _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.t('dns_setting')), 1),
                              _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.t('dns_hint')), 1),
                              _createVNode(_component_v_table, {
                                class: "table mb-5",
                                "fixed-header": ""
                              }, {
                                default: _withCtx(() => [
                                  _cache[7] || (_cache[7] = _createElementVNode("thead", null, [
                                    _createElementVNode("tr", null, [
                                      _createElementVNode("th", { class: "text-left" }, "Status"),
                                      _createElementVNode("th", { class: "text-left" }, "Type"),
                                      _createElementVNode("th", { class: "text-left" }, "Host"),
                                      _createElementVNode("th", { class: "text-left" }, "Value")
                                    ])
                                  ], -1)),
                                  _createElementVNode("tbody", null, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(item.dns).filter(
                        (item) => item === 'dkim1' || item === 'dkim2' || item === 'mailCname',
                      ), (key, index) => {
                                      return (_openBlock(), _createElementBlock("tr", { key: index }, [
                                        _createElementVNode("td", _hoisted_17, [
                                          _createVNode(_component_v_icon, {
                                            class: _normalizeClass([
                            'status-icon',
                            { active: item.validationStatus?.dns[key].valid },
                          ])
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(item.validationStatus?.dns[key].valid
                              ? 'mdi-check-circle-outline'
                              : 'mdi-stop-circle-outline'), 1)
                                            ]),
                                            _: 2
                                          }, 1032, ["class"])
                                        ]),
                                        _createElementVNode("td", _hoisted_18, _toDisplayString(item.dns[key].type), 1),
                                        _createElementVNode("td", _hoisted_19, [
                                          _createTextVNode(_toDisplayString(item.dns[key].host) + " ", 1),
                                          _createVNode(_component_copy_button, {
                                            text: item.dns[key].host
                                          }, null, 8, ["text"])
                                        ]),
                                        _createElementVNode("td", _hoisted_20, [
                                          _createTextVNode(_toDisplayString(item.dns[key].data) + " ", 1),
                                          _createVNode(_component_copy_button, {
                                            text: item.dns[key].data
                                          }, null, 8, ["text"])
                                        ])
                                      ]))
                                    }), 128))
                                  ])
                                ]),
                                _: 2
                              }, 1024),
                              _createElementVNode("p", _hoisted_21, _toDisplayString(_ctx.t('verify')), 1),
                              _createElementVNode("p", _hoisted_22, _toDisplayString(_ctx.t('verify_hint')), 1),
                              _createVNode(_component_v_checkbox, {
                                rules: [_ctx.rules.required],
                                color: "green",
                                class: "checkbox",
                                "hide-details": "",
                                label: 
                    item.validationStatus?.valid ? _ctx.t('verify_again_label') : _ctx.t('verify_label')
                  
                              }, null, 8, ["rules", "label"])
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_23, [
                          _createVNode(_component_gs_button, {
                            type: "info",
                            size: "large",
                            onClick: _withModifiers(($event: any) => (_ctx.cancel(index)), ["prevent"])
                          }, {
                            default: _withCtx(() => _cache[8] || (_cache[8] = [
                              _createTextVNode(" cancel ")
                            ])),
                            _: 2
                          }, 1032, ["onClick"]),
                          _createVNode(_component_gs_button, {
                            size: "large",
                            disabled: !_ctx.isFormValid,
                            loading: _ctx.loading,
                            onClick: _withModifiers(($event: any) => (_ctx.verifyDomain(index)), ["prevent"])
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.buttonText(index)), 1)
                            ]),
                            _: 2
                          }, 1032, ["disabled", "loading", "onClick"])
                        ])
                      ]),
                      _: 2
                    }, 1032, ["modelValue"])
                  ], 2)
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.isDeleteDialogOpen)
        ? (_openBlock(), _createBlock(_component_DeleteDomainDialog, {
            key: 0,
            ref: "deleteDomainDialog",
            "delete-type": "domain",
            "selected-domain": _ctx.selectedDomain,
            onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isDeleteDialogOpen = false)),
            onDeleted: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleDelete()))
          }, null, 8, ["selected-domain"]))
        : _createCommentVNode("", true),
      (_ctx.isCallToUpgradePopupOpen)
        ? (_openBlock(), _createBlock(_component_CallToUpgradePopup, {
            key: 1,
            "popup-type": "domain",
            "is-premium-only": false,
            onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isCallToUpgradePopupOpen = false))
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}