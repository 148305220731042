import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "text-wrapper" }
const _hoisted_2 = { class: "title-wrapper" }
const _hoisted_3 = { class: "checkmark" }
const _hoisted_4 = { class: "setup-title mb-0" }
const _hoisted_5 = { class: "description" }
const _hoisted_6 = {
  key: 0,
  class: "input-wrapper"
}
const _hoisted_7 = { class: "button-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createElementBlock("div", {
    type: "button",
    class: _normalizeClass(['setup-url', { 'is-active': _ctx.isActive }])
  }, [
    _createElementVNode("div", {
      class: "setup-header",
      role: "button",
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.isCardOpen = !_ctx.isCardOpen), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_v_icon, { class: "checkmark-icon" }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode(" mdi-check ")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.t('title')), 1)
        ]),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.t('description')), 1)
      ]),
      _cache[5] || (_cache[5] = _createElementVNode("p", { class: "set-button" }, "Set +", -1))
    ]),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.isCardOpen)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_v_form, {
                modelValue: _ctx.isUrlValid,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isUrlValid) = $event))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.url,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.url) = $event)),
                    label: _ctx.t('input_label'),
                    placeholder: _ctx.t('input_placeholder'),
                    variant: "outlined",
                    flat: "",
                    type: "text",
                    color: "#3B755F",
                    class: "input",
                    prefix: "https://",
                    rules: [_ctx.rules.url]
                  }, null, 8, ["modelValue", "label", "placeholder", "rules"]),
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_gs_button, {
                      class: "save-button",
                      type: "primary",
                      disabled: !_ctx.isUrlValid,
                      loading: _ctx.loading,
                      onClick: _ctx.saveUrl
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('CommonUi.save')), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled", "loading", "onClick"]),
                    _createVNode(_component_v_btn, {
                      type: "info",
                      class: "cancel-button",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isCardOpen = false))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('CommonUi.cancel')), 1)
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 2))
}