import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "impact" }
const _hoisted_3 = {
  key: 0,
  class: "plus"
}
const _hoisted_4 = { class: "button-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TotalFunding = _resolveComponent("TotalFunding")!
  const _component_gs_button = _resolveComponent("gs-button")!

  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_TotalFunding, {
            trees: _ctx.trees,
            plastic: _ctx.plastic,
            carbon: _ctx.carbon
          }, null, 8, ["trees", "plastic", "carbon"]),
          (_ctx.impacts && _ctx.impacts.length > 0 && _ctx.oneTimeImpacts && _ctx.oneTimeImpacts.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, " + "))
            : _createCommentVNode("", true),
          _createVNode(_component_TotalFunding, {
            trees: _ctx.treesOneTime,
            plastic: _ctx.plasticOneTime,
            carbon: _ctx.carbonOneTime,
            "funding-type": _ctx.fundingType
          }, null, 8, ["trees", "plastic", "carbon", "funding-type"]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_gs_button, {
              "full-width": true,
              size: "large",
              onClick: _withModifiers(_ctx.goHome, ["prevent"])
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('continue')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}