<template>
  <div class="user-component">
    <div class="user-component-wrapper">
      <template v-if="$route.name === 'DirectMarketplaceSignup'">
        <CarouselCard class="carousel-card" />
      </template>
      <img v-else class="user-image" :src="require('@/assets/user-info-shape.jpg')" alt="signup" />
      <div class="user-wrapper">
        <div
          class="back"
          @click="accountType === 'ecommerce' ? $emit('ecommerceBackClick') : $emit('backClick')"
        >
          <img
            class="chevron"
            alt="chevron"
            :src="require('@/assets/icons/chevron_left.svg')"
            @click="$emit('backClick')"
          />
          <div>
            {{ t('back') }}
          </div>
        </div>
        <div class="form-wrapper">
          <div class="header">
            {{ t('header') }}
          </div>

          <div class="name-wrapper">
            <div class="text-field-wrapper">
              <v-text-field
                v-model="firstName"
                :label="t('first_name')"
                variant="outlined"
                type="text"
                flat
                color="#3B755F"
                class="left-input"
                :rules="[rules.required]"
              />

              <v-text-field
                v-model="lastName"
                :label="t('last_name')"
                variant="outlined"
                type="text"
                flat
                color="#3B755F"
                class="right-input"
                :rules="[rules.required]"
              />
            </div>
          </div>

          <div
            v-if="accountType === 'business' || accountType === 'ecommerce'"
            class="name-wrapper"
          >
            <div class="text-field-wrapper">
              <v-text-field
                v-model="companyName"
                :label="t('company_name')"
                variant="outlined"
                type="text"
                flat
                color="#3B755F"
                class="left-input"
                :rules="[rules.required]"
              />

              <v-text-field
                v-model="vatId"
                :rules="isEUCountry(country) ? [rules.required] : []"
                :label="isEUCountry(country) ? t('vat_id_required') : t('vat_id')"
                variant="outlined"
                type="text"
                flat
                color="#3B755F"
                class="right-input"
              />
            </div>
          </div>

          <div class="name-wrapper">
            <div class="text-field-wrapper">
              <v-select
                v-model="country"
                :items="countriesTemp"
                :label="t('country')"
                variant="outlined"
                color="#3B755F"
                item-color="#3B755F"
                item-title="name"
                item-value="alpha2"
                :rules="[rules.required]"
              />
            </div>
          </div>

          <div class="name-wrapper">
            <div class="text-field-wrapper">
              <v-text-field
                v-model="street"
                :label="t('street')"
                variant="outlined"
                type="text"
                flat
                color="#3B755F"
                :rules="[rules.required]"
              />
            </div>
          </div>

          <div class="name-wrapper">
            <div class="text-field-wrapper">
              <v-text-field
                v-model="postCode"
                :label="t('postal_code')"
                variant="outlined"
                type="text"
                flat
                color="#3B755F"
                class="left-input"
                :rules="[rules.required]"
              />

              <v-text-field
                v-model="city"
                :label="t('city')"
                variant="outlined"
                type="text"
                flat
                color="#3B755F"
                class="right-input"
                :rules="[rules.required]"
              />
            </div>
          </div>

          <div class="button-wrapper">
            <gs-button
              :disabled="!buttonActive"
              :full-width="true"
              size="large"
              @click.prevent="onNextClicked"
            >
              {{ t('continue') }}
            </gs-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import CarouselCard from '@/components/register/CarouselCard.vue'
import type { Account } from '@/store'
import { Utils } from '@/helpers/mixins/utilsMixin'
import { RulesMixin } from '@/helpers/mixins/RulesMixin.vue'
import type { User } from '@/store/users'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'UserInfo',
  emits: ['ecommerceBackClick', 'backClick', 'nextClick'],
  components: { CarouselCard },
  mixins: [Utils, RulesMixin],
  data() {
    return {
      companyName: '',
      firstName: '',
      lastName: '',
      vatId: '',
      countriesTemp: [],
      country: undefined,
      street: '',
      postCode: '',
      city: '',
      hideBack: false,
      accountType: '',
      buttonActive: false,
    } as {
      companyName: string
      firstName: string
      lastName: string
      vatId: string
      countriesTemp: unknown[]
      country: string | undefined
      street: string
      postCode: string
      city: string
      hideBack: boolean
      accountType: string
      buttonActive: boolean
    }
  },
  created() {
    this.setUserType()
    this.countriesTemp = require(`@/assets/i18n/countries/${this.$i18n.locale}.json`)
    // this.setCountries()
    this.setSavedInfo()
    this.setButtonActive()
  },
  methods: {
    t(key: string) {
      return this.$t(`UserInfo.${key}`)
    },
    onNextClicked() {
      this.$emit('nextClick')
    },
    setUserType() {
      switch (this.$route.name) {
        case 'Signup':
          this.accountType = 'business'
          break
        case 'SignupEcommerce':
        case 'SignupInvite':
          this.accountType = 'ecommerce'
          break
        case 'SignupPersonal':
          this.accountType = 'personal'
          break
        case 'SignupFamily':
          this.accountType = 'family'
          break
        case 'DirectMarketplaceSignup':
          this.accountType = 'ecommerce'
          this.hideBack = false
          break
      }
    },
    setButtonActive() {
      switch (this.accountType) {
        case 'business':
        case 'ecommerce':
          if (this.isEUCountry(this.country)) {
            if (
              this.firstName !== '' &&
              this.lastName !== '' &&
              this.companyName !== '' &&
              this.country !== '' &&
              this.street !== '' &&
              this.postCode !== '' &&
              this.city !== '' &&
              this.vatId !== ''
            ) {
              this.buttonActive = true
            } else {
              this.buttonActive = false
            }
          } else {
            if (
              this.firstName !== '' &&
              this.lastName !== '' &&
              this.companyName !== '' &&
              this.country !== '' &&
              this.street !== '' &&
              this.postCode !== '' &&
              this.city !== ''
            ) {
              this.buttonActive = true
            } else {
              this.buttonActive = false
            }
          }
          break
        case 'personal':
        case 'family':
          if (
            this.firstName !== '' &&
            this.lastName !== '' &&
            this.country !== '' &&
            this.street !== '' &&
            this.postCode !== '' &&
            this.city !== ''
          ) {
            this.buttonActive = true
          } else {
            this.buttonActive = false
          }
          break
      }
    },
    setSavedInfo() {
      if (this.currentUser.firstName !== '' && this.currentUser.firstName !== ' ') {
        this.firstName = this.currentUser.firstName
      }

      if (this.currentUser.lastName !== '' && this.currentUser.lastName !== ' ') {
        this.lastName = this.currentUser.lastName
      }

      if (this.account.companyName !== '' && this.account.companyName !== ' ') {
        this.companyName = this.account.companyName
      }

      if (this.account.vatId !== '' && this.account.vatId !== ' ') {
        this.vatId = this.account.vatId
      }

      if (this.account.address.country !== '' && this.account.address.country !== ' ') {
        this.country = this.account.address.country.toLowerCase()
      }

      if (this.account.address.street !== '' && this.account.address.street !== ' ') {
        this.street = this.account.address.street
      }

      if (this.account.address.postCode !== '' && this.account.address.postCode !== ' ') {
        this.postCode = this.account.address.postCode
      }

      if (this.account.address.city !== '' && this.account.address.city !== ' ') {
        this.city = this.account.address.city
      }
    },
    onCompanyNameChange() {
      if (this.companyName === '') {
        this.setAccount({ companyName: ' ' })
        this.setButtonActive()
      } else {
        this.setAccount({ companyName: this.companyName })
        this.setButtonActive()
      }
    },
    onFirstNameChange() {
      if (this.firstName === '') {
        this.updateCurrentUser({ firstName: ' ' })
        this.setButtonActive()
      } else {
        this.updateCurrentUser({ firstName: this.firstName })
        this.setButtonActive()
      }
    },
    onLastNameChange() {
      if (this.lastName === '') {
        this.updateCurrentUser({ lastName: ' ' })
        this.setButtonActive()
      } else {
        this.updateCurrentUser({ lastName: this.lastName })
        this.setButtonActive()
      }
    },
    onVatIdChange() {
      if (this.vatId === '') {
        this.setAccount({ vatId: ' ' })
        this.setButtonActive()
      } else {
        this.setAccount({ vatId: this.vatId })
        this.setButtonActive()
      }
    },
    onCountryChange() {
      if (this.country === '') {
        const address = {
          street: this.street,
          postCode: this.postCode,
          city: this.city,
          country: ' ',
        }
        this.setAccount({ address: address })
        this.setButtonActive()
      } else {
        const address = {
          street: this.street,
          postCode: this.postCode,
          city: this.city,
          country: this.country?.toUpperCase() ?? '',
        }
        this.setAccount({ address: address })
        this.setButtonActive()
      }
    },
    onStreetChange() {
      if (this.street === '') {
        const address = {
          street: ' ',
          postCode: this.postCode,
          city: this.city,
          country: this.country ?? '',
        }
        this.setAccount({ address: address })
        this.setButtonActive()
      } else {
        const address = {
          street: this.street,
          postCode: this.postCode,
          city: this.city,
          country: this.country ?? '',
        }
        this.setAccount({ address: address })
        this.setButtonActive()
      }
    },
    onPostCodeChange() {
      if (this.postCode === '') {
        const address = {
          street: this.street,
          postCode: ' ',
          city: this.city,
          country: this.country ?? '',
        }
        this.setAccount({ address: address })
        this.setButtonActive()
      } else {
        const address = {
          street: this.street,
          postCode: this.postCode,
          city: this.city,
          country: this.country ?? '',
        }
        this.setAccount({ address: address })
        this.setButtonActive()
      }
    },
    onCityChange() {
      if (this.city === '') {
        const address = {
          street: this.street,
          postCode: this.postCode,
          city: ' ',
          country: this.country ?? '',
        }
        this.setAccount({ address: address })
        this.setButtonActive()
      } else {
        const address = {
          street: this.street,
          postCode: this.postCode,
          city: this.city,
          country: this.country ?? '',
        }
        this.setAccount({ address: address })
        this.setButtonActive()
      }
    },
    updateCurrentUser(user: Partial<User>): Promise<void> {
      return this.$store.dispatch('updateCurrentUser', user)
    },
    setAccount(account: Partial<Account>): void {
      return this.$store.commit('setAccount', account)
    },
  },
  watch: {
    companyName: [
      {
        handler: 'onCompanyNameChange',
      },
    ],
    firstName: [
      {
        handler: 'onFirstNameChange',
      },
    ],
    lastName: [
      {
        handler: 'onLastNameChange',
      },
    ],
    vatId: [
      {
        handler: 'onVatIdChange',
      },
    ],
    country: [
      {
        handler: 'onCountryChange',
      },
    ],
    street: [
      {
        handler: 'onStreetChange',
      },
    ],
    postCode: [
      {
        handler: 'onPostCodeChange',
      },
    ],
    city: [
      {
        handler: 'onCityChange',
      },
    ],
  },
  computed: {
    account(): Account {
      return this.$store.getters['getAccount']
    },
    currentUser(): User {
      return this.$store.getters['getCurrentUser']
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.carousel-card {
  border-radius: 8px 0 0 8px;
  flex: 1 1 50%;
  width: 50%;
}

.user-component {
  min-height: 100%;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: var(--ui-white);
  border-radius: 8px;
}

.user-component-wrapper {
  width: 100%;
  border-radius: 8px;
  display: flex;
  box-shadow: var(--box-shadow);
}

.user-image {
  width: 400px;
  max-width: 45%;
  max-height: 640px;
  object-fit: cover;
  border-radius: 8px 0 0 8px;
}

.user-wrapper {
  width: 50%;
  flex: 1 1 50%;
}

.chevron {
  width: 23px;
  filter: invert(39%) sepia(15%) saturate(1175%) hue-rotate(106deg) brightness(96%) contrast(87%);
}

.back {
  padding-top: 20px;
  padding-left: 20px;
  display: flex;
  font-weight: 500;
  color: #3b755f;
  cursor: pointer;
}

.form-wrapper {
  padding: 36px;
}

.header {
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  color: #212121;
  margin-bottom: 24px;
}

.left-input {
  margin-right: 4px;
}

.right-input {
  margin-left: 4px;
}

.name-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.input-label {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #212121;
  margin-bottom: 4px;
}

.input-description {
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #b0b0b0;
  margin-bottom: 12px;
}

.text-field-wrapper {
  display: flex;
  width: 100%;
}

.employee-input {
  min-width: 90%;
  margin-bottom: 14px;
}

.button-wrapper {
  margin-top: 20px;
}

@media #{map-get($display-breakpoints, 'md')} {
  // .user-component-wrapper {
  //   margin-bottom: 20px;
  // }

  .user-image {
    display: none;
  }

  .carousel-card {
    display: none;
  }

  .employee-input {
    min-width: 95%;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  // .user-component-wrapper {
  //   margin-bottom: 20px;
  // }

  .user-image {
    display: none;
  }

  .carousel-card {
    display: none;
  }

  .text-field-wrapper {
    justify-content: space-between;
  }

  .employee-input {
    min-width: 80%;
    margin-right: 10px;
  }

  .form-wrapper {
    padding: 10px;
  }
}
</style>
