import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "tooltip" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "tooltip-content" }
const _hoisted_4 = {
  key: 0,
  class: "text"
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = {
  key: 1,
  class: "text"
}
const _hoisted_8 = {
  key: 2,
  class: "text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_menu, {
      "open-on-hover": !_ctx.isTouch,
      location: "top",
      offset: "5 100",
      "close-delay": "250",
      "content-class": "elevation-3"
    }, {
      activator: _withCtx(({ props }) => [
        _createElementVNode("img", _mergeProps({
          class: "icon",
          src: require('@/assets/icons/info.svg'),
          alt: "tooltip icon"
        }, props), null, 16, _hoisted_2)
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.relationsText || _ctx.impactActionsText)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_ctx.impactActionsText)
                  ? (_openBlock(), _createElementBlock("p", {
                      key: 0,
                      class: "mb-0",
                      innerHTML: _ctx.impactActionsText
                    }, null, 8, _hoisted_5))
                  : _createCommentVNode("", true),
                (_ctx.relationsText)
                  ? (_openBlock(), _createElementBlock("p", {
                      key: 1,
                      class: "mb-0",
                      innerHTML: _ctx.relationsText
                    }, null, 8, _hoisted_6))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (_ctx.partnerText)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.partnerText), 1))
            : _createCommentVNode("", true),
          (_ctx.transferredImpact && _ctx.transferredImpact > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _cache[0] || (_cache[0] = _createElementVNode("strong", null, "Transferred impact:", -1)),
                _createTextVNode(" " + _toDisplayString(_ctx.transferredText), 1)
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["open-on-hover"])
  ]))
}