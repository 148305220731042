import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { style: {"margin-top":"18px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_file_input = _resolveComponent("v-file-input")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_loading = _resolveComponent("loading")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _cache[3] || (_cache[3] = _createElementVNode("h1", null, "Upload Shopify Payout", -1)),
          _cache[4] || (_cache[4] = _createElementVNode("div", { style: {"margin-bottom":"18px"} }, " This functionality enables uploading Shopify payout files. ", -1)),
          _createVNode(_component_v_file_input, {
            modelValue: _ctx.file,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.file) = $event)),
            accept: "text/csv",
            label: "Upload Shopify payload csv",
            placeholder: "Upload Shopify payload csv",
            "prepend-icon": "mdi-file-delimited-outline"
          }, null, 8, ["modelValue"]),
          _createVNode(_component_gs_button, {
            disabled: _ctx.loading || !_ctx.file,
            "full-width": true,
            loading: _ctx.loading,
            size: "large",
            onClick: _withModifiers(_ctx.upload, ["prevent"])
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode(" Upload ")
            ])),
            _: 1
          }, 8, ["disabled", "loading", "onClick"]),
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_2, [
              _cache[2] || (_cache[2] = _createElementVNode("strong", null, "Latest payout date:", -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.latestPayoutDay), 1)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_loading, { key: 1 }))
      : _createCommentVNode("", true)
  ]))
}