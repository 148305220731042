<template>
  <v-dialog
    width="600"
    persistent
    :fullscreen="isMobile"
    :model-value="modelValue"
    @update:model-value="onUpdate"
  >
    <div class="pop-up">
      <div class="header">
        {{ t('shopify_header') }}
      </div>

      <div v-if="account.currency === 'dollar'" class="text">
        {{ t('shopify_text_dollar') }}
      </div>

      <!-- New Pricing -->
      <template v-else>
        <div class="text">
          {{ t('shopify_text') }}
        </div>
        <div style="width: 100%">
          <div class="text">
            {{
              isBusinessPlanAndYearly
                ? t('plan_yearly', { price: currencyDollar(dollarPricing.planPrice) })
                : t('plan_monthly', { price: currencyDollar(dollarPricing.planPrice) })
            }}
          </div>
          <hr />

          <p v-if="dollarPricing.standard" class="text">
            Standard Team Member: {{ currencyDollar(dollarPricing.standard) }}
          </p>
          <hr v-if="dollarPricing.standard" />

          <p v-if="dollarPricing.superior" class="text">
            Superior Team Member: {{ currencyDollar(dollarPricing.superior) }}
          </p>
          <hr v-if="dollarPricing.superior" />

          <p v-if="dollarPricing.premium" class="text">
            Premium Team Member: {{ currencyDollar(dollarPricing.premium) }}
          </p>
          <hr v-if="dollarPricing.premium" />

          <p v-if="dollarPricing.treePlanter" class="text">
            Tree Planters: {{ currencyDollar(dollarPricing.treePlanter) }}
          </p>
          <hr v-if="dollarPricing.treePlanter" />

          <p v-if="dollarPricing.plasticHero" class="text">
            Plastic Heros: {{ currencyDollar(dollarPricing.plasticHero) }}
          </p>
          <hr v-if="dollarPricing.plasticHero" />

          <p v-if="totalNewCost" class="text">
            <strong
              >Total in USD {{ isActiveSubscriptionOnTrial ? '(after trial period)' : '' }}:</strong
            >
            {{ currencyDollar(totalNewCost) }}
          </p>
        </div>
      </template>

      <gs-button class="mt-2" full-width size="large" @click="handleClickContinue">
        <div class="button-text">
          {{ t('continue') }}
        </div>
      </gs-button>
      <gs-button full-width type="info" size="large" @click="$emit('update:model-value', false)">
        {{ t('cancel') }}
      </gs-button>
    </div>
  </v-dialog>
</template>

<script lang="ts">
import {
  GREENSPARK_SHOPIFY_MARKETPLACE_URL,
  NEW_PAID_BUSINESS_SUBSCRIPTION_YEARLY_TYPES,
} from '@/helpers/constants'
import type { TechnicalAndBusinessSubscriptionType } from '@/helpers/pricing'
import { getPlanPrice } from '@/helpers/pricing'
import { Utils } from '@/helpers/mixins/utilsMixin'
import type { SubscriptionItem } from '@/store/subscriptions'
import type { Account } from '@/store'
import type { OffsetType } from '@/helpers/interfaces'
import { OFFSET_TYPES } from '@/helpers/interfaces'
import { defineComponent } from 'vue'
import { includes } from '@/helpers/parsers'

export default defineComponent({
  name: 'ShopifyIntegrationAlert',
  emits: ['update:model-value'],
  mixins: [Utils],
  computed: {
    isBusinessPlanAndYearly(): boolean {
      return includes(
        NEW_PAID_BUSINESS_SUBSCRIPTION_YEARLY_TYPES,
        this.getActivePaidSubscriptionItem.product,
      )
    },
    dollarPricing(): Record<string, number> {
      const impactTypeDollarPrices = OFFSET_TYPES.reduce((acc, type) => {
        acc[type] = this.getProjectDollarPriceByType(type) || 0
        return acc
      }, {} as Record<OffsetType, number>)
      return {
        planPrice:
          getPlanPrice(
            this.getActivePaidSubscriptionItem.product as TechnicalAndBusinessSubscriptionType,
            'dollar',
          ) || 0,
        standard: 6.25 * (this.account.employeePlans?.standard || 0),
        superior: 12.5 * (this.account.employeePlans?.superior || 0),
        premium: 24.0 * (this.account.employeePlans?.premium || 0),
        plasticHero: 3.95 * (this.account.employeePlanAddons?.plasticHero || 0),
        treePlanter:
          this.getProjectDollarPriceByType('trees') *
          (this.account.employeePlanAddons?.treePlanter || 0),
        ...impactTypeDollarPrices,
      }
    },
    totalNewCost(): number {
      return (
        this.dollarPricing.planPrice * (this.isBusinessPlanAndYearly ? 12 : 1) +
        this.dollarPricing.standard +
        this.dollarPricing.superior +
        this.dollarPricing.premium +
        this.dollarPricing.treePlanter +
        this.dollarPricing.plasticHero
      )
    },
    getActivePaidSubscriptionItem(): SubscriptionItem {
      return this.$store.getters['getActivePaidSubscriptionItem']
    },
    account(): Account {
      return this.$store.getters['getAccount']
    },
    activeSubscription(): SubscriptionItem {
      return this.$store.getters['getActiveSubscriptionItem']
    },
    isActiveSubscriptionOnTrial(): boolean {
      return this.$store.getters['isActiveSubscriptionOnTrial']
    },
    getProjectDollarPriceByType(): (type: OffsetType) => number {
      return this.$store.getters['getProjectDollarPriceByType']
    },
  },
  created() {
    window.addEventListener('resize', this.reportWindowSize)
    this.reportWindowSize()
  },
  unmounted() {
    window.removeEventListener('resize', this.reportWindowSize)
  },
  methods: {
    onUpdate(e: boolean) {
      this.$emit('update:model-value', e)
    },
    handleClickContinue() {
      this.$emit('update:model-value', false)
      window.open(GREENSPARK_SHOPIFY_MARKETPLACE_URL, '_self')
    },
    t(key: string, params?: { [k: string]: string | number }) {
      return this.$t(`ShopifyIntegrationAlert.${key}`, params ?? {})
    },
  },
  props: {
    modelValue: {
      type: Boolean,
    },
  },
})
</script>

<style lang="scss" scoped>
.text {
  font-size: 16px;
  text-align: left;
  margin-bottom: 25px;
  width: 100%;
}

.pop-up {
  background: white;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow-y: scroll;
}

.header {
  font-weight: 500;
  font-size: 20px;
  text-align: left;
  width: 100%;
}
</style>
