<template>
  <svg fill="none" height="17" viewBox="0 0 16 17" width="16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.1848 6.68341C9.53437 6.68341 10.6346 5.5911 10.6346 4.25341C10.6346 3.61622 10.3853 3.03444 9.97762 2.5991C10.1715 2.2627 10.3655 1.93026 10.5634 1.59385C10.5515 1.55823 10.4486 1.29307 10.1676 1.19809C9.98554 1.13872 9.83119 1.1783 9.77183 1.19809C9.62935 1.50283 9.48291 1.80361 9.34044 2.10835C8.99612 1.9263 8.60431 1.8234 8.18876 1.8234C7.7732 1.8234 7.38139 1.9263 7.03708 2.10835C6.8946 1.80361 6.74817 1.50283 6.60569 1.19809C6.54633 1.1783 6.39198 1.13872 6.20992 1.19809C5.92893 1.29307 5.82603 1.55823 5.81416 1.59385C6.00808 1.93026 6.20201 2.2627 6.39989 2.5991C5.99225 3.03444 5.74292 3.61226 5.74292 4.25341C5.74292 5.59506 6.84315 6.68341 8.19271 6.68341H8.1848Z"
      fill="white"
    />
    <path
      d="M4.94344 4.24926C4.94344 4.12261 4.95135 3.99992 4.96322 3.87723C2.86566 3.45376 1.02139 4.09491 0.708731 5.38115C0.396075 6.70301 1.85645 8.13964 4.03317 8.6581C4.10045 8.67393 4.16377 8.6858 4.23105 8.69767C4.24293 8.65414 4.2548 8.61061 4.27063 8.56707C4.5635 7.64098 5.03446 6.83361 5.63603 6.236C5.20068 5.68985 4.94344 4.99725 4.94344 4.24926Z"
      fill="white"
    />
    <path
      d="M8.18476 9.11385C7.02912 9.11385 5.90119 9.29195 4.82074 9.62835C4.76929 9.91726 4.73367 10.218 4.7218 10.5228C5.83786 10.1903 6.99746 10.0122 8.18476 10.0122C9.37206 10.0122 10.5396 10.1864 11.6477 10.507C11.6319 10.1983 11.5963 9.89747 11.5448 9.60856C10.128 9.18113 8.85361 9.10989 8.18476 9.10989V9.11385Z"
      fill="white"
    />
    <path
      d="M4.74163 11.3458C4.80495 11.8286 4.96326 12.2916 5.17697 12.7191C6.15056 12.5014 7.15581 12.3906 8.18084 12.3906C9.39585 12.3906 10.4525 12.5568 11.1887 12.7191C11.4024 12.2877 11.5607 11.8246 11.624 11.3418C10.5317 11.0014 9.32857 10.8075 8.18084 10.8075C7.03312 10.8075 5.84186 10.9935 4.73767 11.3458H4.74163Z"
      fill="white"
    />
    <path
      d="M5.60437 13.4391C6.53838 14.8084 7.88003 15.7187 7.9671 15.7741C8.10166 15.8651 8.26788 15.8612 8.40244 15.7741C8.48951 15.7187 9.83116 14.8084 10.7652 13.4391C10.0884 13.3045 9.19398 13.1818 8.18477 13.1818C7.30617 13.1818 6.4434 13.2729 5.60437 13.4391Z"
      fill="white"
    />
    <path
      d="M8.18471 7.47543C7.45255 7.47543 6.77974 7.23005 6.23358 6.82241C6.22171 6.8145 6.20984 6.80262 6.19401 6.79471C5.70326 7.28546 5.31145 7.95431 5.0542 8.73001C6.06736 8.46089 7.11614 8.32237 8.18867 8.32237C9.2612 8.32237 10.306 8.46484 11.3192 8.72605C11.0619 7.95035 10.6662 7.2815 10.1794 6.79471L10.1398 6.82241C9.59365 7.23005 8.92084 7.47543 8.18867 7.47543H8.18471Z"
      fill="white"
    />
    <path
      d="M15.6608 5.37723C15.3482 4.09494 13.5039 3.4538 11.4063 3.87727C11.4182 3.99996 11.4261 4.12265 11.4261 4.24929C11.4261 4.99729 11.1689 5.68592 10.7335 6.23208L10.7414 6.24C11.3351 6.83365 11.806 7.64101 12.1029 8.57106C12.1147 8.61064 12.1306 8.65418 12.1385 8.69771C12.2058 8.68584 12.2731 8.67001 12.3403 8.65417C14.5131 8.13968 15.9735 6.70304 15.6608 5.37723Z"
      fill="white"
    />
  </svg>
</template>
