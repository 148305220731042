import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "drawer" }
const _hoisted_2 = { style: {"padding":"0 15px"} }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "drawer-item__text" }
const _hoisted_5 = { class: "drawer-item__text" }
const _hoisted_6 = { class: "mobile-menu-wrapper" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "menu-item-text" }
const _hoisted_9 = { class: "menu-item-text" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "drawer-menu" }
const _hoisted_12 = { class: "segment-header" }
const _hoisted_13 = { class: "drawer-menu__list-item" }
const _hoisted_14 = ["href"]
const _hoisted_15 = { class: "drawer-menu__list-item" }
const _hoisted_16 = ["href"]
const _hoisted_17 = {
  key: 1,
  class: "drawer-menu__list-item"
}
const _hoisted_18 = { class: "beamer-trigger-button drawer-menu__link" }
const _hoisted_19 = { class: "notification-item-text" }
const _hoisted_20 = { class: "segment-header" }
const _hoisted_21 = { class: "drawer-menu__list-item" }
const _hoisted_22 = { class: "drawer-menu__list-item" }
const _hoisted_23 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OnboardingChecklistButton = _resolveComponent("OnboardingChecklistButton")!
  const _component_GreenSparkLogo = _resolveComponent("GreenSparkLogo")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("nav", _hoisted_1, [
      _createElementVNode("ul", null, [
        _createElementVNode("li", _hoisted_2, [
          (!_ctx.isChecklistDismissed && _ctx.isBusiness)
            ? (_openBlock(), _createBlock(_component_OnboardingChecklistButton, {
                key: 0,
                "is-mobile": true
              }))
            : _createCommentVNode("", true)
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.otherRoutes, (item, a) => {
          return (_openBlock(), _createElementBlock("li", { key: a }, [
            (item.visible && item.isButton)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "drawer-item",
                  onClick: item.function
                }, [
                  _createElementVNode("div", _hoisted_4, [
                    (item.iconName)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          (item.iconName === 'GreenSparkLogo')
                            ? (_openBlock(), _createBlock(_component_GreenSparkLogo, {
                                key: 0,
                                class: "icon svg"
                              }))
                            : (_openBlock(), _createBlock(_component_v_icon, {
                                key: 1,
                                class: "icon svg"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.iconName), 1)
                                ]),
                                _: 2
                              }, 1024))
                        ], 64))
                      : _createCommentVNode("", true),
                    _createElementVNode("span", null, _toDisplayString(_ctx.t(item.linkText)), 1)
                  ])
                ], 8, _hoisted_3))
              : _createCommentVNode("", true),
            (item.visible && !item.isButton)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 1,
                  to: { path: item.path },
                  class: "drawer-item",
                  target: item.external ? '_blank' : false
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_5, [
                      (item.iconName)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            (item.iconName === 'GreenSparkLogo')
                              ? (_openBlock(), _createBlock(_component_GreenSparkLogo, {
                                  key: 0,
                                  class: "icon svg"
                                }))
                              : (_openBlock(), _createBlock(_component_v_icon, {
                                  key: 1,
                                  class: "icon svg"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.iconName), 1)
                                  ]),
                                  _: 2
                                }, 1024))
                          ], 64))
                        : _createCommentVNode("", true),
                      _createElementVNode("span", null, _toDisplayString(_ctx.t(item.linkText)), 1)
                    ])
                  ]),
                  _: 2
                }, 1032, ["to", "target"]))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ])
    ], 512), [
      [_vShow, _ctx.isDrawerOpen]
    ]),
    _createElementVNode("nav", _hoisted_6, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visibleRoutes, (item, index) => {
        return (_openBlock(), _createElementBlock(_Fragment, null, [
          (item.isButton && item.visible)
            ? (_openBlock(), _createElementBlock("button", {
                key: index,
                class: "menu-item",
                onClick: item.function
              }, [
                (item.iconName)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      (item.iconName === 'GreenSparkLogo')
                        ? (_openBlock(), _createBlock(_component_GreenSparkLogo, {
                            key: 0,
                            class: "icon svg material-icons-outlined"
                          }))
                        : (_openBlock(), _createBlock(_component_v_icon, {
                            key: 1,
                            class: "icon svg material-icons-outlined"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.iconName), 1)
                            ]),
                            _: 2
                          }, 1024))
                    ], 64))
                  : _createCommentVNode("", true),
                _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.t(item.linkText)), 1)
              ], 8, _hoisted_7))
            : _createCommentVNode("", true),
          (item.visible && !item.isButton)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: index,
                to: { path: item.path },
                class: "menu-item",
                target: item.external ? '_blank' : false
              }, {
                default: _withCtx(() => [
                  (item.iconName)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        (item.iconName === 'GreenSparkLogo')
                          ? (_openBlock(), _createBlock(_component_GreenSparkLogo, {
                              key: 0,
                              class: "icon svg material-icons-outlined"
                            }))
                          : (_openBlock(), _createBlock(_component_v_icon, {
                              key: 1,
                              class: "icon svg material-icons-outlined"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.iconName), 1)
                              ]),
                              _: 2
                            }, 1024))
                      ], 64))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.t(item.linkText)), 1)
                ]),
                _: 2
              }, 1032, ["to", "target"]))
            : _createCommentVNode("", true)
        ], 64))
      }), 256)),
      (_ctx.otherRoutes.length)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "more",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDrawer && _ctx.toggleDrawer(...args)))
          }, [
            _createElementVNode("img", {
              class: "icon",
              alt: "more",
              src: require('@/assets/icons/quick-menu.svg')
            }, null, 8, _hoisted_10)
          ]))
        : _createCommentVNode("", true)
    ]),
    _withDirectives(_createElementVNode("ul", _hoisted_11, [
      (_ctx.isSupportMenuOpen)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "close-support-menu",
            onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.toggleSupportMenu()), ["prevent"]))
          }, [
            _createVNode(_component_v_icon, null, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("mdi-chevron-left")
              ])),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.t('documentation')), 1),
      _createElementVNode("li", _hoisted_13, [
        _createElementVNode("a", {
          href: _ctx.KNOWLEDGE_BASE_URL,
          target: "_blank",
          class: "drawer-menu__link"
        }, [
          _createVNode(_component_v_icon, { class: "icon material-icons-outlined" }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode(" mdi-book-outline ")
            ])),
            _: 1
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.t('knowledge_base')), 1)
        ], 8, _hoisted_14)
      ]),
      _createElementVNode("li", _hoisted_15, [
        _createElementVNode("a", {
          href: _ctx.API_DOCUMENTATION_URL,
          target: "_blank",
          class: "drawer-menu__link"
        }, [
          _createVNode(_component_v_icon, { class: "icon" }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode(" mdi-link-variant ")
            ])),
            _: 1
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.t('api_docs')), 1)
        ], 8, _hoisted_16)
      ]),
      (_ctx.isBusiness)
        ? (_openBlock(), _createElementBlock("li", _hoisted_17, [
            _createElementVNode("a", _hoisted_18, [
              _createVNode(_component_v_icon, { class: "icon" }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode(" mdi-bell-outline ")
                ])),
                _: 1
              }),
              _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.t('whats_new')), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("p", _hoisted_20, _toDisplayString(_ctx.t('contact')), 1),
      _createElementVNode("li", _hoisted_21, [
        _createElementVNode("button", {
          class: "drawer-menu__link",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.openChatCloseDrawer && _ctx.openChatCloseDrawer(...args)))
        }, [
          _createVNode(_component_v_icon, { class: "icon material-icons-outlined" }, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [
              _createTextVNode(" mdi-message-text-outline ")
            ])),
            _: 1
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.t('open_chat')), 1)
        ])
      ]),
      _createElementVNode("li", _hoisted_22, [
        _createElementVNode("a", {
          href: _ctx.SUPPORT_EMAIL_URL,
          target: "_blank",
          class: "drawer-menu__link"
        }, [
          _createVNode(_component_v_icon, { class: "icon material-icons-outlined" }, {
            default: _withCtx(() => _cache[8] || (_cache[8] = [
              _createTextVNode(" mdi-email-outline ")
            ])),
            _: 1
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.t('email')), 1)
        ], 8, _hoisted_23)
      ])
    ], 512), [
      [_vShow, _ctx.isSupportMenuOpen]
    ])
  ]))
}