<template>
  <div class="impact-pricing-comparison">
    <h3>{{ t('PlanComparisonTable.impact_pricing') }}</h3>
    <table>
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">
            <h5>{{ t('PlanComparisonTable.free_plan') }}</h5>
          </th>
          <th scope="col">
            <h5>{{ t('PlanComparisonTable.paid_plans') }}</h5>
            <p>{{ t('PlanComparisonTable.paid_plan_list') }}</p>
          </th>
          <th scope="col">
            <h5>{{ t('PlanComparisonTable.enterprise') }}</h5>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(impact, index) in Object.values(pricingPerImpact)" :key="index">
          <th>{{ impact.title }}</th>
          <td v-html="impact.freePlan" />
          <td v-html="impact.paidPlans" />
          <td>{{ impact.enterprise }}</td>
          <hr v-if="index < Object.values(pricingPerImpact).length - 1" />
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import type { OffsetType } from '@/helpers/interfaces'

type PricingCopies = {
  title: string
  freePlan: string
  paidPlans: string
  enterprise: string
}
const { t } = useI18n()
const store = useStore()

await store.dispatch('setProjectList')

const pricingPerImpact = computed<Record<OffsetType, PricingCopies>>(() => {
  const formatter = new Intl.NumberFormat(store.getters['getUserLocale'], {
    style: 'currency',
    currency: store.getters['getUserCurrencyCode'],
    minimumFractionDigits: 2,
    maximumFractionDigits: 3,
  })
  const treeFreePrice = store.getters['getDefaultProjectPriceByType']('trees', true)
  const treePaidPrice = store.getters['getDefaultPaidProjectPriceByType']('trees')

  const plasticFreePrice = store.getters['getDefaultProjectPriceByType']('plastic', true)
  const plasticPaidPrice = store.getters['getDefaultPaidProjectPriceByType']('plastic')

  const carbonFreePrice = store.getters['getDefaultProjectPriceByType']('carbon', true)
  const carbonPaidPrice = store.getters['getDefaultPaidProjectPriceByType']('carbon')

  const kelpFreePrice = store.getters['getDefaultProjectPriceByType']('kelp', true)
  const kelpPaidPrice = store.getters['getDefaultPaidProjectPriceByType']('kelp')

  const waterFreePrice = store.getters['getDefaultProjectPriceByType']('water', true)
  const waterPaidPrice = store.getters['getDefaultPaidProjectPriceByType']('water')

  const beesFreePrice = store.getters['getDefaultProjectPriceByType']('bees', true)
  const beesPaidPrice = store.getters['getDefaultPaidProjectPriceByType']('bees')

  return {
    trees: {
      title: t('trees.billed_action'),
      freePlan: t('trees.from_short_price', {
        price: formatter.format(treeFreePrice),
      }),
      paidPlans: t('trees.from_short_price', {
        price: formatter.format(treePaidPrice),
      }),
      enterprise: t('PlanComparisonTable.custom'),
    },
    plastic: {
      title: t('plastic.billed_action'),
      freePlan: t('plastic.short_price', {
        price: formatter.format(plasticFreePrice),
      }),
      paidPlans: t('plastic.short_price', {
        price: formatter.format(plasticPaidPrice),
      }),
      enterprise: t('PlanComparisonTable.custom'),
    },
    carbon: {
      title: t('carbon.billed_action'),
      freePlan: t('carbon.short_price', {
        price: formatter.format(carbonFreePrice),
      }),
      paidPlans: t('carbon.short_price', {
        price: formatter.format(carbonPaidPrice),
      }),
      enterprise: t('PlanComparisonTable.custom'),
    },
    kelp: {
      title: t('kelp.billed_action'),
      freePlan: t('kelp.short_price', {
        price: formatter.format(kelpFreePrice),
      }),

      paidPlans: t('kelp.short_price', {
        price: formatter.format(kelpPaidPrice),
      }),
      enterprise: t('PlanComparisonTable.custom'),
    },
    water: {
      title: t('water.billed_action'),
      freePlan: t('water.short_price', {
        price: formatter.format(waterFreePrice),
      }),

      paidPlans: t('water.short_price', {
        price: formatter.format(waterPaidPrice),
      }),
      enterprise: t('PlanComparisonTable.custom'),
    },
    bees: {
      title: t('bees.billed_action'),
      freePlan: t('bees.short_price', {
        price: formatter.format(beesFreePrice),
      }),

      paidPlans: t('bees.short_price', {
        price: formatter.format(beesPaidPrice),
      }),
      enterprise: t('PlanComparisonTable.custom'),
    },
  } satisfies Record<OffsetType, PricingCopies>
})
</script>

<style lang="scss" scoped>
$cell-width: 260px;

.impact-pricing-comparison {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  background: white;
  padding: 24px 28px;
  text-align: left;
  overflow-x: scroll;

  h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0;
  }
}

table {
  display: flex;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  width: fit-content;

  thead {
    margin-bottom: 20px;

    tr {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;
      text-align: center;
      gap: 8px;
    }

    th {
      width: $cell-width;
      font-size: 20px;
      font-weight: 700;
      line-height: normal;

      p,
      h5 {
        margin-bottom: 0;
        font-size: 20px;
        line-height: normal;
      }

      p {
        font-weight: 400;
      }
    }
  }

  tbody {
    display: flex;
    flex-direction: column;
    width: max-content;

    tr {
      display: grid;
      grid-template-columns: 332px repeat(3, $cell-width);
      grid-template-rows: auto;
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      text-align: center;
      font-size: 20px;
      font-weight: 400;
      line-height: normal;
    }

    th {
      text-align: left;
      font-weight: 400;
      padding-right: 8px;
    }

    hr {
      grid-column: 1 / 6;
      margin: 20px 0;
    }

    td {
      &:not(:last-of-type) {
        padding-right: 8px;
      }

      :deep(span.small) {
        font-size: 12px;
      }
    }
  }
}
</style>
