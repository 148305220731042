import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "update-wrapper" }
const _hoisted_2 = { class: "subheader" }
const _hoisted_3 = { class: "plan-name-total" }
const _hoisted_4 = { class: "plan" }
const _hoisted_5 = { class: "number" }
const _hoisted_6 = { class: "pending-number" }
const _hoisted_7 = { class: "text-field-wrapper" }
const _hoisted_8 = { class: "plus-minus" }
const _hoisted_9 = { class: "plan-name-total" }
const _hoisted_10 = { class: "plan" }
const _hoisted_11 = { class: "number" }
const _hoisted_12 = { class: "pending-number" }
const _hoisted_13 = { class: "text-field-wrapper" }
const _hoisted_14 = { class: "plus-minus" }
const _hoisted_15 = { class: "plan-name-total" }
const _hoisted_16 = { class: "plan" }
const _hoisted_17 = { class: "number" }
const _hoisted_18 = { class: "pending-number" }
const _hoisted_19 = { class: "text-field-wrapper" }
const _hoisted_20 = { class: "plus-minus" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_v_text_field = _resolveComponent("v-text-field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.t('your_team')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.standardAmount), 1),
        _cache[9] || (_cache[9] = _createElementVNode("span", { class: "x" }, "x", -1)),
        _createElementVNode("div", null, _toDisplayString(_ctx.t('standard')), 1),
        _createVNode(_component_Tooltip, {
          class: "tooltip",
          body: _ctx.t('standard_tooltip')
        }, null, 8, ["body"]),
        _createElementVNode("div", _hoisted_6, "(" + _toDisplayString(_ctx.updatedTotalPendingStandard) + " " + _toDisplayString(_ctx.t('pending')) + ")*", 1)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_v_text_field, {
          modelValue: _ctx.addStandard,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.addStandard) = $event)),
          modelModifiers: { number: true },
          label: _ctx.t('adjust_standard'),
          variant: "outlined",
          clearable: "",
          type: "number",
          flat: "",
          color: "#3B755F",
          class: "employee-input"
        }, null, 8, ["modelValue", "label"]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", {
            class: "add",
            style: {"margin-bottom":"1px"},
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addStandard ? _ctx.addStandard++ : (_ctx.addStandard = 1)))
          }, " + "),
          _createElementVNode("div", {
            class: _normalizeClass(['add', { disabled: _ctx.isStandardMinusDisabled }]),
            style: {"margin-top":"1px"},
            onClick: _cache[2] || (_cache[2] = ($event: any) => (!_ctx.isStandardMinusDisabled ? _ctx.addStandard && _ctx.addStandard-- : null))
          }, " - ", 2)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.superiorAmount), 1),
        _cache[10] || (_cache[10] = _createElementVNode("span", { class: "x" }, "x", -1)),
        _createElementVNode("div", null, _toDisplayString(_ctx.t('superior')), 1),
        _createVNode(_component_Tooltip, {
          class: "tooltip",
          body: _ctx.t('superior_tooltip')
        }, null, 8, ["body"]),
        _createElementVNode("div", _hoisted_12, "(" + _toDisplayString(_ctx.updatedTotalPendingSuperior) + " " + _toDisplayString(_ctx.t('pending')) + ")*", 1)
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_v_text_field, {
          modelValue: _ctx.addSuperior,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.addSuperior) = $event)),
          modelModifiers: { number: true },
          label: _ctx.t('adjust_superior'),
          variant: "outlined",
          clearable: "",
          type: "number",
          flat: "",
          color: "#3B755F",
          class: "employee-input"
        }, null, 8, ["modelValue", "label"]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", {
            class: "add",
            style: {"margin-bottom":"1px"},
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.addSuperior ? _ctx.addSuperior++ : (_ctx.addSuperior = 1)))
          }, " + "),
          _createElementVNode("div", {
            class: _normalizeClass(['add', { disabled: _ctx.isSuperiorMinusDisabled }]),
            style: {"margin-top":"1px"},
            onClick: _cache[5] || (_cache[5] = ($event: any) => (!_ctx.isSuperiorMinusDisabled ? _ctx.addSuperior && _ctx.addSuperior-- : null))
          }, " - ", 2)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_15, [
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.premiumAmount), 1),
        _cache[11] || (_cache[11] = _createElementVNode("span", { class: "x" }, "x", -1)),
        _createElementVNode("div", null, _toDisplayString(_ctx.t('premium')), 1),
        _createVNode(_component_Tooltip, {
          class: "tooltip",
          body: _ctx.t('premium_tooltip')
        }, null, 8, ["body"]),
        _createElementVNode("div", _hoisted_18, "(" + _toDisplayString(_ctx.updatedTotalPendingPremium) + " " + _toDisplayString(_ctx.t('pending')) + ")*", 1)
      ]),
      _createElementVNode("div", _hoisted_19, [
        _createVNode(_component_v_text_field, {
          modelValue: _ctx.addPremium,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.addPremium) = $event)),
          modelModifiers: { number: true },
          label: _ctx.t('adjust_premium'),
          variant: "outlined",
          clearable: "",
          type: "number",
          flat: "",
          color: "#3B755F",
          class: "employee-input"
        }, null, 8, ["modelValue", "label"]),
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("div", {
            class: "add",
            style: {"margin-bottom":"1px"},
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.addPremium ? _ctx.addPremium++ : (_ctx.addPremium = 1)))
          }, " + "),
          _createElementVNode("div", {
            class: _normalizeClass(['add', { disabled: _ctx.isPremiumMinusDisabled }]),
            style: {"margin-top":"1px"},
            onClick: _cache[8] || (_cache[8] = ($event: any) => (!_ctx.isPremiumMinusDisabled ? _ctx.addPremium && _ctx.addPremium-- : null))
          }, " - ", 2)
        ])
      ])
    ])
  ]))
}