<template>
  <v-dialog width="1175" v-model="isOpen" persistent :fullscreen="$vuetify.display.xs">
    <v-card class="card d-flex flex-column">
      <div class="popup-header">
        <p class="popup-title">
          {{
            selectedProducts && selectedProducts?.length > 1
              ? t('choose_bulk_impact', { count: selectedProducts?.length.toString() ?? '0' })
              : t('choose_impact')
          }}
        </p>
        <button class="close-button" @click.prevent="close" variant="plain"></button>
      </div>
      <ImpactSettings v-model="impactSettings" :max-values="maxValues" />
      <div class="totals-wrapper section">
        <p class="cart-items-text">
          {{ getImpactSettingCartText(impactSettings, selectedTrigger) }}
        </p>
        <p class="total-text">
          {{
            t('total', {
              price: userCurrencySymbol + getImpactSettingTotal(impactSettings),
            })
          }}
        </p>
      </div>
      <gs-button
        @click="handleSave"
        :type="automationId ? 'primary' : 'tertiary'"
        size="large"
        full-width
      >
        {{ t(automationId ? 'save_impact' : 'set_impact') }}
      </gs-button>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import type { AutomationOffset } from '@/store/integrations'
import ImpactSettings from '@/components/onboarding/ImpactSettings.vue'
import { Utils } from '@/helpers/mixins/utilsMixin'
import { IntegrationsMixin } from '@/helpers/mixins/integrationsMixin'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import type { AutomationTrigger } from '@/helpers/interfaces'

export default defineComponent({
  name: 'ImpactSettingsPopup',
  emits: ['close', 'save'],
  mixins: [Utils, IntegrationsMixin],
  components: {
    ImpactSettings,
  },
  data() {
    return {
      isOpen: true,
      localValues: {},
      impactSettings: [],
      isFormValid: true,
      lastVisibleCardIndex: 0,
    } as {
      isOpen: boolean
      localValues: Record<string, number>
      impactSettings: AutomationOffset[]
      isFormValid: boolean
      lastVisibleCardIndex: number
    }
  },
  computed: {
    selectedTrigger(): AutomationTrigger {
      return 'offsetPerProduct'
    },
    userCurrencySymbol(): string {
      return this.$store.getters['getUserCurrencySymbol']
    },
  },
  methods: {
    t(key: string, params?: Record<string, string>) {
      return this.$t(`ProductTable.${key}`, params ?? {})
    },
    close() {
      this.isOpen = !this.isOpen
      this.$emit('close')
    },
    setVisibleIndex(payload: number) {
      this.lastVisibleCardIndex = payload
    },
    handleSave() {
      this.$emit('save', {
        offsets: this.impactSettings.filter((impact) => !!impact.amount),
        selectedProducts: this.selectedProducts,
      })
      this.close()
    },
  },
  props: {
    maxValues: {
      default: () => [],
      type: Array as PropType<Omit<Required<AutomationOffset>, 'type' | 'source'>[]>,
    },
    selectedProducts: {
      type: Array as PropType<string[]>,
    },
    automationId: {
      type: String,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.card {
  padding: 16px;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    padding: 32px;
  }

  :deep(.gs-button) {
    overflow: visible;
    padding: 19px 0 !important;
  }
}

.popup-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  padding-right: 40px;
}

.impact-settings {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  column-gap: 24px;
  row-gap: 14px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    overflow: visible;
    flex-wrap: wrap;
  }

  :deep(.select-project-wrapper) {
    padding-bottom: 0;
  }
}

.impact-settings-wrapper {
  position: relative;
}

.markers {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 25px 0 20px;
  gap: 15px;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    display: none;
  }
}

.marker-dot {
  height: 10px;
  width: 10px;
  background-color: var(--gray-light-20);
  box-shadow: var(--box-shadow);
  border-radius: 50%;
  border: 1px solid var(--gray-light);
  transition: all ease 0.3s;
}

.marker-dot.visible {
  background-color: var(--ui-green-light);
  border: none;
  transition: all ease 0.3s;
}

.totals-wrapper {
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 24px;
  margin: 21px 0;
  border-top: 1px solid var(--gray-light-CC);
}

.cart-items-text {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  color: #7c7c7c;
  margin-bottom: 10px;
}

.total-text {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 0;
}

.filter-button-list {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.filter-button {
  border-radius: var(--border-radius-big);
  padding: 5px 8px;
  font-size: 16px;
  line-height: 20px;
  color: var(--gray-light-FF);
  background-color: #7c7c7c;
}

.filter-button.is-active {
  background-color: var(--ui-black);
  cursor: auto;
}
</style>
