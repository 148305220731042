<template>
  <div>
    <div v-if="!loading">
      <h1>Create Script Tag</h1>
      <span class="mb-4 d-block">
        Create and update a new script tag for the selected account with specified widget settings.
        You can see existing script tags by filling out the form and submit it.
      </span>

      <v-form ref="form" v-model="valid">
        <v-autocomplete
          v-model="selectedAccountId"
          :item-title="getAccountId"
          :item-value="getAccountId"
          :items="accounts.map((account) => account.accountId)"
          :rules="[rules.required]"
          class="mb-4"
          color="#3B755F"
          item-color="#3B755F"
          label="Account"
          variant="outlined"
        />

        <v-select
          v-model="type"
          :items="widgetTypes"
          :rules="[rules.required]"
          class="mb-4"
          color="#3B755F"
          item-color="#3B755F"
          label="Widget Type"
          variant="outlined"
        />

        <v-select
          v-model="color"
          :items="widgetColors"
          :rules="[rules.required]"
          class="mb-4"
          color="#3B755F"
          item-color="#3B755F"
          label="Widget Color"
          variant="outlined"
        />

        <v-select
          v-model="style"
          :items="widgetStyles"
          :rules="[rules.required]"
          class="mb-4"
          color="#3B755F"
          item-color="#3B755F"
          label="Widget Style"
          variant="outlined"
        />

        <v-radio-group v-model="withPopup" class="mb-4" hide-details>
          <v-radio :disabled="type === 'static'" :value="true" label="Activate popup"></v-radio>
          <div v-if="withPopup">
            <v-radio-group v-model="popupTheme" class="nested-radio-group">
              <template v-for="(item, index) in popupThemeOptions" :key="index">
                <div
                  :class="{ active: item.value === popupTheme }"
                  class="gs-radio-group-item"
                  @click="type !== 'static' && (popupTheme = item.value)"
                >
                  <v-radio :disabled="type === 'static'" :label="item.label" :value="item.value" />
                  <div class="gs-radio-group-content">
                    <div v-if="item.imageUrl" class="gs-radio-group-image">
                      <img :alt="item.label" :src="item.imageUrl" />
                    </div>
                  </div>
                </div>
              </template>
            </v-radio-group>
          </div>
          <v-radio :disabled="type === 'static'" :value="false" label="Deactivate popup"></v-radio>
        </v-radio-group>

        <v-textarea
          v-model="scriptTag"
          :rows="3"
          class="mb-4"
          label="Generated Script Tag"
          readonly
          variant="outlined"
        >
          <template v-slot:append-inner>
            <v-tooltip
              :open-on-click="true"
              :open-on-hover="false"
              close-delay="5000"
              location="top"
              text="Copied to clipboard"
            >
              <template v-slot:activator="{ props }">
                <v-icon v-bind="props" @click="copyText(scriptTag)"> mdi-content-copy</v-icon>
              </template>
            </v-tooltip>
          </template>
        </v-textarea>

        <v-textarea
          :model-value="shopifyScriptTag"
          :rows="3"
          class="mb-4"
          label="Shopify Script Tag"
          readonly
          variant="outlined"
        >
          <template v-slot:append-inner>
            <v-tooltip
              :open-on-click="true"
              :open-on-hover="false"
              close-delay="5000"
              location="top"
              text="Copied to clipboard"
            >
              <template v-slot:activator="{ props }">
                <v-icon v-bind="props" @click="copyText(shopifyScriptTag)">
                  mdi-content-copy
                </v-icon>
              </template>
            </v-tooltip>
          </template>
        </v-textarea>

        <gs-button
          :disabled="loading || !valid"
          :full-width="true"
          :loading="loading"
          size="large"
          type="primary"
          @click="createScriptTag"
        >
          Generate Script Tag
        </gs-button>
      </v-form>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script lang="ts">
import type {
  AccountDto,
  WidgetEditorSettingsColor,
  WidgetEditorSettingsType,
  WidgetStyle,
} from '@api/index'
import { fetchAllAccount } from '@api/index'
import { upsertWidgetEditorSettingsForAccount } from '@api/admin-api'
import Loading from '@/components/tools/Loading.vue'
import { RulesMixin } from '@/helpers/mixins/RulesMixin.vue'
import type { Notification } from '@/store/notification'
import type { VForm } from 'vuetify/components'
import { defineComponent } from 'vue'
import { Utils } from '@/helpers/mixins/utilsMixin'

export default defineComponent({
  name: 'CreateScriptTagView',
  components: { Loading },
  mixins: [RulesMixin, Utils],
  data() {
    return {
      loading: false,
      valid: false,
      selectedAccountId: '',
      type: undefined as WidgetEditorSettingsType | undefined,
      color: undefined as WidgetEditorSettingsColor | undefined,
      style: undefined as WidgetStyle | undefined,
      withPopup: true,
      popupTheme: 'light' as 'light' | 'dark',
      scriptTag: '',
      accounts: [] as AccountDto[],
    }
  },
  computed: {
    widgetTypes(): { title: string; value: WidgetEditorSettingsType }[] {
      return [
        {
          title: 'Offset by Product',
          value: 'offsetByProduct',
        },
        {
          title: 'Offset per Order',
          value: 'offsetPerOrder',
        },
        {
          title: 'Offset by Spend',
          value: 'offsetBySpend',
        },
        {
          title: 'Offset by Store Revenue',
          value: 'offsetByStoreRevenue',
        },
        {
          title: 'Cart',
          value: 'orderImpacts',
        },
        {
          title: 'By Percentage',
          value: 'byPercentage',
        },
        {
          title: 'By Percentage of Revenue',
          value: 'byPercentageOfRevenue',
        },
        {
          title: 'Top Stats',
          value: 'stats',
        },
        {
          title: 'Static',
          value: 'static',
        },
      ]
    },
    widgetColors(): { title: string; value: WidgetEditorSettingsColor }[] {
      return [
        {
          title: 'Green',
          value: 'green',
        },
        {
          title: 'White',
          value: 'white',
        },
        {
          title: 'Black',
          value: 'black',
        },
        {
          title: 'Beige',
          value: 'beige',
        },
        {
          title: 'Blue',
          value: 'blue',
        },
        {
          title: 'Grey',
          value: 'grey',
        },
        {
          title: 'Transparent',
          value: 'transparent',
        },
      ]
    },
    widgetStyles(): { title: string; value: WidgetStyle }[] {
      const baseStyles: { title: string; value: WidgetStyle }[] = [
        {
          title: 'Default',
          value: 'default' as WidgetStyle,
        },
        {
          title: 'Simplified',
          value: 'simplified' as WidgetStyle,
        },
      ]

      if (this.type === 'static') {
        baseStyles.push({
          title: 'Rounded',
          value: 'rounded' as WidgetStyle,
        })
      }

      return baseStyles
    },
    popupThemeOptions(): Array<{ label: string; value: 'light' | 'dark'; imageUrl: string }> {
      return [
        {
          label: 'Light Theme',
          value: 'light',
          imageUrl: require('@/assets/images/popup_light.png'),
        },
        {
          label: 'Dark Theme',
          value: 'dark',
          imageUrl: require('@/assets/images/popup_dark.png'),
        },
      ]
    },
    shopifyScriptTag(): string {
      return this.desanitizeHTML(
        `&lt;script src=&quot;https://cdn.getgreenspark.com/scripts/widgets-shopify%40latest.js&quot;&gt;&lt;/script&gt;`,
      )
    },
  },
  async created() {
    this.loading = true
    this.accounts = await fetchAllAccount()
    this.loading = false
  },
  methods: {
    getAccountId(account: AccountDto): string {
      return account.accountId
    },
    async createScriptTag() {
      if (!this.$refs.form) return

      const form = this.$refs.form as InstanceType<typeof VForm>
      const { valid } = await form.validate()
      if (!valid) return

      this.loading = true
      try {
        const data = await upsertWidgetEditorSettingsForAccount({
          accountId: this.selectedAccountId,
          type: this.type!,
          color: this.color!,
          style: this.style!,
          status: 'active',
          withPopup: this.type === 'static' ? undefined : this.withPopup,
          popupTheme: this.type === 'static' ? undefined : this.popupTheme,
        })
        this.scriptTag = this.desanitizeHTML(
          `&lt;div class="greenspark-widget-target" id="${data.id}"&gt;&lt;/div&gt;`,
        )
      } catch (e) {
        this.$store.dispatch('notification/notify', {
          text: 'Error generating script tag',
          isError: true,
        } as Notification)
      } finally {
        this.loading = false
      }
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.nested-radio-group:deep(.v-selection-control-group) {
  flex-direction: row;
  gap: 16px;

  .v-icon {
    font-size: 16px !important;
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    flex-direction: column;
  }
}

.gs-radio-group-item {
  border-radius: 10px;
  border: 1px solid var(--gray-light-A9);
  padding: 8px 16px 16px;
  cursor: pointer;
  transition: all 100ms ease-in-out;
  width: 100%;

  &.active {
    border: 1px solid var(--ui-emerald-green);
    background-color: #47d18f33;
  }
}

.gs-radio-group-image {
  display: flex;
  align-items: center;

  img {
    object-fit: cover;
  }
}
</style>
