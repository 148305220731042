import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "carousel-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "carousel-item-wrapper"
}
const _hoisted_3 = { class: "quote" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "author-name" }
const _hoisted_6 = { class: "author-title" }
const _hoisted_7 = {
  key: 1,
  class: "carousel-project-item-wrapper"
}
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "project-content" }
const _hoisted_10 = { class: "project-title" }
const _hoisted_11 = { class: "project-text" }
const _hoisted_12 = { class: "project-checklist" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_carousel_item = _resolveComponent("v-carousel-item")!
  const _component_v_carousel = _resolveComponent("v-carousel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_carousel, {
      "hide-delimiter-background": "",
      height: "100%",
      continuous: "",
      cycle: "",
      "show-arrows": false,
      class: "carousel-card"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.carouselItems, (item, index) => {
          return (_openBlock(), _createBlock(_component_v_carousel_item, {
            key: index,
            class: "carousel-card-item"
          }, {
            default: _withCtx(() => [
              (_ctx.getIfInstanceOfQuote(item))
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createElementVNode("p", _hoisted_3, _toDisplayString(item.quote), 1),
                    _createElementVNode("img", {
                      src: item.imageUrl,
                      alt: "",
                      class: "author-picture"
                    }, null, 8, _hoisted_4),
                    _createElementVNode("p", _hoisted_5, _toDisplayString(item.authorName), 1),
                    _createElementVNode("p", _hoisted_6, _toDisplayString(item.authorTitle), 1)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("img", {
                      src: item.imageUrl,
                      alt: "",
                      class: "project-picture"
                    }, null, 8, _hoisted_8),
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("p", _hoisted_10, _toDisplayString(item.title), 1),
                      _createElementVNode("p", _hoisted_11, _toDisplayString(item.text), 1),
                      _createElementVNode("ul", _hoisted_12, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.checkList, (listItem, itemIndex) => {
                          return (_openBlock(), _createElementBlock("li", {
                            key: itemIndex,
                            class: "project-checklist-item"
                          }, [
                            _createVNode(_component_v_icon, { class: "checkmark" }, {
                              default: _withCtx(() => _cache[0] || (_cache[0] = [
                                _createTextVNode(" mdi-check ")
                              ])),
                              _: 1
                            }),
                            _createTextVNode(" " + _toDisplayString(listItem), 1)
                          ]))
                        }), 128))
                      ])
                    ])
                  ]))
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    })
  ]))
}