import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "user-component" }
const _hoisted_2 = { class: "user-component-wrapper" }
const _hoisted_3 = { class: "user-wrapper" }
const _hoisted_4 = { class: "form-wrapper" }
const _hoisted_5 = { class: "header" }
const _hoisted_6 = { class: "how-it-works-text" }
const _hoisted_7 = { class: "number-explanation-wrapper" }
const _hoisted_8 = { class: "explanation" }
const _hoisted_9 = { class: "number-explanation-wrapper" }
const _hoisted_10 = ["href"]
const _hoisted_11 = {
  class: "number-explanation-wrapper",
  style: {"margin-bottom":"0"}
}
const _hoisted_12 = { class: "explanation" }
const _hoisted_13 = { class: "already-member" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_gs_button = _resolveComponent("gs-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "back" }, null, -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.t('header')), 1),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.t('text')), 1),
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "divider-line" }, null, -1)),
          _createElementVNode("div", _hoisted_7, [
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "number" }, "1", -1)),
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.t('account')), 1)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "number" }, "2", -1)),
            _createVNode(_component_i18n_t, {
              tag: "div",
              keypath: "ShopifySetupInstructions.automations",
              class: "explanation"
            }, {
              default: _withCtx(() => [
                _createElementVNode("a", {
                  href: _ctx.PRIVACY_POLICY_URL,
                  target: "_blank",
                  class: "link"
                }, _toDisplayString(_ctx.t('privacy')), 9, _hoisted_10)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_11, [
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "number" }, "✓", -1)),
            _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.t('done')), 1)
          ]),
          _createVNode(_component_gs_button, {
            class: "button",
            type: "primary",
            size: "large",
            "full-width": "",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('nextClick')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('continue')), 1)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_13, [
            _createTextVNode(_toDisplayString(_ctx.t('already_member')) + " ", 1),
            _createElementVNode("span", {
              class: "green-text",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.navigateToLogin && _ctx.navigateToLogin(...args)))
            }, _toDisplayString(_ctx.t('login')), 1)
          ])
        ])
      ])
    ])
  ]))
}