<template>
  <div class="overlay-text overlay-text--full-width">
    <strong>{{ t('shopify_script_tag_tutorial.title') }}</strong>
    <p class="mt-3 mb-0" v-html="t('shopify_script_tag_tutorial.description')"></p>
  </div>

  <Stepper v-model="activeStep" :max-value="2" :show-logo="false">
    <StepperContent :is-visible="activeStep === 1" transition="fade">
      <div class="pop-up-content-wrapper">
        <div class="how-it-works">
          <div>
            <div class="number-explanation-wrapper mb-2">
              <div class="number">
                <span>1</span>
              </div>
              <div class="explanation" v-html="t('shopify_script_tag_tutorial.step_1')"></div>
            </div>

            <v-radio-group v-model="withPopup" class="mb-4" hide-details>
              <v-radio
                :label="t('shopify_script_tag_tutorial.activate_popup')"
                :value="true"
                :disabled="type === 'static'"
              ></v-radio>
              <div v-if="withPopup">
                <v-radio-group v-model="popupTheme" class="nested-radio-group">
                  <template v-for="(item, index) in shopifyScriptTagItems" :key="index">
                    <div
                      :class="{ active: item.popupTheme === popupTheme }"
                      class="gs-radio-group-item"
                      @click="popupTheme = item.popupTheme"
                    >
                      <v-radio :label="item.label" :value="item.popupTheme" />
                      <div class="gs-radio-group-content">
                        <div v-if="item.imageUrl" class="gs-radio-group-image">
                          <img :alt="item.label" :src="item.imageUrl" />
                        </div>
                      </div>
                    </div>
                  </template>
                </v-radio-group>
              </div>
              <v-radio
                :label="t('shopify_script_tag_tutorial.deactivate_popup')"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </div>
        </div>
        <gs-button
          :uppercased="false"
          capitalized
          full-width
          size="large"
          target="_blank"
          type="tertiary"
          :disabled="loading"
          :loading="loading"
          @click="upsertWidgetEditorSettings"
        >
          Next
        </gs-button>
      </div>
    </StepperContent>
    <StepperContent :is-visible="activeStep === 2" transition="fade">
      <div class="pop-up-content-wrapper">
        <div class="how-it-works">
          <div class="number-explanation-wrapper">
            <div class="number">
              <span>1</span>
            </div>
            <div class="explanation" v-html="t('shopify_script_tag_tutorial.step_2')"></div>
          </div>

          <div class="number-explanation-wrapper">
            <div class="number"><span>2</span></div>
            <div class="explanation" v-html="t('shopify_script_tag_tutorial.step_3')"></div>
          </div>

          <div class="d-flex flex-column my-4">
            <label class="key-input-label" for="shopify-script-div-copy">
              <span v-html="t('shopify_script_tag_tutorial.div_tag_label')"></span>
            </label>
            <v-textarea
              id="shopify-script-div-copy"
              :model-value="shopifyDivTag"
              :rows="2"
              class="shopify-script-key-input"
              hide-details
              no-resize
              readonly
              variant="outlined"
            >
              <template v-slot:append-inner>
                <v-tooltip
                  :open-on-click="true"
                  :open-on-hover="false"
                  :text="t('copy_action_success_feedback')"
                  close-delay="5000"
                  location="top"
                  max-width="480px"
                >
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props" @click="copyText(shopifyDivTag)">
                      mdi-content-copy
                    </v-icon>
                  </template>
                </v-tooltip>
              </template>
            </v-textarea>
          </div>

          <div class="number-explanation-wrapper">
            <div class="number"><span>3</span></div>
            <div class="explanation" v-html="t('shopify_script_tag_tutorial.step_4')"></div>
          </div>

          <div class="d-flex flex-column my-4">
            <label class="key-input-label" for="shopify-script-copy">
              {{ t('shopify_script_tag_tutorial.script_tag_label') }}
            </label>
            <v-textarea
              id="shopify-script-copy"
              :model-value="shopifyScriptTag"
              :rows="2"
              class="shopify-script-key-input"
              hide-details
              no-resize
              readonly
              variant="outlined"
            >
              <template v-slot:append-inner>
                <v-tooltip
                  :open-on-click="true"
                  :open-on-hover="false"
                  :text="t('copy_action_success_feedback')"
                  close-delay="5000"
                  location="top"
                  max-width="480px"
                >
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props" @click="copyText(shopifyScriptTag)">
                      mdi-content-copy
                    </v-icon>
                  </template>
                </v-tooltip>
              </template>
            </v-textarea>
          </div>

          <div class="number-explanation-wrapper">
            <div class="number"><span>4</span></div>
            <div class="explanation" v-html="t('shopify_script_tag_tutorial.step_5')"></div>
          </div>

          <gs-alert-bar
            :message="t('shopify_script_tag_tutorial.only_once')"
            full-width
            type="warning"
          />

          <v-select
            v-model="shopify"
            :items="displayUrls"
            class="dropdown dropdown-block"
            color="#3B755F"
            hide-details
            item-color="#3B755F"
            label="Shop Name"
            variant="outlined"
          />

          <div class="d-flex ga-2 align-start">
            <div class="switch-wrapper">
              <v-switch
                v-model="isActive"
                :color="'#3B755F'"
                class="email-data-sync-toggle__switch"
                hide-details
                inset
                @update:model-value="upsertWidgetEditorSettings"
              >
              </v-switch>
            </div>
            <div class="d-flex flex-column">
              <span v-html="t('shopify_script_tag_tutorial.deactivate')" />
              {{ t('shopify_script_tag_tutorial.deactivate_description') }}
            </div>
          </div>
        </div>
        <div class="d-flex w-100 ga-1">
          <gs-button
            class="form-control"
            icon="mdi-chevron-left"
            size="large"
            type="outlined"
            @click="activeStep = 1"
          >
            {{ $t('CommonUi.back') }}
          </gs-button>
          <gs-button
            :disabled="loading || !shopify"
            :loading="loading"
            :uppercased="false"
            capitalized
            full-width
            size="large"
            target="_blank"
            type="primary"
            @click="handleClickOnGoToShopify"
          >
            {{ t('go_to_shopify') }}
          </gs-button>
        </div>
      </div>
    </StepperContent>
  </Stepper>
</template>
<script lang="ts">
import { LoadCrispMixin } from '@/helpers/mixins/loadCrispMixin'
import { Utils } from '@/helpers/mixins/utilsMixin'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import Stepper from '@/components/onboarding/Stepper.vue'
import StepperContent from '@/components/onboarding/StepperContent.vue'
import type {
  CreateWidgetEditorSettingsRequestBody,
  UpdateWidgetEditorSettingsRequestBody,
  WidgetEditorSettings,
  WidgetEditorSettingsColor,
  WidgetEditorSettingsPopupTheme,
  WidgetEditorSettingsType,
  WidgetStyle,
} from '@api/index'
import { isDemoEnvironment } from '@/helpers/constants'
import type { IntegrationPlatform } from '@/helpers/interfaces'
import type { Integration } from '@/store/integrations'
import type { Notification } from '@/store/notification'
import VueI18n from '@/i18n'

export default defineComponent({
  name: 'ScriptTag',
  mixins: [LoadCrispMixin, Utils],
  components: {
    StepperContent,
    Stepper,
  },
  data() {
    return {
      shopify: '',
      windowWidth: 0,
      withPopup: true,
      popupTheme: 'light',
      activeStep: 1,
      loading: false,
      isActive: false,
    } as {
      shopify: string
      windowWidth: number
      withPopup: boolean
      popupTheme: WidgetEditorSettingsPopupTheme
      activeStep: 1 | 2
      loading: boolean
      isActive: boolean
    }
  },
  computed: {
    shopifyUrl(): string {
      return `https://${this.shopify}/admin/themes/current/editor`
    },
    getIntegrationsByPlatform(): (platform: IntegrationPlatform) => Integration[] {
      return this.$store.getters['getIntegrationsByPlatform']
    },
    integrationList(): Integration[] {
      return this.$store.getters['getIntegrationList']
    },
    shopifyUrls(): string[] {
      return isDemoEnvironment
        ? [this.integrationList[0]?.name]
        : this.getIntegrationsByPlatform('shopify').map(({ name }) => name)
    },
    displayUrls(): { value: string; title: string }[] {
      return this.shopifyUrls.map((item, index) => {
        return {
          title: this.shopifyUrls[index],
          value: this.shopifyUrls[index],
        }
      })
    },
    shopifyScriptTag(): string {
      return this.desanitizeHTML(
        `&lt;script src=&quot;https://cdn.getgreenspark.com/scripts/widgets-shopify%40latest.js&quot;&gt;&lt;/script&gt;`,
      )
    },
    shopifyDivTag(): string {
      return this.desanitizeHTML(
        `&lt;div class="greenspark-widget-target" id="${this.widgetEditorSetting?.id}"&gt;&lt;/div&gt;`,
      )
    },
    shopifyScriptTagItems(): Array<{
      label: string
      imageUrl: string
      popupTheme: WidgetEditorSettingsPopupTheme
    }> {
      return [
        {
          label: this.t('shopify_script_tag_tutorial.light'),
          imageUrl: require('@/assets/images/popup_light.png'),
          popupTheme: 'light',
        },
        {
          label: this.t('shopify_script_tag_tutorial.dark'),
          imageUrl: require('@/assets/images/popup_dark.png'),
          popupTheme: 'dark',
        },
      ]
    },
    widgetEditorSetting(): WidgetEditorSettings | undefined {
      return this.$store.getters['getWidgetEditorSettingsByType'](this.type, this.color, this.style)
    },
  },
  async created() {
    window.addEventListener('resize', this.reportWindowSize)
    this.reportWindowSize()
    if (this.type === 'static') {
      // static widgets don't have popup
      this.withPopup = false
    }
    if (this.widgetEditorSetting) {
      this.activeStep = 2
      if (this.widgetEditorSetting.withPopup !== undefined) {
        this.withPopup = this.widgetEditorSetting.withPopup
      }
      if (this.widgetEditorSetting.popupTheme !== undefined) {
        this.popupTheme = this.widgetEditorSetting.popupTheme
      }
      if (this.widgetEditorSetting.status !== undefined) {
        this.isActive = this.widgetEditorSetting?.status === 'active'
      }
    }
  },
  unmounted() {
    window.removeEventListener('resize', this.reportWindowSize)
  },
  methods: {
    t(key: string) {
      return this.$t(`Badge.${key}`)
    },
    reportWindowSize() {
      this.windowWidth = window.innerWidth
    },
    handleClickOnGoToShopify() {
      window.open(this.shopifyUrl, '_blank')
    },
    async upsertWidgetEditorSettings() {
      try {
        if (!this.widgetEditorSetting?.id) {
          await this.createWidgetEditorSettings()
        } else {
          await this.updateWidgetEditorSettings()
        }
        this.activeStep = 2
      } catch (error) {
        this.$store.dispatch(
          'notification/notify',
          {
            text: VueI18n.global.t('CommonUi.error_generic'),
            isError: true,
            isClosable: true,
            buttonText: 'close',
          } as Notification,
          { root: true },
        )
      } finally {
        this.loading = false
      }
    },
    async createWidgetEditorSettings() {
      if (!this.widgetEditorSetting?.id && this.color && this.type) {
        this.loading = true
        await this.$store.dispatch('createWidgetEditorSettings', {
          color: this.color,
          type: this.type,
          style: this.style,
          status: 'active',
          withPopup: this.type === 'static' ? undefined : this.withPopup,
          popupTheme: this.type === 'static' ? undefined : this.popupTheme,
        } satisfies CreateWidgetEditorSettingsRequestBody)
        this.loading = false
      }
    },
    async updateWidgetEditorSettings() {
      if (this.widgetEditorSetting?.id) {
        this.loading = true
        await this.$store.dispatch('updateWidgetEditorSettings', {
          widgetId: this.widgetEditorSetting?.id,
          status: this.isActive ? 'active' : 'disabled',
          withPopup: this.type === 'static' ? undefined : this.withPopup,
          popupTheme: this.type === 'static' ? undefined : this.popupTheme,
        } satisfies { widgetId: string } & UpdateWidgetEditorSettingsRequestBody)
        this.loading = false
      }
    },
    onWidgetEditorSettingChange() {
      if (this.widgetEditorSetting) {
        this.isActive = this.widgetEditorSetting.status === 'active'
      }
    },
  },
  props: {
    type: {
      type: String as PropType<WidgetEditorSettingsType>,
      required: false,
    },
    color: {
      type: String as PropType<WidgetEditorSettingsColor>,
      required: false,
    },
    style: {
      type: String as PropType<WidgetStyle>,
      required: false,
      default: 'default',
    },
  },
  watch: {
    widgetEditorSetting: [
      {
        handler: 'onWidgetEditorSettingChange',
      },
    ],
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.button {
  margin-top: 18px;
  border-radius: 0;
  padding: 0 !important;
}

.pop-up-content-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.overlay-text {
  text-align: center;
  color: #212121;
  font-size: 15px;
  max-width: 440px;
  margin-top: 12px;
  margin-left: auto;
  margin-right: auto;

  &--full-width {
    text-align: left;
    margin-top: 24px;
    max-width: 100%;
  }

  strong {
    font-size: 18px;
  }

  :deep(a) {
    font-weight: 700;
  }
}

.how-it-works {
  margin-top: 24px;
  margin-bottom: 24px;
  width: 100%;
}

.number-explanation-wrapper {
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;

  &.step-with-input {
    margin-bottom: 8px;
  }
}

.number {
  min-width: 24px;
  min-height: 24px;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: var(--ui-green);
  font-weight: 600;
  margin-right: 8px;

  > span {
    position: relative;
    z-index: 1;
    font-size: 14px;
  }
}

.explanation {
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;

  a {
    font-weight: 700;
  }
}

.key-input-label {
  display: block;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 8px;
}

.shopify-script-key-input:deep(input::placeholder) {
  opacity: 1;
}

.label {
  font-weight: bold;
  margin-bottom: -7px;
}

.switch {
  cursor: pointer;
  color: #3b755f;
  font-size: 12px;
  margin-top: 12px;
}

.switch:hover {
  opacity: 80;
}

:deep(.step-indicator-list) {
  padding: 10px 0;
}

.nested-radio-group:deep(.v-selection-control-group) {
  flex-direction: row;
  gap: 16px;

  .v-icon {
    font-size: 16px !important;
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    flex-direction: column;
  }
}

.gs-radio-group {
  :deep(.v-selection-control-group) {
    flex-direction: column;
    gap: 20px;

    .v-label {
      opacity: 1;
    }
  }

  &.disabled {
    opacity: 0.7;
  }

  &.stacked {
    flex-direction: column;

    :deep(.v-selection-control-group) {
      gap: 8px;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .gs-radio-group-item {
      max-width: 155px;
      padding: 4px 12px 12px;
    }
  }
}

.gs-radio-group-item {
  border-radius: 10px;
  border: 1px solid var(--gray-light-A9);
  padding: 8px 16px 16px;
  cursor: pointer;
  transition: all 100ms ease-in-out;
  width: 100%;

  &.active {
    border: 1px solid var(--ui-emerald-green);
    background-color: #47d18f33;
  }

  &.disabled {
    position: relative;

    .gs-radio-group-item-overlay {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 12px;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--ui-black);
      opacity: 0.7;
      color: var(--lightest);
      border-radius: 10px;
      text-decoration: none;
      font-size: 18px;
      font-weight: 700;
    }
  }
}

.gs-radio-group-item-overlay {
  display: none;
}

.gs-radio-group-image {
  display: flex;
  align-items: center;

  img {
    object-fit: cover;
  }
}

.switch-wrapper {
  width: 55px;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    width: 70px;
  }

  @media #{map-get($display-breakpoints, 'xs')} {
    width: 120px;
  }
}

:deep(.stepper-items) {
  min-height: 0;
  margin-bottom: 0;
}

.dropdown {
  width: 100%;
}

.dropdown-block {
  margin-bottom: 16px;
}

.gs-alert-bar {
  margin-bottom: 24px;
}

.v-select {
  height: 70px;
}
</style>
