import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withKeys as _withKeys, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "user-component" }
const _hoisted_2 = { class: "user-component-wrapper" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "user-wrapper" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "form-wrapper" }
const _hoisted_7 = { class: "header" }
const _hoisted_8 = { class: "name-wrapper" }
const _hoisted_9 = { class: "text-field-wrapper" }
const _hoisted_10 = { class: "name-wrapper" }
const _hoisted_11 = { class: "text-field-wrapper" }
const _hoisted_12 = { class: "checkbox-wrapper" }
const _hoisted_13 = { class: "terms" }
const _hoisted_14 = { class: "button-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReferenceBox = _resolveComponent("ReferenceBox")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_AddPromoCode = _resolveComponent("AddPromoCode")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.$route.name === 'DirectMarketplaceSignup')
        ? (_openBlock(), _createBlock(_component_ReferenceBox, {
            key: 0,
            class: "reference-box",
            theme: "beige"
          }))
        : (_openBlock(), _createElementBlock("img", {
            key: 1,
            alt: "signup",
            class: "user-image",
            src: require('@/assets/create-account-shape.jpg')
          }, null, 8, _hoisted_3)),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: "back",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('backClick')))
        }, [
          _createElementVNode("img", {
            alt: "chevron",
            class: "chevron",
            src: require('@/assets/icons/chevron_left.svg'),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('backClick')))
          }, null, 8, _hoisted_5),
          _createElementVNode("div", null, _toDisplayString(_ctx.t('back')), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.t('create_account')), 1),
          _createVNode(_component_v_form, {
            modelValue: _ctx.isFormValid,
            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.isFormValid) = $event))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_v_text_field, {
                    id: "Email",
                    modelValue: _ctx.userObject.email,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.userObject.email) = $event)),
                    label: _ctx.t('email'),
                    autocomplete: "email",
                    class: "input-field",
                    rules: [_ctx.rules.email, _ctx.rules.required],
                    color: "green",
                    flat: "",
                    "hide-details": "",
                    name: "Email",
                    variant: "outlined",
                    type: "email",
                    onKeyup: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.buttonActive && !_ctx.loading ? _ctx.signup : ''), ["enter"]))
                  }, null, 8, ["modelValue", "label", "rules"])
                ])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_v_text_field, {
                    id: "Password",
                    modelValue: _ctx.userObject.password,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.userObject.password) = $event)),
                    "append-inner-icon": _ctx.showPassword ? 'mdi-eye' : 'mdi-eye-off',
                    label: _ctx.t('password'),
                    type: _ctx.showPassword ? 'text' : 'password',
                    autocomplete: "password",
                    class: "input-field",
                    color: "green",
                    rules: [_ctx.rules.password, _ctx.rules.required],
                    flat: "",
                    "hide-details": "",
                    name: "password",
                    variant: "outlined",
                    "onClick:appendInner": _cache[5] || (_cache[5] = ($event: any) => (_ctx.showPassword = !_ctx.showPassword)),
                    onKeyup: _cache[6] || (_cache[6] = _withKeys(($event: any) => (_ctx.buttonActive && !_ctx.loading ? _ctx.signup : ''), ["enter"]))
                  }, null, 8, ["modelValue", "append-inner-icon", "label", "type", "rules"])
                ])
              ]),
              (_ctx.account.emailAlreadyUsed)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    style: _normalizeStyle(_ctx.fromAppMarketplace ? 'background: #2E3A8C' : ''),
                    class: "error-message"
                  }, [
                    _createTextVNode(_toDisplayString(!_ctx.fromAppMarketplace ? _ctx.t('already_used') : _ctx.t('already_account')) + " ", 1),
                    (_ctx.fromAppMarketplace)
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          class: "login-link",
                          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$router.push('/login?app')))
                        }, _toDisplayString(_ctx.t('login')), 1))
                      : _createCommentVNode("", true)
                  ], 4))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                style: _normalizeStyle(_ctx.hasPasswordError ? 'background: red; color: #F9F9F9' : ''),
                class: "password-hint"
              }, _toDisplayString(_ctx.t('password_hint')), 5),
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_v_checkbox, {
                  modelValue: _ctx.checkbox,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.checkbox) = $event)),
                  color: "#3B755F",
                  "hide-details": "",
                  rules: [_ctx.rules.required],
                  style: {"margin":"0"}
                }, null, 8, ["modelValue", "rules"]),
                _createElementVNode("div", _hoisted_13, [
                  _cache[12] || (_cache[12] = _createTextVNode(" Accept our ")),
                  _createElementVNode("span", {
                    class: "link",
                    onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.openUrl('terms')))
                  }, "Terms & Conditions"),
                  _cache[13] || (_cache[13] = _createTextVNode(" & ")),
                  _createElementVNode("span", {
                    class: "link",
                    onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.openUrl('privacy')))
                  }, "Privacy Policy")
                ])
              ]),
              (_ctx.$route.name === 'DirectMarketplaceSignup')
                ? (_openBlock(), _createBlock(_component_AddPromoCode, {
                    key: 1,
                    onSetPromoCode: _ctx.setPromoCode
                  }, null, 8, ["onSetPromoCode"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_gs_button, {
                  disabled: !_ctx.isFormValid || _ctx.loading,
                  loading: _ctx.loading,
                  "full-width": true,
                  size: "large",
                  onClick: _withModifiers(_ctx.signup, ["prevent"])
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.fromAppMarketplace ? _ctx.t('continue') : _ctx.t('continue_to_payment')), 1)
                  ]),
                  _: 1
                }, 8, ["disabled", "loading", "onClick"])
              ])
            ]),
            _: 1
          }, 8, ["modelValue"])
        ])
      ])
    ])
  ]))
}