import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "user-component" }
const _hoisted_2 = { class: "user-component-wrapper" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "user-wrapper" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "form-wrapper" }
const _hoisted_7 = { class: "header" }
const _hoisted_8 = { class: "name-wrapper" }
const _hoisted_9 = { class: "text-field-wrapper" }
const _hoisted_10 = { class: "name-wrapper" }
const _hoisted_11 = { class: "text-field-wrapper" }
const _hoisted_12 = { class: "name-wrapper" }
const _hoisted_13 = { class: "text-field-wrapper" }
const _hoisted_14 = { class: "name-wrapper" }
const _hoisted_15 = { class: "text-field-wrapper" }
const _hoisted_16 = { class: "checkbox-wrapper" }
const _hoisted_17 = { class: "terms" }
const _hoisted_18 = { class: "button-wrapper" }
const _hoisted_19 = {
  key: 0,
  class: "error-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_Loading = _resolveComponent("Loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "user-image",
        src: require('@/assets/login-image.svg'),
        alt: "signup"
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: "back",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('backClick')))
        }, [
          _createElementVNode("img", {
            class: "chevron",
            alt: "chevron",
            src: require('@/assets/icons/chevron_left.svg'),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('backClick')))
          }, null, 8, _hoisted_5),
          _createElementVNode("div", null, _toDisplayString(_ctx.t('back')), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.t('create_account')), 1),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.userObject.firstName,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.userObject.firstName) = $event)),
                label: _ctx.t('first_name'),
                variant: "outlined",
                type: "text",
                flat: "",
                disabled: "",
                color: "#3B755F",
                class: "left-input",
                "hide-details": ""
              }, null, 8, ["modelValue", "label"]),
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.userObject.lastName,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.userObject.lastName) = $event)),
                label: _ctx.t('last_name'),
                variant: "outlined",
                type: "text",
                flat: "",
                disabled: "",
                color: "#3B755F",
                class: "right-input",
                "hide-details": ""
              }, null, 8, ["modelValue", "label"])
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_v_text_field, {
                id: "Email",
                modelValue: _ctx.userObject.email,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.userObject.email) = $event)),
                class: "input-field",
                name: "Email",
                type: "text",
                label: _ctx.t('email'),
                variant: "outlined",
                disabled: "",
                flat: "",
                color: "#3B755F",
                "hide-details": ""
              }, null, 8, ["modelValue", "label"])
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.userObject.companyName,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.userObject.companyName) = $event)),
                label: _ctx.t('company_name'),
                variant: "outlined",
                type: "text",
                flat: "",
                color: "#3B755F",
                class: "left-input",
                "hide-details": ""
              }, null, 8, ["modelValue", "label"]),
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.userObject.vat,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.userObject.vat) = $event)),
                label: _ctx.t('vat_id'),
                variant: "outlined",
                type: "text",
                flat: "",
                color: "#3B755F",
                class: "right-input",
                "hide-details": ""
              }, null, 8, ["modelValue", "label"])
            ])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createVNode(_component_v_text_field, {
                id: "Password",
                modelValue: _ctx.userObject.password,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.userObject.password) = $event)),
                class: "input-field",
                name: "password",
                autocomplete: "password",
                type: _ctx.showPassword ? 'text' : 'password',
                label: _ctx.t('password'),
                "append-inner-icon": _ctx.showPassword ? 'mdi-eye' : 'mdi-eye-off',
                variant: "outlined",
                flat: "",
                color: "#3B755F",
                "hide-details": "",
                "onClick:appendInner": _cache[8] || (_cache[8] = ($event: any) => (_ctx.showPassword = !_ctx.showPassword)),
                onKeyup: _cache[9] || (_cache[9] = _withKeys(($event: any) => (_ctx.buttonActive && !_ctx.loading ? _ctx.signup : ''), ["enter"]))
              }, null, 8, ["modelValue", "type", "label", "append-inner-icon"])
            ])
          ]),
          _createElementVNode("div", {
            class: "password-hint",
            style: _normalizeStyle(_ctx.passwordError ? 'background: red; color: #F9F9F9' : '')
          }, _toDisplayString(_ctx.t('password_hint')), 5),
          _createElementVNode("div", _hoisted_16, [
            _createVNode(_component_v_checkbox, {
              modelValue: _ctx.checkbox,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.checkbox) = $event)),
              color: "#3B755F",
              "hide-details": "",
              style: {"margin":"0"}
            }, null, 8, ["modelValue"]),
            _createElementVNode("div", _hoisted_17, [
              _cache[13] || (_cache[13] = _createTextVNode(" Accept our ")),
              _createElementVNode("span", {
                class: "link",
                onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.openUrl('terms')))
              }, "Terms & Conditions"),
              _cache[14] || (_cache[14] = _createTextVNode(" & ")),
              _createElementVNode("span", {
                class: "link",
                onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.openUrl('privacy')))
              }, "Privacy Policy")
            ])
          ]),
          _createElementVNode("div", _hoisted_18, [
            _createVNode(_component_gs_button, {
              disabled: !_ctx.buttonActive || _ctx.loading,
              loading: _ctx.loading,
              "full-width": true,
              size: "large",
              onClick: _withModifiers(_ctx.signup, ["prevent"])
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('continue')), 1)
              ]),
              _: 1
            }, 8, ["disabled", "loading", "onClick"])
          ]),
          (_ctx.showError)
            ? (_openBlock(), _createElementBlock("div", _hoisted_19, _toDisplayString(_ctx.errorMessage), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.pageLoading)
          ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}