import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "promo-code-wrapper" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = {
  key: 1,
  class: "promo-code-pill"
}
const _hoisted_4 = { class: "promo-code-dialog" }
const _hoisted_5 = { class: "dialog-header" }
const _hoisted_6 = { class: "dialog-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_gs_input = _resolveComponent("gs-input")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "add-promo-code",
      disabled: _ctx.hasValidPromoCode,
      onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.isDialogOpen = true), ["prevent"]))
    }, [
      _createElementVNode("span", null, _toDisplayString(_ctx.t('add_promo_code')), 1),
      (!_ctx.hasValidPromoCode)
        ? (_openBlock(), _createBlock(_component_v_icon, {
            key: 0,
            style: {"color":"inherit"}
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode(" mdi-plus ")
            ])),
            _: 1
          }))
        : (_openBlock(), _createElementBlock("span", _hoisted_3, [
            _createVNode(_component_v_icon, null, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode("mdi-ticket-percent-outline")
              ])),
              _: 1
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.typedInPromoCode) + " ", 1),
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.clearPromoCode && _ctx.clearPromoCode(...args)), ["prevent","stop"]))
            }, [
              _createVNode(_component_v_icon, null, {
                default: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createTextVNode("mdi-close")
                ])),
                _: 1
              })
            ])
          ]))
    ], 8, _hoisted_2),
    (_ctx.notification)
      ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          class: _normalizeClass(['notification-text', { invalid: !_ctx.isValid }])
        }, _toDisplayString(_ctx.t(_ctx.snakeCase(_ctx.notification))), 3))
      : _createCommentVNode("", true),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.isDialogOpen,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.isDialogOpen) = $event)),
      "max-width": "500",
      fullscreen: _ctx.isMobile
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.t('dialog_title')), 1),
            _createElementVNode("button", {
              class: "dialog-close-button",
              onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.isDialogOpen = false), ["prevent"]))
            }, [
              _createVNode(_component_v_icon, {
                style: {"color":"inherit","font-size":"inherit","line-height":"inherit"},
                variant: "plain"
              }, {
                default: _withCtx(() => _cache[9] || (_cache[9] = [
                  _createTextVNode(" mdi-close ")
                ])),
                _: 1
              })
            ])
          ]),
          _createVNode(_component_v_form, {
            modelValue: _ctx.isFormValid,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isFormValid) = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_gs_input, {
                modelValue: _ctx.typedInPromoCode,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.typedInPromoCode) = $event)),
                label: _ctx.t('input_label'),
                class: "promo-input",
                rules: [_ctx.rules.maxlength(_ctx.maxLength), _ctx.rules.minlength(_ctx.minLength), _ctx.rules.required],
                "hide-details": false
              }, null, 8, ["modelValue", "label", "rules"]),
              _createVNode(_component_gs_button, {
                class: "mt-2",
                "full-width": "",
                size: "large",
                disabled: !_ctx.isFormValid || _ctx.loading,
                loading: _ctx.loading,
                onClick: _withModifiers(_ctx.validatePromoCode, ["prevent"])
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('button_text')), 1)
                ]),
                _: 1
              }, 8, ["disabled", "loading", "onClick"])
            ]),
            _: 1
          }, 8, ["modelValue"])
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "fullscreen"])
  ]))
}