import type {
  CreateWidgetEditorSettingsRequestBody,
  UpdateWidgetEditorSettingsRequestBody,
  WidgetEditorSettings,
} from '@api/index'
import {
  createWidgetEditorSettings,
  fetchWidgetEditorSettings,
  updateWidgetEditorSettings,
} from '@api/index'
import type { ActionContext } from 'vuex'
import type { StateInterface } from '@/store/index'
import VueI18n from '@/i18n'
import type { Notification } from '@/store/notification'

interface WidgetState {
  widgetEditorSettings: WidgetEditorSettings[]
}

export const widgetModule = {
  state: () =>
    ({
      widgetEditorSettings: [],
    } as WidgetState),
  mutations: {
    setWidgetEditorSettings(state: WidgetState, widgetEditorSettings: WidgetEditorSettings[]) {
      state.widgetEditorSettings = widgetEditorSettings
    },
  },
  actions: {
    async setWidgetEditorSettings({
      commit,
      dispatch,
    }: ActionContext<WidgetState, StateInterface>) {
      try {
        const { data } = await fetchWidgetEditorSettings()
        commit('setWidgetEditorSettings', data)
      } catch (e) {
        console.error(e)
        await dispatch(
          'notification/notify',
          {
            text: VueI18n.global.t('CommonUi.error_generic'),
            isError: true,
            isClosable: true,
            buttonText: 'close',
          } as Notification,
          { root: true },
        )
      }
    },
    async createWidgetEditorSettings(
      { dispatch }: ActionContext<WidgetState, StateInterface>,
      body: CreateWidgetEditorSettingsRequestBody,
    ) {
      try {
        await createWidgetEditorSettings(body)
        await dispatch('setWidgetEditorSettings')
      } catch (e) {
        console.error(e)
        await dispatch(
          'notification/notify',
          {
            text: VueI18n.global.t('CommonUi.error_generic'),
            isError: true,
            isClosable: true,
            buttonText: 'close',
          } as Notification,
          { root: true },
        )
        throw e
      }
    },
    async updateWidgetEditorSettings(
      { dispatch }: ActionContext<WidgetState, StateInterface>,
      {
        widgetId,
        status,
        withPopup,
        popupTheme,
      }: { widgetId: string } & UpdateWidgetEditorSettingsRequestBody,
    ) {
      try {
        await updateWidgetEditorSettings(widgetId, {
          status,
          withPopup,
          popupTheme,
        })
        await dispatch('setWidgetEditorSettings')
      } catch (e) {
        console.error(e)
        await dispatch(
          'notification/notify',
          {
            text: VueI18n.global.t('CommonUi.error_generic'),
            isError: true,
            isClosable: true,
            buttonText: 'close',
          } as Notification,
          { root: true },
        )
        throw e
      }
    },
  },
  getters: {
    getWidgetEditorSettingsByType:
      (state: WidgetState) =>
      (type: string, color: string, style: string): WidgetEditorSettings | undefined => {
        return state.widgetEditorSettings.find(
          (setting) => setting.type === type && setting.color === color && setting.style === style,
        )
      },
  },
}
