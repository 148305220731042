import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "loading-wrapper" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "progress-bar-wrapper" }
const _hoisted_4 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.hideWindmill)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "loading-logo",
          alt: "Loading ...",
          src: require('@/assets/icons/main-shape.svg')
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_v_progress_linear, {
        color: "#3B755F",
        indeterminate: "",
        rounded: "",
        height: "6"
      })
    ]),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.text), 1)
  ]))
}