import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "add-impact" }
const _hoisted_2 = { class: "total" }
const _hoisted_3 = { class: "button-wrapper" }
const _hoisted_4 = {
  key: 0,
  class: "note"
}
const _hoisted_5 = {
  key: 1,
  class: "error-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImpactSettings = _resolveComponent("ImpactSettings")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_loading = _resolveComponent("loading")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ImpactSettings, {
          "model-value": _ctx.impactSetting,
          "onUpdate:modelValue": _ctx.onImpactSettingChange
        }, null, 8, ["model-value", "onUpdate:modelValue"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", {
          class: _normalizeClass([{ 'invalid-selection': _ctx.total < 0 }])
        }, _toDisplayString(_ctx.t('total')) + " " + _toDisplayString(_ctx.total), 3)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_gs_button, {
          disabled: _ctx.isDisabled,
          loading: _ctx.loading,
          "full-width": true,
          size: "large",
          onClick: _withModifiers(_ctx.goHome, ["prevent"])
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('continue')), 1)
          ]),
          _: 1
        }, 8, ["disabled", "loading", "onClick"])
      ]),
      (_ctx.isDisabled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.t('note')), 1))
        : _createCommentVNode("", true),
      (_ctx.error)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.t('error')), 1))
        : _createCommentVNode("", true)
    ]),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_loading, { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}