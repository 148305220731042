import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "carbon-footprint" }
const _hoisted_2 = { class: "carbon-footprint__title" }
const _hoisted_3 = { class: "carbon-footprint__description" }
const _hoisted_4 = ["src", "height"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_carbon_footprint_introduction = _resolveComponent("carbon-footprint-introduction")!
  const _component_connected_bank_account_list = _resolveComponent("connected-bank-account-list")!
  const _component_loading = _resolveComponent("loading")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.t('title')), 1),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.t('description')), 1),
    (!_ctx.getIfAnyBankAccountIsConnected)
      ? (_openBlock(), _createBlock(_component_carbon_footprint_introduction, { key: 0 }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_connected_bank_account_list),
          (_ctx.sessionId)
            ? (_openBlock(), _createElementBlock("iframe", {
                key: 0,
                id: "reportIframe",
                src: _ctx.srcUrl,
                title: "Connect Report",
                height: _ctx.iframeHeight
              }, null, 8, _hoisted_4))
            : _createCommentVNode("", true)
        ], 64)),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_loading, { key: 2 }))
      : _createCommentVNode("", true)
  ]))
}