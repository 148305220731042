import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "project-info" }
const _hoisted_2 = { class: "project-title" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "description-wrapper" }
const _hoisted_5 = { class: "project-summary" }
const _hoisted_6 = { class: "project-description" }
const _hoisted_7 = { class: "card-footer" }
const _hoisted_8 = {
  key: 0,
  class: "project-locations"
}
const _hoisted_9 = { class: "location-name" }
const _hoisted_10 = ["href"]
const _hoisted_11 = { class: "card-image-wrapper" }
const _hoisted_12 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PublicMapPin = _resolveComponent("PublicMapPin")!

  return (_openBlock(), _createElementBlock("article", {
    class: _normalizeClass(['project-details-card', _ctx.impactType, { expanded: _ctx.headerExpanded }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("header", null, [
        _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.title), 1),
        (_ctx.impact?.amount)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass(['project-card-impact-values', { deprecated: _ctx.project.status === 'deprecated' }]),
              style: _normalizeStyle({
            backgroundColor:
              _ctx.impact && _ctx.project.status !== 'deprecated'
                ? _ctx.colorSettings[_ctx.project.type]
                : _ctx.getCSSVariable('gray'),
          })
            }, [
              (_ctx.project.status === 'deprecated')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString(_ctx.$t('Projects.deprecated')), 1)
                  ], 64))
                : _createCommentVNode("", true),
              (_ctx.project.status === 'deprecated' && _ctx.impact)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, " - "))
                : _createCommentVNode("", true),
              (_ctx.impact)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                    _createTextVNode(_toDisplayString(_ctx.$t(`Projects.${_ctx.impact.type}`, {
                count: _ctx.impact.amount,
              })), 1)
                  ], 64))
                : _createCommentVNode("", true)
            ], 6))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t(`Projects.${_ctx.project.projectId}.summary`)), 1),
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t(`Projects.${_ctx.project.projectId}.description`)), 1),
        _cache[0] || (_cache[0] = _createElementVNode("hr", null, null, -1)),
        _createElementVNode("footer", _hoisted_7, [
          (_ctx.location)
            ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                _createVNode(_component_PublicMapPin, {
                  class: "location-pin",
                  pin: _ctx.locationPin,
                  "is-active": "",
                  "is-static": "",
                  "is-animated": false
                }, null, 8, ["pin"]),
                _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.location), 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("a", {
            href: _ctx.project.link,
            target: "_blank",
            class: "project-cta"
          }, _toDisplayString(_ctx.t('read_more')), 9, _hoisted_10)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("img", {
        src: _ctx.imageUrl,
        alt: _ctx.impactType + '_image',
        class: "card-image"
      }, null, 8, _hoisted_12)
    ])
  ], 2))
}