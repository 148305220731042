import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "user-component" }
const _hoisted_2 = { class: "user-component-wrapper" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "user-wrapper" }
const _hoisted_5 = { class: "form-wrapper" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "header" }
const _hoisted_8 = { class: "subtext" }
const _hoisted_9 = { class: "how-it-works-wrapper" }
const _hoisted_10 = { class: "how-it-works-header" }
const _hoisted_11 = { class: "number-text-wrapper" }
const _hoisted_12 = { class: "text" }
const _hoisted_13 = { class: "number-text-wrapper" }
const _hoisted_14 = { class: "text" }
const _hoisted_15 = { class: "number-text-wrapper" }
const _hoisted_16 = { class: "text" }
const _hoisted_17 = { class: "button-wrapper" }
const _hoisted_18 = {
  key: 0,
  class: "error-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gs_button = _resolveComponent("gs-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "user-image",
        src: require('@/assets/create-account-shape.jpg'),
        alt: "signup"
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("img", {
            class: "logo",
            src: require('@/assets/greenspark-logo.svg'),
            alt: "greenspark-app"
          }, null, 8, _hoisted_6),
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.t('welcome')), 1),
          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.t('subtext')), 1),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.t('how_it_works')), 1),
            _createElementVNode("div", _hoisted_11, [
              _cache[1] || (_cache[1] = _createElementVNode("div", { class: "number" }, "1", -1)),
              _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.t('one')), 1)
            ]),
            _createElementVNode("div", _hoisted_13, [
              _cache[2] || (_cache[2] = _createElementVNode("div", { class: "number" }, "2", -1)),
              _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.t('two')), 1)
            ]),
            _createElementVNode("div", _hoisted_15, [
              _cache[3] || (_cache[3] = _createElementVNode("div", { class: "number" }, "3", -1)),
              _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.t('three')), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_17, [
            (_ctx.error)
              ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                  _createTextVNode(_toDisplayString(_ctx.t('error')) + " ", 1),
                  _createElementVNode("span", {
                    class: "login",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/login')))
                  }, "Login")
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_gs_button, {
              disabled: _ctx.error,
              "full-width": true,
              size: "large",
              onClick: _withModifiers(_ctx.next, ["prevent"])
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('continue')), 1)
              ]),
              _: 1
            }, 8, ["disabled", "onClick"])
          ])
        ])
      ])
    ])
  ]))
}