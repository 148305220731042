<template>
  <svg fill="none" height="17" viewBox="0 0 15 17" width="15" xmlns="http://www.w3.org/2000/svg">
    <rect fill="#F9F9F9" height="3" width="7" x="4" y="8" />
    <path d="M4 7C4 5.067 5.567 3.5 7.5 3.5C9.433 3.5 11 5.067 11 7V7.5H4V7Z" fill="#F9F9F9" />
    <path
      d="M4 11.5H11V12C11 13.933 9.433 15.5 7.5 15.5C5.567 15.5 4 13.933 4 12V11.5Z"
      fill="#F9F9F9"
    />
    <path d="M6 2.5C6 1.67157 6.67157 1 7.5 1C8.32843 1 9 1.67157 9 2.5V3H6V2.5Z" fill="#F9F9F9" />
  </svg>
</template>
