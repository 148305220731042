<template>
  <div class="upgrade-wrapper">
    <CloseNavigation />
    <section class="pricing-page">
      <div class="intro-section">
        <h1>
          {{ getAccountTypeIsBusiness ? t('upgrade_title_business') : t('upgrade_title_personal') }}
        </h1>
        <p>
          {{ getAccountTypeIsBusiness ? t('upgrade_text_business') : t('upgrade_text_personal') }}
        </p>
      </div>

      <div v-if="!hideYearlyToggle && getAccountTypeIsBusiness" class="billing-period-selection">
        <span :class="{ active: !isSubscriptionYearly }">{{ t('monthly') }}</span>
        <v-switch v-model="isSubscriptionYearly" color="#3B755F" inset hide-details />
        <span :class="{ active: isSubscriptionYearly }">{{ t('yearly') }}</span>
        <span class="free-period-label" v-html="t('months_free', { amount: 3 })" />
      </div>

      <div class="upgrade-card-list-container">
        <upgrade-card-list
          :is-business="getAccountTypeIsBusiness"
          :is-subscription-yearly="isSubscriptionYearly"
          v-model:path-to-navigate="pathToNavigate"
          @turnOffYearlyToggle="turnOffYearlyToggle"
        />
      </div>

      <v-container class="container">
        <template v-if="getAccountTypeIsBusiness">
          <button class="compare-plans-cta" @click="scrollToTable">
            {{ t('compare_plan_features') }}
            <v-icon color="#0017B4" size="22px">mdi-arrow-down</v-icon>
          </button>

          <EnterprisePlanCard />

          <section class="benefits-section">
            <h3>{{ t('enjoy_paid_plan_benefits') }}</h3>
            <ul>
              <li>
                <v-icon color="#3B755F" class="benefit-icon" size="32px">mdi-hand-heart</v-icon>
                <h5>{{ t('positive_impact') }}</h5>
                <p>{{ t('support_verified_projects') }}</p>
              </li>
              <li>
                <v-icon color="#3B755F" class="benefit-icon" size="32px">
                  mdi-monitor-cellphone-star
                </v-icon>
                <h5>{{ t('private_dashboard') }}</h5>
                <p>{{ t('track_real_time_impact') }}</p>
              </li>
              <li>
                <img
                  class="benefit-icon"
                  :src="require('@/assets/icons/device-hub.svg')"
                  :alt="t('integration_with_automation')"
                />
                <h5>{{ t('integration_with_automation') }}</h5>
                <p>{{ t('easily_integrate_sustainability') }}</p>
              </li>
              <li>
                <img
                  class="benefit-icon"
                  :src="require('@/assets/icons/plant-line.svg')"
                  :alt="t('bonus_tree_planting')"
                />
                <h5>{{ t('bonus_tree_planting') }}</h5>
                <p>{{ t('well_plant_tress') }}</p>
              </li>
            </ul>
          </section>

          <transition name="fade">
            <PlanComparisonTable id="comparison-table" class="mb-5"></PlanComparisonTable>
          </transition>

          <ImpactPricingComparisonTable
            id="impact-pricing-table"
            class="mb-5"
          ></ImpactPricingComparisonTable>

          <h4 class="section-cta-text">
            {{ t('have_more_questions') }} <br />
            <span class="link" @click="() => openChat()">{{ t('get_in_touch') }}</span>
            {{ $t('or') }}
            <a href="http://getgreenspark.com/book-a-demo" target="_blank" class="link">{{
              t('book_a_demo')
            }}</a>
          </h4>
        </template>

        <FrequentlyAskedQuestions :is-business="getAccountTypeIsBusiness" />
      </v-container>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import type { CurrencyCode } from '@/helpers/interfaces'
import { LoadCrispMixin } from '@/helpers/mixins/loadCrispMixin'
import { Utils } from '@/helpers/mixins/utilsMixin'
import { SetUpSocialMediaTracking } from '@/helpers/mixins/setupSocialMediaTracking'
import type { Account } from '@/store'
import type { SubscriptionItem } from '@/store/subscriptions'

import CloseNavigation from '@/components/tools/CloseNavigation.vue'
import FrequentlyAskedQuestions from '@/components/checkout/FrequentlyAskedQuestions.vue'
import UpgradeCardList from '@/components/checkout/UpgradeCardList.vue'
import PlanComparisonTable from '@/components/register/PlanComparisonTable.vue'
import ImpactPricingComparisonTable from '@/components/register/ImpactPricingComparisonTable.vue'
import EnterprisePlanCard from '@/components/checkout/EnterprisePlanCard.vue'

export default defineComponent({
  name: 'SelectPlanView',
  components: {
    EnterprisePlanCard,
    UpgradeCardList,
    FrequentlyAskedQuestions,
    CloseNavigation,
    PlanComparisonTable,
    ImpactPricingComparisonTable,
  },
  mixins: [LoadCrispMixin, SetUpSocialMediaTracking, Utils],
  data() {
    return {
      dialog: true,
      isSubscriptionYearly: true,
      hideYearlyToggle: false,
    }
  },
  computed: {
    pathToNavigate(): string {
      if (this.$route.path === '/select-plan' && !this.authUserId) return '/e/signup'
      else return '/checkout'
    },
    showFreePlanCard(): boolean {
      return (
        this.$route.path === '/select-plan' ||
        this.$route.path === '/finish-subscription' ||
        this.$route.name === 'DirectMarketplaceSignup' ||
        this.getActiveSubscriptionItem?.product === 'freeBusiness'
      )
    },
    authUserId(): string {
      return this.$store.getters['getAuthUserId']
    },
    userCurrencyCode(): CurrencyCode {
      return this.$store.getters['getUserCurrencyCode']
    },
    getAccountTypeIsBusiness(): boolean {
      return this.$store.getters['getAccountTypeIsBusiness']
    },
    getActiveSubscriptionItem(): SubscriptionItem {
      return this.$store.getters['getActiveSubscriptionItem']
    },
  },
  async created() {
    await this.setProjectList()
    this.setUpFacebookTracking('ViewContent')
    try {
      const location = await this.getLocation()
      this.setAccount({
        currency: this.account?.userFromMarketplace
          ? 'dollar'
          : this.getCurrencyByLocation(location),
      })
    } catch (error) {
      this.setAccount({
        currency: this.account?.userFromMarketplace ? 'dollar' : this.getCurrencyByLocation(''),
      })
      console.error('geolocation', error)
    }
  },
  methods: {
    t(key: string, params?: { [k: string]: string | number }) {
      return this.$t(`SelectPlanView.${key}`, params ?? {})
    },
    scrollToTable() {
      const table = document.getElementById('comparison-table')
      if (table) table.scrollIntoView({ behavior: 'smooth' })
    },
    turnOffYearlyToggle() {
      this.isSubscriptionYearly = true
      this.hideYearlyToggle = true
    },
    setProjectList(): Promise<void> {
      return this.$store.dispatch('setProjectList')
    },
    setAccount(account: Partial<Account>): void {
      return this.$store.commit('setAccount', account)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

html {
  scroll-behavior: smooth;
}

.pricing-page {
  color: var(--font-color-primary);
  width: 100%;
  height: auto;
  min-height: 100vh;
  background-color: var(--gray-light-F1);
  text-align: center;

  .container {
    max-width: 1200px;
  }
}

.upgrade-card-list-container {
  max-width: 1200px;
  margin: 0 auto;
}

.intro-section {
  padding-top: 48px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 26px;
  margin-bottom: 48px;
  padding: 0 16px;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    padding-top: 88px;
    margin-bottom: 64px;
  }

  h1 {
    font-size: 36px;
    font-weight: 700;
    max-width: 766px;
    line-height: normal;
    margin-bottom: 0;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      font-size: 48px;
    }
  }

  p {
    color: var(--colour-grey-800, #323232);
    max-width: 964px;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    margin-bottom: 0;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      font-size: 24px;
    }
  }
}

.section-cta-text {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 48px;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    font-size: 32px;
  }

  br {
    display: initial;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      display: none;
    }
  }

  .link {
    color: var(--ui-dark-blue);
    font-style: normal;
    text-decoration-line: underline;
    text-decoration-style: solid;
    cursor: pointer;
  }
}

.compare-plans-cta {
  display: block;
  color: var(--ui-dark-blue);
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
  text-decoration-style: solid;
  margin: 0 auto 48px;
}

.benefits-section {
  margin-bottom: 48px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    margin-bottom: 64px;
  }

  h3 {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 24px;
    padding: 0 8px;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      font-size: 32px;
      margin-bottom: 32px;
      padding: 0;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 32px;
    list-style: none;
    text-align: center;
    padding: 0 32px;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      text-align: left;
      gap: 20px;
      padding: 0;
    }

    li {
      .benefit-icon {
        color: var(--ui-green);
        stroke: var(--ui-green);
        margin-bottom: 12px;
        width: 32px;
        height: 32px;
      }

      h5 {
        color: var(--ui-green);
        font-size: 20px;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 8px;
      }

      p {
        font-size: 16px;
        line-height: normal;
        margin-bottom: 0;
      }
    }
  }
}

.billing-period-selection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 48px;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    margin-bottom: 64px;
  }

  span {
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      font-size: 32px;
    }
    &.active {
      font-weight: 700;
    }
  }

  .free-period-label {
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: var(--ui-emerald-green);
    font-size: 16px;
    font-weight: 400;
    line-height: normal;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      font-size: 20px;
    }
  }
}

.upgrade-text-wrapper {
  text-align: center;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
}

.input-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;

  .v-switch {
    min-width: 65px;
  }
}

.label {
  cursor: pointer;
  font-size: 18px;
}

.label:not(:last-of-type) {
  margin-right: 15px;
}

.label-info {
  color: var(--ui-green);
  font-weight: 700;
}

.upgrade-wrapper {
  width: 100%;
  height: 100%;
  z-index: 4;
  min-height: 100vh;
}

.upgrade-title {
  font-size: 40px;
  line-height: 48px;
  margin-bottom: 20px;
  max-width: 18ch;
}

.upgrade-text {
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 20px;
  max-width: 45ch;
}

.comparison-button-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.arrow-icon {
  color: inherit;
  font-size: 20px;
}

.animated-link {
  margin-bottom: 70px;
  font-weight: 600;
}

.demo-link-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  border-radius: var(--border-radius-big);
  box-shadow: var(--box-shadow-wide);
  margin: 0 auto 100px;
  padding: 40px;
  gap: 20px;
  width: fit-content;
}

.demo-link-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 10px;
  text-align: center;
}

.demo-link-description {
  font-size: 16px;
  margin-bottom: 0;
  max-width: 25ch;
  text-align: center;
}

.demo-link {
  background-color: var(--gray-light-20);
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  color: var(--font-color-primary);
  text-decoration: none;
  transition: all linear 0.2s;
}

.demo-link:hover,
.demo-link:focus {
  color: white;
  box-shadow: inset 200px 0 0 0 var(--ui-green);
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .demo-link-wrapper {
    flex-direction: row;
  }

  .demo-link-title {
    text-align: left;
  }

  .demo-link-description {
    text-align: left;
  }

  .label {
    font-size: 32px;
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .upgrade-title {
    font-size: 48px;
    line-height: 52px;
    margin-bottom: 40px;
  }

  .features-table {
    margin: 0 auto 80px;
    max-width: 1220px;
  }

  .label {
    text-align: left;
    width: 100%;
  }

  .label:not(:last-of-type) {
    text-align: right;
  }
}
</style>
