import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "select-projects" }
const _hoisted_2 = { class: "select-project-wrapper" }
const _hoisted_3 = { class: "label-wrapper" }
const _hoisted_4 = { class: "label choose" }
const _hoisted_5 = { class: "description" }
const _hoisted_6 = { class: "label-wrapper with-help-text" }
const _hoisted_7 = { class: "label" }
const _hoisted_8 = { class: "help-text" }
const _hoisted_9 = {
  key: 0,
  class: "additional-impact-setting"
}
const _hoisted_10 = { class: "label-wrapper" }
const _hoisted_11 = { class: "label choose" }
const _hoisted_12 = { class: "description" }
const _hoisted_13 = { class: "input-wrapper" }
const _hoisted_14 = { class: "schedule-picker" }
const _hoisted_15 = { class: "label-wrapper with-help-text mb-6" }
const _hoisted_16 = { class: "label choose" }
const _hoisted_17 = { class: "description" }
const _hoisted_18 = {
  key: 1,
  class: "by-percentage-of-revenue-disclaimer"
}
const _hoisted_19 = ["innerHTML"]
const _hoisted_20 = {
  key: 2,
  class: "totals-wrapper"
}
const _hoisted_21 = {
  key: 0,
  class: "cart-items-text"
}
const _hoisted_22 = {
  key: 1,
  class: "total-text"
}
const _hoisted_23 = {
  key: 3,
  class: "zero-value-disclaimer"
}
const _hoisted_24 = {
  key: 3,
  class: "per-selected-answer-notification-wrapper"
}
const _hoisted_25 = { class: "form-controls" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_step_summary = _resolveComponent("step-summary")!
  const _component_ImpactSettings = _resolveComponent("ImpactSettings")!
  const _component_RuleTable = _resolveComponent("RuleTable")!
  const _component_ImpactSettingsPerQuestion = _resolveComponent("ImpactSettingsPerQuestion")!
  const _component_ProductTable = _resolveComponent("ProductTable")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_gs_input = _resolveComponent("gs-input")!
  const _component_DateTimePicker = _resolveComponent("DateTimePicker")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_PerSelectedAnswerPostPurchaseEmailNotification = _resolveComponent("PerSelectedAnswerPostPurchaseEmailNotification")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_step_summary, {
      label: _ctx.t('integration'),
      "previous-answers": [_ctx.platformName ?? '', _ctx.selectedIntegration?.name ?? '']
    }, null, 8, ["label", "previous-answers"]),
    _createVNode(_component_step_summary, {
      label: _ctx.t('automation'),
      "previous-answers": [_ctx.automationName ?? '']
    }, null, 8, ["label", "previous-answers"]),
    _createVNode(_component_step_summary, {
      label: _ctx.t('trigger'),
      "previous-answers": [
        _ctx.t(`${_ctx.snakeCase(_ctx.selectedTrigger ?? '')}.title`),
        _ctx.isFormIntegration ? _ctx.formImpactPillContent : '',
      ]
    }, null, 8, ["label", "previous-answers"]),
    _createVNode(_component_v_form, {
      modelValue: _ctx.isFormValid,
      "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.isFormValid) = $event))
    }, {
      default: _withCtx(() => [
        _createElementVNode("section", _hoisted_2, [
          (_ctx.selectedTrigger !== 'perSelectedAnswer')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.t(`${_ctx.snakeCase(_ctx.selectedTrigger ?? '')}.impact_header_title`)), 1),
                  _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.t(`${_ctx.snakeCase(_ctx.selectedTrigger ?? '')}.impact_header_description`)), 1)
                ]),
                (_ctx.selectedTrigger !== 'offsetPerProduct' && _ctx.selectedTrigger !== 'byLoyaltyPoints')
                  ? (_openBlock(), _createBlock(_component_ImpactSettings, {
                      key: 0,
                      modelValue: _ctx.impactSetting,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.impactSetting) = $event)),
                      "single-selection": _ctx.isPercentageBasedImpact,
                      onOnValidity: _ctx.setImpactSettingValidity
                    }, null, 8, ["modelValue", "single-selection", "onOnValidity"]))
                  : _createCommentVNode("", true),
                (_ctx.selectedTrigger === 'byLoyaltyPoints')
                  ? (_openBlock(), _createBlock(_component_RuleTable, {
                      key: 1,
                      "integration-id": _ctx.selectedIntegration?.id,
                      platform: _ctx.selectedIntegration?.platform,
                      onSave: _ctx.onImpactSettingsRuleSave
                    }, null, 8, ["integration-id", "platform", "onSave"]))
                  : _createCommentVNode("", true)
              ], 64))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.t('impact_per_question_title')), 1)
                ]),
                _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.t('impact_per_question_description')), 1),
                (_ctx.formQuestion)
                  ? (_openBlock(), _createBlock(_component_ImpactSettingsPerQuestion, {
                      key: 0,
                      "question-data": _ctx.formQuestion,
                      onSave: _ctx.onImpactSettingsPerQuestionSave
                    }, null, 8, ["question-data", "onSave"]))
                  : _createCommentVNode("", true)
              ], 64)),
          (_ctx.selectedTrigger === 'offsetPerProduct')
            ? _withDirectives((_openBlock(), _createBlock(_component_ProductTable, {
                key: 2,
                ref: "productTable",
                "integration-id": _ctx.selectedIntegration?.id,
                "selected-products": _ctx.selectedProducts,
                onSelectItems: _cache[1] || (_cache[1] = (items) => (_ctx.selectedProducts = items)),
                onSave: _ctx.onImpactSettingsProductSave
              }, null, 8, ["integration-id", "selected-products", "onSave"])), [
                [_vShow, _ctx.showProductsTable]
              ])
            : _createCommentVNode("", true)
        ]),
        (_ctx.isAdditionalSettingNeeded)
          ? (_openBlock(), _createElementBlock("section", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.t(`${_ctx.snakeCase(_ctx.selectedTrigger ?? '')}.label`)), 1),
                _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.t(`${_ctx.snakeCase(_ctx.selectedTrigger ?? '')}.tooltip`)), 1)
              ]),
              _createElementVNode("div", _hoisted_13, [
                (_ctx.showCurrencySettingSelect)
                  ? (_openBlock(), _createBlock(_component_v_select, {
                      key: 0,
                      modelValue: _ctx.additionalImpactSetting.currencyCode,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.additionalImpactSetting.currencyCode) = $event)),
                      items: _ctx.availableCurrencies,
                      variant: "outlined",
                      color: "green",
                      "item-color": "green",
                      class: "dropdown-select",
                      "hide-details": ""
                    }, null, 8, ["modelValue", "items"]))
                  : _createCommentVNode("", true),
                (_ctx.isPercentageBasedImpact)
                  ? (_openBlock(), _createBlock(_component_gs_input, {
                      key: 1,
                      modelValue: _ctx.additionalImpactSetting.percentage,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.additionalImpactSetting.percentage) = $event)),
                      modelModifiers: { number: true },
                      label: _ctx.additionalSettingInputLabel,
                      type: "number",
                      min: "0",
                      class: "additional-impact-setting-input",
                      rules: [_ctx.rules.positiveInteger, _ctx.rules.maxValue(100), _ctx.rules.required]
                    }, null, 8, ["modelValue", "label", "rules"]))
                  : (_openBlock(), _createBlock(_component_gs_input, {
                      key: 2,
                      modelValue: _ctx.additionalImpactSetting.amount,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.additionalImpactSetting.amount) = $event)),
                      modelModifiers: { number: true },
                      label: _ctx.additionalSettingInputLabel,
                      type: "number",
                      min: "0",
                      class: "additional-impact-setting-input",
                      rules: [_ctx.rules.positiveInteger, _ctx.rules.required]
                    }, null, 8, ["modelValue", "label", "rules"]))
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("section", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.t('schedule')), 1),
            _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.t('schedule_tooltip')), 1)
          ]),
          _createVNode(_component_DateTimePicker, {
            modelValue: _ctx.schedule.startDate,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.schedule.startDate) = $event)),
            "min-value": _ctx.initialDate,
            class: "mb-6",
            clearable: false,
            "max-width": 450
          }, null, 8, ["modelValue", "min-value"]),
          _cache[14] || (_cache[14] = _createElementVNode("p", { class: "mb-1" }, "End date", -1)),
          _createVNode(_component_v_checkbox, {
            id: "checkbox-datetime",
            modelValue: _ctx.isEndDateVisible,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.isEndDateVisible) = $event)),
            name: "checkbox-datetime",
            inline: "",
            "hide-details": "",
            label: "Set an end date"
          }, null, 8, ["modelValue"]),
          (_ctx.isEndDateVisible)
            ? (_openBlock(), _createBlock(_component_DateTimePicker, {
                key: 0,
                "model-value": _ctx.schedule.endDate ?? new Date(_ctx.schedule.startDate).toISOString(),
                "onUpdate:modelValue": _cache[7] || (_cache[7] = (v) => (_ctx.schedule.endDate = v)),
                "min-value": new Date(_ctx.schedule.startDate).toISOString(),
                error: !_ctx.isScheduleValid,
                "max-width": 450,
                onFocus: _cache[8] || (_cache[8] = ($event: any) => (_ctx.isScheduleValid = true)),
                clearable: false
              }, null, 8, ["model-value", "min-value", "error"]))
            : _createCommentVNode("", true)
        ]),
        (_ctx.showByPercentageOfRevenueDisclaimer)
          ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
              _createVNode(_component_v_icon, {
                class: "info-icon",
                size: "24px"
              }, {
                default: _withCtx(() => _cache[15] || (_cache[15] = [
                  _createTextVNode("mdi-information-outline")
                ])),
                _: 1
              }),
              _createElementVNode("p", {
                innerHTML: _ctx.t('by_percentage_of_revenue_disclaimer')
              }, null, 8, _hoisted_19)
            ]))
          : _createCommentVNode("", true),
        (_ctx.selectedTrigger !== 'offsetPerProduct' && _ctx.selectedTrigger !== 'byLoyaltyPoints')
          ? (_openBlock(), _createElementBlock("section", _hoisted_20, [
              (!!_ctx.cartText)
                ? (_openBlock(), _createElementBlock("p", _hoisted_21, _toDisplayString(_ctx.cartText), 1))
                : _createCommentVNode("", true),
              (_ctx.isTotalTextNeeded)
                ? (_openBlock(), _createElementBlock("p", _hoisted_22, _toDisplayString(_ctx.t(`${_ctx.snakeCase(_ctx.selectedTrigger ?? '')}.total`, {
              spend_level: `${_ctx.getCurrencySymbol(_ctx.additionalImpactSetting.currencyCode || 'USD')}${
                _ctx.additionalImpactSetting.amount || _ctx.additionalImpactSetting.percentage || 0
              }`,
              price: `${_ctx.userCurrencySymbol}${_ctx.total} ${_ctx.getUserCurrencyCode}`,
            })), 1))
                : _createCommentVNode("", true),
              (_ctx.isTotalTextNeeded && _ctx.isFreeSubscription)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 2,
                    class: "total-text paid-plan",
                    onClick: _cache[9] || (_cache[9] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.openPriceComparisonPopup && _ctx.openPriceComparisonPopup(...args)), ["prevent"]))
                  }, _toDisplayString(_ctx.t(`${_ctx.snakeCase(_ctx.selectedTrigger ?? '')}.total_paid_plan`, {
              spend_level: `${_ctx.getCurrencySymbol(_ctx.additionalImpactSetting.currencyCode || 'USD')}${
                _ctx.additionalImpactSetting.amount || _ctx.additionalImpactSetting.percentage || 0
              }`,
              price: `${_ctx.userCurrencySymbol}${_ctx.totalPaidPlan} ${_ctx.getUserCurrencyCode}`,
            })), 1))
                : _createCommentVNode("", true),
              (_ctx.showZeroValueTriggerDisclaimer)
                ? (_openBlock(), _createElementBlock("p", _hoisted_23, [
                    _createTextVNode(_toDisplayString(_ctx.t('zero_value_trigger_disclaimer_part_1', {
              currency_symbol: _ctx.getCurrencySymbol(_ctx.additionalImpactSetting.currencyCode || 'USD'),
            })) + " ", 1),
                    _createElementVNode("a", {
                      href: "",
                      onClick: _cache[10] || (_cache[10] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onChangeTriggerToSpendThreshold && _ctx.onChangeTriggerToSpendThreshold(...args)), ["prevent"]))
                    }, _toDisplayString(_ctx.t('spend_threshold_trigger')), 1),
                    _createTextVNode(" " + _toDisplayString(_ctx.t('zero_value_trigger_disclaimer_part_2')), 1)
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (_ctx.selectedTrigger === 'perSelectedAnswer')
          ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
              _createVNode(_component_PerSelectedAnswerPostPurchaseEmailNotification)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_25, [
          _createVNode(_component_gs_button, {
            type: "outlined",
            size: "large",
            class: "form-control",
            icon: "mdi-chevron-left",
            onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.$emit('back')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('CommonUi.back')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_gs_button, {
            "full-width": "",
            type: "primary",
            size: "large",
            class: "form-control",
            loading: _ctx.loading,
            disabled: _ctx.isButtonDisabled || !_ctx.isFormValid || !_ctx.isImpactSettingValid || _ctx.loading,
            onClick: _withModifiers(_ctx.handleSaveClick, ["prevent"])
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.saveButtonText), 1)
            ]),
            _: 1
          }, 8, ["loading", "disabled", "onClick"])
        ]),
        (!_ctx.isAPIKeyValid)
          ? (_openBlock(), _createElementBlock("p", {
              key: 4,
              class: "error-alert",
              onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.handleIntegrationUpdate && _ctx.handleIntegrationUpdate(...args)))
            }, _toDisplayString(_ctx.$t('CommonUi.test_connection.error.INTEGRATION_READ_DIRECTION_TEST_FORBIDDEN')), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}