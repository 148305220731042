import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "empty" }
const _hoisted_2 = { class: "empty-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "reath-left" }, null, -1)),
      _createElementVNode("div", _hoisted_2, [
        _createTextVNode(_toDisplayString(_ctx.text) + " ", 1),
        _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
        _createTextVNode(" " + _toDisplayString(_ctx.text1), 1)
      ]),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "reath-right" }, null, -1))
    ])
  ]))
}