import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "impact-by-brands-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loader_block = _resolveComponent("loader-block")!
  const _component_ImpactsByBrandsItem = _resolveComponent("ImpactsByBrandsItem")!
  const _component_v_panel = _resolveComponent("v-panel")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_loader_block, { key: 0 }))
    : (_openBlock(), _createBlock(_component_v_panel, { key: 1 }, {
        title: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('title')), 1)
        ]),
        default: _withCtx(() => [
          _createElementVNode("ul", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.impactsByBrands, (brand, index) => {
              return (_openBlock(), _createBlock(_component_ImpactsByBrandsItem, {
                key: index,
                brand: brand
              }, null, 8, ["brand"]))
            }), 128))
          ])
        ]),
        _: 1
      }))
}