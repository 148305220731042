import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "pop-up" }
const _hoisted_2 = { class: "overlay-header" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "overlay-text" }
const _hoisted_5 = { class: "button-wrapper" }
const _hoisted_6 = {
  key: 0,
  class: "cancel-error"
}
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_gs_button = _resolveComponent("gs-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_btn, {
      icon: "",
      onClick: _ctx.close,
      variant: "plain",
      class: "floating-close-button"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_icon, { color: "black" }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode(" mdi-close ")
          ])),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.t('cancel_header')), 1),
    (_ctx.shouldAllowCancel)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createTextVNode(_toDisplayString(_ctx.t('cancel_text')) + " ", 1),
            _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
            _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
            _createTextVNode(" " + _toDisplayString(_ctx.t('cancel_text1')), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_gs_button, {
              class: "button mr-2",
              type: "danger",
              size: "small",
              width: "230px",
              disabled: _ctx.dialogLoading,
              loading: _ctx.dialogLoading,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.deleteUser()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('cancel_subscription')), 1)
              ]),
              _: 1
            }, 8, ["disabled", "loading"]),
            _createVNode(_component_v_btn, {
              class: "button ml-2",
              width: "230px",
              type: "info",
              disabled: _ctx.dialogLoading,
              onClick: _ctx.close
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('back')), 1)
              ]),
              _: 1
            }, 8, ["disabled", "onClick"])
          ]),
          (_ctx.error)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.t('cancel_error')), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.shouldAllowCancel)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "email-us",
          innerHTML: _ctx.t('email_us')
        }, null, 8, _hoisted_7))
      : _createCommentVNode("", true)
  ]))
}