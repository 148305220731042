import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_loading = _resolveComponent("loading")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _cache[3] || (_cache[3] = _createElementVNode("h1", null, "Change Email", -1)),
          _cache[4] || (_cache[4] = _createElementVNode("span", null, " This functionality changes the email address of the selected user in our db, in Firebase and in Active Campaign. ", -1)),
          _createVNode(_component_v_autocomplete, {
            modelValue: _ctx.selectedEmail,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedEmail) = $event)),
            items: _ctx.users.map((user) => user.email),
            "item-title": (user) => user.email,
            "item-value": (user) => user.userId,
            label: 'Emails',
            variant: "outlined",
            color: "#3B755F",
            "item-color": "#3B755F",
            class: "dropdown",
            rules: [_ctx.rules.required]
          }, null, 8, ["modelValue", "items", "item-title", "item-value", "rules"]),
          _createVNode(_component_v_text_field, {
            id: "Email",
            modelValue: _ctx.updatedEmail,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.updatedEmail) = $event)),
            class: "input-field",
            name: "Email",
            autocomplete: "email",
            type: "email",
            rules: [_ctx.rules.required, _ctx.rules.email],
            label: 'Updated Email',
            variant: "outlined",
            flat: "",
            color: "green",
            "hide-details": ""
          }, null, 8, ["modelValue", "rules"]),
          _createVNode(_component_gs_button, {
            disabled: !_ctx.updatedEmail || _ctx.loading || !_ctx.selectedEmail,
            loading: _ctx.loading,
            "full-width": true,
            size: "large",
            onClick: _withModifiers(_ctx.changeEmailForUser, ["prevent"])
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode(" Save ")
            ])),
            _: 1
          }, 8, ["disabled", "loading", "onClick"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_loading, { key: 1 }))
      : _createCommentVNode("", true)
  ]))
}