import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "variables-preview" }
const _hoisted_2 = { class: "text-left" }
const _hoisted_3 = { class: "text-left" }
const _hoisted_4 = { class: "text-body-1 text-no-wrap" }
const _hoisted_5 = { class: "text-body-1 py-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_copy_button = _resolveComponent("copy-button")!
  const _component_v_table = _resolveComponent("v-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", null, _toDisplayString(_ctx.t('text')), 1),
    _createVNode(_component_v_table, {
      class: "table mb-5",
      "fixed-header": ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _createElementVNode("th", _hoisted_2, _toDisplayString(_ctx.t('variable')), 1),
            _createElementVNode("th", _hoisted_3, _toDisplayString(_ctx.t('example')), 1)
          ])
        ]),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.defaultVariables, (value, key) => {
            return (_openBlock(), _createElementBlock("tr", { key: key }, [
              _createElementVNode("td", _hoisted_4, [
                _createTextVNode(_toDisplayString(_ctx.formatKey(key)) + " ", 1),
                _createVNode(_component_copy_button, {
                  text: _ctx.formatKey(key)
                }, null, 8, ["text"])
              ]),
              _createElementVNode("td", _hoisted_5, _toDisplayString(value), 1)
            ]))
          }), 128))
        ])
      ]),
      _: 1
    })
  ]))
}