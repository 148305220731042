<script lang="ts">
import type { OffsetType, TreeComparison } from '@/helpers/interfaces'
import { Utils } from '@/helpers/mixins/utilsMixin'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TreesComparison',
  mixins: [Utils],
  props: {
    comparisons: {
      required: true,
      type: Object as PropType<TreeComparison>,
    },
    colorSettings: {
      required: true,
      type: Object as PropType<Record<OffsetType, string>>,
    },
  },
})
</script>

<template>
  <div class="trees-comparison-container">
    <div
      class="carbon-sequestered comparison-card"
      :style="{ 'background-color': colorSettings.trees }"
    >
      <div class="comparison-data">
        <div class="comparison-amount">
          {{
            formatTotalAmounts(
              comparisons.carbonSequestered.amount,
              comparisons.carbonSequestered.suffix,
            )
          }}
        </div>
        <div class="info-text">{{ comparisons.carbonSequestered.amountDescription }}</div>
        <a :href="comparisons.carbonSequestered.link" target="_blank" class="info-sub-text">
          {{ comparisons.carbonSequestered.description }}
        </a>
      </div>
      <div class="comparison-icon-wrapper">
        <img
          id="carbonSequesteredComparisonIcon"
          class="comparison-icon"
          alt="carbon_sequestered"
          :src="require('@/assets/icons/comparisons/carbon_sequestered_v2.svg')"
        />
      </div>
    </div>
    <div
      class="football-pitches comparison-card"
      :style="{ 'background-color': colorSettings.trees }"
    >
      <div class="comparison-icon-wrapper">
        <img
          id="footballPitchesComparisonIcon"
          class="comparison-icon"
          alt="football-pitches"
          :src="require('@/assets/icons/comparisons/football_pitches_v2.svg')"
        />
      </div>
      <div class="comparison-data">
        <div class="comparison-amount">
          {{
            formatTotalAmounts(
              comparisons.footballPitches.amount,
              comparisons.footballPitches.suffix,
            )
          }}
        </div>
        <div class="info-text">
          {{ comparisons.footballPitches.amountDescription }}
        </div>
        <a :href="comparisons.footballPitches.link" target="_blank" class="info-sub-text">
          {{ comparisons.footballPitches.description }}
        </a>
      </div>
    </div>
    <div class="area-planted comparison-card" :style="{ 'background-color': colorSettings.trees }">
      <div class="comparison-data">
        <div class="comparison-amount">
          {{ formatTotalAmounts(comparisons.areaPlanted.amount, comparisons.areaPlanted.suffix) }}
        </div>
        <div class="info-text">
          {{ comparisons.areaPlanted.amountDescription }}
        </div>
        <a :href="comparisons.areaPlanted.link" target="_blank" class="info-sub-text">
          {{ comparisons.areaPlanted.description }}
        </a>
      </div>
      <div class="comparison-icon-wrapper">
        <img
          id="areaPlantedComparisonIcon"
          class="comparison-icon"
          alt="area planted"
          :src="require('@/assets/icons/comparisons/area_planted_v2.svg')"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '~vuetify/settings';

.trees-comparison-container {
  display: grid;
  gap: 16px;
}

.comparison-card {
  display: grid;
  height: 170px;
  border-radius: 10px;
}

.comparison-card.carbon-sequestered {
  grid-template-columns: auto 100px;
  padding: 6px 11px 6px 22px;
}

.comparison-card.area-planted {
  grid-template-columns: auto 100px;
  padding: 6px 11px 6px 22px;
}

.comparison-card.football-pitches {
  grid-template-columns: 100px auto;
  padding: 6px 22px 6px 11px;
}

.comparison-data {
  font-family: Cabin;
  display: grid;
  align-content: center;
}

.comparison-card:nth-child(even) .comparison-data {
  text-align: right;
}

.comparison-amount {
  color: var(--font-color-primary);
  font-size: 54px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
}

.info-text {
  line-height: normal;
  font-size: 16px;
  margin-bottom: 6px;
}

.info-sub-text {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  color: #212121;
}

.comparison-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.comparison-icon {
  position: relative;
}

.carbon-sequestered .comparison-icon {
  height: 84px;
  left: 4px;
}

.football-pitches .comparison-icon {
  height: 66px;
  right: 6px;
}

.area-planted .comparison-icon {
  height: 88px;
  left: 6px;
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .carbon-sequestered.comparison-card {
    grid-template-columns: auto 140px;
  }

  .area-planted.comparison-card {
    grid-template-columns: auto 140px;
  }

  .football-pitches.comparison-card {
    grid-template-columns: 120px auto;
  }

  .carbon-sequestered .comparison-icon {
    height: 118px;
    left: unset;
  }

  .football-pitches .comparison-icon {
    height: 86px;
    right: unset;
  }

  .area-planted .comparison-icon {
    height: 120px;
    left: unset;
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .trees-comparison-container {
    gap: 26px;
  }

  .carbon-sequestered.comparison-card {
    grid-template-columns: auto 150px;
  }

  .area-planted.comparison-card {
    grid-template-columns: auto 200px;
  }

  .football-pitches.comparison-card {
    grid-template-columns: 200px auto;
  }

  .carbon-sequestered .comparison-icon {
    height: 130px;
  }

  .football-pitches .comparison-icon {
    height: 126px;
  }

  .area-planted .comparison-icon {
    height: 154px;
  }
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
  .carbon-sequestered.comparison-card,
  .football-pitches.comparison-card {
    height: 180px;
  }

  .area-planted.comparison-card {
    height: 360px;
    margin-bottom: auto;
  }

  .trees-comparison-container {
    gap: 32px;
  }

  .carbon-sequestered.comparison-card,
  .football-pitches.comparison-card {
    grid-column: 1 / span 2;
    height: auto;
    min-height: 180px;
  }

  .carbon-sequestered.comparison-card {
    grid-row: 2;
  }

  .area-planted.comparison-card {
    grid-column: 3;
    grid-row: 1 / span 2;
    min-height: 370px;
    grid-template-columns: auto;
    grid-template-rows: auto 100px;
    gap: 16px;
    padding: 22px 40px 0 40px;
    width: 314px;
  }

  .football-pitches.comparison-card {
    grid-row: 1;
  }

  .carbon-sequestered.comparison-card .comparison-data {
    grid-template-columns: 1fr auto;
    column-gap: 16px;
  }

  .football-pitches.comparison-card .comparison-data {
    grid-template-columns: auto 1fr;
    column-gap: 16px;
  }

  .carbon-sequestered.comparison-card .comparison-data .info-sub-text {
    grid-column: 2;
  }

  .football-pitches.comparison-card .comparison-data .info-sub-text {
    grid-column: 1 / span 2;
  }

  .carbon-sequestered.comparison-card .comparison-amount,
  .football-pitches.comparison-card .comparison-amount {
    margin: auto;
  }

  .carbon-sequestered.comparison-card {
    grid-template-columns: auto 165px;
    gap: 16px;
  }

  .football-pitches.comparison-card {
    grid-template-columns: 200px auto;
  }

  .comparison-data .info-text {
    font-size: 24px;
  }

  .area-planted.comparison-card .comparison-data {
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: start;
  }

  .area-planted.comparison-card .comparison-data .info-text {
  }

  .area-planted .comparison-data {
    grid-row: 1;
    display: grid;
    justify-content: center;
    align-content: space-around;
    text-align: center;
    gap: 16px;
  }

  .area-planted .comparison-icon {
    top: -6px;
    height: 166px;
    grid-row: 2;
  }

  .football-pitches .comparison-icon {
    height: 126px;
    right: 20px;
  }

  .carbon-sequestered .comparison-icon {
    height: 130px;
  }
}
</style>
